import React from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Typography, useTheme, Grid } from '@material-ui/core'
import { Section } from '../../../../components/ui/panel/section/Section'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import {
  useListUserDistrictsQuery,
  useListUseragencyQuery,
  // useListUserAgegroupQuery,
  useListUserLanguageQuery,
  useListUserCountyQuery,
  useListUserDisciplineQuery,
  useListUserInsuranceQuery,
  useListUserSpecialityQuery
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'
import { useStyles } from './styles'

// interface ListUserViewParams extends UseParamsType {
//   userid: string
// }
interface ParentProps {
  userid: any
}

export const DetailView = (props: ParentProps) => {
  // const css = `.detailprovider > div:nth-child(2n+1) {
  //   padding-left: 100px
  // }
  // .detailprovider > div:nth-child(2n+2) {
  //   padding-right: 100px
  // }`
  // const theme = useTheme()
  const classes = useStyles()
  const { userid: userId } = props
  const { data, loading } = useListUserDistrictsQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  const { data: agencydata } = useListUseragencyQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  // const { data: ageserved } = useListUserAgegroupQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { user_id: String(userId) }
  // })
  const { data: userlanguage } = useListUserLanguageQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  const { data: usercounty } = useListUserCountyQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  const { data: userdiscipline } = useListUserDisciplineQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  const { data: userinsurance } = useListUserInsuranceQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  const { data: userspeciality } = useListUserSpecialityQuery({
    fetchPolicy: 'network-only',
    variables: { user_id: String(userId) }
  })
  let finalArr
  finalArr = data?.userDistricts

  if (finalArr) finalArr = finalArr.filter((x) => x.user_id.id === userId && x.primary === true)

  return (
    <>
      {/* <NavBar title="Provider Details" goBackBtn /> */}
      {loading && <ProgressIndicator fullHeight />}
      {/* <style>{css}</style> */}
      {finalArr &&
        finalArr?.map((therapist) => (
          <>
            <Grid
              container
              sm={12}
              md={12}
              className="detailprovider"
              style={{ fontFamily: 'Roboto' }}
            >
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong> First Name: </strong>
                <div className={classes.width}>{therapist.user_id.first_name}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <strong>Last Name: </strong>
                <div className={classes.width}>{therapist.user_id.last_name}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong> Phone Number: </strong>
                <div className={classes.width}>{therapist.user_id.mobile_phone}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <strong>Ethnic Group: </strong>
                <div className={classes.width}>{therapist?.user_id?.ethinic_group_id?.name}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Ages Served: </strong>
                <div className={classes.width}>
                  <span>
                    {/* {ageserved &&
                      ageserved?.useragegroup?.map((item, index) => (
                        <span key={item?.age_group_id}>
                          {`${item?.min_age}${`-${item?.max_age}`} years`}
                        </span>
                      ))} */}
                    {`${
                      therapist?.user_id?.newUserAgeGroups?.min_age_list?.name
                    }${`-${therapist?.user_id?.newUserAgeGroups?.max_age_list?.name}`} years`}
                  </span>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <div>
                  <strong>Provider discipline: </strong>
                  <div className={classes.width}>
                    {userdiscipline &&
                      userdiscipline?.userdiscipline?.map((item, index) => (
                        <span key={item?.discipline_id}>{item?.name} </span>
                      ))}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Services: </strong>
                <div className={classes.width}>{therapist.user_id.signal_service_id.name}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <strong>Experience: </strong>
                <div className={classes.width}>
                  {therapist.user_id.signalUserExperience.experience}
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Offered Languages: </strong>{' '}
                <div className={classes.width}>
                  {userlanguage &&
                    userlanguage?.userlanguage?.map((item, index) => (
                      <span key={item?.language_id}>
                        {' '}
                        {item?.name}{' '}
                        {userlanguage?.userlanguage &&
                        userlanguage?.userlanguage.length - 1 > index ? (
                          <span> , &nbsp; </span>
                        ) : (
                          ''
                        )}{' '}
                      </span>
                    ))}
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <div>
                  <strong>County: </strong>
                  <div className={classes.width}>
                    {usercounty &&
                      usercounty?.usercounty?.map((item, index) => (
                        <span key={item?.county_id}>
                          {' '}
                          {item?.name}{' '}
                          {usercounty?.usercounty && usercounty?.usercounty.length - 1 > index ? (
                            <span> , &nbsp; </span>
                          ) : (
                            ''
                          )}{' '}
                        </span>
                      ))}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Agency: </strong>
                <div className={classes.width}>
                  {agencydata &&
                    agencydata?.useragency?.map((item, index) => (
                      <span key={item?.agency_id}>
                        {item?.agency}
                        {agencydata?.useragency && agencydata?.useragency?.length - 1 > index ? (
                          <span>,&nbsp;</span>
                        ) : (
                          ''
                        )}
                      </span>
                    ))}
                  {!agencydata?.useragency?.length && <p> -------- </p>}
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <div>
                  <strong>Agency Address: </strong>
                  <div className={classes.width}>{therapist.user_id.street_address}</div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <div>
                  <strong>Specialities: </strong>
                  <div className={classes.width}>
                    {userspeciality &&
                      userspeciality?.userspeciality?.map((item, index) => (
                        <span key={item?.speciality_id}>
                          {' '}
                          {item?.name}{' '}
                          {userspeciality?.userspeciality &&
                          userspeciality?.userspeciality.length - 1 > index ? (
                            <span>, &nbsp; </span>
                          ) : (
                            ''
                          )}{' '}
                        </span>
                      ))}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <div>
                  <strong>Insurance Accepted: </strong>
                  <div className={classes.width}>
                    {userinsurance &&
                      userinsurance?.userinsurance?.map((item, index) => (
                        <span key={item?.insurance_id}>
                          {' '}
                          {item?.name}{' '}
                          {userinsurance?.userinsurance &&
                          userinsurance?.userinsurance.length - 1 > index ? (
                            <span> , &nbsp; </span>
                          ) : (
                            ''
                          )}{' '}
                        </span>
                      ))}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Degree Held: </strong>
                <div className={classes.width}>{therapist?.user_id?.degree_held}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddry}>
                <strong>School Attended: </strong>
                <div className={classes.width}>{therapist?.user_id?.school_attended}</div>
              </Grid>
              <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <strong>Specialized Training: </strong>
                <div className={classes.width}>{therapist?.user_id?.specialized_training}</div>
              </Grid>
              {therapist?.user_id?.supervisor_name_and_credentials ? (
                <Grid item sm={6} alignItems="center" className={classes.paddry}>
                  <div style={{ width: '200px' }}>
                    <strong>Supervisor Name and Credentials: </strong>
                  </div>
                  <div className={classes.width} style={{ marginTop: '-39px' }}>
                    {therapist?.user_id?.supervisor_name_and_credentials}
                  </div>
                </Grid>
              ) : (
                ''
              )}
              {/* <Grid item sm={6} alignItems="center" className={classes.paddxy}>
                <div>
                  <strong>About Me: </strong>
                  <div className={classes.width}>{therapist.user_id.description}</div>
                </div>
              </Grid> */}
            </Grid>
          </>
        ))}
    </>
  )
}
