import React, { useState } from 'react'
import {
  Grid,
  Radio,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { User, ChatGroup } from 'graphql/hooks.generated'
import useStyles from './Styles'
import Dialog from '../../panel/dialog/Dialog'

interface InviteDialogProps {
  handleSubmit: (groupId: string, emails: string[]) => Promise<unknown>
  fetchCurrentChatUsers: (groupId: string) => Promise<unknown>
  open: boolean
  loading: boolean
  users: User[]
  chats: ChatGroup[]
  setOpen: (pld: boolean) => unknown
}

export const InviteDialog: React.FC<InviteDialogProps> = ({
  handleSubmit,
  fetchCurrentChatUsers,
  open,
  loading,
  users,
  chats,
  setOpen
}: InviteDialogProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedChat, setSelectedChat] = useState<string | null | undefined>(null)
  const classes = useStyles()
  const closeDialog = () => {
    setSelectedUsers([])
    setSelectedChat(null)
    setOpen(false)
  }
  const submitDialog = () => {
    if (selectedChat && selectedUsers.length)
      handleSubmit(
        selectedChat,
        users.filter(({ id }) => selectedUsers.includes(id)).map(({ email }) => email)
      )

    closeDialog()
  }
  const chooseUser = (e) =>
    e.target.checked
      ? setSelectedUsers([...selectedUsers, e.target.value])
      : setSelectedUsers(selectedUsers.filter((id) => id !== e.target.value))
  const chooseChat = (e, chat) => {
    setSelectedChat(e.target?.value)
    fetchCurrentChatUsers(chat.id)
    setSelectedUsers([])
  }

  return (
    <Dialog
      handleClose={closeDialog}
      handleSubmit={submitDialog}
      disabled={!selectedUsers.length}
      cancel="Cancel"
      submit="Invite"
      open={open}
      title="Invite to chat"
      fullWidth
      maxWidth="sm"
    >
      <Grid container spacing={3}>
        <Grid item sm={6} md={6} lg={6}>
          <List>
            {chats.map((chat) => (
              <ListItem key={`chat_${chat.id}`}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={chat.name} />
                <Radio
                  onChange={(e) => chooseChat(e, chat)}
                  value={chat.id}
                  checked={selectedChat === chat.id}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item sm={6} md={6} lg={6}>
          {!loading && (
            <List>
              {selectedChat &&
                users.map((user) => (
                  <ListItem key={`user_${user.id}`}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} />
                    <Checkbox
                      onChange={chooseUser}
                      value={user.id}
                      checked={selectedUsers.includes(user.id)}
                    />
                  </ListItem>
                ))}
              {selectedChat && !users.length && (
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  There is no available users to invite to this chat
                </Typography>
              )}
              {!selectedChat && (
                <Typography
                  variant="h4"
                  color="secondary"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Choose chat first
                </Typography>
              )}
            </List>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default InviteDialog
