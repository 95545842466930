import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Chip } from '@material-ui/core'
import Button from '../../panel/button/Button'
import useStyles from './Styles'

export const TimeSlots = (item) => {
  const slots = item
  const { t, i18n } = useTranslation()
  const [timeSlots, setTimeSlots] = useState(slots?.item || {})
  const [selectedSlot, setSelectedSlot] = useState()
  const slotKeys = Object.keys(timeSlots)
  const classes = useStyles()
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const handleClick = (e, slot) => {
    e.target.classList.toggle('selected')
    setSelectedSlot(slot)
    slots.slotSelected(slot)
  }

  return (
    <>
      {slotKeys?.length >= 1 ? (
        slotKeys.map((item) => {
          return (
            <Grid
              sm={12}
              spacing={1}
              container
              direction="row"
              className={classes.slots}
              key={item}
            >
              <Grid item xs={12} sm={2} className={classes.slotBtnFirst}>
                <div>{item}</div>
              </Grid>
              <Grid item xs={12} sm={10}>
                {timeSlots[item].map((slot, i) => {
                  return (
                    <Chip
                      className={[
                        classes.customChip,
                        slot.booked === '1' ? classes.notAvailable : '',
                        selectedSlot?.timeperiod === slot.timeperiod &&
                        selectedSlot?.student_time_slot === slot.student_time_slot
                          ? classes.selected
                          : ''
                      ]}
                      // className={classes.customChip}
                      label={slot.student_time_slot}
                      onClick={(e) => handleClick(e, slot)}
                    />
                  )
                })}
              </Grid>
              {/* <Grid item>
              <Chip className={classes.customChip} label="9-10 AM" onClick={handleClick} />
            </Grid>
            <Grid item>
              <Chip className={classes.customChip} label="10-11 AM" onClick={handleClick} />
            </Grid>
            <Grid item>
              <Chip className={classes.customChip} label="11-12 AM" onClick={handleClick}>
                11-12 AM
              </Chip>
            </Grid>
            <Grid item>
              <Chip className={classes.customChip} label="1-2 PM" onClick={handleClick}>
                1-2 PM
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                className={[classes.customChip, classes.selected]}
                label="2-3 PM"
                onClick={handleClick}
              >
                2-3 PM
              </Chip>
            </Grid>
            <Grid item>
              <Chip className={classes.customChip} label="3-4 PM" onClick={handleClick}>
                3-4 PM
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                className={[classes.customChip, classes.notAvailable]}
                label="4-5 PM"
                onClick={handleClick}
              >
                4-5 PM
              </Chip>
            </Grid>
            <Grid item>
              <Chip
                className={[classes.customChip, classes.notAvailable]}
                label="4-5 PM"
                onClick={handleClick}
              >
                4-5 PM
              </Chip>
            </Grid>
         */}
            </Grid>
          )
        })
      ) : (
        <>
          <div style={{ color: '#FF0000' }}>{t('errormessages.noslot')}</div>
        </>
      )}
    </>
  )
}
