import {
  AgesServed,
  Role,
  Specialities,
  TherapistDiscipline,
  UserGender
} from 'graphql/hooks.generated'
// import { min_age, max_age } from 'graphql/hooks.generated'
import { CurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { makeValidate } from 'mui-rff'
import { isConditionalExpression } from 'typescript'
import * as yup from 'yup'
import { UserFormModel } from './UserFormModel'
import { AgesServedSelect } from '../../../../components/ui/form/multiselect-dropdown/AgesServedSelect'

const canSetDistrict = (user: CurrentUser) =>
  user.hasRole(Role.TeleteachersAdmin) || user.hasRole(Role.SchoolAdmin)
const therapistRoles = [Role.Therapist, Role.SchoolTherapist]

export const requireRoles = (roles: Role[] | undefined) =>
  therapistRoles.filter((role) => roles?.includes(role)).length > 0

export const createValidator = (user: CurrentUser) =>
  makeValidate(
    yup.object<UserFormModel>({
      district: yup
        .string()
        .label('District')
        .nullable()
        .when('roles', (roles: Role[] | undefined, districtSchema: yup.StringSchema) => {
          if (canSetDistrict(user)) return districtSchema.required()

          return districtSchema
        }),
      streetAddress: yup
        .mixed<string>()
        .label('Agency Address')
        .when(
          'roles',
          (roles: Role[] | undefined, streetAddressSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return streetAddressSchema.required()

            console.log(streetAddressSchema)

            return streetAddressSchema
          }
        ),
      licenseNumber: yup
        .mixed<string>()
        .label('License Number')
        .when(
          'roles',
          (roles: Role[] | undefined, licenseNumberSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return licenseNumberSchema.required()

            return licenseNumberSchema
          }
        ),
      email: yup.string().email().label('Email address').required(),
      firstName: yup.string().label('First Name').required(),
      lastName: yup.string().label('Last Name').required(),
      roles: yup.array<Role>().required(),
      studentId: yup
        .number()
        .label('Students')
        .when('roles', (roles: Role[] | undefined, studentSchema: yup.StringSchema) => {
          if (roles?.includes(Role.Parent)) return studentSchema.required()

          return studentSchema
        }),
      // therapistDiscipline: yup
      //   .mixed<TherapistDiscipline>()
      //   .label('Therapist discipline')
      //   .when(
      //     'roles',
      //     (roles: Role[] | undefined, disciplineSchema: yup.MixedSchema<TherapistDiscipline>) => {
      //       if (requireRoles(roles)) return disciplineSchema

      //       return disciplineSchema
      //     }
      //   ),
      stateLicense: yup
        .mixed<number>()
        .label('State License')
        .when('roles', (roles: Role[] | undefined, stateLicenceSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return stateLicenceSchema

          // console.log(roles)

          return stateLicenceSchema
        }),
      mobilePhone: yup
        // .mixed<string>()
        .string()
        .min(11, 'Work phone number should be 10 digits')
        .max(11, 'Work phone number should be 10 digits')
        .nullable()
        .label('Work Phone Number')
        .when('roles', (roles: Role[] | undefined, mobilePhoneSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return mobilePhoneSchema.required()

          return mobilePhoneSchema
        }),
      certDate: yup
        .date()
        .label('Certificate Date')
        .when('roles', (roles: Role[] | undefined, certDateSchema: yup.DateSchema<Date>) => {
          if (requireRoles(roles)) return certDateSchema

          return certDateSchema
        }),
      certExpireDate: yup
        .date()
        .label('Certificate Expire Date')
        .when('roles', (roles: Role[] | undefined, certExpireDateSchema: yup.DateSchema<Date>) => {
          if (requireRoles(roles)) return certExpireDateSchema

          return certExpireDateSchema
        }),
      npi: yup
        .mixed<string>()
        .label('NPI#')
        .when('roles', (roles: Role[] | undefined, npiSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return npiSchema

          return npiSchema
        }),
      signalDiscipline: yup
        .mixed<number>()
        .label('Therapist discipline')
        .when('roles', (roles: Role[] | undefined, DisciplineSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return DisciplineSchema.required()

          return DisciplineSchema
        }),
      min_age: yup
        .number()
        .label('Min Age')
        .when('roles', (roles: Role[] | undefined, min_ageSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return min_ageSchema.required()

          return min_ageSchema
        }),
      max_age: yup
        .number()
        .label('Max Age')
        // .required()
        .when(
          ['roles', 'min_age'],
          (roles: Role[] | undefined, min_age, max_ageSchema: yup.MixedSchema<number>, maxobj) => {
            return min_age > maxobj.value
              ? max_ageSchema.test(
                  'maxage',
                  'Max Age should be greater than Min Age',
                  (value) => !(value < min_age)
                )
              : requireRoles(roles)
              ? max_ageSchema.required()
              : max_ageSchema
          }
        ),
      signalCounties: yup
        .array<any[]>()
        .label('Counties Served')
        .when('roles', (roles: Role[] | undefined, CountySchema: yup.ArraySchema<any>) => {
          if (requireRoles(roles)) return CountySchema.min(1).required()

          return yup.mixed<any[]>()
        }),
      signalInsurance: yup
        .array<any[]>()
        .label('Insurance Accepted')
        .when('roles', (roles: Role[] | undefined, InsuranceSchema: yup.ArraySchema<any>) => {
          if (requireRoles(roles)) return InsuranceSchema.min(1).required()

          return yup.mixed<any[]>()
        }),
      signalServiceId: yup
        .mixed<number>()
        .label('Services')
        .when('roles', (roles: Role[] | undefined, ServicesSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return ServicesSchema.required()

          return ServicesSchema
        }),
      signalSpecilities: yup
        .array<any[]>()
        .label('Specialities')
        .when('roles', (roles: Role[] | undefined, SpecialitiesSchema: yup.ArraySchema<any>) => {
          if (requireRoles(roles)) return SpecialitiesSchema.min(1).required()

          return yup.mixed<any[]>()
        }),
      // signalEthnicGroup: yup
      //   .mixed<number>()
      //   .label('Ethnic Group')
      //   .when('roles', (roles: Role[] | undefined, EthnicGroupSchema: yup.MixedSchema<number>) => {
      //     if (requireRoles(roles)) return EthnicGroupSchema

      //     return EthnicGroupSchema
      //   }),
      gender: yup
        .mixed<UserGender>()
        .label('Gender')
        .when('roles', (roles: Role[] | undefined, GenderSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return GenderSchema.required()

          return GenderSchema
        }),
      signalLanguage: yup
        .array<any[]>()
        .label('Offered Languages')
        .when('roles', (roles: Role[] | undefined, LanguagesSchema: yup.ArraySchema<any>) => {
          if (requireRoles(roles)) return LanguagesSchema.min(1).required()

          return yup.mixed<any[]>()
        }),
      signalAgencies: yup
        .mixed<number[]>()
        .label('Agency')
        .when('roles', (roles: Role[] | undefined, AgenciesSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return AgenciesSchema.required()

          return AgenciesSchema
        }),
      signalUserExperience: yup
        .mixed<number>()
        .label('Experience')
        .when('roles', (roles: Role[] | undefined, ExperienceSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return ExperienceSchema.required()

          return ExperienceSchema
        }),
      degree_held: yup
        .mixed<string>()
        .label('Degree Held')
        .when('roles', (roles: Role[] | undefined, degreeheldSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return degreeheldSchema.required()

          return degreeheldSchema
        }),
      school_attended: yup
        .mixed<string>()
        .label('School Attended')
        .when(
          'roles',
          (roles: Role[] | undefined, schoolattendedSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return schoolattendedSchema.required()

            return schoolattendedSchema
          }
        ),
      clinician_phone: yup
        // .mixed<string>()
        .string()
        .min(11, 'Clinician phone number should be 10 digits')
        .max(11, 'Clinician phone number should be 10 digits')
        .nullable()
        .label('Clinician Phone')
        .when(
          'roles',
          (roles: Role[] | undefined, clinicianphoneSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return clinicianphoneSchema.required()

            return clinicianphoneSchema
          }
        ),
      specialized_training: yup
        .mixed<string>()
        .label('Specialized Training')
        .when(
          'roles',
          (roles: Role[] | undefined, specializedtrainingSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return specializedtrainingSchema.required()

            return specializedtrainingSchema
          }
        ),
      supervisor_name_and_credentials: yup
        .mixed<string>()
        .label('Supervisor Name And Credentials')
        .when('roles', (roles: Role[] | undefined, supervisorSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return supervisorSchema

          return supervisorSchema
        }),
      homePhone: yup
        .string()
        .min(11, 'Mobile phone number should be 10 digits')
        .max(11, 'Mobile phone number should be 10 digits')
        .nullable()
        .label('Mobile Phone')
        .when('roles', (roles: Role[] | undefined, homephoneSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return homephoneSchema

          return homephoneSchema
        })
    })
  )

function value(value: any) {
  throw new Error('Function not implemented.')
}
