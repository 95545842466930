import React, { useEffect, useMemo, useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory, useRouteMatch, useParams, Router } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  Avatar
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import Paper from '../../../../components/ui/panel/paper/Paper'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useListUserDistrictsLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'

interface ListUserDistrictViewParams extends UseParamsType {
  id: string
}

export const TherapistListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.providerlistq?.columnName
          ? sfStoreState?.providerlistq?.columnName
          : 'user_id.last_name',
        direction: sfStoreState?.providerlistq?.columnDirection
          ? sfStoreState?.providerlistq?.columnDirection
          : 'Ascending'
      }
      // { field: 'user_id.gender', direction: 'Ascending' },
      // { field: 'user_id.email', direction: 'Ascending' },
      // { field: 'add_student_permission', direction: 'Ascending' }
    ]
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.providerlistq?.pageSize ? sfStoreState?.providerlistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.providerlistq?.currentPage
      ? sfStoreState?.providerlistq?.currentPage
      : 1
  }
  const { id: districtId } = useParams<ListUserDistrictViewParams>()
  const classes = useStyles()
  const { data: currentUserData } = useCurrentUser()
  const [UserDistrictsQuery, { data, loading }] = useListUserDistrictsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const usersFetch = useMemo(
    () => ({ district_id }) =>
      UserDistrictsQuery({
        variables: { district_id }
      }),
    [UserDistrictsQuery]
  )
  useEffect(() => {
    let inputdata

    if (districtId) {
      inputdata = {
        district_id: Number(districtId)
      }
      usersFetch(inputdata)
    } else if (currentUserData) {
      inputdata = {
        district_id: Number(currentUserData?.currentUser.district?.id)
      }
      usersFetch(inputdata)
    }
  }, [currentUserData, districtId, usersFetch])
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: [
      'user_id.first_name',
      'user_id.last_name',
      'user_id.gender',
      'user_id.email',
      'add_student_permission'
    ],
    key: sfStoreState?.providerlistq?.searchText,
    ignoreCase: true
  }
  let grid: Grid | null
  const statusData = () => {
    return (
      <div>
        {data?.userDistricts?.map((status) => {
          if (status.add_student_permission === true) return <div>enabled</div>

          return <div>disabled</div>
        })}
      </div>
    )
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            providerlistq: { ...sfStoreState.providerlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              providerlistq: { ...sfStoreState.providerlistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          providerlistq: {
            ...sfStoreState.providerlistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          providerlistq: {
            ...sfStoreState.providerlistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }
  const caseloadpath = (linksD) => {
    if (sfStoreState?.caseloadid !== linksD.user_id.id)
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          caseloadlistq: {
            searchText: '',
            currentPage: 0,
            pageSize: 0,
            columnName: '',
            columnDirection: ''
          },
          caseloadid: ''
        })
      )

    history.push(
      `${url}/${linksD.user_id.id}/caseload?name=${linksD.user_id.first_name} ${linksD.user_id.last_name}`
    )
  }

  return (
    <>
      <NavBar title="Providers" goBackBtn />
      <PageContainer>
        <Box padding="10px" display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h3"
              style={{
                color: theme.palette.info.main,
                fontFamily: 'Roboto'
              }}
            >
              Providers
            </Typography>
          </Box>
        </Box>
        <div className="control-pane">
          <div className="control-section">
            {data && (
              <GridComponent
                key={Math.floor(Math.random() * 100)}
                allowTextWrap
                dataSource={data?.userDistricts || []}
                width="auto"
                toolbar={toolbarOptions}
                allowPaging
                pageSettings={pageOptions}
                allowSorting
                searchSettings={searchOptions}
                sortSettings={sortSettings}
                className={classes.headerCls}
                ref={(g) => gridref(g)}
                created={createdevent}
                actionComplete={(value) => handleActionBegin(value)}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    template={() => (
                      <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                    )}
                    width="40"
                  />
                  <ColumnDirective
                    field="user_id.last_name"
                    template={(names) => (
                      // <Link
                      //   onClick={() => history.push(`${url}/${names.user_id.id}/details`)}
                      //   style={{ color: 'black', cursor: 'pointer' }}
                      // >
                      <span className={classes.nameCls}>
                        {`${names.user_id.last_name} , ${names.user_id.first_name}`}
                      </span>
                      // </Link>
                    )}
                    headerText="NAME"
                    width="130"
                  />
                  <ColumnDirective field="user_id.gender" headerText="GENDER" width="80" />
                  <ColumnDirective field="user_id.email" headerText="EMAIL" width="180" />
                  <ColumnDirective
                    field="user_id.mobile_phone"
                    headerText="MOBILE NUMBER"
                    width="130"
                  />
                  <ColumnDirective
                    field="user_id.signal_service_id.name"
                    headerText="SERVICE"
                    width="130"
                  />
                  <ColumnDirective
                    template={(linksD) => (
                      <Link
                        onClick={() =>
                          history.push(
                            `${url}/${linksD.user_id.id}/calendar?name=${linksD.user_id.first_name} ${linksD.user_id.last_name}`
                          )
                        }
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Calendar{' '}
                      </Link>
                    )}
                    headerText="ACTIONS"
                    width="80"
                  />
                  <ColumnDirective
                    template={(linksD) => (
                      <Link
                        onClick={() => caseloadpath(linksD)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Caseload{' '}
                      </Link>
                    )}
                    width="80"
                  />
                  {/* <ColumnDirective
                    template={(linksD) => (
                      <Link
                        onClick={() => history.push(`${url}/${linksD.user_id.id}/edit`)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Edit{' '}
                      </Link>
                    )}
                    width="60"
                  /> */}
                  {/* <ColumnDirective
                    template={(linksD) => (
                      <Link
                        onClick={() => history.push(`/documentation?type=tp`)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Documentation{' '}
                      </Link>
                    )}
                    width="110"
                  /> */}
                  <ColumnDirective
                    template={(linksD) => (
                      <Link
                        onClick={() => history.push(`/TherapistInviteUpdate/${linksD.user_id.id}`)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Profile{' '}
                      </Link>
                    )}
                    width="110"
                  />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Sort, Page]} />
              </GridComponent>
            )}
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
