import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ImportantDevices } from '@material-ui/icons'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      fontFamily: 'Roboto',
      width: '100%',
      backgroundColor: '#fff',
      '& .MuiFilledInput-input': {
        padding: '0px !important',
        fontSize: '15px !important',
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        padding: 0,
        backgroundColor: '#fff !important'
      },
      '&.MuiSelect-selectMenu': {
        padding: '0px !important',
        backgroundColor: '#fff !important'
      },
      '& .MuiButtonBase-root.Mui-disabled': {
        backgroundColor: 'transparent'
      }
    },
    '&.MuiSelect-selectMenu': {
      padding: '0px !important',
      backgroundColor: '#fff'
    },
    rootCls: {
      flexGrow: 0,
      fontFamily: 'Roboto',
      width: '100%',
      backgroundColor: '#fff'
    },
    pageDiv: {
      backgroundColor: '#fff',
      padding: '10px'
    },
    paper: {
      padding: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      width: '100%',
      fontFamily: 'Roboto',
      boxShadow: 'none',
      borderBottom: '1px dashed #c7c7c7',
      '& p': {}
    },
    paperTabone: {
      padding: 0,
      textAlign: 'center',
      color: '#000',
      display: 'flex',
      width: '100%',
      boxShadow: 'none'
    },
    paperTabtwo: {
      padding: 0,
      textAlign: 'left',
      color: '#000',
      width: '100%',
      boxShadow: 'none'
    },
    paperTabNew: {
      padding: 0,
      textAlign: 'left',
      color: '#000',
      width: '100%',
      boxShadow: 'none',
      pointerEvents: 'none'
    },
    noPonterEvents: {
      pointerEvents: 'none'
    },
    gridLabel: { borderBottom: '1px dashed #c7c7c7' },
    columnSize: {
      paddingRight: '13px',
      fontFamily: 'Roboto',
      display: 'flex',
      lineHeight: '17px',
      marginBottom: '8px',
      alignItems: 'center',
      '& span': {
        paddingLeft: '5px'
      }
    },
    columnSizeLock: {
      paddingRight: '13px',
      paddingLeft: '20px',
      width: 'auto',
      fontFamily: 'Roboto',
      display: 'flex',
      marginBottom: '8px',
      '& span': {
        paddingLeft: '5px'
      }
    },
    iconStyle: {
      width: '13px',
      marginRight: '13px',
      '& .a': {
        fill: '#2C9BD6'
      }
    },
    iconWarning: {
      width: '100%',
      height: '95px',
      margin: '0 auto',
      paddingBottom: '20px',
      '& .a': {
        fill: '#FFB274'
      }
    },
    txtWarning: {
      textAlign: 'center',
      marginBottom: '25px'
    },
    appBar: {
      color: 'inherit',
      backgroundColor: '#ffffff',
      height: '41px',
      boxShadow: 'none',
      flexDirection: 'row',
      marginBottom: '10px',
      '& .myTab': {
        backgroundColor: 'yellow',
        color: 'white'
      },
      indicator: {
        backgroundColor: 'blue'
      }
    },
    iconAdd: {
      '& .a': {
        fill: '#ccc'
      }
    },

    iconWidth: {
      position: 'absolute',
      right: '0',
      marginTop: '-12px',
      backgroundColor: '#ccc',
      borderRadius: '15px',
      width: '14px',
      height: '13px',
      '& .a': {
        fill: '#ccc'
      }
    },
    gridContent: {
      justifyContent: 'left !important',
      marginLeft: '1px'
    },
    addButton: {
      padding: '8px',
      marginLeft: '25px',
      marginTop: '15px'
    },
    tabMenu: {
      height: '48px',
      boxShadow: 'none',
      marginTop: '20px'
    },
    buttonStyle: {
      borderRadius: '25px',
      border: '1px solid #ccc',
      fontSize: '13px',
      padding: '1px'
    },
    mytabView: {
      minHeight: '40px',
      width: 'auto',

      borderRadius: '5px'
    },
    tabPanelStyle: {
      border: '1px solid #E4E4E4',
      height: 'auto'
    },
    startIcon: {
      marginRight: '3px  !important',
      marginLeft: '-4px',
      background: '#ccc',
      borderRadius: '20px',
      border: '2px solid grey !important'
    },
    activeCls: {
      backgroundColor: 'blue',
      '& span': {
        backgroundColor: 'green'
      }
    },
    tabHovernew: {
      fontSize: '13px',
      minHeight: '40px',
      minWidth: '75px',
      textTransform: 'capitalize',
      borderBottom: 'transparent',
      opacity: '1',
      height: '40px',
      indicator: {
        backgroundColor: 'transparent !important'
      },
      color: 'black',
      backgroundColor: 'none',
      fontWeight: 'normal',
      '&:hover': {
        minHeight: '40px',
        color: 'black',
        backgroundColor: '#fff',
        borderBottom: 'transparent',
        opacity: 1
      },
      '&$tabSelected': {
        fontSize: '13px',
        textTransform: 'capitalize',
        color: 'black',
        minWidth: '75px',
        backgroundColor: '#fff',
        borderRadius: '15px 15px 0px 0px;',
        border: '1px solid #E4E4E4',
        borderBottom: 'transparent',
        height: '40px',
        minHeight: '40px',
        fontWeight: 'bold',
        indicator: {
          backgroundColor: '#fff'
        }
      },
      '&:focus': {
        color: 'black',
        backgroundColor: '#fff',
        borderBottom: 'transparent',
        height: '40px',
        minHeight: '40px'
      }
    },
    tabSelected: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    tabHover: {
      color: 'black',
      minHeight: '30px',
      fontSize: '13px',
      minWidth: '75px',
      textTransform: 'capitalize',
      backgroundColor: 'none',
      fontWeight: 'bold',
      '&:hover': {
        color: 'white',
        backgroundColor: '#2C9BD6',
        borderRadius: '25px',
        minWidth: '75px',
        opacity: 1
      },
      '&$tabSelected': {
        color: 'white',
        minHeight: '30px',
        backgroundColor: '#2C9BD6',
        borderRadius: '25px',
        minWidth: '75px',
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: 'white',
        backgroundColor: '#2C9BD6',
        borderRadius: '25px'
      }
    },

    tabsListCls: {
      fontSize: '13px',
      textTransform: 'capitalize',
      minHeight: '35px',
      PrivateTabIndicator: {
        backgroundColor: '#fff'
      }
    },
    listCls: {
      listStyle: 'none'
    },
    appBarcls: {
      backgroundColor: '#fff',
      color: '#000',
      width: '28%',
      height: '30px',
      minHeight: '30px',
      margin: '0 auto',
      fontFamily: 'Roboto',
      boxShadow: 'none'
    },
    indicator: {
      backgroundColor: 'transparent !important',
      display: 'none'
    },
    rootMain: {
      flexGrow: 1,
      fontFamily: 'Roboto',
      width: '100%',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '3px',
      height: 'auto',
      marginTop: '0x'
    },
    rootMainScroll: {
      overflow: 'hidden',
      overflowY: 'scroll',
      height: '500px',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey',
        borderRadius: '10px'
      }
    },
    splitDivs: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: '50%'
    },
    labelForm: {
      paddingRight: '10px',
      display: 'inline-flex',
      paddingTop: '16px'
    },
    goalLayout: {
      backgroundColor: '#EDF7FC',
      borderRadius: '3px',
      marginTop: '2px',
      marginBottom: '8px',
      paddingLeft: '6px',
      paddingRight: '6px',
      paddingBottom: '8px',
      width: '100%'
    },
    goalTxt: {
      textAlign: 'left',
      flexDirection: 'column',
      fontFamily: 'Roboto',
      flex: 'auto',
      fontWeight: 'bold',
      color: '#000'
    },
    deleteIcon: {
      textAlign: 'right',
      flexDirection: 'column',
      flex: 'auto',
      height: '13px',
      cursor: 'pointer',
      fontFamily: 'Roboto'
    },
    paperVideo: {
      padding: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      width: '100%',
      border: '1px solid #eee',
      background: '#fff',
      fontFamily: 'Roboto',
      boxShadow: 'none',
      height: '100%'
    },
    paperDivTabs: {
      padding: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      width: '100%',
      background: 'transparent',
      fontFamily: 'Roboto',
      boxShadow: 'none'
    },
    paperDivContent: {
      padding: 0,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      width: '100%',
      marginTop: '-8px',
      background: 'transparent',
      fontFamily: 'Roboto',
      boxShadow: 'none'
    },
    studentTxt: {
      textAlign: 'left',
      flexDirection: 'row',
      fontFamily: 'Roboto',
      flex: 'auto',
      fontSize: '14px',
      color: '#000'
    },
    activityTxt: {
      textAlign: 'left',
      flexDirection: 'column',
      color: '#2C9BD6',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      flex: 'auto',
      width: '50%',
      background: '#fff',
      padding: '5px',
      marginRight: '6px',
      borderRadius: '5px'
    },
    colorTxt: {
      textAlign: 'left',
      flexDirection: 'column',
      color: '#000',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      flex: 'auto',
      width: '50%',
      background: '#fff',
      padding: '5px',
      marginRight: '6px',
      borderRadius: '5px'
    },
    studentName: {
      paddingTop: '10px',
      paddingRight: '10px',
      display: 'inline !important',
      '& b': {
        color: 'red'
      }
    },
    hidenotes: {
      paddingRight: '8px',
      fontWeight: 'bold',
      display: 'inline !important',
      '& b': {
        color: 'red'
      }
    },
    pty: {
      paddingTop: '2px'
    },
    labelTxt: {
      float: 'left',
      fontSize: '13px',
      color: '#2C9BD6',
      maxWidth: '50%'
    },
    labelText: {
      fontSize: '16px',
      color: '#0D1F48',
      fontWeight: 'bold',
      fontFamily: 'Roboto'
    },
    textColor: {
      color: '#0D1F48'
    },
    fnormal: {
      fontWeight: 'normal'
    },
    copyIcon: {
      float: 'right',
      maxWidth: '50%',
      fontSize: '13px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      color: '#000',
      display: 'inline-flex',
      verticalAlign: 'middle',
      '& .a': {
        fill: '#fff'
      }
    },
    iconCls: {
      width: 'auto',
      minWidth: '26px',
      padding: '0'
    },
    chipIcons: {
      textAlign: 'left',
      flexDirection: 'column',
      fontFamily: 'Roboto',
      flex: 'auto',
      width: '50%',
      border: '1px solid #e5e5e5',
      padding: '5px',
      borderRadius: '5px'
    },
    bottomDiv: {
      textAlign: 'left',
      flexDirection: 'column',
      fontFamily: 'Roboto',
      flex: 'auto',
      width: '50%',
      height: '200px',
      overflow: 'hidden',
      overflowY: 'scroll',
      border: '1px solid #e5e5e5',
      padding: '5px',
      borderRadius: '5px',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey',
        borderRadius: '10px'
      }
    },
    progressDiv: {
      backgroundColor: '#EDF7FC',
      paddingRight: '0',
      padding: '6px'
    },
    paperTabDisable: {
      pointerEvents: 'none'
    },
    labelSpan: {
      width: '25%',
      flexDirection: 'column',
      maxWidth: '100px',
      display: 'inline-block'
    },
    progressBarCls: {
      width: '75%',
      display: 'inline',
      flexDirection: 'column'
    },
    namefieldsize: {
      height: '80px !important',
      width: '100%',
      overflow: 'auto !important',
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Roboto',
      borderRadius: 3,
      maxWidth: '100%',
      backgroundColor: '#fff',
      border: '1px solid #000'
    },
    colorBlack: {
      color: '#000'
    },
    spaceing: {
      fontFamily: 'Roboto',
      fontSize: '11px',
      marginRight: '3px',
      paddingTop: '0',
      lineHeight: '15px',
      width: '93%',
      textOverflow: 'ellipsis',
      backgroundColor: '#fff',
      '&&:focus': { backgroundColor: '#fff' },
      '& hover': {
        backgroundColor: '#fff'
      },
      '& selected': {
        backgroundColor: '#fff'
      },
      '& span': {
        paddingLeft: '8px',
        paddingRight: '4px',
        fontSize: '10px',
        fontFamily: 'Roboto',
        wordBreak: 'break-word'
      }
    },
    chipSpacing: {
      display: 'inline-flex',
      width: '100%',
      maxHeight: '85px'
    },
    trackersInfo: {
      width: '59px',
      padding: '0',
      marginTop: '5px',
      marginBottom: '8px',
      '& li': {
        display: 'inline-block',
        width: '60px',
        '& .a': {
          display: 'block'
        }
      }
    },
    typoBars: {
      display: 'block',
      width: '100%',
      marginBottom: '20px',
      marginTop: '10px'
    },
    progressRate: {
      width: '75%',
      margin: '5px auto',
      marginLeft: '25%',
      fontSize: '14px',
      color: '#000',
      fontFamily: 'Roboto'
    },
    countInfo: {
      marginTop: '10px',
      color: '#000',
      fontSize: '10px',
      fontWeight: 'bold',
      '& a': {
        paddingLeft: '8px'
      }
    },
    spanYes: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#000'
    },
    spanNo: { marginLeft: '50px' },
    spanVerbal: { marginLeft: '50px' },
    spanVisual: { marginLeft: '75px' },
    undoCls: { height: '15px' },
    selectTextProgress: {
      bacgroundColor: '#fff',
      width: '65% !important',
      selectMenu: {
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        padding: '10px !important',
        fontSize: '15px !important',
        '& selected': {},
        '& label': {
          color: 'red'
        }
      }
    },
    selectTextcls: {
      bacgroundColor: '#fff',
      width: '95% !important',
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important',
        padding: '5px !important'
      },
      selectMenu: {
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        padding: '10px !important',
        fontSize: '15px !important'
      }
    },
    selectDropdown: {
      bacgroundColor: '#fff',
      border: '1px solid black',
      backgroundColor: 'white',
      fontSize: '15px',
      fontFamily: 'Roboto',
      height: 35,
      width: '100%',
      paddingTop: 0,
      paddingLeft: 0,
      borderRadius: 3,
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      },
      '& div': {
        padding: '6px'
      },
      selectMenu: {
        bacgroundColor: '#fff',
        padding: '0px !important',
        fontSize: '16px'
      }
    },
    optionItem: { padding: '3px' },
    selectMenuNew: {
      padding: '0px'
    },
    undoText: {
      verticalAlign: 'middle',
      display: 'inline-flex',
      float: 'right',
      background: '#ccc',
      fontSize: '12px',
      borderRadius: '21px',
      paddingRight: '8px',
      marginTop: '8px',
      padding: '4px'
    },

    buttonInfo: {
      textAlign: 'right'
    },
    buttonInfoNew: {
      pointerEvents: 'none'
    },
    buttonStyles: {
      backgroundColor: '#F1583A',
      color: 'white',
      border: '1px solid #F1583A',
      padding: '6px 25px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 100,
      borderRadius: '25px',
      marginRight: '5px',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#F1583A'
      }
    },
    mandatoryButtonStyles: {
      backgroundColor: '#F1583A',
      color: 'white',
      border: '1px solid #F1583A',
      padding: '6px 25px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 100,
      borderRadius: '25px',
      marginRight: '5px',
      marginTop: '5px',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#F1583A'
      }
    },
    buttonStylesLock: {
      backgroundColor: 'none',
      color: 'red',
      border: '0',
      padding: '0',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 100,
      borderRadius: '0',
      marginRight: '5px',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: 'none'
      }
    },
    columnSizeNew: {
      paddingRight: '13px',
      fontFamily: 'Roboto',
      display: 'flex',
      lineHeight: '17px',
      marginTop: '20px',
      marginBottom: '0px',
      '& span': {
        paddingLeft: '5px',
        display: 'flex',
        flexDirection: 'column'
      }
    },
    sigContainer: {
      width: '80%',
      height: '80%',
      margin: '0 auto',
      backgroundColor: '#fff'
    },
    sigPad: {
      width: '100%',
      height: '100%'
    },
    switchChange: {
      width: '55px',
      '& span': {
        paddingLeft: '2px !important'
      }
    },
    closeIcon: {
      position: 'absolute',
      marginTop: '-16px'
    },
    textCls: {},
    formIcon: {},
    caseloadList: {
      minWidth: '250px',
      marginLeft: '-145px',
      border: '1px solid #ccc',
      background: '#fff',
      borderRadius: '5px',
      padding: '10px'
    },
    studentBtn: {
      display: 'inline-flex',
      height: '32px'
    },
    cancelBtn: {
      display: 'inline-flex',
      paddinLeft: '55px',
      border: '1px solid #ccc',
      marginLeft: '55px',
      height: '32px',
      borderRadius: '25px'
    },
    caseloadvalues: {
      padding: '10px',
      selectTextcls: {
        bacgroundColor: '#fff',
        width: '60% !important',
        selectMenu: {
          backgroundColor: '#fff',
          fontFamily: 'Roboto',
          padding: '10px !important',
          fontSize: '15px !important'
        }
      }
    },
    typocls: {
      marginTop: '10px'
    },
    dropdown: {
      position: 'relative',
      zIndex: 1,
      padding: '10px',
      float: 'right',
      width: '25%',
      border: '2px solid transparent',
      borderRadius: '9px',
      minWidth: '300px',
      backgroundColor: '#fff',
      boxShadow: '0 3px 10px 0 rgb(0 0 0 / 15%)'
    },
    signatureCanvas: {
      border: '1px solid black',
      width: '100%',
      minHeight: '500px'
    },
    addSign: {
      textAlign: 'center',
      fontSize: '16px'
    },

    canvasDiv: {
      '& canvas': {
        border: '1px solid #ccc !important',
        borderRadius: '10px !important'
      }
    },
    noteData: {},
    errorText: {
      color: theme.palette.error.dark
    },
    pb10: {
      paddingBottom: '10px'
    },
    pr10: {
      paddingRight: '10px'
    },
    textRight: {
      textAlign: 'right'
    },
    serviceSel: {
      width: '200px',
      marginTop: '-5px'
    },
    servicesSel: {
      width: '255px',
      marginTop: '-5px'
    },
    fs15: {
      fontSize: '15px'
    },
    pl10: {
      paddingLeft: '10px'
    }
  })
)
