import React, { useMemo, useEffect, useState, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import {
  useTheme,
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {
  useCreateFileUploadMutation,
  useMyCategoriesLazyQuery,
  useMyCategoriesQuery,
  useCurrentUserQuery,
  useFileuploadQuery,
  useDeleteFileUploadMutation
} from 'graphql/hooks.generated'
import { FileField } from 'components/ui/form/file-field/FileField'
import { UploaderComponent, UploadingEventArgs } from '@syncfusion/ej2-react-inputs'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useHistory, useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { CollapsibleTable3 } from './table3'
import CollapsibleTable from './table'
import CollapsibleTable2 from './table2'

interface ParentParams {
  studentDetails: any
}
interface ListViewParams extends UseParamsType {
  value: any
  id: string
  stu_id: string
}

export default function StudentDocumentation(props: ParentParams) {
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { studentDetails } = props
  const { stu_id } = useParams<ListViewParams>()
  let stud_id = '-1'
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const [fileData, setFileData] = useState<any>()
  const uploadObj = useRef<UploaderComponent>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [category, setCategory] = React.useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [isSubmitfileupload, setIsSubmitfileupload] = useState(false)
  const [isconfirmmodalopen, setisconfirmmodalopen] = useState(false)
  const [signalFileUploadInput, { loading: uploadfileloading }] = useCreateFileUploadMutation()
  const classes = useStyles()
  const [
    executeQuery,
    { data: categoryListData, loading: categoryListLoading }
  ] = useMyCategoriesLazyQuery({
    fetchPolicy: 'network-only'
  })
  const { refetch, data: filedata, loading: fileloading } = useFileuploadQuery({
    fetchPolicy: 'network-only',
    variables: {
      student_id: Number(stud_id)
    }
  })
  const [deleteFileUpload, { loading: deletefileloading }] = useDeleteFileUploadMutation()
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  const handleClickOpen = () => {
    setCategory(null)
    setIsSubmitfileupload(false)
    setDialogOpen(true)
    fetch()
  }
  const theme = useTheme()
  const [count, setcount] = useState(0)
  const totalsession = (value) => {
    setcount(value)
  }
  const onDeleteuploadfile = async (deletid) => {
    await deleteFileUpload({ variables: { id: deletid?.toString() || '' } })
    setisconfirmmodalopen(false)
    refetch()
  }
  const handeChange = (e) => {
    setCategory(e.target.value)
  }
  const fileupload = (file) => {
    if (file.filesData[0].statusCode === 1 || file.filesData[0].statusCode === '1')
      setUploadStatus(false)
    else {
      if (file.filesData[0]?.validationMessages?.maxSize) {
        file.filesData[0].validationMessages.maxSize = 'File size must be less than 10 MB'
        file.filesData[0].status = 'File size must be less than 10 MB'
      }

      setUploadStatus(true)
    }

    setFileData(file)
  }
  const removingfile = (file) => {
    setFileData(null)
  }
  const closeDilog = () => {
    setDialogOpen(false)
    setFileData(null)
  }
  // input for createFileUpload
  const fileUploadsave = async () => {
    setIsSubmitfileupload(true)

    if (!category || uploadStatus || !fileData) return

    const inputdata = {
      user_id: currentUserdata.data?.currentUser?.id,
      student_id: Number(stud_id),
      categories: Number(category),
      created_by: currentUserdata.data?.currentUser?.id,
      updated_by: currentUserdata.data?.currentUser.id,
      upload: fileData?.filesData[0].rawFile
    }
    await signalFileUploadInput({ variables: { input: inputdata } })
    closeDilog()
    refetch()
  }

  return (
    <div>
      {(categoryListLoading || fileloading || deletefileloading || uploadfileloading) && (
        <ProgressIndicator fullHeight />
      )}
      <Grid container>
        <div className={classes.namecard}>
          <div>
            <Avatar style={{ float: 'left' }} className={classes.avatar}>
              {studentDetails &&
                studentDetails?.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
            </Avatar>
            <div style={{ float: 'left', padding: '10px' }}>
              {studentDetails && (
                <>
                  <span style={{ fontWeight: 'bold', color: '#F1583A', fontSize: '22px' }}>
                    {studentDetails?.name}
                  </span>
                  <div>
                    {moment().diff(moment(studentDetails?.birth_date), 'years', false)} years old
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Grid item md={3} className={classes.docbuttonsalignStyle}>
          <div className={classes.sessionstyl}>
            <div>
              <p className={classes.blueback}>
                Total Sessions : <strong className={classes.bluelabel}>{count}</strong>
              </p>
            </div>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.straigh} />
          </Grid>
        </Grid>
        <Grid container>
          <h6 className="timeZoneTxt">
            <span>All times shown in Mountain(MT) </span> Hawaiian Time = -3 hrs, Alaskan Time = -2
            hrs, Pacific Time = -1 hr, Central Time = +1 hr, Eastern Time = +2 hrs
          </h6>
        </Grid>
        <Grid>
          <CollapsibleTable totalsession={totalsession} />
          <div
            style={{
              color: theme.palette.info.main,
              fontFamily: 'Roboto'
            }}
          >
            <h3 style={{ marginLeft: '45px' }}>Mandatory Disclosure Form</h3>
          </div>
          <CollapsibleTable2 />
        </Grid>
        <Grid>
          <div
            style={{
              color: theme.palette.info.main,
              fontFamily: 'Roboto'
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={6}>
                <h3 style={{ marginLeft: '45px' }}>Files</h3>
              </Grid>
              <Grid item md={6} style={{ textAlign: 'end', paddingRight: '50px' }}>
                <Button
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // className="mar-rt-15 "
                  variant="contained"
                  size="medium"
                  style={{
                    padding: '6px 25px',
                    borderRadius: 25,
                    marginTop: '20px'
                  }}
                  onClick={handleClickOpen}
                >
                  Upload File
                </Button>
                {categoryListData && currentUserdata && (
                  <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={dialogOpen}
                    onClose={closeDilog}
                    disableBackdropClick
                  >
                    <DialogTitle
                      className={`${classes.noteModaltitle} ${classes.brandonFF} ${classes.aligncenter}`}
                    >
                      Upload File
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        <InputLabel
                          className={`${classes.ctext} ${
                            !category && isSubmitfileupload ? classes.errorcol : ''
                          }`}
                        >
                          Category*
                        </InputLabel>
                        {categoryListData ? (
                          <Select
                            variant="outlined"
                            className={classes.sx}
                            labelId="demo-customized-select-label"
                            id="label-age"
                            name="area_of_need"
                            onChange={handeChange}
                          >
                            {categoryListData?.myCategories?.map((dropDown) => (
                              <MenuItem value={dropDown.id?.toString()} key={dropDown.id}>
                                {dropDown?.categories}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            id="label-county"
                            labelId="label-county"
                            variant="filled"
                            name="area_of_need"
                          >
                            <MenuItem>No Data Found</MenuItem>
                          </Select>
                        )}
                      </div>
                      <div className={classes.fullwidth}>
                        <InputLabel
                          className={`${classes.ctext} ${
                            (!fileData || uploadStatus) && isSubmitfileupload
                              ? classes.errorcol
                              : ''
                          }`}
                        >
                          Upload File*
                        </InputLabel>
                        <div style={{ paddingBottom: '6px', color: 'red' }}>
                          (Pdf documents only accepted)
                        </div>
                        <FormControl variant="filled" fullWidth>
                          <div className={`${classes.uploaderstyle}`}>
                            <UploaderComponent
                              ref={uploadObj}
                              id="fileUpload"
                              type="file"
                              maxFileSize={10000000}
                              allowedExtensions=".pdf"
                              multiple={false}
                              change={(file) => fileupload(file)}
                              showFileList
                              autoUpload={false}
                              selected={(file) => fileupload(file)}
                              removing={(file) => removingfile(file)}
                            />
                          </div>
                        </FormControl>
                      </div>
                      <div className={classes.dmar}>
                        <Button
                          color="primary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          className={classes.div}
                          variant="contained"
                          size="medium"
                          style={{
                            padding: '6px 25px',
                            borderRadius: 25
                          }}
                          onClick={() => fileUploadsave()}
                          // disabled={uploadStatus}
                        >
                          Upload
                        </Button>
                        <Button
                          color="primary"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          className={classes.sx1}
                          // variant="contained"
                          // size="medium"
                          // style={{
                          //   padding: '6px 25px',
                          //   borderRadius: 25
                          // }}
                          onClick={closeDilog}
                        >
                          Cancel
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </Grid>
            </Grid>
          </div>
          {filedata && (
            <CollapsibleTable3
              filedata={filedata}
              onDeleteuploadfile={onDeleteuploadfile}
              setisconfirmmodalopen={setisconfirmmodalopen}
              isconfirmmodalopen={isconfirmmodalopen}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
