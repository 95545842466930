import React from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { Typography, Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './Styles'
import './custom.css'
// import cdhLogo from '../../../../assets/illustrations/colorado_img.png'

interface FooterProps {
  ispublic?: boolean
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { ispublic } = props

  return (
    <Box className={`${classes.root} ${ispublic ? classes.footerPublic : classes.footerPrivate}`}>
      {/* <Typography align="right" variant="body2">
        Do you have a bug to report or suggestions for new features? <a href="/">Contact us</a>
      </Typography> */}
      <Grid container>
        <Grid item style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          {/* <TooltipComponent
            content={`${t(
              'teleteachers.teletext'
            )}</br></br><a style="color: white" href="https://teleteachers.com/" target="_blank">Teleteachers</a>`}
            position="TopCenter"
            // opensOn="Click"
            isSticky
            cssClass="customtip"
          > */}
          <Typography variant="body2">
            <a className={`${classes.cursor} ${classes.pbtcolor}`}>
              {t('parentsurveypage.footer')}
            </a>
          </Typography>
          {/* </TooltipComponent> */}
        </Grid>
        <Grid item style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
          {/* <div>
            <a href="https://cdhs.colorado.gov/behavioral-health" target="_blank" rel="noreferrer">
              <div className={classes.footerbg} />
            </a>
          </div> */}
          <div>
            <a
              href="https://teleteachers.com/behavioural-health-platform.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className={classes.footerccsbg} />
            </a>
          </div>
        </Grid>
      </Grid>
      {/* <img src={cdhLogo} alt="CDHS logo" /> */}
    </Box>
  )
}

export default Footer
