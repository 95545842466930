import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Typography,
  Divider,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControl,
  FormControlLabel,
  Container,
  RadioGroup,
  Radio,
  FormHelperText
} from '@material-ui/core'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { TextField, Select, DatePicker, makeValidate, Radios } from 'mui-rff'
import * as yup from 'yup'
import { Form, FormRenderProps } from 'react-final-form'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import InputBase from '@material-ui/core/InputBase'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import { Urlservice } from '../../../../views/public/serviceHelpers/urlhelper'
import { RegistrationNewFormModel } from './RegistrationFormModel'
import ProgressIndicator from '../../panel/progress-indicator/ProgressIndicator'

interface RegistrationFormNextProps {
  nextStep: () => void
  prevStep: () => void
  initialValues?: Partial<RegistrationNewFormModel> | null
  values: any
  handleChange: (input: any) => any
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3.5)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase)
const RegistrationValidator = () =>
  makeValidate(
    yup.object<RegistrationNewFormModel>({
      id: yup.mixed<number>().label(''),
      last_name: yup.mixed<string>().label('Last Name'),
      first_name: yup.mixed<string>().label('First Name'),
      surveyid: yup.mixed<string>(),
      contact_phone_number: yup.mixed<string>().label('Phone Number'),
      birth_date: yup.date().label('DOB').nullable().required(),
      gender: yup.mixed<string>().label('Gender'),
      parent1_phone_number: yup.mixed<string>().label('Parent #1 Number'),
      parent2_phone_number: yup.mixed<string>().label('Parent #2 Number'),
      parent1_email: yup.mixed<string>().label('Parent/ Guardian/ Responsible Party #1 Email'),
      parent2_email: yup.mixed<string>().label('Parent/ Guardian/ Responsible Party #2 Email'),
      middle_name: yup.mixed<string>().label('Middle Name'),
      pronouns: yup.mixed<number>().label('Pronouns'),
      contact_email: yup.string().email().label('Email address').nullable().required(),
      county: yup.number().label('county').required(),
      address: yup.string().label('Address').nullable().required(),
      parent_name: yup.mixed<string>().label('​Parent/ Guardian/ Responsible Party #1 Name'),
      parent2_name: yup.mixed<string>().label('Parent/ Guardian/ Responsible Party #2 Name'),
      accommodations: yup.mixed<string>().label('Accommodations'),
      area_of_need: yup
        .array<any[]>()
        .label('Area of need')
        .min(1, ' ')
        .required(`${t('errormessages.Area')}`),
      insurance_type: yup.mixed<number>().label('Type'),
      insurance_relationship: yup.mixed<number>().label('Relationship'),
      insurance_company: yup.mixed<number>().label('Insurance'),
      relationship_youth: yup.mixed<number>().label('relationship'),
      city: yup.mixed<string>().label('county'),
      zip: yup.mixed<number>().label('county'),
      session_service: yup
        .mixed<number>()
        .label('Services')
        .required(`${t('errormessages.services')}`),
      primaryLanguage_id: yup
        .mixed<number>()
        .label('Language')
        .required(`${t('errormessages.language')}`),
      phonecheck: yup.mixed<any>().label('phonecheck'),
      parentphonecheck1: yup.mixed<any>().label('parentphonecheck1'),
      parentphonecheck2: yup.mixed<any>().label('parentphonecheck2')
    })
  )

export const RegistrationFormNext = (props: RegistrationFormNextProps) => {
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  const [serviceError, setServiceError] = useState(false)
  const { nextStep, handleChange, values, prevStep, initialValues } = props
  const classes = useStyles()
  const history = useHistory()
  const inPerson = ' I would like my session in person '
  const teleHealth = 'I would like my session online'
  const [surveyStudentData, setSurveyStudentData] = useState<any>(values)
  const [dropDownData, setDropDownData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const initialData: RegistrationNewFormModel | null = values
    ? {
        id: values.id,
        surveyid: values.surveyid,
        // student_id: number
        first_name: values.first_name,
        last_name: values.last_name,
        // district_id: number
        // school_id: number
        // icdCode_id: number
        // grade_id: number
        primaryLanguage_id:
          typeof values?.county === 'object'
            ? values?.primaryLanguage_id?.id
              ? values?.primaryLanguage_id?.id
              : null
            : values.primaryLanguage_id,
        contact_phone_number: values.contact_phone_number,
        contact_email: values.contact_email,
        birth_date: values.birth_date,
        gender: values.gender,
        parent1_phone_number: values.parent1_phone_number,
        parent1_email: values.parent1_email,
        phonecheck: values.phonecheck,
        parentphonecheck1: values.parentphonecheck1,
        parentphonecheck2: values.parentphonecheck2,
        middle_name: values.middle_name,
        pronouns: values.pronouns,
        address: values.address,
        // student_gender: null
        county: values?.county?.id ? values?.county?.id : values?.county,
        accommodations: values.accommodations,
        area_of_need:
          values?.area_of_need?.length > 0
            ? typeof values.area_of_need?.[0] === 'object'
              ? values.area_of_need?.map((itm) => {
                  return itm.id
                })
              : values.area_of_need
            : undefined,
        insurance_type: values.insurance_type,
        insurance_relationship: values?.insurance_relationship?.id
          ? values?.insurance_relationship?.id
          : values?.insurance_relationship,
        insurance_company: values?.insurance_company?.id
          ? values?.insurance_company?.id
          : values?.insurance_company,
        relationship_youth: values?.relationship_youth?.id
          ? values?.relationship_youth?.id
          : values?.relationship_youth,
        city: values.city,
        zip: values.zip,
        session_service:
          typeof values.session_service === 'object'
            ? values?.session_service?.id
              ? values?.session_service?.id?.toString()
              : null
            : values.session_service.toString(),
        // typeof values.session_service === 'object'
        //   ? values?.session_service?.id
        //     ? values?.session_service?.id.toString()
        //     : '1'
        //   : values?.session_service.toString()
        //   ? values?.session_service.toString()
        //   : '1',
        // systemtime: Date
        parent_name: values.parent_name,
        parent2_name: values.parent2_name,
        parent2_email: values.parent2_email,
        parent2_phone_number: values.parent2_phone_number
      }
    : null
  const [value, setValue] = useState<any>(initialData?.session_service)
  const nextHandler = (e) => {
    // if (values.session_service === 'object' ? e?.session_service?.id : e?.session_service) {
    setLoading(true)
    const submitData = {
      ...e,
      primaryLanguage_id: dropDownData?.signal_language.filter(
        (coun) => coun.id === e.primaryLanguage_id
      )?.[0],
      // relationship_youth: values?.relationship_youth.filter(
      //   (coun) => coun.id === e.relationship_youth
      // )?.[0],
      county: dropDownData?.signal_county.filter((coun) => coun.id === e.county)?.[0],
      session_service: dropDownData?.signal_service.filter(
        (coun) => Number(coun.id) === Number(e.session_service)
      )?.[0],
      area_of_need: e.area_of_need.map((id) => {
        return dropDownData?.signal_area_of_need.filter((coun) => coun.id === id)?.[0]
      }),
      insurance_company: dropDownData?.signal_insurance_company.filter(
        (coun) => coun.id === e.insurance_company
      )?.[0]
    }
    Urlservice.posturl('signal/signalstudentdata', submitData)
      .then((surveyData) => {
        handleChange(surveyData.data)
        setLoading(false)

        if (e) nextStep()

        setSurveyStudentData(surveyData.data)
      })
      .catch((error) => {
        console.log(error)
      })
    // } else setServiceError(true)
  }
  const cancelHandler = () => {
    history.push(`/public/register`)
  }
  const studentdata = React.useCallback(() => {
    Urlservice.geturl('signal/studentdata')
      .then((ques) => {
        setDropDownData(ques.data)
      })
      .catch((error) => {
        console.log(error)
      })
    /* function body */
  }, [])
  const surveyIdData = React.useCallback(() => {
    Urlservice.posturl('signal/signalstudentdata', values)
      .then((surveyData) => {
        setSurveyStudentData(surveyData.data)
      })
      .catch((error) => {
        console.log(error)
      })
    /* function body */
  }, [values])
  useEffect(() => {
    studentdata()
  }, [studentdata])
  // useEffect(() => {
  //   surveyIdData()
  // }, [surveyIdData])
  const validator = useMemo(() => RegistrationValidator(), [])
  const renderForm = (renderProps: FormRenderProps<RegistrationNewFormModel>) => {
    const { handleSubmit, values } = renderProps
    const prevHandler = (e) => {
      e.preventDefault()
      handleChange(values)
      prevStep()
    }
    const handleChangeRadio = (event) => {
      // setValue(event.target.value)
      renderProps.values.session_service = event.target.value
    }

    return (
      <form style={{ width: '100%' }}>
        {/* First Row */}
        {loading && <ProgressIndicator fullHeight />}

        <Grid container sm={4}>
          <Box mt={2} width="1" className={classes.containerWidth}>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <InputLabel id="demo-customized-select-label" htmlFor="label-county">
                  {t('Registration.language')}
                </InputLabel>
                {dropDownData?.signal_language && dropDownData?.signal_language?.length > 0 ? (
                  <Select
                    labelId="demo-customized-select-label"
                    id="label-county"
                    className={`${classes.inputField} ${classes.formFeild}`}
                    input={<BootstrapInput />}
                    name="primaryLanguage_id"
                    // onChange={(value) => updateFormData('primaryLanguage_id', value.target.value)}
                  >
                    {dropDownData?.signal_language?.map((dropDown) => {
                      return (
                        <MenuItem value={dropDown.id}>
                          {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                ) : (
                  <Select
                    id="label-county"
                    labelId="label-county"
                    input={<BootstrapInput />}
                    variant="filled"
                    name="pronouns"
                    className={classes.formFeild}
                  >
                    <MenuItem>No Data Found</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Box>
        </Grid>
        <Grid container direction="row" spacing={1} className={classes.containerWidth}>
          <Grid item sm={6}>
            <Box mt={2} width="1" className={classes.radio}>
              {/* <label style={{ display: 'grid' }} htmlFor="roles"> */}
              {/* <div style={{ color: 'lightslategray' }}>Service *</div> */}
              <InputLabel id="demo-customized-select-label" htmlFor="session_service">
                {t('Registration.service')}
              </InputLabel>
              <Radios
                // label="Pick one..."
                name="session_service"
                required
                data={[
                  { label: `${t('Registration.online')}`, value: '2' },
                  { label: `${t('Registration.inperson')}`, value: '1' }
                ]}
              />
              {/* {dropDownData?.signal_service?.map((item, i) => (
              <div>
                {item.id !== 3 && (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={i} style={{ marginBottom: '7px', fontSize: '15px' }}>
                    <input
                      type="radio"
                      name="session_service"
                      value={item.id as string}
                      onChange={handleChangeRadio}
                      checked={
                        Number(item.id) === Number(value) ||
                        Number(initialData?.session_service) === Number(item.id)
                      }
                      required
                    />
                    {item.name}
                  </span>
                )}
              </div>
            ))} */}
            </Box>
            {/* </label> */}
          </Grid>

          <Grid item sm={6}>
            <p style={{ padding: '10px' }}> {t('Registration.note')}</p>
          </Grid>
        </Grid>
        {/* {serviceError && ( */}
        {/* )} */}

        {/* Fourth Row */}
        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <InputLabel id="demo-customized-select-label" htmlFor="label-county">
                  {t('Registration.requests')}
                </InputLabel>
                <TextField
                  className={`${classes.inputField} ${classes.formFeild} ${classes.labelTxt}`}
                  id="label-phone"
                  fullWidth
                  variant="outlined"
                  name="accommodations"
                  // onChange={(value) => updateFormData('accommodations', value.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Fifth Row */}
        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <InputLabel id="demo-customized-select-label" htmlFor="label-age">
                  {t('Registration.area')}
                </InputLabel>
                {dropDownData?.signal_county && dropDownData?.signal_county?.length > 0 ? (
                  <Select
                    labelId="demo-customized-select-label"
                    id="label-age"
                    className={classes.formFeild}
                    input={<BootstrapInput />}
                    name="area_of_need"
                    multiple
                    // onChange={(value) => updateFormData('area_of_need', value.target.value)}
                  >
                    {dropDownData?.signal_area_of_need?.map((dropDown) => {
                      return (
                        <MenuItem value={dropDown.id}>
                          {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                ) : (
                  <Select
                    id="label-county"
                    labelId="label-county"
                    input={<BootstrapInput />}
                    variant="filled"
                    name="area_of_need"
                    className={classes.formFeild}
                  >
                    <MenuItem>No Data Found</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* sixth Row */}

        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            {/* <Grid item sm={5}>
              <FormControl>
                <InputLabel id="demo-customized-select-label" htmlFor="label-county">
                  Type
                </InputLabel>
                {dropDownData?.signal_county && dropDownData?.signal_county?.length > 0 ? (
                  <Select
                    labelId="demo-customized-select-label"
                    id="label-county"
                    className={classes.formFeild}
                    input={<BootstrapInput />}
                    name="insurance_type"
                    // onChange={(value) => updateFormData('insurance_type', value.target.value)}
                  >
                    {dropDownData?.signal_insurance_type?.map((dropDown) => {
                      return <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>
                    })}
                  </Select>
                ) : (
                  <Select
                    id="label-county"
                    labelId="label-county"
                    input={<BootstrapInput />}
                    variant="filled"
                    name="pronouns"
                    className={classes.formFeild}
                  >
                    <MenuItem>No Data Found</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <FormControl>
                <InputLabel id="demo-customized-select-label" htmlFor="label-county">
                  Relationship
                </InputLabel>
                {dropDownData?.signal_county && dropDownData?.signal_county?.length > 0 ? (
                  <Select
                    labelId="demo-customized-select-label"
                    id="label-county"
                    className={classes.formFeild}
                    input={<BootstrapInput />}
                    name="insurance_relationship"
                    // onChange={(value) => updateFormData('insurance_relationship', value.target.value)}
                  >
                    {dropDownData?.signal_insurance_relationship?.map((dropDown) => {
                      return <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>
                    })}
                  </Select>
                ) : (
                  <Select
                    id="label-county"
                    labelId="label-county"
                    input={<BootstrapInput />}
                    variant="filled"
                    name="pronouns"
                    className={classes.formFeild}
                  >
                    <MenuItem>No Data Found</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
            */}
            <Grid item xs={12} sm={12}>
              <InputLabel htmlFor="label-city" className={classes.inputLabel}>
                {t('Registration.note1')}
              </InputLabel>
              <FormControl>
                {/* <InputLabel id="demo-customized-select-label" htmlFor="label-county">
                  Insurance
                </InputLabel> */}
                {dropDownData?.signal_county && dropDownData?.signal_county?.length > 0 ? (
                  <Select
                    labelId="demo-customized-select-label"
                    id="label-county"
                    className={classes.formFeild}
                    input={<BootstrapInput />}
                    name="insurance_company"
                    style={{ marginTop: '0px' }}

                    // onChange={(value) => updateFormData('insurance_company', value.target.value)}
                  >
                    {dropDownData?.signal_insurance_company?.map((dropDown) => {
                      return (
                        <MenuItem value={dropDown.id}>
                          {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                ) : (
                  <Select
                    id="label-county"
                    labelId="label-county"
                    input={<BootstrapInput />}
                    variant="filled"
                    name="pronouns"
                    className={classes.formFeild}
                  >
                    <MenuItem>No Data Found</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Seventh Row */}
        <Box mb={2}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={12}>
              <Button
                variant="outlined"
                onClick={prevHandler}
                startIcon={<NavigateBeforeIcon>Next</NavigateBeforeIcon>}
                className={classes.customBtnCls}
              >
                {t('registrationform.back')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                // endIcon={<NavigateNextIcon>Next</NavigateNextIcon>}
                className={`${classes.customBtnCls} ${classes.registrationNextStep} ${classes.ml20}`}
                onClick={handleSubmit}
                style={{ backgroundColor: '#52b7ec' }}
                // onClick={hanSubmit}
              >
                {t('registrationform.next')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    )
  }

  return (
    <Form<RegistrationNewFormModel>
      initialValues={initialData ?? {}}
      validate={validator}
      render={renderForm}
      // onSubmit={onSubmit}
      onSubmit={nextHandler}
      // decorators={[calculator]}
    />
  )
}
