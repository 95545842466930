import React, { useState, useEffect, useMemo } from 'react'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Typography,
  Divider,
  MenuItem,
  InputLabel,
  Container,
  FormControl,
  Tooltip,
  Paper,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import { TextField, Select, DatePicker, makeValidate, Checkboxes, CheckboxData } from 'mui-rff'
import { Form, FormRenderProps } from 'react-final-form'
import * as yup from 'yup'
import { Urlservice } from 'views/public/serviceHelpers/urlhelper'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Avatar from '@material-ui/core/Avatar'
import {
  useListStudentsProfileQuery,
  useUpdateStudentProfileMutation
} from 'graphql/hooks.generated'
import { UseParamsType } from 'types'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { PhoneInput } from 'components/ui/form/phone-input/PhoneInput'
import {
  ReactComponent as StudentIcon,
  ReactComponent as ContactIcon
} from '../../../../../assets/icons/student.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg'
import { ReactComponent as SessionIcon } from '../../../../../assets/icons/session.svg'
import { useStyles } from '../../../../../config/constants/theme/studentprofile'

interface StudentProfileModal {
  fname: string
  lname: string
  dob: string
  gender: string
  email: string
  phone: string
  parentEmail1: string | null | undefined
  parentPhone1: string
  parentName1: string | null | undefined
  parentEmail2: string | null | undefined
  parentPhone2: string | null | undefined
  parentName2: string
  pronouns: number
  clientId: string | null | undefined
  phonecheck: boolean
  parentphonecheck1: boolean
  parentphonecheck2: boolean
  // Insurance: string
}
// interface Parentparams {
//   setuseridHandler: (studentDetails) => unknown
// }
interface ListStudentViewParams extends UseParamsType {
  stu_id: string
}
const profileValidator = () =>
  makeValidate(
    yup.object<StudentProfileModal>({
      clientId: yup.mixed<string>().label('Client Id'),
      fname: yup.mixed<string>().label('First Name').required(),
      lname: yup.mixed<string>().label('Last Name').required(),
      dob: yup.string().label('D.O.B').nullable().required('Date of Birth is a required field'),
      gender: yup.mixed<string>().label('Gender').nullable().required('Gender is a required field'),
      pronouns: yup.mixed<number>().label('Pronouns').required('Pronouns is a required field'),
      phone: yup
        .mixed<string>()
        .label('Phone')
        .required()
        .test('len', 'Phone number must be 10 digits', (val) =>
          val ? !(val?.toString().length > 1 && val?.toString().length <= 10) : true
        ),
      email: yup
        .string()
        .email('Email address must be a valid email')
        .label('Email')
        .required('Email is a required field'),
      parentPhone1: yup
        .mixed<string>()
        .label('Parent1 Phone')
        .test('len', 'Phone number must be 10 digits', (val) =>
          val ? !(val?.toString().length > 1 && val?.toString().length <= 10) : true
        ),
      parentEmail1: yup
        .string()
        .email('Parent1 Email address must be a valid email')
        .nullable()
        .notRequired()
        .label('Parent1 Email')
        .when('dob', (dob, parentEmailSchema: yup.StringSchema) => {
          const studentAge = moment().diff(dob, 'years', false)

          if (studentAge < 12)
            return parentEmailSchema.required('Parent1 email is a required field')

          return parentEmailSchema
        }),
      parentName1: yup
        .string()
        .nullable()
        .notRequired()
        .label('​Parent1 Name')
        .when('dob', (dob, parentSchema: yup.StringSchema) => {
          const studentAge = moment().diff(dob, 'years', false)

          if (studentAge < 12) return parentSchema.required('Parent1 name is a required field')

          return parentSchema
        }),
      parentEmail2: yup
        .string()
        .email('Parent2 Email address must be a valid email')
        .nullable()
        .notRequired()
        .label('Parent2 Email'),
      parentName2: yup.mixed<string>().label('Parent2 Name'),
      parentPhone2: yup
        .string()
        .label('Parent2 Phone')
        .test('len', 'Phone number must be 10 digits', (val) =>
          val ? !(val?.toString().length > 1 && val?.toString().length <= 10) : true
        ),
      phonecheck: yup.mixed<any>().label('phonecheck'),
      parentphonecheck1: yup.mixed<any>().label('parentphonecheck1'),
      parentphonecheck2: yup.mixed<any>().label('parentphonecheck2')
    })
  )
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3.5)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      // border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase)

export const StudentProfileForm = () => {
  const classes = useStyles()
  // const { setuseridHandler } = props
  const [isInEditMode, setisInEditMode] = useState(false)
  const [dropDownData, setDropDownData] = useState<any>({})
  const [updateStudentQuery, { loading: updateloading }] = useUpdateStudentProfileMutation()
  const updateStuFetch = useMemo(
    () => ({ id, input }) => updateStudentQuery({ variables: { id, input } }),
    [updateStudentQuery]
  )
  const errorObj = {
    totalminutes: '',
    plannedminutes: '',
    parent1_email: '',
    parent2_email: '',
    parent1_phone_number: '',
    parent2_phone_number: ''
  }
  const { stu_id } = useParams<ListStudentViewParams>()
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const initialData = {
    fname: '',
    lname: '',
    dob: '',
    gender: '',
    email: '',
    phone: '',
    parentName1: '',
    parentPhone1: '',
    parentEmail1: '',
    parentName2: '',
    parentPhone2: '',
    parentEmail2: '',
    clientId: '',
    pronouns: -1,
    phonecheck: false,
    parentphonecheck1: false,
    parentphonecheck2: false
  }
  const phonecheckLabel =
    'The provided number is a mobile number and I agree to receive appointment reminders via text at this number'
  const checkboxData: CheckboxData[] = [{ label: phonecheckLabel, value: true }]
  const checkboxData1: CheckboxData[] = [{ label: phonecheckLabel, value: true }]
  const checkboxData2: CheckboxData[] = [{ label: phonecheckLabel, value: true }]
  const [formValues, setFormValues] = useState(initialData)
  const { enqueueSnackbar } = useSnackbar()
  let studentobj
  const { data, loading, refetch } = useListStudentsProfileQuery({
    fetchPolicy: 'network-only',
    variables: { id: Number(stud_id) }
  })

  if (data) studentobj = data?.studentsProfile?.find(() => true)

  const validator = useMemo(() => profileValidator(), [])
  // useEffect(() => {
  //   if (data) {
  //     const stuobj = data?.studentsProfile?.find(() => true)
  //     setuseridHandler(stuobj)
  //     // setdobDate(moment(stuobj?.birth_date, 'YYYY-MM-DD').format())
  //   }
  // }, [data, setuseridHandler])
  const updateStudent = async (id, values, isNewEmail) => {
    // update student
    const inputdata = {
      firstName: values.fname,
      lastName: values.lname,
      birth_date: moment(values.dob).format('YYYY-MM-DD'),
      gender: values.gender,
      pronouns: values.pronouns,
      contact_phone_number: values.phone,
      parent1_email: values?.parentEmail1 || '',
      parent1_phone_number: values?.parentPhone1 || '',
      parent_name: values?.parentName1 || '',
      parent2_email: values.parentEmail2 || '',
      parent2_phone_number: values?.parentPhone2 || '',
      parent2_name: values?.parentName2 || '',
      phonecheck: values.phonecheck === true ? 1 : 0,
      parentphonecheck1: values.parentphonecheck1 === true ? 1 : 0,
      parentphonecheck2: values.parentphonecheck2 === true ? 1 : 0
    }
    let inputdataNew

    if (isNewEmail) inputdataNew = { ...inputdata, contact_email: values.email }
    else inputdataNew = { ...inputdata }

    const payloaddata = { id: Number(id), input: inputdataNew }
    await updateStuFetch(payloaddata)
    refetch()
    setisInEditMode(!isInEditMode)
  }
  const formSubmit = (values) => {
    const stuObj = data?.studentsProfile?.find(() => true)

    if (values.email !== stuObj?.contact_email)
      Urlservice.posturl('signal/validationcheck', { contact_email: values.email })
        .then((validMail) => {
          if (validMail?.data !== 0) enqueueSnackbar('Email Already Exists', { variant: 'error' })
          else updateStudent(stuObj?.id, values, true)
        })
        .catch((error) => {
          console.log(error)

          return true
        })
    else updateStudent(stuObj?.id, values, false)
  }
  const studentdata = React.useCallback(() => {
    Urlservice.geturl('signal/studentdata')
      .then((ques) => {
        setDropDownData(ques.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    studentdata()
  }, [studentdata])
  const defaultView = () => {
    setisInEditMode(!isInEditMode)
  }
  const changeEditMode = () => {
    setisInEditMode(!isInEditMode)
    const formObj = { ...formValues }
    data?.studentsProfile?.map((e) => {
      formObj.fname = e?.first_name || ''
      formObj.lname = e?.last_name || ''
      formObj.dob = e?.birth_date ? moment(e?.birth_date, 'YYYY-MM-DD').format() : ''
      formObj.gender = e?.gender || ''
      formObj.pronouns = e?.pronouns?.id || -1
      formObj.email = e?.contact_email || ''
      formObj.phone = e?.contact_phone_number || ''
      formObj.parentEmail1 = e?.parent1_email || ''
      formObj.parentPhone1 = e?.parent1_phone_number || ''
      formObj.parentName1 = e?.parent_name || ''
      formObj.parentEmail2 = e?.parent2_email || ''
      formObj.parentPhone2 = e?.parent2_phone_number || ''
      formObj.parentName2 = e?.parent2_name || ''
      formObj.phonecheck = e?.phonecheck || false
      formObj.parentphonecheck1 = e?.parentphonecheck1 || false
      formObj.parentphonecheck2 = e?.parentphonecheck2 || false

      return true
    })
    setFormValues(formObj)
  }
  const emailCheck = React.useCallback(
    (value) => {
      const oldEmail = data?.studentsProfile?.find(() => true)

      if (value?.target?.value !== oldEmail?.contact_email)
        Urlservice.posturl('signal/validationcheck', { contact_email: value?.target?.value })
          .then((validMail) => {
            if (validMail?.data !== 0) {
              enqueueSnackbar('Email Already Exists', { variant: 'error' })

              return 0
            }

            return 1
          })
          .catch((error) => {
            console.log(error)

            return true
          })
    },
    [enqueueSnackbar, data]
  )
  const renderForm = (renderProps: FormRenderProps<StudentProfileModal>) => {
    const { handleSubmit, values } = renderProps
    // console.log('values', values)
    let studentAge

    if (values.dob) studentAge = moment().diff(values.dob, 'years', false)

    return (
      <form>
        {(loading || updateloading) && <ProgressIndicator fullHeight />}
        <Box mb={2} mt={2}>
          <Grid container>
            <Grid item md={6} sm={6}>
              <div className={classes.namecard}>
                <div>
                  <Avatar style={{ float: 'left' }} className={classes.avatar}>
                    {studentobj?.name.split(/[\s]+/).map((value) => value.slice(0, 1))}
                  </Avatar>
                  <div style={{ float: 'left', padding: '10px' }}>
                    <span style={{ fontWeight: 'bold', color: '#F1583A', fontSize: '22px' }}>
                      {studentobj?.name}
                    </span>{' '}
                    <div>
                      {moment().diff(moment(studentobj?.birth_date), 'years', false)} years old
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6} sm={6}>
              <div className={classes.buttonadjust}>
                <Button
                  variant="contained"
                  disabled={isInEditMode}
                  onClick={changeEditMode}
                  className={classes.edit}
                >
                  <EditIcon />
                </Button>
              </div>
            </Grid>
            <Grid container style={{ display: 'flex', alignItems: 'stretch' }}>
              <Grid item sm={5} md={4} style={{ padding: '15px' }}>
                <div className={classes.allcards}>
                  <div className={classes.headersalign}>
                    <StudentIcon />
                    <h2 className={classes.studentheaders}>Registration form</h2>
                  </div>
                  <div style={{ padding: '5px 10px' }}>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Client Id:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.unique_profile_id ? studentobj?.unique_profile_id : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-fname"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              First Name
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-fname"
                            fullWidth
                            variant="outlined"
                            name="fname"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.first_name ? studentobj?.first_name : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-lname"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Last Name
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-flname"
                            fullWidth
                            variant="outlined"
                            name="lname"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.last_name ? studentobj?.last_name : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.dob} style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="datepicker"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              D.O.B
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <DatePicker
                            focused={false}
                            required
                            clearable
                            maxDate={new Date()}
                            name="dob"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            className={classes.inputPadding}
                            // className={classes.labelField}
                          />
                        ) : (
                          <>
                            {studentobj?.birth_date ? (
                              <div style={{ padding: '9px 9px' }}>
                                {moment(studentobj?.birth_date, 'YYYY-MM-DD').format('MM-DD-YYYY')}
                              </div>
                            ) : (
                              <div style={{ padding: '9px 9px' }}>---</div>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-age"
                              className={`${classes.studentlabels} ${classes.iBlock} ${
                                isInEditMode ? classes.mt20 : ''
                              }`}
                            >
                              Gender
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <Select
                            name="gender"
                            labelId="demo-customized-select-label"
                            id="label-age"
                            required
                            input={<BootstrapInput />}
                            className={classes.dropDown}
                            variant="filled"
                            // onChange={(value) => updateFormData('gender', value.target.value)}
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Non-binary/third gender">
                              Non-binary/ third gender
                            </MenuItem>
                            <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.gender ? studentobj?.gender : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-pronouns"
                              className={`${classes.studentlabels} ${classes.iBlock} ${
                                isInEditMode ? classes.mt20 : ''
                              }`}
                            >
                              Pronouns
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <>
                            {dropDownData?.signal_pronouns &&
                            dropDownData?.signal_pronouns?.length > 0 ? (
                              <Select
                                labelId="demo-customized-select-label"
                                id="label-pronouns"
                                required
                                input={<BootstrapInput />}
                                name="pronouns"
                                className={classes.dropDown}
                                // onChange={(value) => updateFormData('pronouns', value.target.value)}
                              >
                                {dropDownData?.signal_pronouns?.map((dropDown) => {
                                  return <MenuItem value={dropDown.id}>{dropDown?.name}</MenuItem>
                                })}
                              </Select>
                            ) : (
                              <Select
                                input={<BootstrapInput />}
                                variant="filled"
                                name="pronouns"
                                required
                                className={classes.dropDown}
                              >
                                <MenuItem>No Data Found</MenuItem>
                              </Select>
                            )}
                          </>
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.pronouns?.name ? studentobj?.pronouns?.name : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Address:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.address ? studentobj?.address : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>County:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.county?.name ? studentobj?.county?.name : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>City,State,Zip:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {`${studentobj?.city || ''} ${
                            studentobj?.city && studentobj?.state ? ',' : ''
                          } ${studentobj?.state || ''} ${
                            (studentobj?.city && !studentobj?.state) || studentobj?.state ? ',' : ''
                          } ${studentobj?.zip || ''} ${
                            !studentobj?.city && !studentobj?.state && !studentobj?.zip ? '---' : ''
                          }`}
                        </div>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item sm={5} md={4} style={{ padding: '15px' }}>
                <div className={classes.allcards}>
                  <div className={classes.headersalign}>
                    <ContactIcon />
                    <h2 className={classes.studentheaders}>Contact Details</h2>
                  </div>
                  <div style={{ padding: '5px 10px' }}>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-Email"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Email
                            </InputLabel>
                            {isInEditMode && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-Email"
                            fullWidth
                            variant="outlined"
                            name="email"
                            required
                            className={`${classes.inputPadding}`}
                            onBlur={(value) => emailCheck(value)}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px', wordBreak: 'break-word' }}>
                            {studentobj?.contact_email ? studentobj?.contact_email : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-phone"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Phone
                            </InputLabel>
                            {isInEditMode && <span>*</span>}{' '}
                            <b>
                              <span style={{ fontFamily: 'sans-serif' }}>#</span>:
                            </b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <PhoneInput
                            focused={false}
                            fullWidth
                            required
                            id="label-phone"
                            variant="outlined"
                            name="phone"
                            className={`${classes.phoneinputPadding} ${classes.phoneHelperTxt}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.contact_phone_number
                              ? studentobj?.contact_phone_number
                              : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={`${isInEditMode ? classes.pb10 : classes.pb5} ${classes.hintText}`}
                    >
                      {isInEditMode && (
                        <Checkboxes name="phonecheck" required data={checkboxData} />
                      )}
                      {!isInEditMode && studentobj && (
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              key={`${Math.floor(Math.random() * 100)}`}
                              checked={studentobj?.phonecheck}
                            />
                          }
                          label={phonecheckLabel}
                        />
                      )}
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-ParentName"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent1 Name
                            </InputLabel>
                            {isInEditMode && studentAge < 12 && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-ParentName"
                            fullWidth
                            variant="outlined"
                            name="parentName1"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.parent_name ? studentobj?.parent_name : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-parentphone"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent1 Phone
                            </InputLabel>{' '}
                            <b>
                              <span style={{ fontFamily: 'sans-serif' }}>#</span>:
                            </b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <PhoneInput
                            focused={false}
                            fullWidth
                            required
                            id="label-parentphone"
                            variant="outlined"
                            name="parentPhone1"
                            className={`${classes.phoneinputPadding} ${classes.phoneHelperTxt}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.parent1_phone_number
                              ? studentobj?.parent1_phone_number
                              : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={`${isInEditMode ? classes.pb10 : classes.pb5} ${classes.hintText}`}
                    >
                      {isInEditMode && (
                        <Checkboxes name="parentphonecheck1" required data={checkboxData1} />
                      )}
                      {!isInEditMode && studentobj && (
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              key={`${Math.floor(Math.random() * 100)}`}
                              checked={studentobj?.parentphonecheck1}
                            />
                          }
                          label={phonecheckLabel}
                        />
                      )}
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-ParentEmail"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent1 Email
                            </InputLabel>
                            {isInEditMode && studentAge < 12 && <span>*</span>}
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-ParentEmail"
                            fullWidth
                            variant="outlined"
                            name="parentEmail1"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px', wordBreak: 'break-word' }}>
                            {studentobj?.parent1_email ? studentobj?.parent1_email : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-Parent2Name"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent2 Name
                            </InputLabel>
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-Parent2Name"
                            fullWidth
                            variant="outlined"
                            name="parentName2"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.parent2_name ? studentobj?.parent2_name : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-parent2phone"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent2 Phone
                            </InputLabel>{' '}
                            <b>
                              <span style={{ fontFamily: 'sans-serif' }}>#</span>:
                            </b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <PhoneInput
                            focused={false}
                            fullWidth
                            required
                            id="label-parent2phone"
                            variant="outlined"
                            name="parentPhone2"
                            className={`${classes.phoneinputPadding} ${classes.phoneHelperTxt}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px' }}>
                            {studentobj?.parent2_phone_number
                              ? studentobj?.parent2_phone_number
                              : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={`${isInEditMode ? classes.pb10 : classes.pb5} ${classes.hintText}`}
                    >
                      {isInEditMode && (
                        <Checkboxes name="parentphonecheck2" required data={checkboxData2} />
                      )}
                      {!isInEditMode && studentobj && (
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              key={`${Math.floor(Math.random() * 100)}`}
                              checked={studentobj?.parentphonecheck2}
                            />
                          }
                          label={phonecheckLabel}
                        />
                      )}
                    </Grid>
                    <Grid container className={isInEditMode ? classes.pb10 : classes.pb5}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <InputLabel
                              htmlFor="label-Parent2Email"
                              className={`${classes.studentlabels} ${classes.iBlock}`}
                            >
                              Parent2 Email
                            </InputLabel>
                            <b>:</b>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        {isInEditMode ? (
                          <TextField
                            focused={false}
                            id="label-Parent2Email"
                            fullWidth
                            variant="outlined"
                            name="parentEmail2"
                            required
                            className={`${classes.inputPadding}`}
                          />
                        ) : (
                          <div style={{ padding: '9px 9px', wordBreak: 'break-word' }}>
                            {studentobj?.parent2_email ? studentobj?.parent2_email : '---'}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item sm={5} md={4} style={{ padding: '15px' }}>
                <div className={classes.allcards}>
                  <div className={classes.headersalign}>
                    <SessionIcon />
                    <h2 className={classes.studentheaders}>Preferences</h2>
                  </div>
                  <div style={{ padding: '5px 10px' }}>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Language:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.primaryLanguage?.name
                            ? studentobj?.primaryLanguage?.name
                            : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Service:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.session_service?.name
                            ? studentobj?.session_service?.name
                            : '---'}
                        </div>
                        {/* )} */}
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Service Requests:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.accommodations ? studentobj?.accommodations : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Area Of Need:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.student_specialities.length > 0
                            ? studentobj?.student_specialities?.map((sp, index) => (
                                <span>
                                  {' '}
                                  {sp?.speciality_id?.name}{' '}
                                  {studentobj?.student_specialities.length - 1 === index
                                    ? ''
                                    : ' ,'}{' '}
                                </span>
                              ))
                            : '---'}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: '5px' }}>
                      <Grid item md={5}>
                        <div className={classes.flex}>
                          <div className={classes.studentlabelsize1}>
                            <p className={classes.studentlabels}>Insurance:</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={7}>
                        <div style={{ padding: '9px 9px' }}>
                          {studentobj?.insurance_company?.name
                            ? studentobj?.insurance_company?.name
                            : '---'}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
            {isInEditMode && (
              <div className={classes.buttongroup}>
                <Button className={classes.cancel} onClick={defaultView}>
                  Cancel
                </Button>
                <Button className={classes.save} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            )}
          </Grid>
        </Box>
      </form>
    )

    // }
  }

  //   return renderEditView()
  return (
    <Form<StudentProfileModal>
      initialValues={formValues}
      validate={validator}
      render={renderForm}
      onSubmit={formSubmit}
    />
  )
}
