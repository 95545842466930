import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { Role } from 'graphql/hooks.generated'
import path from 'path'
import { DistrictForm } from './form/DistrictForm'
import ReportsTabsListView from './list/ReportsTabsListView'
import Groupaudit from './form/Groupaudit'
import Progressreport from './form/Progressreport'
import ProgressSummary from './form/ProgressSummary'
import DirectIndirect from './form/DirectIndirectReport'
import ServiceProviderMinutesreport from './form/ServiceProviderReport'
import { Billingreport } from './list/Billingreport'
import { PrivateRoute } from '../../../components/router/PrivateRoute'
import { DetailView } from '../therapist/details/DetailView'

export const ReportsRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/form`} component={DistrictForm} />
      <Route path={`${url}/student-progress-summary`} component={ProgressSummary} />
      <Route path={`${url}/group-audit`} component={Groupaudit} />
      <Route path={`${url}/progress-report`} component={Progressreport} />
      <Route path={`${url}/Direct-Indirect`} component={DirectIndirect} />
      <Route path={`${url}/Provider-Service-Minutes`} component={ServiceProviderMinutesreport} />
      <Route path={`${path}/:id/details`} component={DetailView} />
      <PrivateRoute
        roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
        path={`${url}/Billing-Report`}
        component={Billingreport}
      />
      <Route component={ReportsTabsListView} />
    </Switch>
  )
}
