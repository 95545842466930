import { Grid, InputLabel, Box } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React, { useEffect, useState, useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import * as yup from 'yup'
import { useClientFillingAttemptsMutation } from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { engLoc, spaLoc } from 'config/constants/translations'
import Button from '../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import { InfoButton } from '../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../components/ui/panel/section/Section'
import BackgroundImage from '../../../assets/illustrations/Mira_for_Mental_Health_Background.png'

export interface ClientFillingFormModel {
  subject: string
  notes: string
  token: string
}

interface ClientMessageParams extends UseParamsType {
  token: string
  language_id: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelField: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        backgroundColor: '#fff !important',
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-inputMultiline': {
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    contpadd: {
      padding: '0px 15px 0px 50px'
    },
    gridalign: {
      maxWidth: '700px',
      backgroundColor: '#FFFFFF',
      marginTop: '40px',
      marginLeft: '50px',
      marginBottom: '50px'
    },
    colBlak: {
      color: '#000',
      fontFamily: 'Brandon Grotesque !important'
    },
    iBlock: {
      display: 'inline-block',
      marginBottom: '10px'
    },
    errorCol: {
      color: '#f44336'
    },
    backgroundImage: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      minHeight: 'calc(100vh - 60px - 75px)'
    },
    labelError: {
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    }
  })
)
const notemaxErrMsg = 'Exceeded maximum 1000 characters'
const submaxErrMsg = 'Exceeded maximum 100 characters'
const clientFillingvalidator = () =>
  makeValidate(
    yup.object<ClientFillingFormModel>({
      subject: yup
        .string()
        .label('Subject')
        .required()
        .test('len', submaxErrMsg, (val) => {
          let flag = false

          if (val && val?.toString().length < 100) flag = true

          return flag
        }),
      notes: yup
        .string()
        .label('Message')
        .required()
        .test('len', notemaxErrMsg, (val) => {
          let flag = false

          if (val && val?.toString().length < 1000) flag = true

          return flag
        }),
      token: yup.string()
    })
  )

export const ClientFillingForm = () => {
  const history = useHistory()
  const classes = useStyles()
  const { token, language_id } = useParams<ClientMessageParams>()
  const validator = useMemo(() => clientFillingvalidator(), [])
  const [complete, setComplete] = useState(false)
  const [clientFilling, { loading }] = useClientFillingAttemptsMutation()
  let langcode

  if (language_id)
    if (language_id === '1') langcode = 'en'
    else langcode = 'es'
  else langcode = 'en'

  //   const onSubmit = (values: ClientFillingFormModel) => props.onSubmit(values)
  const handleFormSubmit = async (values: ClientFillingFormModel) => {
    clientFilling({
      variables: {
        input: {
          subject: values.subject,
          notes: values.notes,
          token
        }
      }
    })
    setComplete(true)
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const renderForm = (renderProps: FormRenderProps<ClientFillingFormModel>) => {
    const { handleSubmit, errors, submitFailed } = renderProps

    return (
      <form>
        <Grid container className={classes.backgroundImage}>
          {complete === false ? (
            <Box>
              <Grid container spacing={4} className={classes.gridalign}>
                <Grid
                  item
                  sm={12}
                  style={{
                    paddingTop: 20,
                    paddingLeft: '65px',
                    paddingBottom: '0px',
                    fontSize: '18px'
                  }}
                >
                  {langcode === 'en'
                    ? engLoc.clientmessage.headerTxt
                    : spaLoc.clientmessage.headerTxt}
                </Grid>
                <Grid item sm={12}>
                  <Grid
                    container
                    spacing={3}
                    className={`${classes.contpadd} ${classes.labelError}`}
                  >
                    <Grid item sm={11}>
                      <div
                        className={`${
                          submitFailed && errors?.subject?.length
                            ? classes.errorCol
                            : classes.colBlak
                        }`}
                      >
                        <InputLabel
                          htmlFor="subject"
                          className={`${
                            submitFailed && errors?.subject?.length
                              ? classes.errorCol
                              : classes.colBlak
                          } ${classes.iBlock}`}
                        >
                          {langcode === 'en'
                            ? engLoc.clientmessage.subject
                            : spaLoc.clientmessage.subject}
                          * :
                        </InputLabel>
                      </div>
                      <TextField
                        required
                        id="label-mi"
                        variant="outlined"
                        name="subject"
                        className={classes.labelField}
                      />
                      {errors &&
                        errors?.subject?.length === 1 &&
                        errors?.subject[0] === submaxErrMsg && (
                          <div
                            style={{ paddingTop: '5px', fontSize: '12px' }}
                            className={classes.errorCol}
                          >
                            {submaxErrMsg}
                          </div>
                        )}
                    </Grid>
                    <Grid item sm={11}>
                      <div
                        className={`${
                          submitFailed && errors?.notes?.length ? classes.errorCol : classes.colBlak
                        }`}
                      >
                        <InputLabel
                          htmlFor="notes"
                          className={`${
                            submitFailed && errors?.notes?.length
                              ? classes.errorCol
                              : classes.colBlak
                          }`}
                        >
                          {langcode === 'en'
                            ? engLoc.clientmessage.message
                            : spaLoc.clientmessage.message}
                          * :
                        </InputLabel>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <TextField
                          required
                          id="label-mi"
                          variant="outlined"
                          name="notes"
                          className={classes.labelField}
                          multiline
                          rows={7}
                        />
                        {errors &&
                          errors?.notes?.length === 1 &&
                          errors?.notes[0] === notemaxErrMsg && (
                            <div
                              style={{ paddingTop: '5px', fontSize: '12px' }}
                              className={classes.errorCol}
                            >
                              {notemaxErrMsg}
                            </div>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={12} style={{ paddingTop: 20, marginLeft: '5px' }}>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: '485px', marginRight: '30px' }}
                    onClick={() => handleSubmit()}
                  >
                    {langcode === 'en'
                      ? engLoc.clientmessage.sendbtn
                      : spaLoc.clientmessage.sendbtn}
                  </Button>
                  {/* <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
                    Cancel
                  </Button> */}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '350px',
                fontSize: '20px',
                color: 'white',
                flex: 'auto'
              }}
            >
              <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
                <div style={{ color: 'black', display: 'inline-block' }}>
                  {langcode === 'en'
                    ? engLoc.clientmessage.sendmesg
                    : spaLoc.clientmessage.sendmesg}
                </div>
                <span style={{ color: 'blue', marginLeft: '5px', display: 'inline-block' }}>
                  <a href="mailto: support@imattercolorado.org">
                    {langcode === 'en'
                      ? engLoc.clientmessage.sendmssg
                      : spaLoc.clientmessage.sendmssg}
                  </a>
                </span>
              </div>
            </div>
          )}
        </Grid>
        {isLoading()}
      </form>
    )
  }

  return (
    <Form<ClientFillingFormModel>
      validate={validator}
      render={renderForm}
      onSubmit={handleFormSubmit}
    />
  )
}
