import React, { useEffect, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TextareaAutosize
} from '@material-ui/core'
import { useCurrentUserQuery, useDashboardRequestsLazyQuery } from 'graphql/hooks.generated'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { ReactComponent as AttachIcon } from '../../../assets/icons/attach.svg'
import useStyles from '../Styles'

export default function Requests() {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeRequestsQuery,
    { data: requestsData, loading: requestsLoading }
  ] = useDashboardRequestsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchRequestsQuery = useMemo(
    () => ({ user_id }) =>
      executeRequestsQuery({
        variables: { user_id }
      }),
    [executeRequestsQuery]
  )
  useEffect(() => {
    if (currentUserdata) {
      const user_obj = { user_id: currentUserdata?.data?.currentUser?.id }
      fetchRequestsQuery(user_obj)
    }
  }, [currentUserdata, fetchRequestsQuery])

  return (
    <Paper>
      <h3 className={classes.pad}>
        <u>Requests in the Last 7 Days</u>
      </h3>
      <TableContainer component={Paper} style={{ height: '150px' }}>
        <Table stickyHeader className={classes.tableBorder} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Delivery</TableCell>
              <TableCell>Requested Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Status Date</TableCell>
              <TableCell>Request</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {breakdown.map((breakdownrow) => ( */}
            {requestsData?.ongoingrequest.map((request) => {
              return (
                <TableRow key={`${Math.floor(Math.random() * 100)}`}>
                  <TableCell align="left">{request.Client}</TableCell>
                  <TableCell align="left">{request.Delivery}</TableCell>
                  <TableCell align="left">{request.Requested_Date}</TableCell>
                  <TableCell align="left">{request.Status}</TableCell>
                  <TableCell align="left">{request.Status_Date}</TableCell>
                  <TableCell align="left">{request.Request}</TableCell>
                </TableRow>
              )
            })}

            {/* ))} */}
          </TableBody>
        </Table>
        {/* <div className={classes.tMintes}>
                      <h3>
                        <b>Coming soon</b>
                      </h3>
                    </div> */}
      </TableContainer>
      <div className={classes.requestProgress}>
        {requestsLoading && <ProgressIndicator position="relative" top="-60px" />}
      </div>
    </Paper>
  )
}
