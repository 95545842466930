import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Checkbox,
  Avatar,
  Collapse,
  FormControlLabel
} from '@material-ui/core'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { UseParamsType } from 'types'
import { RegistrationFormModel } from './RegistrationFormModel'
import { TimeSlots } from './timeSlots'
import { useListUserDistrictsQuery } from '../../../../graphql/hooks.generated'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import { Urlservice } from '../../../../views/public/serviceHelpers/urlhelper'
import ProgressIndicator from '../../panel/progress-indicator/ProgressIndicator'

interface TherapistsListProps {
  nextStep: () => void
  prevStep: () => void
  values: any
  handleChange: (flag: boolean, input?, selecttherapist?) => any
  // formExpand: any
}

export const TherapistsList = (props: TherapistsListProps) => {
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  // const { id: userId } = useParams<ListUserViewParams>()
  // console.log(userId)
  // const { data } = useListUserDistrictsQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { user_id: String(userId) }
  // })
  const { nextStep, handleChange, values, prevStep } = props
  const stateObj = { flag: false, index: -1, item: {} }
  const [expand, setExpand] = useState(stateObj)
  const [surveyStudentData, setSurveyStudentData] = useState<any>({})
  const [studentData, setStudentData] = useState<any>({})
  const [therapistSelected, setTherapistSelected] = useState(false)
  const [checkTherapistSelect, setCheckTherapistSelect] = useState(false)
  const { flag, index } = expand
  // const address = data?.userDistricts
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const items = [1, 2, 3, 4, 5, 6, 7]
  const nextHandler = (e) => {
    if (
      surveyStudentData?.therapist?.id
      // && ?.selected_slots?.user_availability_id
    ) {
      e.preventDefault()
      handleChange(false, surveyStudentData, expand)
      nextStep()
      setCheckTherapistSelect(false)
    } else setCheckTherapistSelect(true)
  }
  const waitlistHandler = (e) => {
    nextStep()
    handleChange(true, values)
  }
  const prevHandler = (e) => {
    e.preventDefault()
    prevStep()
  }
  const selectedTherapist = React.useCallback(
    (i, e, item) => {
      setExpand({ index: i, flag: e, item })

      if (e) {
        const currentTime = moment(new Date()).format('HH:mm:ss')
        const surveyId = {
          ...values,
          systemtime: currentTime,
          therapist: item
        }
        setTherapistSelected(false)
        setSurveyStudentData(null)
        setLoading(true)
        Urlservice.posturl('signal/signalstudentdata', surveyId)
          .then((surveyData) => {
            const slots = surveyData.data
            setStudentData(surveyData.data)
            setSurveyStudentData(surveyData.data.signal_slots)
            setTherapistSelected(true)
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    [values]
  )
  const slotTime = (slot) => {
    const slplit = slot.time_slot.split('-')
    const timeZoneOffset = moment.tz(slot.timeperiod, 'US/Mountain').format('Z')
    const mapsplit = slplit.map((itm) => {
      const momentStr = moment(itm, ['h:mm A']).format('HH:mm')
      const dates = moment(slot.timeperiod).utc().format('YYYY-MM-DD')
      const withDate = moment(`${dates} ${momentStr}`).format(
        `YYYY-MM-DDTHH:mm:ss${timeZoneOffset}`
      )

      return withDate
    })

    return mapsplit
  }
  const slotSelected = (slot) => {
    setCheckTherapistSelect(false)
    const startEndTime = slotTime(slot)
    const slotSelected = {
      ...slot,
      startdatetime: startEndTime[0],
      enddatetime: startEndTime[1]
    }
    setSurveyStudentData({ ...studentData, selected_slots: slotSelected })
  }
  const isLoading = () => <ProgressIndicator />
  useEffect(() => {
    // window.scrollTo(0, 0)
    // if (expand?.item?.id) selectedTherapist(expand.index, expand.flag, expand.item)
  }, [])

  return (
    <>
      <Grid sm={12}>
        {loading && <ProgressIndicator fullHeight />}
        <List className={classes.ListRoot}>
          {values?.signal_therapist?.length >= 1 ? (
            values?.signal_therapist?.map((item, i) => {
              return (
                <ListItem alignItems="flex-start" className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${item.Username}  `}
                      className={classes.avatarCustom}
                      src={item.picture}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.div1}
                    primary={
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${classes.inline} ${classes.registrationTherapistColor}`}
                            >
                              {`${item.Username}  `}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inlineSecondary}
                            >
                              {locallang === 'en'
                                ? `${item.service_name}`
                                : `${item.spanish_service_name}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${classes.inline} ${classes.registrationTherapistColor}`}
                            >
                              {item.service_name !== 'Telehealth' ||
                              item.spanish_service_name !== 'Telesalud'
                                ? `${t('scheduling.add')}`
                                : ''}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inlineSecondary}
                            >
                              {item.service_name !== 'Telehealth' ||
                              item.spanish_service_name !== 'Telesalud'
                                ? `${item.address} `
                                : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    }
                    secondary={<>{item.description}</>}
                  />
                  {/* <ListItemText primary={index === i && flag && <TimeSlots />} /> */}
                  <Collapse
                    in={index === i && flag}
                    timeout="auto"
                    unmountOnExit
                    className={classes.div3}
                  >
                    <List component="div" disablePadding>
                      <ListItem>
                        {therapistSelected && (
                          <TimeSlots
                            item={surveyStudentData}
                            slotSelected={(value) => slotSelected(value)}
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItemSecondaryAction>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      checked={index === i && flag}
                      name="checkedH"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        selectedTherapist(i, e.target.checked, item)
                      }}
                      className={classes.registrationTherapistColor}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          ) : (
            <>
              <div>{t('scheduling.note1')}</div>{' '}
              <div>
                {t('scheduling.note2')},{' '}
                <a
                  href="https://teleteachers.com/behavioural-health-platform.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mira for Mental Health Services
                </a>{' '}
                {t('scheduling.note4')}
              </div>
            </>
          )}
        </List>
      </Grid>
      <p style={{ marginTop: '0px', padding: '0 10px' }}>{t('scheduling.note3')}</p>
      {checkTherapistSelect && (
        <div
          style={{ padding: '10px', width: '100%', color: 'red' }}
          className={classes.fieldInfoValue}
        >
          {t('errormessages.select')}
        </div>
      )}
      {/* Sixth Row */}
      <Grid container direction="row" spacing={1}>
        <Grid item md={12} sm={12}>
          <Button
            variant="outlined"
            onClick={prevHandler}
            startIcon={<NavigateBeforeIcon>Next</NavigateBeforeIcon>}
            className={classes.customBtnCls}
          >
            {t('registrationform.back')}
          </Button>
          <Button
            className={`${classes.customBtnCls} ${classes.registrationTherapistStep} ${classes.ml20}`}
            endIcon={<NavigateNextIcon>Next</NavigateNextIcon>}
            onClick={nextHandler}
            disabled={!values?.signal_therapist?.length}
            style={{ backgroundColor: '#52b7ec' }}
          >
            {t('registrationform.next')}
          </Button>
          <Button
            variant="outlined"
            onClick={waitlistHandler}
            className={`${classes.customBtnCls} ${classes.mr25} ${classes.mr25} ${classes.fright}`}
          >
            {t('waitlist.waitlist')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
