import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(({ palette }: Theme) => ({
  headRoot: {
    heigth: '40px',
    '& > tr > th': {
      borderBottom: `1px dotted ${palette.divider}`,
      color: '#848484',
      fontSize: '10px',
      padding: '8px 32px'
    }
  },
  headerCls: {
    '&.e-grid .e-headercell': {
      color: 'black'
    },
    '&.e-grid .e-headercelldiv': {
      fontWeight: '600'
    },
    '&.e-grid.e-wrap .e-gridheader .e-sortnumber': {
      display: 'none'
    },
    '& .e-pager .e-pagerdropdown': {
      float: 'left',
      marginTop: '0px'
    },
    '& .e-input-group.e-control-wrapper': {
      height: '32px'
    }
  },
  labelText: {
    fontSize: '14px',
    color: '#2C9BD6',
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  labelField: {
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  '& .MuiFilledInput-input': {
    backgroundColor: '#fff !important'
  },
  '& .MuiSelect-filled.MuiSelect-filled': {
    backgroundColor: '#fff !important'
  },
  labelFieldNew: {
    border: '1px solid #000',
    backgroundColor: '#fff !important',
    '&.MuiFilledInput-adornedStart': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& div': {
      backgroundColor: '#fff !important'
    }
  },
  '&.MuiFilledInput-adornedStart': {
    backgroundColor: '#fff !important',
    border: '1px solid #000'
  },
  labelFieldOne: {
    marginTop: '10px',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  checkBoxStyles: {
    zIndex: 9,
    display: 'flex',
    position: 'absolute',
    right: '281px',
    top: '25px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#606060',
    '& .e-checkbox-wrapper .e-frame.e-check': {
      backgroundColor: 'rgb(241, 88, 58)'
    }
  },
  selectBoxStyles: {
    zIndex: 9,
    display: 'flex',
    position: 'absolute',
    right: '440px',
    top: '20px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#606060',
    alignItems: 'center',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  digbodycont: {
    width: '100%',
    margin: '0 auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    marginTop: '30px',
    paddingTop: '0px',
    paddingLeft: '0px',
    marginBottom: '30px',
    paddingRight: '0px',
    backgroundColor: '#fff'
  },
  nameCls: {
    fontWeight: 'bold'
  },
  selectDropdownOne: {
    width: '100%',
    minWidth: '565px',
    height: '58px',
    marginTop: '19px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  selectDropdown: {
    width: '100%',
    height: '50px',
    marginTop: '19px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  title: {
    borderBottom: `1px dotted ${palette.divider}`,
    padding: '16px 32px',
    '& > *': {
      color: palette.info.main
    }
  },
  plr10: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  selectDropdownUser: {
    width: '100%',
    height: '32px',
    marginTop: '19px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  selectDropdownUserForm: {
    width: '100%',
    height: '50px',
    marginTop: '12px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  selectDropdownState: {
    width: '100%',
    height: '60px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  stateCls: {
    marginTop: '10px'
  },
  assignCls: {
    margin: '0 auto',
    width: '97%'
  },
  controlSection: {
    '& .e-headercelldiv span.e-headertext': {
      fontSize: '11px'
    }
  }
}))
