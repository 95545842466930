import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  Page,
  ExcelExportProperties,
  SortSettingsModel,
  Sort
} from '@syncfusion/ej2-react-grids'
import React, { useMemo, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Typography, Link } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { useReportLazyQuery } from '../../../../graphql/hooks.generated'

export const Billingreport = () => {
  const history = useHistory()
  let grid: Grid | null
  const toolbar: ToolbarItems[] = ['ExcelExport']
  const [reportQuery, { data, loading, refetch }] = useReportLazyQuery({
    fetchPolicy: 'network-only'
  })
  const lastOneWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  const [billfrom, BillFrom] = useState(lastOneWeek)
  const [billTo, BillTo] = useState(currentDate)
  const FromDate = (e) => {
    BillFrom(e.target.value)
  }
  const ToDate = (e) => {
    BillTo(e.target.value)
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'agency', direction: 'Ascending' },
      { field: 'providername', direction: 'Ascending' }
    ]
  }
  const Fromformat = moment(billfrom).format('MM/DD/YYYY')
  const Toformat = moment(billTo).format('MM/DD/YYYY')
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_excelexport') {
      const excelExportProperties: ExcelExportProperties = {
        fileName: `BillingReport(${billfrom}_${billTo}).xlsx`,
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 4,
                  value: 'Billing Report',
                  style: { fontSize: 15, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `From Date: ${Fromformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `To Date: ${Toformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            }
          ]
        }
      }
      grid.excelExport(excelExportProperties)
    }
  }
  const reportquery = useMemo(
    () => ({ from_date, to_date }) =>
      reportQuery({
        variables: { from_date, to_date }
      }),
    [reportQuery]
  )
  useEffect(() => {
    if (billfrom && billTo) {
      const input = {
        from_date: billfrom,
        to_date: billTo
      }
      reportquery(input)
    }
  }, [billfrom, billTo, reportquery])

  return (
    <>
      <PageContainer>
        <Typography
          variant="h3"
          style={{ color: theme.palette.info.main, padding: '12px', fontFamily: 'Roboto' }}
        >
          {' '}
          Billing Report{' '}
        </Typography>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              textAlign: 'end',
              position: 'absolute',
              top: '21px',
              right: '10px',
              zIndex: 99
            }}
          >
            <span>
              {' '}
              <b> From Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="FromDate"
                type="Date"
                onChange={FromDate}
                defaultValue={billfrom}
              />
            </span>{' '}
            <span>
              {' '}
              <b>To Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="ToDate"
                type="Date"
                onChange={ToDate}
                defaultValue={billTo}
              />
            </span>
          </div>
          {loading && <ProgressIndicator fullHeight />}
          <GridComponent
            dataSource={data?.report || []}
            allowTextWrap
            allowPaging
            pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
            allowSorting
            id="grid"
            toolbar={toolbar}
            allowExcelExport
            toolbarClick={(e) => toolbarClick(e)}
            ref={(g) => {
              grid = g
            }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="agency"
                headerText="Agency"
                width="150"
                allowFiltering={false}
              />
              <ColumnDirective
                field="providername"
                headerText="Provider Name"
                width="150"
                allowFiltering={false}
              />
              <ColumnDirective
                field="service"
                headerText="Service Name"
                width="150"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client"
                template={(report) => (
                  <Link
                    onClick={() =>
                      history.push(
                        `/studentsinfo/${btoa(`${report?.student_id}_${report?.student_id}`)}`
                      )
                    }
                    style={{ color: 'black', cursor: 'pointer' }}
                  >
                    <span>{`${report?.client}`}</span>
                  </Link>
                )}
                headerText="Client"
                width="130"
              />
              <ColumnDirective
                field="county"
                headerText="County"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="zip"
                headerText="Zip Code"
                width="90"
                allowFiltering={false}
              />
              <ColumnDirective
                field="signalservice"
                headerText="In-Person/ Telehealth"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective field="Date" headerText="Date" width="100" allowFiltering={false} />
              <ColumnDirective
                field="fromtime"
                headerText="From Time"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="totime"
                headerText="To Time"
                width="90"
                allowFiltering={false}
              />
              <ColumnDirective
                field="sign"
                headerText="Documentation"
                width="150"
                allowFiltering={false}
              />
              <ColumnDirective
                field="present"
                headerText="Present/ Absent"
                width="110"
                allowFiltering={false}
              />
              {/* <ColumnDirective
                field="TimeElapsed"
                headerText="Time Elapsed"
                width="110"
                allowFiltering={false}
              /> */}
              <ColumnDirective
                field="BillableTime"
                headerText="Billable Time"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="Totalhours"
                headerText="Total hours"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="status"
                headerText="Status"
                width="110"
                allowFiltering={false}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, Page, Sort]} />
          </GridComponent>
        </div>
      </PageContainer>
    </>
  )
}
