import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  Iframe: {
    height: 'calc(100vh - var(--iframe-offset-top))'
  },
  IframeHidden: {
    opacity: '0'
  },
  Preloader: {
    maxHeight: 'calc(100vh - var(--iframe-offset-top))',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
