import React, { useEffect, useState, useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Grid, MenuItem, InputLabel, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Select, DatePicker, makeValidate } from 'mui-rff'
import { Form, FormRenderProps } from 'react-final-form'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import {
  useCreateAppointmentListMutation,
  useGetAddSlotUsersLazyQuery,
  useGetUnallocatedUserSlotsLazyQuery,
  useSignalServicesQuery,
  useUpdateAppointmentListMutation
} from 'graphql/hooks.generated'
import moment from 'moment'
import CalendarIcon from '@material-ui/icons/CalendarToday'

interface CreateAppontFormModel {
  date: Date | undefined
  starttime: any
  appointmentTypeid: any
  therapistId: any
  isServicetypeboth?: boolean
}

export interface CreteAppontmntFormProps {
  open: boolean
  handleClose: () => unknown
  userId: string
  submitdata: any
  distId: any
  istherapist: boolean
  therapistName: string
  onChange: (value) => any
  appointmentId: any
  waitlist: any
  filterobj: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '13px 14px'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    datefld: {
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    dropDown: {
      marginTop: '10px'
    },
    inputPadding: {
      '& input': {
        padding: '11.5px'
      }
    },
    fs20: {
      fontSize: '20px'
    },
    colBlak: {
      color: '#000'
    },
    iBlock: {
      display: 'inline-block'
    },
    fright: {
      float: 'right'
    },
    contpadd: {
      padding: '15px 15px 0px 10px'
    },
    mAuto: {
      margin: 'auto'
    },
    relative: {
      position: 'relative'
    },
    calendarIcon: {
      position: 'absolute',
      right: '10px',
      top: '10px'
    }
  })
)
const addAppointmentvalidator = () =>
  makeValidate(
    yup.object<CreateAppontFormModel>({
      date: yup.date().label('Date').nullable().required('Required'),
      starttime: yup.mixed<string>().label('Start Time').required('Required'),
      appointmentTypeid: yup.mixed<string>().label('Appointment Type').required('Required'),
      therapistId: yup.mixed<string>().label('Provider').required('Required')
    })
  )

export const CreateAppointmentModal = (props: CreteAppontmntFormProps) => {
  const {
    open,
    handleClose,
    userId,
    submitdata,
    distId,
    istherapist,
    therapistName,
    onChange,
    appointmentId,
    waitlist,
    filterobj
  } = props
  const isEdit = submitdata?.edit
  const history = useHistory()
  const validator = useMemo(() => addAppointmentvalidator(), [])
  const [userid, setUserid] = useState(userId)
  // const [serviceid, setServiceid] = useState(waitlist ? filterobj.service_id : submitdata.Service)
  const serviceid = waitlist ? filterobj.service_id : submitdata.Service
  const [allUnAvailableSlots, setallUnAvailableSlots] = useState<any[]>([])
  const [dayUnAvailableSlots, setdayUnAvailableSlots] = useState<any[]>([])
  const classes = useStyles()
  const servicesData = [
    { name: 'Telehealth', id: 2 },
    { name: 'In-person', id: 1 }
  ]
  const { data: appointmntTypeData, loading: appointmntLoading } = useSignalServicesQuery({
    fetchPolicy: 'network-only'
  })
  const [
    notAllocatedUserQuery,
    { data: notAllocatedSlotsDataQuery, loading: notAllocSlotsLoadl }
  ] = useGetUnallocatedUserSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    createAppMutation,
    { data: createAppData, loading: createAppLoading }
  ] = useCreateAppointmentListMutation()
  const [
    therapistListQuery,
    { data: therapistdata, loading: therapistLoading }
  ] = useGetAddSlotUsersLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [updateAppointment, { loading: appUpdateLoading }] = useUpdateAppointmentListMutation()
  const fetchunallocatedSlots = useMemo(
    () => ({ user_id }) =>
      notAllocatedUserQuery({
        variables: {
          user_id
        }
      }),
    [notAllocatedUserQuery]
  )
  const fetchTherapistList = useMemo(
    () => ({ district_id }) =>
      therapistListQuery({
        variables: {
          district_id
        }
      }),
    [therapistListQuery]
  )
  const handleFormSubmit = async (values) => {
    // console.log(values)
    let starttime
    let endtime
    let timeperiod
    const getChangedDate = moment(values?.date).format('YYYY-MM-DD')
    allUnAvailableSlots.map((outerArr) => {
      outerArr.map((slotobj) => {
        if (slotobj.timeperiod === getChangedDate && values.starttime === slotobj.id) {
          starttime = slotobj.slottime
          endtime = slotobj.endtime
          timeperiod = slotobj.timeperiod
        }

        return true
      })

      return true
    })
    const timeZoneOffset = moment.tz(timeperiod, 'US/Mountain').format('Z')
    const startdate = moment(`${timeperiod} ${starttime}`).format(
      `YYYY-MM-DDTHH:mm:ss${timeZoneOffset}`
    )
    const enddate = moment(`${timeperiod} ${endtime}`).format(
      `YYYY-MM-DDTHH:mm:ss${timeZoneOffset}`
    )
    const inputData = {
      signal_service_id: Number(values.appointmentTypeid),
      appointmentTypeId: '1',
      userId: values.therapistId,
      startDate: startdate,
      endDate: enddate,
      students: submitdata.Client,
      title: submitdata.title,
      districtId: Number(distId),
      createdBy: userId,
      updatedBy: userId,
      waitlist_create: waitlist || false
    }

    if (!isEdit)
      try {
        await createAppMutation({
          variables: {
            input: inputData
          }
        })
        handleClose()
        onChange('reload')
      } catch (e) {
        console.log(e)
      }
    if (isEdit)
      try {
        await updateAppointment({
          variables: {
            id: appointmentId,
            caseloadInsert: !istherapist,
            input: inputData
          }
        })
        handleClose()

        if (waitlist) history.push('/waitlist')
        else onChange('reload')
      } catch (e) {
        console.log(e)
      }
  }
  useEffect(() => {
    if (!istherapist) fetchTherapistList({ district_id: Number(distId) })
  }, [fetchTherapistList, istherapist, distId])
  useEffect(() => {
    fetchunallocatedSlots({ user_id: userid })
  }, [fetchunallocatedSlots, userid])
  useEffect(() => {
    if (notAllocatedSlotsDataQuery) {
      const notAllocatedSlotsData = notAllocatedSlotsDataQuery
      const availSlots: any[] = []
      Object.entries(notAllocatedSlotsData?.getUnallocatedUserSlots).forEach(([k, v], index) => {
        availSlots[index] = v
      })
      setallUnAvailableSlots(availSlots)
    }
  }, [notAllocatedSlotsDataQuery, setallUnAvailableSlots])
  const initialObj = useMemo(() => {
    const iniObj = {
      date: undefined,
      starttime: undefined,
      appointmentTypeid: serviceid,
      therapistId: userId,
      isServicetypeboth: false
    }

    return iniObj
  }, [serviceid, userId])
  const renderForm = (renderProps: FormRenderProps<CreateAppontFormModel>) => {
    const { handleSubmit, values, active } = renderProps
    // console.log('values', values)

    if (values.therapistId && active === 'therapistId') {
      const therapistArr = therapistdata?.getAddSlotUsers?.filter(
        (thepObj) => thepObj?.id === values.therapistId
      )
      values.date = undefined
      values.starttime = undefined
      values.appointmentTypeid =
        therapistArr?.[0]?.signal_service_id?.id !== 3
          ? therapistArr?.[0]?.signal_service_id?.id
          : undefined

      if (therapistArr?.[0]?.signal_service_id?.id !== 3) values.isServicetypeboth = false
      else values.isServicetypeboth = true

      // setServiceid(therapistArr?.[0]?.signal_service_id?.id)
      setUserid(values.therapistId)
    }
    if (values.date && active === 'date') {
      let dayAvailSlots = []
      const getChangedDate = moment(values?.date).format('YYYY-MM-DD')
      allUnAvailableSlots.map((outerArr) => {
        const oneslotObj = outerArr.find(() => true)

        if (oneslotObj.timeperiod === getChangedDate) {
          dayAvailSlots = outerArr
          values.starttime = undefined
        }

        return true
      })
      setdayUnAvailableSlots(dayAvailSlots)
    }

    return (
      <>
        <div>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
          >
            <DialogTitle id="alert-dialog-title">
              <div className={classes.fs20}>
                {isEdit ? 'Edit' : 'Create'} Appointment - Schedule Outside Availability
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container xs={12} md={12} sm={12} lg={12}>
                  <Grid item md={6}>
                    <Grid container md={12} className={classes.contpadd}>
                      <Grid item md={4} className={classes.mAuto}>
                        <InputLabel
                          htmlFor="label-provider"
                          className={`${classes.colBlak} ${classes.iBlock}`}
                        >
                          Provider
                        </InputLabel>
                        <div className={`${classes.colBlak} ${classes.iBlock} ${classes.fright}`}>
                          :
                        </div>
                      </Grid>
                      <Grid item md={7} className={classes.root}>
                        {therapistdata && !istherapist && (
                          <Select
                            name="therapistId"
                            labelId="demo-customized-select-label"
                            id="label-provider"
                            required
                            variant="outlined"
                          >
                            {therapistdata?.getAddSlotUsers?.map((dropDown) => (
                              <MenuItem value={dropDown?.id}>{dropDown?.name}</MenuItem>
                            ))}
                          </Select>
                        )}
                        {istherapist && (
                          <Select
                            name="therapistId"
                            labelId="demo-customized-select-label"
                            id="label-provider"
                            required
                            variant="outlined"
                            disabled
                          >
                            <MenuItem value={userid}>{therapistName}</MenuItem>
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container md={12} className={classes.contpadd}>
                      <Grid item md={4} className={classes.mAuto}>
                        <InputLabel
                          htmlFor="appointmentData"
                          className={`${classes.colBlak} ${classes.iBlock}`}
                        >
                          Date
                        </InputLabel>
                        <div className={`${classes.colBlak} ${classes.iBlock} ${classes.fright}`}>
                          :
                        </div>
                      </Grid>
                      <Grid item md={7} className={`${classes.datefld} ${classes.relative}`}>
                        {notAllocatedSlotsDataQuery && (
                          <>
                            <CalendarIcon className={classes.calendarIcon} />
                            <DatePicker
                              required
                              clearable
                              // InputProps={{ readOnly: true }}
                              minDate={
                                moment
                                  .tz('US/Mountain')
                                  .isBefore(
                                    moment.tz('22:00:00', 'HH:mm:ss', 'US/Mountain').format()
                                  )
                                  ? moment.tz(new Date(), 'US/Mountain').format('MM/DD/YYYY')
                                  : moment
                                      .tz(new Date(), 'US/Mountain')
                                      .add(1, 'days')
                                      .format('MM/DD/YYYY')
                              }
                              maxDate={moment
                                .tz('US/Mountain')
                                .add(
                                  Object.entries(
                                    notAllocatedSlotsDataQuery?.getUnallocatedUserSlots
                                  )?.length - 1,
                                  'days'
                                )
                                .format('MM/DD/YYYY')}
                              name="date"
                              id="appointmentData"
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              className={classes.inputPadding}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container md={12} className={classes.contpadd}>
                      <Grid item md={4} className={classes.mAuto}>
                        <InputLabel
                          htmlFor="label-apptType"
                          className={`${classes.colBlak} ${classes.iBlock}`}
                        >
                          Appointment Type
                        </InputLabel>
                        <div className={`${classes.colBlak} ${classes.iBlock} ${classes.fright}`}>
                          :
                        </div>
                      </Grid>
                      <Grid item md={7} className={classes.root}>
                        {appointmntTypeData &&
                          (Number(values.appointmentTypeid) === 3 ||
                            !values.appointmentTypeid ||
                            values.isServicetypeboth) && (
                            <Select
                              name="appointmentTypeid"
                              labelId="demo-customized-select-label"
                              id="label-apptType"
                              required
                              variant="outlined"
                            >
                              {servicesData?.map((dropDown) => (
                                <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        {appointmntTypeData &&
                          (Number(values.appointmentTypeid) === 1 ||
                            Number(values.appointmentTypeid) === 2) &&
                          !values.isServicetypeboth && (
                            <Select
                              name="appointmentTypeid"
                              labelId="demo-customized-select-label"
                              id="label-apptType"
                              required
                              variant="outlined"
                              disabled={
                                Number(values.appointmentTypeid) === 1 ||
                                Number(values.appointmentTypeid) === 2
                              }
                            >
                              {appointmntTypeData?.signalServices?.map((dropDown) => (
                                <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>
                              ))}
                            </Select>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container md={12} className={classes.contpadd}>
                      <Grid item md={4} className={classes.mAuto}>
                        <InputLabel
                          htmlFor="label-startTime"
                          className={`${classes.colBlak} ${classes.iBlock}`}
                        >
                          Start Time
                        </InputLabel>
                        <div className={`${classes.colBlak} ${classes.iBlock} ${classes.fright}`}>
                          :
                        </div>
                      </Grid>
                      <Grid item md={7} className={classes.root}>
                        {notAllocatedSlotsDataQuery && (
                          <Select
                            name="starttime"
                            labelId="demo-customized-select-label"
                            id="label-startTime"
                            required
                            variant="outlined"
                            disabled={!dayUnAvailableSlots?.length}
                          >
                            {dayUnAvailableSlots &&
                              dayUnAvailableSlots?.map((slots) => (
                                <MenuItem
                                  value={slots.id}
                                  disabled={
                                    moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD') ===
                                      moment
                                        .tz(slots.timeperiod, 'YYYY-MM-DD', 'US/Mountain')
                                        .format('YYYY-MM-DD') &&
                                    !moment
                                      .tz('US/Mountain')
                                      .isBefore(
                                        moment.tz(slots.slottime, 'H:mm:ss', 'US/Mountain').format()
                                      )
                                  }
                                >
                                  {moment(slots.slottime, 'H:mm:ss').format('h:mm A')}&nbsp;-&nbsp;
                                  {moment(slots.endtime, 'H:mm:ss').format('h:mm A')}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingRight: '30px' }}>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                className="m-t-5 m-b-20"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="mar-rt-15 m-t-5 m-b-20"
                variant="contained"
                size="medium"
                style={{ height: '36px' }}
                onClick={() => handleSubmit()}
              >
                Add Appointment
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }

  return (
    <>
      {(appointmntLoading ||
        notAllocSlotsLoadl ||
        createAppLoading ||
        therapistLoading ||
        appUpdateLoading) && <ProgressIndicator fullHeight />}
      <Form<CreateAppontFormModel>
        render={renderForm}
        validate={validator}
        initialValues={initialObj}
        onSubmit={handleFormSubmit}
      />
    </>
  )
}
