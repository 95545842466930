/* eslint-disable */
import React, { useMemo, useState } from 'react'
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  ViewsDirective,
  ViewDirective,
  Inject,
  Print
} from '@syncfusion/ej2-react-schedule'
import Button from '../../../../components/ui/panel/button/Button'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useAppointmentsListLazyQuery } from '../../../../graphql/hooks.generated'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { useLocation } from 'react-router-dom'

import { L10n } from '@syncfusion/ej2-base'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { enableRipple } from '@syncfusion/ej2-base'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import moment from 'moment'

import ScheduleViewDialog from '../view'
import ScheduleUpdateAddDialog from '../update'
import { NewScheduleUpdate } from '../newUpdate'

import '../css/view.css'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { refreshForeignData } from '@syncfusion/ej2-react-grids'
enableRipple(true)

L10n.load({
  'en-US': {
    schedule: {
      saveButton: 'Save',
      cancelButton: 'Close',
      deleteButton: 'Delete',
      newEvent: 'Add Event'
    }
  }
})

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

const CalendarView = (props) => {
  const search = useLocation().search
  const name = new URLSearchParams(search).get('name')
  let scheduleObj
  const { data: currentUserData } = useCurrentUser()

  const [appointmentlistQuery, { data, loading, refetch }] = useAppointmentsListLazyQuery({
    fetchPolicy: 'network-only'
  })
  const appointmentlistFetch = useMemo(
    () => ({ user_id, district_id, appointment_type_id }) =>
      appointmentlistQuery({ variables: { user_id, district_id, appointment_type_id } }),
    [appointmentlistQuery]
  )
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')

  const [notValidDate, setNotValidDate] = useState(false)
  const [action, setAction] = useState('')
  const [openAddUpdateScreen, setOpenAddUpdateScreen] = useState(false)
  const [cellData, setCellData] = useState(undefined)
  const [parentData, setParentData] = useState(undefined)
  const [hideDialog, setHideDialog] = useState(false)
  const [recurConfirm, setRecurConfirm] = useState(false)
  const [editRecur, setEditRecur] = useState(null)
  const [showBlockout, setShowBlockout] = useState(false)
  const [therapistName, setTherapistName] = useState('')
  const [cancelHeader, setCancelHeader] = useState(false)
  const [emailHeader, setEmailHeader] = useState(false)
  const [modelHeaderString, setModelHeaderString] = useState('')

  const _view = 'view'
  const _edit = 'edit'
  const _editOccurence = 'edit_occurence'
  const _editFollowing = 'edit_following'
  const _quickLaunch = 'quick'
  const _addNewAppointment = 'add'
  const _cancelEvent = 'cancel'
  const _refreshAppointments = 'refetch'
  const _addAppointment = 'new'

  const onEventRendered = (args) => {
    // console.log('event rendered' , args)
    if (args.data.signal_service_id?.id === 1) {
      args.element.style.backgroundColor = '#1aaa55'
    } else if (args.data.signal_service_id?.id === 2) {
      args.element.style.backgroundColor = '#3e49b5'
    } else {
      args.element.style.backgroundColor = '#ff6501'
    }
    // args.element.style.backgroundColor = categoryColor;
  }

  const onActionBegin = (args) => {
    if (args.requestType === 'toolbarItemRendering') {
      let arg = args
      let item3 = arg.items[3]
      let item5 = arg.items[5]
      let item6 = arg.items[6]
      let item7 = arg.items[7]
      item3.text = ''
      item5.align = 'center'
      item6.align = 'center'
      item7.align = 'center'
    }
  }

  const onMoreEventsClick = (value) => {
    value.event.stopPropagation()
  }

  const onDoubleClick = (value) => {
    value.cancel = true
  }
  const modelHeaderChange = (value, key) => {
    // setModelHeader(value)
    if (key === 'email') {
      setModelHeaderString('Are you sure you want to send the link to the client?')
      setEmailHeader(value)
    } else if (key === 'cancel') {
      setModelHeaderString('Are you sure you want to cancel?')
      setCancelHeader(value)
    }
  }

  const _cellEventClick = (args, action) => {
    args.cancel = true
    let date = new Date()
    const start = moment(date)
    const remainder = 15 - (start.minute() % 15)

    const startTime =
      action !== _addNewAppointment && action !== _quickLaunch
        ? moment(start).add(remainder, 'minutes').local()
        : action == 'quick'
        ? moment(start).local()
        : args.startTime
    const endTime =
      action !== _addNewAppointment ? moment(startTime).add(15, 'minutes').local() : args.endTime

    setNotValidDate(false)

    let cellDataObject = {
      startTime: startTime,
      endTime: endTime,
      Subject: '',
      appointment_type_id: '1',
      action: action,
      change: cellData?.action ? false : true,
      note: '',
      RecurrenceRule: null,
      isreccuring: null,
      EventStudents: null
    }
    setAction(action)

    if (action === _view) {
      // console.log('args', args.event)
      args.event = {
        ...args.event,
        startTime: args.event.StartTime,
        endTime: args.event.EndTime,
        action: action,
        change: cellData?.action ? false : true,
        RecurrenceException: args.RecurrenceException,
        parentRecException: args.RecurrenceException,
        RecurrenceRule: args.event.RecurrenceRule
      }
      setCellData(args.event)
      setParentData(args.event)
      // if (!props.match.params.userId && !args.event.isreccuring) {
      setHideDialog(true)
      // setRecurConfirm(true)
      // } else if (!props.match.params.userId && args.event.isreccuring) {
      //   setRecurConfirm(true)
      // } else {
      //   setHideDialog(false)
      // }
    } else {
      // if (!props.match.params.userId) {
      // let GivenDate = moment(cellDataObject.startTime).format('YYYY-MM-DD')
      var CurrentDate = new Date()
      let GivenDate = new Date(cellDataObject.endTime)
      // if (GivenDate > CurrentDate) {
      setCellData(cellDataObject)
      setHideDialog(true)
      // }
      // } else {
      //   setHideDialog(false)
      // }
    }
  }

  const _eventDialogHeader = () => {
    let headerText =
      cellData.appointment_type_id.id === '3'
        ? 'Blockout Day'
        : cancelHeader || emailHeader
        ? modelHeaderString
        : action == _edit || action == _view
        ? 'Event Details'
        : 'Add Event'
    return (
      <div>
        <span style={{ color: 'white' }}>{headerText}</span>
      </div>
    )
  }
  const setEditScreen = () => {
    setCellData({ ...cellData, edit: true })
    setOpenAddUpdateScreen(true)
  }

  const _eventDialogContent = () => {
    const blockout = cellData.appointment_type_id.id === '3' ? true : false
    return (
      <div>
        {/* <span style={{color: '#000'}} >content</span> */}
        {/* {action !== 'view' && (
          <ScheduleUpdateAddDialog
            cellData={cellData}
            onChange={(value) => _eventDialogClose(value)}
            appointmentListData={data?.appointmentList}
            repeat={editRecur}
            admin={props?.match?.params?.userId ? props?.match?.params?.userId : false}
            currentTime={moment(new Date()).format('HH:mm:ss')}
            scheduleAction={action}
          />
        )} */}

        {action === 'view' && (
          <ScheduleViewDialog
            cellData={cellData}
            onChange={(value) => {
              value === _refreshAppointments ? _eventDialogClose(value) : setAction(value)
            }}
            repeat={editRecur}
            appointmentListData={data?.appointmentList}
            currentUserId={currentUserData.currentUser.id}
            currentUserDisID={currentUserData.currentUser.district.id}
            blockout={blockout}
            admin={props?.match?.params?.userId ? props?.match?.params?.userId : false}
            modalHeader={(value, key) => modelHeaderChange(value, key)}
            cancelHeader={cancelHeader}
            emailHeader={emailHeader}
            openEdit={() => setEditScreen()}
          />
        )}
      </div>
    )
  }

  const _eventDialogClose = (action) => {
    // this.setState({ hideDialog: false });
    setHideDialog(false)
    setCancelHeader(false)
    setEmailHeader(false)
    if (action && action !== _cancelEvent) {
      appointmentApiFetch()
    }

    // setStudentValid(false)
    // setServiceValid(false)
    // setAppointmentsLoading(false)
    // setDeleteLoading(false)
    setEditRecur(null)
    setCellData(null)
    // this.buttonElement.style.display = 'inline-block';
  }
  const appointmentApiFetch = () => {
    if (props.match.params.userId) {
      let input = {
        user_id: props.match.params.userId,
        district_id: parseInt(currentUserData.currentUser.district.id),
        appointment_type_id: '3'
      }
      appointmentlistFetch(input)
    } else {
      if (currentUserData) {
        let input = {
          user_id: currentUserData.currentUser.id,
          district_id: parseInt(currentUserData.currentUser.district.id),
          appointment_type_id: '3'
        }
        appointmentlistFetch(input)
      }
    }
  }
  const viewBlockout = () => {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}> View Blockout Event </p>
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}></div>
      </React.Fragment>
    )
  }
  const recuringConfirm = () => {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}>Event Acceptance </p>
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
          <Button
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 "
            variant="contained"
            size="medium"
            style={{
              height: '36px',
              marginTop: '13px',
              background: '#297147'
            }}
            onClick={() => editRecurringEvent(_editOccurence)}
          >
            Accept
          </Button>
          <Button
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 "
            variant="contained"
            size="medium"
            style={{
              height: '36px',
              marginTop: '13px'
            }}
            onClick={() => setRecurConfirm(false)}
          >
            Reject
          </Button>
        </div>
      </React.Fragment>
    )
  }
  const handelChange = (action) => {
    setOpenAddUpdateScreen(false)

    if (action === 'reload') {
      appointmentApiFetch()
      setAction(null)
      setHideDialog(false)
      setCellData(null)
    }
  }
  const editRecurringEvent = (action) => {
    setRecurConfirm(false)

    setHideDialog(true)
    setEditRecur(action)
  }
  React.useEffect(() => {
    appointmentApiFetch()
  }, [])
  const selecteName = new URLSearchParams(props.location.search).get('name')
  const classes = useStyles()

  return (
    <>
      {!openAddUpdateScreen && (
        <div>
          <NavBar
            className="container-adjust"
            title={`Schedule ${selecteName ? `of ${selecteName}` : ''}`}
            goBackBtn
          />
          <PageContainer>
            <div className="e-card" id="basic" style={{ width: '100%', overflow: 'auto' }}>
              <div className="e-card-content">
                {isLoading}
                <div style={{ display: 'flex' }}>
                  <h6 className="timeZoneTxt">
                    <span>All times shown in Mountain(MT) </span> Hawaiian Time = -3 hrs, Alaskan
                    Time = -2 hrs, Pacific Time = -1 hr, Central Time = +1 hr, Eastern Time = +2 hrs
                  </h6>
                  <h3 style={{ marginLeft: '8rem', color: '#F1583A' }}> {name} </h3>
                </div>

                <ScheduleComponent
                  ref={(t) => (scheduleObj = t)}
                  cssClass="schedule-cell-dimension"
                  height="550px"
                  style={{ margin: '10px auto' }}
                  eventSettings={{ dataSource: data?.appointmentList }}
                  // actionComplete={(value) => onActionComplete(value)}
                  actionBegin={(value) => onActionBegin(value)}
                  showQuickInfo={false}
                  // popupOpen={(value) => onPopupOpen(value)}
                  moreEventsClick={(value) => onMoreEventsClick(value)}
                  // cellClick={(value) => _cellEventClick(value, _addNewAppointment)}
                  cellDoubleClick={(value) => onDoubleClick(value)}
                  eventClick={(value) => _cellEventClick(value, _view)}
                  // popupClose={(value) => onPopupClose(value)}
                  width="100%"
                  currentView="Week"
                  eventRendered={(value) => onEventRendered(value)}
                  timeScale={{ enable: true, interval: 60, slotCount: 4 }}
                  // editorTemplate = {(value) =>editorTemplate(value)}
                  timezone="US/Mountain"
                >
                  <ViewsDirective>
                    <ViewDirective option="Day" />
                    <ViewDirective option="Week" />
                    <ViewDirective option="Month" />
                  </ViewsDirective>
                  <Inject services={[Day, Week, Month, Print]} />
                  {/* {!props.match.params.userId && ( */}
                  <div className="add-eve-btn">
                    <Button
                      color="primary"
                      onClick={() => setOpenAddUpdateScreen(true)}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="mar-rt-15 custom-btn"
                      variant="contained"
                      size="medium"
                    >
                      Add Event 
                    </Button>
                  </div>
                  {/* )} */}
                  {hideDialog && (
                    <DialogComponent
                      isModal={true}
                      header={() => _eventDialogHeader()}
                      content={() => _eventDialogContent()}
                      showCloseIcon={true}
                      className="eventDialog"
                      open={() => dialogOpen()}
                      close={() => _eventDialogClose(_cancelEvent)}
                      height={'auto'}
                      width={'525px'}
                      visible={hideDialog}
                    ></DialogComponent>
                  )}
                  {recurConfirm && (
                    <DialogComponent
                      isModal={true}
                      content={() => recuringConfirm()}
                      showCloseIcon={true}
                      // open={()=>dialogOpen()}
                      close={() => setRecurConfirm(false)}
                      height={'auto'}
                      width={'400px'}
                      visible={recurConfirm}
                    ></DialogComponent>
                  )}
                </ScheduleComponent>

                {/* {hideDialog &&  */}

                {/* <DialogComponent
                isModal={true}
                content={() => viewBlockout()}
                showCloseIcon={true}
                // open={()=>dialogOpen()}
                close={() => setShowBlockout(false)}
                height={'auto'}
                width={'400px'}
                visible={showBlockout}
              ></DialogComponent> */}
              </div>
            </div>
          </PageContainer>
        </div>
      )}
      {openAddUpdateScreen && (
        <NewScheduleUpdate
          onChange={(value) => handelChange(value)}
          userDetails={currentUserData.currentUser}
          userId={
            props?.match?.params?.userId
              ? props?.match?.params?.userId
              : currentUserData?.currentUser?.id
          }
          admin={props?.match?.params?.userId ? true : false}
          cellData={cellData}
          providerName={selecteName}
        />
      )}
    </>
  )
}
export default CalendarView
