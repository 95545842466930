import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errormessage: {
      fontSize: '12px',
      padding: '5px',
      color: theme.palette.error.dark
    }
  })
)
