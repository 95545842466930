/* eslint-disable */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  useAppointmentStudentsLazyQuery,
  useUserServiceListLazyQuery,
  useClientFilterLazyQuery
} from '../../../../graphql/hooks.generated'
import { useSnackbar } from 'notistack'
import { Grid, Select, MenuItem, Typography } from '@material-ui/core'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import Button from '../../../../components/ui/panel/button/Button'

const InputField = ({
  title,
  value,
  valueKey,
  lableKey,
  onSelect,
  defaultValue,
  edit,
  waitlist
}) => {
  return (
    <React.Fragment>
      <div className="field-name-div p-x ">
        <div className="eventFeilds_div">
          <Typography
            className="field-name"
            style={{
              width: '100%',
              minWidth: title === 'Current Slot' || title === 'Current Provider' ? '125px' : '60px'
            }}
          >
            {title}
          </Typography>
          <span style={{ padding: '0 10px' }}> :</span>
        </div>
        {title !== 'Service' && (title !== 'Client' || edit || waitlist) && (
          <Typography style={{ color: ' #757575', fontWeight: '400', maxWidth: '65%' }}>
            {value}
          </Typography>
        )}

        {(title === 'Service' || (title === 'Client' && !edit && !waitlist)) && (
          <Select
            labelId="demo-customized-select-label"
            id="label-age"
            required
            className="eventDropdown"
            variant="filled"
            value={Number(defaultValue)}
            onChange={(value) => onSelect(title, Number(value.target.value))}
            disabled={title === 'Client' && edit}
            style={{ width: '100%' }}
          >
            {value?.map((itm) => (
              <MenuItem value={Number(itm[valueKey])}>{itm[lableKey]}</MenuItem>
            ))}
          </Select>
        )}
      </div>
    </React.Fragment>
  )
}

const EventDetails = ({
  userDetails,
  userId,
  admin,
  onChange,
  appointmentDetails,
  studetentDetails,
  filterLoading,
  setSubmitDetails,
  errorObject,
  waitlist,
  setStudentId,
  serviceId,
  setServiceId,
  setCounty,
  setValues
}) => {
  const [
    studentsList,
    { data: studentsData, loading: studentsLoading }
  ] = useAppointmentStudentsLazyQuery({
    variables: {
      user_id: userId
    },
    fetchPolicy: 'network-only'
  })
  const [
    executeTherapistQuery,
    { data: userServiceList, loading: serviceLoading }
  ] = useUserServiceListLazyQuery({
    variables: { id: userId },
    fetchPolicy: 'network-only'
  })
  const [
    clientDetailsQuery,
    { data: clientDetails, loading: clientLoading }
  ] = useClientFilterLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch1 = useMemo(() => () => studentsList(), [studentsList])
  const TherapistService = useMemo(() => () => executeTherapistQuery(), [executeTherapistQuery])
  const Clientdetails = useMemo(() => (query) => clientDetailsQuery(query), [clientDetailsQuery])
  const [eventDetails, setEventDetails] = useState()
  const history = useHistory()
  const servicesData = [
    { label: 'Telehealth', value: '2' },
    { label: 'Inperson', value: '1' }
  ]
  useEffect(() => {
    if ((admin || waitlist) && !serviceId) setServiceId('3')
  })
  const ChangeHandel = (title, value) => {
    onChange(title, value)
    setEventDetails({
      ...eventDetails,
      [title]: value
    })
    if (title === 'Service') {
      setServiceId(value)
      setValues(false)
    }
    if (title === 'Client' && admin) {
      const qeury = {
        variables: { student_id: value }
      }
      setStudentId(value)
      filterLoading(true)
      Clientdetails(qeury)
    }
  }
  const setTitle = (value) => {
    if (studentsData) {
      const title = studentsData?.appointmentStudents?.filter((stu) => stu.id === value)
      onChange('title', title?.[0]?.name)
      setEventDetails({
        ...eventDetails,
        title: title?.[0]?.name
      })
    }
  }
  const isLoading = () =>
    studentsLoading || serviceLoading || clientLoading ? <ProgressIndicator fullHeight /> : ''
  useEffect(() => {
    fetch1()
    TherapistService()
  }, [])
  useEffect(() => {
    if (clientDetails?.clientFilter) {
      const clientdetails = clientDetails?.clientFilter?.[0]
      // const specialities = clientdetails.student_specialities.map((spe) => {
      //   return spe.speciality_id.id
      // })
      const tmpObj = {
        // speciality_id: specialities,
        // language_id: clientdetails?.primaryLanguage?.id,
        county_id: !waitlist
          ? appointmentDetails?.Service === 2 || clientdetails?.session_service?.id === 2
            ? 1
            : clientdetails?.county?.id || 1
          : null,
        service_id: !waitlist
          ? appointmentDetails?.Service || clientdetails?.session_service.id
          : clientdetails?.session_service?.id || null
      }
      if (!waitlist) setServiceId(tmpObj.service_id)
      if (tmpObj.service_id === 2 && !waitlist) {
        setCounty(1)
      } else {
        setCounty(clientdetails?.county?.id)
      }
      if (!waitlist) onChange('Service', tmpObj.service_id)
      setEventDetails({
        ...eventDetails,
        Service: tmpObj.service_id
      })
      setValues(true)
      studetentDetails(tmpObj)
      filterLoading(false)
    }
  }, [clientDetails, appointmentDetails])
  useEffect(() => {
    if (eventDetails?.Client) {
      setTitle(eventDetails?.Client)
    }
  }, [eventDetails?.Client])
  useEffect(() => {
    // if (studentsData?.appointmentStudents && appointmentDetails) {
    if (appointmentDetails?.edit || appointmentDetails?.waitlist) {
      const qeury = {
        variables: { student_id: appointmentDetails.Client }
      }
      setEventDetails(appointmentDetails)
      filterLoading(true)
      Clientdetails(qeury)
      setSubmitDetails(appointmentDetails)
    }
    // }
  }, [appointmentDetails])
  useEffect(() => {
    const clientdetails = clientDetails?.clientFilter?.[0]
    if (waitlist && clientdetails) setServiceId(clientdetails?.session_service?.id)
  }, [waitlist, setServiceId, clientDetails])
  useEffect(() => {
    const services = userServiceList?.userServiceList[0]?.signal_service_id
    const serviceId = services?.length === 1 ? services[0]?.value : 2

    if (!admin && !appointmentDetails?.edit && !waitlist && services) {
      onChange('Service', serviceId)
      setEventDetails({
        ...eventDetails,
        Service: serviceId
      })
      setServiceId(serviceId)
    }
    // }
  }, [userServiceList?.userServiceList])
  // useEffect(() => {
  //   if ((admin || waitlist) && !appointmentDetails?.edit) {
  //     onChange('Service', 2)
  //     setEventDetails({
  //       ...eventDetails,
  //       Service: 2
  //     })
  //   }
  //   // }
  // }, [])
  return (
    <Grid
      container
      className="detail-container"
      style={{ marginTop: '15px', padding: '10px', border: '1px solid', background: '#fff' }}
    >
      {isLoading()}
      <Grid item sm={4} className="mr-25">
        {' '}
        <div style={{ display: 'flex' }}>
          <InputField
            title={'Client'}
            value={
              appointmentDetails.edit || appointmentDetails.waitlist
                ? eventDetails?.clientName
                : studentsData?.appointmentStudents
            }
            valueKey={'id'}
            lableKey={'name'}
            onSelect={(field, value) => ChangeHandel(field, value)}
            defaultValue={eventDetails?.Client}
            edit={appointmentDetails?.edit}
            waitlist={appointmentDetails.waitlist}
          />
          <Button
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 "
            variant="contained"
            size="medium"
            style={{
              height: '25px',
              width: ' 105px',
              background: eventDetails?.Client ? '#2c9bd6' : '#00000042',
              margin: '10px',
              fontSize: '10px'
            }}
            onClick={() =>
              window.open(
                `/studentsinfo/${btoa(`${eventDetails?.Client}_${eventDetails?.Client}`)}`,
                `_blank`
              )
            }
            disabled={!eventDetails?.Client}
          >
            Client Info
          </Button>
        </div>
        {errorObject?.Client && (
          <Typography style={{ color: '#f50b2b' }}>Please Select Client</Typography>
        )}
      </Grid>
      {/* <Grid item sm={1} className="mr-25">
        <Button
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="mar-rt-15 "
          variant="contained"
          size="medium"
          style={{
            height: '36px',
            width: ' 105px',
            background: eventDetails?.Client ? '#2c9bd6' : '#00000042',
            margin: '5px'
          }}
          onClick={() =>
            history.push(`/studentsinfo/${btoa(`${eventDetails?.Client}_${eventDetails?.Client}`)}`)
          }
          disabled={!eventDetails?.Client}
        >
          Client Info
        </Button>
      </Grid> */}
      <Grid item sm={3} className="mr-25">
        {' '}
        <InputField
          title={'Service'}
          value={
            admin || waitlist
              ? servicesData
              : userServiceList?.userServiceList[0]?.signal_service_id
          }
          valueKey={'value'}
          lableKey={'label'}
          onSelect={(field, value) => ChangeHandel(field, value)}
          defaultValue={!admin && !waitlist ? eventDetails?.Service : serviceId || '3'}
        />
        {errorObject?.Service && (
          <Typography style={{ color: '#f50b2b' }}>Please Select Service</Typography>
        )}
      </Grid>
      <Grid item sm={5} className="mr-25">
        {' '}
        {appointmentDetails.edit && (
          <InputField title={'Current Slot'} value={eventDetails?.currentSlot} />
        )}
      </Grid>

      <Grid item sm={3} className="mr-25">
        <InputField
          title={'Title'}
          value={
            appointmentDetails.edit || appointmentDetails.waitlist
              ? eventDetails?.clientName
              : eventDetails?.title
          }
          waitlist={appointmentDetails.waitlist}
        />
      </Grid>
      <Grid item sm={4} className="mr-25"></Grid>
      <Grid item sm={5} className="mr-25">
        {' '}
        {admin && appointmentDetails.edit && (
          <InputField title={'Current Provider'} value={appointmentDetails?.userName} />
        )}
      </Grid>
    </Grid>
  )
}
export default EventDetails
