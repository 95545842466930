import { makeStyles, Theme } from '@material-ui/core'
import cdhLogo from '../../../../assets/illustrations/colorado_img.png'
import ccslogo from '../../../../assets/illustrations/Mira_Logo.png'
// import wisconsinLogo from '../../../../assets/illustrations/wisconsin-logo.png'

export default makeStyles(({ breakpoints, palette }: Theme) => ({
  root: {
    borderTop: `1px dashed ${palette.divider}`,
    color: '#848484',
    '& > *': {
      flexGrow: 1
    }
  },
  footerPrivate: {
    display: 'flex',
    width: '100%',
    height: '75px',
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: '3.5%',
    background: '#FFF' // '#1a3560'
  },
  footerPublic: {
    display: 'flex',
    width: '100%',
    height: '65px',
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: '3.5%',
    [breakpoints.down('xs')]: {
      paddingLeft: '5px'
    },
    background: '#fafafa'
  },
  footerbg: {
    alignItems: 'center',
    marginRight: '15px',
    width: '210px',
    height: '45px',
    backgroundSize: '210px',
    backgroundPosition: '100% 100%',
    backgroundImage: `url(${cdhLogo})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      marginRight: '5px',
      width: '175px',
      backgroundSize: '175px'
    }
  },
  footerccsbg: {
    alignItems: 'center',
    marginRight: '15px',
    width: '215px',
    height: '45px',
    backgroundSize: '212px auto',
    backgroundPosition: '100%',
    backgroundImage: `url(${ccslogo})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      marginRight: '5px',
      width: '175px',
      backgroundSize: '175px'
    },
    borderRadius: '12px'
  },
  cursor: {
    cursor: 'pointer'
  },
  pbtcolor: {
    // color: 'white'
  }
}))
