import React from 'react'
import {
  useListChatGroupsLazyQuery,
  useCreateChatGroupMutation,
  useListChatGroupMembersLazyQuery,
  useInviteToChatGroupMutation,
  ChatCredentials,
  User
} from 'graphql/hooks.generated'

interface CredentialsProps {
  chatCredentials: ChatCredentials | null
  getUsers: () => User[]
  [key: string]: unknown
}

export const withChatGroup = (Component) => (props: CredentialsProps) => {
  const { chatCredentials, getUsers } = props
  const [
    executeChatListQuery,
    { data: chatListData, loading: chatListLoading }
  ] = useListChatGroupsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeChatGroupMembersQuery,
    { data: chatMembersData, loading: chatMembersLoading }
  ] = useListChatGroupMembersLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeInviteToChatGroupMutation,
    { loading: startInviteToChatLoading }
  ] = useInviteToChatGroupMutation()
  const [
    executeCreateChatGroupMutation,
    { loading: startChatLoading }
  ] = useCreateChatGroupMutation()
  const startChatGroup = async (name: string, emails: string[]) => {
    if (chatCredentials?.authToken && !startChatLoading) {
      await executeCreateChatGroupMutation({
        variables: { chatAuthToken: chatCredentials?.authToken, name, emails }
      })
      await executeChatListQuery({
        variables: { chatAuthToken: chatCredentials?.authToken }
      })
    }
  }
  React.useEffect(() => {
    if (chatCredentials && !chatListData && !chatListLoading)
      executeChatListQuery({
        variables: { chatAuthToken: chatCredentials?.authToken }
      })
  }, [chatCredentials, chatListData, chatListLoading, executeChatListQuery])
  const inviteToChatGroup = async (groupId: string, emails: string[]) => {
    if (chatCredentials?.authToken && !startInviteToChatLoading)
      await executeInviteToChatGroupMutation({
        variables: {
          chatAuthToken: chatCredentials?.authToken,
          groupId,
          emails
        }
      })
  }
  const fetchChatGroupMembers = async (groupId: string) => {
    if (chatCredentials?.authToken && !chatMembersLoading)
      await executeChatGroupMembersQuery({
        variables: {
          chatAuthToken: chatCredentials?.authToken,
          groupId
        }
      })
  }
  // render helpers
  const getChatList = () => {
    return !chatListLoading && chatListData ? chatListData?.listChatGroups || [] : []
  }
  const getCurrentChatUsers = () => {
    const groupMembersList = chatMembersData?.listChatGroupMembers || []

    return getUsers().filter(
      ({ id }) => !groupMembersList.some(({ id: existingId }) => existingId === id)
    )
  }

  return (
    <Component
      startChatGroup={startChatGroup}
      getChatList={getChatList}
      getCurrentChatUsers={getCurrentChatUsers}
      inviteToChatGroup={inviteToChatGroup}
      fetchChatGroupMembers={fetchChatGroupMembers}
      chatMembersLoading={chatMembersLoading}
      {...props}
    />
  )
}

export default withChatGroup
