import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  Grid,
  Box,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Container,
  Checkbox,
  InputBase
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import CheckIcon from '@material-ui/icons/Check'
import moment from 'moment'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { RegistrationFormModel } from './RegistrationFormModel'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import { Urlservice } from '../../../../views/public/serviceHelpers/urlhelper'

interface ReviewFormProps {
  nextStep: () => void
  prevStep: () => void
  values: any
  handleChange: (input: string) => any
  isWaitlistFlow?: boolean
}
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3.5)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      // border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase)

export const ReviewForm = (props: ReviewFormProps) => {
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  const { nextStep, handleChange, values, prevStep, isWaitlistFlow } = props
  const classes = useStyles()
  const history = useHistory()
  const { url } = useRouteMatch()
  const [initialValues, setInitialValues] = useState(values)
  const [selectAgreement, setSelectAgreement] = useState(false)
  const [consentTraveling, setConsentTraveling] = useState(false)
  const [shareDetails, setShareDetails] = useState(false)
  const [consentHealth, setConsentHealth] = useState(false)
  const [consentHealthValue, setConsentHealthValue] = useState(false)
  const [selectAgreementMsg, setSelectAgreementMsg] = useState('')
  // const [consent_person, setConsentName] = useState('')
  const [consentFaq, setConsentFaq] = useState(false)
  const [legalAuth, setLegalAuth] = useState(false)
  // console.log('values', { ...values })
  const [loading, setLoading] = useState(false)
  const [openConsent, setOpenConsent] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [dropDownData, setDropDownData] = useState<any>({})
  const { enqueueSnackbar } = useSnackbar()
  const services = {
    'In-person': `${t('Registration.inperson')}`,
    Telehealth: `${t('Registration.online')}`
  }
  const nextHandler = (e) => {
    e.preventDefault()
    nextStep()
  }
  const handleCloseConsent = () => {
    setOpenConsent(false)
  }
  const handleopenconsent = () => {
    setOpenConsent(true)
  }
  const prevStepHandler = (e) => {
    // e?.preventDefault()
    prevStep()
  }
  const handleNameChange = (value) => {
    // setConsentName(value.target.value)
    setInitialValues({ ...initialValues, consent_person: value.target.value })
  }
  const handleRelationChange = (value) => {
    // setConsentName(value.target.value)
    // console.log('value', value)
    setInitialValues({ ...initialValues, relationship_youth: { id: value.target.value, name: '' } })
  }
  const studentdata = React.useCallback(() => {
    Urlservice.geturl('signal/studentdata')

      .then((ques) => {
        setDropDownData(ques.data)
        console.log(ques.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    studentdata()
  }, [studentdata])
  const ages = moment()
  const calAge = moment(`${initialValues.birth_date}`, 'YYYY')
  const studentAge = ages.diff(calAge, 'years')
  // console.log(studentAge)
  const sumbit = () => {
    setSubmitted(true)
    // console.log('2222', initialValues)

    if (!initialValues.consent_person || !initialValues.relationship_youth?.id) return
    if (
      isWaitlistFlow &&
      ((initialValues?.session_service.id === 1 &&
        initialValues?.travel &&
        initialValues?.share_details &&
        initialValues?.consent &&
        initialValues?.legal_authority &&
        consentHealthValue) ||
        (initialValues?.session_service.id !== 1 &&
          initialValues?.share_details &&
          initialValues?.consent &&
          consentHealthValue))
    ) {
      if (values?.signal_slots && values?.signal_therapist) {
        delete values.signal_slots
        delete values.signal_therapist
      }

      setLoading(true)
      // console.log('1111', values)
      const consent_waitlist = {
        ...values,
        consent_person: initialValues.consent_person,
        consent: initialValues.consent,
        legal_authority: initialValues.legal_authority,
        relationship_youth: initialValues.relationship_youth,
        travel: initialValues.travel,
        share_details: initialValues.share_details
      }
      Urlservice.posturl('signal/waitliststudent', consent_waitlist)
        .then((waitlistdata) => {
          setLoading(false)
          sessionStorage.setItem('s_name', `${initialValues.first_name} ${initialValues.last_name}`)
          history.push(`${url}/confirm?wl=true`)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    } else if (
      !isWaitlistFlow &&
      ((initialValues?.session_service.id === 1 &&
        initialValues?.travel &&
        initialValues?.share_details &&
        initialValues?.consent &&
        initialValues?.legal_authority &&
        consentHealthValue) ||
        (initialValues?.session_service.id !== 1 &&
          initialValues?.share_details &&
          initialValues?.consent &&
          consentHealthValue))
    ) {
      setLoading(true)
      Urlservice.posturl('signal/studentupdate', initialValues)
        .then((surveyData) => {
          setLoading(false)

          if (
            typeof surveyData?.data?.info === 'string' &&
            surveyData?.data?.info?.includes('Selected slot has been booked')
          ) {
            enqueueSnackbar(`${t('errormessages.slotbook')}`, {
              variant: 'error'
            })
            prevStepHandler('')
          } else if (
            typeof surveyData?.data?.info === 'string' &&
            surveyData?.data?.info?.includes('Entered email already registered')
          ) {
            enqueueSnackbar('Entered email already registered. Please take survey again', {
              variant: 'info'
            })
            history.push(`/public/survey`)
          } else if (
            typeof surveyData?.data?.message === 'string' &&
            surveyData?.data?.message?.includes('Student registered sucessfully')
          ) {
            sessionStorage.setItem(
              's_name',
              `${initialValues.first_name} ${initialValues.last_name}`
            )
            history.push(`${url}/confirm`)
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.message || `${t('errormessages.reload')}`, {
            variant: 'error'
          })
        })
    }
    // console.log(initialValues)
    // setSelectAgreement(true)
    // else if (
    //   initialValues?.session_service.id === 1 &&
    //   !initialValues?.travel &&
    //   !initialValues?.share_details &&
    //   !consentHealthValue
    // ) {
    //   setShareDetails(true)
    //   setConsentTraveling(true)
    //   setConsentHealth(true)
    // }
    // setSelectAgreementMsg('Please select the above two agreements')
    if (!consentHealthValue) setConsentHealth(true)
    if (!initialValues?.share_details) setShareDetails(true)
    // setSelectAgreementMsg('Please select the share_details agreements')
    if (!initialValues?.travel) setConsentTraveling(true)
    if (!initialValues?.consent) setConsentFaq(true)
    if (!initialValues?.legal_authority) setLegalAuth(true)
    // setSelectAgreementMsg('Please select the Travel agreements')
  }
  const checkBoxHandel = (key, value) => {
    if (key === 'shareDetails') {
      if (value) setShareDetails(false)
      else setShareDetails(true)

      setInitialValues({ ...initialValues, share_details: value })
    } else if (key === 'consentTraveling') {
      if (value) setConsentTraveling(false)
      else setConsentTraveling(true)

      setInitialValues({ ...initialValues, travel: value })
    } else if (key === 'consentHealth') {
      if (value) setConsentHealth(false)
      else setConsentHealth(true)

      setConsentHealthValue(value)
    } else if (key === 'consentFAQ') {
      if (value) setConsentFaq(false)
      else setConsentFaq(true)

      setInitialValues({ ...initialValues, consent: value })
    } else if (key === 'legal_authority') {
      if (value) setLegalAuth(false)
      else setLegalAuth(true)

      setInitialValues({ ...initialValues, legal_authority: value })
    }
    // setInitialValues({ ...initialValues, share_details: value })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* First Row */}
      <Grid container md={12} lg={12} sm={12} direction="row">
        {loading && <ProgressIndicator fullHeight />}
        <Grid container sm={12}>
          {/* first row first half */}
          <Grid item sm={6}>
            <h2 className={`${classes.subHeader} ${classes.registrationSubmitColor}`}>
              {t('review.contact')}
            </h2>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={3}>
                <b>{t('review.name')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                <p
                  style={{ marginTop: '0px', marginBottom: '2px' }}
                >{`${values?.first_name} ${values?.last_name} `}</p>
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={3}>
                <b>{t('review.address')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                {`${values?.address}`}
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={3}>
                <b>{t('review.county')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                {values?.county?.name}
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={3}>
                <b>{t('review.Email')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                {values?.contact_email}
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={3}>
                <b>{t('review.Phone')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                {values?.contact_phone_number}
              </Grid>
            </Grid>
          </Grid>
          {/* first row second half */}
          <Grid item sm={6}>
            <h2 className={`${classes.subHeader} ${classes.registrationSubmitColor}`}>
              {t('review.Preferences')}
            </h2>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={6}>
                <b>{t('review.Language')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue} ${classes.wordBreak}`}>
                {locallang === 'en'
                  ? values?.primaryLanguage_id?.name
                  : values?.primaryLanguage_id?.spanish_name}
              </Grid>
            </Grid>
            <Grid container className={classes?.fieldInfo}>
              <Grid item xs={5} sm={6}>
                <b>{t('review.In person, Online')}</b>
              </Grid>
              <Grid item xs={5} sm={6} className={`${classes.fieldInfoValue}`}>
                <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                  {services[values?.session_service?.name]}
                </p>
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={12} sm={6}>
                <b>{t('review.Special Requests')}</b>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={`${classes.fieldInfoValue} ${classes.wordBreak}`}
              >
                {values?.accommodations}
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={6}>
                <b>{t('review.Area of need')}</b>
              </Grid>
              <Grid item xs={5} sm={6} className={`${classes.fieldInfoValue}`}>
                {values?.area_of_need?.map((itm) => {
                  return (
                    <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                      {locallang === 'en' ? itm?.name : itm.spanish_name}
                    </p>
                  )
                })}
              </Grid>
            </Grid>
            <Grid container className={classes.fieldInfo}>
              <Grid item xs={5} sm={6}>
                <b>{t('review.Insurance')}</b>
              </Grid>
              <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue}`}>
                <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                  {locallang === 'en'
                    ? values?.insurance_company?.name
                    : values?.insurance_company?.spanish_name}
                  {/* {values?.insurance_company?.name} */}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* second Row */}
      <Grid container md={12} lg={12} sm={12} direction="row" className={classes.provider}>
        <h2 className={`${classes.subHeader} ${classes.registrationSubmitColor}`}>
          {t('review.Provider and Session Requested')}
        </h2>
        <Grid container className={classes.fieldInfo}>
          <Grid item xs={5} sm={4}>
            <b>{t('review.Provider')}</b>
          </Grid>
          <Grid item xs={7} sm={6} className={`${classes.fieldInfoValue}`}>
            {isWaitlistFlow && (
              <p style={{ marginTop: '0px', marginBottom: '2px' }}>{t('review.pending')}</p>
            )}
            {!isWaitlistFlow && (
              <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                {' '}
                {values?.therapist?.Username}
              </p>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.fieldInfo}>
          <Grid item xs={5} sm={4}>
            <b>{t('review.Session Date and Time')}</b>
          </Grid>
          <Grid item xs={7} sm={6} className={classes.fieldInfoValue}>
            {isWaitlistFlow && (
              <p style={{ marginTop: '0px', marginBottom: '2px' }}>{t('review.pending')}</p>
            )}
            {!isWaitlistFlow && (
              <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                {' '}
                {`${moment(values?.selected_slots?.timeperiod).utc().format('MM-DD-YYYY')}, ${
                  values?.selected_slots?.student_time_slot
                }`}
              </p>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* Thirs Row */}
      <h2 className={`${classes.subHeader} ${classes.registrationSubmitColor}`}>
        {t('review.Consent')}
      </h2>
      {/* {values?.session_service?.id === 1 && values?.session_service?.id === 2 && ( */}
      <Box mb={2} className={classes.containerWidth}>
        <Grid container direction="row" spacing={1}>
          <Grid item sm={6}>
            <InputLabel
              htmlFor="label-consentPersion"
              className={classes.inputLabel}
              style={{ color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <b>{t('review.consent15')}</b>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              htmlFor="label-relationship"
              // className={classes.inputLabel}
              // className={i18n?.language === 'es' ? classes.pt20 : ''}
              style={{ color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <b>{t('review.Relationship')}</b>
            </InputLabel>
          </Grid>
          <Grid item sm={6}>
            <TextField
              style={{ marginTop: '-3px' }}
              className={classes.inputField}
              id="label-consentName"
              fullWidth
              variant="outlined"
              name="consent_name"
              required
              // defaultValue={values.email}
              onChange={(value) => handleNameChange(value)}
            />
            {submitted && !initialValues.consent_person && (
              <div style={{ color: 'red' }}>{t('review.consent16')}</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {dropDownData?.signal_relationship_youth &&
            dropDownData?.signal_relationship_youth?.length > 0 ? (
              <Select
                style={{ width: '350px', marginTop: '-3px' }}
                labelId="label-relationship"
                id="label-relationship"
                // className={classes.dropDown}
                input={<BootstrapInput />}
                variant="outlined"
                name="relationship"
                required
                onChange={(value) => handleRelationChange(value)}

                // onChange={(value) => updateFormData('county', value.target.value)}
              >
                {dropDownData?.signal_relationship_youth?.map((dropDown) => {
                  // if (studentAge <= 12)
                  if (!(dropDown.id === 1 && studentAge < 12))
                    return (
                      <MenuItem value={dropDown.id}>
                        {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                      </MenuItem>
                    )

                  return <></>
                })}
              </Select>
            ) : (
              <Select
                id="label-relationship"
                labelId="label-relationship"
                input={<BootstrapInput />}
                variant="filled"
                name="relationship"
                // className={classes.dropDown}
              >
                <MenuItem>No Data Found</MenuItem>
              </Select>
            )}
            {submitted && !initialValues.relationship_youth?.id && (
              <div style={{ color: 'red', marginTop: '3px' }}>{t('review.Relationship1')}</div>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container spacing={2} sm={12} direction="row"> */}
      {/* <Grid md={3} item alignItems="center">
          <b>{t('review.consent15')}</b>
        </Grid> */}
      {/* <Grid item style={{ width: '160px' }}>
          <TextField
            style={{ marginTop: '-3px' }}
            className={classes.inputField}
            id="label-consentName"
            fullWidth
            variant="outlined"
            name="consent_name"
            required
            // defaultValue={values.email}
            onChange={(value) => handleNameChange(value)}
          />
          {submitted && !initialValues.consent_person && (
            <div style={{ color: 'red' }}>{t('review.consent16')}</div>
          )}
        </Grid> */}
      {/* <Grid md={3} item style={{ paddingTop: '17px' }} alignItems="center">
          <b>{t('review.Relationship')}</b>
        </Grid> */}
      {/* <Grid>
          {dropDownData?.signal_relationship_youth &&
          dropDownData?.signal_relationship_youth?.length > 0 ? (
            <Select
              style={{ width: '160px', marginTop: '-3px' }}
              labelId="label-relationship"
              id="label-relationship"
              className={classes.dropDown}
              input={<BootstrapInput />}
              variant="outlined"
              name="relationship"
              required
              onChange={(value) => handleRelationChange(value)}

              // onChange={(value) => updateFormData('county', value.target.value)}
            >
              {dropDownData?.signal_relationship_youth?.map((dropDown) => {
                // if (dropDown.id !== 1)
                return (
                  <MenuItem value={dropDown.id}>
                    {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                  </MenuItem>
                )

                return <></>
              })}
            </Select>
          ) : (
            <Select
              id="label-relationship"
              labelId="label-relationship"
              input={<BootstrapInput />}
              variant="filled"
              name="relationship"
              className={classes.dropDown}
            >
              <MenuItem>No Data Found</MenuItem>
            </Select>
          )}
          {submitted && !initialValues.relationship_youth?.id && (
            <div style={{ color: 'red' }}>{t('review.Relationship1')}</div>
          )}
        </Grid> */}
      {/* </Grid> */}
      {/* )} */}
      {/* {values?.session_service?.id === 1 && values?.session_service?.id === 2 && ( */}
      <>
        <Grid container xs={12} md={12} lg={12} sm={12} direction="row">
          <Grid item xs={2} sm={1}>
            <Checkbox
              style={{
                color: consentFaq ? 'red' : '#AAAAAA',
                paddingLeft: '18px',
                paddingBottom: '0px'
              }}
              // onChange={(e) => setInitialValues({ ...initialValues, travel: e.target.checked })}
              onChange={(e) => checkBoxHandel('consentFAQ', e.target.checked)}
            />
            {consentFaq && (
              <div style={{ color: 'red' }}>
                {' '}
                <b>{t('review.RequiredMsg')}</b>
              </div>
            )}
          </Grid>
          <Grid item xs={10} sm={11}>
            <div style={{ marginTop: '10px' }} className={classes.fieldInfoValue}>
              {t('review.checkFAQ')}{' '}
              <a
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  textDecorationColor: '#000'
                }}
                role="button"
                tabIndex={0}
                onClick={handleopenconsent}
                onKeyDown={handleopenconsent}
              >
                {t('review.checkFA')}
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} lg={12} sm={12} direction="row">
          <Grid item xs={2} sm={1}>
            <Checkbox
              style={{
                color: legalAuth ? 'red' : '#AAAAAA',
                paddingLeft: '18px',
                paddingBottom: '0px'
              }}
              // onChange={(e) => setInitialValues({ ...initialValues, travel: e.target.checked })}
              onChange={(e) => checkBoxHandel('legal_authority', e.target.checked)}
            />
            {legalAuth && (
              <div style={{ color: 'red' }}>
                {' '}
                <b>{t('review.RequiredMsg')}</b>
              </div>
            )}
          </Grid>
          <Grid item xs={10} sm={11}>
            <div style={{ marginTop: '10px' }} className={classes.fieldInfoValue}>
              {t('review.checkLegalAuth')}
            </div>
          </Grid>
        </Grid>
      </>
      {/* )} */}
      <Grid container md={12} lg={12} sm={12} direction="row">
        <Grid item xs={2} sm={1}>
          <Checkbox
            style={{
              color: consentHealth ? 'red' : '#AAAAAA',
              paddingLeft: '18px',
              paddingBottom: '0px'
            }}
            onChange={
              (e) => checkBoxHandel('consentHealth', e.target.checked)
              // setInitialValues({ ...initialValues, share_details: e.target.checked })
            }
          />
          {consentHealth && (
            <div style={{ color: 'red' }}>
              {' '}
              <b>{t('review.RequiredMsg')}</b>
            </div>
          )}
        </Grid>
        <Grid item xs={10} sm={11}>
          <div style={{ marginTop: '10px' }} className={classes.fieldInfoValue}>
            {t('review.check1')}
          </div>
        </Grid>
      </Grid>
      {values?.session_service?.id === 1 && (
        <Grid container xs={12} md={12} lg={12} sm={12} direction="row">
          <Grid item xs={2} sm={1}>
            <Checkbox
              style={{
                color: consentTraveling ? 'red' : '#AAAAAA',
                paddingLeft: '18px',
                paddingBottom: '0px'
              }}
              // onChange={(e) => setInitialValues({ ...initialValues, travel: e.target.checked })}
              onChange={(e) => checkBoxHandel('consentTraveling', e.target.checked)}
            />
            {consentTraveling && (
              <div style={{ color: 'red' }}>
                {' '}
                <b>{t('review.RequiredMsg')}</b>
              </div>
            )}
          </Grid>
          <Grid item xs={10} sm={11}>
            <div style={{ marginTop: '10px' }} className={classes.fieldInfoValue}>
              {t('review.check2')}
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container md={12} lg={12} sm={12} direction="row">
        <Grid item xs={2} sm={1}>
          <Checkbox
            style={{
              color: shareDetails ? 'red' : '#AAAAAA',
              paddingLeft: '18px',
              paddingBottom: '0px'
            }}
            onChange={
              (e) => checkBoxHandel('shareDetails', e.target.checked)
              // setInitialValues({ ...initialValues, share_details: e.target.checked })
            }
          />
          {shareDetails && (
            <div style={{ color: 'red' }}>
              {' '}
              <b>{t('review.RequiredMsg')}</b>
            </div>
          )}
        </Grid>
        <Grid item xs={10} sm={11}>
          <div style={{ marginTop: '10px' }} className={classes.fieldInfoValue}>
            {t('review.check3')}
          </div>
        </Grid>
      </Grid>
      {/* {selectAgreement && (
        <div
          style={{ padding: '10px', width: '100%', color: 'red' }}
          className={classes.fieldInfoValue}
        >
          {selectAgreementMsg}
        </div>
      )} */}
      {/* Third Row */}
      <Grid container direction="row" md={12} spacing={2} style={{ paddingTop: '15px' }}>
        <Grid item xs={6} md={2} sm={3}>
          <Button
            variant="outlined"
            onClick={prevStepHandler}
            startIcon={<NavigateBeforeIcon>Next</NavigateBeforeIcon>}
            className={classes.customBtnCls}
          >
            {t('registrationform.back')}
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.customBtnCls} ${classes.registrationSubmitStep}`}
            startIcon={<CheckIcon>Next</CheckIcon>}
            style={{ backgroundColor: '#52b7ec' }}
            onClick={() => sumbit()}
          >
            {t('registrationform.submit')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={openConsent}
        maxWidth="md"
        onClose={handleCloseConsent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div>
            <h2>{t('review.consent1')}</h2>
          </div>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <b>{t('review.consent2')}</b>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <b>{t('review.consent3')}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{t('review.consent4')}</TableCell>
                  <TableCell>{t('review.consent5')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('review.consent6')}</TableCell>
                  <TableCell>{t('review.consent7')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('review.consent8')}</TableCell>
                  <TableCell>{t('review.consent9')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('review.consent10')}</TableCell>
                  <TableCell>{t('review.consent11')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('review.consent12')}</TableCell>
                  <TableCell>{t('review.consent13')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ textAlign: 'center', padding: '15px' }}>
            <Button
              onClick={handleCloseConsent}
              variant="contained"
              color="primary"
              style={{ fontWeight: 'normal' }}
            >
              {t('review.consent14')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
