import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, TextField, Link } from '@material-ui/core'
import { Redirect, useHistory } from 'react-router-dom'
import { theme } from 'config/constants/theme'
import { useForgetPasswordMutation } from 'graphql/hooks.generated'
import InputAdornment from '@material-ui/core/InputAdornment'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import MailIcon from '@material-ui/icons/Mail'
import ProgressIndicator from '../../panel/progress-indicator/ProgressIndicator'
import { InfoButton } from '../../panel/button/InfoButton'
import Paper from '../../panel/paper/Paper'
import AppBar from '../../panel/app-bar/AppBar'
import Footer from '../../panel/footer/Footer'
import { useAuth } from '../../../../hooks/useAuth'
import ForgotPasswordForm from './ForgotPasswordForm'

const backgroundStyle = {
  backgroundImage: 'url(illustrations.png)',
  backgroundPosition: 'center',
  backgroundSize: 'auto 430px',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh'
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    leftIcon: {
      color: 'slategray'
    }
  })
)

export const Forgotpassword = () => {
  const { isAuthenticated } = useAuth()
  const history = useHistory()
  const initailValues = { email: '' }
  const [open, setOpen] = useState<boolean>(false)
  const [formValues, setFormValues] = useState(initailValues)
  const [formErrors, setFormErrors] = useState({ email: '' })
  // const [isformSubmitted, setIsformSubmitted] = useState<boolean>(false)
  const [showSpinner, setSpinner] = useState<boolean>(false)
  const [otpcallErrMsg, setOTPsendErrMsg] = useState<string>('')
  const [forgetPasswordMutation] = useForgetPasswordMutation()
  useEffect(() => {})
  const classes = useStyles()

  if (isAuthenticated) return <Redirect to="/" />

  const handleClose = () => {
    setOpen(false)
  }
  const handleChanges = (e) => {
    setFormValues({ email: e.target.value })
    setFormErrors({ email: '' })
  }
  const validateFormErrors = (values) => {
    // console.log(values)
    const errors = { email: '' }

    if (!values.email) errors.email = 'Required'
    else if (values.email) {
      const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      if (!emailPattern.test(values.email)) errors.email = 'Please provide valid email'
    }

    return errors
  }
  const forgotPasswordFun = async (Username) => {
    setSpinner(true)
    try {
      await forgetPasswordMutation({ variables: { input: { Username } } })
      setSpinner(false)
      setOpen(true)
    } catch (e) {
      // console.log('error:=============', e)
      setSpinner(false)
      const errmsg = e.graphQLErrors[0].message
      setOTPsendErrMsg(errmsg)
    }
  }
  const sendForOTP = () => {
    const validationErrors = validateFormErrors(formValues)
    let hasErrors = false
    Object.values(validationErrors).forEach((value) => {
      if (value) hasErrors = true
    })

    if (hasErrors) setFormErrors(validationErrors)
    else forgotPasswordFun(formValues.email)
  }
  const handleEnterKey = (ev) => {
    // console.log(`Pressed keyCode ${ev.key}`)
    if (ev.key === 'Enter') ev.preventDefault()
  }

  return (
    <Grid
      style={backgroundStyle}
      container
      direction="column"
      justify="space-between"
      alignItems="center"
    >
      <AppBar />
      {showSpinner ? <ProgressIndicator fullHeight /> : ''}
      <Grid container style={{ minHeight: 'calc(100vh - 60px - 75px)' }}>
        <Grid item sm={5} md={4} lg={3} style={{ margin: 'auto' }}>
          <Paper style={{ border: 'solid 1px #c7c7c7' }}>
            <Box px={3} py={4}>
              <Typography variant="h1">Forgot Password?</Typography>
              <p style={{ fontSize: '12px' }}>
                Enter the email address you used when you joined and we will send you instructions
                to reset your password!
              </p>
            </Box>
            <Box p={3} pt={0}>
              <form>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={12} xs={12} lg={12}>
                    <TextField
                      label="Email"
                      fullWidth
                      variant="filled"
                      name="email"
                      onChange={handleChanges}
                      onKeyPress={(ev) => handleEnterKey(ev)}
                    />
                  </Grid>
                  {formErrors.email && (
                    <Grid item style={{ paddingTop: '0px' }}>
                      <div style={{ color: theme.palette.error.dark }}>{formErrors.email}</div>
                    </Grid>
                  )}
                  {otpcallErrMsg && (
                    <Grid item style={{ paddingTop: '0px' }}>
                      <div style={{ color: theme.palette.error.dark }}>{otpcallErrMsg}</div>
                    </Grid>
                  )}
                  <Grid item sm={12} md={12} xs={12} lg={12}>
                    <Box display="flex" p={0} mt={1} justifyContent="center" alignItems="center">
                      <InfoButton
                        // disabled={!formValues.email}
                        variant="contained"
                        size="large"
                        type="button"
                        onClick={() => sendForOTP()}
                      >
                        Submit
                      </InfoButton>
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={12} xs={12} lg={12}>
                    <Box display="flex" p={0} justifyContent="center" alignItems="center">
                      <Link
                        color="inherit"
                        component="button"
                        style={{ color: theme.palette.info.dark }}
                        onClick={() => history.push(`/login`)}
                      >
                        Login
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          {open && (
            <>
              <ForgotPasswordForm email={formValues.email} open={open} handleClose={handleClose} />
            </>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  )
}
