import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import {
  Grid,
  Box,
  Typography,
  Divider,
  MenuItem,
  InputLabel,
  Container,
  FormControl,
  Tooltip,
  Input
} from '@material-ui/core'
// import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { TextField, Select, DatePicker, makeValidate, Checkboxes, CheckboxData } from 'mui-rff'
import { Form, FormRenderProps } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import InputBase from '@material-ui/core/InputBase'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'
import moment from 'moment'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import { Urlservice } from '../../../../views/public/serviceHelpers/urlhelper'
import { RegistrationNewFormModel } from './RegistrationFormModel'
// import { RegistrationValidator } from './registrationValidator'
import { PhoneInput } from '../../form/phone-input/PhoneInput'

interface RegistrationFormProps {
  onSubmit(values: RegistrationNewFormModel): void
  initialValues?: Partial<RegistrationNewFormModel> | null
  nextStep: () => void
  handleChange: (input: any) => any
  values: any
}

const RegistrationValidator = () =>
  makeValidate(
    yup.object<RegistrationNewFormModel>({
      id: yup.mixed<number>().label(''),
      last_name: yup
        .mixed<string>()
        .label('Last Name')
        .required(`${t('errormessages.lastname')}`),
      first_name: yup
        .mixed<string>()
        .label('First Name')
        .required(`${t('errormessages.firstname')}`),
      contact_phone_number: yup
        .mixed<string>()
        .label('Phone Number')
        .required(`${t('errormessages.phone')}`),
      birth_date: yup
        .date()
        .label('Date of birth')
        .nullable()
        .required(`${t('errormessages.dob')}`)
        .test('DOB', `${t('errormessages.dob2')}`, (value) => {
          return !(moment().diff(value, 'years', false) >= 21)
        }),
      gender: yup
        .mixed<string>()
        .label('Gender')
        .nullable()
        .required(`${t('errormessages.gender')}`),
      pronouns: yup
        .mixed<number>()
        .label('Pronouns')
        .required(`${t('errormessages.pronouns')}`),
      contact_email: yup
        .string()
        .email(`${t('errormessages.Vmail')}`)
        .label('Email address')
        .nullable()
        .required(`${t('errormessages.email')}`),
      zip: yup
        .number()
        .label('Zip code')
        .required(`${t('errormessages.zip')}`)
        .typeError(`${t('errormessages.zip1')}`)
        .test('len', `${t('errormessages.zip2')}`, (val) => val?.toString().length === 5),
      county: yup
        .mixed<number>()
        .label('County')
        .required(`${t('errormessages.county')}`),
      address: yup
        .string()
        .label('Address')
        .nullable()
        .required(`${t('errormessages.address')}`),
      parent1_phone_number: yup.mixed<string>().label('Parent #1 Number'),
      parent2_phone_number: yup.mixed<string>().label('Parent #2 Number'),
      parent1_email: yup
        .string()
        .email(`${t('errormessages.Pmail')}`)
        .label('Parent/ Guardian/ Responsible Party #1 Email')
        .nullable()
        .when('birth_date', (birth_date, parentEmailSchema: yup.StringSchema) => {
          const studentAge = moment().diff(birth_date, 'years', false)

          if (studentAge < 12)
            return parentEmailSchema.required(`${t('errormessages.parent1_email')}`)

          return parentEmailSchema
        }),
      parent2_email: yup
        .string()
        .email(`${t('errormessages.Pmail')}`)
        .nullable()
        .notRequired()
        .label('Parent/ Guardian/ Responsible Party #2 Email'),
      middle_name: yup.mixed<string>().label('Middle Name'),
      parent_name: yup
        .mixed<string>()
        .label('​Parent/ Guardian/ Responsible Party #1 Name')
        .when('birth_date', (birth_date, parentSchema: yup.StringSchema) => {
          const studentAge = moment().diff(birth_date, 'years', false)

          if (studentAge < 12) return parentSchema.required(`${t('errormessages.parent1')}`)

          return parentSchema
        }),
      // .required(`${t('errormessages.parent1')}`),
      parent2_name: yup.mixed<string>().label('Parent/ Guardian/ Responsible Party #2 Name'),
      accommodations: yup.mixed<string>().label('Address'),
      area_of_need: yup.mixed<number[]>().label('Pronouns'),
      insurance_type: yup.mixed<number>().label('county'),
      insurance_relationship: yup.mixed<number>().label('county'),
      relationship_youth: yup.mixed<number>().label('relationship'),
      insurance_company: yup.mixed<number>().label('county'),
      city: yup
        .mixed<string>()
        .label('City')
        .required(`${t('errormessages.city')}`),
      session_service: yup.mixed<string>().label('county'),
      primaryLanguage_id: yup.mixed<number>().label('county'),
      surveyid: yup.mixed<string>(),
      phonecheck: yup.mixed<any>().label('phonecheck'),
      parentphonecheck1: yup.mixed<any>().label('parentphonecheck1'),
      parentphonecheck2: yup.mixed<any>().label('parentphonecheck2')
    })
  )
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3.5)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      // border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase)

export const RegistrationForm = (props: RegistrationFormProps) => {
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  const { nextStep, handleChange, initialValues, onSubmit, values } = props
  const [changeStatusList, setChangeStatusList] = useState(false)
  const params = new URLSearchParams(window.location.search)
  const classes = useStyles()
  const history = useHistory()
  const [checked, setChecked] = React.useState(false)
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }
  const [surveyStudentData, setSurveyStudentData] = useState(initialValues)
  const [dropDownData, setDropDownData] = useState<any>({})
  const DobTooltip = `${t('errormessages.dobttiptext')}`
  const initialData: RegistrationNewFormModel | null = values
    ? {
        id: values.id,
        surveyid: values.surveyid,
        // student_id: number
        first_name: values.first_name,
        last_name: values.last_name,
        // district_id: number
        // school_id: number
        // icdCode_id: number
        // grade_id: number
        primaryLanguage_id:
          typeof values.county === 'object'
            ? values?.primaryLanguage_id?.id
              ? values?.primaryLanguage_id?.id
              : null
            : values.primaryLanguage_id,
        contact_phone_number: values.contact_phone_number,
        // phonecheck: changeStatusList === true ? 1 : 0,
        contact_email: values.contact_email,
        birth_date: values.birth_date,
        gender: values.gender,
        parent1_phone_number: values.parent1_phone_number,
        parent1_email: values.parent1_email,
        phonecheck: values.phonecheck === true ? 1 : 0,
        parentphonecheck1: values.parentphonecheck1 === true ? 1 : 0,
        parentphonecheck2: values.parentphonecheck2 === true ? 1 : 0,
        middle_name: values.middle_name,
        pronouns: values?.pronouns?.id ? values?.pronouns?.id : values?.pronouns,
        address: values.address,
        // student_gender: null
        county: typeof values.county === 'object' ? null : values.county,
        accommodations: values.accommodations,
        area_of_need:
          values?.area_of_need?.length > 0
            ? typeof values.area_of_need?.[0] === 'object'
              ? values.area_of_need?.map((itm) => {
                  return itm.id
                })
              : values.area_of_need
            : undefined,
        insurance_type: values.insurance_type,
        insurance_relationship: values.insurance_relationship,
        insurance_company: values.insurance_company,
        relationship_youth: values.relationship_youth,
        city: values.city,
        zip: values.zip,
        session_service: values.session_service,
        // systemtime: Date
        parent_name: values.parent_name,
        parent2_name: values.parent2_name,
        parent2_email: values.parent2_email,
        parent2_phone_number: values.parent2_phone_number
      }
    : null
  const { enqueueSnackbar } = useSnackbar()
  const emailCheck = React.useCallback(
    (value) => {
      Urlservice.posturl('signal/validationcheck', { contact_email: value })
        .then((validMail) => {
          if (validMail?.data !== 0) {
            enqueueSnackbar(`${t('errormessages.Eemail')}`, { variant: 'error' })

            return 0
          }

          return 1
        })
        .catch((error) => {
          console.log(error)

          return true
        })
    },
    [enqueueSnackbar, t]
  )
  const nextHandler = React.useCallback(
    async (e) => {
      // const emailValidation = await emailCheck(e?.contact_email)
      Urlservice.posturl('signal/validationcheck', { contact_email: e?.contact_email })
        .then((validMail) => {
          if (validMail?.data !== 0)
            enqueueSnackbar(`${t('errormessages.Eemail')}`, { variant: 'error' })
          else {
            handleChange(e)
            nextStep()
          }
        })
        .catch((error) => {
          console.log(error)

          return true
        })
      // if (emailValidation === 1) {
      //   handleChange(e)
      //   nextStep()
      // }
    },
    [nextStep, handleChange, enqueueSnackbar, t]
  )
  const cancelHandler = () => {
    history.push(`/public/register`)
  }
  const updateFormData = (fieldName, value) => {
    const county = dropDownData?.signal_county?.filter((coun) => coun.id === value)[0]
    setSurveyStudentData({
      ...surveyStudentData,
      [fieldName]: fieldName === 'county' ? county : value
    })
  }
  const studentdata = React.useCallback(() => {
    Urlservice.geturl('signal/studentdata')
      .then((ques) => {
        setDropDownData(ques.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const surveyIdData = () => {
    const surveyIdSession = sessionStorage.getItem('sid')
    // const surveyId = {
    //   surveyid: surveyIdSession
    // }
    const surveyId = {
      surveyid: '07129c28-57f4-48f5-a490-46abd2117c5d'
    }
    Urlservice.posturl('signal/studentinfo', surveyId)
      .then((surveyData) => {
        setSurveyStudentData(surveyData.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const changeStatusOfList = (e) => {
    setChangeStatusList(e.target.checked)
  }
  const checkboxData: CheckboxData[] = [{ label: t('review.phonecheck'), value: true }]
  const checkboxData1: CheckboxData[] = [{ label: t('review.phonecheck'), value: true }]
  const checkboxData2: CheckboxData[] = [{ label: t('review.phonecheck'), value: true }]
  const validator = useMemo(() => RegistrationValidator(), [])
  useEffect(() => {
    window.scrollTo(0, 0)
    // surveyIdData()
  }, [])
  useEffect(() => {
    studentdata()
  }, [studentdata])
  const renderForm = (renderProps: FormRenderProps<RegistrationNewFormModel>) => {
    const { handleSubmit, values } = renderProps
    const birthDates = values.birth_date
    const studentAge = moment().diff(birthDates, 'years', false)

    return (
      <form>
        {/* First Row */}
        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={4}>
              <InputLabel htmlFor="label-firstname" className={classes.inputLabel}>
                {t('registrationform.fname')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-firstname"
                fullWidth
                variant="outlined"
                name="first_name"
                // onChange={(value) => updateFormData('first_name', value.target.value)}
                // onChange={handleChange('first_name')}
                // value={values?.first_name}
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel htmlFor="label-mi" className={classes.inputLabel}>
                {/* MI */}
                {t('registrationform.MI')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-mi"
                fullWidth
                variant="outlined"
                name="middle_name"
                // onChange={(value) => updateFormData('middle_name', value.target.value)}
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel htmlFor="label-lastname" className={classes.inputLabel}>
                {/* Last Name* */}
                {t('registrationform.lname')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-lastname"
                fullWidth
                variant="outlined"
                name="last_name"
                // onChange={(value) => updateFormData('last_name', value.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Second Row */}
        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Tooltip title={DobTooltip} placement="top-start">
              <Grid item xs={12} md={5} sm={12}>
                <InputLabel htmlFor="label-dob" className={classes.inputLabel}>
                  {t('registrationform.dob')}
                </InputLabel>
                <DatePicker
                  required
                  clearable
                  maxDate={new Date()}
                  name="birth_date"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  className={classes.inputPadding}
                  // className={classes.labelField}
                />

                {/* <TextField
                className={classes.inputField}
                id="label-dob"
                fullWidth
                variant="outlined"
                name="dob"
                type="date"
                onChange={(value) => updateFormData('birth_date', value.target.value)}
              /> */}
              </Grid>
            </Tooltip>
            <Grid item xs={12} md={2} sm={6}>
              {/* <FormControl> */}
              <InputLabel id="demo-customized-select-label" htmlFor="label-age">
                {t('registrationform.Gender')}
              </InputLabel>
              <Select
                name="gender"
                labelId="demo-customized-select-label"
                id="label-age"
                required
                input={<BootstrapInput />}
                className={classes.dropDown}
                variant="filled"
                // onChange={(value) => updateFormData('gender', value.target.value)}
              >
                <MenuItem value="Male">{t('genders.male')}</MenuItem>
                <MenuItem value="Female">{t('genders.female')}</MenuItem>
                <MenuItem value="Non-binary/third gender">{t('genders.third')}</MenuItem>
                <MenuItem value="Prefer not to say">{t('genders.prefer')}</MenuItem>
                <MenuItem value="Other">{t('genders.other')}</MenuItem>
              </Select>
              {/* </FormControl> */}
            </Grid>
            <Grid item xs={12} md={5} sm={6}>
              <InputLabel htmlFor="label-pronouns">{t('registrationform.​Pronouns')}</InputLabel>
              {dropDownData?.signal_pronouns && dropDownData?.signal_pronouns?.length > 0 ? (
                <Select
                  labelId="demo-customized-select-label"
                  id="label-age"
                  required
                  input={<BootstrapInput />}
                  name="pronouns"
                  className={classes.dropDown}
                  // onChange={(value) => updateFormData('pronouns', value.target.value)}
                >
                  {dropDownData?.signal_pronouns?.map((dropDown) => {
                    return (
                      <MenuItem value={dropDown.id}>
                        {locallang === 'en' ? dropDown?.name : dropDown?.spanish_name}
                      </MenuItem>
                    )
                  })}
                </Select>
              ) : (
                <Select
                  input={<BootstrapInput />}
                  variant="filled"
                  name="pronouns"
                  required
                  className={classes.dropDown}
                >
                  <MenuItem>No Data Found</MenuItem>
                </Select>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Third Row */}
        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={7}>
              <InputLabel htmlFor="label-email" className={classes.inputLabel}>
                {t('registrationform.email')}
              </InputLabel>
              <TextField
                required
                className={classes.inputField}
                id="label-email*"
                fullWidth
                variant="outlined"
                name="contact_email"
                onMouseOut={(value) => emailCheck(value.target)}
                // onChange={(value) => updateFormData('contact_email', value.target.value)}
              />
            </Grid>

            <Grid item sm={5}>
              <InputLabel htmlFor="label-phone" className={classes.inputLabel}>
                {t('registrationform.phone')}
              </InputLabel>
              <PhoneInput
                fullWidth
                required
                id="label-phone"
                variant="outlined"
                name="contact_phone_number"
                className={`${classes.inputPadding} ${classes.phoneHelperTxt}`}
                // input={<BootstrapInput />}
              />
            </Grid>
            <div>
              <Checkboxes
                // checked={checked}
                // // onChange={handleChangeCheckBox}
                // name="phonecheck"
                // inputProps={{ 'aria-label': 'controlled' }}
                // label="Check at least one..."
                name="phonecheck"
                required
                data={checkboxData}
              />
              {/* <span>{t('review.phonecheck')}</span> */}
            </div>
          </Grid>
        </Box>
        <Box mb={2} mt={2} className={classes.containerWidth}>
          <div
            style={{
              color: 'darkblue'
            }}
          >
            <strong>
              {moment().diff(moment(values.birth_date), 'years', false) >= 12
                ? `${t('registrationform.servicesText')}`
                : ''}
            </strong>
          </div>
        </Box>

        {/* Fourth Row */}
        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={7}>
              <InputLabel htmlFor="label-firstname" className={classes.inputLabel}>
                {t('registrationform.parentname')}
                {studentAge < 12 ? '*' : ''}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-firstname"
                fullWidth
                variant="outlined"
                name="parent_name"
                required
                // defaultValue={values.email}
                // onChange={(value) => updateFormData('parent_name', value.target.value)}
              />
            </Grid>
            <Grid item sm={5}>
              <InputLabel htmlFor="label-lastname" className={classes.inputLabel}>
                {t('registrationform.parentphone')}
              </InputLabel>
              <PhoneInput
                fullWidth
                required
                id="label-phone"
                variant="outlined"
                name="parent1_phone_number"
                className={`${classes.inputPadding} ${classes.phoneHelperTxt}`}
                // input={<BootstrapInput />}
              />
              {/* <TextField
                className={classes.inputField}
                id="label-lastname"
                fullWidth
                variant="outlined"
                name="parent1_phone_number"
                // onChange={(value) => updateFormData('parent1_phone_number', value.target.value)}
              /> */}
            </Grid>
            <div>
              <Checkboxes
                // checked={checked}
                // // onChange={handleChangeCheckBox}
                // name="phonecheck"
                // inputProps={{ 'aria-label': 'controlled' }}
                // label="Check at least one..."
                name="parentphonecheck1"
                required
                data={checkboxData1}
              />
              {/* <span>{t('review.phonecheck')}</span> */}
            </div>
          </Grid>
        </Box>

        {/* Fourth Row */}

        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={12}>
              <InputLabel htmlFor="label-firstname" className={classes.inputLabel}>
                {`${t('registrationform.parentemail')}`}
                {studentAge < 12 ? '*' : ''}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-firstname"
                fullWidth
                variant="outlined"
                name="parent1_email"
                required
                // defaultValue={values.email}
                // onChange={(value) => updateFormData('parent1_email', value.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={7}>
              <InputLabel htmlFor="label-firstname" className={classes.inputLabel}>
                {t('registrationform.parentname2')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-firstname"
                fullWidth
                variant="outlined"
                name="parent2_name"
                required
                // defaultValue={values.email}
                // onChange={(value) => updateFormData('parent_name', value.target.value)}
              />
            </Grid>
            <Grid item sm={5}>
              <InputLabel htmlFor="label-lastname" className={classes.inputLabel}>
                {t('registrationform.parentphone2')}
              </InputLabel>
              <PhoneInput
                fullWidth
                required
                id="label-phone"
                variant="outlined"
                name="parent2_phone_number"
                className={`${classes.inputPadding} ${classes.phoneHelperTxt}`}
                // input={<BootstrapInput />}
              />
              {/* <TextField
                className={classes.inputField}
                id="label-lastname"
                fullWidth
                variant="outlined"
                name="parent1_phone_number"
                // onChange={(value) => updateFormData('parent1_phone_number', value.target.value)}
              /> */}
            </Grid>
            <div>
              <Checkboxes
                // checked={checked}
                // // onChange={handleChangeCheckBox}
                // name="phonecheck"
                // inputProps={{ 'aria-label': 'controlled' }}
                // label="Check at least one..."
                name="parentphonecheck2"
                required
                data={checkboxData2}
              />
              {/* <span>{t('review.phonecheck')}</span> */}
            </div>
          </Grid>
        </Box>

        <Box mb={2} mt={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={12}>
              <InputLabel htmlFor="label-firstname" className={classes.inputLabel}>
                {t('registrationform.parentemail2')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-firstname"
                fullWidth
                variant="outlined"
                name="parent2_email"
                required
                // defaultValue={values.email}
                // onChange={(value) => updateFormData('parent1_email', value.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Sixth Row */}
        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={7}>
              <InputLabel htmlFor="label-address" className={classes.inputLabel}>
                {t('registrationform.address')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-address*"
                fullWidth
                variant="outlined"
                name="address"
                required
                // onChange={(value) => updateFormData('address', value.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              {/* <FormControl> */}
              <InputLabel htmlFor="label-county">{t('registrationform.county')}</InputLabel>
              {dropDownData?.signal_county && dropDownData?.signal_county?.length > 0 ? (
                <Select
                  labelId="label-county"
                  id="label-county"
                  className={classes.dropDown}
                  input={<BootstrapInput />}
                  variant="outlined"
                  name="county"
                  required
                  // onChange={(value) => updateFormData('county', value.target.value)}
                >
                  {dropDownData?.signal_county?.map((dropDown) => {
                    if (dropDown.id !== 1)
                      return <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>

                    return <></>
                  })}
                </Select>
              ) : (
                <Select
                  id="label-county"
                  labelId="label-county"
                  input={<BootstrapInput />}
                  variant="filled"
                  name="pronouns"
                  className={classes.dropDown}
                >
                  <MenuItem>No Data Found</MenuItem>
                </Select>
              )}
              {/* </FormControl> */}
            </Grid>
          </Grid>
        </Box>

        {/* seventh Row */}
        <Box mb={2} className={classes.containerWidth}>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={7}>
              <InputLabel htmlFor="label-city" className={classes.inputLabel}>
                {t('registrationform.citystate')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-city"
                fullWidth
                size="medium"
                variant="outlined"
                name="city"
                // disabled
                // value="Colorado, co"
                // onChange={(value) => updateFormData('city', value.target.value)}
              />
            </Grid>

            <Grid item sm={5}>
              <InputLabel htmlFor="label-zip" className={classes.inputLabel}>
                {t('registrationform.zip')}
              </InputLabel>
              <TextField
                className={classes.inputField}
                id="label-zip"
                fullWidth
                variant="outlined"
                name="zip"
              />
            </Grid>
          </Grid>
        </Box>

        {/* eighth Row */}
        <Box mb={2}>
          <Grid container direction="row">
            <Grid item sm={12}>
              <Button
                variant="outlined"
                className={classes.customBtnCls}
                onClick={() =>
                  history.push(`/public/survey/encourage?loc=${params.get('loc') || 'en'}`)
                }
              >
                {' '}
                {t('registrationform.cancel')}
              </Button>
              <Button
                className={`${classes.customBtnCls} ${classes.registrationStep} ${classes.ml20}`}
                // endIcon={<NavigateNextIcon>Next</NavigateNextIcon>}
                style={{ backgroundColor: '#52b7ec' }}
                onClick={handleSubmit}
              >
                {t('registrationform.next')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    )
  }

  return (
    <Form<RegistrationNewFormModel>
      initialValues={initialData ?? {}}
      validate={validator}
      render={renderForm}
      // onSubmit={onSubmit}
      onSubmit={nextHandler}
      // decorators={[calculator]}
    />
  )
}
