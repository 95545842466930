import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RootPrivateView } from './private/RootPrivateView'
import { RootPublicView } from './public/RootPublicView'
import { Login } from '../components/ui/auth/login/Login'
import { Forgotpassword } from '../components/ui/auth/forgot-password/Forgotpassword'

export const RootView = () => {
  return (
    <Switch>
      <Route path="/public" component={RootPublicView} />
      <Route path="/login" component={Login} />
      <Route path="/forgotpassword" component={Forgotpassword} />
      <Route component={RootPrivateView} />
    </Switch>
  )
}
