import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    table: {
      width: 700
    },
    overrides: {
      backgroundColor: 'white',
      color: 'black'
    },
    tabbackground: {
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    tabRoot: {
      color: '#0D1F48',
      fontWeight: 600,
      textTransform: 'capitalize',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque'
    },
    indicator: {
      backgroundColor: '#F1583A'
    },
    selected: {
      color: '#F1583A'
    },
    pt15: {
      paddingTop: '15px'
    },
    pb15: {
      paddingBottom: '15px'
    },
    center: {
      textAlign: 'center'
    },
    cursor: {
      cursor: 'pointer'
    },
    errordark: {
      color: theme.palette.error.dark
    },
    justifyCenter: {
      justifyContent: 'center'
    },
    plr5: {
      paddingLeft: '5px',
      paddingRight: '5px'
    },
    headerCls: {
      '&.e-grid .e-headercell': {
        color: 'black'
      },
      '&.e-grid .e-headercelldiv': {
        fontWeight: '600'
      },
      '&.e-grid.e-wrap .e-gridheader .e-sortnumber': {
        display: 'none'
      }
    },
    colorCls: {
      color: '#4b99d1',
      fontWeight: 'bold',
      fontFamily: 'Brandon Grotesque'
    },
    textLinkClr: {
      color: '#4b99d1'
    },
    brandonFF: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '15px'
    }
  })
)
