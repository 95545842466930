import React, { useRef, useMemo, useState } from 'react'
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  MonthAgenda,
  Inject,
  Month,
  Agenda
} from '@syncfusion/ej2-react-schedule'
import moment from 'moment'
import useStyles from './types/Styles'
import { useAppointmentsListLazyQuery } from '../../graphql/hooks.generated'
import { useCurrentUser } from '../../graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../../components/ui/panel/progress-indicator/ProgressIndicator'

export const DashboardScheduer = () => {
  const scheduleObj = useRef<ScheduleComponent>(null)
  const scheduleObjAgenda = useRef<ScheduleComponent>(null)
  const classes = useStyles()
  const { data: currentUserData } = useCurrentUser()
  const [appointmentlistQuery, { data, loading, refetch }] = useAppointmentsListLazyQuery({
    fetchPolicy: 'network-only'
  })
  const appointmentlistFetch = useMemo(
    () => ({ user_id, district_id, appointment_type_id }) =>
      appointmentlistQuery({ variables: { user_id, district_id, appointment_type_id } }),
    [appointmentlistQuery]
  )
  const [appointmentData, setAppointmentData] = useState<any>()
  const appointmentApiFetch = () => {
    if (currentUserData) {
      const distId: string = currentUserData?.currentUser?.district?.id || ''
      const input = {
        user_id: currentUserData.currentUser.id,
        district_id: parseInt(distId),
        appointment_type_id: '3'
      }
      appointmentlistFetch(input)
    }
  }
  React.useEffect(() => {
    if (currentUserData) {
      const distId: string = currentUserData?.currentUser?.district?.id || ''
      const input = {
        user_id: currentUserData.currentUser.id,
        district_id: parseInt(distId),
        appointment_type_id: '3'
      }
      appointmentlistFetch(input)
    }
  }, [appointmentlistFetch, currentUserData])
  React.useEffect(() => {
    setAppointmentData(data?.appointmentList)
  }, [data])
  const onCellClick = (args) => {
    if (scheduleObjAgenda) {
      const scheObj: any = scheduleObjAgenda
      scheObj.current.selectedDate = args.startTime
    }
  }
  const onAgendaCellClick = (args) => {
    args.cancel = true
    // args.cancel = true
  }
  const eventTemplateAgenda = (props) => {
    const data = props

    return (
      <div style={{ display: 'flex', fontSize: '14px', alignItems: 'center' }}>
        <div> {moment(data?.StartTime).format('hh:mm A')} </div>
        <div className="time" style={{ padding: '0 20px' }}>
          <p style={{ padding: '5px', margin: 0, color: '#000000' }}>{data?.Subject}</p>
          <p style={{ padding: '5px', margin: 0, color: '#787878' }}>
            {data?.signal_service_id?.name}
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={`${classes.dashboarSchedule} schedule-control-section`}>
        <div className="col-lg-12 control-section">
          <div className="control-wrapper schedule-wrapper">
            <div>
              <ScheduleComponent
                ref={scheduleObj}
                height="330px"
                width="350px"
                eventSettings={{ dataSource: appointmentData }}
                cellClick={(args) => onCellClick(args)}
                timezone="US/Mountain"
                cellDoubleClick={(args) => onAgendaCellClick(args)}
              >
                <ViewsDirective>
                  <ViewDirective option="MonthAgenda" />
                </ViewsDirective>
                <Inject services={[MonthAgenda]} />
              </ScheduleComponent>
            </div>
            <div>
              <h4>Schedule</h4>
              <ScheduleComponent
                ref={scheduleObjAgenda}
                width="100%"
                height="270px"
                showHeaderBar={false}
                // selectedDate={new Date()}
                agendaDaysCount={1}
                eventSettings={{ dataSource: appointmentData }}
                eventClick={(args) => onAgendaCellClick(args)}
                timezone="US/Mountain"
                // actionBegin={(args) => onAgendaCellClick(args)}
                popupOpen={(args) => onAgendaCellClick(args)}

                // eventDoubleClick={(args) => onAgendaCellClick(args)}
              >
                <ViewsDirective>
                  <ViewDirective option="Agenda" eventTemplate={eventTemplateAgenda} />
                </ViewsDirective>
                <Inject services={[Agenda]} />
              </ScheduleComponent>
            </div>
          </div>
        </div>
        <div className={classes.scheduleProgress}>
          {loading && <ProgressIndicator position="relative" top="-60px" />}
        </div>
      </div>
    </>
  )
}
