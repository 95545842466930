import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  TextField,
  Grid
} from '@material-ui/core'
import moment from 'moment'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useAppointmentsQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'
import { Section } from '../../../../components/ui/panel/section/Section'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'

interface ListAppointmentViewParams extends UseParamsType {
  id: string
}

export const AppointmentListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: studentId } = useParams<ListAppointmentViewParams>()
  const classes = useStyles()
  const { data, loading, refetch } = useAppointmentsQuery({
    variables: { student_id: Number(studentId) },
    fetchPolicy: 'network-only'
  })
  const [appointmentsData, setData] = useState<any>()
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')

  // const handleChange = (e) => {
  //   const keyWord = e.target.value

  //   if (keyWord && keyWord.trim().length > 0) {
  //     const appointmentsData = data?.appointments?.filter((obj) => {
  //       if (obj.id.toLocaleLowerCase() === keyWord.toLocaleLowerCase()) {
  //         return obj
  //       }
  //     })
  //     setData(appointmentsData)
  //   }
  // }

  return (
    <>
      <form>
        <NavBar title="Appointment Settings" goBackBtn />
        {/* <Grid style={{ marginLeft: '80px' }}>
          <Section title="Data Tools"></Section>
        </Grid> */}
        <Grid container spacing={0} style={{ marginTop: '30px' }}>
          <Grid item sm={3} style={{ marginLeft: '250px', display: 'flex' }}>
            <TextField
              id="date"
              label="From"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item sm={4} style={{ marginLeft: '93px', display: 'flex' }}>
            <div style={{ position: 'relative', top: '-30px', padding: '48px' }}> Provider: </div>
            {data?.appointments?.length ? (
              <select
                // onChange={handleChange}
                style={{
                  width: '170px',
                  height: '55px',
                  backgroundColor: '#E0E0E0',
                  border: '#eeee',
                  outlineColor: '#E0E0E0',
                  padding: '5px'
                }}
              >
                {data?.appointments?.map((appointment) => (
                  <option key={appointment.id} value={appointment.provider_name}>
                    {appointment.provider_name}
                  </option>
                ))}
              </select>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={3} style={{ marginLeft: '250px', display: 'flex' }}>
            <TextField
              id="date"
              label="To"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Box
            className="float-right"
            style={{ float: 'right' }}
            py={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <InfoButton
              style={{ top: '-25px', marginLeft: '250px' }}
              color="primary"
              variant="contained"
              size="small"
            >
              Filter
            </InfoButton>
          </Box>
        </Grid>

        <PageContainer>
          <div style={{ marginTop: '-72px' }} className="main_page">
            <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="h3" style={{ color: theme.palette.info.main }}>
                  Appointments
                </Typography>
              </Box>
            </Box>

            <TableContainer square={false} component={Paper}>
              <Table>
                <TableHead className={classes.headRoot}>
                  <TableRow>
                    <TableCell>PROVIDER NAME</TableCell>
                    <TableCell>PROVIDER CATEGORY</TableCell>
                    <TableCell>CLIENT NAME</TableCell>
                    <TableCell>CLIENT DISTRICT</TableCell>
                    <TableCell>IN TIME</TableCell>
                    <TableCell>OUT TIME</TableCell>
                    <TableCell>CALCULATED UNITS</TableCell>
                    <TableCell>DIAGNOSIS </TableCell>
                    <TableCell>SERVICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.appointments?.map((appointment) => (
                    <TableRow key={appointment.id}>
                      <TableCell>{appointment.provider_name}</TableCell>
                      <TableCell />
                      <TableCell>{appointment.student_name}</TableCell>
                      <TableCell>{appointment.student_district}</TableCell>
                      <TableCell>
                        {' '}
                        {moment(appointment.started_at).format('DD/MM/YYYY h:mm a')}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {moment(appointment.ended_at).format('DD/MM/YYYY h:mm a')}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>{appointment.icd_code}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading()}
          </div>
        </PageContainer>
      </form>
    </>
  )
}
