import React, { useEffect, useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  Filter,
  FilterSettingsModel,
  SortSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  // Grid,
  Avatar,
  DialogActions
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import moment from 'moment'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useListViewLogQuery } from 'graphql/hooks.generated'
import Button from '../../../../components/ui/panel/button/Button'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

interface ParentProps {
  open: boolean
  handleclose: () => unknown
  waitlistobj: any
}

export const WaitlistLog = (props: ParentProps) => {
  const { open, handleclose, waitlistobj } = props
  const theme = useTheme()
  const history = useHistory()
  const toolbarOptions: ToolbarItems[] = ['Search']
  const classes = useStyles()
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: 'action',
        direction: 'Ascending'
      }
    ]
  }
  const searchOptions = {
    fields: ['name'],
    key: '',
    ignoreCase: true
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: 15,
    pageCount: 5,
    currentPage: 1
  }
  const data = [
    {
      action: 'Remove',
      details: 'studying eight standard',
      date: moment().format('MM-DD-YYYY'),
      user: 'Signal Therapist'
    }
  ]
  const { data: viewlogdata, loading: viewlogloading } = useListViewLogQuery({
    fetchPolicy: 'network-only',
    variables: { waitlist_id: waitlistobj?.id }
  })
  let asigneduserObj

  if (viewlogdata) asigneduserObj = viewlogdata?.listViewLog?.find(() => true)

  const asignedusername = asigneduserObj?.user_id?.name || '---'

  return (
    <>
      {viewlogloading && <ProgressIndicator fullHeight />}
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className={classes.plr10}>
              <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.info.main, fontFamily: 'Roboto' }}
                  >
                    Waitlist Log
                  </Typography>
                </Box>
              </Box>
              <div style={{ paddingLeft: '50px', fontSize: '16px', color: '#000' }}>
                <div style={{ paddingBottom: '5px' }}>
                  <span>
                    <strong>Client Name:</strong> &nbsp;&nbsp;{waitlistobj?.name}
                  </span>
                </div>
                <div>
                  <span>
                    <strong>Assigned To:</strong> &nbsp;&nbsp;{asignedusername || ''}
                  </span>
                </div>
              </div>
              {/* <PageContainer> */}
              <div className={`${classes.digbodycont} control-pane`}>
                <div className="control-section">
                  {viewlogdata && (
                    <GridComponent
                      key={Math.floor(Math.random() * 100)}
                      allowTextWrap
                      dataSource={viewlogdata?.listViewLog || []}
                      width="auto"
                      // toolbar={toolbarOptions}
                      allowPaging
                      pageSettings={pageOptions}
                      allowSorting
                      sortSettings={sortSettings}
                      // searchSettings={searchOptions}
                      className={classes.headerCls}
                    >
                      <ColumnsDirective>
                        <ColumnDirective
                          field="action_type_id.name"
                          headerText="Actions"
                          width="100"
                        />
                        <ColumnDirective field="details" headerText="Details" width="100" />
                        <ColumnDirective
                          field="date"
                          headerText="Date"
                          width="80"
                          template={(dateobj) => (
                            <>{moment.tz(dateobj?.date, 'America/Denver').format('MM-DD-YYYY')}</>
                          )}
                        />
                        <ColumnDirective field="user_id.name" headerText="User" width="80" />
                        <Inject services={[Sort, Page]} />
                      </ColumnsDirective>
                    </GridComponent>
                  )}
                </div>
              </div>
              {/* </PageContainer> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '8px 40px' }}>
          <Button onClick={handleclose} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
