import React, { useState, useEffect } from 'react'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import useStyles from './Styles'

interface IProps {
  chatUrl: string | undefined
  token: string | false | undefined
}

export const ChatWindow = (props: IProps) => {
  const { chatUrl, token } = props
  const classes = useStyles()
  const [loggedIn, setloggedIn] = useState(false)
  const [iframeCssVars, setIframeCssVars] = useState({})
  const loginUserWithToken = (token) =>
    (document?.querySelector(
      '#chat-window-iframe'
    ) as HTMLIFrameElement)?.contentWindow?.postMessage(
      {
        externalCommand: 'login-with-token',
        token
      },
      '*'
    )
  const updateIframeStyles = () => {
    const el: HTMLIFrameElement | HTMLElement | null =
      document?.querySelector('#chat-window-iframe') ||
      document?.querySelector('#chat-window-preloader')
    setIframeCssVars({
      '--iframe-offset-top': `${el?.offsetTop}px`
    })
  }
  const handleIframeOnload = async () => {
    loginUserWithToken(token)
    setloggedIn(true)
  }
  useEffect(() => {
    updateIframeStyles()
    window.addEventListener('resize', updateIframeStyles)

    return () => window.removeEventListener('resize', updateIframeStyles)
  }, [])

  return (
    <>
      {!loggedIn && (
        <div id="chat-window-preloader" style={iframeCssVars} className={classes.Preloader}>
          <ProgressIndicator />
        </div>
      )}
      {token && (
        <iframe
          id="chat-window-iframe"
          style={iframeCssVars}
          className={`${classes.Iframe} ${!loggedIn ? classes.IframeHidden : ''}`}
          title="chat"
          onLoad={handleIframeOnload}
          width="100%"
          frameBorder="0"
          src={chatUrl}
        />
      )}
    </>
  )
}

export default ChatWindow
