import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import DeleteIcon from '@material-ui/icons/Delete'
import { TransitionProps } from '@material-ui/core/transitions'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { UseParamsType } from 'types'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useStudentDocumentationLazyQuery,
  useCurrentUserQuery,
  Role,
  useDeleteIncompleteSessionsMutation
} from 'graphql/hooks.generated'
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/84380.svg'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'

interface ListViewParams extends UseParamsType {
  stu_id: string
  session_id: string
}
interface ParentParams {
  totalsession: (count) => unknown
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CollapsibleTable(props: ParentParams) {
  const { hasAnyRole } = useCurrentUser()
  const history = useHistory()
  const classes = useStyles()
  const { stu_id } = useParams<ListViewParams>()
  let stud_id = '-1'
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const { totalsession } = props
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [executeQuery, { data, loading }] = useStudentDocumentationLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      student_id: Number(stud_id)
    }
  })
  const fetchStudentDocumentation = useMemo(() => () => executeQuery(), [executeQuery])
  // therapist level doc call
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  let documentSessArray: any

  if (data) {
    documentSessArray = data?.studentDocumentation
    totalsession(data?.studentDocumentation?.length)
  }

  const [deleteSessions, { loading: deleteLoading }] = useDeleteIncompleteSessionsMutation()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteId, setDeleteId] = useState('')
  const deleteSession = async (documentation) => {
    await deleteSessions({ variables: { sessionId: deleteId } })
    setOpenDelete(false)
    history.go(0)
  }
  const handleClose = () => {
    setOpenDelete(false)
    // console.log(records)
  }
  const handleClickOpen = (documentation) => {
    setOpenDelete(true)
    setDeleteId(documentation?.session_id?.id)
    // deleteSession(documentation)
  }
  useEffect(() => {
    if (stu_id) fetchStudentDocumentation()
  }, [fetchStudentDocumentation, currentUserdata, stu_id])
  const handleClick = (id) => {
    setSelectedIndex(id)

    if (id === selectedIndex) setOpen(!open)
    else setOpen(true)
  }
  const editStudentsSessioninfo = (id, fromDate, toDate) => {
    history.push(`/calendar/${id}/${fromDate}/${toDate}/sessions`)
  }
  const isLoading = () => (loading || deleteLoading ? <ProgressIndicator fullHeight /> : '')
  const commonTableView = () => {
    return (
      <TableContainer className={(classes.updatetable, classes.tabconAdjust)} component={Paper}>
        <Table aria-label="collapsible table" style={{ tableLayout: 'fixed' }}>
          <TableRow>
            <TableCell className={classes.dochead} width="37%">
              DATE/TIME
            </TableCell>
            <TableCell className={classes.dochead} width="28%">
              PROVIDER
            </TableCell>
            <TableCell className={classes.dochead} width="25%">
              CLIENTS
            </TableCell>
            <TableCell className={classes.dochead} width="28%">
              REQUEST CLIENT RESOURCES/ REFERRALS
            </TableCell>
            <TableCell className={classes.dochead} width="28%">
              ON-GOING CARE
            </TableCell>
            <TableCell className={classes.dochead} width="35%">
              STATUS
            </TableCell>
            <TableCell className={classes.dochead} width="28%">
              ATTENDANCE
            </TableCell>
            <TableCell className={`${classes.headers} ${classes.alignleft}`} width="25%">
              ACTIONS
            </TableCell>
          </TableRow>
          <TableBody>
            {documentSessArray && documentSessArray?.length ? (
              <>
                {documentSessArray?.map((documentation, index) => (
                  <>
                    {stu_id && (
                      <TableRow key={documentation?.session_id?.id} className={classes.root}>
                        <TableCell className={classes.forntSizes}>
                          {stu_id ? (
                            <div>
                              <div>{moment(documentation?.sdate).format('MM/DD/YYYY')}</div>
                              <div>
                                {' '}
                                {documentation?.stime}-{documentation?.etime}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>{moment(documentation?.sdate).format('MM/DD/YYYY')} </div>
                              <div>
                                {' '}
                                {documentation?.stime}-{documentation?.etime}
                              </div>
                            </div>
                          )}
                          {/* {stu_id ? (
                            <div>
                              {documentation?.sdate}
                              {documentation?.edate}
                              {documentation?.stime}
                              {documentation?.etime}
                            </div>
                          ) : (
                            <div>
                              {documentation?.sdate}
                              {documentation?.edate}
                              {documentation?.stime}
                              {documentation?.etime}
                            </div>
                          )} */}
                        </TableCell>
                        <TableCell className={classes.forntSizes}>
                          {documentation?.session_id?.user_id?.name}
                        </TableCell>
                        <TableCell className={classes.forntSizes}>
                          {documentation?.student_id?.name}
                        </TableCell>
                        <TableCell>{documentation?.high_level_care ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{documentation?.further_sessions ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          <div>
                            {stu_id && !documentation?.session_id?.sign && (
                              <div className={`${classes.alignRightt} ${classes.saveBtn}`}>
                                ! Incomplete Notes
                              </div>
                            )}
                            {stu_id && documentation?.session_id?.sign && (
                              <div className={`${classes.saveBtnn}`} style={{ color: '#4da063' }}>
                                <CheckBoxIcon className={`${classes.completedIcon}`} /> completed
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{documentation?.present ? 'Present' : 'Absent'}</TableCell>
                        <TableCell>
                          <div style={{ width: '90px' }}>
                            <IconButton
                              className={`${classes.alignRightt} ${classes.alignPadding}`}
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleClick(index)}
                            >
                              {open && index === selectedIndex ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                            {!hasAnyRole(Role.TeleteachersAdmin) && !hasAnyRole(Role.SchoolAdmin) && (
                              <>
                                {stu_id && (
                                  <Link
                                    className={`${classes.alignRightt} ${classes.alignPadding}`}
                                    onClick={() =>
                                      editStudentsSessioninfo(
                                        documentation?.session_id?.appointment_id?.id,
                                        moment(documentation?.sdate).format(),
                                        moment(documentation?.edate).format()
                                      )
                                    }
                                  >
                                    <EditIcon
                                      width="18"
                                      height="25"
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Link>
                                )}
                              </>
                            )}
                            {!documentation?.session_id?.sign && (
                              <DeleteIcon
                                style={{
                                  marginBottom: '-17px',
                                  cursor: 'pointer'
                                }}
                                color="primary"
                                onClick={() => handleClickOpen(documentation)}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className={`${classes.forntSizes} ${classes.ltblue}`} colSpan={12}>
                        <Collapse in={open && index === selectedIndex} timeout="auto" unmountOnExit>
                          <div>
                            <Grid item xs={12}>
                              <div className={classes.straightline} />
                            </Grid>
                            <Grid container spacing={3} className={classes.marbtneg}>
                              <Grid item xs={12}>
                                <h1 className={classes.dochead}>Data, Assessment & Plan</h1>
                                <Grid
                                  container
                                  className={`${classes.collapsetheme} ${classes.ptb10}`}
                                >
                                  {stu_id && (
                                    <Grid item md={12} xs={12} className={classes.mar0}>
                                      <div>
                                        <strong>Data:</strong> {documentation?.notes}
                                      </div>
                                      <div>
                                        <strong>Assessment:</strong> {documentation?.assessment}
                                      </div>
                                      <div>
                                        <strong>Plan:</strong> {documentation?.plan}
                                      </div>
                                      <div>
                                        <strong>Resources/referrals requested:</strong>{' '}
                                        {documentation?.high_level_care_text}
                                      </div>
                                      <div>
                                        <strong>On-Going Care Notes:</strong>{' '}
                                        {documentation?.further_sessions_text}
                                      </div>
                                      <div>
                                        <strong>Outcome of Session:</strong>{' '}
                                        {documentation?.session_id?.outcome_id?.name}
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <span>
                    <h3>No Documentation</h3>
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isLoading()}

        {/* {window.location.reload()} */}
      </TableContainer>
    )
  }

  return (
    <>
      <PageContainer>{commonTableView()}</PageContainer>
      {openDelete && (
        <Dialog
          open={openDelete}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to delete session
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={deleteSession} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
