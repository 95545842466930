import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
  CmsDate: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
  CmsDateTime: any;
  CmsHex: any;
  /** Raw JSON value */
  CmsJson: any;
  /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  CmsLong: any;
  CmsRGBAHue: any;
  CmsRGBATransparency: any;
  /** Slate-compatible RichText AST */
  CmsRichTextAST: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  JSON: any;
  Null: any;
  Object: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateAccountInput = {
  description?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  userAvailabilityInput?: Maybe<Array<UserAvailabilityInput>>;
};

export type Activeprovider = {
  __typename?: 'Activeprovider';
  id: Scalars['ID'];
  minutes?: Maybe<Scalars['Int']>;
  provider_first_name?: Maybe<Scalars['String']>;
  provider_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  student_last_name?: Maybe<Scalars['String']>;
  time_period?: Maybe<Scalars['String']>;
};

export type Activityfeed = {
  __typename?: 'Activityfeed';
  created_by?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['JSON']>;
  updated_by?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['JSON']>;
};

export type Agency = {
  __typename?: 'Agency';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  restriction?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_by?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AgencyInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  restriction?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AgencyMessage = {
  __typename?: 'AgencyMessage';
  info?: Maybe<Scalars['Int']>;
};

export type AgesServed = {
  __typename?: 'AgesServed';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type AgesServedInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  ended_at?: Maybe<Scalars['DateTime']>;
  icd_code: Scalars['String'];
  id: Scalars['String'];
  provider_name: Scalars['String'];
  started_at?: Maybe<Scalars['DateTime']>;
  student_district: Scalars['String'];
  student_name: Scalars['String'];
};

export type AppointmentList = {
  __typename?: 'AppointmentList';
  appointment_slot_id?: Maybe<Scalars['ID']>;
  appointment_type_id: AppointmentTypes;
  created_by?: Maybe<Scalars['String']>;
  district_id: District;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  is_allday?: Maybe<Scalars['Boolean']>;
  isreccuring?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  parent_appointment_id?: Maybe<Scalars['ID']>;
  recurrance_r_rule?: Maybe<Scalars['String']>;
  recurrence_exception?: Maybe<Scalars['String']>;
  service_id?: Maybe<Array<Service>>;
  signal_service_id?: Maybe<SignalServices>;
  slot_management?: Maybe<SlotManagement>;
  start_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  studentMail?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Student>>;
  title?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: User;
};

export type AppointmentListInput = {
  appointmentTypeId: Scalars['String'];
  appointment_slot_id?: Maybe<Scalars['String']>;
  caseloadInsert?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  districtId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  isAllday?: Maybe<Scalars['Boolean']>;
  isreccuring?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  parentAppointmentId?: Maybe<Scalars['String']>;
  recurranceRRule?: Maybe<Scalars['String']>;
  recurrenceException?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Array<Scalars['Int']>>;
  signal_service_id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['String']>;
  slot_time?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  students?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  waitlist_create?: Maybe<Scalars['Boolean']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type AppointmentServiceNoteUpdate = {
  note?: Maybe<Scalars['String']>;
  signal_service_id?: Maybe<Scalars['Int']>;
};

export type AppointmentTypes = {
  __typename?: 'AppointmentTypes';
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_by?: Maybe<Scalars['String']>;
};

export type AppointmentTypesInput = {
  createdBy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedBy?: Maybe<Scalars['String']>;
};

export type AppointmentstatusUpdate = {
  status?: Maybe<Scalars['Int']>;
};

export type ApppitmentProfile = {
  __typename?: 'ApppitmentProfile';
  attendance?: Maybe<Scalars['String']>;
  date_requested?: Maybe<Scalars['Date']>;
  provider_name?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  session_date?: Maybe<Scalars['String']>;
  session_time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ApppitmentProfileCount = {
  __typename?: 'ApppitmentProfileCount';
  cancelledcount?: Maybe<Scalars['Int']>;
  sessioncount?: Maybe<Scalars['Int']>;
};

export type AuthenticationTokens = {
  __typename?: 'AuthenticationTokens';
  access: Scalars['String'];
  id: Scalars['String'];
  refresh: Scalars['String'];
};

export type BenchmarkGoalsInput = {
  actual?: Maybe<Scalars['Int']>;
  baseline?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goalTypeId?: Maybe<Scalars['String']>;
  goal_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  iepId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentGoalId?: Maybe<Scalars['String']>;
  reached?: Maybe<Scalars['Boolean']>;
  studentId?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
  trackers?: Maybe<Array<Maybe<Tracker>>>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type BlackoutDays = {
  __typename?: 'BlackoutDays';
  appointment_type_id: AppointmentTypes;
  created_by?: Maybe<Scalars['String']>;
  day_portion?: Maybe<Scalars['Boolean']>;
  district_id: District;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  service_id: Service;
  start_date?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: User;
};

export type BlackoutDaysInput = {
  appointmentTypeId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  day_portion?: Maybe<Scalars['Boolean']>;
  districtId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type Caseload = {
  __typename?: 'Caseload';
  billing_code?: Maybe<Scalars['String']>;
  district_id: Scalars['Int'];
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iep_minutes?: Maybe<Scalars['Int']>;
  minutes_per?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  planned_sessions?: Maybe<Scalars['Int']>;
  private_notes?: Maybe<Scalars['String']>;
  session_dates?: Maybe<Sessions>;
  sessions_count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  students: Student;
  updated_by?: Maybe<Scalars['String']>;
  user_id: User;
};

export type CaseloadInput = {
  billing_code: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  iep_minutes: Scalars['Int'];
  minutes_per?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  planned_sessions?: Maybe<Scalars['Int']>;
  private_notes?: Maybe<Scalars['String']>;
  students: Scalars['Int'];
  updated_by?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CaseloadProviders = {
  __typename?: 'CaseloadProviders';
  first_session_date?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  last_session_date?: Maybe<Scalars['DateTime']>;
  minutes_per?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  therapist_discipline?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type CaseloadType = {
  __typename?: 'CaseloadType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CaseloadTypeInput = {
  name: Scalars['String'];
};

export type CaseloadUpdate = {
  frequency?: Maybe<Scalars['String']>;
  iep_minutes?: Maybe<Scalars['Int']>;
  minutes_per?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  parent1_email?: Maybe<Scalars['String']>;
  parent1_phone_number?: Maybe<Scalars['String']>;
  parent2_email?: Maybe<Scalars['String']>;
  parent2_phone_number?: Maybe<Scalars['String']>;
  planned_sessions?: Maybe<Scalars['Int']>;
  private_notes?: Maybe<Scalars['String']>;
  students: Scalars['Int'];
  updated_by?: Maybe<Scalars['String']>;
};

export type Categories = {
  __typename?: 'Categories';
  categories?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type ChangePassword = {
  Password: Scalars['String'];
  Username: Scalars['String'];
};

export type ChatCredentials = {
  __typename?: 'ChatCredentials';
  authToken: Scalars['String'];
  userId: Scalars['String'];
};

export type ChatGroup = {
  __typename?: 'ChatGroup';
  id: Scalars['String'];
  messagesCount: Scalars['Int'];
  name: Scalars['String'];
  usersCount: Scalars['Int'];
};

export type ChatInstantMessaging = {
  __typename?: 'ChatInstantMessaging';
  id: Scalars['String'];
  messagesCount: Scalars['Int'];
  user: User;
};

export type ClientAttemptsInput = {
  notes: Scalars['String'];
  subject: Scalars['String'];
  token: Scalars['String'];
};

export type CmsAggregate = {
  __typename?: 'CmsAggregate';
  count: Scalars['Int'];
};

export type CmsArticle = CmsNode & {
  __typename?: 'CmsArticle';
  body: Scalars['String'];
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsArticle>;
  /** List of Article versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsArticleCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsArticleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsArticleHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsArticlePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsArticleResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsArticleScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsArticleUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsArticleWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsArticleConnection = {
  __typename?: 'CmsArticleConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsArticleEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsArticleCreateInput = {
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  name: Scalars['String'];
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsArticleCreateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: Maybe<Array<CmsArticleWhereUniqueInput>>;
  /** Create and connect multiple existing Article documents */
  create?: Maybe<Array<CmsArticleCreateInput>>;
};

export type CmsArticleCreateOneInlineInput = {
  /** Connect one existing Article document */
  connect?: Maybe<CmsArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: Maybe<CmsArticleCreateInput>;
};

/** An edge in a connection. */
export type CmsArticleEdge = {
  __typename?: 'CmsArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsArticle;
};

/** Identifies documents */
export type CmsArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsArticleWhereStageInput>;
  documentInStages_none?: Maybe<CmsArticleWhereStageInput>;
  documentInStages_some?: Maybe<CmsArticleWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsArticleOrderByInput {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsArticleUpdateInput = {
  body?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsArticleUpdateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: Maybe<Array<CmsArticleConnectInput>>;
  /** Create and connect multiple Article documents */
  create?: Maybe<Array<CmsArticleCreateInput>>;
  /** Delete multiple Article documents */
  delete?: Maybe<Array<CmsArticleWhereUniqueInput>>;
  /** Disconnect multiple Article documents */
  disconnect?: Maybe<Array<CmsArticleWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Article documents */
  set?: Maybe<Array<CmsArticleWhereUniqueInput>>;
  /** Update multiple Article documents */
  update?: Maybe<Array<CmsArticleUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Article documents */
  upsert?: Maybe<Array<CmsArticleUpsertWithNestedWhereUniqueInput>>;
};

export type CmsArticleUpdateManyInput = {
  body?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CmsArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsArticleUpdateManyInput;
  /** Document search */
  where: CmsArticleWhereInput;
};

export type CmsArticleUpdateOneInlineInput = {
  /** Connect existing Article document */
  connect?: Maybe<CmsArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: Maybe<CmsArticleCreateInput>;
  /** Delete currently connected Article document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Article document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Article document */
  update?: Maybe<CmsArticleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Article document */
  upsert?: Maybe<CmsArticleUpsertWithNestedWhereUniqueInput>;
};

export type CmsArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsArticleUpdateInput;
  /** Unique document search */
  where: CmsArticleWhereUniqueInput;
};

export type CmsArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsArticleCreateInput;
  /** Update document if it exists */
  update: CmsArticleUpdateInput;
};

export type CmsArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsArticleUpsertInput;
  /** Unique document search */
  where: CmsArticleWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsArticleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsArticleWhereStageInput>;
  documentInStages_none?: Maybe<CmsArticleWhereStageInput>;
  documentInStages_some?: Maybe<CmsArticleWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsArticleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsArticleWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsArticleWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsArticleWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsArticleWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Article record uniquely */
export type CmsArticleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Asset system model */
export type CmsAsset = CmsNode & {
  __typename?: 'CmsAsset';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsAsset>;
  dragBoardImages: Array<CmsDragBoard>;
  /** The file name */
  fileName: Scalars['String'];
  /** The file handle */
  handle: Scalars['String'];
  /** The height of the file */
  height?: Maybe<Scalars['Float']>;
  /** List of Asset versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: CmsLocale;
  /** Get the other localizations for this document */
  localizations: Array<CmsAsset>;
  mcAnswerImage: Array<CmsMcAnswer>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']>;
  orderingAnswerImage: Array<CmsOrderingAnswer>;
  pdfFile: Array<CmsPdf>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resourceThumbnail: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** The file size */
  size?: Maybe<Scalars['Float']>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'];
  videoFile: Array<CmsVideo>;
  /** The file width */
  width?: Maybe<Scalars['Float']>;
};


/** Asset system model */
export type CmsAssetCreatedAtArgs = {
  variation?: CmsSystemDateTimeFieldVariation;
};


/** Asset system model */
export type CmsAssetCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Asset system model */
export type CmsAssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


/** Asset system model */
export type CmsAssetDragBoardImagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsDragBoardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsDragBoardWhereInput>;
};


/** Asset system model */
export type CmsAssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


/** Asset system model */
export type CmsAssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<CmsLocale>;
};


/** Asset system model */
export type CmsAssetMcAnswerImageArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsMcAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsMcAnswerWhereInput>;
};


/** Asset system model */
export type CmsAssetOrderingAnswerImageArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsOrderingAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsOrderingAnswerWhereInput>;
};


/** Asset system model */
export type CmsAssetPdfFileArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsPdfOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsPdfWhereInput>;
};


/** Asset system model */
export type CmsAssetPublishedAtArgs = {
  variation?: CmsSystemDateTimeFieldVariation;
};


/** Asset system model */
export type CmsAssetPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Asset system model */
export type CmsAssetResourceThumbnailArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsResourceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


/** Asset system model */
export type CmsAssetScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


/** Asset system model */
export type CmsAssetUpdatedAtArgs = {
  variation?: CmsSystemDateTimeFieldVariation;
};


/** Asset system model */
export type CmsAssetUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Asset system model */
export type CmsAssetUrlArgs = {
  transformation?: Maybe<CmsAssetTransformationInput>;
};


/** Asset system model */
export type CmsAssetVideoFileArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsVideoWhereInput>;
};

export type CmsAssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsAssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsAssetConnection = {
  __typename?: 'CmsAssetConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsAssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsAssetCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  dragBoardImages?: Maybe<CmsDragBoardCreateManyInlineInput>;
  fileName: Scalars['String'];
  handle: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<CmsAssetCreateLocalizationsInput>;
  mcAnswerImage?: Maybe<CmsMcAnswerCreateManyInlineInput>;
  mimeType?: Maybe<Scalars['String']>;
  orderingAnswerImage?: Maybe<CmsOrderingAnswerCreateManyInlineInput>;
  pdfFile?: Maybe<CmsPdfCreateManyInlineInput>;
  resourceThumbnail?: Maybe<CmsResourceCreateManyInlineInput>;
  size?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  videoFile?: Maybe<CmsVideoCreateManyInlineInput>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  fileName: Scalars['String'];
  handle: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetCreateLocalizationInput = {
  /** Localization input */
  data: CmsAssetCreateLocalizationDataInput;
  locale: CmsLocale;
};

export type CmsAssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<CmsAssetCreateLocalizationInput>>;
};

export type CmsAssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<CmsAssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: Maybe<Array<CmsAssetCreateInput>>;
};

export type CmsAssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: Maybe<CmsAssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: Maybe<CmsAssetCreateInput>;
};

/** An edge in a connection. */
export type CmsAssetEdge = {
  __typename?: 'CmsAssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsAsset;
};

/** Identifies documents */
export type CmsAssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsAssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsAssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsAssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsAssetWhereStageInput>;
  documentInStages_none?: Maybe<CmsAssetWhereStageInput>;
  documentInStages_some?: Maybe<CmsAssetWhereStageInput>;
  dragBoardImages_every?: Maybe<CmsDragBoardWhereInput>;
  dragBoardImages_none?: Maybe<CmsDragBoardWhereInput>;
  dragBoardImages_some?: Maybe<CmsDragBoardWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  mcAnswerImage_every?: Maybe<CmsMcAnswerWhereInput>;
  mcAnswerImage_none?: Maybe<CmsMcAnswerWhereInput>;
  mcAnswerImage_some?: Maybe<CmsMcAnswerWhereInput>;
  orderingAnswerImage_every?: Maybe<CmsOrderingAnswerWhereInput>;
  orderingAnswerImage_none?: Maybe<CmsOrderingAnswerWhereInput>;
  orderingAnswerImage_some?: Maybe<CmsOrderingAnswerWhereInput>;
  pdfFile_every?: Maybe<CmsPdfWhereInput>;
  pdfFile_none?: Maybe<CmsPdfWhereInput>;
  pdfFile_some?: Maybe<CmsPdfWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resourceThumbnail_every?: Maybe<CmsResourceWhereInput>;
  resourceThumbnail_none?: Maybe<CmsResourceWhereInput>;
  resourceThumbnail_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
  videoFile_every?: Maybe<CmsVideoWhereInput>;
  videoFile_none?: Maybe<CmsVideoWhereInput>;
  videoFile_some?: Maybe<CmsVideoWhereInput>;
};

export enum CmsAssetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Transformations for Assets */
export type CmsAssetTransformationInput = {
  document?: Maybe<CmsDocumentTransformationInput>;
  image?: Maybe<CmsImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: Maybe<Scalars['Boolean']>;
};

export type CmsAssetUpdateInput = {
  dragBoardImages?: Maybe<CmsDragBoardUpdateManyInlineInput>;
  fileName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  /** Manage document localizations */
  localizations?: Maybe<CmsAssetUpdateLocalizationsInput>;
  mcAnswerImage?: Maybe<CmsMcAnswerUpdateManyInlineInput>;
  mimeType?: Maybe<Scalars['String']>;
  orderingAnswerImage?: Maybe<CmsOrderingAnswerUpdateManyInlineInput>;
  pdfFile?: Maybe<CmsPdfUpdateManyInlineInput>;
  resourceThumbnail?: Maybe<CmsResourceUpdateManyInlineInput>;
  size?: Maybe<Scalars['Float']>;
  videoFile?: Maybe<CmsVideoUpdateManyInlineInput>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetUpdateLocalizationDataInput = {
  fileName?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetUpdateLocalizationInput = {
  data: CmsAssetUpdateLocalizationDataInput;
  locale: CmsLocale;
};

export type CmsAssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<CmsAssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: Maybe<Array<CmsLocale>>;
  /** Localizations to update */
  update?: Maybe<Array<CmsAssetUpdateLocalizationInput>>;
  upsert?: Maybe<Array<CmsAssetUpsertLocalizationInput>>;
};

export type CmsAssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<CmsAssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: Maybe<Array<CmsAssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: Maybe<Array<CmsAssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: Maybe<Array<CmsAssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: Maybe<Array<CmsAssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: Maybe<Array<CmsAssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: Maybe<Array<CmsAssetUpsertWithNestedWhereUniqueInput>>;
};

export type CmsAssetUpdateManyInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  /** Optional updates to localizations */
  localizations?: Maybe<CmsAssetUpdateManyLocalizationsInput>;
  mimeType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetUpdateManyLocalizationDataInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type CmsAssetUpdateManyLocalizationInput = {
  data: CmsAssetUpdateManyLocalizationDataInput;
  locale: CmsLocale;
};

export type CmsAssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<CmsAssetUpdateManyLocalizationInput>>;
};

export type CmsAssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsAssetUpdateManyInput;
  /** Document search */
  where: CmsAssetWhereInput;
};

export type CmsAssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: Maybe<CmsAssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: Maybe<CmsAssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Asset document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Asset document */
  update?: Maybe<CmsAssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: Maybe<CmsAssetUpsertWithNestedWhereUniqueInput>;
};

export type CmsAssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsAssetUpdateInput;
  /** Unique document search */
  where: CmsAssetWhereUniqueInput;
};

export type CmsAssetUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsAssetCreateInput;
  /** Update document if it exists */
  update: CmsAssetUpdateInput;
};

export type CmsAssetUpsertLocalizationInput = {
  create: CmsAssetCreateLocalizationDataInput;
  locale: CmsLocale;
  update: CmsAssetUpdateLocalizationDataInput;
};

export type CmsAssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsAssetUpsertInput;
  /** Unique document search */
  where: CmsAssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsAssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsAssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsAssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsAssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsAssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsAssetWhereStageInput>;
  documentInStages_none?: Maybe<CmsAssetWhereStageInput>;
  documentInStages_some?: Maybe<CmsAssetWhereStageInput>;
  dragBoardImages_every?: Maybe<CmsDragBoardWhereInput>;
  dragBoardImages_none?: Maybe<CmsDragBoardWhereInput>;
  dragBoardImages_some?: Maybe<CmsDragBoardWhereInput>;
  fileName?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  fileName_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  handle_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  handle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  height_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: Maybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: Maybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  height_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  mcAnswerImage_every?: Maybe<CmsMcAnswerWhereInput>;
  mcAnswerImage_none?: Maybe<CmsMcAnswerWhereInput>;
  mcAnswerImage_some?: Maybe<CmsMcAnswerWhereInput>;
  mimeType?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  mimeType_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: Maybe<Scalars['String']>;
  orderingAnswerImage_every?: Maybe<CmsOrderingAnswerWhereInput>;
  orderingAnswerImage_none?: Maybe<CmsOrderingAnswerWhereInput>;
  orderingAnswerImage_some?: Maybe<CmsOrderingAnswerWhereInput>;
  pdfFile_every?: Maybe<CmsPdfWhereInput>;
  pdfFile_none?: Maybe<CmsPdfWhereInput>;
  pdfFile_some?: Maybe<CmsPdfWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resourceThumbnail_every?: Maybe<CmsResourceWhereInput>;
  resourceThumbnail_none?: Maybe<CmsResourceWhereInput>;
  resourceThumbnail_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  size?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  size_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: Maybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: Maybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  size_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
  videoFile_every?: Maybe<CmsVideoWhereInput>;
  videoFile_none?: Maybe<CmsVideoWhereInput>;
  videoFile_some?: Maybe<CmsVideoWhereInput>;
  width?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  width_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: Maybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: Maybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  width_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsAssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsAssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsAssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsAssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsAssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Asset record uniquely */
export type CmsAssetWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsBatchPayload = {
  __typename?: 'CmsBatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['CmsLong'];
};

export type CmsCoGame = CmsNode & {
  __typename?: 'CmsCOGame';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsCoGame>;
  /** List of COGame versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  questions: Array<CmsCoQuestion>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsCoGameCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsCoGameDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsCoGameHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsCoGamePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsCoGameQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsCoQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsCoQuestionWhereInput>;
};


export type CmsCoGameResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsCoGameScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsCoGameUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsCoGameConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsCoGameWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsCoGameConnection = {
  __typename?: 'CmsCOGameConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsCoGameEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsCoGameCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  questions?: Maybe<CmsCoQuestionCreateManyInlineInput>;
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsCoGameCreateManyInlineInput = {
  /** Connect multiple existing COGame documents */
  connect?: Maybe<Array<CmsCoGameWhereUniqueInput>>;
  /** Create and connect multiple existing COGame documents */
  create?: Maybe<Array<CmsCoGameCreateInput>>;
};

export type CmsCoGameCreateOneInlineInput = {
  /** Connect one existing COGame document */
  connect?: Maybe<CmsCoGameWhereUniqueInput>;
  /** Create and connect one COGame document */
  create?: Maybe<CmsCoGameCreateInput>;
};

/** An edge in a connection. */
export type CmsCoGameEdge = {
  __typename?: 'CmsCOGameEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsCoGame;
};

/** Identifies documents */
export type CmsCoGameManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsCoGameWhereStageInput>;
  documentInStages_none?: Maybe<CmsCoGameWhereStageInput>;
  documentInStages_some?: Maybe<CmsCoGameWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  questions_every?: Maybe<CmsCoQuestionWhereInput>;
  questions_none?: Maybe<CmsCoQuestionWhereInput>;
  questions_some?: Maybe<CmsCoQuestionWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsCoGameOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsCoGameUpdateInput = {
  questions?: Maybe<CmsCoQuestionUpdateManyInlineInput>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsCoGameUpdateManyInlineInput = {
  /** Connect multiple existing COGame documents */
  connect?: Maybe<Array<CmsCoGameConnectInput>>;
  /** Create and connect multiple COGame documents */
  create?: Maybe<Array<CmsCoGameCreateInput>>;
  /** Delete multiple COGame documents */
  delete?: Maybe<Array<CmsCoGameWhereUniqueInput>>;
  /** Disconnect multiple COGame documents */
  disconnect?: Maybe<Array<CmsCoGameWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing COGame documents */
  set?: Maybe<Array<CmsCoGameWhereUniqueInput>>;
  /** Update multiple COGame documents */
  update?: Maybe<Array<CmsCoGameUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple COGame documents */
  upsert?: Maybe<Array<CmsCoGameUpsertWithNestedWhereUniqueInput>>;
};

export type CmsCoGameUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: Maybe<Scalars['String']>;
};

export type CmsCoGameUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsCoGameUpdateManyInput;
  /** Document search */
  where: CmsCoGameWhereInput;
};

export type CmsCoGameUpdateOneInlineInput = {
  /** Connect existing COGame document */
  connect?: Maybe<CmsCoGameWhereUniqueInput>;
  /** Create and connect one COGame document */
  create?: Maybe<CmsCoGameCreateInput>;
  /** Delete currently connected COGame document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected COGame document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single COGame document */
  update?: Maybe<CmsCoGameUpdateWithNestedWhereUniqueInput>;
  /** Upsert single COGame document */
  upsert?: Maybe<CmsCoGameUpsertWithNestedWhereUniqueInput>;
};

export type CmsCoGameUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsCoGameUpdateInput;
  /** Unique document search */
  where: CmsCoGameWhereUniqueInput;
};

export type CmsCoGameUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsCoGameCreateInput;
  /** Update document if it exists */
  update: CmsCoGameUpdateInput;
};

export type CmsCoGameUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsCoGameUpsertInput;
  /** Unique document search */
  where: CmsCoGameWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsCoGameWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsCoGameWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoGameWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsCoGameWhereStageInput>;
  documentInStages_none?: Maybe<CmsCoGameWhereStageInput>;
  documentInStages_some?: Maybe<CmsCoGameWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  questions_every?: Maybe<CmsCoQuestionWhereInput>;
  questions_none?: Maybe<CmsCoQuestionWhereInput>;
  questions_some?: Maybe<CmsCoQuestionWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsCoGameWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoGameWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoGameWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoGameWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsCoGameWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References COGame record uniquely */
export type CmsCoGameWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsCoQuestion = CmsNode & {
  __typename?: 'CmsCOQuestion';
  answers: Array<CmsMcAnswer>;
  cOGame: Array<CmsCoGame>;
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsCoQuestion>;
  /** List of COQuestion versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  question?: Maybe<Scalars['String']>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsCoQuestionAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsMcAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsMcAnswerWhereInput>;
};


export type CmsCoQuestionCoGameArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsCoGameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsCoGameWhereInput>;
};


export type CmsCoQuestionCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsCoQuestionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsCoQuestionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsCoQuestionPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsCoQuestionScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsCoQuestionUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsCoQuestionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsCoQuestionWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsCoQuestionConnection = {
  __typename?: 'CmsCOQuestionConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsCoQuestionEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsCoQuestionCreateInput = {
  answers?: Maybe<CmsMcAnswerCreateManyInlineInput>;
  cOGame?: Maybe<CmsCoGameCreateManyInlineInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  question?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsCoQuestionCreateManyInlineInput = {
  /** Connect multiple existing COQuestion documents */
  connect?: Maybe<Array<CmsCoQuestionWhereUniqueInput>>;
  /** Create and connect multiple existing COQuestion documents */
  create?: Maybe<Array<CmsCoQuestionCreateInput>>;
};

export type CmsCoQuestionCreateOneInlineInput = {
  /** Connect one existing COQuestion document */
  connect?: Maybe<CmsCoQuestionWhereUniqueInput>;
  /** Create and connect one COQuestion document */
  create?: Maybe<CmsCoQuestionCreateInput>;
};

/** An edge in a connection. */
export type CmsCoQuestionEdge = {
  __typename?: 'CmsCOQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsCoQuestion;
};

/** Identifies documents */
export type CmsCoQuestionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  answers_every?: Maybe<CmsMcAnswerWhereInput>;
  answers_none?: Maybe<CmsMcAnswerWhereInput>;
  answers_some?: Maybe<CmsMcAnswerWhereInput>;
  cOGame_every?: Maybe<CmsCoGameWhereInput>;
  cOGame_none?: Maybe<CmsCoGameWhereInput>;
  cOGame_some?: Maybe<CmsCoGameWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsCoQuestionWhereStageInput>;
  documentInStages_none?: Maybe<CmsCoQuestionWhereStageInput>;
  documentInStages_some?: Maybe<CmsCoQuestionWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  question?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  question_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  question_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  question_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  question_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  question_starts_with?: Maybe<Scalars['String']>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsCoQuestionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsCoQuestionUpdateInput = {
  answers?: Maybe<CmsMcAnswerUpdateManyInlineInput>;
  cOGame?: Maybe<CmsCoGameUpdateManyInlineInput>;
  question?: Maybe<Scalars['String']>;
};

export type CmsCoQuestionUpdateManyInlineInput = {
  /** Connect multiple existing COQuestion documents */
  connect?: Maybe<Array<CmsCoQuestionConnectInput>>;
  /** Create and connect multiple COQuestion documents */
  create?: Maybe<Array<CmsCoQuestionCreateInput>>;
  /** Delete multiple COQuestion documents */
  delete?: Maybe<Array<CmsCoQuestionWhereUniqueInput>>;
  /** Disconnect multiple COQuestion documents */
  disconnect?: Maybe<Array<CmsCoQuestionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing COQuestion documents */
  set?: Maybe<Array<CmsCoQuestionWhereUniqueInput>>;
  /** Update multiple COQuestion documents */
  update?: Maybe<Array<CmsCoQuestionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple COQuestion documents */
  upsert?: Maybe<Array<CmsCoQuestionUpsertWithNestedWhereUniqueInput>>;
};

export type CmsCoQuestionUpdateManyInput = {
  question?: Maybe<Scalars['String']>;
};

export type CmsCoQuestionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsCoQuestionUpdateManyInput;
  /** Document search */
  where: CmsCoQuestionWhereInput;
};

export type CmsCoQuestionUpdateOneInlineInput = {
  /** Connect existing COQuestion document */
  connect?: Maybe<CmsCoQuestionWhereUniqueInput>;
  /** Create and connect one COQuestion document */
  create?: Maybe<CmsCoQuestionCreateInput>;
  /** Delete currently connected COQuestion document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected COQuestion document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single COQuestion document */
  update?: Maybe<CmsCoQuestionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single COQuestion document */
  upsert?: Maybe<CmsCoQuestionUpsertWithNestedWhereUniqueInput>;
};

export type CmsCoQuestionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsCoQuestionUpdateInput;
  /** Unique document search */
  where: CmsCoQuestionWhereUniqueInput;
};

export type CmsCoQuestionUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsCoQuestionCreateInput;
  /** Update document if it exists */
  update: CmsCoQuestionUpdateInput;
};

export type CmsCoQuestionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsCoQuestionUpsertInput;
  /** Unique document search */
  where: CmsCoQuestionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsCoQuestionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsCoQuestionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoQuestionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  answers_every?: Maybe<CmsMcAnswerWhereInput>;
  answers_none?: Maybe<CmsMcAnswerWhereInput>;
  answers_some?: Maybe<CmsMcAnswerWhereInput>;
  cOGame_every?: Maybe<CmsCoGameWhereInput>;
  cOGame_none?: Maybe<CmsCoGameWhereInput>;
  cOGame_some?: Maybe<CmsCoGameWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsCoQuestionWhereStageInput>;
  documentInStages_none?: Maybe<CmsCoQuestionWhereStageInput>;
  documentInStages_some?: Maybe<CmsCoQuestionWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  question?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  question_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  question_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  question_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  question_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  question_starts_with?: Maybe<Scalars['String']>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsCoQuestionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsCoQuestionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsCoQuestionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsCoQuestionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsCoQuestionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References COQuestion record uniquely */
export type CmsCoQuestionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type CmsColor = {
  __typename?: 'CmsColor';
  css: Scalars['String'];
  hex: Scalars['CmsHex'];
  rgba: CmsRgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type CmsColorInput = {
  hex?: Maybe<Scalars['CmsHex']>;
  rgba?: Maybe<CmsRgbaInput>;
};

export type CmsConnectPositionInput = {
  /** Connect document after specified document */
  after?: Maybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: Maybe<Scalars['ID']>;
  /** Connect document at last position */
  end?: Maybe<Scalars['Boolean']>;
  /** Connect document at first position */
  start?: Maybe<Scalars['Boolean']>;
};



export type CmsDiscipline = CmsNode & {
  __typename?: 'CmsDiscipline';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsDiscipline>;
  /** List of Discipline versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsDisciplineCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsDisciplineDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsDisciplineHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsDisciplinePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsDisciplineResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsResourceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsDisciplineScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsDisciplineUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsDisciplineConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsDisciplineWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsDisciplineConnection = {
  __typename?: 'CmsDisciplineConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsDisciplineEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsDisciplineCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  name: Scalars['String'];
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsDisciplineCreateManyInlineInput = {
  /** Connect multiple existing Discipline documents */
  connect?: Maybe<Array<CmsDisciplineWhereUniqueInput>>;
  /** Create and connect multiple existing Discipline documents */
  create?: Maybe<Array<CmsDisciplineCreateInput>>;
};

export type CmsDisciplineCreateOneInlineInput = {
  /** Connect one existing Discipline document */
  connect?: Maybe<CmsDisciplineWhereUniqueInput>;
  /** Create and connect one Discipline document */
  create?: Maybe<CmsDisciplineCreateInput>;
};

/** An edge in a connection. */
export type CmsDisciplineEdge = {
  __typename?: 'CmsDisciplineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsDiscipline;
};

/** Identifies documents */
export type CmsDisciplineManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsDisciplineWhereStageInput>;
  documentInStages_none?: Maybe<CmsDisciplineWhereStageInput>;
  documentInStages_some?: Maybe<CmsDisciplineWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsDisciplineOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsDisciplineUpdateInput = {
  name?: Maybe<Scalars['String']>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsDisciplineUpdateManyInlineInput = {
  /** Connect multiple existing Discipline documents */
  connect?: Maybe<Array<CmsDisciplineConnectInput>>;
  /** Create and connect multiple Discipline documents */
  create?: Maybe<Array<CmsDisciplineCreateInput>>;
  /** Delete multiple Discipline documents */
  delete?: Maybe<Array<CmsDisciplineWhereUniqueInput>>;
  /** Disconnect multiple Discipline documents */
  disconnect?: Maybe<Array<CmsDisciplineWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Discipline documents */
  set?: Maybe<Array<CmsDisciplineWhereUniqueInput>>;
  /** Update multiple Discipline documents */
  update?: Maybe<Array<CmsDisciplineUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Discipline documents */
  upsert?: Maybe<Array<CmsDisciplineUpsertWithNestedWhereUniqueInput>>;
};

export type CmsDisciplineUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
};

export type CmsDisciplineUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsDisciplineUpdateManyInput;
  /** Document search */
  where: CmsDisciplineWhereInput;
};

export type CmsDisciplineUpdateOneInlineInput = {
  /** Connect existing Discipline document */
  connect?: Maybe<CmsDisciplineWhereUniqueInput>;
  /** Create and connect one Discipline document */
  create?: Maybe<CmsDisciplineCreateInput>;
  /** Delete currently connected Discipline document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Discipline document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Discipline document */
  update?: Maybe<CmsDisciplineUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Discipline document */
  upsert?: Maybe<CmsDisciplineUpsertWithNestedWhereUniqueInput>;
};

export type CmsDisciplineUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsDisciplineUpdateInput;
  /** Unique document search */
  where: CmsDisciplineWhereUniqueInput;
};

export type CmsDisciplineUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsDisciplineCreateInput;
  /** Update document if it exists */
  update: CmsDisciplineUpdateInput;
};

export type CmsDisciplineUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsDisciplineUpsertInput;
  /** Unique document search */
  where: CmsDisciplineWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsDisciplineWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsDisciplineWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDisciplineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsDisciplineWhereStageInput>;
  documentInStages_none?: Maybe<CmsDisciplineWhereStageInput>;
  documentInStages_some?: Maybe<CmsDisciplineWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsDisciplineWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDisciplineWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDisciplineWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDisciplineWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsDisciplineWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Discipline record uniquely */
export type CmsDisciplineWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum CmsDocumentFileTypes {
  Doc = 'doc',
  Docx = 'docx',
  Html = 'html',
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Xls = 'xls',
  Xlsx = 'xlsx'
}

export type CmsDocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   * 
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: Maybe<CmsDocumentFileTypes>;
};

/** Transformations for Documents */
export type CmsDocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: Maybe<CmsDocumentOutputInput>;
};

export type CmsDocumentVersion = {
  __typename?: 'CmsDocumentVersion';
  createdAt: Scalars['CmsDateTime'];
  data?: Maybe<Scalars['CmsJson']>;
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: CmsStage;
};

export type CmsDragBoard = CmsNode & {
  __typename?: 'CmsDragBoard';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsDragBoard>;
  /** List of DragBoard versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  images: Array<CmsAsset>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsDragBoardCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsDragBoardDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsDragBoardHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsDragBoardImagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsAssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsAssetWhereInput>;
};


export type CmsDragBoardPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsDragBoardResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsDragBoardScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsDragBoardUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsDragBoardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsDragBoardWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsDragBoardConnection = {
  __typename?: 'CmsDragBoardConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsDragBoardEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsDragBoardCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  images?: Maybe<CmsAssetCreateManyInlineInput>;
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsDragBoardCreateManyInlineInput = {
  /** Connect multiple existing DragBoard documents */
  connect?: Maybe<Array<CmsDragBoardWhereUniqueInput>>;
  /** Create and connect multiple existing DragBoard documents */
  create?: Maybe<Array<CmsDragBoardCreateInput>>;
};

export type CmsDragBoardCreateOneInlineInput = {
  /** Connect one existing DragBoard document */
  connect?: Maybe<CmsDragBoardWhereUniqueInput>;
  /** Create and connect one DragBoard document */
  create?: Maybe<CmsDragBoardCreateInput>;
};

/** An edge in a connection. */
export type CmsDragBoardEdge = {
  __typename?: 'CmsDragBoardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsDragBoard;
};

/** Identifies documents */
export type CmsDragBoardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsDragBoardWhereStageInput>;
  documentInStages_none?: Maybe<CmsDragBoardWhereStageInput>;
  documentInStages_some?: Maybe<CmsDragBoardWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  images_every?: Maybe<CmsAssetWhereInput>;
  images_none?: Maybe<CmsAssetWhereInput>;
  images_some?: Maybe<CmsAssetWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsDragBoardOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsDragBoardUpdateInput = {
  images?: Maybe<CmsAssetUpdateManyInlineInput>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsDragBoardUpdateManyInlineInput = {
  /** Connect multiple existing DragBoard documents */
  connect?: Maybe<Array<CmsDragBoardConnectInput>>;
  /** Create and connect multiple DragBoard documents */
  create?: Maybe<Array<CmsDragBoardCreateInput>>;
  /** Delete multiple DragBoard documents */
  delete?: Maybe<Array<CmsDragBoardWhereUniqueInput>>;
  /** Disconnect multiple DragBoard documents */
  disconnect?: Maybe<Array<CmsDragBoardWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DragBoard documents */
  set?: Maybe<Array<CmsDragBoardWhereUniqueInput>>;
  /** Update multiple DragBoard documents */
  update?: Maybe<Array<CmsDragBoardUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DragBoard documents */
  upsert?: Maybe<Array<CmsDragBoardUpsertWithNestedWhereUniqueInput>>;
};

export type CmsDragBoardUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: Maybe<Scalars['String']>;
};

export type CmsDragBoardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsDragBoardUpdateManyInput;
  /** Document search */
  where: CmsDragBoardWhereInput;
};

export type CmsDragBoardUpdateOneInlineInput = {
  /** Connect existing DragBoard document */
  connect?: Maybe<CmsDragBoardWhereUniqueInput>;
  /** Create and connect one DragBoard document */
  create?: Maybe<CmsDragBoardCreateInput>;
  /** Delete currently connected DragBoard document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected DragBoard document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single DragBoard document */
  update?: Maybe<CmsDragBoardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DragBoard document */
  upsert?: Maybe<CmsDragBoardUpsertWithNestedWhereUniqueInput>;
};

export type CmsDragBoardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsDragBoardUpdateInput;
  /** Unique document search */
  where: CmsDragBoardWhereUniqueInput;
};

export type CmsDragBoardUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsDragBoardCreateInput;
  /** Update document if it exists */
  update: CmsDragBoardUpdateInput;
};

export type CmsDragBoardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsDragBoardUpsertInput;
  /** Unique document search */
  where: CmsDragBoardWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsDragBoardWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsDragBoardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDragBoardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsDragBoardWhereStageInput>;
  documentInStages_none?: Maybe<CmsDragBoardWhereStageInput>;
  documentInStages_some?: Maybe<CmsDragBoardWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  images_every?: Maybe<CmsAssetWhereInput>;
  images_none?: Maybe<CmsAssetWhereInput>;
  images_some?: Maybe<CmsAssetWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsDragBoardWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsDragBoardWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsDragBoardWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsDragBoardWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsDragBoardWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References DragBoard record uniquely */
export type CmsDragBoardWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};


export enum CmsImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /**
   * Resizes the image to fit the specified parameters exactly by removing any
   * parts of the image that don't fit within the boundaries.
   */
  Crop = 'crop',
  /**
   * Resizes the image to fit within the parameters, but as opposed to 'fit:clip'
   * will not scale the image if the image is smaller than the output size.
   */
  Max = 'max',
  /**
   * Resizes the image to fit the specified parameters exactly by scaling the image
   * to the desired size. The aspect ratio of the image is not respected and the
   * image can be distorted using this method.
   */
  Scale = 'scale'
}

export type CmsImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: Maybe<CmsImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: Maybe<Scalars['Int']>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: Maybe<Scalars['Int']>;
};

/** Transformations for Images */
export type CmsImageTransformationInput = {
  /** Resizes the image */
  resize?: Maybe<CmsImageResizeInput>;
};


/** Locale system enumeration */
export enum CmsLocale {
  /** System locale */
  En = 'en'
}

/** Representing a geolocation point with latitude and longitude */
export type CmsLocation = {
  __typename?: 'CmsLocation';
  distance: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


/** Representing a geolocation point with latitude and longitude */
export type CmsLocationDistanceArgs = {
  from: CmsLocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type CmsLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type CmsMcAnswer = CmsNode & {
  __typename?: 'CmsMcAnswer';
  cOQuestion?: Maybe<CmsCoQuestion>;
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsMcAnswer>;
  /** List of McAnswer versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<CmsAsset>;
  isCorrect: Scalars['Boolean'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsMcAnswerCoQuestionArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsMcAnswerCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsMcAnswerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsMcAnswerHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsMcAnswerImageArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsMcAnswerPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsMcAnswerScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsMcAnswerUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsMcAnswerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsMcAnswerWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsMcAnswerConnection = {
  __typename?: 'CmsMcAnswerConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsMcAnswerEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsMcAnswerCreateInput = {
  cOQuestion?: Maybe<CmsCoQuestionCreateOneInlineInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  image?: Maybe<CmsAssetCreateOneInlineInput>;
  isCorrect: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsMcAnswerCreateManyInlineInput = {
  /** Connect multiple existing McAnswer documents */
  connect?: Maybe<Array<CmsMcAnswerWhereUniqueInput>>;
  /** Create and connect multiple existing McAnswer documents */
  create?: Maybe<Array<CmsMcAnswerCreateInput>>;
};

export type CmsMcAnswerCreateOneInlineInput = {
  /** Connect one existing McAnswer document */
  connect?: Maybe<CmsMcAnswerWhereUniqueInput>;
  /** Create and connect one McAnswer document */
  create?: Maybe<CmsMcAnswerCreateInput>;
};

/** An edge in a connection. */
export type CmsMcAnswerEdge = {
  __typename?: 'CmsMcAnswerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsMcAnswer;
};

/** Identifies documents */
export type CmsMcAnswerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  cOQuestion?: Maybe<CmsCoQuestionWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsMcAnswerWhereStageInput>;
  documentInStages_none?: Maybe<CmsMcAnswerWhereStageInput>;
  documentInStages_some?: Maybe<CmsMcAnswerWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  image?: Maybe<CmsAssetWhereInput>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isCorrect_not?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsMcAnswerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsCorrectAsc = 'isCorrect_ASC',
  IsCorrectDesc = 'isCorrect_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsMcAnswerUpdateInput = {
  cOQuestion?: Maybe<CmsCoQuestionUpdateOneInlineInput>;
  image?: Maybe<CmsAssetUpdateOneInlineInput>;
  isCorrect?: Maybe<Scalars['Boolean']>;
};

export type CmsMcAnswerUpdateManyInlineInput = {
  /** Connect multiple existing McAnswer documents */
  connect?: Maybe<Array<CmsMcAnswerConnectInput>>;
  /** Create and connect multiple McAnswer documents */
  create?: Maybe<Array<CmsMcAnswerCreateInput>>;
  /** Delete multiple McAnswer documents */
  delete?: Maybe<Array<CmsMcAnswerWhereUniqueInput>>;
  /** Disconnect multiple McAnswer documents */
  disconnect?: Maybe<Array<CmsMcAnswerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing McAnswer documents */
  set?: Maybe<Array<CmsMcAnswerWhereUniqueInput>>;
  /** Update multiple McAnswer documents */
  update?: Maybe<Array<CmsMcAnswerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple McAnswer documents */
  upsert?: Maybe<Array<CmsMcAnswerUpsertWithNestedWhereUniqueInput>>;
};

export type CmsMcAnswerUpdateManyInput = {
  isCorrect?: Maybe<Scalars['Boolean']>;
};

export type CmsMcAnswerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsMcAnswerUpdateManyInput;
  /** Document search */
  where: CmsMcAnswerWhereInput;
};

export type CmsMcAnswerUpdateOneInlineInput = {
  /** Connect existing McAnswer document */
  connect?: Maybe<CmsMcAnswerWhereUniqueInput>;
  /** Create and connect one McAnswer document */
  create?: Maybe<CmsMcAnswerCreateInput>;
  /** Delete currently connected McAnswer document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected McAnswer document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single McAnswer document */
  update?: Maybe<CmsMcAnswerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single McAnswer document */
  upsert?: Maybe<CmsMcAnswerUpsertWithNestedWhereUniqueInput>;
};

export type CmsMcAnswerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsMcAnswerUpdateInput;
  /** Unique document search */
  where: CmsMcAnswerWhereUniqueInput;
};

export type CmsMcAnswerUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsMcAnswerCreateInput;
  /** Update document if it exists */
  update: CmsMcAnswerUpdateInput;
};

export type CmsMcAnswerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsMcAnswerUpsertInput;
  /** Unique document search */
  where: CmsMcAnswerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsMcAnswerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsMcAnswerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsMcAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  cOQuestion?: Maybe<CmsCoQuestionWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsMcAnswerWhereStageInput>;
  documentInStages_none?: Maybe<CmsMcAnswerWhereStageInput>;
  documentInStages_some?: Maybe<CmsMcAnswerWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  image?: Maybe<CmsAssetWhereInput>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isCorrect_not?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsMcAnswerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsMcAnswerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsMcAnswerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsMcAnswerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsMcAnswerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References McAnswer record uniquely */
export type CmsMcAnswerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** An object with an ID */
export type CmsNode = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: CmsStage;
};

export type CmsOrderingAnswer = CmsNode & {
  __typename?: 'CmsOrderingAnswer';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsOrderingAnswer>;
  /** List of OrderingAnswer versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<CmsAsset>;
  order: Scalars['Int'];
  orderingQuestion?: Maybe<CmsOrderingQuestion>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsOrderingAnswerCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingAnswerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsOrderingAnswerHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsOrderingAnswerImageArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingAnswerOrderingQuestionArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingAnswerPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingAnswerScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsOrderingAnswerUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsOrderingAnswerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsOrderingAnswerWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsOrderingAnswerConnection = {
  __typename?: 'CmsOrderingAnswerConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsOrderingAnswerEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsOrderingAnswerCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  image?: Maybe<CmsAssetCreateOneInlineInput>;
  order: Scalars['Int'];
  orderingQuestion?: Maybe<CmsOrderingQuestionCreateOneInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsOrderingAnswerCreateManyInlineInput = {
  /** Connect multiple existing OrderingAnswer documents */
  connect?: Maybe<Array<CmsOrderingAnswerWhereUniqueInput>>;
  /** Create and connect multiple existing OrderingAnswer documents */
  create?: Maybe<Array<CmsOrderingAnswerCreateInput>>;
};

export type CmsOrderingAnswerCreateOneInlineInput = {
  /** Connect one existing OrderingAnswer document */
  connect?: Maybe<CmsOrderingAnswerWhereUniqueInput>;
  /** Create and connect one OrderingAnswer document */
  create?: Maybe<CmsOrderingAnswerCreateInput>;
};

/** An edge in a connection. */
export type CmsOrderingAnswerEdge = {
  __typename?: 'CmsOrderingAnswerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsOrderingAnswer;
};

/** Identifies documents */
export type CmsOrderingAnswerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingAnswerWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingAnswerWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingAnswerWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  image?: Maybe<CmsAssetWhereInput>;
  order?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  order_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderingQuestion?: Maybe<CmsOrderingQuestionWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsOrderingAnswerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsOrderingAnswerUpdateInput = {
  image?: Maybe<CmsAssetUpdateOneInlineInput>;
  order?: Maybe<Scalars['Int']>;
  orderingQuestion?: Maybe<CmsOrderingQuestionUpdateOneInlineInput>;
};

export type CmsOrderingAnswerUpdateManyInlineInput = {
  /** Connect multiple existing OrderingAnswer documents */
  connect?: Maybe<Array<CmsOrderingAnswerConnectInput>>;
  /** Create and connect multiple OrderingAnswer documents */
  create?: Maybe<Array<CmsOrderingAnswerCreateInput>>;
  /** Delete multiple OrderingAnswer documents */
  delete?: Maybe<Array<CmsOrderingAnswerWhereUniqueInput>>;
  /** Disconnect multiple OrderingAnswer documents */
  disconnect?: Maybe<Array<CmsOrderingAnswerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing OrderingAnswer documents */
  set?: Maybe<Array<CmsOrderingAnswerWhereUniqueInput>>;
  /** Update multiple OrderingAnswer documents */
  update?: Maybe<Array<CmsOrderingAnswerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple OrderingAnswer documents */
  upsert?: Maybe<Array<CmsOrderingAnswerUpsertWithNestedWhereUniqueInput>>;
};

export type CmsOrderingAnswerUpdateManyInput = {
  order?: Maybe<Scalars['Int']>;
};

export type CmsOrderingAnswerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsOrderingAnswerUpdateManyInput;
  /** Document search */
  where: CmsOrderingAnswerWhereInput;
};

export type CmsOrderingAnswerUpdateOneInlineInput = {
  /** Connect existing OrderingAnswer document */
  connect?: Maybe<CmsOrderingAnswerWhereUniqueInput>;
  /** Create and connect one OrderingAnswer document */
  create?: Maybe<CmsOrderingAnswerCreateInput>;
  /** Delete currently connected OrderingAnswer document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected OrderingAnswer document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single OrderingAnswer document */
  update?: Maybe<CmsOrderingAnswerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single OrderingAnswer document */
  upsert?: Maybe<CmsOrderingAnswerUpsertWithNestedWhereUniqueInput>;
};

export type CmsOrderingAnswerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsOrderingAnswerUpdateInput;
  /** Unique document search */
  where: CmsOrderingAnswerWhereUniqueInput;
};

export type CmsOrderingAnswerUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsOrderingAnswerCreateInput;
  /** Update document if it exists */
  update: CmsOrderingAnswerUpdateInput;
};

export type CmsOrderingAnswerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsOrderingAnswerUpsertInput;
  /** Unique document search */
  where: CmsOrderingAnswerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsOrderingAnswerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsOrderingAnswerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingAnswerWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingAnswerWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingAnswerWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  image?: Maybe<CmsAssetWhereInput>;
  order?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  order_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  order_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  order_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  order_lte?: Maybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  order_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderingQuestion?: Maybe<CmsOrderingQuestionWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsOrderingAnswerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingAnswerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingAnswerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingAnswerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsOrderingAnswerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References OrderingAnswer record uniquely */
export type CmsOrderingAnswerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsOrderingGame = CmsNode & {
  __typename?: 'CmsOrderingGame';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsOrderingGame>;
  /** List of OrderingGame versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  questions: Array<CmsOrderingQuestion>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsOrderingGameCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingGameDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsOrderingGameHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsOrderingGamePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingGameQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsOrderingQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsOrderingQuestionWhereInput>;
};


export type CmsOrderingGameResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsOrderingGameScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsOrderingGameUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsOrderingGameConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsOrderingGameWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsOrderingGameConnection = {
  __typename?: 'CmsOrderingGameConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsOrderingGameEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsOrderingGameCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  questions?: Maybe<CmsOrderingQuestionCreateManyInlineInput>;
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsOrderingGameCreateManyInlineInput = {
  /** Connect multiple existing OrderingGame documents */
  connect?: Maybe<Array<CmsOrderingGameWhereUniqueInput>>;
  /** Create and connect multiple existing OrderingGame documents */
  create?: Maybe<Array<CmsOrderingGameCreateInput>>;
};

export type CmsOrderingGameCreateOneInlineInput = {
  /** Connect one existing OrderingGame document */
  connect?: Maybe<CmsOrderingGameWhereUniqueInput>;
  /** Create and connect one OrderingGame document */
  create?: Maybe<CmsOrderingGameCreateInput>;
};

/** An edge in a connection. */
export type CmsOrderingGameEdge = {
  __typename?: 'CmsOrderingGameEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsOrderingGame;
};

/** Identifies documents */
export type CmsOrderingGameManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingGameWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingGameWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingGameWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  questions_every?: Maybe<CmsOrderingQuestionWhereInput>;
  questions_none?: Maybe<CmsOrderingQuestionWhereInput>;
  questions_some?: Maybe<CmsOrderingQuestionWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsOrderingGameOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsOrderingGameUpdateInput = {
  questions?: Maybe<CmsOrderingQuestionUpdateManyInlineInput>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsOrderingGameUpdateManyInlineInput = {
  /** Connect multiple existing OrderingGame documents */
  connect?: Maybe<Array<CmsOrderingGameConnectInput>>;
  /** Create and connect multiple OrderingGame documents */
  create?: Maybe<Array<CmsOrderingGameCreateInput>>;
  /** Delete multiple OrderingGame documents */
  delete?: Maybe<Array<CmsOrderingGameWhereUniqueInput>>;
  /** Disconnect multiple OrderingGame documents */
  disconnect?: Maybe<Array<CmsOrderingGameWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing OrderingGame documents */
  set?: Maybe<Array<CmsOrderingGameWhereUniqueInput>>;
  /** Update multiple OrderingGame documents */
  update?: Maybe<Array<CmsOrderingGameUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple OrderingGame documents */
  upsert?: Maybe<Array<CmsOrderingGameUpsertWithNestedWhereUniqueInput>>;
};

export type CmsOrderingGameUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: Maybe<Scalars['String']>;
};

export type CmsOrderingGameUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsOrderingGameUpdateManyInput;
  /** Document search */
  where: CmsOrderingGameWhereInput;
};

export type CmsOrderingGameUpdateOneInlineInput = {
  /** Connect existing OrderingGame document */
  connect?: Maybe<CmsOrderingGameWhereUniqueInput>;
  /** Create and connect one OrderingGame document */
  create?: Maybe<CmsOrderingGameCreateInput>;
  /** Delete currently connected OrderingGame document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected OrderingGame document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single OrderingGame document */
  update?: Maybe<CmsOrderingGameUpdateWithNestedWhereUniqueInput>;
  /** Upsert single OrderingGame document */
  upsert?: Maybe<CmsOrderingGameUpsertWithNestedWhereUniqueInput>;
};

export type CmsOrderingGameUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsOrderingGameUpdateInput;
  /** Unique document search */
  where: CmsOrderingGameWhereUniqueInput;
};

export type CmsOrderingGameUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsOrderingGameCreateInput;
  /** Update document if it exists */
  update: CmsOrderingGameUpdateInput;
};

export type CmsOrderingGameUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsOrderingGameUpsertInput;
  /** Unique document search */
  where: CmsOrderingGameWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsOrderingGameWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsOrderingGameWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingGameWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingGameWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingGameWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingGameWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  questions_every?: Maybe<CmsOrderingQuestionWhereInput>;
  questions_none?: Maybe<CmsOrderingQuestionWhereInput>;
  questions_some?: Maybe<CmsOrderingQuestionWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsOrderingGameWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingGameWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingGameWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingGameWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsOrderingGameWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References OrderingGame record uniquely */
export type CmsOrderingGameWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsOrderingQuestion = CmsNode & {
  __typename?: 'CmsOrderingQuestion';
  answers: Array<CmsOrderingAnswer>;
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsOrderingQuestion>;
  /** List of OrderingQuestion versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  orderingGame?: Maybe<CmsOrderingGame>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  question?: Maybe<Scalars['String']>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsOrderingQuestionAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsOrderingAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsOrderingAnswerWhereInput>;
};


export type CmsOrderingQuestionCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingQuestionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsOrderingQuestionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsOrderingQuestionOrderingGameArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingQuestionPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsOrderingQuestionScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsOrderingQuestionUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsOrderingQuestionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsOrderingQuestionWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsOrderingQuestionConnection = {
  __typename?: 'CmsOrderingQuestionConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsOrderingQuestionEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsOrderingQuestionCreateInput = {
  answers?: Maybe<CmsOrderingAnswerCreateManyInlineInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  orderingGame?: Maybe<CmsOrderingGameCreateOneInlineInput>;
  question?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsOrderingQuestionCreateManyInlineInput = {
  /** Connect multiple existing OrderingQuestion documents */
  connect?: Maybe<Array<CmsOrderingQuestionWhereUniqueInput>>;
  /** Create and connect multiple existing OrderingQuestion documents */
  create?: Maybe<Array<CmsOrderingQuestionCreateInput>>;
};

export type CmsOrderingQuestionCreateOneInlineInput = {
  /** Connect one existing OrderingQuestion document */
  connect?: Maybe<CmsOrderingQuestionWhereUniqueInput>;
  /** Create and connect one OrderingQuestion document */
  create?: Maybe<CmsOrderingQuestionCreateInput>;
};

/** An edge in a connection. */
export type CmsOrderingQuestionEdge = {
  __typename?: 'CmsOrderingQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsOrderingQuestion;
};

/** Identifies documents */
export type CmsOrderingQuestionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  answers_every?: Maybe<CmsOrderingAnswerWhereInput>;
  answers_none?: Maybe<CmsOrderingAnswerWhereInput>;
  answers_some?: Maybe<CmsOrderingAnswerWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingQuestionWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingQuestionWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingQuestionWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  orderingGame?: Maybe<CmsOrderingGameWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  question?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  question_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  question_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  question_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  question_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  question_starts_with?: Maybe<Scalars['String']>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsOrderingQuestionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsOrderingQuestionUpdateInput = {
  answers?: Maybe<CmsOrderingAnswerUpdateManyInlineInput>;
  orderingGame?: Maybe<CmsOrderingGameUpdateOneInlineInput>;
  question?: Maybe<Scalars['String']>;
};

export type CmsOrderingQuestionUpdateManyInlineInput = {
  /** Connect multiple existing OrderingQuestion documents */
  connect?: Maybe<Array<CmsOrderingQuestionConnectInput>>;
  /** Create and connect multiple OrderingQuestion documents */
  create?: Maybe<Array<CmsOrderingQuestionCreateInput>>;
  /** Delete multiple OrderingQuestion documents */
  delete?: Maybe<Array<CmsOrderingQuestionWhereUniqueInput>>;
  /** Disconnect multiple OrderingQuestion documents */
  disconnect?: Maybe<Array<CmsOrderingQuestionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing OrderingQuestion documents */
  set?: Maybe<Array<CmsOrderingQuestionWhereUniqueInput>>;
  /** Update multiple OrderingQuestion documents */
  update?: Maybe<Array<CmsOrderingQuestionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple OrderingQuestion documents */
  upsert?: Maybe<Array<CmsOrderingQuestionUpsertWithNestedWhereUniqueInput>>;
};

export type CmsOrderingQuestionUpdateManyInput = {
  question?: Maybe<Scalars['String']>;
};

export type CmsOrderingQuestionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsOrderingQuestionUpdateManyInput;
  /** Document search */
  where: CmsOrderingQuestionWhereInput;
};

export type CmsOrderingQuestionUpdateOneInlineInput = {
  /** Connect existing OrderingQuestion document */
  connect?: Maybe<CmsOrderingQuestionWhereUniqueInput>;
  /** Create and connect one OrderingQuestion document */
  create?: Maybe<CmsOrderingQuestionCreateInput>;
  /** Delete currently connected OrderingQuestion document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected OrderingQuestion document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single OrderingQuestion document */
  update?: Maybe<CmsOrderingQuestionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single OrderingQuestion document */
  upsert?: Maybe<CmsOrderingQuestionUpsertWithNestedWhereUniqueInput>;
};

export type CmsOrderingQuestionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsOrderingQuestionUpdateInput;
  /** Unique document search */
  where: CmsOrderingQuestionWhereUniqueInput;
};

export type CmsOrderingQuestionUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsOrderingQuestionCreateInput;
  /** Update document if it exists */
  update: CmsOrderingQuestionUpdateInput;
};

export type CmsOrderingQuestionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsOrderingQuestionUpsertInput;
  /** Unique document search */
  where: CmsOrderingQuestionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsOrderingQuestionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsOrderingQuestionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingQuestionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  answers_every?: Maybe<CmsOrderingAnswerWhereInput>;
  answers_none?: Maybe<CmsOrderingAnswerWhereInput>;
  answers_some?: Maybe<CmsOrderingAnswerWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsOrderingQuestionWhereStageInput>;
  documentInStages_none?: Maybe<CmsOrderingQuestionWhereStageInput>;
  documentInStages_some?: Maybe<CmsOrderingQuestionWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  orderingGame?: Maybe<CmsOrderingGameWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  question?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  question_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  question_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  question_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  question_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  question_starts_with?: Maybe<Scalars['String']>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsOrderingQuestionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsOrderingQuestionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsOrderingQuestionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsOrderingQuestionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsOrderingQuestionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References OrderingQuestion record uniquely */
export type CmsOrderingQuestionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Information about pagination in a connection. */
export type CmsPageInfo = {
  __typename?: 'CmsPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type CmsPdf = CmsNode & {
  __typename?: 'CmsPdf';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsPdf>;
  file: CmsAsset;
  /** List of Pdf versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsPdfCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsPdfDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsPdfFileArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsPdfHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsPdfPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsPdfResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsPdfScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsPdfUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsPdfConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsPdfWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsPdfConnection = {
  __typename?: 'CmsPdfConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsPdfEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsPdfCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  file: CmsAssetCreateOneInlineInput;
  name: Scalars['String'];
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsPdfCreateManyInlineInput = {
  /** Connect multiple existing Pdf documents */
  connect?: Maybe<Array<CmsPdfWhereUniqueInput>>;
  /** Create and connect multiple existing Pdf documents */
  create?: Maybe<Array<CmsPdfCreateInput>>;
};

export type CmsPdfCreateOneInlineInput = {
  /** Connect one existing Pdf document */
  connect?: Maybe<CmsPdfWhereUniqueInput>;
  /** Create and connect one Pdf document */
  create?: Maybe<CmsPdfCreateInput>;
};

/** An edge in a connection. */
export type CmsPdfEdge = {
  __typename?: 'CmsPdfEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsPdf;
};

/** Identifies documents */
export type CmsPdfManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsPdfWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsPdfWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsPdfWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsPdfWhereStageInput>;
  documentInStages_none?: Maybe<CmsPdfWhereStageInput>;
  documentInStages_some?: Maybe<CmsPdfWhereStageInput>;
  file?: Maybe<CmsAssetWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsPdfOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsPdfUpdateInput = {
  file?: Maybe<CmsAssetUpdateOneInlineInput>;
  name?: Maybe<Scalars['String']>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsPdfUpdateManyInlineInput = {
  /** Connect multiple existing Pdf documents */
  connect?: Maybe<Array<CmsPdfConnectInput>>;
  /** Create and connect multiple Pdf documents */
  create?: Maybe<Array<CmsPdfCreateInput>>;
  /** Delete multiple Pdf documents */
  delete?: Maybe<Array<CmsPdfWhereUniqueInput>>;
  /** Disconnect multiple Pdf documents */
  disconnect?: Maybe<Array<CmsPdfWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Pdf documents */
  set?: Maybe<Array<CmsPdfWhereUniqueInput>>;
  /** Update multiple Pdf documents */
  update?: Maybe<Array<CmsPdfUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Pdf documents */
  upsert?: Maybe<Array<CmsPdfUpsertWithNestedWhereUniqueInput>>;
};

export type CmsPdfUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
};

export type CmsPdfUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsPdfUpdateManyInput;
  /** Document search */
  where: CmsPdfWhereInput;
};

export type CmsPdfUpdateOneInlineInput = {
  /** Connect existing Pdf document */
  connect?: Maybe<CmsPdfWhereUniqueInput>;
  /** Create and connect one Pdf document */
  create?: Maybe<CmsPdfCreateInput>;
  /** Delete currently connected Pdf document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Pdf document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Pdf document */
  update?: Maybe<CmsPdfUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Pdf document */
  upsert?: Maybe<CmsPdfUpsertWithNestedWhereUniqueInput>;
};

export type CmsPdfUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsPdfUpdateInput;
  /** Unique document search */
  where: CmsPdfWhereUniqueInput;
};

export type CmsPdfUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsPdfCreateInput;
  /** Update document if it exists */
  update: CmsPdfUpdateInput;
};

export type CmsPdfUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsPdfUpsertInput;
  /** Unique document search */
  where: CmsPdfWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsPdfWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsPdfWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsPdfWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsPdfWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsPdfWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsPdfWhereStageInput>;
  documentInStages_none?: Maybe<CmsPdfWhereStageInput>;
  documentInStages_some?: Maybe<CmsPdfWhereStageInput>;
  file?: Maybe<CmsAssetWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsPdfWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsPdfWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsPdfWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsPdfWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsPdfWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Pdf record uniquely */
export type CmsPdfWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsPublishLocaleInput = {
  /** Locales to publish */
  locale: CmsLocale;
  /** Stages to publish selected locales to */
  stages: Array<CmsStage>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type CmsRgba = {
  __typename?: 'CmsRGBA';
  a: Scalars['CmsRGBATransparency'];
  b: Scalars['CmsRGBAHue'];
  g: Scalars['CmsRGBAHue'];
  r: Scalars['CmsRGBAHue'];
};


/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type CmsRgbaInput = {
  a: Scalars['CmsRGBATransparency'];
  b: Scalars['CmsRGBAHue'];
  g: Scalars['CmsRGBAHue'];
  r: Scalars['CmsRGBAHue'];
};


export type CmsResource = CmsNode & {
  __typename?: 'CmsResource';
  content?: Maybe<CmsResourceContent>;
  /** The cover image */
  coverImage: CmsAsset;
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  description?: Maybe<Scalars['String']>;
  disciplines: Array<CmsDiscipline>;
  /** Get the document in other stages */
  documentInStages: Array<CmsResource>;
  /** List of Resource versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  related: Array<CmsResource>;
  resource?: Maybe<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsResourceContentArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsResourceCoverImageArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsResourceCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsResourceDisciplinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsDisciplineWhereInput>;
};


export type CmsResourceDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsResourceHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsResourcePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsResourceRelatedArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsResourceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsResourceResourceArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsResourceScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsResourceUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsResourceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsResourceWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsResourceConnection = {
  __typename?: 'CmsResourceConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsResourceEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsResourceContent = CmsArticle | CmsCoGame | CmsDragBoard | CmsOrderingGame | CmsPdf | CmsVideo;

export type CmsResourceContentConnectInput = {
  Article?: Maybe<CmsArticleConnectInput>;
  COGame?: Maybe<CmsCoGameConnectInput>;
  DragBoard?: Maybe<CmsDragBoardConnectInput>;
  OrderingGame?: Maybe<CmsOrderingGameConnectInput>;
  Pdf?: Maybe<CmsPdfConnectInput>;
  Video?: Maybe<CmsVideoConnectInput>;
};

export type CmsResourceContentCreateInput = {
  Article?: Maybe<CmsArticleCreateInput>;
  COGame?: Maybe<CmsCoGameCreateInput>;
  DragBoard?: Maybe<CmsDragBoardCreateInput>;
  OrderingGame?: Maybe<CmsOrderingGameCreateInput>;
  Pdf?: Maybe<CmsPdfCreateInput>;
  Video?: Maybe<CmsVideoCreateInput>;
};

export type CmsResourceContentCreateManyInlineInput = {
  /** Connect multiple existing ResourceContent documents */
  connect?: Maybe<Array<CmsResourceContentWhereUniqueInput>>;
  /** Create and connect multiple existing ResourceContent documents */
  create?: Maybe<Array<CmsResourceContentCreateInput>>;
};

export type CmsResourceContentCreateOneInlineInput = {
  /** Connect one existing ResourceContent document */
  connect?: Maybe<CmsResourceContentWhereUniqueInput>;
  /** Create and connect one ResourceContent document */
  create?: Maybe<CmsResourceContentCreateInput>;
};

export type CmsResourceContentUpdateInput = {
  Article?: Maybe<CmsArticleUpdateInput>;
  COGame?: Maybe<CmsCoGameUpdateInput>;
  DragBoard?: Maybe<CmsDragBoardUpdateInput>;
  OrderingGame?: Maybe<CmsOrderingGameUpdateInput>;
  Pdf?: Maybe<CmsPdfUpdateInput>;
  Video?: Maybe<CmsVideoUpdateInput>;
};

export type CmsResourceContentUpdateManyInlineInput = {
  /** Connect multiple existing ResourceContent documents */
  connect?: Maybe<Array<CmsResourceContentConnectInput>>;
  /** Create and connect multiple ResourceContent documents */
  create?: Maybe<Array<CmsResourceContentCreateInput>>;
  /** Delete multiple ResourceContent documents */
  delete?: Maybe<Array<CmsResourceContentWhereUniqueInput>>;
  /** Disconnect multiple ResourceContent documents */
  disconnect?: Maybe<Array<CmsResourceContentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ResourceContent documents */
  set?: Maybe<Array<CmsResourceContentWhereUniqueInput>>;
  /** Update multiple ResourceContent documents */
  update?: Maybe<Array<CmsResourceContentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ResourceContent documents */
  upsert?: Maybe<Array<CmsResourceContentUpsertWithNestedWhereUniqueInput>>;
};

export type CmsResourceContentUpdateManyWithNestedWhereInput = {
  Article?: Maybe<CmsArticleUpdateManyWithNestedWhereInput>;
  COGame?: Maybe<CmsCoGameUpdateManyWithNestedWhereInput>;
  DragBoard?: Maybe<CmsDragBoardUpdateManyWithNestedWhereInput>;
  OrderingGame?: Maybe<CmsOrderingGameUpdateManyWithNestedWhereInput>;
  Pdf?: Maybe<CmsPdfUpdateManyWithNestedWhereInput>;
  Video?: Maybe<CmsVideoUpdateManyWithNestedWhereInput>;
};

export type CmsResourceContentUpdateOneInlineInput = {
  /** Connect existing ResourceContent document */
  connect?: Maybe<CmsResourceContentWhereUniqueInput>;
  /** Create and connect one ResourceContent document */
  create?: Maybe<CmsResourceContentCreateInput>;
  /** Delete currently connected ResourceContent document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected ResourceContent document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single ResourceContent document */
  update?: Maybe<CmsResourceContentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ResourceContent document */
  upsert?: Maybe<CmsResourceContentUpsertWithNestedWhereUniqueInput>;
};

export type CmsResourceContentUpdateWithNestedWhereUniqueInput = {
  Article?: Maybe<CmsArticleUpdateWithNestedWhereUniqueInput>;
  COGame?: Maybe<CmsCoGameUpdateWithNestedWhereUniqueInput>;
  DragBoard?: Maybe<CmsDragBoardUpdateWithNestedWhereUniqueInput>;
  OrderingGame?: Maybe<CmsOrderingGameUpdateWithNestedWhereUniqueInput>;
  Pdf?: Maybe<CmsPdfUpdateWithNestedWhereUniqueInput>;
  Video?: Maybe<CmsVideoUpdateWithNestedWhereUniqueInput>;
};

export type CmsResourceContentUpsertWithNestedWhereUniqueInput = {
  Article?: Maybe<CmsArticleUpsertWithNestedWhereUniqueInput>;
  COGame?: Maybe<CmsCoGameUpsertWithNestedWhereUniqueInput>;
  DragBoard?: Maybe<CmsDragBoardUpsertWithNestedWhereUniqueInput>;
  OrderingGame?: Maybe<CmsOrderingGameUpsertWithNestedWhereUniqueInput>;
  Pdf?: Maybe<CmsPdfUpsertWithNestedWhereUniqueInput>;
  Video?: Maybe<CmsVideoUpsertWithNestedWhereUniqueInput>;
};

export type CmsResourceContentWhereInput = {
  Article?: Maybe<CmsArticleWhereInput>;
  COGame?: Maybe<CmsCoGameWhereInput>;
  DragBoard?: Maybe<CmsDragBoardWhereInput>;
  OrderingGame?: Maybe<CmsOrderingGameWhereInput>;
  Pdf?: Maybe<CmsPdfWhereInput>;
  Video?: Maybe<CmsVideoWhereInput>;
};

export type CmsResourceContentWhereUniqueInput = {
  Article?: Maybe<CmsArticleWhereUniqueInput>;
  COGame?: Maybe<CmsCoGameWhereUniqueInput>;
  DragBoard?: Maybe<CmsDragBoardWhereUniqueInput>;
  OrderingGame?: Maybe<CmsOrderingGameWhereUniqueInput>;
  Pdf?: Maybe<CmsPdfWhereUniqueInput>;
  Video?: Maybe<CmsVideoWhereUniqueInput>;
};

export type CmsResourceCreateInput = {
  content?: Maybe<CmsResourceContentCreateOneInlineInput>;
  coverImage: CmsAssetCreateOneInlineInput;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  description?: Maybe<Scalars['String']>;
  disciplines?: Maybe<CmsDisciplineCreateManyInlineInput>;
  related?: Maybe<CmsResourceCreateManyInlineInput>;
  resource?: Maybe<CmsResourceCreateOneInlineInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsResourceCreateManyInlineInput = {
  /** Connect multiple existing Resource documents */
  connect?: Maybe<Array<CmsResourceWhereUniqueInput>>;
  /** Create and connect multiple existing Resource documents */
  create?: Maybe<Array<CmsResourceCreateInput>>;
};

export type CmsResourceCreateOneInlineInput = {
  /** Connect one existing Resource document */
  connect?: Maybe<CmsResourceWhereUniqueInput>;
  /** Create and connect one Resource document */
  create?: Maybe<CmsResourceCreateInput>;
};

/** An edge in a connection. */
export type CmsResourceEdge = {
  __typename?: 'CmsResourceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsResource;
};

/** Identifies documents */
export type CmsResourceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsResourceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsResourceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsResourceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** All values in which the union is connected to the given models */
  content?: Maybe<CmsResourceContentWhereInput>;
  /** All values in which the union is empty */
  content_empty?: Maybe<Scalars['Boolean']>;
  coverImage?: Maybe<CmsAssetWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  disciplines_every?: Maybe<CmsDisciplineWhereInput>;
  disciplines_none?: Maybe<CmsDisciplineWhereInput>;
  disciplines_some?: Maybe<CmsDisciplineWhereInput>;
  documentInStages_every?: Maybe<CmsResourceWhereStageInput>;
  documentInStages_none?: Maybe<CmsResourceWhereStageInput>;
  documentInStages_some?: Maybe<CmsResourceWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  related_every?: Maybe<CmsResourceWhereInput>;
  related_none?: Maybe<CmsResourceWhereInput>;
  related_some?: Maybe<CmsResourceWhereInput>;
  resource?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsResourceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TagsAsc = 'tags_ASC',
  TagsDesc = 'tags_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsResourceUpdateInput = {
  content?: Maybe<CmsResourceContentUpdateOneInlineInput>;
  coverImage?: Maybe<CmsAssetUpdateOneInlineInput>;
  description?: Maybe<Scalars['String']>;
  disciplines?: Maybe<CmsDisciplineUpdateManyInlineInput>;
  related?: Maybe<CmsResourceUpdateManyInlineInput>;
  resource?: Maybe<CmsResourceUpdateOneInlineInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type CmsResourceUpdateManyInlineInput = {
  /** Connect multiple existing Resource documents */
  connect?: Maybe<Array<CmsResourceConnectInput>>;
  /** Create and connect multiple Resource documents */
  create?: Maybe<Array<CmsResourceCreateInput>>;
  /** Delete multiple Resource documents */
  delete?: Maybe<Array<CmsResourceWhereUniqueInput>>;
  /** Disconnect multiple Resource documents */
  disconnect?: Maybe<Array<CmsResourceWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Resource documents */
  set?: Maybe<Array<CmsResourceWhereUniqueInput>>;
  /** Update multiple Resource documents */
  update?: Maybe<Array<CmsResourceUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Resource documents */
  upsert?: Maybe<Array<CmsResourceUpsertWithNestedWhereUniqueInput>>;
};

export type CmsResourceUpdateManyInput = {
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type CmsResourceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsResourceUpdateManyInput;
  /** Document search */
  where: CmsResourceWhereInput;
};

export type CmsResourceUpdateOneInlineInput = {
  /** Connect existing Resource document */
  connect?: Maybe<CmsResourceWhereUniqueInput>;
  /** Create and connect one Resource document */
  create?: Maybe<CmsResourceCreateInput>;
  /** Delete currently connected Resource document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Resource document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Resource document */
  update?: Maybe<CmsResourceUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Resource document */
  upsert?: Maybe<CmsResourceUpsertWithNestedWhereUniqueInput>;
};

export type CmsResourceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsResourceUpdateInput;
  /** Unique document search */
  where: CmsResourceWhereUniqueInput;
};

export type CmsResourceUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsResourceCreateInput;
  /** Update document if it exists */
  update: CmsResourceUpdateInput;
};

export type CmsResourceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsResourceUpsertInput;
  /** Unique document search */
  where: CmsResourceWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsResourceWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsResourceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsResourceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsResourceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsResourceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** All values in which the union is connected to the given models */
  content?: Maybe<CmsResourceContentWhereInput>;
  /** All values in which the union is empty */
  content_empty?: Maybe<Scalars['Boolean']>;
  coverImage?: Maybe<CmsAssetWhereInput>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  disciplines_every?: Maybe<CmsDisciplineWhereInput>;
  disciplines_none?: Maybe<CmsDisciplineWhereInput>;
  disciplines_some?: Maybe<CmsDisciplineWhereInput>;
  documentInStages_every?: Maybe<CmsResourceWhereStageInput>;
  documentInStages_none?: Maybe<CmsResourceWhereStageInput>;
  documentInStages_some?: Maybe<CmsResourceWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  related_every?: Maybe<CmsResourceWhereInput>;
  related_none?: Maybe<CmsResourceWhereInput>;
  related_some?: Maybe<CmsResourceWhereInput>;
  resource?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: Maybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsResourceWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsResourceWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsResourceWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsResourceWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsResourceWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Resource record uniquely */
export type CmsResourceWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type CmsRichText = {
  __typename?: 'CmsRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns AST representation */
  raw: Scalars['CmsRichTextAST'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};


/** Scheduled Operation system model */
export type CmsScheduledOperation = CmsNode & {
  __typename?: 'CmsScheduledOperation';
  affectedDocuments: Array<CmsScheduledOperationAffectedDocument>;
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Operation description */
  description?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<CmsScheduledOperation>;
  /** Operation error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['CmsJson'];
  /** The release this operation is scheduled for */
  release?: Maybe<CmsScheduledRelease>;
  /** System stage field */
  stage: CmsStage;
  /** operation Status */
  status: CmsScheduledOperationStatus;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationAffectedDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationReleaseArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Scheduled Operation system model */
export type CmsScheduledOperationUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsScheduledOperationAffectedDocument = CmsArticle | CmsAsset | CmsCoGame | CmsCoQuestion | CmsDiscipline | CmsDragBoard | CmsMcAnswer | CmsOrderingAnswer | CmsOrderingGame | CmsOrderingQuestion | CmsPdf | CmsResource | CmsVideo;

export type CmsScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsScheduledOperationConnection = {
  __typename?: 'CmsScheduledOperationConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<CmsScheduledOperationWhereUniqueInput>>;
};

export type CmsScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: Maybe<CmsScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type CmsScheduledOperationEdge = {
  __typename?: 'CmsScheduledOperationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsScheduledOperation;
};

/** Identifies documents */
export type CmsScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: Maybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored. 
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: Maybe<Scalars['CmsJson']>;
  release?: Maybe<CmsScheduledReleaseWhereInput>;
  status?: Maybe<CmsScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Maybe<CmsScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: Maybe<CmsScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Maybe<CmsScheduledOperationStatus>>>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Operation Status */
export enum CmsScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type CmsScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<CmsScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: Maybe<Array<CmsScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: Maybe<Array<CmsScheduledOperationWhereUniqueInput>>;
};

export type CmsScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: Maybe<CmsScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: Maybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored. 
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: Maybe<Scalars['CmsJson']>;
  release?: Maybe<CmsScheduledReleaseWhereInput>;
  status?: Maybe<CmsScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Maybe<CmsScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: Maybe<CmsScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Maybe<CmsScheduledOperationStatus>>>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type CmsScheduledOperationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Scheduled Release system model */
export type CmsScheduledRelease = CmsNode & {
  __typename?: 'CmsScheduledRelease';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Release description */
  description?: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<CmsScheduledRelease>;
  /** Release error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean'];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean'];
  /** Operations to run with this release */
  operations: Array<CmsScheduledOperation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  /** Release date and time */
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  /** System stage field */
  stage: CmsStage;
  /** Release Status */
  status: CmsScheduledReleaseStatus;
  /** Release Title */
  title?: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


/** Scheduled Release system model */
export type CmsScheduledReleaseCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Scheduled Release system model */
export type CmsScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


/** Scheduled Release system model */
export type CmsScheduledReleaseOperationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  orderBy?: Maybe<CmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


/** Scheduled Release system model */
export type CmsScheduledReleasePublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


/** Scheduled Release system model */
export type CmsScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsScheduledReleaseConnection = {
  __typename?: 'CmsScheduledReleaseConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsScheduledReleaseCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<CmsScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: Maybe<Array<CmsScheduledReleaseCreateInput>>;
};

export type CmsScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: Maybe<CmsScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<CmsScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type CmsScheduledReleaseEdge = {
  __typename?: 'CmsScheduledReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsScheduledRelease;
};

/** Identifies documents */
export type CmsScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  isImplicit?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>;
  operations_every?: Maybe<CmsScheduledOperationWhereInput>;
  operations_none?: Maybe<CmsScheduledOperationWhereInput>;
  operations_some?: Maybe<CmsScheduledOperationWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  status?: Maybe<CmsScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Maybe<CmsScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: Maybe<CmsScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Maybe<CmsScheduledReleaseStatus>>>;
  title?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Release Status */
export enum CmsScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type CmsScheduledReleaseUpdateInput = {
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<CmsScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: Maybe<Array<CmsScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: Maybe<Array<CmsScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: Maybe<Array<CmsScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: Maybe<Array<CmsScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: Maybe<Array<CmsScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: Maybe<Array<CmsScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type CmsScheduledReleaseUpdateManyInput = {
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsScheduledReleaseUpdateManyInput;
  /** Document search */
  where: CmsScheduledReleaseWhereInput;
};

export type CmsScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: Maybe<CmsScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<CmsScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single ScheduledRelease document */
  update?: Maybe<CmsScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: Maybe<CmsScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type CmsScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsScheduledReleaseUpdateInput;
  /** Unique document search */
  where: CmsScheduledReleaseWhereUniqueInput;
};

export type CmsScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: CmsScheduledReleaseUpdateInput;
};

export type CmsScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsScheduledReleaseUpsertInput;
  /** Unique document search */
  where: CmsScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type CmsScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  isImplicit?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>;
  operations_every?: Maybe<CmsScheduledOperationWhereInput>;
  operations_none?: Maybe<CmsScheduledOperationWhereInput>;
  operations_some?: Maybe<CmsScheduledOperationWhereInput>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  releaseAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  status?: Maybe<CmsScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Maybe<CmsScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: Maybe<CmsScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Maybe<CmsScheduledReleaseStatus>>>;
  title?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type CmsScheduledReleaseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Stage system enumeration */
export enum CmsStage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED'
}

export enum CmsSystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION'
}

export type CmsUnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: CmsLocale;
  /** Stages to unpublish selected locales from */
  stages: Array<CmsStage>;
};

/** User system model */
export type CmsUser = CmsNode & {
  __typename?: 'CmsUser';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** Get the document in other stages */
  documentInStages: Array<CmsUser>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: CmsUserKind;
  /** The username */
  name: Scalars['String'];
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
};


/** User system model */
export type CmsUserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};

export type CmsUserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsUserWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsUserConnection = {
  __typename?: 'CmsUserConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsUserEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsUserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<CmsUserWhereUniqueInput>>;
};

export type CmsUserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: Maybe<CmsUserWhereUniqueInput>;
};

/** An edge in a connection. */
export type CmsUserEdge = {
  __typename?: 'CmsUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsUser;
};

/** System User Kind */
export enum CmsUserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK'
}

/** Identifies documents */
export type CmsUserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsUserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsUserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsUserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  documentInStages_every?: Maybe<CmsUserWhereStageInput>;
  documentInStages_none?: Maybe<CmsUserWhereStageInput>;
  documentInStages_some?: Maybe<CmsUserWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<CmsUserKind>;
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<Maybe<CmsUserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: Maybe<CmsUserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<Maybe<CmsUserKind>>>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
};

export enum CmsUserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsUserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<CmsUserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: Maybe<Array<CmsUserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: Maybe<Array<CmsUserWhereUniqueInput>>;
};

export type CmsUserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: Maybe<CmsUserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: Maybe<Scalars['Boolean']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsUserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsUserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsUserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsUserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsUserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  documentInStages_every?: Maybe<CmsUserWhereStageInput>;
  documentInStages_none?: Maybe<CmsUserWhereStageInput>;
  documentInStages_some?: Maybe<CmsUserWhereStageInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<CmsUserKind>;
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<Maybe<CmsUserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: Maybe<CmsUserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<Maybe<CmsUserKind>>>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsUserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsUserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsUserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsUserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsUserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References User record uniquely */
export type CmsUserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CmsVersion = {
  __typename?: 'CmsVersion';
  createdAt: Scalars['CmsDateTime'];
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: CmsStage;
};

export type CmsVersionWhereInput = {
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: CmsStage;
};

export type CmsVideo = CmsNode & {
  __typename?: 'CmsVideo';
  /** The time the document was created */
  createdAt: Scalars['CmsDateTime'];
  /** User that created this document */
  createdBy?: Maybe<CmsUser>;
  /** Get the document in other stages */
  documentInStages: Array<CmsVideo>;
  /** The video asset uploaded to GraphCMS */
  file: CmsAsset;
  /** List of Video versions */
  history: Array<CmsVersion>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<CmsUser>;
  resource: Array<CmsResource>;
  scheduledIn: Array<CmsScheduledOperation>;
  /** System stage field */
  stage: CmsStage;
  /** The time the document was updated */
  updatedAt: Scalars['CmsDateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<CmsUser>;
};


export type CmsVideoCreatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsVideoDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<CmsStage>;
};


export type CmsVideoFileArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsVideoHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<CmsStage>;
};


export type CmsVideoPublishedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};


export type CmsVideoResourceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsResourceWhereInput>;
};


export type CmsVideoScheduledInArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<CmsLocale>>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type CmsVideoUpdatedByArgs = {
  forceParentLocale?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<CmsLocale>>;
};

export type CmsVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<CmsConnectPositionInput>;
  /** Document to connect */
  where: CmsVideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type CmsVideoConnection = {
  __typename?: 'CmsVideoConnection';
  aggregate: CmsAggregate;
  /** A list of edges. */
  edges: Array<CmsVideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: CmsPageInfo;
};

export type CmsVideoCreateInput = {
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  file: CmsAssetCreateOneInlineInput;
  name: Scalars['String'];
  resource?: Maybe<CmsResourceCreateManyInlineInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
};

export type CmsVideoCreateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: Maybe<Array<CmsVideoWhereUniqueInput>>;
  /** Create and connect multiple existing Video documents */
  create?: Maybe<Array<CmsVideoCreateInput>>;
};

export type CmsVideoCreateOneInlineInput = {
  /** Connect one existing Video document */
  connect?: Maybe<CmsVideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: Maybe<CmsVideoCreateInput>;
};

/** An edge in a connection. */
export type CmsVideoEdge = {
  __typename?: 'CmsVideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CmsVideo;
};

/** Identifies documents */
export type CmsVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsVideoWhereStageInput>;
  documentInStages_none?: Maybe<CmsVideoWhereStageInput>;
  documentInStages_some?: Maybe<CmsVideoWhereStageInput>;
  file?: Maybe<CmsAssetWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

export enum CmsVideoOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CmsVideoUpdateInput = {
  file?: Maybe<CmsAssetUpdateOneInlineInput>;
  name?: Maybe<Scalars['String']>;
  resource?: Maybe<CmsResourceUpdateManyInlineInput>;
};

export type CmsVideoUpdateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: Maybe<Array<CmsVideoConnectInput>>;
  /** Create and connect multiple Video documents */
  create?: Maybe<Array<CmsVideoCreateInput>>;
  /** Delete multiple Video documents */
  delete?: Maybe<Array<CmsVideoWhereUniqueInput>>;
  /** Disconnect multiple Video documents */
  disconnect?: Maybe<Array<CmsVideoWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Video documents */
  set?: Maybe<Array<CmsVideoWhereUniqueInput>>;
  /** Update multiple Video documents */
  update?: Maybe<Array<CmsVideoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Video documents */
  upsert?: Maybe<Array<CmsVideoUpsertWithNestedWhereUniqueInput>>;
};

export type CmsVideoUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
};

export type CmsVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CmsVideoUpdateManyInput;
  /** Document search */
  where: CmsVideoWhereInput;
};

export type CmsVideoUpdateOneInlineInput = {
  /** Connect existing Video document */
  connect?: Maybe<CmsVideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: Maybe<CmsVideoCreateInput>;
  /** Delete currently connected Video document */
  delete?: Maybe<Scalars['Boolean']>;
  /** Disconnect currently connected Video document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Update single Video document */
  update?: Maybe<CmsVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Video document */
  upsert?: Maybe<CmsVideoUpsertWithNestedWhereUniqueInput>;
};

export type CmsVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CmsVideoUpdateInput;
  /** Unique document search */
  where: CmsVideoWhereUniqueInput;
};

export type CmsVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: CmsVideoCreateInput;
  /** Update document if it exists */
  update: CmsVideoUpdateInput;
};

export type CmsVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CmsVideoUpsertInput;
  /** Unique document search */
  where: CmsVideoWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CmsVideoWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CmsVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  createdBy?: Maybe<CmsUserWhereInput>;
  documentInStages_every?: Maybe<CmsVideoWhereStageInput>;
  documentInStages_none?: Maybe<CmsVideoWhereStageInput>;
  documentInStages_some?: Maybe<CmsVideoWhereStageInput>;
  file?: Maybe<CmsAssetWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  publishedBy?: Maybe<CmsUserWhereInput>;
  resource_every?: Maybe<CmsResourceWhereInput>;
  resource_none?: Maybe<CmsResourceWhereInput>;
  resource_some?: Maybe<CmsResourceWhereInput>;
  scheduledIn_every?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<CmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<CmsScheduledOperationWhereInput>;
  updatedAt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['CmsDateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['CmsDateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['CmsDateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: Maybe<Scalars['CmsDateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['CmsDateTime']>>>;
  updatedBy?: Maybe<CmsUserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CmsVideoWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CmsVideoWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CmsVideoWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CmsVideoWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: Maybe<CmsVideoWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: Maybe<CmsStage>;
};

/** References Video record uniquely */
export type CmsVideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum Cms_FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  JsonPathExists = 'json_path_exists',
  JsonValueRecursive = 'json_value_recursive',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with',
  UnionEmpty = 'union_empty',
  UnionEvery = 'union_every',
  UnionNone = 'union_none',
  UnionSingle = 'union_single',
  UnionSome = 'union_some'
}

export enum Cms_MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual'
}

export enum Cms_MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert'
}

export enum Cms_OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Cms_RelationInputCardinality {
  Many = 'many',
  One = 'one'
}

export enum Cms_RelationInputKind {
  Create = 'create',
  Update = 'update'
}

export enum Cms_RelationKind {
  Regular = 'regular',
  Union = 'union'
}

export enum Cms_SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization'
}

export type ConfirmForgetPasswordInput = {
  ConfirmationCode: Scalars['String'];
  Password: Scalars['String'];
  Username: Scalars['String'];
};

export type ContactAttempts = {
  __typename?: 'ContactAttempts';
  bcc?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  client_fill?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  sent_date?: Maybe<Scalars['DateTime']>;
  students?: Maybe<Student>;
  subject?: Maybe<Scalars['String']>;
  therapistId?: Maybe<User>;
  to?: Maybe<Scalars['String']>;
};

export type ContactAttemptsInput = {
  bcc?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  sent_date?: Maybe<Scalars['DateTime']>;
  students?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  therapistId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type Counties = {
  __typename?: 'Counties';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type CountiesInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type CreateServiceRequestInput = {
  discipline?: Maybe<ServiceRequestDiscipline>;
  grade?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['Boolean']>;
  minutes?: Maybe<Scalars['String']>;
  student: Scalars['String'];
  type: ServiceRequestType;
  upload: Scalars['Upload'];
};



export type District = {
  __typename?: 'District';
  allow_conferencing?: Maybe<Scalars['Boolean']>;
  bill_tracking_records_enabled?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  id: Scalars['ID'];
  level_of_service: DistrictLevelOfService;
  name: Scalars['String'];
  offer_billing_question?: Maybe<Scalars['Boolean']>;
  planTypes: Array<PlanType>;
  provider_pins_enabled?: Maybe<Scalars['Boolean']>;
  signature_required: DistrictSignatureRequired;
  state: State;
  status: DistrictStatus;
  street_address: Scalars['String'];
  therapy_component_enabled?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type DistrictInput = {
  allowConferencing?: Maybe<Scalars['Boolean']>;
  billTrackingIdEnabled?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  enableProviderPins?: Maybe<Scalars['Boolean']>;
  levelOfService: DistrictLevelOfService;
  name: Scalars['String'];
  offerBillingQuestion?: Maybe<Scalars['Boolean']>;
  planTypes: Array<PlanType>;
  signatureRequired: DistrictSignatureRequired;
  stateId: Scalars['Int'];
  streetAddress: Scalars['String'];
  therapyComponentEnabled?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export enum DistrictLevelOfService {
  Platform = 'PLATFORM',
  PlatformProviders = 'PLATFORM_PROVIDERS',
  Providers = 'PROVIDERS'
}

export enum DistrictPlanType {
  Member = 'MEMBER',
  NonMember = 'NON_MEMBER'
}

export enum DistrictSignatureRequired {
  Always = 'ALWAYS',
  Billable = 'BILLABLE',
  NotRequired = 'NOT_REQUIRED'
}

export enum DistrictStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum DistrictType {
  Coop = 'COOP',
  CoopMember = 'COOP_MEMBER',
  FigureheadCoop = 'FIGUREHEAD_COOP',
  FigureheadMember = 'FIGUREHEAD_MEMBER',
  Standard = 'STANDARD'
}

export type DistrictsQueryInput = {
  query?: Maybe<Scalars['String']>;
};

export type EthnicGroup = {
  __typename?: 'EthnicGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type EthnicGroupInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type ForgetPasswordInput = {
  Username: Scalars['String'];
};

export type FurtherInput = {
  created_by?: Maybe<Scalars['String']>;
  further_sessions_approval?: Maybe<Scalars['Boolean']>;
  session_approval?: Maybe<Scalars['Int']>;
  student_id: Scalars['Int'];
};

export type GetAdminUser = {
  __typename?: 'GetAdminUser';
  IsPasswordExpaired?: Maybe<Scalars['Boolean']>;
  NumberOfDays?: Maybe<Scalars['String']>;
  UserAttributes?: Maybe<Array<UserAttributes>>;
  UserCreateDate?: Maybe<Scalars['DateTime']>;
  UserLastModifiedDate?: Maybe<Scalars['DateTime']>;
  UserStatus?: Maybe<Scalars['String']>;
  Username: Scalars['String'];
};

export type GoalProgress = {
  __typename?: 'GoalProgress';
  goal_id?: Maybe<Scalars['String']>;
  last_1_progress?: Maybe<Scalars['Float']>;
  last_3_progress?: Maybe<Scalars['Float']>;
  overall?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Int']>;
};

export type GoalTypes = {
  __typename?: 'GoalTypes';
  goal_type?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type GoalTypesInput = {
  goal_type?: Maybe<Scalars['String']>;
};

export type GoalsTracker = {
  __typename?: 'GoalsTracker';
  color?: Maybe<Scalars['String']>;
  goal_id: StudentGoals;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tracker_icon_id?: Maybe<Scalars['Int']>;
  tracker_type_id?: Maybe<TrackerTypes>;
  tracker_type_result?: Maybe<Scalars['Int']>;
};

export type GoalsTrackerInput = {
  color?: Maybe<Scalars['String']>;
  goal_id: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tracker_icon_id?: Maybe<Scalars['Int']>;
  tracker_type_id?: Maybe<Scalars['String']>;
  tracker_type_result?: Maybe<Scalars['Int']>;
};

export type Grade = {
  __typename?: 'Grade';
  grade: Scalars['String'];
  id: Scalars['Int'];
};

export type GradeInput = {
  grade: Scalars['String'];
};

export type IcdCode = {
  __typename?: 'IcdCode';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: IcdCodeStatus;
  therapist_discipline?: Maybe<TherapistDiscipline>;
  version: Scalars['String'];
};

export type IcdCodeInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  therapistDiscipline?: Maybe<TherapistDiscipline>;
  version: Scalars['String'];
};

export enum IcdCodeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type IcdInputQuery = {
  discipline?: Maybe<Array<TherapistDiscipline>>;
};

export type InprogressInput = {
  created_by?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  student_id: Scalars['Int'];
};

export type Insurance = {
  __typename?: 'Insurance';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type InsuranceInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};


export type LogActionType = {
  __typename?: 'LogActionType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type MandatoryFormDetails = {
  __typename?: 'MandatoryFormDetails';
  clinician_phone?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  degreesheld?: Maybe<Scalars['String']>;
  degreesheld_and_schoolattended?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  licenses_and_certifications?: Maybe<Scalars['String']>;
  relationship_to_client_id?: Maybe<RelationshipToClient>;
  school_attended?: Maybe<Scalars['String']>;
  session_id?: Maybe<Sessions>;
  specializedtraining_and_areasofclinicalcompetence?: Maybe<Scalars['String']>;
  status_id?: Maybe<MandatoryFormStatus>;
  student_date?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Student>;
  student_sign?: Maybe<Scalars['String']>;
  supervisorname_and_credentials?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<User>;
  user_sign?: Maybe<Scalars['String']>;
};

export type MandatoryFormDetailsInput = {
  clinician_phone?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  degreesheld?: Maybe<Scalars['String']>;
  degreesheld_and_schoolattended?: Maybe<Scalars['String']>;
  licenses_and_certifications?: Maybe<Scalars['String']>;
  relationship_to_client_id?: Maybe<Scalars['Int']>;
  school_attended?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  specializedtraining_and_areasofclinicalcompetence?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  student_date?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['Int']>;
  student_sign?: Maybe<Scalars['String']>;
  supervisorname_and_credentials?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
  user_sign?: Maybe<Scalars['String']>;
};

export type MandatoryFormStatus = {
  __typename?: 'MandatoryFormStatus';
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  info?: Maybe<Scalars['Int']>;
};

export type Metric = {
  __typename?: 'Metric';
  assignedEvaluations?: Maybe<Scalars['Int']>;
  assignedScreenings?: Maybe<Scalars['Int']>;
  nextDueIep?: Maybe<Scalars['Int']>;
  numberOfCaseloads?: Maybe<Scalars['Int']>;
  timeOwed?: Maybe<TimeOwed>;
  totalDue?: Maybe<Scalars['Int']>;
  totalSession?: Maybe<Scalars['Int']>;
  totalStudentAbsenses?: Maybe<Scalars['Int']>;
  totalStudentDistrict?: Maybe<Scalars['Int']>;
  undocumentedAppointments?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateAccount: Scalars['Boolean'];
  addClient: Scalars['Boolean'];
  addClients: Scalars['Boolean'];
  addMandatoryDetails?: Maybe<Scalars['JSON']>;
  addStudentToSession: Scalars['Boolean'];
  bulkUploadCsv: Scalars['JSON'];
  cancelAppointment?: Maybe<Scalars['Boolean']>;
  changePassword: Scalars['Boolean'];
  clientFillingAttempts?: Maybe<Scalars['JSON']>;
  confirmForgetPassword: Scalars['Boolean'];
  createAgency: AgencyMessage;
  createAgesServed: AgesServed;
  createAppointmentList: Message;
  createAppointmentTypes: AppointmentTypes;
  createAssignment?: Maybe<UserAssignment>;
  createBlackoutDays: BlackoutDays;
  createCaseload: Caseload;
  createCaseloadType: CaseloadType;
  createChatGroup: ChatGroup;
  createCounties: Counties;
  createDirectIndirectReport: Scalars['JSON'];
  createDistrict?: Maybe<District>;
  createEthnicGroup: EthnicGroup;
  createFileUpload?: Maybe<SignalFileUpload>;
  createGoalTypes: GoalTypes;
  createGoals: StudentGoals;
  createGoalsTracker: GoalsTracker;
  createGrade: Grade;
  createIcdCode: IcdCode;
  createIeps: StudentIeps;
  createInsurance: Insurance;
  createMandatoryForm: MandatoryFormDetails;
  createNewProfileSlots?: Maybe<Scalars['JSON']>;
  createNoteCategory: NoteCategory;
  createNoteCategoryType: NoteCategoryType;
  createNotes: Notes;
  createPrimaryLanguage: PrimaryLanguage;
  createProgressReport: Scalars['JSON'];
  createProgressSummary: Scalars['JSON'];
  createProviderMinutesReport: Scalars['JSON'];
  createReportshistory: Scalars['JSON'];
  createReportsstatus: Reportsstatus;
  createSchool: School;
  createService: Service;
  createServiceRequest: ServiceRequest;
  createSessions: Sessions;
  createSignalServices: SignalServices;
  createSignalSlots: SignalSlots;
  createSignatureHistory: SignatureHistory;
  createSpecialities: Specialities;
  createState: State;
  createStudent: Student;
  createTherapistDescipline: TherapistDescipline;
  createTrackerTypes: TrackerTypes;
  createUser: UserInvite;
  createUserDistrict: UserDistrict;
  createUserSlots: Scalars['JSON'];
  createWaitlistAppointment: Message;
  createWaitlistAssign?: Maybe<Scalars['Boolean']>;
  createWaitlistOutreach?: Maybe<Scalars['Boolean']>;
  deleteAgency: Scalars['Boolean'];
  deleteAgesServed: Scalars['Boolean'];
  deleteAppointment: Scalars['JSON'];
  deleteAppointmentList: Scalars['Boolean'];
  deleteAppointmentLists: Scalars['Boolean'];
  deleteAppointmentTypes: Scalars['Boolean'];
  deleteBlackoutDays: Scalars['Boolean'];
  deleteCaseload: Scalars['Boolean'];
  deleteCaseloadType: Scalars['Boolean'];
  deleteCounties: Scalars['Boolean'];
  deleteDistrict: Scalars['Boolean'];
  deleteEthnicGroup: Scalars['Boolean'];
  deleteFileUpload?: Maybe<Scalars['Boolean']>;
  deleteGoalTypes: Scalars['Boolean'];
  deleteGoals: Scalars['Boolean'];
  deleteGoalsTracker: Scalars['Boolean'];
  deleteGrade: Scalars['Boolean'];
  deleteIcdCode: Scalars['Boolean'];
  deleteIeps: Scalars['Boolean'];
  deleteIncompleteSessions: Scalars['Boolean'];
  deleteInsurance: Scalars['Boolean'];
  deleteMandatoryForm: Scalars['Boolean'];
  deleteNewProfileSlots: Scalars['Boolean'];
  deleteNoteCategory: Scalars['Boolean'];
  deleteNoteCategoryType: Scalars['Boolean'];
  deleteNotes: Scalars['Boolean'];
  deletePrimaryLanguage: Scalars['Boolean'];
  deleteReport: Scalars['Boolean'];
  deleteReportsstatus: Scalars['Boolean'];
  deleteSchool: Scalars['Boolean'];
  deleteService: Scalars['Boolean'];
  deleteSessionGoals: Scalars['Boolean'];
  deleteSignalServices: Scalars['Boolean'];
  deleteSignalSlots: Scalars['Boolean'];
  deleteSpecialities: Scalars['Boolean'];
  deleteState: Scalars['Boolean'];
  deleteStudent: Scalars['Boolean'];
  deleteStudentFromSession: Scalars['Boolean'];
  deleteTherapistDescipline: Scalars['Boolean'];
  deleteTrackerTypes: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserDistrict: Scalars['Boolean'];
  deleteUserListDistricts: Scalars['Boolean'];
  deleteUserSlots: Scalars['Boolean'];
  disableUser: Scalars['Boolean'];
  enableUser: Scalars['Boolean'];
  forgetPassword: Scalars['Boolean'];
  furtherInsert: Scalars['JSON'];
  inprogressInsert: Scalars['JSON'];
  inviteToChatGroup: ChatGroup;
  reSendMandatoryEmail: Scalars['Boolean'];
  referralInsert: Scalars['JSON'];
  removeClient: Scalars['Boolean'];
  removeClients: Scalars['Boolean'];
  setMasterAccountID: Scalars['Boolean'];
  startInstantMessaging: ChatInstantMessaging;
  trackersUpdate: Scalars['Boolean'];
  updateAddStudentPermission: Scalars['Boolean'];
  updateAddStudentPermissionForUserDistricts?: Maybe<Scalars['Boolean']>;
  updateAgency: Scalars['Boolean'];
  updateAgesServed: Scalars['Boolean'];
  updateAppointmentList: Message;
  updateAppointmentServices?: Maybe<Scalars['Boolean']>;
  updateAppointmentTypes: Scalars['Boolean'];
  updateAppointmentstatus: Scalars['JSON'];
  updateBlackoutDays: Scalars['Boolean'];
  updateCaseload: Scalars['Boolean'];
  updateCaseloadType: Scalars['Boolean'];
  updateCounties: Scalars['Boolean'];
  updateDistrict?: Maybe<District>;
  updateEthnicGroup: Scalars['Boolean'];
  updateGoalTypes: Scalars['Boolean'];
  updateGoals: Scalars['Boolean'];
  updateGoalsTracker: Scalars['Boolean'];
  updateGrade: Scalars['Boolean'];
  updateIcdCode: Scalars['Boolean'];
  updateIeps: Scalars['Boolean'];
  updateInsurance: Scalars['Boolean'];
  updateMandatoryData: Scalars['Boolean'];
  updateMandatoryForm: Scalars['Boolean'];
  updateNewProfileSlots?: Maybe<Scalars['JSON']>;
  updateNoteCategory: Scalars['Boolean'];
  updateNoteCategoryType: Scalars['Boolean'];
  updateNotes: Scalars['Boolean'];
  updateOngoingCare: Scalars['JSON'];
  updatePrimaryLanguage: Scalars['Boolean'];
  updateRecurringSlots?: Maybe<Scalars['Boolean']>;
  updateReportsstatus: Scalars['Boolean'];
  updateSchool: Scalars['Boolean'];
  updateService: Scalars['Boolean'];
  updateSessions: Scalars['JSON'];
  updateSignalServices: Scalars['Boolean'];
  updateSignalSlots: Scalars['Boolean'];
  updateSignalTherapist: Scalars['JSON'];
  updateSpecialities: Scalars['Boolean'];
  updateState: Scalars['Boolean'];
  updateStudent: Scalars['Boolean'];
  updateStudentProfile: Scalars['Boolean'];
  updateTherapistDescipline: Scalars['Boolean'];
  updateTrackerTypes: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateUserDistrict: Scalars['Boolean'];
};


export type MutationActivateAccountArgs = {
  input: ActivateAccountInput;
};


export type MutationAddClientArgs = {
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type MutationAddClientsArgs = {
  student_id: Scalars['Int'];
  user_id?: Maybe<Scalars['String']>;
};


export type MutationAddMandatoryDetailsArgs = {
  input: MandatoryFormDetailsInput;
  resend?: Maybe<Scalars['Boolean']>;
};


export type MutationAddStudentToSessionArgs = {
  session_id: Scalars['String'];
  student_id: Scalars['Int'];
  therapist_id: Scalars['String'];
};


export type MutationBulkUploadCsvArgs = {
  district_id?: Maybe<Scalars['Int']>;
  fileUpload: Scalars['Upload'];
  user_id?: Maybe<Scalars['String']>;
};


export type MutationCancelAppointmentArgs = {
  id: Scalars['String'];
  input?: Maybe<AppointmentServiceNoteUpdate>;
  sendMail?: Maybe<Scalars['Boolean']>;
};


export type MutationChangePasswordArgs = {
  id: Scalars['ID'];
  input: ChangePassword;
};


export type MutationClientFillingAttemptsArgs = {
  input: ClientAttemptsInput;
};


export type MutationConfirmForgetPasswordArgs = {
  id: Scalars['ID'];
  input: ConfirmForgetPasswordInput;
};


export type MutationCreateAgencyArgs = {
  input: AgencyInput;
};


export type MutationCreateAgesServedArgs = {
  input: AgesServedInput;
};


export type MutationCreateAppointmentListArgs = {
  input: AppointmentListInput;
};


export type MutationCreateAppointmentTypesArgs = {
  input: AppointmentTypesInput;
};


export type MutationCreateAssignmentArgs = {
  input: UserAssignmentInput;
};


export type MutationCreateBlackoutDaysArgs = {
  input: BlackoutDaysInput;
};


export type MutationCreateCaseloadArgs = {
  input: CaseloadInput;
};


export type MutationCreateCaseloadTypeArgs = {
  input: CaseloadTypeInput;
};


export type MutationCreateChatGroupArgs = {
  chatAuthToken: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
};


export type MutationCreateCountiesArgs = {
  input: CountiesInput;
};


export type MutationCreateDirectIndirectReportArgs = {
  input: Scalars['JSON'];
};


export type MutationCreateDistrictArgs = {
  input: DistrictInput;
};


export type MutationCreateEthnicGroupArgs = {
  input: EthnicGroupInput;
};


export type MutationCreateFileUploadArgs = {
  input: SignalFileUploadInput;
};


export type MutationCreateGoalTypesArgs = {
  input: GoalTypesInput;
};


export type MutationCreateGoalsArgs = {
  BenchmarkGoalsInput?: Maybe<Array<BenchmarkGoalsInput>>;
  Tracker?: Maybe<Array<Tracker>>;
  input: StudentGoalsInput;
};


export type MutationCreateGoalsTrackerArgs = {
  input: GoalsTrackerInput;
};


export type MutationCreateGradeArgs = {
  input: GradeInput;
};


export type MutationCreateIcdCodeArgs = {
  input: IcdCodeInput;
};


export type MutationCreateIepsArgs = {
  input: StudentIepsInput;
};


export type MutationCreateInsuranceArgs = {
  input: InsuranceInput;
};


export type MutationCreateMandatoryFormArgs = {
  input: MandatoryFormDetailsInput;
};


export type MutationCreateNewProfileSlotsArgs = {
  input?: Maybe<Array<ProfileSlotsInput>>;
};


export type MutationCreateNoteCategoryArgs = {
  input: NoteCategoryInput;
};


export type MutationCreateNoteCategoryTypeArgs = {
  input: NoteCategoryTypeInput;
};


export type MutationCreateNotesArgs = {
  input: NotesInput;
};


export type MutationCreatePrimaryLanguageArgs = {
  input: PrimaryLanguageInput;
};


export type MutationCreateProgressReportArgs = {
  input: Scalars['JSON'];
};


export type MutationCreateProgressSummaryArgs = {
  input: Scalars['JSON'];
};


export type MutationCreateProviderMinutesReportArgs = {
  input: Scalars['JSON'];
};


export type MutationCreateReportshistoryArgs = {
  input: Scalars['JSON'];
};


export type MutationCreateReportsstatusArgs = {
  input: ReportsstatusInput;
};


export type MutationCreateSchoolArgs = {
  input: SchoolInput;
};


export type MutationCreateServiceArgs = {
  input: ServiceInput;
};


export type MutationCreateServiceRequestArgs = {
  input: CreateServiceRequestInput;
};


export type MutationCreateSessionsArgs = {
  input: SessionsInput;
};


export type MutationCreateSignalServicesArgs = {
  input: SignalServicesInput;
};


export type MutationCreateSignalSlotsArgs = {
  input: SignalSlotsInput;
};


export type MutationCreateSignatureHistoryArgs = {
  input: SignatureHistoryInput;
};


export type MutationCreateSpecialitiesArgs = {
  input: SpecialitiesInput;
};


export type MutationCreateStateArgs = {
  input: StateInput;
};


export type MutationCreateStudentArgs = {
  input: StudentInput;
};


export type MutationCreateTherapistDesciplineArgs = {
  input: TherapistDesciplineInput;
};


export type MutationCreateTrackerTypesArgs = {
  input: TrackerTypesInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserDistrictArgs = {
  input?: Maybe<Array<UserDistrictInput>>;
};


export type MutationCreateUserSlotsArgs = {
  input?: Maybe<Array<TherapistAvailabilityInput>>;
};


export type MutationCreateWaitlistAppointmentArgs = {
  input: AppointmentListInput;
};


export type MutationCreateWaitlistAssignArgs = {
  input?: Maybe<WaitlistAssignInput>;
};


export type MutationCreateWaitlistOutreachArgs = {
  input?: Maybe<WaitlistOutreach>;
};


export type MutationDeleteAgencyArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAgesServedArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAppointmentArgs = {
  appointment_slot_id?: Maybe<Scalars['String']>;
  input?: Maybe<AppointmentstatusUpdate>;
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type MutationDeleteAppointmentListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAppointmentListsArgs = {
  parent_appointment_id?: Maybe<Scalars['ID']>;
};


export type MutationDeleteAppointmentTypesArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBlackoutDaysArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCaseloadArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCaseloadTypeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCountiesArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDistrictArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEthnicGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFileUploadArgs = {
  id: Scalars['String'];
};


export type MutationDeleteGoalTypesArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGoalsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGoalsTrackerArgs = {
  id: Array<Scalars['ID']>;
};


export type MutationDeleteGradeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteIcdCodeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteIepsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncompleteSessionsArgs = {
  sessionId: Scalars['String'];
};


export type MutationDeleteInsuranceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMandatoryFormArgs = {
  id: Scalars['String'];
};


export type MutationDeleteNewProfileSlotsArgs = {
  id: Array<Scalars['Int']>;
};


export type MutationDeleteNoteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteNoteCategoryTypeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteNotesArgs = {
  id: Scalars['String'];
};


export type MutationDeletePrimaryLanguageArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportsstatusArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSchoolArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteServiceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSessionGoalsArgs = {
  id: Array<Scalars['ID']>;
};


export type MutationDeleteSignalServicesArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSignalSlotsArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSpecialitiesArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStudentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStudentFromSessionArgs = {
  session_id: Scalars['String'];
  student_id: Scalars['Int'];
};


export type MutationDeleteTherapistDesciplineArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTrackerTypesArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserDistrictArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserListDistrictsArgs = {
  input?: Maybe<Array<DeleteUserListDistrictsInput>>;
  user_id: Scalars['String'];
};


export type MutationDeleteUserSlotsArgs = {
  id: Array<Scalars['Int']>;
};


export type MutationDisableUserArgs = {
  id: Scalars['ID'];
  updated_by?: Maybe<Scalars['String']>;
};


export type MutationEnableUserArgs = {
  id: Scalars['ID'];
  updated_by?: Maybe<Scalars['String']>;
};


export type MutationForgetPasswordArgs = {
  input: ForgetPasswordInput;
};


export type MutationFurtherInsertArgs = {
  id: Scalars['String'];
  input: FurtherInput;
};


export type MutationInprogressInsertArgs = {
  id: Scalars['String'];
  input: InprogressInput;
};


export type MutationInviteToChatGroupArgs = {
  chatAuthToken: Scalars['String'];
  emails: Array<Scalars['String']>;
  groupId: Scalars['String'];
};


export type MutationReSendMandatoryEmailArgs = {
  id: Scalars['String'];
};


export type MutationReferralInsertArgs = {
  id: Scalars['String'];
  input: ReferralInput;
};


export type MutationRemoveClientArgs = {
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type MutationRemoveClientsArgs = {
  student_id: Scalars['Int'];
  user_id?: Maybe<Scalars['String']>;
};


export type MutationSetMasterAccountIdArgs = {
  code_verifier: Scalars['String'];
  sub: Scalars['String'];
};


export type MutationStartInstantMessagingArgs = {
  chatAuthToken: Scalars['String'];
  email: Scalars['String'];
};


export type MutationTrackersUpdateArgs = {
  Tracker: Array<Tracker>;
  id: Array<Scalars['ID']>;
};


export type MutationUpdateAddStudentPermissionArgs = {
  input: UpdatePrimaryUserDistrictsInput;
  user_id: Scalars['String'];
};


export type MutationUpdateAddStudentPermissionForUserDistrictsArgs = {
  input?: Maybe<Array<Maybe<UpdateAddStudentPermissionForUserDistrictsInput>>>;
  user_id: Scalars['String'];
};


export type MutationUpdateAgencyArgs = {
  id: Scalars['Int'];
  input: AgencyInput;
};


export type MutationUpdateAgesServedArgs = {
  id: Scalars['Int'];
  input: AgesServedInput;
};


export type MutationUpdateAppointmentListArgs = {
  caseloadInsert?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  input: AppointmentListInput;
};


export type MutationUpdateAppointmentServicesArgs = {
  id: Scalars['ID'];
  input?: Maybe<AppointmentServiceNoteUpdate>;
};


export type MutationUpdateAppointmentTypesArgs = {
  id: Scalars['ID'];
  input: AppointmentTypesInput;
};


export type MutationUpdateAppointmentstatusArgs = {
  appointment_slot_id?: Maybe<Scalars['String']>;
  input?: Maybe<AppointmentstatusUpdate>;
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type MutationUpdateBlackoutDaysArgs = {
  id: Scalars['ID'];
  input: BlackoutDaysInput;
};


export type MutationUpdateCaseloadArgs = {
  id: Scalars['ID'];
  input: CaseloadUpdate;
};


export type MutationUpdateCaseloadTypeArgs = {
  id: Scalars['Int'];
  input: CaseloadTypeInput;
};


export type MutationUpdateCountiesArgs = {
  id: Scalars['Int'];
  input: CountiesInput;
};


export type MutationUpdateDistrictArgs = {
  id: Scalars['Int'];
  input: DistrictInput;
};


export type MutationUpdateEthnicGroupArgs = {
  id: Scalars['Int'];
  input: EthnicGroupInput;
};


export type MutationUpdateGoalTypesArgs = {
  id: Scalars['ID'];
  input: GoalTypesInput;
};


export type MutationUpdateGoalsArgs = {
  BenchmarkGoalsInput?: Maybe<Array<BenchmarkGoalsInput>>;
  Tracker?: Maybe<Array<Tracker>>;
  id: Array<Scalars['ID']>;
  input: Array<StudentGoalsInput>;
};


export type MutationUpdateGoalsTrackerArgs = {
  id: Scalars['ID'];
  input: GoalsTrackerInput;
};


export type MutationUpdateGradeArgs = {
  id: Scalars['Int'];
  input: GradeInput;
};


export type MutationUpdateIcdCodeArgs = {
  id: Scalars['Int'];
  input: IcdCodeInput;
};


export type MutationUpdateIepsArgs = {
  id: Scalars['ID'];
  input: StudentIepsInput;
};


export type MutationUpdateInsuranceArgs = {
  id: Scalars['Int'];
  input: InsuranceInput;
};


export type MutationUpdateMandatoryDataArgs = {
  id: Scalars['String'];
  input: MandatoryFormDetailsInput;
};


export type MutationUpdateMandatoryFormArgs = {
  id: Scalars['String'];
  input: MandatoryFormDetailsInput;
};


export type MutationUpdateNewProfileSlotsArgs = {
  input?: Maybe<Array<ProfileSlotsInput>>;
};


export type MutationUpdateNoteCategoryArgs = {
  id: Scalars['Int'];
  input: NoteCategoryInput;
};


export type MutationUpdateNoteCategoryTypeArgs = {
  id: Scalars['Int'];
  input: NoteCategoryTypeInput;
};


export type MutationUpdateNotesArgs = {
  id: Scalars['String'];
  input: NotesInput;
};


export type MutationUpdateOngoingCareArgs = {
  input: Scalars['JSON'];
};


export type MutationUpdatePrimaryLanguageArgs = {
  id: Scalars['Int'];
  input: PrimaryLanguageInput;
};


export type MutationUpdateRecurringSlotsArgs = {
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['String']>;
};


export type MutationUpdateReportsstatusArgs = {
  id: Scalars['Int'];
  input: ReportsstatusInput;
};


export type MutationUpdateSchoolArgs = {
  id: Scalars['Int'];
  input: SchoolInput;
};


export type MutationUpdateServiceArgs = {
  id: Scalars['Int'];
  input: ServiceInput;
};


export type MutationUpdateSessionsArgs = {
  input: Scalars['JSON'];
};


export type MutationUpdateSignalServicesArgs = {
  id: Scalars['Int'];
  input: SignalServicesInput;
};


export type MutationUpdateSignalSlotsArgs = {
  id: Scalars['Int'];
  input: SignalSlotsInput;
};


export type MutationUpdateSignalTherapistArgs = {
  id: Scalars['ID'];
  input: SignalTherapistInput;
};


export type MutationUpdateSpecialitiesArgs = {
  id: Scalars['Int'];
  input: SpecialitiesInput;
};


export type MutationUpdateStateArgs = {
  id: Scalars['Int'];
  input: StateInput;
};


export type MutationUpdateStudentArgs = {
  id: Scalars['Int'];
  input: StudentInput;
};


export type MutationUpdateStudentProfileArgs = {
  id: Scalars['Int'];
  input: StudentProfileInput;
};


export type MutationUpdateTherapistDesciplineArgs = {
  id: Scalars['Int'];
  input: TherapistDesciplineInput;
};


export type MutationUpdateTrackerTypesArgs = {
  id: Scalars['ID'];
  input: TrackerTypesInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


export type MutationUpdateUserDistrictArgs = {
  id: Scalars['Int'];
  input: UserDistrictInput;
};

export type NewSignalAgesServed = {
  __typename?: 'NewSignalAgesServed';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type NewUserToAges = {
  __typename?: 'NewUserToAges';
  id?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['JSON']>;
  max_age_list?: Maybe<Scalars['JSON']>;
  min_age?: Maybe<Scalars['JSON']>;
  min_age_list?: Maybe<Scalars['JSON']>;
  user_id?: Maybe<Scalars['String']>;
};

export type NoteCategory = {
  __typename?: 'NoteCategory';
  category_type_id: NoteCategoryType;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  user_visible: Scalars['Boolean'];
};

export type NoteCategoryInput = {
  category_type_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  user_visible: Scalars['Boolean'];
};

export type NoteCategoryType = {
  __typename?: 'NoteCategoryType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type NoteCategoryTypeInput = {
  name: Scalars['String'];
};

export type Notes = {
  __typename?: 'Notes';
  category_id?: Maybe<NoteCategory>;
  created_at?: Maybe<Scalars['DateTime']>;
  createdby?: Maybe<User>;
  id: Scalars['String'];
  mt_date?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  reference_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Student>;
};

export type NotesInput = {
  category_id?: Maybe<Scalars['Int']>;
  createdby?: Maybe<Scalars['String']>;
  mt_date?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  reference_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};



export type OngoingCare = {
  __typename?: 'OngoingCare';
  action_id?: Maybe<OngoingCareActions>;
  assigned_date?: Maybe<Scalars['DateTime']>;
  assigned_to?: Maybe<User>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<User>;
  further_session?: Maybe<Scalars['Boolean']>;
  further_session_reason?: Maybe<Scalars['String']>;
  further_sessions_approval?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ongoing_care?: Maybe<Scalars['Boolean']>;
  ongoing_care_reason?: Maybe<Scalars['String']>;
  referral_date?: Maybe<Scalars['DateTime']>;
  service_requested?: Maybe<Scalars['DateTime']>;
  session_approval?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Student>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<User>;
  user_id?: Maybe<User>;
};

export type OngoingCareActions = {
  __typename?: 'OngoingCareActions';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type OngoingCareInput = {
  status?: Maybe<Scalars['Boolean']>;
};

export type OngoingCareInputData = {
  action_id?: Maybe<Scalars['Int']>;
  assigned_date?: Maybe<Scalars['DateTime']>;
  assigned_to?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  further_session?: Maybe<Scalars['Boolean']>;
  further_session_reason?: Maybe<Scalars['String']>;
  further_sessions_approval?: Maybe<Scalars['Boolean']>;
  ongoing_care?: Maybe<Scalars['Boolean']>;
  ongoing_care_reason?: Maybe<Scalars['String']>;
  referral_date?: Maybe<Scalars['DateTime']>;
  service_requested?: Maybe<Scalars['DateTime']>;
  session_approval?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type OutCome = {
  __typename?: 'OutCome';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type OutreachType = {
  __typename?: 'OutreachType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ParametersInput = {
  outputNotebookPath?: Maybe<Scalars['String']>;
  parameters?: Maybe<ReportshistoryInput>;
};

export enum PlanType {
  Behavior = 'Behavior',
  Iep = 'Iep',
  Individual = 'Individual',
  NonIdea = 'NonIdea',
  NonResident = 'NonResident',
  Section504 = 'Section504',
  SupportSchoolPersonnel = 'SupportSchoolPersonnel'
}

export type PrimaryLanguage = {
  __typename?: 'PrimaryLanguage';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PrimaryLanguageInput = {
  name: Scalars['String'];
};

export type ProfileSlots = {
  __typename?: 'ProfileSlots';
  created_by?: Maybe<Scalars['String']>;
  etime?: Maybe<Scalars['Time']>;
  id?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['Date']>;
  stime?: Maybe<Scalars['Time']>;
  timeperiod?: Maybe<Scalars['Date']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type ProfileSlotsInput = {
  created_by?: Maybe<Scalars['String']>;
  etime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  service_type_id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['String']>;
  stime?: Maybe<Scalars['String']>;
  timeperiod?: Maybe<Scalars['Date']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type Provider = {
  __typename?: 'Provider';
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_activity?: Maybe<Scalars['DateTime']>;
  last_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: Maybe<Array<Role>>;
};

export type Query = {
  __typename?: 'Query';
  Activityfeed?: Maybe<Array<Activityfeed>>;
  Availableslots: Scalars['JSON'];
  Datacount: Scalars['JSON'];
  DocumentedSession: Scalars['JSON'];
  /** Retrieve a single article */
  GCMS_article?: Maybe<CmsArticle>;
  /** Retrieve document version */
  GCMS_articleVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple articles */
  GCMS_articles: Array<CmsArticle>;
  /** Retrieve multiple articles using the Relay connection interface */
  GCMS_articlesConnection: CmsArticleConnection;
  /** Retrieve a single asset */
  GCMS_asset?: Maybe<CmsAsset>;
  /** Retrieve document version */
  GCMS_assetVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple assets */
  GCMS_assets: Array<CmsAsset>;
  /** Retrieve multiple assets using the Relay connection interface */
  GCMS_assetsConnection: CmsAssetConnection;
  /** Retrieve a single cOGame */
  GCMS_cOGame?: Maybe<CmsCoGame>;
  /** Retrieve document version */
  GCMS_cOGameVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple cOGames */
  GCMS_cOGames: Array<CmsCoGame>;
  /** Retrieve multiple cOGames using the Relay connection interface */
  GCMS_cOGamesConnection: CmsCoGameConnection;
  /** Retrieve a single cOQuestion */
  GCMS_cOQuestion?: Maybe<CmsCoQuestion>;
  /** Retrieve document version */
  GCMS_cOQuestionVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple cOQuestions */
  GCMS_cOQuestions: Array<CmsCoQuestion>;
  /** Retrieve multiple cOQuestions using the Relay connection interface */
  GCMS_cOQuestionsConnection: CmsCoQuestionConnection;
  /** Retrieve a single discipline */
  GCMS_discipline?: Maybe<CmsDiscipline>;
  /** Retrieve document version */
  GCMS_disciplineVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple disciplines */
  GCMS_disciplines: Array<CmsDiscipline>;
  /** Retrieve multiple disciplines using the Relay connection interface */
  GCMS_disciplinesConnection: CmsDisciplineConnection;
  /** Retrieve a single dragBoard */
  GCMS_dragBoard?: Maybe<CmsDragBoard>;
  /** Retrieve document version */
  GCMS_dragBoardVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple dragBoards */
  GCMS_dragBoards: Array<CmsDragBoard>;
  /** Retrieve multiple dragBoards using the Relay connection interface */
  GCMS_dragBoardsConnection: CmsDragBoardConnection;
  /** Retrieve a single mcAnswer */
  GCMS_mcAnswer?: Maybe<CmsMcAnswer>;
  /** Retrieve document version */
  GCMS_mcAnswerVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple mcAnswers */
  GCMS_mcAnswers: Array<CmsMcAnswer>;
  /** Retrieve multiple mcAnswers using the Relay connection interface */
  GCMS_mcAnswersConnection: CmsMcAnswerConnection;
  /** Fetches an object given its ID */
  GCMS_node?: Maybe<CmsNode>;
  /** Retrieve a single orderingAnswer */
  GCMS_orderingAnswer?: Maybe<CmsOrderingAnswer>;
  /** Retrieve document version */
  GCMS_orderingAnswerVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple orderingAnswers */
  GCMS_orderingAnswers: Array<CmsOrderingAnswer>;
  /** Retrieve multiple orderingAnswers using the Relay connection interface */
  GCMS_orderingAnswersConnection: CmsOrderingAnswerConnection;
  /** Retrieve a single orderingGame */
  GCMS_orderingGame?: Maybe<CmsOrderingGame>;
  /** Retrieve document version */
  GCMS_orderingGameVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple orderingGames */
  GCMS_orderingGames: Array<CmsOrderingGame>;
  /** Retrieve multiple orderingGames using the Relay connection interface */
  GCMS_orderingGamesConnection: CmsOrderingGameConnection;
  /** Retrieve a single orderingQuestion */
  GCMS_orderingQuestion?: Maybe<CmsOrderingQuestion>;
  /** Retrieve document version */
  GCMS_orderingQuestionVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple orderingQuestions */
  GCMS_orderingQuestions: Array<CmsOrderingQuestion>;
  /** Retrieve multiple orderingQuestions using the Relay connection interface */
  GCMS_orderingQuestionsConnection: CmsOrderingQuestionConnection;
  /** Retrieve a single pdf */
  GCMS_pdf?: Maybe<CmsPdf>;
  /** Retrieve document version */
  GCMS_pdfVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple pdfs */
  GCMS_pdfs: Array<CmsPdf>;
  /** Retrieve multiple pdfs using the Relay connection interface */
  GCMS_pdfsConnection: CmsPdfConnection;
  /** Retrieve a single resource */
  GCMS_resource?: Maybe<CmsResource>;
  /** Retrieve document version */
  GCMS_resourceVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple resources */
  GCMS_resources: Array<CmsResource>;
  /** Retrieve multiple resources using the Relay connection interface */
  GCMS_resourcesConnection: CmsResourceConnection;
  /** Retrieve a single scheduledOperation */
  GCMS_scheduledOperation?: Maybe<CmsScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  GCMS_scheduledOperations: Array<CmsScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  GCMS_scheduledOperationsConnection: CmsScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  GCMS_scheduledRelease?: Maybe<CmsScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  GCMS_scheduledReleases: Array<CmsScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  GCMS_scheduledReleasesConnection: CmsScheduledReleaseConnection;
  /** Retrieve a single user */
  GCMS_user?: Maybe<CmsUser>;
  /** Retrieve multiple users */
  GCMS_users: Array<CmsUser>;
  /** Retrieve multiple users using the Relay connection interface */
  GCMS_usersConnection: CmsUserConnection;
  /** Retrieve a single video */
  GCMS_video?: Maybe<CmsVideo>;
  /** Retrieve document version */
  GCMS_videoVersion?: Maybe<CmsDocumentVersion>;
  /** Retrieve multiple videos */
  GCMS_videos: Array<CmsVideo>;
  /** Retrieve multiple videos using the Relay connection interface */
  GCMS_videosConnection: CmsVideoConnection;
  Mandatorystatus: Scalars['JSON'];
  ServiceSession: Scalars['JSON'];
  Sessionstatus: Scalars['JSON'];
  StudentSessionCount?: Maybe<Array<StudentListSession>>;
  StudentSessionsCount?: Maybe<Array<StudentSessions>>;
  activeprovider?: Maybe<Array<Caseload>>;
  activeproviders?: Maybe<Array<Activeprovider>>;
  addAppointmetnsList?: Maybe<Scalars['JSON']>;
  adimncount: Scalars['JSON'];
  adminUsersList?: Maybe<Array<AdminUsers>>;
  agency?: Maybe<Array<Agency>>;
  agencyList?: Maybe<Array<Agency>>;
  agencycheck: Scalars['Boolean'];
  agesServed?: Maybe<Array<AgesServed>>;
  appointmentList?: Maybe<Array<AppointmentList>>;
  appointmentProfile?: Maybe<Array<ApppitmentProfile>>;
  appointmentProfileCount?: Maybe<Array<ApppitmentProfileCount>>;
  appointmentStudents?: Maybe<Array<AppointmentStudent>>;
  appointmentTypes?: Maybe<Array<AppointmentTypes>>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsData?: Maybe<Array<Maybe<AppointmentList>>>;
  authenticationTokens: AuthenticationTokens;
  blackoutDay?: Maybe<Array<BlackoutDays>>;
  blackoutDays?: Maybe<Array<BlackoutDays>>;
  caseStudents?: Maybe<Array<CaseStudent>>;
  caseload?: Maybe<Array<Caseload>>;
  caseloadProviders?: Maybe<Array<Maybe<CaseloadProviders>>>;
  caseloadStatus?: Maybe<Scalars['Boolean']>;
  caseloadType?: Maybe<Array<CaseloadType>>;
  caseloads?: Maybe<Array<Caseload>>;
  certificationDays?: Maybe<Array<CertificationDay>>;
  chatCredentials: ChatCredentials;
  clientFilter?: Maybe<Array<Student>>;
  contact_attempts?: Maybe<Array<ContactAttempts>>;
  counties?: Maybe<Array<Counties>>;
  createSessionInfo?: Maybe<Array<SessionInfo>>;
  currentUser: User;
  displayAddStudentPermission?: Maybe<Array<UserDistrictQueryInput>>;
  distAdminDocument?: Maybe<Array<SessionDocument>>;
  district?: Maybe<District>;
  districts?: Maybe<Array<District>>;
  districtselect?: Maybe<Array<District>>;
  documentation?: Maybe<Array<SessionStudents>>;
  ethnicGroup?: Maybe<Array<EthnicGroup>>;
  fileUploadList?: Maybe<Array<Maybe<SignalFileUpload>>>;
  getAddSlotUsers?: Maybe<Array<Maybe<User>>>;
  getAdminUser: GetAdminUser;
  getMasterSlots?: Maybe<Scalars['JSON']>;
  getNewProfileSlots?: Maybe<Scalars['JSON']>;
  getStudentsData?: Maybe<Array<StudentsData>>;
  getUnallocatedUserSlots?: Maybe<Scalars['JSON']>;
  getUpdateSlotsList?: Maybe<Scalars['JSON']>;
  getUserAvailableProfileSlots?: Maybe<Scalars['JSON']>;
  getUserDetailsInProfile?: Maybe<Scalars['JSON']>;
  goalNumberCheck?: Maybe<Scalars['Boolean']>;
  goalProgress?: Maybe<Array<Maybe<GoalProgress>>>;
  goalTypes?: Maybe<Array<GoalTypes>>;
  goals?: Maybe<Array<StudentGoals>>;
  goalsTracker?: Maybe<Array<GoalsTracker>>;
  grade?: Maybe<Array<Grade>>;
  icdCode: IcdCode;
  icdCodes?: Maybe<Array<IcdCode>>;
  iepCount?: Maybe<Array<IepsCount>>;
  iepsDues?: Maybe<Array<IepsDue>>;
  insurance?: Maybe<Array<Insurance>>;
  listChatGroupMembers?: Maybe<Array<User>>;
  listChatGroups?: Maybe<Array<ChatGroup>>;
  listChatUsers?: Maybe<Array<User>>;
  listDocument?: Maybe<Array<SessionDocument>>;
  listInstantMessaging?: Maybe<Array<ChatInstantMessaging>>;
  listViewLog?: Maybe<Array<Maybe<WaitlistViewLog>>>;
  login: AuthenticationTokens;
  lookupUser?: Maybe<User>;
  mandatoryDataCheck?: Maybe<Array<MandatoryFormDetails>>;
  mandatoryDetailsList?: Maybe<Array<MandatoryFormDetails>>;
  mandatoryFormStatus?: Maybe<Array<MandatoryFormStatus>>;
  mandatoryInvite?: Maybe<MandatoryFormDetails>;
  mandatoryListCall?: Maybe<Array<Maybe<MandatoryFormDetails>>>;
  mandatoryResendDetails?: Maybe<Array<MandatoryFormDetails>>;
  metrics?: Maybe<Metric>;
  myAssignments?: Maybe<Array<UserAssignment>>;
  myCategories?: Maybe<Array<Categories>>;
  myGeneratedReports?: Maybe<Array<Report>>;
  myServiceRequests?: Maybe<Array<ServiceRequest>>;
  newDistrictDashboards?: Maybe<Array<NewDistrictDashboard>>;
  nextDays?: Maybe<Scalars['JSON']>;
  noteCategory?: Maybe<Array<NoteCategory>>;
  noteCategoryType?: Maybe<Array<NoteCategoryType>>;
  notes?: Maybe<Array<Notes>>;
  ongoingCare?: Maybe<Array<Sessions>>;
  ongoingcare?: Maybe<Array<OngoingCare>>;
  ongoingcare_actions?: Maybe<Array<OngoingCareActions>>;
  ongoingcarecount: Scalars['JSON'];
  ongoingcares?: Maybe<Array<OngoingCare>>;
  ongoingrequest: Scalars['JSON'];
  outCome?: Maybe<Array<OutCome>>;
  primaryLanguage?: Maybe<Array<PrimaryLanguage>>;
  progressBars?: Maybe<Array<ProgressBar>>;
  providerCaseloads?: Maybe<Array<ProviderCaseload>>;
  referral?: Maybe<Array<Maybe<Referral>>>;
  relationshipToClient?: Maybe<Array<RelationshipToClient>>;
  report: Scalars['JSON'];
  reportshistory?: Maybe<Array<Reportshistory>>;
  reportsstatus?: Maybe<Array<Reportsstatus>>;
  reportusers?: Maybe<Array<TherapistListForDoc>>;
  request_referral?: Maybe<Array<RequestReferral>>;
  rescheduleusers?: Maybe<Array<TherapistListForDoc>>;
  resendAppointmentEmail?: Maybe<Scalars['Boolean']>;
  school: School;
  schools?: Maybe<Array<School>>;
  service: Service;
  serviceRequests?: Maybe<Array<ServiceRequest>>;
  services?: Maybe<Array<Service>>;
  servicesList?: Maybe<Array<Service>>;
  session: Session;
  sessionCount?: Maybe<Array<Maybe<SessionsCount>>>;
  sessionDocument?: Maybe<Array<SessionDocument>>;
  sessionGoalsList?: Maybe<Array<SessionGoals>>;
  sessionRateList?: Maybe<Array<SessionRate>>;
  sessionStatus?: Maybe<Scalars['Boolean']>;
  sessionTrackers?: Maybe<Array<SessionTrackers>>;
  sessions?: Maybe<Array<Session>>;
  sessionsList?: Maybe<Array<Sessions>>;
  signalExperience?: Maybe<Array<SignalExperience>>;
  signalOutreachType?: Maybe<Array<Maybe<OutreachType>>>;
  signalPronouns?: Maybe<Array<SignalPronouns>>;
  signalServices?: Maybe<Array<SignalServices>>;
  signalSlots?: Maybe<Array<SignalSlots>>;
  signalTherapistUpdateView?: Maybe<Array<SignalTherapistList>>;
  signatureHistoryList?: Maybe<Array<SignatureHistory>>;
  specialities?: Maybe<Array<Specialities>>;
  state: State;
  states?: Maybe<Array<State>>;
  student: Student;
  studentAbsenses?: Maybe<Array<StudentsAbsenses>>;
  studentDocumentation?: Maybe<Array<SessionStudents>>;
  studentGrades?: Maybe<Array<StudentGrade>>;
  studentIeps?: Maybe<Array<StudentIeps>>;
  studentSpecialties?: Maybe<Array<StudentSpecialties>>;
  studentfilter?: Maybe<Array<SessionStudents>>;
  students?: Maybe<Array<Student>>;
  studentsProfile?: Maybe<Array<Student>>;
  surveystudent: Scalars['JSON'];
  surveytherapist: Scalars['JSON'];
  therapist: Therapist;
  therapistCaseloads?: Maybe<Array<TherapistCaseload>>;
  therapistDescipline?: Maybe<Array<TherapistDescipline>>;
  therapistIeps?: Maybe<Array<TherapistIep>>;
  therapistListForDoc?: Maybe<Array<TherapistListForDoc>>;
  therapistReports?: Maybe<Array<Therapist>>;
  therapistabsences?: Maybe<Array<TherapistAbsences>>;
  therapistminutes?: Maybe<Array<TherapistMinutes>>;
  therapists?: Maybe<Array<User>>;
  trackerTypes?: Maybe<Array<TrackerTypes>>;
  unDocumentedSessions?: Maybe<Array<UnDocumentedSession>>;
  unreadMessageCount: UnreadMessageCount;
  user: User;
  userAvailableSp?: Maybe<Scalars['JSON']>;
  userDistricts?: Maybe<Array<UserDistrict>>;
  userDocument?: Maybe<Array<SessionDocument>>;
  userInvite?: Maybe<UserInvite>;
  userListDistricts?: Maybe<Array<UserDistrictQueryInput>>;
  userListDistrictsForCaseload?: Maybe<Array<UserDistrictQueryInput>>;
  userParticipant: UserParticipant;
  userParticipants?: Maybe<Array<UserParticipant>>;
  userServiceList?: Maybe<Scalars['JSON']>;
  userStudentsForDoc?: Maybe<Array<StudentsData>>;
  useragegroup?: Maybe<Array<UserAgegroup>>;
  useragency?: Maybe<Array<UserAgency>>;
  usercounty?: Maybe<Array<UserCounty>>;
  userdiscipline?: Maybe<Array<UserDiscipline>>;
  userinsurance?: Maybe<Array<UserInsurance>>;
  userlanguage?: Maybe<Array<UserLanguage>>;
  users?: Maybe<Array<User>>;
  userspeciality?: Maybe<Array<UserSpeciality>>;
  wailistassignment: Scalars['JSON'];
  waitlist?: Maybe<Array<Waitlist>>;
};


export type QueryActivityfeedArgs = {
  from_date?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['String']>;
};


export type QueryAvailableslotsArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryDatacountArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryDocumentedSessionArgs = {
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
};


export type QueryGcms_ArticleArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsArticleWhereUniqueInput;
};


export type QueryGcms_ArticleVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_ArticlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsArticleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsArticleWhereInput>;
};


export type QueryGcms_ArticlesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsArticleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsArticleWhereInput>;
};


export type QueryGcms_AssetArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsAssetWhereUniqueInput;
};


export type QueryGcms_AssetVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_AssetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsAssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsAssetWhereInput>;
};


export type QueryGcms_AssetsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsAssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsAssetWhereInput>;
};


export type QueryGcms_CoGameArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsCoGameWhereUniqueInput;
};


export type QueryGcms_CoGameVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_CoGamesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsCoGameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsCoGameWhereInput>;
};


export type QueryGcms_CoGamesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsCoGameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsCoGameWhereInput>;
};


export type QueryGcms_CoQuestionArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsCoQuestionWhereUniqueInput;
};


export type QueryGcms_CoQuestionVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_CoQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsCoQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsCoQuestionWhereInput>;
};


export type QueryGcms_CoQuestionsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsCoQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsCoQuestionWhereInput>;
};


export type QueryGcms_DisciplineArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsDisciplineWhereUniqueInput;
};


export type QueryGcms_DisciplineVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_DisciplinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsDisciplineWhereInput>;
};


export type QueryGcms_DisciplinesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsDisciplineOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsDisciplineWhereInput>;
};


export type QueryGcms_DragBoardArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsDragBoardWhereUniqueInput;
};


export type QueryGcms_DragBoardVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_DragBoardsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsDragBoardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsDragBoardWhereInput>;
};


export type QueryGcms_DragBoardsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsDragBoardOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsDragBoardWhereInput>;
};


export type QueryGcms_McAnswerArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsMcAnswerWhereUniqueInput;
};


export type QueryGcms_McAnswerVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_McAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsMcAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsMcAnswerWhereInput>;
};


export type QueryGcms_McAnswersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsMcAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsMcAnswerWhereInput>;
};


export type QueryGcms_NodeArgs = {
  id: Scalars['ID'];
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
};


export type QueryGcms_OrderingAnswerArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsOrderingAnswerWhereUniqueInput;
};


export type QueryGcms_OrderingAnswerVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_OrderingAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingAnswerWhereInput>;
};


export type QueryGcms_OrderingAnswersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingAnswerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingAnswerWhereInput>;
};


export type QueryGcms_OrderingGameArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsOrderingGameWhereUniqueInput;
};


export type QueryGcms_OrderingGameVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_OrderingGamesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingGameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingGameWhereInput>;
};


export type QueryGcms_OrderingGamesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingGameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingGameWhereInput>;
};


export type QueryGcms_OrderingQuestionArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsOrderingQuestionWhereUniqueInput;
};


export type QueryGcms_OrderingQuestionVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_OrderingQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingQuestionWhereInput>;
};


export type QueryGcms_OrderingQuestionsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsOrderingQuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsOrderingQuestionWhereInput>;
};


export type QueryGcms_PdfArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsPdfWhereUniqueInput;
};


export type QueryGcms_PdfVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_PdfsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsPdfOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsPdfWhereInput>;
};


export type QueryGcms_PdfsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsPdfOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsPdfWhereInput>;
};


export type QueryGcms_ResourceArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsResourceWhereUniqueInput;
};


export type QueryGcms_ResourceVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_ResourcesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsResourceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsResourceWhereInput>;
};


export type QueryGcms_ResourcesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsResourceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsResourceWhereInput>;
};


export type QueryGcms_ScheduledOperationArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsScheduledOperationWhereUniqueInput;
};


export type QueryGcms_ScheduledOperationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type QueryGcms_ScheduledOperationsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsScheduledOperationWhereInput>;
};


export type QueryGcms_ScheduledReleaseArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsScheduledReleaseWhereUniqueInput;
};


export type QueryGcms_ScheduledReleasesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsScheduledReleaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsScheduledReleaseWhereInput>;
};


export type QueryGcms_ScheduledReleasesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsScheduledReleaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsScheduledReleaseWhereInput>;
};


export type QueryGcms_UserArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsUserWhereUniqueInput;
};


export type QueryGcms_UsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsUserWhereInput>;
};


export type QueryGcms_UsersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsUserWhereInput>;
};


export type QueryGcms_VideoArgs = {
  locales?: Array<CmsLocale>;
  stage?: CmsStage;
  where: CmsVideoWhereUniqueInput;
};


export type QueryGcms_VideoVersionArgs = {
  where: CmsVersionWhereInput;
};


export type QueryGcms_VideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsVideoWhereInput>;
};


export type QueryGcms_VideosConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Array<CmsLocale>;
  orderBy?: Maybe<CmsVideoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  stage?: CmsStage;
  where?: Maybe<CmsVideoWhereInput>;
};


export type QueryServiceSessionArgs = {
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
};


export type QuerySessionstatusArgs = {
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
};


export type QueryStudentSessionsCountArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryActiveproviderArgs = {
  student_id: Scalars['Int'];
};


export type QueryActiveprovidersArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryAddAppointmetnsListArgs = {
  filter?: Maybe<UserFilterInputs>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryAdimncountArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryAgencyArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryAgencycheckArgs = {
  agency_id?: Maybe<Scalars['Int']>;
};


export type QueryAppointmentListArgs = {
  appointment_type_id?: Maybe<Scalars['ID']>;
  district_id?: Maybe<Scalars['Int']>;
  pagination?: Maybe<PaginationInput>;
  service_id?: Maybe<Scalars['Int']>;
  students?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryAppointmentProfileArgs = {
  id: Scalars['Int'];
};


export type QueryAppointmentProfileCountArgs = {
  id: Scalars['Int'];
};


export type QueryAppointmentStudentsArgs = {
  district_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryAppointmentTypesArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryAppointmentsArgs = {
  pagination?: Maybe<PaginationInput>;
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryAppointmentsDataArgs = {
  appointment_id?: Maybe<Scalars['String']>;
};


export type QueryAuthenticationTokensArgs = {
  clientId: Scalars['String'];
  code: Scalars['String'];
  verifier: Scalars['String'];
};


export type QueryBlackoutDayArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryBlackoutDaysArgs = {
  appointment_type_id?: Maybe<Scalars['ID']>;
  district_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryCaseStudentsArgs = {
  district_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryCaseloadArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryCaseloadProvidersArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryCaseloadStatusArgs = {
  status?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryCaseloadTypeArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryCaseloadsArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCertificationDaysArgs = {
  district_id?: Maybe<Scalars['ID']>;
};


export type QueryClientFilterArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryContact_AttemptsArgs = {
  student_id: Scalars['Int'];
};


export type QueryCreateSessionInfoArgs = {
  Appointmentid: Scalars['String'];
  TherapistId: Scalars['String'];
  s_end?: Maybe<Scalars['DateTime']>;
  s_start?: Maybe<Scalars['DateTime']>;
};


export type QueryDisplayAddStudentPermissionArgs = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};


export type QueryDistAdminDocumentArgs = {
  FromDate?: Maybe<Scalars['String']>;
  StuId?: Maybe<Scalars['Int']>;
  ToDate?: Maybe<Scalars['String']>;
  district_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryDistrictArgs = {
  id: Scalars['Int'];
};


export type QueryDistrictsArgs = {
  input?: Maybe<DistrictsQueryInput>;
};


export type QueryDistrictselectArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryDocumentationArgs = {
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  student_id: Scalars['Int'];
  therapist_discipline: Scalars['String'];
};


export type QueryFileUploadListArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryGetAddSlotUsersArgs = {
  district_id?: Maybe<Scalars['Int']>;
};


export type QueryGetAdminUserArgs = {
  Username: Scalars['String'];
};


export type QueryGetMasterSlotsArgs = {
  slot_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGetNewProfileSlotsArgs = {
  timeperiod?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGetStudentsDataArgs = {
  TherapistId: Scalars['String'];
};


export type QueryGetUnallocatedUserSlotsArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGetUpdateSlotsListArgs = {
  slot_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGetUserAvailableProfileSlotsArgs = {
  slot_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGetUserDetailsInProfileArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryGoalNumberCheckArgs = {
  goal_number?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryGoalProgressArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryGoalsArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryGradeArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryIcdCodeArgs = {
  id: Scalars['Int'];
};


export type QueryIcdCodesArgs = {
  input?: Maybe<IcdInputQuery>;
};


export type QueryIepCountArgs = {
  district_id?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryIepsDuesArgs = {
  district_id?: Maybe<Scalars['ID']>;
};


export type QueryListChatGroupMembersArgs = {
  chatAuthToken: Scalars['String'];
  groupId: Scalars['String'];
};


export type QueryListChatGroupsArgs = {
  chatAuthToken: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
};


export type QueryListChatUsersArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryListDocumentArgs = {
  district_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryListInstantMessagingArgs = {
  chatAuthToken: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
};


export type QueryListViewLogArgs = {
  waitlist_id?: Maybe<Scalars['String']>;
};


export type QueryLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type QueryLookupUserArgs = {
  password?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};


export type QueryMandatoryDataCheckArgs = {
  id: Scalars['String'];
};


export type QueryMandatoryDetailsListArgs = {
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryMandatoryInviteArgs = {
  token: Scalars['String'];
};


export type QueryMandatoryListCallArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryMandatoryResendDetailsArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryMyGeneratedReportsArgs = {
  last?: Maybe<Scalars['Int']>;
};


export type QueryNewDistrictDashboardsArgs = {
  district_id?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryNoteCategoryArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryNoteCategoryTypeArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryNotesArgs = {
  student_id: Scalars['Int'];
};


export type QueryOngoingcareArgs = {
  assigned_to?: Maybe<Scalars['String']>;
};


export type QueryOngoingcaresArgs = {
  assigned_to?: Maybe<Scalars['String']>;
};


export type QueryOngoingrequestArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryPrimaryLanguageArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryProgressBarsArgs = {
  district_id?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryProviderCaseloadsArgs = {
  district_id?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryReportArgs = {
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
};


export type QueryReportusersArgs = {
  district_id?: Maybe<Scalars['Int']>;
};


export type QueryRequest_ReferralArgs = {
  session_id?: Maybe<Scalars['String']>;
};


export type QueryResendAppointmentEmailArgs = {
  appointment_id?: Maybe<Scalars['String']>;
};


export type QuerySchoolArgs = {
  id: Scalars['Int'];
};


export type QuerySchoolsArgs = {
  district_id?: Maybe<Scalars['Int']>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryServiceArgs = {
  id: Scalars['Int'];
};


export type QueryServicesArgs = {
  input?: Maybe<ServiceInputQuery>;
};


export type QuerySessionArgs = {
  id: Scalars['Int'];
};


export type QuerySessionCountArgs = {
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QuerySessionDocumentArgs = {
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  district_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  therapist_discipline?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QuerySessionStatusArgs = {
  appointment_id: Scalars['String'];
  scheduled_end: Scalars['String'];
  scheduled_start: Scalars['String'];
};


export type QuerySessionsListArgs = {
  id: Scalars['String'];
};


export type QuerySignalTherapistUpdateViewArgs = {
  id: Scalars['ID'];
};


export type QueryStateArgs = {
  id: Scalars['Int'];
};


export type QueryStudentArgs = {
  id: Scalars['Int'];
};


export type QueryStudentAbsensesArgs = {
  district_id?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryStudentDocumentationArgs = {
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  therapist_discipline?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryStudentGradesArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryStudentIepsArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryStudentSpecialtiesArgs = {
  student_id?: Maybe<Scalars['Int']>;
};


export type QueryStudentfilterArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryStudentsArgs = {
  district_id?: Maybe<Scalars['Int']>;
  icdCode_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};


export type QueryStudentsProfileArgs = {
  id: Scalars['Int'];
};


export type QuerySurveystudentArgs = {
  sid?: Maybe<Scalars['Int']>;
};


export type QuerySurveytherapistArgs = {
  sid?: Maybe<Scalars['Int']>;
};


export type QueryTherapistArgs = {
  id: Scalars['ID'];
};


export type QueryTherapistCaseloadsArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryTherapistIepsArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryTherapistListForDocArgs = {
  district_id?: Maybe<Scalars['Int']>;
};


export type QueryTherapistabsencesArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryTherapistminutesArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryTherapistsArgs = {
  district_id?: Maybe<Scalars['Int']>;
};


export type QueryUnDocumentedSessionsArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUnreadMessageCountArgs = {
  chatAuthToken: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserAvailableSpArgs = {
  service_id?: Maybe<Scalars['Int']>;
  tid?: Maybe<Scalars['String']>;
  ttime?: Maybe<Scalars['String']>;
};


export type QueryUserDistrictsArgs = {
  district_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUserDocumentArgs = {
  FromDate?: Maybe<Scalars['String']>;
  StuId?: Maybe<Scalars['Int']>;
  ToDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUserInviteArgs = {
  token: Scalars['String'];
};


export type QueryUserListDistrictsArgs = {
  user_id: Scalars['String'];
};


export type QueryUserListDistrictsForCaseloadArgs = {
  user_id: Scalars['String'];
};


export type QueryUserParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryUserServiceListArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserStudentsForDocArgs = {
  user_id: Scalars['String'];
};


export type QueryUseragegroupArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUseragencyArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUsercountyArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUserdisciplineArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUserinsuranceArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUserlanguageArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  input?: Maybe<UsersQueryInput>;
};


export type QueryUserspecialityArgs = {
  user_id?: Maybe<Scalars['String']>;
};


export type QueryWaitlistArgs = {
  user_id?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type ReferralInput = {
  created_by?: Maybe<Scalars['String']>;
  referral_date?: Maybe<Scalars['DateTime']>;
  student_id: Scalars['Int'];
};

export type RelationshipToClient = {
  __typename?: 'RelationshipToClient';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  spanish_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  user: User;
};

export type Reportshistory = {
  __typename?: 'Reportshistory';
  IMatter_Provider_Manual?: Maybe<Scalars['String']>;
  YRMHR_Provider_Manual?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['Date']>;
  file_type?: Maybe<Scalars['String']>;
  from_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  report_name?: Maybe<Scalars['String']>;
  s3_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  to_date?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<User>;
};

export type ReportshistoryInput = {
  created_by?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  from_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  report_name?: Maybe<Scalars['String']>;
  s3_name?: Maybe<Scalars['String']>;
  sortby?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Reportsstatus = {
  __typename?: 'Reportsstatus';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ReportsstatusInput = {
  name: Scalars['String'];
};

export type RequestReferral = {
  __typename?: 'RequestReferral';
  name?: Maybe<Scalars['String']>;
  referral_id?: Maybe<Scalars['Int']>;
};

export enum Role {
  Parent = 'PARENT',
  SchoolAdmin = 'SCHOOL_ADMIN',
  SchoolTherapist = 'SCHOOL_THERAPIST',
  TeleteachersAdmin = 'TELETEACHERS_ADMIN',
  Therapist = 'THERAPIST'
}

export type School = {
  __typename?: 'School';
  city: Scalars['String'];
  district: District;
  id: Scalars['Int'];
  location_code: Scalars['String'];
  name: Scalars['String'];
  state: State;
  status: SchoolStatus;
  street_address: Scalars['String'];
  zip: Scalars['String'];
};

export type SchoolInput = {
  city: Scalars['String'];
  district: Scalars['Int'];
  location_code: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['Int'];
  status: SchoolStatus;
  street_address: Scalars['String'];
  zip: Scalars['String'];
};

export enum SchoolStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Service = {
  __typename?: 'Service';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_direct?: Maybe<Scalars['Boolean']>;
  is_evaluation?: Maybe<Scalars['Boolean']>;
  is_indirect?: Maybe<Scalars['Boolean']>;
  is_support?: Maybe<Scalars['Boolean']>;
  is_therapy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  status?: Maybe<ServiceStatus>;
  therapist_discipline?: Maybe<TherapistDiscipline>;
};

export type ServiceInput = {
  code?: Maybe<Scalars['String']>;
  is_direct?: Maybe<Scalars['Boolean']>;
  is_evaluation?: Maybe<Scalars['Boolean']>;
  is_indirect?: Maybe<Scalars['Boolean']>;
  is_support?: Maybe<Scalars['Boolean']>;
  is_therapy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  therapistDiscipline?: Maybe<TherapistDiscipline>;
};

export type ServiceInputQuery = {
  discipline?: Maybe<Array<TherapistDiscipline>>;
  is_direct?: Maybe<Scalars['Boolean']>;
  is_indirect?: Maybe<Scalars['Boolean']>;
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  createdAt: Scalars['DateTime'];
  discipline?: Maybe<ServiceRequestDiscipline>;
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  requester: User;
  student: Student;
  type: ServiceRequestType;
};

export enum ServiceRequestDiscipline {
  Behavioral = 'BEHAVIORAL',
  Occupational = 'OCCUPATIONAL',
  Physical = 'PHYSICAL',
  Speech = 'SPEECH'
}

export enum ServiceRequestType {
  Evaluation = 'EVALUATION',
  RecordReview = 'RECORD_REVIEW',
  Screening = 'SCREENING',
  Therapy = 'THERAPY'
}

export enum ServiceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Session = {
  __typename?: 'Session';
  description?: Maybe<Scalars['String']>;
  geo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  service_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type SessionDocument = {
  __typename?: 'SessionDocument';
  appointment_id: AppointmentList;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  edate?: Maybe<Scalars['Date']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  etime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  reference?: Maybe<Scalars['String']>;
  scheduled_end?: Maybe<Scalars['DateTime']>;
  scheduled_start?: Maybe<Scalars['DateTime']>;
  sdate?: Maybe<Scalars['Date']>;
  session_Goals?: Maybe<Array<Maybe<SessionGoals>>>;
  session_students?: Maybe<Array<SessionStudents>>;
  session_type_id: SessionTypes;
  sign?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['DateTime']>;
  stime?: Maybe<Scalars['String']>;
  student_id?: Maybe<Student>;
  tags?: Maybe<Scalars['String']>;
  therapistNoShow?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: User;
};

export type SessionGoals = {
  __typename?: 'SessionGoals';
  activity?: Maybe<Scalars['String']>;
  assist?: Maybe<Scalars['Float']>;
  attempts?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  goal_id?: Maybe<StudentGoals>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  parent_goal_id?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  sessionGoal_status_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Sessions>;
  session_rate_id?: Maybe<Scalars['String']>;
  session_tracker?: Maybe<Array<Maybe<SessionTrackers>>>;
  student_id?: Maybe<Student>;
  total?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SessionGoalsInput = {
  activity?: Maybe<Scalars['String']>;
  assist?: Maybe<Scalars['Float']>;
  attempts?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  sessionGoal_status_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  session_rate_id?: Maybe<Scalars['String']>;
  session_tracker?: Maybe<Array<Maybe<SessionTrackersInput>>>;
  student_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SessionInfo = {
  __typename?: 'SessionInfo';
  getSessionId?: Maybe<Scalars['String']>;
};

export type SessionRate = {
  __typename?: 'SessionRate';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SessionStudents = {
  __typename?: 'SessionStudents';
  assessment?: Maybe<Scalars['String']>;
  deleted_goals?: Maybe<Array<Maybe<Scalars['String']>>>;
  edate?: Maybe<Scalars['Date']>;
  etime?: Maybe<Scalars['String']>;
  further_sessions?: Maybe<Scalars['Boolean']>;
  further_sessions_text?: Maybe<Scalars['String']>;
  high_level_care?: Maybe<Scalars['Boolean']>;
  high_level_care_text?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  sdate?: Maybe<Scalars['Date']>;
  session_Goals?: Maybe<Array<Maybe<SessionGoals>>>;
  session_id?: Maybe<Sessions>;
  session_no?: Maybe<Scalars['Int']>;
  stime?: Maybe<Scalars['String']>;
  student_id?: Maybe<Student>;
};

export type SessionStudentsInput = {
  assessment?: Maybe<Scalars['String']>;
  deleted_goals?: Maybe<Array<Maybe<Scalars['String']>>>;
  further_sessions?: Maybe<Scalars['Boolean']>;
  further_sessions_text?: Maybe<Scalars['String']>;
  high_level_care?: Maybe<Scalars['Boolean']>;
  high_level_care_text?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  session_Goals?: Maybe<Array<Maybe<SessionGoalsInput>>>;
  session_id?: Maybe<Scalars['String']>;
  session_no?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

export type SessionTrackers = {
  __typename?: 'SessionTrackers';
  activity?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  present?: Maybe<Scalars['Boolean']>;
  session_goal_id?: Maybe<SessionGoals>;
  tracker_id?: Maybe<GoalsTracker>;
};

export type SessionTrackersInput = {
  activity?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  present?: Maybe<Scalars['Boolean']>;
  session_goal_id?: Maybe<Scalars['String']>;
  tracker_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SessionTypeInput = {
  session_type?: Maybe<Scalars['String']>;
};

export type SessionTypes = {
  __typename?: 'SessionTypes';
  id: Scalars['ID'];
  session_type?: Maybe<Scalars['String']>;
};

export type Sessions = {
  __typename?: 'Sessions';
  appointment_id?: Maybe<AppointmentList>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  outcome_id?: Maybe<OutCome>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  reference?: Maybe<Scalars['String']>;
  requestReferral?: Maybe<Array<Referral>>;
  scheduled_end?: Maybe<Scalars['DateTime']>;
  scheduled_start?: Maybe<Scalars['DateTime']>;
  service_id?: Maybe<Service>;
  session_students?: Maybe<Array<SessionStudents>>;
  session_type_id?: Maybe<SessionTypes>;
  sign?: Maybe<Scalars['Boolean']>;
  signal_service_id?: Maybe<SignalServices>;
  signature_history?: Maybe<SignatureHistory>;
  started_at?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['String']>;
  therapistNoShow?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<User>;
};

export type SessionsCount = {
  __typename?: 'SessionsCount';
  count?: Maybe<Scalars['Int']>;
};

export type SessionsInput = {
  appointment_id?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  outcome_id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['String']>;
  present?: Maybe<Scalars['Boolean']>;
  reference?: Maybe<Scalars['String']>;
  requestReferral?: Maybe<Array<Scalars['Int']>>;
  scheduled_end?: Maybe<Scalars['DateTime']>;
  scheduled_start?: Maybe<Scalars['DateTime']>;
  service_id?: Maybe<Scalars['Int']>;
  session_students?: Maybe<Array<SessionStudentsInput>>;
  session_type_id?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['Boolean']>;
  signature_history?: Maybe<SignatureHistoryInput>;
  started_at?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['String']>;
  therapistNoShow?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type SignalExperience = {
  __typename?: 'SignalExperience';
  created_at?: Maybe<Scalars['DateTime']>;
  experience?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SignalFileUpload = {
  __typename?: 'SignalFileUpload';
  categories?: Maybe<Categories>;
  created_by?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['DateTime']>;
  fileUrl?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Student>;
  updated_by?: Maybe<Scalars['String']>;
  updated_on?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<User>;
};

export type SignalFileUploadInput = {
  categories?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
  upload: Scalars['Upload'];
  user_id?: Maybe<Scalars['String']>;
};

export type SignalPronouns = {
  __typename?: 'SignalPronouns';
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SignalServices = {
  __typename?: 'SignalServices';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type SignalServicesInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type SignalSlots = {
  __typename?: 'SignalSlots';
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  time_slot?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SignalSlotsInput = {
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  time_slot?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SignalTherapistInput = {
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  userAvailabilityInput?: Maybe<Array<TherapistAvailabilityInput>>;
};

export type SignalTherapistList = {
  __typename?: 'SignalTherapistList';
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  userAvailabilityInput?: Maybe<Scalars['JSON']>;
};

export type SignatureHistory = {
  __typename?: 'SignatureHistory';
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_sign_at?: Maybe<Scalars['DateTime']>;
  session_id?: Maybe<Sessions>;
  sign?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SignatureHistoryInput = {
  created_by?: Maybe<Scalars['String']>;
  last_sign_at?: Maybe<Scalars['DateTime']>;
  session_id?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type SlotManagement = {
  __typename?: 'SlotManagement';
  appointment_slot_id?: Maybe<Scalars['String']>;
  enddatetime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['Date']>;
  startdatetime?: Maybe<Scalars['DateTime']>;
  student_id?: Maybe<Scalars['Int']>;
  time_slot_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type Specialities = {
  __typename?: 'Specialities';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type SpecialitiesInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type State = {
  __typename?: 'State';
  abbreviation: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  contact_info?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  medicaid_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  street_address?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type StateInput = {
  abbreviation: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  medicaidName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  streetAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Student = {
  __typename?: 'Student';
  accommodations?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone_number?: Maybe<Scalars['String']>;
  county?: Maybe<Counties>;
  craft_score?: Maybe<Scalars['Int']>;
  district?: Maybe<District>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  icdCode?: Maybe<IcdCode>;
  id: Scalars['Int'];
  iep_date?: Maybe<Scalars['Date']>;
  insurance_company?: Maybe<Insurance>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent1_email?: Maybe<Scalars['String']>;
  parent1_phone_number?: Maybe<Scalars['String']>;
  parent2_email?: Maybe<Scalars['String']>;
  parent2_name?: Maybe<Scalars['String']>;
  parent2_phone_number?: Maybe<Scalars['String']>;
  parent_name?: Maybe<Scalars['String']>;
  parentphonecheck1?: Maybe<Scalars['Boolean']>;
  parentphonecheck2?: Maybe<Scalars['Boolean']>;
  phonecheck?: Maybe<Scalars['Boolean']>;
  primaryLanguage?: Maybe<PrimaryLanguage>;
  pronouns?: Maybe<SignalPronouns>;
  psc_score?: Maybe<Scalars['Int']>;
  re_evl_date?: Maybe<Scalars['Date']>;
  school?: Maybe<School>;
  sdoh_score?: Maybe<Scalars['Int']>;
  session_service?: Maybe<SignalServices>;
  sessions?: Maybe<Scalars['Int']>;
  status?: Maybe<StudentStatus>;
  student_id?: Maybe<Scalars['String']>;
  student_specialities?: Maybe<Array<Maybe<StudentSpecialties>>>;
  unique_profile_id?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export enum StudentGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type StudentGoals = {
  __typename?: 'StudentGoals';
  actual?: Maybe<Scalars['Int']>;
  baseline?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal_number?: Maybe<Scalars['Float']>;
  goal_type_id?: Maybe<GoalTypes>;
  id: Scalars['ID'];
  iep_id?: Maybe<StudentIeps>;
  name?: Maybe<Scalars['String']>;
  parent_goal_id?: Maybe<Array<Maybe<StudentGoals>>>;
  reached?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Student>;
  target?: Maybe<Scalars['Int']>;
  trackers?: Maybe<Array<Maybe<GoalsTracker>>>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type StudentGoalsInput = {
  actual?: Maybe<Scalars['Int']>;
  baseline?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goalTypeId?: Maybe<Scalars['String']>;
  goal_number?: Maybe<Scalars['Float']>;
  iepId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentGoalId?: Maybe<Scalars['String']>;
  reached?: Maybe<Scalars['Boolean']>;
  studentId?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type StudentIeps = {
  __typename?: 'StudentIeps';
  created_by?: Maybe<Scalars['String']>;
  date_annual_review?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
  date_initiated?: Maybe<Scalars['DateTime']>;
  date_reeval?: Maybe<Scalars['DateTime']>;
  district_contact_days?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  planned_minutes?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Student>;
  updated_by?: Maybe<Scalars['String']>;
};

export type StudentIepsInput = {
  createdBy?: Maybe<Scalars['String']>;
  date_annual_review?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
  date_initiated?: Maybe<Scalars['DateTime']>;
  date_reeval?: Maybe<Scalars['DateTime']>;
  district_contact_days?: Maybe<Scalars['Int']>;
  planned_minutes?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type StudentInput = {
  address?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  contactAltEmail?: Maybe<Scalars['String']>;
  contactAltNumber?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactPhoneNumber?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  district: Scalars['Int'];
  firstName: Scalars['String'];
  gender: StudentGender;
  gradeId: Scalars['Int'];
  icdCode: Scalars['Int'];
  iepDate: Scalars['Date'];
  lastName: Scalars['String'];
  parent1_email?: Maybe<Scalars['String']>;
  parent1_phone_number?: Maybe<Scalars['String']>;
  parent2_email?: Maybe<Scalars['String']>;
  parent2_phone_number?: Maybe<Scalars['String']>;
  primaryLanguageId: Scalars['Int'];
  reEvlDate?: Maybe<Scalars['Date']>;
  school: Scalars['Int'];
  session_service?: Maybe<Scalars['Int']>;
  studentId: Scalars['String'];
  unique_profile_id?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export type StudentProfileInput = {
  accommodations?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone_number?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  insurance_company?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  parent1_email?: Maybe<Scalars['String']>;
  parent1_phone_number?: Maybe<Scalars['String']>;
  parent2_email?: Maybe<Scalars['String']>;
  parent2_name?: Maybe<Scalars['String']>;
  parent2_phone_number?: Maybe<Scalars['String']>;
  parent_name?: Maybe<Scalars['String']>;
  parentphonecheck1?: Maybe<Scalars['Int']>;
  parentphonecheck2?: Maybe<Scalars['Int']>;
  phonecheck?: Maybe<Scalars['Int']>;
  primaryLanguage?: Maybe<Scalars['Int']>;
  pronouns?: Maybe<Scalars['Int']>;
  session_service?: Maybe<Scalars['Int']>;
  student_specialities?: Maybe<Array<Scalars['Int']>>;
  updatedBy?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export type StudentSpecialties = {
  __typename?: 'StudentSpecialties';
  id?: Maybe<Scalars['Int']>;
  speciality_id?: Maybe<Specialities>;
  student_id?: Maybe<Scalars['Int']>;
};

export enum StudentStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type StudentsData = {
  __typename?: 'StudentsData';
  id?: Maybe<Scalars['Int']>;
  student_name?: Maybe<Scalars['String']>;
};

export type Therapist = {
  __typename?: 'Therapist';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TherapistDescipline = {
  __typename?: 'TherapistDescipline';
  id: Scalars['Int'];
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export type TherapistDesciplineInput = {
  name: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
};

export enum TherapistDiscipline {
  All = 'ALL',
  Occupational = 'OCCUPATIONAL',
  Physical = 'PHYSICAL',
  PtAndOt = 'PT_AND_OT',
  SchoolCounselor = 'SCHOOL_COUNSELOR',
  SchoolPsychologist = 'SCHOOL_PSYCHOLOGIST',
  SocialWorker = 'SOCIAL_WORKER',
  SpedTeacher = 'SPED_TEACHER',
  Speech = 'SPEECH',
  TviTeacher = 'TVI_TEACHER'
}

export type TherapistDistrict = {
  __typename?: 'TherapistDistrict';
  alt_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<District>;
  hourly_per_rate?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<User>;
};

export type TherapistListForDoc = {
  __typename?: 'TherapistListForDoc';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type TimeOwed = {
  __typename?: 'TimeOwed';
  minutes?: Maybe<Scalars['Int']>;
  studentsCount?: Maybe<Scalars['Int']>;
};

export enum TimeZone {
  CentralTimeUsAndCanada = 'CENTRAL_TIME_US_AND_CANADA',
  EasternTimeUsAndCanada = 'EASTERN_TIME_US_AND_CANADA',
  MountainTimeUsAndCanada = 'MOUNTAIN_TIME_US_AND_CANADA',
  PacificTimeUsAndCanada = 'PACIFIC_TIME_US_AND_CANADA'
}

export type Tracker = {
  color?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tracker_icon_id?: Maybe<Scalars['Int']>;
  tracker_type_id?: Maybe<Scalars['String']>;
  tracker_type_result?: Maybe<Scalars['Int']>;
};

export type TrackerTypes = {
  __typename?: 'TrackerTypes';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TrackerTypesInput = {
  name?: Maybe<Scalars['String']>;
};

export type UnreadMessageCount = {
  __typename?: 'UnreadMessageCount';
  count?: Maybe<Scalars['Int']>;
};


export type User = {
  __typename?: 'User';
  add_student_permission?: Maybe<UserDistrict>;
  cert_date?: Maybe<Scalars['Date']>;
  cert_expire_date?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  clinician_phone?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  degree_held?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
  email: Scalars['String'];
  ethinic_group_id?: Maybe<EthnicGroup>;
  first_name: Scalars['String'];
  gender?: Maybe<UserGender>;
  home_phone?: Maybe<Scalars['String']>;
  hourly_rate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_activity?: Maybe<Scalars['DateTime']>;
  last_name?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  maiden_name?: Maybe<Scalars['String']>;
  middle_initial?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  newUserAgeGroups?: Maybe<NewUserToAges>;
  npi?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  school_attended?: Maybe<Scalars['String']>;
  sendInvite?: Maybe<Scalars['Boolean']>;
  signalAgeGroup?: Maybe<Array<AgesServed>>;
  signalAgencies?: Maybe<Array<Agency>>;
  signalCounties?: Maybe<Array<Counties>>;
  signalDiscipline?: Maybe<TherapistDescipline>;
  signalInsurance?: Maybe<Array<Insurance>>;
  signalLanguage?: Maybe<Array<PrimaryLanguage>>;
  signalSpecilities?: Maybe<Array<Specialities>>;
  signalUserExperience?: Maybe<SignalExperience>;
  signal_service_id?: Maybe<SignalServices>;
  specialized_training?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  state_license?: Maybe<State>;
  status?: Maybe<UserStatus>;
  street_address?: Maybe<Scalars['String']>;
  student_id?: Maybe<Student>;
  supervisor_name_and_credentials?: Maybe<Scalars['String']>;
  telemedicine_enabled?: Maybe<Scalars['Boolean']>;
  telemedicine_trained_at?: Maybe<Scalars['DateTime']>;
  therapistDistrict?: Maybe<Array<TherapistDistrict>>;
  therapist_discipline?: Maybe<TherapistDiscipline>;
  time_zone?: Maybe<TimeZone>;
  updated_by?: Maybe<Scalars['String']>;
  waitlist_assignee?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
  zoom_id?: Maybe<Scalars['String']>;
};

export type UserAgegroup = {
  __typename?: 'UserAgegroup';
  age_group_id?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
  min_age?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserAgency = {
  __typename?: 'UserAgency';
  agency?: Maybe<Scalars['String']>;
  agency_address?: Maybe<Scalars['String']>;
  agency_id?: Maybe<Scalars['Int']>;
};

export type UserAssignment = {
  __typename?: 'UserAssignment';
  caseloadType?: Maybe<CaseloadType>;
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  student: Student;
  updated_at: Scalars['Date'];
  user?: Maybe<User>;
};

export type UserAssignmentInput = {
  caseloadTypeId: Scalars['Int'];
  studentId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  Name?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type UserCounty = {
  __typename?: 'UserCounty';
  county_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserDiscipline = {
  __typename?: 'UserDiscipline';
  discipline_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserDistrict = {
  __typename?: 'UserDistrict';
  add_student_permission?: Maybe<Scalars['Boolean']>;
  alt_id?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['String']>;
  district_id: Scalars['Int'];
  hourly_rate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  primary?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: User;
};

export type UserDistrictInput = {
  add_student_permission?: Maybe<Scalars['Boolean']>;
  alt_id?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  district_id: Scalars['String'];
  hourly_rate?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type UserDistrictQueryInput = {
  __typename?: 'UserDistrictQueryInput';
  add_student_permission?: Maybe<Scalars['Boolean']>;
  alt_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  hourly_rate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['String']>;
};

export enum UserDistrictStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserFilterInputs = {
  ages_id?: Maybe<Scalars['Int']>;
  county_id?: Maybe<Scalars['Int']>;
  insurance_id?: Maybe<Scalars['Int']>;
  language_id?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['Int']>;
  speciality_id?: Maybe<Array<Scalars['Int']>>;
};

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type UserInput = {
  certDate?: Maybe<Scalars['Date']>;
  certExpireDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  clinician_phone?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  degree_held?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<UserGender>;
  homePhone?: Maybe<Scalars['String']>;
  hourlyRate?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  maidenName?: Maybe<Scalars['String']>;
  max_age?: Maybe<Scalars['Int']>;
  middleInitial?: Maybe<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  mobilePhone?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  school_attended?: Maybe<Scalars['String']>;
  sendInvite?: Maybe<Scalars['Boolean']>;
  signalAgeGroup?: Maybe<Array<Scalars['Int']>>;
  signalAgencies?: Maybe<Array<Scalars['Int']>>;
  signalCounties?: Maybe<Array<Scalars['Int']>>;
  signalDiscipline?: Maybe<Scalars['Int']>;
  signalEthnicGroup?: Maybe<Scalars['Int']>;
  signalInsurance?: Maybe<Array<Scalars['Int']>>;
  signalLanguage?: Maybe<Array<Scalars['Int']>>;
  signalServiceId?: Maybe<Scalars['Int']>;
  signalSpecilities?: Maybe<Array<Scalars['Int']>>;
  signalUserExperience?: Maybe<Scalars['Int']>;
  specialized_training?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateLicense?: Maybe<Scalars['Int']>;
  streetAddress?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['Int']>;
  supervisor_name_and_credentials?: Maybe<Scalars['String']>;
  telemedicineEnabled?: Maybe<Scalars['Boolean']>;
  telemedicineTrainedAt?: Maybe<Scalars['DateTime']>;
  therapistDiscipline?: Maybe<TherapistDiscipline>;
  timeZone?: Maybe<TimeZone>;
  updated_by?: Maybe<Scalars['String']>;
  waitlist_assignee?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
  zoomId?: Maybe<Scalars['String']>;
};

export type UserInsurance = {
  __typename?: 'UserInsurance';
  insurance_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  district?: Maybe<District>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  roles?: Maybe<Array<Role>>;
};

export type UserLanguage = {
  __typename?: 'UserLanguage';
  language_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserParticipant = {
  __typename?: 'UserParticipant';
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  last_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type UserSpeciality = {
  __typename?: 'UserSpeciality';
  name?: Maybe<Scalars['String']>;
  speciality_id?: Maybe<Scalars['Int']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UsersCursor = {
  __typename?: 'UsersCursor';
  nextOffset: Scalars['Int'];
  users?: Maybe<Array<User>>;
};

export type UsersQueryInput = {
  districtId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['Int']>;
  studentId?: Maybe<Scalars['Int']>;
  withRoles?: Maybe<Array<Role>>;
};

export type Waitlist = {
  __typename?: 'Waitlist';
  assigned_user_id?: Maybe<User>;
  availability_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  outreach_attempts?: Maybe<Scalars['Int']>;
  preference_notes?: Maybe<Scalars['String']>;
  student_id?: Maybe<Student>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type WaitlistAssignInput = {
  assigned_user_id?: Maybe<Scalars['String']>;
  assigned_user_name?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  waitlist_id?: Maybe<Scalars['String']>;
};

export type WaitlistOutreach = {
  created_by?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  outreach_type_id?: Maybe<Scalars['Int']>;
  studentName?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  waitlist_id?: Maybe<Scalars['String']>;
};

export type WaitlistViewLog = {
  __typename?: 'WaitlistViewLog';
  action_type_id?: Maybe<LogActionType>;
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  user_id?: Maybe<User>;
  waitlist_id?: Maybe<Waitlist>;
};

export type AdminUsers = {
  __typename?: 'adminUsers';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type AppointmentStudent = {
  __typename?: 'appointmentStudent';
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  student_name?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type BulkUploadCsvData = {
  __typename?: 'bulkUploadCsvData';
  distId?: Maybe<Scalars['Int']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  milliseconds?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type CaseStudent = {
  __typename?: 'caseStudent';
  add_student_permission?: Maybe<Scalars['Boolean']>;
  birth_date?: Maybe<Scalars['Date']>;
  district_id?: Maybe<Scalars['Int']>;
  districtname?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<StudentStatus>;
  student_id?: Maybe<Scalars['String']>;
  student_name?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type CertificationDay = {
  __typename?: 'certificationDay';
  difference?: Maybe<Scalars['Int']>;
  therapist_discipline?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

export type DeleteUserListDistrictsInput = {
  district_id: Scalars['Int'];
};

export type IepsCount = {
  __typename?: 'iepsCount';
  students?: Maybe<Scalars['Int']>;
};

export type IepsDue = {
  __typename?: 'iepsDue';
  student_name?: Maybe<Scalars['String']>;
};

export type NewDistrictDashboard = {
  __typename?: 'newDistrictDashboard';
  IncompleteSession?: Maybe<Scalars['Int']>;
  sessions?: Maybe<Scalars['Int']>;
  student_count?: Maybe<Scalars['Int']>;
  therapistcount?: Maybe<Scalars['Int']>;
};

export type ProgressBar = {
  __typename?: 'progressBar';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Int']>;
  r?: Maybe<Scalars['Int']>;
  rate_order?: Maybe<Scalars['Int']>;
};

export type ProviderCaseload = {
  __typename?: 'providerCaseload';
  caseload?: Maybe<Scalars['Int']>;
  providers?: Maybe<Scalars['String']>;
};

export type StudentGrade = {
  __typename?: 'studentGrade';
  grade: Scalars['String'];
  id: Scalars['Int'];
};

export type StudentListSession = {
  __typename?: 'studentListSession';
  birth_date?: Maybe<Scalars['Date']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone_number?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  district?: Maybe<District>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  icdCode?: Maybe<IcdCode>;
  id: Scalars['Int'];
  iep_date?: Maybe<Scalars['Date']>;
  insurance?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent1_email?: Maybe<Scalars['String']>;
  parent1_phone_number?: Maybe<Scalars['String']>;
  parent2_email?: Maybe<Scalars['String']>;
  parent2_phone_number?: Maybe<Scalars['String']>;
  parent_name?: Maybe<Scalars['String']>;
  primaryLanguage?: Maybe<PrimaryLanguage>;
  re_evl_date?: Maybe<Scalars['Date']>;
  school?: Maybe<School>;
  status?: Maybe<StudentStatus>;
  student_id?: Maybe<Scalars['String']>;
  unique_profile_id?: Maybe<Scalars['String']>;
};

export type StudentSessions = {
  __typename?: 'studentSessions';
  count?: Maybe<Scalars['Int']>;
};

export type StudentsAbsenses = {
  __typename?: 'studentsAbsenses';
  absences?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
};

export type TherapistAbsences = {
  __typename?: 'therapistAbsences';
  absences?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type TherapistAvailabilityInput = {
  created_by?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_availability_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type TherapistAvailabilityView = {
  __typename?: 'therapistAvailabilityView';
  created_by?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  slot_date?: Maybe<Scalars['Date']>;
  start_time?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_availability_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type TherapistCaseload = {
  __typename?: 'therapistCaseload';
  caseload_count?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type TherapistIep = {
  __typename?: 'therapistIep';
  student_name?: Maybe<Scalars['String']>;
};

export type TherapistMinutes = {
  __typename?: 'therapistMinutes';
  created_at?: Maybe<Scalars['DateTime']>;
  minutes?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

export type UnDocumentedSession = {
  __typename?: 'unDocumentedSession';
  unDocumentedSession?: Maybe<Scalars['Int']>;
};

export type UpdateAddStudentPermissionForUserDistrictsInput = {
  add_student_permission?: Maybe<Scalars['Boolean']>;
  alt_id?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  district_id: Scalars['Int'];
  hourly_rate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type UpdatePrimaryUserDistrictsInput = {
  add_student_permission?: Maybe<Scalars['Boolean']>;
  alt_id?: Maybe<Scalars['String']>;
  hourly_rate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type UserAvailabilityInput = {
  created_by?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['DateTime']>;
  slot_date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Scalars['String']>;
  user_availability_id?: Maybe<Scalars['Int']>;
  week_day?: Maybe<Scalars['Int']>;
};

export type UserAvailableSp = {
  __typename?: 'userAvailableSp';
  booked?: Maybe<Scalars['Boolean']>;
  dayname?: Maybe<Scalars['String']>;
  rid?: Maybe<Scalars['Int']>;
  time_slot?: Maybe<Scalars['String']>;
  timeperiod?: Maybe<Scalars['DateTime']>;
  user_availability_id?: Maybe<Scalars['Int']>;
  week_day?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
};

export type ChatCredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatCredentialsQuery = (
  { __typename?: 'Query' }
  & { chatCredentials: (
    { __typename?: 'ChatCredentials' }
    & Pick<ChatCredentials, 'authToken' | 'userId'>
  ) }
);

export type CreateChatGroupMutationVariables = Exact<{
  chatAuthToken: Scalars['String'];
  name: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
}>;


export type CreateChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { createChatGroup: (
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'id' | 'name' | 'messagesCount' | 'usersCount'>
  ) }
);

export type InviteToChatGroupMutationVariables = Exact<{
  chatAuthToken: Scalars['String'];
  groupId: Scalars['String'];
  emails: Array<Scalars['String']>;
}>;


export type InviteToChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { inviteToChatGroup: (
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'id' | 'name' | 'messagesCount' | 'usersCount'>
  ) }
);

export type ListChatGroupMembersQueryVariables = Exact<{
  chatAuthToken: Scalars['String'];
  groupId: Scalars['String'];
}>;


export type ListChatGroupMembersQuery = (
  { __typename?: 'Query' }
  & { listChatGroupMembers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'last_activity' | 'name' | 'roles'>
  )>> }
);

export type ListChatGroupsQueryVariables = Exact<{
  chatAuthToken: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type ListChatGroupsQuery = (
  { __typename?: 'Query' }
  & { listChatGroups?: Maybe<Array<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'id' | 'name' | 'messagesCount' | 'usersCount'>
  )>> }
);

export type ListInstantMessagingQueryVariables = Exact<{
  chatAuthToken: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type ListInstantMessagingQuery = (
  { __typename?: 'Query' }
  & { listInstantMessaging?: Maybe<Array<(
    { __typename?: 'ChatInstantMessaging' }
    & Pick<ChatInstantMessaging, 'id' | 'messagesCount'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'name'>
    ) }
  )>> }
);

export type ListChatUsersQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;


export type ListChatUsersQuery = (
  { __typename?: 'Query' }
  & { listChatUsers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'last_activity' | 'name' | 'roles'>
  )>> }
);

export type ConfirmForgetPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ConfirmForgetPasswordInput;
}>;


export type ConfirmForgetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmForgetPassword'>
);

export type ForgetPasswordMutationVariables = Exact<{
  input: ForgetPasswordInput;
}>;


export type ForgetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgetPassword'>
);

export type LoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & { login: (
    { __typename?: 'AuthenticationTokens' }
    & Pick<AuthenticationTokens, 'id' | 'access'>
  ) }
);

export type CreateCaseloadMutationVariables = Exact<{
  input: CaseloadInput;
}>;


export type CreateCaseloadMutation = (
  { __typename?: 'Mutation' }
  & { createCaseload: (
    { __typename?: 'Caseload' }
    & Pick<Caseload, 'id'>
  ) }
);

export type IcdCodesQueryVariables = Exact<{
  input?: Maybe<IcdInputQuery>;
}>;


export type IcdCodesQuery = (
  { __typename?: 'Query' }
  & { icdCodes?: Maybe<Array<(
    { __typename?: 'IcdCode' }
    & Pick<IcdCode, 'code' | 'therapist_discipline'>
  )>> }
);

export type CaseStudentsQueryVariables = Exact<{
  district_id: Scalars['ID'];
  user_id: Scalars['String'];
}>;


export type CaseStudentsQuery = (
  { __typename?: 'Query' }
  & { caseStudents?: Maybe<Array<(
    { __typename?: 'caseStudent' }
    & Pick<CaseStudent, 'id' | 'student_name' | 'districtname' | 'birth_date'>
  )>> }
);

export type CaseloadTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type CaseloadTypeQuery = (
  { __typename?: 'Query' }
  & { caseloadType?: Maybe<Array<(
    { __typename?: 'CaseloadType' }
    & Pick<CaseloadType, 'id' | 'name'>
  )>> }
);

export type GetDisciplinesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDisciplinesQuery = (
  { __typename?: 'Query' }
  & { GCMS_disciplines: Array<(
    { __typename?: 'CmsDiscipline' }
    & Pick<CmsDiscipline, 'id' | 'name'>
  )> }
);

export type DistrictSelectQueryVariables = Exact<{
  input?: Maybe<DistrictsQueryInput>;
}>;


export type DistrictSelectQuery = (
  { __typename?: 'Query' }
  & { districts?: Maybe<Array<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'name' | 'status'>
  )>> }
);

export type GoalSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type GoalSelectQuery = (
  { __typename?: 'Query' }
  & { goalTypes?: Maybe<Array<(
    { __typename?: 'GoalTypes' }
    & Pick<GoalTypes, 'id' | 'goal_type'>
  )>> }
);

export type GradeQueryVariables = Exact<{ [key: string]: never; }>;


export type GradeQuery = (
  { __typename?: 'Query' }
  & { grade?: Maybe<Array<(
    { __typename?: 'Grade' }
    & Pick<Grade, 'id' | 'grade'>
  )>> }
);

export type IcdCodeSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type IcdCodeSelectQuery = (
  { __typename?: 'Query' }
  & { icdCodes?: Maybe<Array<(
    { __typename?: 'IcdCode' }
    & Pick<IcdCode, 'id' | 'code' | 'status' | 'name'>
  )>> }
);

export type AgesServedSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type AgesServedSelectQuery = (
  { __typename?: 'Query' }
  & { agesServed?: Maybe<Array<(
    { __typename?: 'AgesServed' }
    & Pick<AgesServed, 'id' | 'name' | 'status'>
  )>> }
);

export type EthnicGroupSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type EthnicGroupSelectQuery = (
  { __typename?: 'Query' }
  & { ethnicGroup?: Maybe<Array<(
    { __typename?: 'EthnicGroup' }
    & Pick<EthnicGroup, 'id' | 'name' | 'status'>
  )>> }
);

export type CountiesSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type CountiesSelectQuery = (
  { __typename?: 'Query' }
  & { counties?: Maybe<Array<(
    { __typename?: 'Counties' }
    & Pick<Counties, 'id' | 'name' | 'status'>
  )>> }
);

export type InsuranceSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type InsuranceSelectQuery = (
  { __typename?: 'Query' }
  & { insurance?: Maybe<Array<(
    { __typename?: 'Insurance' }
    & Pick<Insurance, 'id' | 'name' | 'status'>
  )>> }
);

export type SpecialitiesSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecialitiesSelectQuery = (
  { __typename?: 'Query' }
  & { specialities?: Maybe<Array<(
    { __typename?: 'Specialities' }
    & Pick<Specialities, 'id' | 'status' | 'name'>
  )>> }
);

export type TherapistDesciplineSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type TherapistDesciplineSelectQuery = (
  { __typename?: 'Query' }
  & { therapistDescipline?: Maybe<Array<(
    { __typename?: 'TherapistDescipline' }
    & Pick<TherapistDescipline, 'id' | 'name' | 'status'>
  )>> }
);

export type SignalServicesSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type SignalServicesSelectQuery = (
  { __typename?: 'Query' }
  & { signalServices?: Maybe<Array<(
    { __typename?: 'SignalServices' }
    & Pick<SignalServices, 'id' | 'name' | 'status'>
  )>> }
);

export type PrimaryLanguageQueryVariables = Exact<{ [key: string]: never; }>;


export type PrimaryLanguageQuery = (
  { __typename?: 'Query' }
  & { primaryLanguage?: Maybe<Array<(
    { __typename?: 'PrimaryLanguage' }
    & Pick<PrimaryLanguage, 'id' | 'name'>
  )>> }
);

export type AgencyListQueryVariables = Exact<{ [key: string]: never; }>;


export type AgencyListQuery = (
  { __typename?: 'Query' }
  & { agencyList?: Maybe<Array<(
    { __typename?: 'Agency' }
    & Pick<Agency, 'id' | 'name'>
  )>> }
);

export type ExperienceListQueryVariables = Exact<{ [key: string]: never; }>;


export type ExperienceListQuery = (
  { __typename?: 'Query' }
  & { signalExperience?: Maybe<Array<(
    { __typename?: 'SignalExperience' }
    & Pick<SignalExperience, 'id' | 'experience'>
  )>> }
);

export type SchoolSelectQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type SchoolSelectQuery = (
  { __typename?: 'Query' }
  & { schools?: Maybe<Array<(
    { __typename?: 'School' }
    & Pick<School, 'id' | 'name' | 'status'>
  )>> }
);

export type ServiceSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceSelectQuery = (
  { __typename?: 'Query' }
  & { services?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'code' | 'status' | 'is_direct' | 'is_indirect' | 'is_evaluation' | 'is_support' | 'is_therapy' | 'therapist_discipline'>
  )>> }
);

export type StudentSelectQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type StudentSelectQuery = (
  { __typename?: 'Query' }
  & { students?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name'>
  )>> }
);

export type StudentGradesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StudentGradesQuery = (
  { __typename?: 'Query' }
  & { studentGrades?: Maybe<Array<(
    { __typename?: 'studentGrade' }
    & Pick<StudentGrade, 'id' | 'grade'>
  )>> }
);

export type TrackerSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type TrackerSelectQuery = (
  { __typename?: 'Query' }
  & { trackerTypes?: Maybe<Array<(
    { __typename?: 'TrackerTypes' }
    & Pick<TrackerTypes, 'id' | 'name'>
  )>> }
);

export type UserListDistrictsSelectQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type UserListDistrictsSelectQuery = (
  { __typename?: 'Query' }
  & { userListDistricts?: Maybe<Array<(
    { __typename?: 'UserDistrictQueryInput' }
    & Pick<UserDistrictQueryInput, 'user_id' | 'district_id' | 'name' | 'alt_id' | 'hourly_rate' | 'add_student_permission' | 'status'>
  )>> }
);

export type UserSelectQueryVariables = Exact<{
  input?: Maybe<UsersQueryInput>;
}>;


export type UserSelectQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>> }
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReport'>
);

export type MyGeneratedReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyGeneratedReportsQuery = (
  { __typename?: 'Query' }
  & { myGeneratedReports?: Maybe<Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'createdAt' | 'fileName' | 'id' | 'name'>
  )>> }
);

export type GetAdminUserQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetAdminUserQuery = (
  { __typename?: 'Query' }
  & { getAdminUser: (
    { __typename?: 'GetAdminUser' }
    & Pick<GetAdminUser, 'UserStatus' | 'Username' | 'UserCreateDate' | 'UserLastModifiedDate' | 'NumberOfDays' | 'IsPasswordExpaired'>
    & { UserAttributes?: Maybe<Array<(
      { __typename?: 'UserAttributes' }
      & Pick<UserAttributes, 'Name' | 'Value'>
    )>> }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ChangePassword;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'roles' | 'last_activity' | 'therapist_discipline' | 'street_address'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )>, signalAgencies?: Maybe<Array<(
      { __typename?: 'Agency' }
      & Pick<Agency, 'id' | 'name'>
    )>> }
  ) }
);

export type AdminDashboardMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDashboardMetricsQuery = (
  { __typename?: 'Query' }
  & { metrics?: Maybe<(
    { __typename?: 'Metric' }
    & Pick<Metric, 'totalStudentDistrict' | 'totalSession' | 'totalStudentAbsenses' | 'totalDue'>
  )> }
);

export type NewDistrictDashboardsQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type NewDistrictDashboardsQuery = (
  { __typename?: 'Query' }
  & { newDistrictDashboards?: Maybe<Array<(
    { __typename?: 'newDistrictDashboard' }
    & Pick<NewDistrictDashboard, 'student_count' | 'therapistcount' | 'sessions' | 'IncompleteSession'>
  )>> }
);

export type IepsDuesQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
}>;


export type IepsDuesQuery = (
  { __typename?: 'Query' }
  & { iepsDues?: Maybe<Array<(
    { __typename?: 'iepsDue' }
    & Pick<IepsDue, 'student_name'>
  )>> }
);

export type StudentAbsensesQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type StudentAbsensesQuery = (
  { __typename?: 'Query' }
  & { studentAbsenses?: Maybe<Array<(
    { __typename?: 'studentsAbsenses' }
    & Pick<StudentsAbsenses, 'name'>
  )>> }
);

export type ProviderCaseloadsQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type ProviderCaseloadsQuery = (
  { __typename?: 'Query' }
  & { providerCaseloads?: Maybe<Array<(
    { __typename?: 'providerCaseload' }
    & Pick<ProviderCaseload, 'providers' | 'caseload'>
  )>> }
);

export type CertificationDaysQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
}>;


export type CertificationDaysQuery = (
  { __typename?: 'Query' }
  & { certificationDays?: Maybe<Array<(
    { __typename?: 'certificationDay' }
    & Pick<CertificationDay, 'user_name' | 'therapist_discipline' | 'difference'>
  )>> }
);

export type ProgressBarsQueryVariables = Exact<{
  district_id?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type ProgressBarsQuery = (
  { __typename?: 'Query' }
  & { progressBars?: Maybe<Array<(
    { __typename?: 'progressBar' }
    & Pick<ProgressBar, 'name' | 'percentage'>
  )>> }
);

export type AdminDocumentedSessionQueryVariables = Exact<{
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
}>;


export type AdminDocumentedSessionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocumentedSession'>
);

export type SessionDeliveryQueryVariables = Exact<{
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
}>;


export type SessionDeliveryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ServiceSession'>
);

export type AppointmentStatusQueryVariables = Exact<{
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
}>;


export type AppointmentStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Sessionstatus'>
);

export type WaitlistAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type WaitlistAssignmentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'wailistassignment'>
);

export type OngoingcareAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type OngoingcareAssignmentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ongoingcarecount'>
);

export type MandatoryDiscluserDelQueryVariables = Exact<{ [key: string]: never; }>;


export type MandatoryDiscluserDelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Mandatorystatus'>
);

export type AdminCountsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type AdminCountsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'adimncount'>
);

export type ActivityfeedQueryVariables = Exact<{
  roles?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['String']>;
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
}>;


export type ActivityfeedQuery = (
  { __typename?: 'Query' }
  & { Activityfeed?: Maybe<Array<(
    { __typename?: 'Activityfeed' }
    & Pick<Activityfeed, 'id' | 'notes' | 'roles' | 'users' | 'created_by' | 'updated_by' | 'hours'>
  )>> }
);

export type TherapistMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type TherapistMetricsQuery = (
  { __typename?: 'Query' }
  & { metrics?: Maybe<(
    { __typename?: 'Metric' }
    & Pick<Metric, 'undocumentedAppointments' | 'assignedEvaluations' | 'assignedScreenings' | 'numberOfCaseloads' | 'nextDueIep'>
    & { timeOwed?: Maybe<(
      { __typename?: 'TimeOwed' }
      & Pick<TimeOwed, 'minutes' | 'studentsCount'>
    )> }
  )> }
);

export type TherapistIepsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type TherapistIepsQuery = (
  { __typename?: 'Query' }
  & { therapistIeps?: Maybe<Array<(
    { __typename?: 'therapistIep' }
    & Pick<TherapistIep, 'student_name'>
  )>> }
);

export type TherapistCaseloadsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type TherapistCaseloadsQuery = (
  { __typename?: 'Query' }
  & { therapistCaseloads?: Maybe<Array<(
    { __typename?: 'therapistCaseload' }
    & Pick<TherapistCaseload, 'caseload_count'>
  )>> }
);

export type TherapistminutesQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type TherapistminutesQuery = (
  { __typename?: 'Query' }
  & { therapistminutes?: Maybe<Array<(
    { __typename?: 'therapistMinutes' }
    & Pick<TherapistMinutes, 'minutes'>
  )>> }
);

export type TherapistabsencesQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type TherapistabsencesQuery = (
  { __typename?: 'Query' }
  & { therapistabsences?: Maybe<Array<(
    { __typename?: 'therapistAbsences' }
    & Pick<TherapistAbsences, 'name'>
  )>> }
);

export type UnDocumentedSessionsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type UnDocumentedSessionsQuery = (
  { __typename?: 'Query' }
  & { unDocumentedSessions?: Maybe<Array<(
    { __typename?: 'unDocumentedSession' }
    & Pick<UnDocumentedSession, 'unDocumentedSession'>
  )>> }
);

export type TherapistNewMetricsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type TherapistNewMetricsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Datacount'>
);

export type DashboardRequestsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type DashboardRequestsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ongoingrequest'>
);

export type SlotsAvailableQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type SlotsAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Availableslots'>
);

export type StartInstantMessagingMutationVariables = Exact<{
  chatAuthToken: Scalars['String'];
  email: Scalars['String'];
}>;


export type StartInstantMessagingMutation = (
  { __typename?: 'Mutation' }
  & { startInstantMessaging: (
    { __typename?: 'ChatInstantMessaging' }
    & Pick<ChatInstantMessaging, 'id' | 'messagesCount'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'name'>
    ) }
  ) }
);

export type CreateAgencyMutationVariables = Exact<{
  input: AgencyInput;
}>;


export type CreateAgencyMutation = (
  { __typename?: 'Mutation' }
  & { createAgency: (
    { __typename?: 'AgencyMessage' }
    & Pick<AgencyMessage, 'info'>
  ) }
);

export type ListAgencyQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAgencyQuery = (
  { __typename?: 'Query' }
  & { agencyList?: Maybe<Array<(
    { __typename?: 'Agency' }
    & Pick<Agency, 'id' | 'name' | 'address' | 'status' | 'city' | 'state' | 'zipcode' | 'restriction'>
  )>> }
);

export type DeleteAgencyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAgencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAgency'>
);

export type UpdateAgencyMutationVariables = Exact<{
  id: Scalars['Int'];
  input: AgencyInput;
}>;


export type UpdateAgencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAgency'>
);

export type AgencyQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type AgencyQuery = (
  { __typename?: 'Query' }
  & { agency?: Maybe<Array<(
    { __typename?: 'Agency' }
    & Pick<Agency, 'name' | 'address' | 'city' | 'state' | 'zipcode' | 'restriction'>
  )>> }
);

export type AppointmentsQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type AppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'provider_name' | 'student_name' | 'student_district' | 'started_at' | 'ended_at' | 'icd_code'>
  )>> }
);

export type ListBlackoutDayQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type ListBlackoutDayQuery = (
  { __typename?: 'Query' }
  & { blackoutDay?: Maybe<Array<(
    { __typename?: 'BlackoutDays' }
    & Pick<BlackoutDays, 'id' | 'title' | 'start_date' | 'end_date' | 'day_portion'>
  )>> }
);

export type CreateBlackoutDaysMutationVariables = Exact<{
  input: BlackoutDaysInput;
}>;


export type CreateBlackoutDaysMutation = (
  { __typename?: 'Mutation' }
  & { createBlackoutDays: (
    { __typename?: 'BlackoutDays' }
    & Pick<BlackoutDays, 'title' | 'start_date' | 'end_date' | 'created_by' | 'updated_by' | 'day_portion'>
    & { appointment_type_id: (
      { __typename?: 'AppointmentTypes' }
      & Pick<AppointmentTypes, 'id'>
    ), user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), district_id: (
      { __typename?: 'District' }
      & Pick<District, 'id'>
    ), service_id: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
    ) }
  ) }
);

export type DeleteBlackoutDaysMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBlackoutDaysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlackoutDays'>
);

export type ListBlackoutDaysQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  appointment_type_id?: Maybe<Scalars['ID']>;
}>;


export type ListBlackoutDaysQuery = (
  { __typename?: 'Query' }
  & { blackoutDays?: Maybe<Array<(
    { __typename?: 'BlackoutDays' }
    & Pick<BlackoutDays, 'id' | 'title' | 'start_date' | 'end_date' | 'created_by' | 'updated_by' | 'day_portion'>
    & { appointment_type_id: (
      { __typename?: 'AppointmentTypes' }
      & Pick<AppointmentTypes, 'id'>
    ), user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), district_id: (
      { __typename?: 'District' }
      & Pick<District, 'id'>
    ), service_id: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
    ) }
  )>> }
);

export type UpdateBlackoutDaysMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BlackoutDaysInput;
}>;


export type UpdateBlackoutDaysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBlackoutDays'>
);

export type ListUserDistrictsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  district_id?: Maybe<Scalars['Int']>;
}>;


export type ListUserDistrictsQuery = (
  { __typename?: 'Query' }
  & { userDistricts?: Maybe<Array<(
    { __typename?: 'UserDistrict' }
    & Pick<UserDistrict, 'primary' | 'district_id' | 'alt_id' | 'hourly_rate' | 'status' | 'add_student_permission'>
    & { user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'description' | 'first_name' | 'last_name' | 'gender' | 'middle_initial' | 'maiden_name' | 'roles' | 'city' | 'zip' | 'status' | 'telemedicine_enabled' | 'telemedicine_trained_at' | 'mobile_phone' | 'home_phone' | 'therapist_discipline' | 'street_address' | 'license_number' | 'cert_date' | 'cert_expire_date' | 'degree_held' | 'school_attended' | 'specialized_training' | 'supervisor_name_and_credentials'>
      & { district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'id' | 'name'>
      )>, newUserAgeGroups?: Maybe<(
        { __typename?: 'NewUserToAges' }
        & Pick<NewUserToAges, 'id' | 'user_id' | 'min_age' | 'max_age' | 'min_age_list' | 'max_age_list'>
      )>, student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
      )>, state_license?: Maybe<(
        { __typename?: 'State' }
        & Pick<State, 'id' | 'name' | 'abbreviation'>
      )>, signal_service_id?: Maybe<(
        { __typename?: 'SignalServices' }
        & Pick<SignalServices, 'id' | 'name'>
      )>, ethinic_group_id?: Maybe<(
        { __typename?: 'EthnicGroup' }
        & Pick<EthnicGroup, 'name'>
      )>, signalUserExperience?: Maybe<(
        { __typename?: 'SignalExperience' }
        & Pick<SignalExperience, 'experience'>
      )> }
    ) }
  )>> }
);

export type UserListDistrictsForCaseloadQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type UserListDistrictsForCaseloadQuery = (
  { __typename?: 'Query' }
  & { userListDistrictsForCaseload?: Maybe<Array<(
    { __typename?: 'UserDistrictQueryInput' }
    & Pick<UserDistrictQueryInput, 'district_id' | 'alt_id' | 'hourly_rate' | 'status' | 'add_student_permission' | 'primary'>
  )>> }
);

export type ListCaseloadQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListCaseloadQuery = (
  { __typename?: 'Query' }
  & { caseload?: Maybe<Array<(
    { __typename?: 'Caseload' }
    & Pick<Caseload, 'id' | 'iep_minutes' | 'minutes_per' | 'frequency' | 'updated_by' | 'billing_code' | 'planned_sessions' | 'notes' | 'status' | 'private_notes' | 'sessions_count'>
    & { user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name'>
    ), students: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'unique_profile_id' | 'contact_email' | 're_evl_date' | 'birth_date' | 'contact_phone_number' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email'>
      & { district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'name'>
      )>, primaryLanguage?: Maybe<(
        { __typename?: 'PrimaryLanguage' }
        & Pick<PrimaryLanguage, 'name'>
      )>, insurance_company?: Maybe<(
        { __typename?: 'Insurance' }
        & Pick<Insurance, 'name'>
      )>, student_specialities?: Maybe<Array<Maybe<(
        { __typename?: 'StudentSpecialties' }
        & { speciality_id?: Maybe<(
          { __typename?: 'Specialities' }
          & Pick<Specialities, 'id' | 'name'>
        )> }
      )>>> }
    ) }
  )>> }
);

export type RemoveClientMutationVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
}>;


export type RemoveClientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeClient'>
);

export type AddClientMutationVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
}>;


export type AddClientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addClient'>
);

export type DeleteCaseloadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCaseloadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCaseload'>
);

export type ListStudentCaseloadQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ListStudentCaseloadQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'iep_date' | 're_evl_date' | 'status' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email' | 'accommodations' | 'parent_name' | 'psc_score' | 'sdoh_score' | 'craft_score'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, icdCode?: Maybe<(
      { __typename?: 'IcdCode' }
      & Pick<IcdCode, 'id' | 'code'>
    )>, grade?: Maybe<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'id' | 'grade'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )> }
  ) }
);

export type ListsurveystudentQueryVariables = Exact<{
  sid?: Maybe<Scalars['Int']>;
}>;


export type ListsurveystudentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'surveystudent'>
);

export type ClientFillingAttemptsMutationVariables = Exact<{
  input: ClientAttemptsInput;
}>;


export type ClientFillingAttemptsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clientFillingAttempts'>
);

export type MandatoryDetailsListQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
}>;


export type MandatoryDetailsListQuery = (
  { __typename?: 'Query' }
  & { mandatoryDetailsList?: Maybe<Array<(
    { __typename?: 'MandatoryFormDetails' }
    & Pick<MandatoryFormDetails, 'id' | 'comment' | 'licenses_and_certifications' | 'degreesheld_and_schoolattended' | 'clinician_phone' | 'specializedtraining_and_areasofclinicalcompetence' | 'supervisorname_and_credentials' | 'user_sign' | 'student_sign' | 'user_date' | 'student_date' | 'school_attended'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'street_address' | 'license_number' | 'cert_date'>
      & { signalAgencies?: Maybe<Array<(
        { __typename?: 'Agency' }
        & Pick<Agency, 'id' | 'name'>
      )>> }
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
      & { primaryLanguage?: Maybe<(
        { __typename?: 'PrimaryLanguage' }
        & Pick<PrimaryLanguage, 'id' | 'name'>
      )> }
    )>, status_id?: Maybe<(
      { __typename?: 'MandatoryFormStatus' }
      & Pick<MandatoryFormStatus, 'id' | 'name'>
    )>, relationship_to_client_id?: Maybe<(
      { __typename?: 'RelationshipToClient' }
      & Pick<RelationshipToClient, 'id' | 'name' | 'spanish_name'>
    )>, session_id?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'id'>
    )> }
  )>> }
);

export type UpdateMandatoryFormMutationVariables = Exact<{
  id: Scalars['String'];
  input: MandatoryFormDetailsInput;
}>;


export type UpdateMandatoryFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMandatoryForm'>
);

export type RelationshipToClientQueryVariables = Exact<{ [key: string]: never; }>;


export type RelationshipToClientQuery = (
  { __typename?: 'Query' }
  & { relationshipToClient?: Maybe<Array<(
    { __typename?: 'RelationshipToClient' }
    & Pick<RelationshipToClient, 'id' | 'name' | 'spanish_name' | 'status'>
  )>> }
);

export type MandatoryInviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type MandatoryInviteQuery = (
  { __typename?: 'Query' }
  & { mandatoryInvite?: Maybe<(
    { __typename?: 'MandatoryFormDetails' }
    & Pick<MandatoryFormDetails, 'id' | 'comment' | 'licenses_and_certifications' | 'degreesheld_and_schoolattended' | 'clinician_phone' | 'specializedtraining_and_areasofclinicalcompetence' | 'supervisorname_and_credentials' | 'user_sign' | 'student_sign' | 'user_date' | 'student_date' | 'school_attended'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'street_address' | 'license_number' | 'cert_date'>
      & { signalAgencies?: Maybe<Array<(
        { __typename?: 'Agency' }
        & Pick<Agency, 'name'>
      )>> }
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )>, status_id?: Maybe<(
      { __typename?: 'MandatoryFormStatus' }
      & Pick<MandatoryFormStatus, 'id' | 'name'>
    )>, relationship_to_client_id?: Maybe<(
      { __typename?: 'RelationshipToClient' }
      & Pick<RelationshipToClient, 'id' | 'name'>
    )>, session_id?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'id'>
    )> }
  )> }
);

export type UpdateMandatoryDataMutationVariables = Exact<{
  id: Scalars['String'];
  input: MandatoryFormDetailsInput;
}>;


export type UpdateMandatoryDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMandatoryData'>
);

export type AddMandatoryDetailsMutationVariables = Exact<{
  input: MandatoryFormDetailsInput;
  resend?: Maybe<Scalars['Boolean']>;
}>;


export type AddMandatoryDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMandatoryDetails'>
);

export type CreateDistrictMutationVariables = Exact<{
  input: DistrictInput;
}>;


export type CreateDistrictMutation = (
  { __typename?: 'Mutation' }
  & { createDistrict?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'id'>
  )> }
);

export type StatesQueryVariables = Exact<{ [key: string]: never; }>;


export type StatesQuery = (
  { __typename?: 'Query' }
  & { states?: Maybe<Array<(
    { __typename?: 'State' }
    & Pick<State, 'id' | 'name' | 'abbreviation'>
  )>> }
);

export type DeleteDistrictMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDistrictMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDistrict'>
);

export type DistrictsQueryVariables = Exact<{ [key: string]: never; }>;


export type DistrictsQuery = (
  { __typename?: 'Query' }
  & { districts?: Maybe<Array<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'name' | 'zip' | 'status' | 'city' | 'street_address' | 'level_of_service'>
  )>> }
);

export type DistrictQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DistrictQuery = (
  { __typename?: 'Query' }
  & { district?: Maybe<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'city' | 'level_of_service' | 'name' | 'status' | 'street_address' | 'zip' | 'allow_conferencing' | 'provider_pins_enabled' | 'bill_tracking_records_enabled' | 'therapy_component_enabled' | 'offer_billing_question' | 'signature_required' | 'planTypes'>
    & { state: (
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateDistrictMutationVariables = Exact<{
  id: Scalars['Int'];
  input: DistrictInput;
}>;


export type UpdateDistrictMutation = (
  { __typename?: 'Mutation' }
  & { updateDistrict?: Maybe<(
    { __typename?: 'District' }
    & { state: (
      { __typename?: 'State' }
      & Pick<State, 'id'>
    ) }
  )> }
);

export type CreateIcdCodeMutationVariables = Exact<{
  input: IcdCodeInput;
}>;


export type CreateIcdCodeMutation = (
  { __typename?: 'Mutation' }
  & { createIcdCode: (
    { __typename?: 'IcdCode' }
    & Pick<IcdCode, 'name' | 'code' | 'therapist_discipline' | 'version'>
  ) }
);

export type DeleteIcdCodeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteIcdCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIcdCode'>
);

export type ListIcdCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIcdCodesQuery = (
  { __typename?: 'Query' }
  & { icdCodes?: Maybe<Array<(
    { __typename?: 'IcdCode' }
    & Pick<IcdCode, 'id' | 'name' | 'code' | 'version' | 'therapist_discipline' | 'status'>
  )>> }
);

export type IcdCodeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IcdCodeQuery = (
  { __typename?: 'Query' }
  & { icdCode: (
    { __typename?: 'IcdCode' }
    & Pick<IcdCode, 'name' | 'code' | 'therapist_discipline' | 'version'>
  ) }
);

export type UpdateIcdCodeMutationVariables = Exact<{
  id: Scalars['Int'];
  input: IcdCodeInput;
}>;


export type UpdateIcdCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIcdCode'>
);

export type SessionsListQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SessionsListQuery = (
  { __typename?: 'Query' }
  & { sessionsList?: Maybe<Array<(
    { __typename?: 'Sessions' }
    & Pick<Sessions, 'id' | 'name' | 'started_at' | 'ended_at' | 'sign'>
    & { service_id?: Maybe<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name'>
    )>, user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, outcome_id?: Maybe<(
      { __typename?: 'OutCome' }
      & Pick<OutCome, 'id' | 'name'>
    )>, signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )>, signature_history?: Maybe<(
      { __typename?: 'SignatureHistory' }
      & Pick<SignatureHistory, 'id' | 'sign'>
    )>, requestReferral?: Maybe<Array<(
      { __typename?: 'Referral' }
      & Pick<Referral, 'id' | 'name'>
    )>>, appointment_id?: Maybe<(
      { __typename?: 'AppointmentList' }
      & Pick<AppointmentList, 'id' | 'start_date'>
      & { service_id?: Maybe<Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'name'>
      )>> }
    )>, session_students?: Maybe<Array<(
      { __typename?: 'SessionStudents' }
      & Pick<SessionStudents, 'deleted_goals' | 'session_no' | 'notes' | 'assessment' | 'present' | 'plan' | 'further_sessions' | 'further_sessions_text' | 'high_level_care' | 'high_level_care_text'>
      & { student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
        & { primaryLanguage?: Maybe<(
          { __typename?: 'PrimaryLanguage' }
          & Pick<PrimaryLanguage, 'id'>
        )> }
      )>, session_Goals?: Maybe<Array<Maybe<(
        { __typename?: 'SessionGoals' }
        & Pick<SessionGoals, 'id' | 'percentage' | 'attempts' | 'assist' | 'total' | 'parent_goal_id' | 'session_rate_id' | 'notes' | 'activity'>
        & { goal_id?: Maybe<(
          { __typename?: 'StudentGoals' }
          & Pick<StudentGoals, 'id' | 'goal_number' | 'target' | 'name' | 'description'>
        )>, session_tracker?: Maybe<Array<Maybe<(
          { __typename?: 'SessionTrackers' }
          & Pick<SessionTrackers, 'clicks' | 'activity'>
          & { tracker_id?: Maybe<(
            { __typename?: 'GoalsTracker' }
            & Pick<GoalsTracker, 'id' | 'name' | 'icon'>
          )> }
        )>>> }
      )>>> }
    )>> }
  )>> }
);

export type ServicesListQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesListQuery = (
  { __typename?: 'Query' }
  & { servicesList?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name'>
  )>> }
);

export type SessionCountQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
}>;


export type SessionCountQuery = (
  { __typename?: 'Query' }
  & { sessionCount?: Maybe<Array<Maybe<(
    { __typename?: 'SessionsCount' }
    & Pick<SessionsCount, 'count'>
  )>>> }
);

export type CreateSessionInfoQueryVariables = Exact<{
  Appointmentid: Scalars['String'];
  TherapistId: Scalars['String'];
  s_start?: Maybe<Scalars['DateTime']>;
  s_end?: Maybe<Scalars['DateTime']>;
}>;


export type CreateSessionInfoQuery = (
  { __typename?: 'Query' }
  & { createSessionInfo?: Maybe<Array<(
    { __typename?: 'SessionInfo' }
    & Pick<SessionInfo, 'getSessionId'>
  )>> }
);

export type SessionRateSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionRateSelectQuery = (
  { __typename?: 'Query' }
  & { sessionRateList?: Maybe<Array<(
    { __typename?: 'SessionRate' }
    & Pick<SessionRate, 'id' | 'name'>
  )>> }
);

export type ReferralQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferralQuery = (
  { __typename?: 'Query' }
  & { referral?: Maybe<Array<Maybe<(
    { __typename?: 'Referral' }
    & Pick<Referral, 'id' | 'name'>
  )>>> }
);

export type AddStudentToSessionMutationVariables = Exact<{
  session_id: Scalars['String'];
  therapist_id: Scalars['String'];
  student_id: Scalars['Int'];
}>;


export type AddStudentToSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addStudentToSession'>
);

export type DeleteStudentFromSessionMutationVariables = Exact<{
  session_id: Scalars['String'];
  student_id: Scalars['Int'];
}>;


export type DeleteStudentFromSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudentFromSession'>
);

export type UpdateSessionsMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type UpdateSessionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSessions'>
);

export type AdminOngoingUsersListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminOngoingUsersListQuery = (
  { __typename?: 'Query' }
  & { adminUsersList?: Maybe<Array<(
    { __typename?: 'adminUsers' }
    & Pick<AdminUsers, 'id' | 'name'>
  )>> }
);

export type InprogressInsertMutationVariables = Exact<{
  id: Scalars['String'];
  input: InprogressInput;
}>;


export type InprogressInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inprogressInsert'>
);

export type FurtherInsertMutationVariables = Exact<{
  id: Scalars['String'];
  input: FurtherInput;
}>;


export type FurtherInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'furtherInsert'>
);

export type ReferralInsertMutationVariables = Exact<{
  id: Scalars['String'];
  input: ReferralInput;
}>;


export type ReferralInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'referralInsert'>
);

export type OngoingcaresQueryVariables = Exact<{
  assigned_to?: Maybe<Scalars['String']>;
}>;


export type OngoingcaresQuery = (
  { __typename?: 'Query' }
  & { ongoingcares?: Maybe<Array<(
    { __typename?: 'OngoingCare' }
    & Pick<OngoingCare, 'id' | 'status' | 'ongoing_care' | 'further_session' | 'ongoing_care_reason' | 'further_session_reason' | 'service_requested' | 'assigned_date'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name'>
    )>, assigned_to?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, action_id?: Maybe<(
      { __typename?: 'OngoingCareActions' }
      & Pick<OngoingCareActions, 'name' | 'id'>
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'first_name' | 'last_name' | 'birth_date' | 'contact_email' | 'contact_phone_number' | 'gender' | 'unique_profile_id'>
      & { insurance_company?: Maybe<(
        { __typename?: 'Insurance' }
        & Pick<Insurance, 'id' | 'name'>
      )> }
    )> }
  )>> }
);

export type UpdateOngoingCareMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type UpdateOngoingCareMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOngoingCare'>
);

export type CreateAssignmentMutationVariables = Exact<{
  input: UserAssignmentInput;
}>;


export type CreateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createAssignment?: Maybe<(
    { __typename?: 'UserAssignment' }
    & Pick<UserAssignment, 'id'>
  )> }
);

export type MyAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAssignmentsQuery = (
  { __typename?: 'Query' }
  & { myAssignments?: Maybe<Array<(
    { __typename?: 'UserAssignment' }
    & Pick<UserAssignment, 'id'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, student: (
      { __typename?: 'Student' }
      & Pick<Student, 'name'>
    ), caseloadType?: Maybe<(
      { __typename?: 'CaseloadType' }
      & Pick<CaseloadType, 'id' | 'name'>
    )> }
  )>> }
);

export type CreateReportshistoryMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type CreateReportshistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReportshistory'>
);

export type CreateDirectIndirectReportMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type CreateDirectIndirectReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDirectIndirectReport'>
);

export type CreateProgressReportMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type CreateProgressReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProgressReport'>
);

export type CreateProgressSummaryMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type CreateProgressSummaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProgressSummary'>
);

export type CreateProviderMinutesReportMutationVariables = Exact<{
  input: Scalars['JSON'];
}>;


export type CreateProviderMinutesReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProviderMinutesReport'>
);

export type DistrictSelectFilterQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type DistrictSelectFilterQuery = (
  { __typename?: 'Query' }
  & { districtselect?: Maybe<Array<(
    { __typename?: 'District' }
    & Pick<District, 'id' | 'name' | 'status'>
  )>> }
);

export type ReportusersQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type ReportusersQuery = (
  { __typename?: 'Query' }
  & { reportusers?: Maybe<Array<(
    { __typename?: 'TherapistListForDoc' }
    & Pick<TherapistListForDoc, 'id' | 'name'>
  )>> }
);

export type ReportQueryVariables = Exact<{
  from_date?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['String']>;
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'report'>
);

export type GetUnallocatedUserSlotsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type GetUnallocatedUserSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUnallocatedUserSlots'>
);

export type GetAddSlotUsersQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type GetAddSlotUsersQuery = (
  { __typename?: 'Query' }
  & { getAddSlotUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
    & { signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )> }
  )>>> }
);

export type CreateAppointmentListMutationVariables = Exact<{
  input: AppointmentListInput;
}>;


export type CreateAppointmentListMutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentList: (
    { __typename?: 'Message' }
    & Pick<Message, 'info'>
  ) }
);

export type AppointmentsListQueryVariables = Exact<{
  user_id: Scalars['String'];
  district_id?: Maybe<Scalars['Int']>;
  appointment_type_id?: Maybe<Scalars['ID']>;
}>;


export type AppointmentsListQuery = (
  { __typename?: 'Query' }
  & { appointmentList?: Maybe<Array<(
    { __typename?: 'AppointmentList' }
    & Pick<AppointmentList, 'id' | 'note' | 'isreccuring' | 'created_by' | 'updated_by' | 'appointment_slot_id'>
    & { StartTime: AppointmentList['start_date'], EndTime: AppointmentList['end_date'], Subject: AppointmentList['title'], RecurrenceRule: AppointmentList['recurrance_r_rule'], RecurrenceID: AppointmentList['parent_appointment_id'], RecurrenceException: AppointmentList['recurrence_exception'] }
    & { EventStudents?: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )>>, EventService?: Maybe<Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name'>
    )>>, user_id: (
      { __typename?: 'User' }
      & Pick<User, 'first_name' | 'last_name' | 'id'>
      & { district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'id'>
      )> }
    ), appointment_type_id: (
      { __typename?: 'AppointmentTypes' }
      & Pick<AppointmentTypes, 'id' | 'name'>
    ), signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )> }
  )>> }
);

export type SessionStatusQueryVariables = Exact<{
  appointment_id: Scalars['String'];
  scheduled_start: Scalars['String'];
  scheduled_end: Scalars['String'];
}>;


export type SessionStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sessionStatus'>
);

export type UpdateAppointmentListMutationVariables = Exact<{
  id: Scalars['ID'];
  caseloadInsert?: Maybe<Scalars['Boolean']>;
  input: AppointmentListInput;
}>;


export type UpdateAppointmentListMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointmentList: (
    { __typename?: 'Message' }
    & Pick<Message, 'info'>
  ) }
);

export type DeleteAppointmentListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAppointmentListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAppointmentList'>
);

export type CaseloadSelectQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type CaseloadSelectQuery = (
  { __typename?: 'Query' }
  & { caseload?: Maybe<Array<(
    { __typename?: 'Caseload' }
    & { students: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    ) }
  )>> }
);

export type AppointmentStudentsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type AppointmentStudentsQuery = (
  { __typename?: 'Query' }
  & { appointmentStudents?: Maybe<Array<(
    { __typename?: 'appointmentStudent' }
    & { id: AppointmentStudent['student_id'], name: AppointmentStudent['student_name'] }
  )>> }
);

export type DeleteAppointmentListsMutationVariables = Exact<{
  parent_appointment_id?: Maybe<Scalars['ID']>;
}>;


export type DeleteAppointmentListsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAppointmentLists'>
);

export type ListsurveytherapistQueryVariables = Exact<{
  sid?: Maybe<Scalars['Int']>;
}>;


export type ListsurveytherapistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'surveytherapist'>
);

export type ServicesQueryVariables = Exact<{
  input?: Maybe<ServiceInputQuery>;
}>;


export type ServicesQuery = (
  { __typename?: 'Query' }
  & { services?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'code' | 'therapist_discipline' | 'is_direct' | 'is_indirect' | 'is_therapy' | 'is_evaluation' | 'is_support' | 'status'>
  )>> }
);

export type CaseloadStatusQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
}>;


export type CaseloadStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'caseloadStatus'>
);

export type UserUnbookedSlotsQueryVariables = Exact<{
  tid?: Maybe<Scalars['String']>;
  ttime?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['Int']>;
}>;


export type UserUnbookedSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userAvailableSp'>
);

export type UpdateAppointmentstatusMutationVariables = Exact<{
  input?: Maybe<AppointmentstatusUpdate>;
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  appointment_slot_id?: Maybe<Scalars['String']>;
}>;


export type UpdateAppointmentstatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAppointmentstatus'>
);

export type DeleteMutationVariables = Exact<{
  input?: Maybe<AppointmentstatusUpdate>;
  user_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  appointment_slot_id?: Maybe<Scalars['String']>;
}>;


export type DeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAppointment'>
);

export type UserServiceListQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UserServiceListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userServiceList'>
);

export type RescheduleusersQueryVariables = Exact<{ [key: string]: never; }>;


export type RescheduleusersQuery = (
  { __typename?: 'Query' }
  & { rescheduleusers?: Maybe<Array<(
    { __typename?: 'TherapistListForDoc' }
    & Pick<TherapistListForDoc, 'id' | 'name'>
  )>> }
);

export type CreateWaitlistAppointmentMutationVariables = Exact<{
  input: AppointmentListInput;
}>;


export type CreateWaitlistAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createWaitlistAppointment: (
    { __typename?: 'Message' }
    & Pick<Message, 'info'>
  ) }
);

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<AppointmentServiceNoteUpdate>;
  sendMail?: Maybe<Scalars['Boolean']>;
}>;


export type CancelAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelAppointment'>
);

export type AppointmentsDataQueryVariables = Exact<{
  appointment_id?: Maybe<Scalars['String']>;
}>;


export type AppointmentsDataQuery = (
  { __typename?: 'Query' }
  & { appointmentsData?: Maybe<Array<Maybe<(
    { __typename?: 'AppointmentList' }
    & Pick<AppointmentList, 'id' | 'link'>
    & { user_id: (
      { __typename?: 'User' }
      & Pick<User, 'street_address'>
      & { signalAgencies?: Maybe<Array<(
        { __typename?: 'Agency' }
        & Pick<Agency, 'name' | 'address' | 'city' | 'state' | 'zipcode'>
      )>> }
    ), signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'name'>
    )> }
  )>>> }
);

export type ResendAppointmentEmailQueryVariables = Exact<{
  appointment_id?: Maybe<Scalars['String']>;
}>;


export type ResendAppointmentEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'resendAppointmentEmail'>
);

export type UpdateAppointmentServicesMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<AppointmentServiceNoteUpdate>;
}>;


export type UpdateAppointmentServicesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAppointmentServices'>
);

export type ClientFilterQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type ClientFilterQuery = (
  { __typename?: 'Query' }
  & { clientFilter?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name'>
    & { county?: Maybe<(
      { __typename?: 'Counties' }
      & Pick<Counties, 'id'>
    )>, session_service?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )> }
  )>> }
);

export type AddAppointmetnsListQueryVariables = Exact<{
  filter?: Maybe<UserFilterInputs>;
  pagination?: Maybe<PaginationInput>;
}>;


export type AddAppointmetnsListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'addAppointmetnsList'>
);

export type CreateSchoolMutationVariables = Exact<{
  input: SchoolInput;
}>;


export type CreateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createSchool: (
    { __typename?: 'School' }
    & Pick<School, 'id'>
  ) }
);

export type DeleteSchoolMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSchoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSchool'>
);

export type SchoolsQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type SchoolsQuery = (
  { __typename?: 'Query' }
  & { schools?: Maybe<Array<(
    { __typename?: 'School' }
    & Pick<School, 'id' | 'city' | 'location_code' | 'name' | 'status' | 'street_address' | 'zip'>
    & { district: (
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'zip' | 'city' | 'street_address' | 'level_of_service'>
    ), state: (
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'abbreviation'>
    ) }
  )>> }
);

export type SchoolQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SchoolQuery = (
  { __typename?: 'Query' }
  & { school: (
    { __typename?: 'School' }
    & Pick<School, 'id' | 'city' | 'location_code' | 'name' | 'status' | 'street_address' | 'zip'>
    & { district: (
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name' | 'zip' | 'city' | 'street_address' | 'level_of_service'>
    ), state: (
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'abbreviation'>
    ) }
  ) }
);

export type UpdateSchoolMutationVariables = Exact<{
  id: Scalars['Int'];
  input: SchoolInput;
}>;


export type UpdateSchoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSchool'>
);

export type AllServiceRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllServiceRequestsQuery = (
  { __typename?: 'Query' }
  & { serviceRequests?: Maybe<Array<(
    { __typename?: 'ServiceRequest' }
    & Pick<ServiceRequest, 'id' | 'createdAt' | 'fileUrl' | 'type'>
    & { requester: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ), student: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    ) }
  )>> }
);

export type CreateServiceRequestMutationVariables = Exact<{
  input: CreateServiceRequestInput;
}>;


export type CreateServiceRequestMutation = (
  { __typename?: 'Mutation' }
  & { createServiceRequest: (
    { __typename?: 'ServiceRequest' }
    & Pick<ServiceRequest, 'id'>
  ) }
);

export type MyServiceRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyServiceRequestsQuery = (
  { __typename?: 'Query' }
  & { myServiceRequests?: Maybe<Array<(
    { __typename?: 'ServiceRequest' }
    & Pick<ServiceRequest, 'id' | 'createdAt' | 'type'>
    & { student: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    ) }
  )>> }
);

export type CreateServiceMutationVariables = Exact<{
  input: ServiceInput;
}>;


export type CreateServiceMutation = (
  { __typename?: 'Mutation' }
  & { createService: (
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'code' | 'therapist_discipline' | 'is_direct' | 'is_indirect' | 'is_therapy' | 'is_evaluation' | 'is_support'>
  ) }
);

export type DeleteServiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteService'>
);

export type ListServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListServicesQuery = (
  { __typename?: 'Query' }
  & { services?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'code' | 'therapist_discipline' | 'is_direct' | 'is_indirect' | 'is_therapy' | 'is_evaluation' | 'is_support' | 'status'>
  )>> }
);

export type ServiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ServiceQuery = (
  { __typename?: 'Query' }
  & { service: (
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'code' | 'therapist_discipline' | 'is_direct' | 'is_indirect' | 'is_therapy' | 'is_evaluation' | 'is_support' | 'status'>
  ) }
);

export type UpdateServiceMutationVariables = Exact<{
  id: Scalars['Int'];
  input: ServiceInput;
}>;


export type UpdateServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateService'>
);

export type CreateStateMutationVariables = Exact<{
  input: StateInput;
}>;


export type CreateStateMutation = (
  { __typename?: 'Mutation' }
  & { createState: (
    { __typename?: 'State' }
    & Pick<State, 'id'>
  ) }
);

export type DeleteStateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteState'>
);

export type ListStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStatesQuery = (
  { __typename?: 'Query' }
  & { states?: Maybe<Array<(
    { __typename?: 'State' }
    & Pick<State, 'id' | 'name' | 'abbreviation' | 'medicaid_name' | 'city'>
  )>> }
);

export type StateQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StateQuery = (
  { __typename?: 'Query' }
  & { state: (
    { __typename?: 'State' }
    & Pick<State, 'name' | 'abbreviation' | 'medicaid_name' | 'ein' | 'street_address' | 'city' | 'zip' | 'contact_info'>
  ) }
);

export type UpdateStateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: StateInput;
}>;


export type UpdateStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateState'>
);

export type ActiveproviderQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type ActiveproviderQuery = (
  { __typename?: 'Query' }
  & { activeproviders?: Maybe<Array<(
    { __typename?: 'Activeprovider' }
    & Pick<Activeprovider, 'id' | 'student_id' | 'time_period' | 'minutes' | 'student_first_name' | 'student_last_name' | 'provider_first_name' | 'provider_last_name'>
  )>> }
);

export type CreateStudentMutationVariables = Exact<{
  input: StudentInput;
}>;


export type CreateStudentMutation = (
  { __typename?: 'Mutation' }
  & { createStudent: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'iep_date' | 're_evl_date' | 'status'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, icdCode?: Maybe<(
      { __typename?: 'IcdCode' }
      & Pick<IcdCode, 'id' | 'code' | 'name'>
    )>, grade?: Maybe<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'id' | 'grade'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )> }
  ) }
);

export type DeleteStudentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudent'>
);

export type StudentSessionCountQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentSessionCountQuery = (
  { __typename?: 'Query' }
  & { StudentSessionCount?: Maybe<Array<(
    { __typename?: 'studentListSession' }
    & Pick<StudentListSession, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'insurance' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'iep_date' | 're_evl_date' | 'status' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email' | 'count' | 'parent_name' | 'unique_profile_id'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, icdCode?: Maybe<(
      { __typename?: 'IcdCode' }
      & Pick<IcdCode, 'id' | 'code'>
    )>, grade?: Maybe<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'id' | 'grade'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )> }
  )>> }
);

export type ListStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStudentsQuery = (
  { __typename?: 'Query' }
  & { students?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'iep_date' | 're_evl_date' | 'status' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, icdCode?: Maybe<(
      { __typename?: 'IcdCode' }
      & Pick<IcdCode, 'id' | 'code'>
    )>, grade?: Maybe<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'id' | 'grade'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )> }
  )>> }
);

export type BulkUploadCsvMutationVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
  fileUpload: Scalars['Upload'];
}>;


export type BulkUploadCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkUploadCsv'>
);

export type StudentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StudentQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'iep_date' | 're_evl_date' | 'status' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, icdCode?: Maybe<(
      { __typename?: 'IcdCode' }
      & Pick<IcdCode, 'id' | 'code'>
    )>, grade?: Maybe<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'id' | 'grade'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateStudentMutationVariables = Exact<{
  id: Scalars['Int'];
  input: StudentInput;
}>;


export type UpdateStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStudent'>
);

export type ListActiveProviderQueryVariables = Exact<{
  student_id: Scalars['Int'];
}>;


export type ListActiveProviderQuery = (
  { __typename?: 'Query' }
  & { activeprovider?: Maybe<Array<(
    { __typename?: 'Caseload' }
    & Pick<Caseload, 'id' | 'iep_minutes' | 'minutes_per' | 'frequency' | 'billing_code'>
    & { user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'name' | 'therapist_discipline'>
    ), students: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'contact_email' | 'iep_date' | 're_evl_date' | 'birth_date' | 'contact_phone_number'>
      & { grade?: Maybe<(
        { __typename?: 'Grade' }
        & Pick<Grade, 'grade'>
      )>, school?: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'name'>
      )>, icdCode?: Maybe<(
        { __typename?: 'IcdCode' }
        & Pick<IcdCode, 'id' | 'code'>
      )> }
    ), session_dates?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'started_at' | 'ended_at'>
    )> }
  )>> }
);

export type CaseloadProvidersQueryVariables = Exact<{
  student_id: Scalars['Int'];
}>;


export type CaseloadProvidersQuery = (
  { __typename?: 'Query' }
  & { caseloadProviders?: Maybe<Array<Maybe<(
    { __typename?: 'CaseloadProviders' }
    & Pick<CaseloadProviders, 'user_id' | 'fullname' | 'therapist_discipline' | 'minutes_per' | 'frequency' | 'student_id' | 'first_session_date' | 'last_session_date'>
  )>>> }
);

export type AppointmentProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AppointmentProfileQuery = (
  { __typename?: 'Query' }
  & { appointmentProfile?: Maybe<Array<(
    { __typename?: 'ApppitmentProfile' }
    & Pick<ApppitmentProfile, 'session_date' | 'session_time' | 'provider_name' | 'service' | 'date_requested' | 'status' | 'attendance'>
  )>> }
);

export type AppointmentProfileCountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AppointmentProfileCountQuery = (
  { __typename?: 'Query' }
  & { appointmentProfileCount?: Maybe<Array<(
    { __typename?: 'ApppitmentProfileCount' }
    & Pick<ApppitmentProfileCount, 'sessioncount' | 'cancelledcount'>
  )>> }
);

export type StudentsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StudentsQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'first_name' | 'last_name' | 'contact_email' | 're_evl_date' | 'birth_date'>
  ) }
);

export type ContactAttemptsQueryVariables = Exact<{
  student_id: Scalars['Int'];
}>;


export type ContactAttemptsQuery = (
  { __typename?: 'Query' }
  & { contact_attempts?: Maybe<Array<(
    { __typename?: 'ContactAttempts' }
    & Pick<ContactAttempts, 'subject' | 'to' | 'from' | 'cc' | 'bcc' | 'sent_date' | 'client_fill'>
    & { therapistId?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, students?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )> }
  )>> }
);

export type ListCaseloadsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type ListCaseloadsQuery = (
  { __typename?: 'Query' }
  & { caseloads?: Maybe<Array<(
    { __typename?: 'Caseload' }
    & Pick<Caseload, 'id' | 'iep_minutes' | 'minutes_per' | 'frequency' | 'updated_by' | 'billing_code' | 'planned_sessions' | 'notes' | 'private_notes'>
    & { user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'therapist_discipline'>
    ), students: (
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name' | 'contact_email' | 'iep_date' | 're_evl_date' | 'birth_date' | 'contact_phone_number' | 'parent1_phone_number' | 'parent2_phone_number' | 'parent1_email' | 'parent2_email' | 'gender'>
      & { grade?: Maybe<(
        { __typename?: 'Grade' }
        & Pick<Grade, 'grade'>
      )>, school?: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'name'>
      )>, district?: Maybe<(
        { __typename?: 'District' }
        & Pick<District, 'name'>
      )>, icdCode?: Maybe<(
        { __typename?: 'IcdCode' }
        & Pick<IcdCode, 'id' | 'code'>
      )>, primaryLanguage?: Maybe<(
        { __typename?: 'PrimaryLanguage' }
        & Pick<PrimaryLanguage, 'name'>
      )> }
    ) }
  )>> }
);

export type ListStudentsProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ListStudentsProfileQuery = (
  { __typename?: 'Query' }
  & { studentsProfile?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name' | 'student_id' | 'first_name' | 'last_name' | 'contact_phone_number' | 'contact_email' | 'birth_date' | 'gender' | 'address' | 'accommodations' | 'city' | 'zip' | 'parent1_phone_number' | 'parent2_phone_number' | 'phonecheck' | 'parentphonecheck1' | 'parentphonecheck2' | 'parent1_email' | 'parent2_email' | 'parent_name' | 'parent2_name' | 'unique_profile_id'>
    & { insurance_company?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'id' | 'name'>
    )>, student_specialities?: Maybe<Array<Maybe<(
      { __typename?: 'StudentSpecialties' }
      & { speciality_id?: Maybe<(
        { __typename?: 'Specialities' }
        & Pick<Specialities, 'id' | 'name'>
      )> }
    )>>>, session_service?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )>, primaryLanguage?: Maybe<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )>, pronouns?: Maybe<(
      { __typename?: 'SignalPronouns' }
      & Pick<SignalPronouns, 'id' | 'name'>
    )>, county?: Maybe<(
      { __typename?: 'Counties' }
      & Pick<Counties, 'id' | 'name'>
    )> }
  )>> }
);

export type UpdateStudentProfileMutationVariables = Exact<{
  id: Scalars['Int'];
  input: StudentProfileInput;
}>;


export type UpdateStudentProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStudentProfile'>
);

export type UpdateCaseloadMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CaseloadUpdate;
}>;


export type UpdateCaseloadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCaseload'>
);

export type DistAdminDocumentQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  StuId?: Maybe<Scalars['Int']>;
}>;


export type DistAdminDocumentQuery = (
  { __typename?: 'Query' }
  & { distAdminDocument?: Maybe<Array<(
    { __typename?: 'SessionDocument' }
    & Pick<SessionDocument, 'id' | 'scheduled_start' | 'scheduled_end' | 'sdate' | 'edate' | 'stime' | 'etime' | 'sign' | 'notes' | 'plan'>
    & { appointment_id: (
      { __typename?: 'AppointmentList' }
      & Pick<AppointmentList, 'id'>
      & { service_id?: Maybe<Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'name'>
      )>> }
    ), user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ), session_Goals?: Maybe<Array<Maybe<(
      { __typename?: 'SessionGoals' }
      & Pick<SessionGoals, 'activity' | 'parent_goal_id' | 'assist' | 'attempts' | 'percentage' | 'total'>
      & { goal_id?: Maybe<(
        { __typename?: 'StudentGoals' }
        & Pick<StudentGoals, 'id' | 'name' | 'goal_number'>
      )>, student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
      )> }
    )>>>, session_students?: Maybe<Array<(
      { __typename?: 'SessionStudents' }
      & Pick<SessionStudents, 'notes' | 'plan' | 'present' | 'assessment' | 'further_sessions' | 'further_sessions_text' | 'high_level_care' | 'high_level_care_text'>
      & { student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
      )>, session_id?: Maybe<(
        { __typename?: 'Sessions' }
        & { outcome_id?: Maybe<(
          { __typename?: 'OutCome' }
          & Pick<OutCome, 'id' | 'name'>
        )> }
      )> }
    )>> }
  )>> }
);

export type DeleteIncompleteSessionsMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type DeleteIncompleteSessionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIncompleteSessions'>
);

export type StudentDocumentationQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
  therapist_discipline?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
}>;


export type StudentDocumentationQuery = (
  { __typename?: 'Query' }
  & { studentDocumentation?: Maybe<Array<(
    { __typename?: 'SessionStudents' }
    & Pick<SessionStudents, 'notes' | 'plan' | 'present' | 'assessment' | 'further_sessions' | 'further_sessions_text' | 'high_level_care' | 'high_level_care_text' | 'sdate' | 'edate' | 'stime' | 'etime'>
    & { session_id?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'id' | 'scheduled_start' | 'scheduled_end' | 'sign'>
      & { user_id?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )>, outcome_id?: Maybe<(
        { __typename?: 'OutCome' }
        & Pick<OutCome, 'id' | 'name'>
      )>, appointment_id?: Maybe<(
        { __typename?: 'AppointmentList' }
        & Pick<AppointmentList, 'id'>
        & { service_id?: Maybe<Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'name'>
        )>> }
      )> }
    )>, session_Goals?: Maybe<Array<Maybe<(
      { __typename?: 'SessionGoals' }
      & Pick<SessionGoals, 'id' | 'activity' | 'parent_goal_id' | 'assist' | 'attempts' | 'percentage' | 'total'>
      & { goal_id?: Maybe<(
        { __typename?: 'StudentGoals' }
        & Pick<StudentGoals, 'id' | 'name' | 'goal_number'>
      )> }
    )>>>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )> }
  )>> }
);

export type OutComeQueryVariables = Exact<{ [key: string]: never; }>;


export type OutComeQuery = (
  { __typename?: 'Query' }
  & { outCome?: Maybe<Array<(
    { __typename?: 'OutCome' }
    & Pick<OutCome, 'id' | 'name'>
  )>> }
);

export type UserStudentsForDocQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type UserStudentsForDocQuery = (
  { __typename?: 'Query' }
  & { userStudentsForDoc?: Maybe<Array<(
    { __typename?: 'StudentsData' }
    & Pick<StudentsData, 'id' | 'student_name'>
  )>> }
);

export type TherapistListForDocQueryVariables = Exact<{
  district_id: Scalars['Int'];
}>;


export type TherapistListForDocQuery = (
  { __typename?: 'Query' }
  & { therapistListForDoc?: Maybe<Array<(
    { __typename?: 'TherapistListForDoc' }
    & Pick<TherapistListForDoc, 'id' | 'name'>
  )>> }
);

export type UserDocumentQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  StuId?: Maybe<Scalars['Int']>;
}>;


export type UserDocumentQuery = (
  { __typename?: 'Query' }
  & { userDocument?: Maybe<Array<(
    { __typename?: 'SessionDocument' }
    & Pick<SessionDocument, 'id' | 'scheduled_start' | 'scheduled_end' | 'sdate' | 'edate' | 'stime' | 'etime' | 'sign' | 'notes' | 'plan'>
    & { appointment_id: (
      { __typename?: 'AppointmentList' }
      & Pick<AppointmentList, 'id'>
      & { service_id?: Maybe<Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'name'>
      )>> }
    ), user_id: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ), session_Goals?: Maybe<Array<Maybe<(
      { __typename?: 'SessionGoals' }
      & Pick<SessionGoals, 'activity' | 'parent_goal_id' | 'assist' | 'attempts' | 'percentage' | 'total'>
      & { goal_id?: Maybe<(
        { __typename?: 'StudentGoals' }
        & Pick<StudentGoals, 'id' | 'name' | 'goal_number'>
      )>, student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
      )> }
    )>>>, session_students?: Maybe<Array<(
      { __typename?: 'SessionStudents' }
      & Pick<SessionStudents, 'notes' | 'plan' | 'present' | 'assessment' | 'further_sessions' | 'further_sessions_text' | 'high_level_care' | 'high_level_care_text'>
      & { student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id' | 'name'>
      )>, session_id?: Maybe<(
        { __typename?: 'Sessions' }
        & { outcome_id?: Maybe<(
          { __typename?: 'OutCome' }
          & Pick<OutCome, 'id' | 'name'>
        )> }
      )> }
    )>> }
  )>> }
);

export type CreateGoalsMutationVariables = Exact<{
  input: StudentGoalsInput;
  BenchmarkGoalsInput?: Maybe<Array<BenchmarkGoalsInput>>;
  Tracker?: Maybe<Array<Tracker>>;
}>;


export type CreateGoalsMutation = (
  { __typename?: 'Mutation' }
  & { createGoals: (
    { __typename?: 'StudentGoals' }
    & Pick<StudentGoals, 'id'>
  ) }
);

export type GoalsQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type GoalsQuery = (
  { __typename?: 'Query' }
  & { goals?: Maybe<Array<(
    { __typename?: 'StudentGoals' }
    & Pick<StudentGoals, 'id' | 'name' | 'goal_number' | 'user_id' | 'target' | 'baseline'>
    & { goal_type_id?: Maybe<(
      { __typename?: 'GoalTypes' }
      & Pick<GoalTypes, 'id' | 'goal_type'>
    )>, parent_goal_id?: Maybe<Array<Maybe<(
      { __typename?: 'StudentGoals' }
      & Pick<StudentGoals, 'id' | 'goal_number' | 'name' | 'target'>
      & { trackers?: Maybe<Array<Maybe<(
        { __typename?: 'GoalsTracker' }
        & Pick<GoalsTracker, 'id' | 'icon' | 'name' | 'tracker_icon_id'>
        & { tracker_type_id?: Maybe<(
          { __typename?: 'TrackerTypes' }
          & Pick<TrackerTypes, 'id'>
        )> }
      )>>> }
    )>>>, trackers?: Maybe<Array<Maybe<(
      { __typename?: 'GoalsTracker' }
      & Pick<GoalsTracker, 'id' | 'icon' | 'name' | 'tracker_icon_id'>
      & { tracker_type_id?: Maybe<(
        { __typename?: 'TrackerTypes' }
        & Pick<TrackerTypes, 'id'>
      )> }
    )>>> }
  )>> }
);

export type ListstudentIepsQueryVariables = Exact<{
  student_id: Scalars['Int'];
}>;


export type ListstudentIepsQuery = (
  { __typename?: 'Query' }
  & { studentIeps?: Maybe<Array<(
    { __typename?: 'StudentIeps' }
    & Pick<StudentIeps, 'id' | 'date_reeval' | 'date_end' | 'date_initiated' | 'date_annual_review'>
    & { student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )> }
  )>> }
);

export type GoalNumberCheckQueryVariables = Exact<{
  goal_number: Scalars['Float'];
  student_id?: Maybe<Scalars['Int']>;
}>;


export type GoalNumberCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'goalNumberCheck'>
);

export type GoalProgressQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type GoalProgressQuery = (
  { __typename?: 'Query' }
  & { goalProgress?: Maybe<Array<Maybe<(
    { __typename?: 'GoalProgress' }
    & Pick<GoalProgress, 'student_id' | 'goal_id' | 'overall' | 'last_3_progress' | 'last_1_progress'>
  )>>> }
);

export type UpdateGoalsMutationVariables = Exact<{
  id: Array<Scalars['ID']>;
  input: Array<StudentGoalsInput>;
  BenchmarkGoalsInput?: Maybe<Array<BenchmarkGoalsInput>>;
  Tracker?: Maybe<Array<Tracker>>;
}>;


export type UpdateGoalsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGoals'>
);

export type UpdateGoalsTrackerMutationVariables = Exact<{
  id: Scalars['ID'];
  input: GoalsTrackerInput;
}>;


export type UpdateGoalsTrackerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGoalsTracker'>
);

export type TrackerUpdateMutationVariables = Exact<{
  id: Array<Scalars['ID']>;
  Tracker: Array<Tracker>;
}>;


export type TrackerUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trackersUpdate'>
);

export type UpdateIepsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: StudentIepsInput;
}>;


export type UpdateIepsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIeps'>
);

export type DeleteGoalsTrackerMutationVariables = Exact<{
  id: Array<Scalars['ID']>;
}>;


export type DeleteGoalsTrackerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGoalsTracker'>
);

export type StudentSessionsCountQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type StudentSessionsCountQuery = (
  { __typename?: 'Query' }
  & { StudentSessionsCount?: Maybe<Array<(
    { __typename?: 'studentSessions' }
    & Pick<StudentSessions, 'count'>
  )>> }
);

export type CreateFileUploadMutationVariables = Exact<{
  input: SignalFileUploadInput;
}>;


export type CreateFileUploadMutation = (
  { __typename?: 'Mutation' }
  & { createFileUpload?: Maybe<(
    { __typename?: 'SignalFileUpload' }
    & { categories?: Maybe<(
      { __typename?: 'Categories' }
      & Pick<Categories, 'categories'>
    )> }
  )> }
);

export type MyCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCategoriesQuery = (
  { __typename?: 'Query' }
  & { myCategories?: Maybe<Array<(
    { __typename?: 'Categories' }
    & Pick<Categories, 'id' | 'categories'>
  )>> }
);

export type FileuploadQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type FileuploadQuery = (
  { __typename?: 'Query' }
  & { fileUploadList?: Maybe<Array<Maybe<(
    { __typename?: 'SignalFileUpload' }
    & Pick<SignalFileUpload, 'id' | 'file_name' | 'fileUrl' | 'created_by' | 'updated_by' | 'created_on' | 'updated_on'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name'>
    )>, categories?: Maybe<(
      { __typename?: 'Categories' }
      & Pick<Categories, 'categories'>
    )> }
  )>>> }
);

export type DeleteFileUploadMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFileUploadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFileUpload'>
);

export type MandatoryResendDetailsQueryVariables = Exact<{
  student_id?: Maybe<Scalars['Int']>;
}>;


export type MandatoryResendDetailsQuery = (
  { __typename?: 'Query' }
  & { mandatoryResendDetails?: Maybe<Array<(
    { __typename?: 'MandatoryFormDetails' }
    & Pick<MandatoryFormDetails, 'id' | 'comment' | 'licenses_and_certifications' | 'degreesheld_and_schoolattended' | 'clinician_phone' | 'specializedtraining_and_areasofclinicalcompetence' | 'supervisorname_and_credentials' | 'user_sign' | 'student_sign' | 'user_date' | 'student_date' | 'school_attended'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'street_address' | 'license_number' | 'cert_date'>
      & { signalAgencies?: Maybe<Array<(
        { __typename?: 'Agency' }
        & Pick<Agency, 'id' | 'name'>
      )>> }
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
      & { primaryLanguage?: Maybe<(
        { __typename?: 'PrimaryLanguage' }
        & Pick<PrimaryLanguage, 'id' | 'name'>
      )> }
    )>, status_id?: Maybe<(
      { __typename?: 'MandatoryFormStatus' }
      & Pick<MandatoryFormStatus, 'id' | 'name'>
    )>, relationship_to_client_id?: Maybe<(
      { __typename?: 'RelationshipToClient' }
      & Pick<RelationshipToClient, 'id' | 'name' | 'spanish_name'>
    )>, session_id?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'id'>
    )> }
  )>> }
);

export type ReSendMandatoryEmailMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReSendMandatoryEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reSendMandatoryEmail'>
);

export type MandatoryPdfListCallQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type MandatoryPdfListCallQuery = (
  { __typename?: 'Query' }
  & { mandatoryListCall?: Maybe<Array<Maybe<(
    { __typename?: 'MandatoryFormDetails' }
    & Pick<MandatoryFormDetails, 'id' | 'comment' | 'licenses_and_certifications' | 'degreesheld_and_schoolattended' | 'clinician_phone' | 'specializedtraining_and_areasofclinicalcompetence' | 'supervisorname_and_credentials' | 'user_sign' | 'student_sign' | 'user_date' | 'student_date' | 'school_attended'>
    & { user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'street_address' | 'license_number' | 'cert_date'>
      & { signalAgencies?: Maybe<Array<(
        { __typename?: 'Agency' }
        & Pick<Agency, 'id' | 'name'>
      )>> }
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
      & { primaryLanguage?: Maybe<(
        { __typename?: 'PrimaryLanguage' }
        & Pick<PrimaryLanguage, 'id' | 'name'>
      )> }
    )>, status_id?: Maybe<(
      { __typename?: 'MandatoryFormStatus' }
      & Pick<MandatoryFormStatus, 'id' | 'name'>
    )>, relationship_to_client_id?: Maybe<(
      { __typename?: 'RelationshipToClient' }
      & Pick<RelationshipToClient, 'id' | 'name' | 'spanish_name'>
    )>, session_id?: Maybe<(
      { __typename?: 'Sessions' }
      & Pick<Sessions, 'id'>
    )> }
  )>>> }
);

export type NoteCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type NoteCategoryQuery = (
  { __typename?: 'Query' }
  & { noteCategory?: Maybe<Array<(
    { __typename?: 'NoteCategory' }
    & Pick<NoteCategory, 'id' | 'description' | 'name' | 'user_visible'>
    & { category_type_id: (
      { __typename?: 'NoteCategoryType' }
      & Pick<NoteCategoryType, 'id' | 'name'>
    ) }
  )>> }
);

export type NotesQueryVariables = Exact<{
  student_id: Scalars['Int'];
}>;


export type NotesQuery = (
  { __typename?: 'Query' }
  & { notes?: Maybe<Array<(
    { __typename?: 'Notes' }
    & Pick<Notes, 'id' | 'note' | 'reference_id' | 'created_at' | 'mt_date'>
    & { createdby?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'name'>
    )>, category_id?: Maybe<(
      { __typename?: 'NoteCategory' }
      & Pick<NoteCategory, 'id' | 'name'>
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )> }
  )>> }
);

export type CreateNotesMutationVariables = Exact<{
  input: NotesInput;
}>;


export type CreateNotesMutation = (
  { __typename?: 'Mutation' }
  & { createNotes: (
    { __typename?: 'Notes' }
    & Pick<Notes, 'id'>
  ) }
);

export type SignalTherapistUpdateViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SignalTherapistUpdateViewQuery = (
  { __typename?: 'Query' }
  & { signalTherapistUpdateView?: Maybe<Array<(
    { __typename?: 'SignalTherapistList' }
    & Pick<SignalTherapistList, 'id' | 'name' | 'email' | 'picture' | 'description' | 'userAvailabilityInput'>
  )>> }
);

export type UpdateSignalTherapistMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SignalTherapistInput;
}>;


export type UpdateSignalTherapistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSignalTherapist'>
);

export type CreateUserSlotsMutationVariables = Exact<{
  input?: Maybe<Array<TherapistAvailabilityInput>>;
}>;


export type CreateUserSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserSlots'>
);

export type DeleteUserSlotsMutationVariables = Exact<{
  id: Array<Scalars['Int']>;
}>;


export type DeleteUserSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserSlots'>
);

export type ListTrainingQueryVariables = Exact<{ [key: string]: never; }>;


export type ListTrainingQuery = (
  { __typename?: 'Query' }
  & { reportshistory?: Maybe<Array<(
    { __typename?: 'Reportshistory' }
    & Pick<Reportshistory, 'IMatter_Provider_Manual' | 'YRMHR_Provider_Manual'>
  )>> }
);

export type GetNewProfileSlotsQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  timeperiod?: Maybe<Scalars['DateTime']>;
}>;


export type GetNewProfileSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNewProfileSlots'>
);

export type GetUpdateSlotsListQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  slot_date?: Maybe<Scalars['DateTime']>;
}>;


export type GetUpdateSlotsListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUpdateSlotsList'>
);

export type GetUserAvailableProfileSlotsQueryVariables = Exact<{
  slot_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
}>;


export type GetUserAvailableProfileSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserAvailableProfileSlots'>
);

export type SignalServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type SignalServicesQuery = (
  { __typename?: 'Query' }
  & { signalServices?: Maybe<Array<(
    { __typename?: 'SignalServices' }
    & Pick<SignalServices, 'id' | 'name'>
  )>> }
);

export type GetUserDetailsInProfileQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type GetUserDetailsInProfileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserDetailsInProfile'>
);

export type GetMasterSlotsQueryVariables = Exact<{
  slot_date?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
}>;


export type GetMasterSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMasterSlots'>
);

export type CreateNewProfileSlotsMutationVariables = Exact<{
  input?: Maybe<Array<ProfileSlotsInput>>;
}>;


export type CreateNewProfileSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNewProfileSlots'>
);

export type DeleteNewProfileSlotsMutationVariables = Exact<{
  id: Array<Scalars['Int']>;
}>;


export type DeleteNewProfileSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNewProfileSlots'>
);

export type UpdateRecurringSlotsMutationVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
}>;


export type UpdateRecurringSlotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRecurringSlots'>
);

export type ListUseragencyQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUseragencyQuery = (
  { __typename?: 'Query' }
  & { useragency?: Maybe<Array<(
    { __typename?: 'UserAgency' }
    & Pick<UserAgency, 'agency' | 'agency_address' | 'agency_id'>
  )>> }
);

export type AgencyCheckQueryVariables = Exact<{
  agency_id?: Maybe<Scalars['Int']>;
}>;


export type AgencyCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'agencycheck'>
);

export type ListUserAgegroupQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserAgegroupQuery = (
  { __typename?: 'Query' }
  & { useragegroup?: Maybe<Array<(
    { __typename?: 'UserAgegroup' }
    & Pick<UserAgegroup, 'name' | 'age_group_id' | 'min_age' | 'max_age'>
  )>> }
);

export type ListUserLanguageQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserLanguageQuery = (
  { __typename?: 'Query' }
  & { userlanguage?: Maybe<Array<(
    { __typename?: 'UserLanguage' }
    & Pick<UserLanguage, 'name' | 'language_id'>
  )>> }
);

export type ListUserCountyQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserCountyQuery = (
  { __typename?: 'Query' }
  & { usercounty?: Maybe<Array<(
    { __typename?: 'UserCounty' }
    & Pick<UserCounty, 'name' | 'county_id'>
  )>> }
);

export type ListUserDisciplineQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserDisciplineQuery = (
  { __typename?: 'Query' }
  & { userdiscipline?: Maybe<Array<(
    { __typename?: 'UserDiscipline' }
    & Pick<UserDiscipline, 'name' | 'discipline_id'>
  )>> }
);

export type ListUserInsuranceQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserInsuranceQuery = (
  { __typename?: 'Query' }
  & { userinsurance?: Maybe<Array<(
    { __typename?: 'UserInsurance' }
    & Pick<UserInsurance, 'name' | 'insurance_id'>
  )>> }
);

export type ListUserSpecialityQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type ListUserSpecialityQuery = (
  { __typename?: 'Query' }
  & { userspeciality?: Maybe<Array<(
    { __typename?: 'UserSpeciality' }
    & Pick<UserSpeciality, 'name' | 'speciality_id'>
  )>> }
);

export type ListUsersQueryVariables = Exact<{
  district_id?: Maybe<Scalars['Int']>;
}>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { therapists?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'middle_initial' | 'maiden_name' | 'roles' | 'email' | 'mobile_phone' | 'zip' | 'city' | 'status' | 'home_phone' | 'street_address' | 'license_number' | 'cert_date' | 'cert_expire_date' | 'therapist_discipline'>
    & { state_license?: Maybe<(
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'abbreviation'>
    )>, add_student_permission?: Maybe<(
      { __typename?: 'UserDistrict' }
      & Pick<UserDistrict, 'add_student_permission'>
    )>, district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )> }
  )>> }
);

export type CreateUserDistrictMutationVariables = Exact<{
  input?: Maybe<Array<UserDistrictInput>>;
}>;


export type CreateUserDistrictMutation = (
  { __typename?: 'Mutation' }
  & { createUserDistrict: (
    { __typename?: 'UserDistrict' }
    & Pick<UserDistrict, 'hourly_rate'>
  ) }
);

export type UpdateAddStudentPermissionMutationVariables = Exact<{
  user_id: Scalars['String'];
  input: UpdatePrimaryUserDistrictsInput;
}>;


export type UpdateAddStudentPermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAddStudentPermission'>
);

export type DeleteUserListDistrictMutationVariables = Exact<{
  user_id: Scalars['String'];
  input?: Maybe<Array<DeleteUserListDistrictsInput>>;
}>;


export type DeleteUserListDistrictMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserListDistricts'>
);

export type DisplayAddStudentPermissionQueryVariables = Exact<{
  user_id: Scalars['String'];
  id: Scalars['String'];
}>;


export type DisplayAddStudentPermissionQuery = (
  { __typename?: 'Query' }
  & { displayAddStudentPermission?: Maybe<Array<(
    { __typename?: 'UserDistrictQueryInput' }
    & Pick<UserDistrictQueryInput, 'user_id' | 'add_student_permission' | 'alt_id' | 'hourly_rate' | 'status'>
  )>> }
);

export type UpdateAddStudentPermissionForUserDistrictsMutationVariables = Exact<{
  user_id: Scalars['String'];
  input?: Maybe<Array<Maybe<UpdateAddStudentPermissionForUserDistrictsInput>>>;
}>;


export type UpdateAddStudentPermissionForUserDistrictsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAddStudentPermissionForUserDistricts'>
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserInvite' }
    & Pick<UserInvite, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type UsersQueryVariables = Exact<{
  input?: Maybe<UsersQueryInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'middle_initial' | 'maiden_name' | 'roles' | 'city' | 'zip' | 'status' | 'telemedicine_enabled' | 'telemedicine_trained_at' | 'mobile_phone' | 'home_phone' | 'therapist_discipline' | 'street_address' | 'license_number' | 'cert_date' | 'cert_expire_date' | 'gender' | 'created_by' | 'updated_by'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )>, state_license?: Maybe<(
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'abbreviation'>
    )> }
  )>> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type DisableUserMutationVariables = Exact<{
  id: Scalars['ID'];
  updated_by?: Maybe<Scalars['String']>;
}>;


export type DisableUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableUser'>
);

export type EnableUserMutationVariables = Exact<{
  id: Scalars['ID'];
  updated_by?: Maybe<Scalars['String']>;
}>;


export type EnableUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enableUser'>
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'first_name' | 'last_name' | 'middle_initial' | 'maiden_name' | 'roles' | 'city' | 'zip' | 'status' | 'time_zone' | 'npi' | 'zoom_id' | 'hourly_rate' | 'telemedicine_enabled' | 'telemedicine_trained_at' | 'mobile_phone' | 'home_phone' | 'street_address' | 'license_number' | 'cert_date' | 'cert_expire_date' | 'gender' | 'password' | 'degree_held' | 'school_attended' | 'clinician_phone' | 'specialized_training' | 'supervisor_name_and_credentials' | 'waitlist_assignee'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )>, newUserAgeGroups?: Maybe<(
      { __typename?: 'NewUserToAges' }
      & Pick<NewUserToAges, 'id' | 'user_id' | 'min_age' | 'max_age'>
    )>, student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'name'>
    )>, state_license?: Maybe<(
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'abbreviation'>
    )>, signalDiscipline?: Maybe<(
      { __typename?: 'TherapistDescipline' }
      & Pick<TherapistDescipline, 'id' | 'name'>
    )>, signalAgeGroup?: Maybe<Array<(
      { __typename?: 'AgesServed' }
      & Pick<AgesServed, 'id' | 'name'>
    )>>, signalCounties?: Maybe<Array<(
      { __typename?: 'Counties' }
      & Pick<Counties, 'id' | 'name'>
    )>>, signalInsurance?: Maybe<Array<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'id' | 'name'>
    )>>, signalSpecilities?: Maybe<Array<(
      { __typename?: 'Specialities' }
      & Pick<Specialities, 'id' | 'name'>
    )>>, signalLanguage?: Maybe<Array<(
      { __typename?: 'PrimaryLanguage' }
      & Pick<PrimaryLanguage, 'id' | 'name'>
    )>>, signalAgencies?: Maybe<Array<(
      { __typename?: 'Agency' }
      & Pick<Agency, 'id' | 'name'>
    )>>, ethinic_group_id?: Maybe<(
      { __typename?: 'EthnicGroup' }
      & Pick<EthnicGroup, 'id' | 'name'>
    )>, signal_service_id?: Maybe<(
      { __typename?: 'SignalServices' }
      & Pick<SignalServices, 'id' | 'name'>
    )>, signalUserExperience?: Maybe<(
      { __typename?: 'SignalExperience' }
      & Pick<SignalExperience, 'id' | 'experience'>
    )> }
  ) }
);

export type WaitlistQueryVariables = Exact<{
  user_id?: Maybe<Scalars['String']>;
}>;


export type WaitlistQuery = (
  { __typename?: 'Query' }
  & { waitlist?: Maybe<Array<(
    { __typename?: 'Waitlist' }
    & Pick<Waitlist, 'id' | 'preference_notes' | 'availability_notes' | 'created_at' | 'updated_at' | 'outreach_attempts'>
    & { student_id?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id' | 'first_name' | 'last_name' | 'birth_date' | 'contact_email' | 'status' | 'contact_phone_number' | 'gender' | 'psc_score' | 'sdoh_score' | 'craft_score'>
      & { insurance_company?: Maybe<(
        { __typename?: 'Insurance' }
        & Pick<Insurance, 'id' | 'name'>
      )>, student_specialities?: Maybe<Array<Maybe<(
        { __typename?: 'StudentSpecialties' }
        & { speciality_id?: Maybe<(
          { __typename?: 'Specialities' }
          & Pick<Specialities, 'id' | 'name'>
        )> }
      )>>>, session_service?: Maybe<(
        { __typename?: 'SignalServices' }
        & Pick<SignalServices, 'id' | 'name'>
      )> }
    )>, assigned_user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )>> }
);

export type AdminUsersListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminUsersListQuery = (
  { __typename?: 'Query' }
  & { adminUsersList?: Maybe<Array<(
    { __typename?: 'adminUsers' }
    & Pick<AdminUsers, 'id' | 'name'>
  )>> }
);

export type ListViewLogQueryVariables = Exact<{
  waitlist_id?: Maybe<Scalars['String']>;
}>;


export type ListViewLogQuery = (
  { __typename?: 'Query' }
  & { listViewLog?: Maybe<Array<Maybe<(
    { __typename?: 'WaitlistViewLog' }
    & Pick<WaitlistViewLog, 'id' | 'details' | 'date'>
    & { waitlist_id?: Maybe<(
      { __typename?: 'Waitlist' }
      & Pick<Waitlist, 'id'>
      & { student_id?: Maybe<(
        { __typename?: 'Student' }
        & Pick<Student, 'id'>
      )>, assigned_user_id?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )>, user_id?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, action_type_id?: Maybe<(
      { __typename?: 'LogActionType' }
      & Pick<LogActionType, 'id' | 'name'>
    )> }
  )>>> }
);

export type SignalOutreachTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type SignalOutreachTypeQuery = (
  { __typename?: 'Query' }
  & { signalOutreachType?: Maybe<Array<Maybe<(
    { __typename?: 'OutreachType' }
    & Pick<OutreachType, 'id' | 'name'>
  )>>> }
);

export type RemoveFromWaitlistMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveFromWaitlistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudent'>
);

export type AddClientFromWaitlistMutationVariables = Exact<{
  student_id: Scalars['Int'];
  user_id?: Maybe<Scalars['String']>;
}>;


export type AddClientFromWaitlistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addClients'>
);

export type RemoveClientFromWaitlistMutationVariables = Exact<{
  student_id: Scalars['Int'];
  user_id?: Maybe<Scalars['String']>;
}>;


export type RemoveClientFromWaitlistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeClients'>
);

export type CreateWaitlistAssignMutationVariables = Exact<{
  input?: Maybe<WaitlistAssignInput>;
}>;


export type CreateWaitlistAssignMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createWaitlistAssign'>
);

export type CreateWaitlistOutreachMutationVariables = Exact<{
  input?: Maybe<WaitlistOutreach>;
}>;


export type CreateWaitlistOutreachMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createWaitlistOutreach'>
);

export type ActivateAccountMutationVariables = Exact<{
  input: ActivateAccountInput;
}>;


export type ActivateAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateAccount'>
);

export type UserInviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type UserInviteQuery = (
  { __typename?: 'Query' }
  & { userInvite?: Maybe<(
    { __typename?: 'UserInvite' }
    & Pick<UserInvite, 'id' | 'email' | 'name' | 'roles'>
    & { district?: Maybe<(
      { __typename?: 'District' }
      & Pick<District, 'id' | 'name'>
    )> }
  )> }
);

export type SignalSlotsQueryVariables = Exact<{ [key: string]: never; }>;


export type SignalSlotsQuery = (
  { __typename?: 'Query' }
  & { signalSlots?: Maybe<Array<(
    { __typename?: 'SignalSlots' }
    & Pick<SignalSlots, 'id' | 'time_slot'>
  )>> }
);

export type NextDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type NextDaysQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nextDays'>
);

export type ResourcesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type ResourcesQuery = (
  { __typename?: 'Query' }
  & { GCMS_resources: Array<(
    { __typename?: 'CmsResource' }
    & Pick<CmsResource, 'id' | 'title' | 'createdAt'>
    & { coverImage: (
      { __typename?: 'CmsAsset' }
      & Pick<CmsAsset, 'id' | 'url' | 'handle' | 'height' | 'width'>
    ), disciplines: Array<(
      { __typename?: 'CmsDiscipline' }
      & Pick<CmsDiscipline, 'id' | 'name'>
    )>, content?: Maybe<(
      { __typename?: 'CmsArticle' }
      & Pick<CmsArticle, 'id'>
    ) | (
      { __typename?: 'CmsCOGame' }
      & Pick<CmsCoGame, 'id'>
    ) | (
      { __typename?: 'CmsDragBoard' }
      & Pick<CmsDragBoard, 'id'>
    ) | (
      { __typename?: 'CmsOrderingGame' }
      & Pick<CmsOrderingGame, 'id'>
    ) | (
      { __typename?: 'CmsPdf' }
      & Pick<CmsPdf, 'id'>
    ) | (
      { __typename?: 'CmsVideo' }
      & Pick<CmsVideo, 'id'>
      & { file: (
        { __typename?: 'CmsAsset' }
        & Pick<CmsAsset, 'id' | 'url'>
      ) }
    )> }
  )> }
);

export type ResourceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceQuery = (
  { __typename?: 'Query' }
  & { GCMS_resource?: Maybe<(
    { __typename?: 'CmsResource' }
    & Pick<CmsResource, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { coverImage: (
      { __typename?: 'CmsAsset' }
      & Pick<CmsAsset, 'id' | 'url' | 'handle' | 'height' | 'width'>
    ), disciplines: Array<(
      { __typename?: 'CmsDiscipline' }
      & Pick<CmsDiscipline, 'id' | 'name'>
    )>, related: Array<(
      { __typename?: 'CmsResource' }
      & Pick<CmsResource, 'id' | 'title'>
      & { coverImage: (
        { __typename?: 'CmsAsset' }
        & Pick<CmsAsset, 'id' | 'url' | 'handle' | 'height' | 'width'>
      ) }
    )>, content?: Maybe<(
      { __typename?: 'CmsArticle' }
      & Pick<CmsArticle, 'id' | 'body'>
    ) | (
      { __typename?: 'CmsCOGame' }
      & Pick<CmsCoGame, 'id'>
      & { questions: Array<(
        { __typename?: 'CmsCOQuestion' }
        & Pick<CmsCoQuestion, 'id' | 'question'>
        & { answers: Array<(
          { __typename?: 'CmsMcAnswer' }
          & Pick<CmsMcAnswer, 'id' | 'isCorrect'>
          & { image?: Maybe<(
            { __typename?: 'CmsAsset' }
            & Pick<CmsAsset, 'id' | 'handle' | 'width' | 'height'>
          )> }
        )> }
      )> }
    ) | (
      { __typename?: 'CmsDragBoard' }
      & Pick<CmsDragBoard, 'id'>
      & { images: Array<(
        { __typename?: 'CmsAsset' }
        & Pick<CmsAsset, 'id' | 'handle' | 'width' | 'height'>
      )> }
    ) | (
      { __typename?: 'CmsOrderingGame' }
      & Pick<CmsOrderingGame, 'id'>
      & { questions: Array<(
        { __typename?: 'CmsOrderingQuestion' }
        & Pick<CmsOrderingQuestion, 'id' | 'question'>
        & { answers: Array<(
          { __typename?: 'CmsOrderingAnswer' }
          & Pick<CmsOrderingAnswer, 'id' | 'order'>
          & { image?: Maybe<(
            { __typename?: 'CmsAsset' }
            & Pick<CmsAsset, 'id' | 'handle' | 'width' | 'height'>
          )> }
        )> }
      )> }
    ) | (
      { __typename?: 'CmsPdf' }
      & Pick<CmsPdf, 'id'>
      & { file: (
        { __typename?: 'CmsAsset' }
        & Pick<CmsAsset, 'id' | 'url'>
      ) }
    ) | (
      { __typename?: 'CmsVideo' }
      & Pick<CmsVideo, 'id'>
      & { file: (
        { __typename?: 'CmsAsset' }
        & Pick<CmsAsset, 'id' | 'url'>
      ) }
    )> }
  )> }
);


export const ChatCredentialsDocument = gql`
    query ChatCredentials {
  chatCredentials {
    authToken
    userId
  }
}
    `;

/**
 * __useChatCredentialsQuery__
 *
 * To run a query within a React component, call `useChatCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<ChatCredentialsQuery, ChatCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatCredentialsQuery, ChatCredentialsQueryVariables>(ChatCredentialsDocument, options);
      }
export function useChatCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatCredentialsQuery, ChatCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatCredentialsQuery, ChatCredentialsQueryVariables>(ChatCredentialsDocument, options);
        }
export type ChatCredentialsQueryHookResult = ReturnType<typeof useChatCredentialsQuery>;
export type ChatCredentialsLazyQueryHookResult = ReturnType<typeof useChatCredentialsLazyQuery>;
export type ChatCredentialsQueryResult = Apollo.QueryResult<ChatCredentialsQuery, ChatCredentialsQueryVariables>;
export const CreateChatGroupDocument = gql`
    mutation CreateChatGroup($chatAuthToken: String!, $name: String!, $emails: [String!]) {
  createChatGroup(chatAuthToken: $chatAuthToken, name: $name, emails: $emails) {
    id
    name
    messagesCount
    usersCount
  }
}
    `;
export type CreateChatGroupMutationFn = Apollo.MutationFunction<CreateChatGroupMutation, CreateChatGroupMutationVariables>;

/**
 * __useCreateChatGroupMutation__
 *
 * To run a mutation, you first call `useCreateChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatGroupMutation, { data, loading, error }] = useCreateChatGroupMutation({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      name: // value for 'name'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useCreateChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatGroupMutation, CreateChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatGroupMutation, CreateChatGroupMutationVariables>(CreateChatGroupDocument, options);
      }
export type CreateChatGroupMutationHookResult = ReturnType<typeof useCreateChatGroupMutation>;
export type CreateChatGroupMutationResult = Apollo.MutationResult<CreateChatGroupMutation>;
export type CreateChatGroupMutationOptions = Apollo.BaseMutationOptions<CreateChatGroupMutation, CreateChatGroupMutationVariables>;
export const InviteToChatGroupDocument = gql`
    mutation InviteToChatGroup($chatAuthToken: String!, $groupId: String!, $emails: [String!]!) {
  inviteToChatGroup(
    chatAuthToken: $chatAuthToken
    groupId: $groupId
    emails: $emails
  ) {
    id
    name
    messagesCount
    usersCount
  }
}
    `;
export type InviteToChatGroupMutationFn = Apollo.MutationFunction<InviteToChatGroupMutation, InviteToChatGroupMutationVariables>;

/**
 * __useInviteToChatGroupMutation__
 *
 * To run a mutation, you first call `useInviteToChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToChatGroupMutation, { data, loading, error }] = useInviteToChatGroupMutation({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      groupId: // value for 'groupId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteToChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<InviteToChatGroupMutation, InviteToChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteToChatGroupMutation, InviteToChatGroupMutationVariables>(InviteToChatGroupDocument, options);
      }
export type InviteToChatGroupMutationHookResult = ReturnType<typeof useInviteToChatGroupMutation>;
export type InviteToChatGroupMutationResult = Apollo.MutationResult<InviteToChatGroupMutation>;
export type InviteToChatGroupMutationOptions = Apollo.BaseMutationOptions<InviteToChatGroupMutation, InviteToChatGroupMutationVariables>;
export const ListChatGroupMembersDocument = gql`
    query ListChatGroupMembers($chatAuthToken: String!, $groupId: String!) {
  listChatGroupMembers(chatAuthToken: $chatAuthToken, groupId: $groupId) {
    id
    email
    first_name
    last_name
    last_activity
    name
    roles
  }
}
    `;

/**
 * __useListChatGroupMembersQuery__
 *
 * To run a query within a React component, call `useListChatGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatGroupMembersQuery({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useListChatGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<ListChatGroupMembersQuery, ListChatGroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatGroupMembersQuery, ListChatGroupMembersQueryVariables>(ListChatGroupMembersDocument, options);
      }
export function useListChatGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatGroupMembersQuery, ListChatGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatGroupMembersQuery, ListChatGroupMembersQueryVariables>(ListChatGroupMembersDocument, options);
        }
export type ListChatGroupMembersQueryHookResult = ReturnType<typeof useListChatGroupMembersQuery>;
export type ListChatGroupMembersLazyQueryHookResult = ReturnType<typeof useListChatGroupMembersLazyQuery>;
export type ListChatGroupMembersQueryResult = Apollo.QueryResult<ListChatGroupMembersQuery, ListChatGroupMembersQueryVariables>;
export const ListChatGroupsDocument = gql`
    query ListChatGroups($chatAuthToken: String!, $pagination: PaginationInput) {
  listChatGroups(chatAuthToken: $chatAuthToken, pagination: $pagination) {
    id
    name
    messagesCount
    usersCount
  }
}
    `;

/**
 * __useListChatGroupsQuery__
 *
 * To run a query within a React component, call `useListChatGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatGroupsQuery({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListChatGroupsQuery(baseOptions: Apollo.QueryHookOptions<ListChatGroupsQuery, ListChatGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatGroupsQuery, ListChatGroupsQueryVariables>(ListChatGroupsDocument, options);
      }
export function useListChatGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatGroupsQuery, ListChatGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatGroupsQuery, ListChatGroupsQueryVariables>(ListChatGroupsDocument, options);
        }
export type ListChatGroupsQueryHookResult = ReturnType<typeof useListChatGroupsQuery>;
export type ListChatGroupsLazyQueryHookResult = ReturnType<typeof useListChatGroupsLazyQuery>;
export type ListChatGroupsQueryResult = Apollo.QueryResult<ListChatGroupsQuery, ListChatGroupsQueryVariables>;
export const ListInstantMessagingDocument = gql`
    query ListInstantMessaging($chatAuthToken: String!, $pagination: PaginationInput) {
  listInstantMessaging(chatAuthToken: $chatAuthToken, pagination: $pagination) {
    id
    user {
      id
      email
      first_name
      last_name
      name
    }
    messagesCount
  }
}
    `;

/**
 * __useListInstantMessagingQuery__
 *
 * To run a query within a React component, call `useListInstantMessagingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstantMessagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstantMessagingQuery({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListInstantMessagingQuery(baseOptions: Apollo.QueryHookOptions<ListInstantMessagingQuery, ListInstantMessagingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInstantMessagingQuery, ListInstantMessagingQueryVariables>(ListInstantMessagingDocument, options);
      }
export function useListInstantMessagingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInstantMessagingQuery, ListInstantMessagingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInstantMessagingQuery, ListInstantMessagingQueryVariables>(ListInstantMessagingDocument, options);
        }
export type ListInstantMessagingQueryHookResult = ReturnType<typeof useListInstantMessagingQuery>;
export type ListInstantMessagingLazyQueryHookResult = ReturnType<typeof useListInstantMessagingLazyQuery>;
export type ListInstantMessagingQueryResult = Apollo.QueryResult<ListInstantMessagingQuery, ListInstantMessagingQueryVariables>;
export const ListChatUsersDocument = gql`
    query ListChatUsers($pagination: PaginationInput) {
  listChatUsers(pagination: $pagination) {
    id
    email
    first_name
    last_name
    last_activity
    name
    roles
  }
}
    `;

/**
 * __useListChatUsersQuery__
 *
 * To run a query within a React component, call `useListChatUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListChatUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListChatUsersQuery, ListChatUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatUsersQuery, ListChatUsersQueryVariables>(ListChatUsersDocument, options);
      }
export function useListChatUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatUsersQuery, ListChatUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatUsersQuery, ListChatUsersQueryVariables>(ListChatUsersDocument, options);
        }
export type ListChatUsersQueryHookResult = ReturnType<typeof useListChatUsersQuery>;
export type ListChatUsersLazyQueryHookResult = ReturnType<typeof useListChatUsersLazyQuery>;
export type ListChatUsersQueryResult = Apollo.QueryResult<ListChatUsersQuery, ListChatUsersQueryVariables>;
export const ConfirmForgetPasswordDocument = gql`
    mutation ConfirmForgetPassword($id: ID!, $input: ConfirmForgetPasswordInput!) {
  confirmForgetPassword(id: $id, input: $input)
}
    `;
export type ConfirmForgetPasswordMutationFn = Apollo.MutationFunction<ConfirmForgetPasswordMutation, ConfirmForgetPasswordMutationVariables>;

/**
 * __useConfirmForgetPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmForgetPasswordMutation, { data, loading, error }] = useConfirmForgetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmForgetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmForgetPasswordMutation, ConfirmForgetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmForgetPasswordMutation, ConfirmForgetPasswordMutationVariables>(ConfirmForgetPasswordDocument, options);
      }
export type ConfirmForgetPasswordMutationHookResult = ReturnType<typeof useConfirmForgetPasswordMutation>;
export type ConfirmForgetPasswordMutationResult = Apollo.MutationResult<ConfirmForgetPasswordMutation>;
export type ConfirmForgetPasswordMutationOptions = Apollo.BaseMutationOptions<ConfirmForgetPasswordMutation, ConfirmForgetPasswordMutationVariables>;
export const ForgetPasswordDocument = gql`
    mutation ForgetPassword($input: ForgetPasswordInput!) {
  forgetPassword(input: $input)
}
    `;
export type ForgetPasswordMutationFn = Apollo.MutationFunction<ForgetPasswordMutation, ForgetPasswordMutationVariables>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument, options);
      }
export type ForgetPasswordMutationHookResult = ReturnType<typeof useForgetPasswordMutation>;
export type ForgetPasswordMutationResult = Apollo.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions = Apollo.BaseMutationOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>;
export const LoginDocument = gql`
    query Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    id
    access
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const CreateCaseloadDocument = gql`
    mutation CreateCaseload($input: CaseloadInput!) {
  createCaseload(input: $input) {
    id
  }
}
    `;
export type CreateCaseloadMutationFn = Apollo.MutationFunction<CreateCaseloadMutation, CreateCaseloadMutationVariables>;

/**
 * __useCreateCaseloadMutation__
 *
 * To run a mutation, you first call `useCreateCaseloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseloadMutation, { data, loading, error }] = useCreateCaseloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCaseloadMutation(baseOptions?: Apollo.MutationHookOptions<CreateCaseloadMutation, CreateCaseloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCaseloadMutation, CreateCaseloadMutationVariables>(CreateCaseloadDocument, options);
      }
export type CreateCaseloadMutationHookResult = ReturnType<typeof useCreateCaseloadMutation>;
export type CreateCaseloadMutationResult = Apollo.MutationResult<CreateCaseloadMutation>;
export type CreateCaseloadMutationOptions = Apollo.BaseMutationOptions<CreateCaseloadMutation, CreateCaseloadMutationVariables>;
export const IcdCodesDocument = gql`
    query IcdCodes($input: IcdInputQuery) {
  icdCodes(input: $input) {
    code
    therapist_discipline
  }
}
    `;

/**
 * __useIcdCodesQuery__
 *
 * To run a query within a React component, call `useIcdCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcdCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcdCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIcdCodesQuery(baseOptions?: Apollo.QueryHookOptions<IcdCodesQuery, IcdCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IcdCodesQuery, IcdCodesQueryVariables>(IcdCodesDocument, options);
      }
export function useIcdCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IcdCodesQuery, IcdCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IcdCodesQuery, IcdCodesQueryVariables>(IcdCodesDocument, options);
        }
export type IcdCodesQueryHookResult = ReturnType<typeof useIcdCodesQuery>;
export type IcdCodesLazyQueryHookResult = ReturnType<typeof useIcdCodesLazyQuery>;
export type IcdCodesQueryResult = Apollo.QueryResult<IcdCodesQuery, IcdCodesQueryVariables>;
export const CaseStudentsDocument = gql`
    query caseStudents($district_id: ID!, $user_id: String!) {
  caseStudents(district_id: $district_id, user_id: $user_id) {
    id
    student_name
    districtname
    birth_date
  }
}
    `;

/**
 * __useCaseStudentsQuery__
 *
 * To run a query within a React component, call `useCaseStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStudentsQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCaseStudentsQuery(baseOptions: Apollo.QueryHookOptions<CaseStudentsQuery, CaseStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseStudentsQuery, CaseStudentsQueryVariables>(CaseStudentsDocument, options);
      }
export function useCaseStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseStudentsQuery, CaseStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseStudentsQuery, CaseStudentsQueryVariables>(CaseStudentsDocument, options);
        }
export type CaseStudentsQueryHookResult = ReturnType<typeof useCaseStudentsQuery>;
export type CaseStudentsLazyQueryHookResult = ReturnType<typeof useCaseStudentsLazyQuery>;
export type CaseStudentsQueryResult = Apollo.QueryResult<CaseStudentsQuery, CaseStudentsQueryVariables>;
export const CaseloadTypeDocument = gql`
    query CaseloadType {
  caseloadType {
    id
    name
  }
}
    `;

/**
 * __useCaseloadTypeQuery__
 *
 * To run a query within a React component, call `useCaseloadTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseloadTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseloadTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseloadTypeQuery(baseOptions?: Apollo.QueryHookOptions<CaseloadTypeQuery, CaseloadTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseloadTypeQuery, CaseloadTypeQueryVariables>(CaseloadTypeDocument, options);
      }
export function useCaseloadTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseloadTypeQuery, CaseloadTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseloadTypeQuery, CaseloadTypeQueryVariables>(CaseloadTypeDocument, options);
        }
export type CaseloadTypeQueryHookResult = ReturnType<typeof useCaseloadTypeQuery>;
export type CaseloadTypeLazyQueryHookResult = ReturnType<typeof useCaseloadTypeLazyQuery>;
export type CaseloadTypeQueryResult = Apollo.QueryResult<CaseloadTypeQuery, CaseloadTypeQueryVariables>;
export const GetDisciplinesDocument = gql`
    query GetDisciplines {
  GCMS_disciplines {
    id
    name
  }
}
    `;

/**
 * __useGetDisciplinesQuery__
 *
 * To run a query within a React component, call `useGetDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisciplinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDisciplinesQuery(baseOptions?: Apollo.QueryHookOptions<GetDisciplinesQuery, GetDisciplinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisciplinesQuery, GetDisciplinesQueryVariables>(GetDisciplinesDocument, options);
      }
export function useGetDisciplinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisciplinesQuery, GetDisciplinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisciplinesQuery, GetDisciplinesQueryVariables>(GetDisciplinesDocument, options);
        }
export type GetDisciplinesQueryHookResult = ReturnType<typeof useGetDisciplinesQuery>;
export type GetDisciplinesLazyQueryHookResult = ReturnType<typeof useGetDisciplinesLazyQuery>;
export type GetDisciplinesQueryResult = Apollo.QueryResult<GetDisciplinesQuery, GetDisciplinesQueryVariables>;
export const DistrictSelectDocument = gql`
    query DistrictSelect($input: DistrictsQueryInput) {
  districts(input: $input) {
    id
    name
    status
  }
}
    `;

/**
 * __useDistrictSelectQuery__
 *
 * To run a query within a React component, call `useDistrictSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistrictSelectQuery(baseOptions?: Apollo.QueryHookOptions<DistrictSelectQuery, DistrictSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictSelectQuery, DistrictSelectQueryVariables>(DistrictSelectDocument, options);
      }
export function useDistrictSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictSelectQuery, DistrictSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictSelectQuery, DistrictSelectQueryVariables>(DistrictSelectDocument, options);
        }
export type DistrictSelectQueryHookResult = ReturnType<typeof useDistrictSelectQuery>;
export type DistrictSelectLazyQueryHookResult = ReturnType<typeof useDistrictSelectLazyQuery>;
export type DistrictSelectQueryResult = Apollo.QueryResult<DistrictSelectQuery, DistrictSelectQueryVariables>;
export const GoalSelectDocument = gql`
    query GoalSelect {
  goalTypes {
    id
    goal_type
  }
}
    `;

/**
 * __useGoalSelectQuery__
 *
 * To run a query within a React component, call `useGoalSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalSelectQuery(baseOptions?: Apollo.QueryHookOptions<GoalSelectQuery, GoalSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalSelectQuery, GoalSelectQueryVariables>(GoalSelectDocument, options);
      }
export function useGoalSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalSelectQuery, GoalSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalSelectQuery, GoalSelectQueryVariables>(GoalSelectDocument, options);
        }
export type GoalSelectQueryHookResult = ReturnType<typeof useGoalSelectQuery>;
export type GoalSelectLazyQueryHookResult = ReturnType<typeof useGoalSelectLazyQuery>;
export type GoalSelectQueryResult = Apollo.QueryResult<GoalSelectQuery, GoalSelectQueryVariables>;
export const GradeDocument = gql`
    query Grade {
  grade {
    id
    grade
  }
}
    `;

/**
 * __useGradeQuery__
 *
 * To run a query within a React component, call `useGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGradeQuery(baseOptions?: Apollo.QueryHookOptions<GradeQuery, GradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GradeQuery, GradeQueryVariables>(GradeDocument, options);
      }
export function useGradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GradeQuery, GradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GradeQuery, GradeQueryVariables>(GradeDocument, options);
        }
export type GradeQueryHookResult = ReturnType<typeof useGradeQuery>;
export type GradeLazyQueryHookResult = ReturnType<typeof useGradeLazyQuery>;
export type GradeQueryResult = Apollo.QueryResult<GradeQuery, GradeQueryVariables>;
export const IcdCodeSelectDocument = gql`
    query IcdCodeSelect {
  icdCodes {
    id
    code
    status
    name
  }
}
    `;

/**
 * __useIcdCodeSelectQuery__
 *
 * To run a query within a React component, call `useIcdCodeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcdCodeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcdCodeSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useIcdCodeSelectQuery(baseOptions?: Apollo.QueryHookOptions<IcdCodeSelectQuery, IcdCodeSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IcdCodeSelectQuery, IcdCodeSelectQueryVariables>(IcdCodeSelectDocument, options);
      }
export function useIcdCodeSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IcdCodeSelectQuery, IcdCodeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IcdCodeSelectQuery, IcdCodeSelectQueryVariables>(IcdCodeSelectDocument, options);
        }
export type IcdCodeSelectQueryHookResult = ReturnType<typeof useIcdCodeSelectQuery>;
export type IcdCodeSelectLazyQueryHookResult = ReturnType<typeof useIcdCodeSelectLazyQuery>;
export type IcdCodeSelectQueryResult = Apollo.QueryResult<IcdCodeSelectQuery, IcdCodeSelectQueryVariables>;
export const AgesServedSelectDocument = gql`
    query AgesServedSelect {
  agesServed {
    id
    name
    status
  }
}
    `;

/**
 * __useAgesServedSelectQuery__
 *
 * To run a query within a React component, call `useAgesServedSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgesServedSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgesServedSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgesServedSelectQuery(baseOptions?: Apollo.QueryHookOptions<AgesServedSelectQuery, AgesServedSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgesServedSelectQuery, AgesServedSelectQueryVariables>(AgesServedSelectDocument, options);
      }
export function useAgesServedSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgesServedSelectQuery, AgesServedSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgesServedSelectQuery, AgesServedSelectQueryVariables>(AgesServedSelectDocument, options);
        }
export type AgesServedSelectQueryHookResult = ReturnType<typeof useAgesServedSelectQuery>;
export type AgesServedSelectLazyQueryHookResult = ReturnType<typeof useAgesServedSelectLazyQuery>;
export type AgesServedSelectQueryResult = Apollo.QueryResult<AgesServedSelectQuery, AgesServedSelectQueryVariables>;
export const EthnicGroupSelectDocument = gql`
    query EthnicGroupSelect {
  ethnicGroup {
    id
    name
    status
  }
}
    `;

/**
 * __useEthnicGroupSelectQuery__
 *
 * To run a query within a React component, call `useEthnicGroupSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEthnicGroupSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEthnicGroupSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useEthnicGroupSelectQuery(baseOptions?: Apollo.QueryHookOptions<EthnicGroupSelectQuery, EthnicGroupSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EthnicGroupSelectQuery, EthnicGroupSelectQueryVariables>(EthnicGroupSelectDocument, options);
      }
export function useEthnicGroupSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EthnicGroupSelectQuery, EthnicGroupSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EthnicGroupSelectQuery, EthnicGroupSelectQueryVariables>(EthnicGroupSelectDocument, options);
        }
export type EthnicGroupSelectQueryHookResult = ReturnType<typeof useEthnicGroupSelectQuery>;
export type EthnicGroupSelectLazyQueryHookResult = ReturnType<typeof useEthnicGroupSelectLazyQuery>;
export type EthnicGroupSelectQueryResult = Apollo.QueryResult<EthnicGroupSelectQuery, EthnicGroupSelectQueryVariables>;
export const CountiesSelectDocument = gql`
    query CountiesSelect {
  counties {
    id
    name
    status
  }
}
    `;

/**
 * __useCountiesSelectQuery__
 *
 * To run a query within a React component, call `useCountiesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountiesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountiesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountiesSelectQuery(baseOptions?: Apollo.QueryHookOptions<CountiesSelectQuery, CountiesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountiesSelectQuery, CountiesSelectQueryVariables>(CountiesSelectDocument, options);
      }
export function useCountiesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountiesSelectQuery, CountiesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountiesSelectQuery, CountiesSelectQueryVariables>(CountiesSelectDocument, options);
        }
export type CountiesSelectQueryHookResult = ReturnType<typeof useCountiesSelectQuery>;
export type CountiesSelectLazyQueryHookResult = ReturnType<typeof useCountiesSelectLazyQuery>;
export type CountiesSelectQueryResult = Apollo.QueryResult<CountiesSelectQuery, CountiesSelectQueryVariables>;
export const InsuranceSelectDocument = gql`
    query InsuranceSelect {
  insurance {
    id
    name
    status
  }
}
    `;

/**
 * __useInsuranceSelectQuery__
 *
 * To run a query within a React component, call `useInsuranceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsuranceSelectQuery(baseOptions?: Apollo.QueryHookOptions<InsuranceSelectQuery, InsuranceSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceSelectQuery, InsuranceSelectQueryVariables>(InsuranceSelectDocument, options);
      }
export function useInsuranceSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceSelectQuery, InsuranceSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceSelectQuery, InsuranceSelectQueryVariables>(InsuranceSelectDocument, options);
        }
export type InsuranceSelectQueryHookResult = ReturnType<typeof useInsuranceSelectQuery>;
export type InsuranceSelectLazyQueryHookResult = ReturnType<typeof useInsuranceSelectLazyQuery>;
export type InsuranceSelectQueryResult = Apollo.QueryResult<InsuranceSelectQuery, InsuranceSelectQueryVariables>;
export const SpecialitiesSelectDocument = gql`
    query SpecialitiesSelect {
  specialities {
    id
    status
    name
  }
}
    `;

/**
 * __useSpecialitiesSelectQuery__
 *
 * To run a query within a React component, call `useSpecialitiesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialitiesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialitiesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecialitiesSelectQuery(baseOptions?: Apollo.QueryHookOptions<SpecialitiesSelectQuery, SpecialitiesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialitiesSelectQuery, SpecialitiesSelectQueryVariables>(SpecialitiesSelectDocument, options);
      }
export function useSpecialitiesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialitiesSelectQuery, SpecialitiesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialitiesSelectQuery, SpecialitiesSelectQueryVariables>(SpecialitiesSelectDocument, options);
        }
export type SpecialitiesSelectQueryHookResult = ReturnType<typeof useSpecialitiesSelectQuery>;
export type SpecialitiesSelectLazyQueryHookResult = ReturnType<typeof useSpecialitiesSelectLazyQuery>;
export type SpecialitiesSelectQueryResult = Apollo.QueryResult<SpecialitiesSelectQuery, SpecialitiesSelectQueryVariables>;
export const TherapistDesciplineSelectDocument = gql`
    query TherapistDesciplineSelect {
  therapistDescipline {
    id
    name
    status
  }
}
    `;

/**
 * __useTherapistDesciplineSelectQuery__
 *
 * To run a query within a React component, call `useTherapistDesciplineSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistDesciplineSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistDesciplineSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTherapistDesciplineSelectQuery(baseOptions?: Apollo.QueryHookOptions<TherapistDesciplineSelectQuery, TherapistDesciplineSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistDesciplineSelectQuery, TherapistDesciplineSelectQueryVariables>(TherapistDesciplineSelectDocument, options);
      }
export function useTherapistDesciplineSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistDesciplineSelectQuery, TherapistDesciplineSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistDesciplineSelectQuery, TherapistDesciplineSelectQueryVariables>(TherapistDesciplineSelectDocument, options);
        }
export type TherapistDesciplineSelectQueryHookResult = ReturnType<typeof useTherapistDesciplineSelectQuery>;
export type TherapistDesciplineSelectLazyQueryHookResult = ReturnType<typeof useTherapistDesciplineSelectLazyQuery>;
export type TherapistDesciplineSelectQueryResult = Apollo.QueryResult<TherapistDesciplineSelectQuery, TherapistDesciplineSelectQueryVariables>;
export const SignalServicesSelectDocument = gql`
    query SignalServicesSelect {
  signalServices {
    id
    name
    status
  }
}
    `;

/**
 * __useSignalServicesSelectQuery__
 *
 * To run a query within a React component, call `useSignalServicesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalServicesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalServicesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignalServicesSelectQuery(baseOptions?: Apollo.QueryHookOptions<SignalServicesSelectQuery, SignalServicesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalServicesSelectQuery, SignalServicesSelectQueryVariables>(SignalServicesSelectDocument, options);
      }
export function useSignalServicesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalServicesSelectQuery, SignalServicesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalServicesSelectQuery, SignalServicesSelectQueryVariables>(SignalServicesSelectDocument, options);
        }
export type SignalServicesSelectQueryHookResult = ReturnType<typeof useSignalServicesSelectQuery>;
export type SignalServicesSelectLazyQueryHookResult = ReturnType<typeof useSignalServicesSelectLazyQuery>;
export type SignalServicesSelectQueryResult = Apollo.QueryResult<SignalServicesSelectQuery, SignalServicesSelectQueryVariables>;
export const PrimaryLanguageDocument = gql`
    query PrimaryLanguage {
  primaryLanguage {
    id
    name
  }
}
    `;

/**
 * __usePrimaryLanguageQuery__
 *
 * To run a query within a React component, call `usePrimaryLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrimaryLanguageQuery(baseOptions?: Apollo.QueryHookOptions<PrimaryLanguageQuery, PrimaryLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrimaryLanguageQuery, PrimaryLanguageQueryVariables>(PrimaryLanguageDocument, options);
      }
export function usePrimaryLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrimaryLanguageQuery, PrimaryLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrimaryLanguageQuery, PrimaryLanguageQueryVariables>(PrimaryLanguageDocument, options);
        }
export type PrimaryLanguageQueryHookResult = ReturnType<typeof usePrimaryLanguageQuery>;
export type PrimaryLanguageLazyQueryHookResult = ReturnType<typeof usePrimaryLanguageLazyQuery>;
export type PrimaryLanguageQueryResult = Apollo.QueryResult<PrimaryLanguageQuery, PrimaryLanguageQueryVariables>;
export const AgencyListDocument = gql`
    query AgencyList {
  agencyList {
    id
    name
  }
}
    `;

/**
 * __useAgencyListQuery__
 *
 * To run a query within a React component, call `useAgencyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgencyListQuery(baseOptions?: Apollo.QueryHookOptions<AgencyListQuery, AgencyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyListQuery, AgencyListQueryVariables>(AgencyListDocument, options);
      }
export function useAgencyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyListQuery, AgencyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyListQuery, AgencyListQueryVariables>(AgencyListDocument, options);
        }
export type AgencyListQueryHookResult = ReturnType<typeof useAgencyListQuery>;
export type AgencyListLazyQueryHookResult = ReturnType<typeof useAgencyListLazyQuery>;
export type AgencyListQueryResult = Apollo.QueryResult<AgencyListQuery, AgencyListQueryVariables>;
export const ExperienceListDocument = gql`
    query ExperienceList {
  signalExperience {
    id
    experience
  }
}
    `;

/**
 * __useExperienceListQuery__
 *
 * To run a query within a React component, call `useExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperienceListQuery(baseOptions?: Apollo.QueryHookOptions<ExperienceListQuery, ExperienceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExperienceListQuery, ExperienceListQueryVariables>(ExperienceListDocument, options);
      }
export function useExperienceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperienceListQuery, ExperienceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExperienceListQuery, ExperienceListQueryVariables>(ExperienceListDocument, options);
        }
export type ExperienceListQueryHookResult = ReturnType<typeof useExperienceListQuery>;
export type ExperienceListLazyQueryHookResult = ReturnType<typeof useExperienceListLazyQuery>;
export type ExperienceListQueryResult = Apollo.QueryResult<ExperienceListQuery, ExperienceListQueryVariables>;
export const SchoolSelectDocument = gql`
    query SchoolSelect($district_id: Int) {
  schools(district_id: $district_id) {
    id
    name
    status
  }
}
    `;

/**
 * __useSchoolSelectQuery__
 *
 * To run a query within a React component, call `useSchoolSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolSelectQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useSchoolSelectQuery(baseOptions?: Apollo.QueryHookOptions<SchoolSelectQuery, SchoolSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolSelectQuery, SchoolSelectQueryVariables>(SchoolSelectDocument, options);
      }
export function useSchoolSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolSelectQuery, SchoolSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolSelectQuery, SchoolSelectQueryVariables>(SchoolSelectDocument, options);
        }
export type SchoolSelectQueryHookResult = ReturnType<typeof useSchoolSelectQuery>;
export type SchoolSelectLazyQueryHookResult = ReturnType<typeof useSchoolSelectLazyQuery>;
export type SchoolSelectQueryResult = Apollo.QueryResult<SchoolSelectQuery, SchoolSelectQueryVariables>;
export const ServiceSelectDocument = gql`
    query ServiceSelect {
  services {
    id
    name
    code
    status
    is_direct
    is_indirect
    is_evaluation
    is_support
    is_therapy
    therapist_discipline
  }
}
    `;

/**
 * __useServiceSelectQuery__
 *
 * To run a query within a React component, call `useServiceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceSelectQuery(baseOptions?: Apollo.QueryHookOptions<ServiceSelectQuery, ServiceSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceSelectQuery, ServiceSelectQueryVariables>(ServiceSelectDocument, options);
      }
export function useServiceSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceSelectQuery, ServiceSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceSelectQuery, ServiceSelectQueryVariables>(ServiceSelectDocument, options);
        }
export type ServiceSelectQueryHookResult = ReturnType<typeof useServiceSelectQuery>;
export type ServiceSelectLazyQueryHookResult = ReturnType<typeof useServiceSelectLazyQuery>;
export type ServiceSelectQueryResult = Apollo.QueryResult<ServiceSelectQuery, ServiceSelectQueryVariables>;
export const StudentSelectDocument = gql`
    query StudentSelect($district_id: Int) {
  students(district_id: $district_id) {
    id
    name
  }
}
    `;

/**
 * __useStudentSelectQuery__
 *
 * To run a query within a React component, call `useStudentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSelectQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useStudentSelectQuery(baseOptions?: Apollo.QueryHookOptions<StudentSelectQuery, StudentSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSelectQuery, StudentSelectQueryVariables>(StudentSelectDocument, options);
      }
export function useStudentSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSelectQuery, StudentSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSelectQuery, StudentSelectQueryVariables>(StudentSelectDocument, options);
        }
export type StudentSelectQueryHookResult = ReturnType<typeof useStudentSelectQuery>;
export type StudentSelectLazyQueryHookResult = ReturnType<typeof useStudentSelectLazyQuery>;
export type StudentSelectQueryResult = Apollo.QueryResult<StudentSelectQuery, StudentSelectQueryVariables>;
export const StudentGradesDocument = gql`
    query studentGrades($id: ID!) {
  studentGrades(id: $id) {
    id
    grade
  }
}
    `;

/**
 * __useStudentGradesQuery__
 *
 * To run a query within a React component, call `useStudentGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentGradesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentGradesQuery(baseOptions: Apollo.QueryHookOptions<StudentGradesQuery, StudentGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentGradesQuery, StudentGradesQueryVariables>(StudentGradesDocument, options);
      }
export function useStudentGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentGradesQuery, StudentGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentGradesQuery, StudentGradesQueryVariables>(StudentGradesDocument, options);
        }
export type StudentGradesQueryHookResult = ReturnType<typeof useStudentGradesQuery>;
export type StudentGradesLazyQueryHookResult = ReturnType<typeof useStudentGradesLazyQuery>;
export type StudentGradesQueryResult = Apollo.QueryResult<StudentGradesQuery, StudentGradesQueryVariables>;
export const TrackerSelectDocument = gql`
    query TrackerSelect {
  trackerTypes {
    id
    name
  }
}
    `;

/**
 * __useTrackerSelectQuery__
 *
 * To run a query within a React component, call `useTrackerSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrackerSelectQuery(baseOptions?: Apollo.QueryHookOptions<TrackerSelectQuery, TrackerSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackerSelectQuery, TrackerSelectQueryVariables>(TrackerSelectDocument, options);
      }
export function useTrackerSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackerSelectQuery, TrackerSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackerSelectQuery, TrackerSelectQueryVariables>(TrackerSelectDocument, options);
        }
export type TrackerSelectQueryHookResult = ReturnType<typeof useTrackerSelectQuery>;
export type TrackerSelectLazyQueryHookResult = ReturnType<typeof useTrackerSelectLazyQuery>;
export type TrackerSelectQueryResult = Apollo.QueryResult<TrackerSelectQuery, TrackerSelectQueryVariables>;
export const UserListDistrictsSelectDocument = gql`
    query userListDistrictsSelect($user_id: String!) {
  userListDistricts(user_id: $user_id) {
    user_id
    district_id
    name
    alt_id
    hourly_rate
    add_student_permission
    status
  }
}
    `;

/**
 * __useUserListDistrictsSelectQuery__
 *
 * To run a query within a React component, call `useUserListDistrictsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListDistrictsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListDistrictsSelectQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserListDistrictsSelectQuery(baseOptions: Apollo.QueryHookOptions<UserListDistrictsSelectQuery, UserListDistrictsSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListDistrictsSelectQuery, UserListDistrictsSelectQueryVariables>(UserListDistrictsSelectDocument, options);
      }
export function useUserListDistrictsSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListDistrictsSelectQuery, UserListDistrictsSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListDistrictsSelectQuery, UserListDistrictsSelectQueryVariables>(UserListDistrictsSelectDocument, options);
        }
export type UserListDistrictsSelectQueryHookResult = ReturnType<typeof useUserListDistrictsSelectQuery>;
export type UserListDistrictsSelectLazyQueryHookResult = ReturnType<typeof useUserListDistrictsSelectLazyQuery>;
export type UserListDistrictsSelectQueryResult = Apollo.QueryResult<UserListDistrictsSelectQuery, UserListDistrictsSelectQueryVariables>;
export const UserSelectDocument = gql`
    query UserSelect($input: UsersQueryInput) {
  users(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useUserSelectQuery__
 *
 * To run a query within a React component, call `useUserSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserSelectQuery(baseOptions?: Apollo.QueryHookOptions<UserSelectQuery, UserSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
      }
export function useUserSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSelectQuery, UserSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
        }
export type UserSelectQueryHookResult = ReturnType<typeof useUserSelectQuery>;
export type UserSelectLazyQueryHookResult = ReturnType<typeof useUserSelectLazyQuery>;
export type UserSelectQueryResult = Apollo.QueryResult<UserSelectQuery, UserSelectQueryVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: ID!) {
  deleteReport(id: $id)
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const MyGeneratedReportsDocument = gql`
    query MyGeneratedReports {
  myGeneratedReports {
    createdAt
    fileName
    id
    name
  }
}
    `;

/**
 * __useMyGeneratedReportsQuery__
 *
 * To run a query within a React component, call `useMyGeneratedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGeneratedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGeneratedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGeneratedReportsQuery(baseOptions?: Apollo.QueryHookOptions<MyGeneratedReportsQuery, MyGeneratedReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyGeneratedReportsQuery, MyGeneratedReportsQueryVariables>(MyGeneratedReportsDocument, options);
      }
export function useMyGeneratedReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyGeneratedReportsQuery, MyGeneratedReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyGeneratedReportsQuery, MyGeneratedReportsQueryVariables>(MyGeneratedReportsDocument, options);
        }
export type MyGeneratedReportsQueryHookResult = ReturnType<typeof useMyGeneratedReportsQuery>;
export type MyGeneratedReportsLazyQueryHookResult = ReturnType<typeof useMyGeneratedReportsLazyQuery>;
export type MyGeneratedReportsQueryResult = Apollo.QueryResult<MyGeneratedReportsQuery, MyGeneratedReportsQueryVariables>;
export const GetAdminUserDocument = gql`
    query getAdminUser($username: String!) {
  getAdminUser(Username: $username) {
    UserStatus
    Username
    UserCreateDate
    UserLastModifiedDate
    NumberOfDays
    IsPasswordExpaired
    UserAttributes {
      Name
      Value
    }
  }
}
    `;

/**
 * __useGetAdminUserQuery__
 *
 * To run a query within a React component, call `useGetAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetAdminUserQuery(baseOptions: Apollo.QueryHookOptions<GetAdminUserQuery, GetAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminUserQuery, GetAdminUserQueryVariables>(GetAdminUserDocument, options);
      }
export function useGetAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUserQuery, GetAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminUserQuery, GetAdminUserQueryVariables>(GetAdminUserDocument, options);
        }
export type GetAdminUserQueryHookResult = ReturnType<typeof useGetAdminUserQuery>;
export type GetAdminUserLazyQueryHookResult = ReturnType<typeof useGetAdminUserLazyQuery>;
export type GetAdminUserQueryResult = Apollo.QueryResult<GetAdminUserQuery, GetAdminUserQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($id: ID!, $input: ChangePassword!) {
  changePassword(id: $id, input: $input)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    email
    name
    roles
    district {
      id
      name
    }
    last_activity
    therapist_discipline
    signal_service_id {
      id
      name
    }
    street_address
    signalAgencies {
      id
      name
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const AdminDashboardMetricsDocument = gql`
    query AdminDashboardMetrics {
  metrics {
    totalStudentDistrict
    totalSession
    totalStudentAbsenses
    totalDue
  }
}
    `;

/**
 * __useAdminDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useAdminDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDashboardMetricsQuery(baseOptions?: Apollo.QueryHookOptions<AdminDashboardMetricsQuery, AdminDashboardMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDashboardMetricsQuery, AdminDashboardMetricsQueryVariables>(AdminDashboardMetricsDocument, options);
      }
export function useAdminDashboardMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDashboardMetricsQuery, AdminDashboardMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDashboardMetricsQuery, AdminDashboardMetricsQueryVariables>(AdminDashboardMetricsDocument, options);
        }
export type AdminDashboardMetricsQueryHookResult = ReturnType<typeof useAdminDashboardMetricsQuery>;
export type AdminDashboardMetricsLazyQueryHookResult = ReturnType<typeof useAdminDashboardMetricsLazyQuery>;
export type AdminDashboardMetricsQueryResult = Apollo.QueryResult<AdminDashboardMetricsQuery, AdminDashboardMetricsQueryVariables>;
export const NewDistrictDashboardsDocument = gql`
    query NewDistrictDashboards($district_id: ID, $startDate: String, $endDate: String) {
  newDistrictDashboards(
    district_id: $district_id
    startDate: $startDate
    endDate: $endDate
  ) {
    student_count
    therapistcount
    sessions
    IncompleteSession
  }
}
    `;

/**
 * __useNewDistrictDashboardsQuery__
 *
 * To run a query within a React component, call `useNewDistrictDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewDistrictDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewDistrictDashboardsQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useNewDistrictDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<NewDistrictDashboardsQuery, NewDistrictDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewDistrictDashboardsQuery, NewDistrictDashboardsQueryVariables>(NewDistrictDashboardsDocument, options);
      }
export function useNewDistrictDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewDistrictDashboardsQuery, NewDistrictDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewDistrictDashboardsQuery, NewDistrictDashboardsQueryVariables>(NewDistrictDashboardsDocument, options);
        }
export type NewDistrictDashboardsQueryHookResult = ReturnType<typeof useNewDistrictDashboardsQuery>;
export type NewDistrictDashboardsLazyQueryHookResult = ReturnType<typeof useNewDistrictDashboardsLazyQuery>;
export type NewDistrictDashboardsQueryResult = Apollo.QueryResult<NewDistrictDashboardsQuery, NewDistrictDashboardsQueryVariables>;
export const IepsDuesDocument = gql`
    query IepsDues($district_id: ID) {
  iepsDues(district_id: $district_id) {
    student_name
  }
}
    `;

/**
 * __useIepsDuesQuery__
 *
 * To run a query within a React component, call `useIepsDuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIepsDuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIepsDuesQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useIepsDuesQuery(baseOptions?: Apollo.QueryHookOptions<IepsDuesQuery, IepsDuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IepsDuesQuery, IepsDuesQueryVariables>(IepsDuesDocument, options);
      }
export function useIepsDuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IepsDuesQuery, IepsDuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IepsDuesQuery, IepsDuesQueryVariables>(IepsDuesDocument, options);
        }
export type IepsDuesQueryHookResult = ReturnType<typeof useIepsDuesQuery>;
export type IepsDuesLazyQueryHookResult = ReturnType<typeof useIepsDuesLazyQuery>;
export type IepsDuesQueryResult = Apollo.QueryResult<IepsDuesQuery, IepsDuesQueryVariables>;
export const StudentAbsensesDocument = gql`
    query StudentAbsenses($district_id: ID, $startDate: String, $endDate: String) {
  studentAbsenses(
    district_id: $district_id
    startDate: $startDate
    endDate: $endDate
  ) {
    name
  }
}
    `;

/**
 * __useStudentAbsensesQuery__
 *
 * To run a query within a React component, call `useStudentAbsensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAbsensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAbsensesQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStudentAbsensesQuery(baseOptions?: Apollo.QueryHookOptions<StudentAbsensesQuery, StudentAbsensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentAbsensesQuery, StudentAbsensesQueryVariables>(StudentAbsensesDocument, options);
      }
export function useStudentAbsensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentAbsensesQuery, StudentAbsensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentAbsensesQuery, StudentAbsensesQueryVariables>(StudentAbsensesDocument, options);
        }
export type StudentAbsensesQueryHookResult = ReturnType<typeof useStudentAbsensesQuery>;
export type StudentAbsensesLazyQueryHookResult = ReturnType<typeof useStudentAbsensesLazyQuery>;
export type StudentAbsensesQueryResult = Apollo.QueryResult<StudentAbsensesQuery, StudentAbsensesQueryVariables>;
export const ProviderCaseloadsDocument = gql`
    query ProviderCaseloads($district_id: ID, $startDate: String, $endDate: String) {
  providerCaseloads(
    district_id: $district_id
    startDate: $startDate
    endDate: $endDate
  ) {
    providers
    caseload
  }
}
    `;

/**
 * __useProviderCaseloadsQuery__
 *
 * To run a query within a React component, call `useProviderCaseloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderCaseloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderCaseloadsQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProviderCaseloadsQuery(baseOptions?: Apollo.QueryHookOptions<ProviderCaseloadsQuery, ProviderCaseloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderCaseloadsQuery, ProviderCaseloadsQueryVariables>(ProviderCaseloadsDocument, options);
      }
export function useProviderCaseloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderCaseloadsQuery, ProviderCaseloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderCaseloadsQuery, ProviderCaseloadsQueryVariables>(ProviderCaseloadsDocument, options);
        }
export type ProviderCaseloadsQueryHookResult = ReturnType<typeof useProviderCaseloadsQuery>;
export type ProviderCaseloadsLazyQueryHookResult = ReturnType<typeof useProviderCaseloadsLazyQuery>;
export type ProviderCaseloadsQueryResult = Apollo.QueryResult<ProviderCaseloadsQuery, ProviderCaseloadsQueryVariables>;
export const CertificationDaysDocument = gql`
    query CertificationDays($district_id: ID) {
  certificationDays(district_id: $district_id) {
    user_name
    therapist_discipline
    difference
  }
}
    `;

/**
 * __useCertificationDaysQuery__
 *
 * To run a query within a React component, call `useCertificationDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationDaysQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useCertificationDaysQuery(baseOptions?: Apollo.QueryHookOptions<CertificationDaysQuery, CertificationDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertificationDaysQuery, CertificationDaysQueryVariables>(CertificationDaysDocument, options);
      }
export function useCertificationDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificationDaysQuery, CertificationDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertificationDaysQuery, CertificationDaysQueryVariables>(CertificationDaysDocument, options);
        }
export type CertificationDaysQueryHookResult = ReturnType<typeof useCertificationDaysQuery>;
export type CertificationDaysLazyQueryHookResult = ReturnType<typeof useCertificationDaysLazyQuery>;
export type CertificationDaysQueryResult = Apollo.QueryResult<CertificationDaysQuery, CertificationDaysQueryVariables>;
export const ProgressBarsDocument = gql`
    query ProgressBars($district_id: ID, $startDate: String, $endDate: String) {
  progressBars(
    district_id: $district_id
    startDate: $startDate
    endDate: $endDate
  ) {
    name
    percentage
  }
}
    `;

/**
 * __useProgressBarsQuery__
 *
 * To run a query within a React component, call `useProgressBarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgressBarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgressBarsQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProgressBarsQuery(baseOptions?: Apollo.QueryHookOptions<ProgressBarsQuery, ProgressBarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgressBarsQuery, ProgressBarsQueryVariables>(ProgressBarsDocument, options);
      }
export function useProgressBarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgressBarsQuery, ProgressBarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgressBarsQuery, ProgressBarsQueryVariables>(ProgressBarsDocument, options);
        }
export type ProgressBarsQueryHookResult = ReturnType<typeof useProgressBarsQuery>;
export type ProgressBarsLazyQueryHookResult = ReturnType<typeof useProgressBarsLazyQuery>;
export type ProgressBarsQueryResult = Apollo.QueryResult<ProgressBarsQuery, ProgressBarsQueryVariables>;
export const AdminDocumentedSessionDocument = gql`
    query AdminDocumentedSession($from_date: String, $to_date: String) {
  DocumentedSession(from_date: $from_date, to_date: $to_date)
}
    `;

/**
 * __useAdminDocumentedSessionQuery__
 *
 * To run a query within a React component, call `useAdminDocumentedSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDocumentedSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDocumentedSessionQuery({
 *   variables: {
 *      from_date: // value for 'from_date'
 *      to_date: // value for 'to_date'
 *   },
 * });
 */
export function useAdminDocumentedSessionQuery(baseOptions?: Apollo.QueryHookOptions<AdminDocumentedSessionQuery, AdminDocumentedSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDocumentedSessionQuery, AdminDocumentedSessionQueryVariables>(AdminDocumentedSessionDocument, options);
      }
export function useAdminDocumentedSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDocumentedSessionQuery, AdminDocumentedSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDocumentedSessionQuery, AdminDocumentedSessionQueryVariables>(AdminDocumentedSessionDocument, options);
        }
export type AdminDocumentedSessionQueryHookResult = ReturnType<typeof useAdminDocumentedSessionQuery>;
export type AdminDocumentedSessionLazyQueryHookResult = ReturnType<typeof useAdminDocumentedSessionLazyQuery>;
export type AdminDocumentedSessionQueryResult = Apollo.QueryResult<AdminDocumentedSessionQuery, AdminDocumentedSessionQueryVariables>;
export const SessionDeliveryDocument = gql`
    query SessionDelivery($from_date: String, $to_date: String) {
  ServiceSession(from_date: $from_date, to_date: $to_date)
}
    `;

/**
 * __useSessionDeliveryQuery__
 *
 * To run a query within a React component, call `useSessionDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionDeliveryQuery({
 *   variables: {
 *      from_date: // value for 'from_date'
 *      to_date: // value for 'to_date'
 *   },
 * });
 */
export function useSessionDeliveryQuery(baseOptions?: Apollo.QueryHookOptions<SessionDeliveryQuery, SessionDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionDeliveryQuery, SessionDeliveryQueryVariables>(SessionDeliveryDocument, options);
      }
export function useSessionDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionDeliveryQuery, SessionDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionDeliveryQuery, SessionDeliveryQueryVariables>(SessionDeliveryDocument, options);
        }
export type SessionDeliveryQueryHookResult = ReturnType<typeof useSessionDeliveryQuery>;
export type SessionDeliveryLazyQueryHookResult = ReturnType<typeof useSessionDeliveryLazyQuery>;
export type SessionDeliveryQueryResult = Apollo.QueryResult<SessionDeliveryQuery, SessionDeliveryQueryVariables>;
export const AppointmentStatusDocument = gql`
    query AppointmentStatus($from_date: String, $to_date: String) {
  Sessionstatus(from_date: $from_date, to_date: $to_date)
}
    `;

/**
 * __useAppointmentStatusQuery__
 *
 * To run a query within a React component, call `useAppointmentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentStatusQuery({
 *   variables: {
 *      from_date: // value for 'from_date'
 *      to_date: // value for 'to_date'
 *   },
 * });
 */
export function useAppointmentStatusQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentStatusQuery, AppointmentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentStatusQuery, AppointmentStatusQueryVariables>(AppointmentStatusDocument, options);
      }
export function useAppointmentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentStatusQuery, AppointmentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentStatusQuery, AppointmentStatusQueryVariables>(AppointmentStatusDocument, options);
        }
export type AppointmentStatusQueryHookResult = ReturnType<typeof useAppointmentStatusQuery>;
export type AppointmentStatusLazyQueryHookResult = ReturnType<typeof useAppointmentStatusLazyQuery>;
export type AppointmentStatusQueryResult = Apollo.QueryResult<AppointmentStatusQuery, AppointmentStatusQueryVariables>;
export const WaitlistAssignmentsDocument = gql`
    query WaitlistAssignments {
  wailistassignment
}
    `;

/**
 * __useWaitlistAssignmentsQuery__
 *
 * To run a query within a React component, call `useWaitlistAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWaitlistAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<WaitlistAssignmentsQuery, WaitlistAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaitlistAssignmentsQuery, WaitlistAssignmentsQueryVariables>(WaitlistAssignmentsDocument, options);
      }
export function useWaitlistAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaitlistAssignmentsQuery, WaitlistAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaitlistAssignmentsQuery, WaitlistAssignmentsQueryVariables>(WaitlistAssignmentsDocument, options);
        }
export type WaitlistAssignmentsQueryHookResult = ReturnType<typeof useWaitlistAssignmentsQuery>;
export type WaitlistAssignmentsLazyQueryHookResult = ReturnType<typeof useWaitlistAssignmentsLazyQuery>;
export type WaitlistAssignmentsQueryResult = Apollo.QueryResult<WaitlistAssignmentsQuery, WaitlistAssignmentsQueryVariables>;
export const OngoingcareAssignmentsDocument = gql`
    query OngoingcareAssignments {
  ongoingcarecount
}
    `;

/**
 * __useOngoingcareAssignmentsQuery__
 *
 * To run a query within a React component, call `useOngoingcareAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingcareAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingcareAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOngoingcareAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<OngoingcareAssignmentsQuery, OngoingcareAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OngoingcareAssignmentsQuery, OngoingcareAssignmentsQueryVariables>(OngoingcareAssignmentsDocument, options);
      }
export function useOngoingcareAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingcareAssignmentsQuery, OngoingcareAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OngoingcareAssignmentsQuery, OngoingcareAssignmentsQueryVariables>(OngoingcareAssignmentsDocument, options);
        }
export type OngoingcareAssignmentsQueryHookResult = ReturnType<typeof useOngoingcareAssignmentsQuery>;
export type OngoingcareAssignmentsLazyQueryHookResult = ReturnType<typeof useOngoingcareAssignmentsLazyQuery>;
export type OngoingcareAssignmentsQueryResult = Apollo.QueryResult<OngoingcareAssignmentsQuery, OngoingcareAssignmentsQueryVariables>;
export const MandatoryDiscluserDelDocument = gql`
    query MandatoryDiscluserDel {
  Mandatorystatus
}
    `;

/**
 * __useMandatoryDiscluserDelQuery__
 *
 * To run a query within a React component, call `useMandatoryDiscluserDelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatoryDiscluserDelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatoryDiscluserDelQuery({
 *   variables: {
 *   },
 * });
 */
export function useMandatoryDiscluserDelQuery(baseOptions?: Apollo.QueryHookOptions<MandatoryDiscluserDelQuery, MandatoryDiscluserDelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatoryDiscluserDelQuery, MandatoryDiscluserDelQueryVariables>(MandatoryDiscluserDelDocument, options);
      }
export function useMandatoryDiscluserDelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatoryDiscluserDelQuery, MandatoryDiscluserDelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatoryDiscluserDelQuery, MandatoryDiscluserDelQueryVariables>(MandatoryDiscluserDelDocument, options);
        }
export type MandatoryDiscluserDelQueryHookResult = ReturnType<typeof useMandatoryDiscluserDelQuery>;
export type MandatoryDiscluserDelLazyQueryHookResult = ReturnType<typeof useMandatoryDiscluserDelLazyQuery>;
export type MandatoryDiscluserDelQueryResult = Apollo.QueryResult<MandatoryDiscluserDelQuery, MandatoryDiscluserDelQueryVariables>;
export const AdminCountsDocument = gql`
    query AdminCounts($user_id: String) {
  adimncount(user_id: $user_id)
}
    `;

/**
 * __useAdminCountsQuery__
 *
 * To run a query within a React component, call `useAdminCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAdminCountsQuery(baseOptions?: Apollo.QueryHookOptions<AdminCountsQuery, AdminCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountsQuery, AdminCountsQueryVariables>(AdminCountsDocument, options);
      }
export function useAdminCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountsQuery, AdminCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountsQuery, AdminCountsQueryVariables>(AdminCountsDocument, options);
        }
export type AdminCountsQueryHookResult = ReturnType<typeof useAdminCountsQuery>;
export type AdminCountsLazyQueryHookResult = ReturnType<typeof useAdminCountsLazyQuery>;
export type AdminCountsQueryResult = Apollo.QueryResult<AdminCountsQuery, AdminCountsQueryVariables>;
export const ActivityfeedDocument = gql`
    query Activityfeed($roles: String, $users: String, $from_date: String, $to_date: String) {
  Activityfeed(
    roles: $roles
    users: $users
    from_date: $from_date
    to_date: $to_date
  ) {
    id
    notes
    roles
    users
    created_by
    updated_by
    hours
  }
}
    `;

/**
 * __useActivityfeedQuery__
 *
 * To run a query within a React component, call `useActivityfeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityfeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityfeedQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      users: // value for 'users'
 *      from_date: // value for 'from_date'
 *      to_date: // value for 'to_date'
 *   },
 * });
 */
export function useActivityfeedQuery(baseOptions?: Apollo.QueryHookOptions<ActivityfeedQuery, ActivityfeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityfeedQuery, ActivityfeedQueryVariables>(ActivityfeedDocument, options);
      }
export function useActivityfeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityfeedQuery, ActivityfeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityfeedQuery, ActivityfeedQueryVariables>(ActivityfeedDocument, options);
        }
export type ActivityfeedQueryHookResult = ReturnType<typeof useActivityfeedQuery>;
export type ActivityfeedLazyQueryHookResult = ReturnType<typeof useActivityfeedLazyQuery>;
export type ActivityfeedQueryResult = Apollo.QueryResult<ActivityfeedQuery, ActivityfeedQueryVariables>;
export const TherapistMetricsDocument = gql`
    query TherapistMetrics {
  metrics {
    undocumentedAppointments
    assignedEvaluations
    assignedScreenings
    numberOfCaseloads
    nextDueIep
    timeOwed {
      minutes
      studentsCount
    }
  }
}
    `;

/**
 * __useTherapistMetricsQuery__
 *
 * To run a query within a React component, call `useTherapistMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTherapistMetricsQuery(baseOptions?: Apollo.QueryHookOptions<TherapistMetricsQuery, TherapistMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistMetricsQuery, TherapistMetricsQueryVariables>(TherapistMetricsDocument, options);
      }
export function useTherapistMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistMetricsQuery, TherapistMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistMetricsQuery, TherapistMetricsQueryVariables>(TherapistMetricsDocument, options);
        }
export type TherapistMetricsQueryHookResult = ReturnType<typeof useTherapistMetricsQuery>;
export type TherapistMetricsLazyQueryHookResult = ReturnType<typeof useTherapistMetricsLazyQuery>;
export type TherapistMetricsQueryResult = Apollo.QueryResult<TherapistMetricsQuery, TherapistMetricsQueryVariables>;
export const TherapistIepsDocument = gql`
    query TherapistIeps($user_id: String) {
  therapistIeps(user_id: $user_id) {
    student_name
  }
}
    `;

/**
 * __useTherapistIepsQuery__
 *
 * To run a query within a React component, call `useTherapistIepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistIepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistIepsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useTherapistIepsQuery(baseOptions?: Apollo.QueryHookOptions<TherapistIepsQuery, TherapistIepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistIepsQuery, TherapistIepsQueryVariables>(TherapistIepsDocument, options);
      }
export function useTherapistIepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistIepsQuery, TherapistIepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistIepsQuery, TherapistIepsQueryVariables>(TherapistIepsDocument, options);
        }
export type TherapistIepsQueryHookResult = ReturnType<typeof useTherapistIepsQuery>;
export type TherapistIepsLazyQueryHookResult = ReturnType<typeof useTherapistIepsLazyQuery>;
export type TherapistIepsQueryResult = Apollo.QueryResult<TherapistIepsQuery, TherapistIepsQueryVariables>;
export const TherapistCaseloadsDocument = gql`
    query TherapistCaseloads($user_id: String, $startDate: String, $endDate: String) {
  therapistCaseloads(user_id: $user_id, startDate: $startDate, endDate: $endDate) {
    caseload_count
  }
}
    `;

/**
 * __useTherapistCaseloadsQuery__
 *
 * To run a query within a React component, call `useTherapistCaseloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistCaseloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistCaseloadsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTherapistCaseloadsQuery(baseOptions?: Apollo.QueryHookOptions<TherapistCaseloadsQuery, TherapistCaseloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistCaseloadsQuery, TherapistCaseloadsQueryVariables>(TherapistCaseloadsDocument, options);
      }
export function useTherapistCaseloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistCaseloadsQuery, TherapistCaseloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistCaseloadsQuery, TherapistCaseloadsQueryVariables>(TherapistCaseloadsDocument, options);
        }
export type TherapistCaseloadsQueryHookResult = ReturnType<typeof useTherapistCaseloadsQuery>;
export type TherapistCaseloadsLazyQueryHookResult = ReturnType<typeof useTherapistCaseloadsLazyQuery>;
export type TherapistCaseloadsQueryResult = Apollo.QueryResult<TherapistCaseloadsQuery, TherapistCaseloadsQueryVariables>;
export const TherapistminutesDocument = gql`
    query Therapistminutes($user_id: String, $startDate: String, $endDate: String) {
  therapistminutes(user_id: $user_id, startDate: $startDate, endDate: $endDate) {
    minutes
  }
}
    `;

/**
 * __useTherapistminutesQuery__
 *
 * To run a query within a React component, call `useTherapistminutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistminutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistminutesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTherapistminutesQuery(baseOptions?: Apollo.QueryHookOptions<TherapistminutesQuery, TherapistminutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistminutesQuery, TherapistminutesQueryVariables>(TherapistminutesDocument, options);
      }
export function useTherapistminutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistminutesQuery, TherapistminutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistminutesQuery, TherapistminutesQueryVariables>(TherapistminutesDocument, options);
        }
export type TherapistminutesQueryHookResult = ReturnType<typeof useTherapistminutesQuery>;
export type TherapistminutesLazyQueryHookResult = ReturnType<typeof useTherapistminutesLazyQuery>;
export type TherapistminutesQueryResult = Apollo.QueryResult<TherapistminutesQuery, TherapistminutesQueryVariables>;
export const TherapistabsencesDocument = gql`
    query Therapistabsences($user_id: String, $startDate: String, $endDate: String) {
  therapistabsences(user_id: $user_id, startDate: $startDate, endDate: $endDate) {
    name
  }
}
    `;

/**
 * __useTherapistabsencesQuery__
 *
 * To run a query within a React component, call `useTherapistabsencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistabsencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistabsencesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTherapistabsencesQuery(baseOptions?: Apollo.QueryHookOptions<TherapistabsencesQuery, TherapistabsencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistabsencesQuery, TherapistabsencesQueryVariables>(TherapistabsencesDocument, options);
      }
export function useTherapistabsencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistabsencesQuery, TherapistabsencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistabsencesQuery, TherapistabsencesQueryVariables>(TherapistabsencesDocument, options);
        }
export type TherapistabsencesQueryHookResult = ReturnType<typeof useTherapistabsencesQuery>;
export type TherapistabsencesLazyQueryHookResult = ReturnType<typeof useTherapistabsencesLazyQuery>;
export type TherapistabsencesQueryResult = Apollo.QueryResult<TherapistabsencesQuery, TherapistabsencesQueryVariables>;
export const UnDocumentedSessionsDocument = gql`
    query UnDocumentedSessions($user_id: String, $startDate: String, $endDate: String) {
  unDocumentedSessions(
    user_id: $user_id
    startDate: $startDate
    endDate: $endDate
  ) {
    unDocumentedSession
  }
}
    `;

/**
 * __useUnDocumentedSessionsQuery__
 *
 * To run a query within a React component, call `useUnDocumentedSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnDocumentedSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnDocumentedSessionsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUnDocumentedSessionsQuery(baseOptions?: Apollo.QueryHookOptions<UnDocumentedSessionsQuery, UnDocumentedSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnDocumentedSessionsQuery, UnDocumentedSessionsQueryVariables>(UnDocumentedSessionsDocument, options);
      }
export function useUnDocumentedSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnDocumentedSessionsQuery, UnDocumentedSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnDocumentedSessionsQuery, UnDocumentedSessionsQueryVariables>(UnDocumentedSessionsDocument, options);
        }
export type UnDocumentedSessionsQueryHookResult = ReturnType<typeof useUnDocumentedSessionsQuery>;
export type UnDocumentedSessionsLazyQueryHookResult = ReturnType<typeof useUnDocumentedSessionsLazyQuery>;
export type UnDocumentedSessionsQueryResult = Apollo.QueryResult<UnDocumentedSessionsQuery, UnDocumentedSessionsQueryVariables>;
export const TherapistNewMetricsDocument = gql`
    query TherapistNewMetrics($user_id: String) {
  Datacount(user_id: $user_id)
}
    `;

/**
 * __useTherapistNewMetricsQuery__
 *
 * To run a query within a React component, call `useTherapistNewMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistNewMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistNewMetricsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useTherapistNewMetricsQuery(baseOptions?: Apollo.QueryHookOptions<TherapistNewMetricsQuery, TherapistNewMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistNewMetricsQuery, TherapistNewMetricsQueryVariables>(TherapistNewMetricsDocument, options);
      }
export function useTherapistNewMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistNewMetricsQuery, TherapistNewMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistNewMetricsQuery, TherapistNewMetricsQueryVariables>(TherapistNewMetricsDocument, options);
        }
export type TherapistNewMetricsQueryHookResult = ReturnType<typeof useTherapistNewMetricsQuery>;
export type TherapistNewMetricsLazyQueryHookResult = ReturnType<typeof useTherapistNewMetricsLazyQuery>;
export type TherapistNewMetricsQueryResult = Apollo.QueryResult<TherapistNewMetricsQuery, TherapistNewMetricsQueryVariables>;
export const DashboardRequestsDocument = gql`
    query DashboardRequests($user_id: String) {
  ongoingrequest(user_id: $user_id)
}
    `;

/**
 * __useDashboardRequestsQuery__
 *
 * To run a query within a React component, call `useDashboardRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardRequestsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDashboardRequestsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardRequestsQuery, DashboardRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardRequestsQuery, DashboardRequestsQueryVariables>(DashboardRequestsDocument, options);
      }
export function useDashboardRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardRequestsQuery, DashboardRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardRequestsQuery, DashboardRequestsQueryVariables>(DashboardRequestsDocument, options);
        }
export type DashboardRequestsQueryHookResult = ReturnType<typeof useDashboardRequestsQuery>;
export type DashboardRequestsLazyQueryHookResult = ReturnType<typeof useDashboardRequestsLazyQuery>;
export type DashboardRequestsQueryResult = Apollo.QueryResult<DashboardRequestsQuery, DashboardRequestsQueryVariables>;
export const SlotsAvailableDocument = gql`
    query SlotsAvailable($user_id: String) {
  Availableslots(user_id: $user_id)
}
    `;

/**
 * __useSlotsAvailableQuery__
 *
 * To run a query within a React component, call `useSlotsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlotsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlotsAvailableQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useSlotsAvailableQuery(baseOptions?: Apollo.QueryHookOptions<SlotsAvailableQuery, SlotsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlotsAvailableQuery, SlotsAvailableQueryVariables>(SlotsAvailableDocument, options);
      }
export function useSlotsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlotsAvailableQuery, SlotsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlotsAvailableQuery, SlotsAvailableQueryVariables>(SlotsAvailableDocument, options);
        }
export type SlotsAvailableQueryHookResult = ReturnType<typeof useSlotsAvailableQuery>;
export type SlotsAvailableLazyQueryHookResult = ReturnType<typeof useSlotsAvailableLazyQuery>;
export type SlotsAvailableQueryResult = Apollo.QueryResult<SlotsAvailableQuery, SlotsAvailableQueryVariables>;
export const StartInstantMessagingDocument = gql`
    mutation StartInstantMessaging($chatAuthToken: String!, $email: String!) {
  startInstantMessaging(chatAuthToken: $chatAuthToken, email: $email) {
    id
    user {
      id
      email
      first_name
      last_name
      name
    }
    messagesCount
  }
}
    `;
export type StartInstantMessagingMutationFn = Apollo.MutationFunction<StartInstantMessagingMutation, StartInstantMessagingMutationVariables>;

/**
 * __useStartInstantMessagingMutation__
 *
 * To run a mutation, you first call `useStartInstantMessagingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartInstantMessagingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startInstantMessagingMutation, { data, loading, error }] = useStartInstantMessagingMutation({
 *   variables: {
 *      chatAuthToken: // value for 'chatAuthToken'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStartInstantMessagingMutation(baseOptions?: Apollo.MutationHookOptions<StartInstantMessagingMutation, StartInstantMessagingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartInstantMessagingMutation, StartInstantMessagingMutationVariables>(StartInstantMessagingDocument, options);
      }
export type StartInstantMessagingMutationHookResult = ReturnType<typeof useStartInstantMessagingMutation>;
export type StartInstantMessagingMutationResult = Apollo.MutationResult<StartInstantMessagingMutation>;
export type StartInstantMessagingMutationOptions = Apollo.BaseMutationOptions<StartInstantMessagingMutation, StartInstantMessagingMutationVariables>;
export const CreateAgencyDocument = gql`
    mutation CreateAgency($input: AgencyInput!) {
  createAgency(input: $input) {
    info
  }
}
    `;
export type CreateAgencyMutationFn = Apollo.MutationFunction<CreateAgencyMutation, CreateAgencyMutationVariables>;

/**
 * __useCreateAgencyMutation__
 *
 * To run a mutation, you first call `useCreateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgencyMutation, { data, loading, error }] = useCreateAgencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgencyMutation, CreateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgencyMutation, CreateAgencyMutationVariables>(CreateAgencyDocument, options);
      }
export type CreateAgencyMutationHookResult = ReturnType<typeof useCreateAgencyMutation>;
export type CreateAgencyMutationResult = Apollo.MutationResult<CreateAgencyMutation>;
export type CreateAgencyMutationOptions = Apollo.BaseMutationOptions<CreateAgencyMutation, CreateAgencyMutationVariables>;
export const ListAgencyDocument = gql`
    query ListAgency {
  agencyList {
    id
    name
    address
    status
    city
    state
    zipcode
    restriction
  }
}
    `;

/**
 * __useListAgencyQuery__
 *
 * To run a query within a React component, call `useListAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAgencyQuery(baseOptions?: Apollo.QueryHookOptions<ListAgencyQuery, ListAgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgencyQuery, ListAgencyQueryVariables>(ListAgencyDocument, options);
      }
export function useListAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgencyQuery, ListAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgencyQuery, ListAgencyQueryVariables>(ListAgencyDocument, options);
        }
export type ListAgencyQueryHookResult = ReturnType<typeof useListAgencyQuery>;
export type ListAgencyLazyQueryHookResult = ReturnType<typeof useListAgencyLazyQuery>;
export type ListAgencyQueryResult = Apollo.QueryResult<ListAgencyQuery, ListAgencyQueryVariables>;
export const DeleteAgencyDocument = gql`
    mutation DeleteAgency($id: Int!) {
  deleteAgency(id: $id)
}
    `;
export type DeleteAgencyMutationFn = Apollo.MutationFunction<DeleteAgencyMutation, DeleteAgencyMutationVariables>;

/**
 * __useDeleteAgencyMutation__
 *
 * To run a mutation, you first call `useDeleteAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgencyMutation, { data, loading, error }] = useDeleteAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAgencyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAgencyMutation, DeleteAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAgencyMutation, DeleteAgencyMutationVariables>(DeleteAgencyDocument, options);
      }
export type DeleteAgencyMutationHookResult = ReturnType<typeof useDeleteAgencyMutation>;
export type DeleteAgencyMutationResult = Apollo.MutationResult<DeleteAgencyMutation>;
export type DeleteAgencyMutationOptions = Apollo.BaseMutationOptions<DeleteAgencyMutation, DeleteAgencyMutationVariables>;
export const UpdateAgencyDocument = gql`
    mutation UpdateAgency($id: Int!, $input: AgencyInput!) {
  updateAgency(id: $id, input: $input)
}
    `;
export type UpdateAgencyMutationFn = Apollo.MutationFunction<UpdateAgencyMutation, UpdateAgencyMutationVariables>;

/**
 * __useUpdateAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyMutation, { data, loading, error }] = useUpdateAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgencyMutation, UpdateAgencyMutationVariables>(UpdateAgencyDocument, options);
      }
export type UpdateAgencyMutationHookResult = ReturnType<typeof useUpdateAgencyMutation>;
export type UpdateAgencyMutationResult = Apollo.MutationResult<UpdateAgencyMutation>;
export type UpdateAgencyMutationOptions = Apollo.BaseMutationOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>;
export const AgencyDocument = gql`
    query Agency($id: Int) {
  agency(id: $id) {
    name
    address
    city
    state
    zipcode
    restriction
  }
}
    `;

/**
 * __useAgencyQuery__
 *
 * To run a query within a React component, call `useAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgencyQuery(baseOptions?: Apollo.QueryHookOptions<AgencyQuery, AgencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyQuery, AgencyQueryVariables>(AgencyDocument, options);
      }
export function useAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyQuery, AgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyQuery, AgencyQueryVariables>(AgencyDocument, options);
        }
export type AgencyQueryHookResult = ReturnType<typeof useAgencyQuery>;
export type AgencyLazyQueryHookResult = ReturnType<typeof useAgencyLazyQuery>;
export type AgencyQueryResult = Apollo.QueryResult<AgencyQuery, AgencyQueryVariables>;
export const AppointmentsDocument = gql`
    query Appointments($student_id: Int) {
  appointments(student_id: $student_id) {
    id
    provider_name
    student_name
    student_district
    started_at
    ended_at
    icd_code
  }
}
    `;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const ListBlackoutDayDocument = gql`
    query ListBlackoutDay($id: String) {
  blackoutDay(id: $id) {
    id
    title
    start_date
    end_date
    day_portion
  }
}
    `;

/**
 * __useListBlackoutDayQuery__
 *
 * To run a query within a React component, call `useListBlackoutDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlackoutDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlackoutDayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListBlackoutDayQuery(baseOptions?: Apollo.QueryHookOptions<ListBlackoutDayQuery, ListBlackoutDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBlackoutDayQuery, ListBlackoutDayQueryVariables>(ListBlackoutDayDocument, options);
      }
export function useListBlackoutDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBlackoutDayQuery, ListBlackoutDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBlackoutDayQuery, ListBlackoutDayQueryVariables>(ListBlackoutDayDocument, options);
        }
export type ListBlackoutDayQueryHookResult = ReturnType<typeof useListBlackoutDayQuery>;
export type ListBlackoutDayLazyQueryHookResult = ReturnType<typeof useListBlackoutDayLazyQuery>;
export type ListBlackoutDayQueryResult = Apollo.QueryResult<ListBlackoutDayQuery, ListBlackoutDayQueryVariables>;
export const CreateBlackoutDaysDocument = gql`
    mutation CreateBlackoutDays($input: BlackoutDaysInput!) {
  createBlackoutDays(input: $input) {
    appointment_type_id {
      id
    }
    user_id {
      id
    }
    district_id {
      id
    }
    title
    service_id {
      id
    }
    start_date
    end_date
    created_by
    updated_by
    day_portion
  }
}
    `;
export type CreateBlackoutDaysMutationFn = Apollo.MutationFunction<CreateBlackoutDaysMutation, CreateBlackoutDaysMutationVariables>;

/**
 * __useCreateBlackoutDaysMutation__
 *
 * To run a mutation, you first call `useCreateBlackoutDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlackoutDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlackoutDaysMutation, { data, loading, error }] = useCreateBlackoutDaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlackoutDaysMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlackoutDaysMutation, CreateBlackoutDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlackoutDaysMutation, CreateBlackoutDaysMutationVariables>(CreateBlackoutDaysDocument, options);
      }
export type CreateBlackoutDaysMutationHookResult = ReturnType<typeof useCreateBlackoutDaysMutation>;
export type CreateBlackoutDaysMutationResult = Apollo.MutationResult<CreateBlackoutDaysMutation>;
export type CreateBlackoutDaysMutationOptions = Apollo.BaseMutationOptions<CreateBlackoutDaysMutation, CreateBlackoutDaysMutationVariables>;
export const DeleteBlackoutDaysDocument = gql`
    mutation DeleteBlackoutDays($id: ID!) {
  deleteBlackoutDays(id: $id)
}
    `;
export type DeleteBlackoutDaysMutationFn = Apollo.MutationFunction<DeleteBlackoutDaysMutation, DeleteBlackoutDaysMutationVariables>;

/**
 * __useDeleteBlackoutDaysMutation__
 *
 * To run a mutation, you first call `useDeleteBlackoutDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlackoutDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlackoutDaysMutation, { data, loading, error }] = useDeleteBlackoutDaysMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlackoutDaysMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlackoutDaysMutation, DeleteBlackoutDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlackoutDaysMutation, DeleteBlackoutDaysMutationVariables>(DeleteBlackoutDaysDocument, options);
      }
export type DeleteBlackoutDaysMutationHookResult = ReturnType<typeof useDeleteBlackoutDaysMutation>;
export type DeleteBlackoutDaysMutationResult = Apollo.MutationResult<DeleteBlackoutDaysMutation>;
export type DeleteBlackoutDaysMutationOptions = Apollo.BaseMutationOptions<DeleteBlackoutDaysMutation, DeleteBlackoutDaysMutationVariables>;
export const ListBlackoutDaysDocument = gql`
    query ListBlackoutDays($user_id: String, $appointment_type_id: ID) {
  blackoutDays(user_id: $user_id, appointment_type_id: $appointment_type_id) {
    id
    appointment_type_id {
      id
    }
    user_id {
      id
    }
    district_id {
      id
    }
    title
    service_id {
      id
    }
    start_date
    end_date
    created_by
    updated_by
    day_portion
  }
}
    `;

/**
 * __useListBlackoutDaysQuery__
 *
 * To run a query within a React component, call `useListBlackoutDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlackoutDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlackoutDaysQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      appointment_type_id: // value for 'appointment_type_id'
 *   },
 * });
 */
export function useListBlackoutDaysQuery(baseOptions?: Apollo.QueryHookOptions<ListBlackoutDaysQuery, ListBlackoutDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBlackoutDaysQuery, ListBlackoutDaysQueryVariables>(ListBlackoutDaysDocument, options);
      }
export function useListBlackoutDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBlackoutDaysQuery, ListBlackoutDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBlackoutDaysQuery, ListBlackoutDaysQueryVariables>(ListBlackoutDaysDocument, options);
        }
export type ListBlackoutDaysQueryHookResult = ReturnType<typeof useListBlackoutDaysQuery>;
export type ListBlackoutDaysLazyQueryHookResult = ReturnType<typeof useListBlackoutDaysLazyQuery>;
export type ListBlackoutDaysQueryResult = Apollo.QueryResult<ListBlackoutDaysQuery, ListBlackoutDaysQueryVariables>;
export const UpdateBlackoutDaysDocument = gql`
    mutation UpdateBlackoutDays($id: ID!, $input: BlackoutDaysInput!) {
  updateBlackoutDays(id: $id, input: $input)
}
    `;
export type UpdateBlackoutDaysMutationFn = Apollo.MutationFunction<UpdateBlackoutDaysMutation, UpdateBlackoutDaysMutationVariables>;

/**
 * __useUpdateBlackoutDaysMutation__
 *
 * To run a mutation, you first call `useUpdateBlackoutDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlackoutDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlackoutDaysMutation, { data, loading, error }] = useUpdateBlackoutDaysMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlackoutDaysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlackoutDaysMutation, UpdateBlackoutDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlackoutDaysMutation, UpdateBlackoutDaysMutationVariables>(UpdateBlackoutDaysDocument, options);
      }
export type UpdateBlackoutDaysMutationHookResult = ReturnType<typeof useUpdateBlackoutDaysMutation>;
export type UpdateBlackoutDaysMutationResult = Apollo.MutationResult<UpdateBlackoutDaysMutation>;
export type UpdateBlackoutDaysMutationOptions = Apollo.BaseMutationOptions<UpdateBlackoutDaysMutation, UpdateBlackoutDaysMutationVariables>;
export const ListUserDistrictsDocument = gql`
    query ListUserDistricts($user_id: String, $district_id: Int) {
  userDistricts(user_id: $user_id, district_id: $district_id) {
    user_id {
      id
      district {
        id
        name
      }
      newUserAgeGroups {
        id
        user_id
        min_age
        max_age
        min_age_list
        max_age_list
      }
      email
      description
      first_name
      last_name
      gender
      middle_initial
      maiden_name
      roles
      student_id {
        id
        name
      }
      city
      zip
      status
      telemedicine_enabled
      telemedicine_trained_at
      mobile_phone
      home_phone
      therapist_discipline
      state_license {
        id
        name
        abbreviation
      }
      street_address
      license_number
      cert_date
      cert_expire_date
      signal_service_id {
        id
        name
      }
      ethinic_group_id {
        name
      }
      degree_held
      school_attended
      specialized_training
      supervisor_name_and_credentials
      signalUserExperience {
        experience
      }
    }
    primary
    district_id
    alt_id
    hourly_rate
    status
    add_student_permission
  }
}
    `;

/**
 * __useListUserDistrictsQuery__
 *
 * To run a query within a React component, call `useListUserDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDistrictsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useListUserDistrictsQuery(baseOptions?: Apollo.QueryHookOptions<ListUserDistrictsQuery, ListUserDistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserDistrictsQuery, ListUserDistrictsQueryVariables>(ListUserDistrictsDocument, options);
      }
export function useListUserDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserDistrictsQuery, ListUserDistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserDistrictsQuery, ListUserDistrictsQueryVariables>(ListUserDistrictsDocument, options);
        }
export type ListUserDistrictsQueryHookResult = ReturnType<typeof useListUserDistrictsQuery>;
export type ListUserDistrictsLazyQueryHookResult = ReturnType<typeof useListUserDistrictsLazyQuery>;
export type ListUserDistrictsQueryResult = Apollo.QueryResult<ListUserDistrictsQuery, ListUserDistrictsQueryVariables>;
export const UserListDistrictsForCaseloadDocument = gql`
    query UserListDistrictsForCaseload($user_id: String!) {
  userListDistrictsForCaseload(user_id: $user_id) {
    district_id
    alt_id
    hourly_rate
    status
    add_student_permission
    primary
  }
}
    `;

/**
 * __useUserListDistrictsForCaseloadQuery__
 *
 * To run a query within a React component, call `useUserListDistrictsForCaseloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListDistrictsForCaseloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListDistrictsForCaseloadQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserListDistrictsForCaseloadQuery(baseOptions: Apollo.QueryHookOptions<UserListDistrictsForCaseloadQuery, UserListDistrictsForCaseloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListDistrictsForCaseloadQuery, UserListDistrictsForCaseloadQueryVariables>(UserListDistrictsForCaseloadDocument, options);
      }
export function useUserListDistrictsForCaseloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListDistrictsForCaseloadQuery, UserListDistrictsForCaseloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListDistrictsForCaseloadQuery, UserListDistrictsForCaseloadQueryVariables>(UserListDistrictsForCaseloadDocument, options);
        }
export type UserListDistrictsForCaseloadQueryHookResult = ReturnType<typeof useUserListDistrictsForCaseloadQuery>;
export type UserListDistrictsForCaseloadLazyQueryHookResult = ReturnType<typeof useUserListDistrictsForCaseloadLazyQuery>;
export type UserListDistrictsForCaseloadQueryResult = Apollo.QueryResult<UserListDistrictsForCaseloadQuery, UserListDistrictsForCaseloadQueryVariables>;
export const ListCaseloadDocument = gql`
    query ListCaseload($user_id: String) {
  caseload(user_id: $user_id) {
    id
    iep_minutes
    minutes_per
    frequency
    updated_by
    billing_code
    user_id {
      id
      first_name
      last_name
    }
    students {
      id
      name
      unique_profile_id
      contact_email
      re_evl_date
      birth_date
      contact_phone_number
      district {
        name
      }
      primaryLanguage {
        name
      }
      parent1_phone_number
      parent2_phone_number
      parent1_email
      parent2_email
      insurance_company {
        name
      }
      student_specialities {
        speciality_id {
          id
          name
        }
      }
    }
    planned_sessions
    notes
    status
    private_notes
    sessions_count
  }
}
    `;

/**
 * __useListCaseloadQuery__
 *
 * To run a query within a React component, call `useListCaseloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCaseloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCaseloadQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListCaseloadQuery(baseOptions?: Apollo.QueryHookOptions<ListCaseloadQuery, ListCaseloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCaseloadQuery, ListCaseloadQueryVariables>(ListCaseloadDocument, options);
      }
export function useListCaseloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCaseloadQuery, ListCaseloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCaseloadQuery, ListCaseloadQueryVariables>(ListCaseloadDocument, options);
        }
export type ListCaseloadQueryHookResult = ReturnType<typeof useListCaseloadQuery>;
export type ListCaseloadLazyQueryHookResult = ReturnType<typeof useListCaseloadLazyQuery>;
export type ListCaseloadQueryResult = Apollo.QueryResult<ListCaseloadQuery, ListCaseloadQueryVariables>;
export const RemoveClientDocument = gql`
    mutation removeClient($user_id: String, $student_id: Int) {
  removeClient(user_id: $user_id, student_id: $student_id)
}
    `;
export type RemoveClientMutationFn = Apollo.MutationFunction<RemoveClientMutation, RemoveClientMutationVariables>;

/**
 * __useRemoveClientMutation__
 *
 * To run a mutation, you first call `useRemoveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientMutation, { data, loading, error }] = useRemoveClientMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useRemoveClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientMutation, RemoveClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientMutation, RemoveClientMutationVariables>(RemoveClientDocument, options);
      }
export type RemoveClientMutationHookResult = ReturnType<typeof useRemoveClientMutation>;
export type RemoveClientMutationResult = Apollo.MutationResult<RemoveClientMutation>;
export type RemoveClientMutationOptions = Apollo.BaseMutationOptions<RemoveClientMutation, RemoveClientMutationVariables>;
export const AddClientDocument = gql`
    mutation addClient($user_id: String, $student_id: Int) {
  addClient(user_id: $user_id, student_id: $student_id)
}
    `;
export type AddClientMutationFn = Apollo.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;
export const DeleteCaseloadDocument = gql`
    mutation DeleteCaseload($id: ID!) {
  deleteCaseload(id: $id)
}
    `;
export type DeleteCaseloadMutationFn = Apollo.MutationFunction<DeleteCaseloadMutation, DeleteCaseloadMutationVariables>;

/**
 * __useDeleteCaseloadMutation__
 *
 * To run a mutation, you first call `useDeleteCaseloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaseloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseloadMutation, { data, loading, error }] = useDeleteCaseloadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCaseloadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCaseloadMutation, DeleteCaseloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCaseloadMutation, DeleteCaseloadMutationVariables>(DeleteCaseloadDocument, options);
      }
export type DeleteCaseloadMutationHookResult = ReturnType<typeof useDeleteCaseloadMutation>;
export type DeleteCaseloadMutationResult = Apollo.MutationResult<DeleteCaseloadMutation>;
export type DeleteCaseloadMutationOptions = Apollo.BaseMutationOptions<DeleteCaseloadMutation, DeleteCaseloadMutationVariables>;
export const ListStudentCaseloadDocument = gql`
    query ListStudentCaseload($id: Int!) {
  student(id: $id) {
    id
    name
    student_id
    first_name
    last_name
    district {
      id
      name
    }
    school {
      id
      name
    }
    icdCode {
      id
      code
    }
    grade {
      id
      grade
    }
    primaryLanguage {
      id
      name
    }
    contact_phone_number
    contact_email
    birth_date
    gender
    iep_date
    re_evl_date
    status
    parent1_phone_number
    parent2_phone_number
    parent1_email
    parent2_email
    accommodations
    parent_name
    psc_score
    sdoh_score
    craft_score
  }
}
    `;

/**
 * __useListStudentCaseloadQuery__
 *
 * To run a query within a React component, call `useListStudentCaseloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStudentCaseloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStudentCaseloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListStudentCaseloadQuery(baseOptions: Apollo.QueryHookOptions<ListStudentCaseloadQuery, ListStudentCaseloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStudentCaseloadQuery, ListStudentCaseloadQueryVariables>(ListStudentCaseloadDocument, options);
      }
export function useListStudentCaseloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStudentCaseloadQuery, ListStudentCaseloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStudentCaseloadQuery, ListStudentCaseloadQueryVariables>(ListStudentCaseloadDocument, options);
        }
export type ListStudentCaseloadQueryHookResult = ReturnType<typeof useListStudentCaseloadQuery>;
export type ListStudentCaseloadLazyQueryHookResult = ReturnType<typeof useListStudentCaseloadLazyQuery>;
export type ListStudentCaseloadQueryResult = Apollo.QueryResult<ListStudentCaseloadQuery, ListStudentCaseloadQueryVariables>;
export const ListsurveystudentDocument = gql`
    query Listsurveystudent($sid: Int) {
  surveystudent(sid: $sid)
}
    `;

/**
 * __useListsurveystudentQuery__
 *
 * To run a query within a React component, call `useListsurveystudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsurveystudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsurveystudentQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useListsurveystudentQuery(baseOptions?: Apollo.QueryHookOptions<ListsurveystudentQuery, ListsurveystudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListsurveystudentQuery, ListsurveystudentQueryVariables>(ListsurveystudentDocument, options);
      }
export function useListsurveystudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsurveystudentQuery, ListsurveystudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListsurveystudentQuery, ListsurveystudentQueryVariables>(ListsurveystudentDocument, options);
        }
export type ListsurveystudentQueryHookResult = ReturnType<typeof useListsurveystudentQuery>;
export type ListsurveystudentLazyQueryHookResult = ReturnType<typeof useListsurveystudentLazyQuery>;
export type ListsurveystudentQueryResult = Apollo.QueryResult<ListsurveystudentQuery, ListsurveystudentQueryVariables>;
export const ClientFillingAttemptsDocument = gql`
    mutation ClientFillingAttempts($input: ClientAttemptsInput!) {
  clientFillingAttempts(input: $input)
}
    `;
export type ClientFillingAttemptsMutationFn = Apollo.MutationFunction<ClientFillingAttemptsMutation, ClientFillingAttemptsMutationVariables>;

/**
 * __useClientFillingAttemptsMutation__
 *
 * To run a mutation, you first call `useClientFillingAttemptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientFillingAttemptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientFillingAttemptsMutation, { data, loading, error }] = useClientFillingAttemptsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientFillingAttemptsMutation(baseOptions?: Apollo.MutationHookOptions<ClientFillingAttemptsMutation, ClientFillingAttemptsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClientFillingAttemptsMutation, ClientFillingAttemptsMutationVariables>(ClientFillingAttemptsDocument, options);
      }
export type ClientFillingAttemptsMutationHookResult = ReturnType<typeof useClientFillingAttemptsMutation>;
export type ClientFillingAttemptsMutationResult = Apollo.MutationResult<ClientFillingAttemptsMutation>;
export type ClientFillingAttemptsMutationOptions = Apollo.BaseMutationOptions<ClientFillingAttemptsMutation, ClientFillingAttemptsMutationVariables>;
export const MandatoryDetailsListDocument = gql`
    query mandatoryDetailsList($user_id: String, $student_id: Int) {
  mandatoryDetailsList(user_id: $user_id, student_id: $student_id) {
    id
    user_id {
      id
      name
      street_address
      license_number
      cert_date
      signalAgencies {
        id
        name
      }
    }
    student_id {
      id
      name
      primaryLanguage {
        id
        name
      }
    }
    status_id {
      id
      name
    }
    comment
    licenses_and_certifications
    degreesheld_and_schoolattended
    clinician_phone
    specializedtraining_and_areasofclinicalcompetence
    supervisorname_and_credentials
    relationship_to_client_id {
      id
      name
      spanish_name
    }
    user_sign
    student_sign
    user_date
    student_date
    school_attended
    session_id {
      id
    }
  }
}
    `;

/**
 * __useMandatoryDetailsListQuery__
 *
 * To run a query within a React component, call `useMandatoryDetailsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatoryDetailsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatoryDetailsListQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useMandatoryDetailsListQuery(baseOptions?: Apollo.QueryHookOptions<MandatoryDetailsListQuery, MandatoryDetailsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatoryDetailsListQuery, MandatoryDetailsListQueryVariables>(MandatoryDetailsListDocument, options);
      }
export function useMandatoryDetailsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatoryDetailsListQuery, MandatoryDetailsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatoryDetailsListQuery, MandatoryDetailsListQueryVariables>(MandatoryDetailsListDocument, options);
        }
export type MandatoryDetailsListQueryHookResult = ReturnType<typeof useMandatoryDetailsListQuery>;
export type MandatoryDetailsListLazyQueryHookResult = ReturnType<typeof useMandatoryDetailsListLazyQuery>;
export type MandatoryDetailsListQueryResult = Apollo.QueryResult<MandatoryDetailsListQuery, MandatoryDetailsListQueryVariables>;
export const UpdateMandatoryFormDocument = gql`
    mutation updateMandatoryForm($id: String!, $input: MandatoryFormDetailsInput!) {
  updateMandatoryForm(id: $id, input: $input)
}
    `;
export type UpdateMandatoryFormMutationFn = Apollo.MutationFunction<UpdateMandatoryFormMutation, UpdateMandatoryFormMutationVariables>;

/**
 * __useUpdateMandatoryFormMutation__
 *
 * To run a mutation, you first call `useUpdateMandatoryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMandatoryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMandatoryFormMutation, { data, loading, error }] = useUpdateMandatoryFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMandatoryFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMandatoryFormMutation, UpdateMandatoryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMandatoryFormMutation, UpdateMandatoryFormMutationVariables>(UpdateMandatoryFormDocument, options);
      }
export type UpdateMandatoryFormMutationHookResult = ReturnType<typeof useUpdateMandatoryFormMutation>;
export type UpdateMandatoryFormMutationResult = Apollo.MutationResult<UpdateMandatoryFormMutation>;
export type UpdateMandatoryFormMutationOptions = Apollo.BaseMutationOptions<UpdateMandatoryFormMutation, UpdateMandatoryFormMutationVariables>;
export const RelationshipToClientDocument = gql`
    query relationshipToClient {
  relationshipToClient {
    id
    name
    spanish_name
    status
  }
}
    `;

/**
 * __useRelationshipToClientQuery__
 *
 * To run a query within a React component, call `useRelationshipToClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipToClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipToClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelationshipToClientQuery(baseOptions?: Apollo.QueryHookOptions<RelationshipToClientQuery, RelationshipToClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelationshipToClientQuery, RelationshipToClientQueryVariables>(RelationshipToClientDocument, options);
      }
export function useRelationshipToClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelationshipToClientQuery, RelationshipToClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelationshipToClientQuery, RelationshipToClientQueryVariables>(RelationshipToClientDocument, options);
        }
export type RelationshipToClientQueryHookResult = ReturnType<typeof useRelationshipToClientQuery>;
export type RelationshipToClientLazyQueryHookResult = ReturnType<typeof useRelationshipToClientLazyQuery>;
export type RelationshipToClientQueryResult = Apollo.QueryResult<RelationshipToClientQuery, RelationshipToClientQueryVariables>;
export const MandatoryInviteDocument = gql`
    query mandatoryInvite($token: String!) {
  mandatoryInvite(token: $token) {
    id
    user_id {
      id
      name
      street_address
      license_number
      cert_date
      signalAgencies {
        name
      }
    }
    student_id {
      id
      name
    }
    status_id {
      id
      name
    }
    comment
    licenses_and_certifications
    degreesheld_and_schoolattended
    clinician_phone
    specializedtraining_and_areasofclinicalcompetence
    supervisorname_and_credentials
    relationship_to_client_id {
      id
      name
    }
    user_sign
    student_sign
    user_date
    student_date
    school_attended
    session_id {
      id
    }
  }
}
    `;

/**
 * __useMandatoryInviteQuery__
 *
 * To run a query within a React component, call `useMandatoryInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatoryInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatoryInviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMandatoryInviteQuery(baseOptions: Apollo.QueryHookOptions<MandatoryInviteQuery, MandatoryInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatoryInviteQuery, MandatoryInviteQueryVariables>(MandatoryInviteDocument, options);
      }
export function useMandatoryInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatoryInviteQuery, MandatoryInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatoryInviteQuery, MandatoryInviteQueryVariables>(MandatoryInviteDocument, options);
        }
export type MandatoryInviteQueryHookResult = ReturnType<typeof useMandatoryInviteQuery>;
export type MandatoryInviteLazyQueryHookResult = ReturnType<typeof useMandatoryInviteLazyQuery>;
export type MandatoryInviteQueryResult = Apollo.QueryResult<MandatoryInviteQuery, MandatoryInviteQueryVariables>;
export const UpdateMandatoryDataDocument = gql`
    mutation updateMandatoryData($id: String!, $input: MandatoryFormDetailsInput!) {
  updateMandatoryData(id: $id, input: $input)
}
    `;
export type UpdateMandatoryDataMutationFn = Apollo.MutationFunction<UpdateMandatoryDataMutation, UpdateMandatoryDataMutationVariables>;

/**
 * __useUpdateMandatoryDataMutation__
 *
 * To run a mutation, you first call `useUpdateMandatoryDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMandatoryDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMandatoryDataMutation, { data, loading, error }] = useUpdateMandatoryDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMandatoryDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMandatoryDataMutation, UpdateMandatoryDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMandatoryDataMutation, UpdateMandatoryDataMutationVariables>(UpdateMandatoryDataDocument, options);
      }
export type UpdateMandatoryDataMutationHookResult = ReturnType<typeof useUpdateMandatoryDataMutation>;
export type UpdateMandatoryDataMutationResult = Apollo.MutationResult<UpdateMandatoryDataMutation>;
export type UpdateMandatoryDataMutationOptions = Apollo.BaseMutationOptions<UpdateMandatoryDataMutation, UpdateMandatoryDataMutationVariables>;
export const AddMandatoryDetailsDocument = gql`
    mutation addMandatoryDetails($input: MandatoryFormDetailsInput!, $resend: Boolean) {
  addMandatoryDetails(input: $input, resend: $resend)
}
    `;
export type AddMandatoryDetailsMutationFn = Apollo.MutationFunction<AddMandatoryDetailsMutation, AddMandatoryDetailsMutationVariables>;

/**
 * __useAddMandatoryDetailsMutation__
 *
 * To run a mutation, you first call `useAddMandatoryDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMandatoryDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMandatoryDetailsMutation, { data, loading, error }] = useAddMandatoryDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      resend: // value for 'resend'
 *   },
 * });
 */
export function useAddMandatoryDetailsMutation(baseOptions?: Apollo.MutationHookOptions<AddMandatoryDetailsMutation, AddMandatoryDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMandatoryDetailsMutation, AddMandatoryDetailsMutationVariables>(AddMandatoryDetailsDocument, options);
      }
export type AddMandatoryDetailsMutationHookResult = ReturnType<typeof useAddMandatoryDetailsMutation>;
export type AddMandatoryDetailsMutationResult = Apollo.MutationResult<AddMandatoryDetailsMutation>;
export type AddMandatoryDetailsMutationOptions = Apollo.BaseMutationOptions<AddMandatoryDetailsMutation, AddMandatoryDetailsMutationVariables>;
export const CreateDistrictDocument = gql`
    mutation CreateDistrict($input: DistrictInput!) {
  createDistrict(input: $input) {
    id
  }
}
    `;
export type CreateDistrictMutationFn = Apollo.MutationFunction<CreateDistrictMutation, CreateDistrictMutationVariables>;

/**
 * __useCreateDistrictMutation__
 *
 * To run a mutation, you first call `useCreateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistrictMutation, { data, loading, error }] = useCreateDistrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDistrictMutation(baseOptions?: Apollo.MutationHookOptions<CreateDistrictMutation, CreateDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDistrictMutation, CreateDistrictMutationVariables>(CreateDistrictDocument, options);
      }
export type CreateDistrictMutationHookResult = ReturnType<typeof useCreateDistrictMutation>;
export type CreateDistrictMutationResult = Apollo.MutationResult<CreateDistrictMutation>;
export type CreateDistrictMutationOptions = Apollo.BaseMutationOptions<CreateDistrictMutation, CreateDistrictMutationVariables>;
export const StatesDocument = gql`
    query States {
  states {
    id
    name
    abbreviation
  }
}
    `;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<StatesQuery, StatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatesQuery, StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<StatesQuery, StatesQueryVariables>;
export const DeleteDistrictDocument = gql`
    mutation DeleteDistrict($id: ID!) {
  deleteDistrict(id: $id)
}
    `;
export type DeleteDistrictMutationFn = Apollo.MutationFunction<DeleteDistrictMutation, DeleteDistrictMutationVariables>;

/**
 * __useDeleteDistrictMutation__
 *
 * To run a mutation, you first call `useDeleteDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDistrictMutation, { data, loading, error }] = useDeleteDistrictMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDistrictMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDistrictMutation, DeleteDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDistrictMutation, DeleteDistrictMutationVariables>(DeleteDistrictDocument, options);
      }
export type DeleteDistrictMutationHookResult = ReturnType<typeof useDeleteDistrictMutation>;
export type DeleteDistrictMutationResult = Apollo.MutationResult<DeleteDistrictMutation>;
export type DeleteDistrictMutationOptions = Apollo.BaseMutationOptions<DeleteDistrictMutation, DeleteDistrictMutationVariables>;
export const DistrictsDocument = gql`
    query Districts {
  districts {
    id
    name
    zip
    status
    city
    street_address
    level_of_service
  }
}
    `;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistrictsQuery(baseOptions?: Apollo.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
      }
export function useDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
        }
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = Apollo.QueryResult<DistrictsQuery, DistrictsQueryVariables>;
export const DistrictDocument = gql`
    query District($id: Int!) {
  district(id: $id) {
    id
    city
    level_of_service
    name
    status
    street_address
    zip
    allow_conferencing
    provider_pins_enabled
    bill_tracking_records_enabled
    therapy_component_enabled
    offer_billing_question
    signature_required
    planTypes
    state {
      id
      name
    }
  }
}
    `;

/**
 * __useDistrictQuery__
 *
 * To run a query within a React component, call `useDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictQuery(baseOptions: Apollo.QueryHookOptions<DistrictQuery, DistrictQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictQuery, DistrictQueryVariables>(DistrictDocument, options);
      }
export function useDistrictLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictQuery, DistrictQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictQuery, DistrictQueryVariables>(DistrictDocument, options);
        }
export type DistrictQueryHookResult = ReturnType<typeof useDistrictQuery>;
export type DistrictLazyQueryHookResult = ReturnType<typeof useDistrictLazyQuery>;
export type DistrictQueryResult = Apollo.QueryResult<DistrictQuery, DistrictQueryVariables>;
export const UpdateDistrictDocument = gql`
    mutation UpdateDistrict($id: Int!, $input: DistrictInput!) {
  updateDistrict(id: $id, input: $input) {
    state {
      id
    }
  }
}
    `;
export type UpdateDistrictMutationFn = Apollo.MutationFunction<UpdateDistrictMutation, UpdateDistrictMutationVariables>;

/**
 * __useUpdateDistrictMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictMutation, { data, loading, error }] = useUpdateDistrictMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDistrictMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDistrictMutation, UpdateDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDistrictMutation, UpdateDistrictMutationVariables>(UpdateDistrictDocument, options);
      }
export type UpdateDistrictMutationHookResult = ReturnType<typeof useUpdateDistrictMutation>;
export type UpdateDistrictMutationResult = Apollo.MutationResult<UpdateDistrictMutation>;
export type UpdateDistrictMutationOptions = Apollo.BaseMutationOptions<UpdateDistrictMutation, UpdateDistrictMutationVariables>;
export const CreateIcdCodeDocument = gql`
    mutation CreateIcdCode($input: IcdCodeInput!) {
  createIcdCode(input: $input) {
    name
    code
    therapist_discipline
    version
  }
}
    `;
export type CreateIcdCodeMutationFn = Apollo.MutationFunction<CreateIcdCodeMutation, CreateIcdCodeMutationVariables>;

/**
 * __useCreateIcdCodeMutation__
 *
 * To run a mutation, you first call `useCreateIcdCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIcdCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIcdCodeMutation, { data, loading, error }] = useCreateIcdCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIcdCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateIcdCodeMutation, CreateIcdCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIcdCodeMutation, CreateIcdCodeMutationVariables>(CreateIcdCodeDocument, options);
      }
export type CreateIcdCodeMutationHookResult = ReturnType<typeof useCreateIcdCodeMutation>;
export type CreateIcdCodeMutationResult = Apollo.MutationResult<CreateIcdCodeMutation>;
export type CreateIcdCodeMutationOptions = Apollo.BaseMutationOptions<CreateIcdCodeMutation, CreateIcdCodeMutationVariables>;
export const DeleteIcdCodeDocument = gql`
    mutation DeleteIcdCode($id: Int!) {
  deleteIcdCode(id: $id)
}
    `;
export type DeleteIcdCodeMutationFn = Apollo.MutationFunction<DeleteIcdCodeMutation, DeleteIcdCodeMutationVariables>;

/**
 * __useDeleteIcdCodeMutation__
 *
 * To run a mutation, you first call `useDeleteIcdCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIcdCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIcdCodeMutation, { data, loading, error }] = useDeleteIcdCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIcdCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIcdCodeMutation, DeleteIcdCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIcdCodeMutation, DeleteIcdCodeMutationVariables>(DeleteIcdCodeDocument, options);
      }
export type DeleteIcdCodeMutationHookResult = ReturnType<typeof useDeleteIcdCodeMutation>;
export type DeleteIcdCodeMutationResult = Apollo.MutationResult<DeleteIcdCodeMutation>;
export type DeleteIcdCodeMutationOptions = Apollo.BaseMutationOptions<DeleteIcdCodeMutation, DeleteIcdCodeMutationVariables>;
export const ListIcdCodesDocument = gql`
    query ListIcdCodes {
  icdCodes {
    id
    name
    code
    version
    therapist_discipline
    status
  }
}
    `;

/**
 * __useListIcdCodesQuery__
 *
 * To run a query within a React component, call `useListIcdCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIcdCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIcdCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIcdCodesQuery(baseOptions?: Apollo.QueryHookOptions<ListIcdCodesQuery, ListIcdCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIcdCodesQuery, ListIcdCodesQueryVariables>(ListIcdCodesDocument, options);
      }
export function useListIcdCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIcdCodesQuery, ListIcdCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIcdCodesQuery, ListIcdCodesQueryVariables>(ListIcdCodesDocument, options);
        }
export type ListIcdCodesQueryHookResult = ReturnType<typeof useListIcdCodesQuery>;
export type ListIcdCodesLazyQueryHookResult = ReturnType<typeof useListIcdCodesLazyQuery>;
export type ListIcdCodesQueryResult = Apollo.QueryResult<ListIcdCodesQuery, ListIcdCodesQueryVariables>;
export const IcdCodeDocument = gql`
    query IcdCode($id: Int!) {
  icdCode(id: $id) {
    name
    code
    therapist_discipline
    version
  }
}
    `;

/**
 * __useIcdCodeQuery__
 *
 * To run a query within a React component, call `useIcdCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcdCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcdCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIcdCodeQuery(baseOptions: Apollo.QueryHookOptions<IcdCodeQuery, IcdCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IcdCodeQuery, IcdCodeQueryVariables>(IcdCodeDocument, options);
      }
export function useIcdCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IcdCodeQuery, IcdCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IcdCodeQuery, IcdCodeQueryVariables>(IcdCodeDocument, options);
        }
export type IcdCodeQueryHookResult = ReturnType<typeof useIcdCodeQuery>;
export type IcdCodeLazyQueryHookResult = ReturnType<typeof useIcdCodeLazyQuery>;
export type IcdCodeQueryResult = Apollo.QueryResult<IcdCodeQuery, IcdCodeQueryVariables>;
export const UpdateIcdCodeDocument = gql`
    mutation UpdateIcdCode($id: Int!, $input: IcdCodeInput!) {
  updateIcdCode(id: $id, input: $input)
}
    `;
export type UpdateIcdCodeMutationFn = Apollo.MutationFunction<UpdateIcdCodeMutation, UpdateIcdCodeMutationVariables>;

/**
 * __useUpdateIcdCodeMutation__
 *
 * To run a mutation, you first call `useUpdateIcdCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIcdCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIcdCodeMutation, { data, loading, error }] = useUpdateIcdCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIcdCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIcdCodeMutation, UpdateIcdCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIcdCodeMutation, UpdateIcdCodeMutationVariables>(UpdateIcdCodeDocument, options);
      }
export type UpdateIcdCodeMutationHookResult = ReturnType<typeof useUpdateIcdCodeMutation>;
export type UpdateIcdCodeMutationResult = Apollo.MutationResult<UpdateIcdCodeMutation>;
export type UpdateIcdCodeMutationOptions = Apollo.BaseMutationOptions<UpdateIcdCodeMutation, UpdateIcdCodeMutationVariables>;
export const SessionsListDocument = gql`
    query SessionsList($id: String!) {
  sessionsList(id: $id) {
    id
    name
    started_at
    ended_at
    sign
    service_id {
      id
      name
    }
    user_id {
      id
    }
    outcome_id {
      id
      name
    }
    signal_service_id {
      id
      name
    }
    signature_history {
      id
      sign
    }
    requestReferral {
      id
      name
    }
    appointment_id {
      id
      start_date
      service_id {
        name
      }
    }
    session_students {
      student_id {
        id
        name
        primaryLanguage {
          id
        }
      }
      deleted_goals
      session_no
      notes
      assessment
      present
      plan
      further_sessions
      further_sessions_text
      high_level_care
      high_level_care_text
      session_Goals {
        id
        percentage
        attempts
        assist
        total
        goal_id {
          id
          goal_number
          target
          name
          description
        }
        parent_goal_id
        session_rate_id
        notes
        activity
        percentage
        total
        assist
        attempts
        session_tracker {
          tracker_id {
            id
            name
            icon
          }
          clicks
          activity
        }
      }
    }
  }
}
    `;

/**
 * __useSessionsListQuery__
 *
 * To run a query within a React component, call `useSessionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionsListQuery(baseOptions: Apollo.QueryHookOptions<SessionsListQuery, SessionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsListQuery, SessionsListQueryVariables>(SessionsListDocument, options);
      }
export function useSessionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsListQuery, SessionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsListQuery, SessionsListQueryVariables>(SessionsListDocument, options);
        }
export type SessionsListQueryHookResult = ReturnType<typeof useSessionsListQuery>;
export type SessionsListLazyQueryHookResult = ReturnType<typeof useSessionsListLazyQuery>;
export type SessionsListQueryResult = Apollo.QueryResult<SessionsListQuery, SessionsListQueryVariables>;
export const ServicesListDocument = gql`
    query ServicesList {
  servicesList {
    id
    name
  }
}
    `;

/**
 * __useServicesListQuery__
 *
 * To run a query within a React component, call `useServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesListQuery(baseOptions?: Apollo.QueryHookOptions<ServicesListQuery, ServicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesListQuery, ServicesListQueryVariables>(ServicesListDocument, options);
      }
export function useServicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesListQuery, ServicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesListQuery, ServicesListQueryVariables>(ServicesListDocument, options);
        }
export type ServicesListQueryHookResult = ReturnType<typeof useServicesListQuery>;
export type ServicesListLazyQueryHookResult = ReturnType<typeof useServicesListLazyQuery>;
export type ServicesListQueryResult = Apollo.QueryResult<ServicesListQuery, ServicesListQueryVariables>;
export const SessionCountDocument = gql`
    query SessionCount($student_id: Int, $user_id: String) {
  sessionCount(student_id: $student_id, user_id: $user_id) {
    count
  }
}
    `;

/**
 * __useSessionCountQuery__
 *
 * To run a query within a React component, call `useSessionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionCountQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useSessionCountQuery(baseOptions?: Apollo.QueryHookOptions<SessionCountQuery, SessionCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionCountQuery, SessionCountQueryVariables>(SessionCountDocument, options);
      }
export function useSessionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionCountQuery, SessionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionCountQuery, SessionCountQueryVariables>(SessionCountDocument, options);
        }
export type SessionCountQueryHookResult = ReturnType<typeof useSessionCountQuery>;
export type SessionCountLazyQueryHookResult = ReturnType<typeof useSessionCountLazyQuery>;
export type SessionCountQueryResult = Apollo.QueryResult<SessionCountQuery, SessionCountQueryVariables>;
export const CreateSessionInfoDocument = gql`
    query CreateSessionInfo($Appointmentid: String!, $TherapistId: String!, $s_start: DateTime, $s_end: DateTime) {
  createSessionInfo(
    Appointmentid: $Appointmentid
    TherapistId: $TherapistId
    s_start: $s_start
    s_end: $s_end
  ) {
    getSessionId
  }
}
    `;

/**
 * __useCreateSessionInfoQuery__
 *
 * To run a query within a React component, call `useCreateSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateSessionInfoQuery({
 *   variables: {
 *      Appointmentid: // value for 'Appointmentid'
 *      TherapistId: // value for 'TherapistId'
 *      s_start: // value for 's_start'
 *      s_end: // value for 's_end'
 *   },
 * });
 */
export function useCreateSessionInfoQuery(baseOptions: Apollo.QueryHookOptions<CreateSessionInfoQuery, CreateSessionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateSessionInfoQuery, CreateSessionInfoQueryVariables>(CreateSessionInfoDocument, options);
      }
export function useCreateSessionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateSessionInfoQuery, CreateSessionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateSessionInfoQuery, CreateSessionInfoQueryVariables>(CreateSessionInfoDocument, options);
        }
export type CreateSessionInfoQueryHookResult = ReturnType<typeof useCreateSessionInfoQuery>;
export type CreateSessionInfoLazyQueryHookResult = ReturnType<typeof useCreateSessionInfoLazyQuery>;
export type CreateSessionInfoQueryResult = Apollo.QueryResult<CreateSessionInfoQuery, CreateSessionInfoQueryVariables>;
export const SessionRateSelectDocument = gql`
    query SessionRateSelect {
  sessionRateList {
    id
    name
  }
}
    `;

/**
 * __useSessionRateSelectQuery__
 *
 * To run a query within a React component, call `useSessionRateSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionRateSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionRateSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionRateSelectQuery(baseOptions?: Apollo.QueryHookOptions<SessionRateSelectQuery, SessionRateSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionRateSelectQuery, SessionRateSelectQueryVariables>(SessionRateSelectDocument, options);
      }
export function useSessionRateSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionRateSelectQuery, SessionRateSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionRateSelectQuery, SessionRateSelectQueryVariables>(SessionRateSelectDocument, options);
        }
export type SessionRateSelectQueryHookResult = ReturnType<typeof useSessionRateSelectQuery>;
export type SessionRateSelectLazyQueryHookResult = ReturnType<typeof useSessionRateSelectLazyQuery>;
export type SessionRateSelectQueryResult = Apollo.QueryResult<SessionRateSelectQuery, SessionRateSelectQueryVariables>;
export const ReferralDocument = gql`
    query Referral {
  referral {
    id
    name
  }
}
    `;

/**
 * __useReferralQuery__
 *
 * To run a query within a React component, call `useReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralQuery(baseOptions?: Apollo.QueryHookOptions<ReferralQuery, ReferralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralQuery, ReferralQueryVariables>(ReferralDocument, options);
      }
export function useReferralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralQuery, ReferralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralQuery, ReferralQueryVariables>(ReferralDocument, options);
        }
export type ReferralQueryHookResult = ReturnType<typeof useReferralQuery>;
export type ReferralLazyQueryHookResult = ReturnType<typeof useReferralLazyQuery>;
export type ReferralQueryResult = Apollo.QueryResult<ReferralQuery, ReferralQueryVariables>;
export const AddStudentToSessionDocument = gql`
    mutation AddStudentToSession($session_id: String!, $therapist_id: String!, $student_id: Int!) {
  addStudentToSession(
    session_id: $session_id
    therapist_id: $therapist_id
    student_id: $student_id
  )
}
    `;
export type AddStudentToSessionMutationFn = Apollo.MutationFunction<AddStudentToSessionMutation, AddStudentToSessionMutationVariables>;

/**
 * __useAddStudentToSessionMutation__
 *
 * To run a mutation, you first call `useAddStudentToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentToSessionMutation, { data, loading, error }] = useAddStudentToSessionMutation({
 *   variables: {
 *      session_id: // value for 'session_id'
 *      therapist_id: // value for 'therapist_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useAddStudentToSessionMutation(baseOptions?: Apollo.MutationHookOptions<AddStudentToSessionMutation, AddStudentToSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStudentToSessionMutation, AddStudentToSessionMutationVariables>(AddStudentToSessionDocument, options);
      }
export type AddStudentToSessionMutationHookResult = ReturnType<typeof useAddStudentToSessionMutation>;
export type AddStudentToSessionMutationResult = Apollo.MutationResult<AddStudentToSessionMutation>;
export type AddStudentToSessionMutationOptions = Apollo.BaseMutationOptions<AddStudentToSessionMutation, AddStudentToSessionMutationVariables>;
export const DeleteStudentFromSessionDocument = gql`
    mutation DeleteStudentFromSession($session_id: String!, $student_id: Int!) {
  deleteStudentFromSession(session_id: $session_id, student_id: $student_id)
}
    `;
export type DeleteStudentFromSessionMutationFn = Apollo.MutationFunction<DeleteStudentFromSessionMutation, DeleteStudentFromSessionMutationVariables>;

/**
 * __useDeleteStudentFromSessionMutation__
 *
 * To run a mutation, you first call `useDeleteStudentFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentFromSessionMutation, { data, loading, error }] = useDeleteStudentFromSessionMutation({
 *   variables: {
 *      session_id: // value for 'session_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useDeleteStudentFromSessionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentFromSessionMutation, DeleteStudentFromSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentFromSessionMutation, DeleteStudentFromSessionMutationVariables>(DeleteStudentFromSessionDocument, options);
      }
export type DeleteStudentFromSessionMutationHookResult = ReturnType<typeof useDeleteStudentFromSessionMutation>;
export type DeleteStudentFromSessionMutationResult = Apollo.MutationResult<DeleteStudentFromSessionMutation>;
export type DeleteStudentFromSessionMutationOptions = Apollo.BaseMutationOptions<DeleteStudentFromSessionMutation, DeleteStudentFromSessionMutationVariables>;
export const UpdateSessionsDocument = gql`
    mutation UpdateSessions($input: JSON!) {
  updateSessions(input: $input)
}
    `;
export type UpdateSessionsMutationFn = Apollo.MutationFunction<UpdateSessionsMutation, UpdateSessionsMutationVariables>;

/**
 * __useUpdateSessionsMutation__
 *
 * To run a mutation, you first call `useUpdateSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionsMutation, { data, loading, error }] = useUpdateSessionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionsMutation, UpdateSessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionsMutation, UpdateSessionsMutationVariables>(UpdateSessionsDocument, options);
      }
export type UpdateSessionsMutationHookResult = ReturnType<typeof useUpdateSessionsMutation>;
export type UpdateSessionsMutationResult = Apollo.MutationResult<UpdateSessionsMutation>;
export type UpdateSessionsMutationOptions = Apollo.BaseMutationOptions<UpdateSessionsMutation, UpdateSessionsMutationVariables>;
export const AdminOngoingUsersListDocument = gql`
    query AdminOngoingUsersList {
  adminUsersList {
    id
    name
  }
}
    `;

/**
 * __useAdminOngoingUsersListQuery__
 *
 * To run a query within a React component, call `useAdminOngoingUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOngoingUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOngoingUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOngoingUsersListQuery(baseOptions?: Apollo.QueryHookOptions<AdminOngoingUsersListQuery, AdminOngoingUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOngoingUsersListQuery, AdminOngoingUsersListQueryVariables>(AdminOngoingUsersListDocument, options);
      }
export function useAdminOngoingUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOngoingUsersListQuery, AdminOngoingUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOngoingUsersListQuery, AdminOngoingUsersListQueryVariables>(AdminOngoingUsersListDocument, options);
        }
export type AdminOngoingUsersListQueryHookResult = ReturnType<typeof useAdminOngoingUsersListQuery>;
export type AdminOngoingUsersListLazyQueryHookResult = ReturnType<typeof useAdminOngoingUsersListLazyQuery>;
export type AdminOngoingUsersListQueryResult = Apollo.QueryResult<AdminOngoingUsersListQuery, AdminOngoingUsersListQueryVariables>;
export const InprogressInsertDocument = gql`
    mutation InprogressInsert($id: String!, $input: InprogressInput!) {
  inprogressInsert(id: $id, input: $input)
}
    `;
export type InprogressInsertMutationFn = Apollo.MutationFunction<InprogressInsertMutation, InprogressInsertMutationVariables>;

/**
 * __useInprogressInsertMutation__
 *
 * To run a mutation, you first call `useInprogressInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInprogressInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inprogressInsertMutation, { data, loading, error }] = useInprogressInsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInprogressInsertMutation(baseOptions?: Apollo.MutationHookOptions<InprogressInsertMutation, InprogressInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InprogressInsertMutation, InprogressInsertMutationVariables>(InprogressInsertDocument, options);
      }
export type InprogressInsertMutationHookResult = ReturnType<typeof useInprogressInsertMutation>;
export type InprogressInsertMutationResult = Apollo.MutationResult<InprogressInsertMutation>;
export type InprogressInsertMutationOptions = Apollo.BaseMutationOptions<InprogressInsertMutation, InprogressInsertMutationVariables>;
export const FurtherInsertDocument = gql`
    mutation FurtherInsert($id: String!, $input: FurtherInput!) {
  furtherInsert(id: $id, input: $input)
}
    `;
export type FurtherInsertMutationFn = Apollo.MutationFunction<FurtherInsertMutation, FurtherInsertMutationVariables>;

/**
 * __useFurtherInsertMutation__
 *
 * To run a mutation, you first call `useFurtherInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFurtherInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [furtherInsertMutation, { data, loading, error }] = useFurtherInsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFurtherInsertMutation(baseOptions?: Apollo.MutationHookOptions<FurtherInsertMutation, FurtherInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FurtherInsertMutation, FurtherInsertMutationVariables>(FurtherInsertDocument, options);
      }
export type FurtherInsertMutationHookResult = ReturnType<typeof useFurtherInsertMutation>;
export type FurtherInsertMutationResult = Apollo.MutationResult<FurtherInsertMutation>;
export type FurtherInsertMutationOptions = Apollo.BaseMutationOptions<FurtherInsertMutation, FurtherInsertMutationVariables>;
export const ReferralInsertDocument = gql`
    mutation ReferralInsert($id: String!, $input: ReferralInput!) {
  referralInsert(id: $id, input: $input)
}
    `;
export type ReferralInsertMutationFn = Apollo.MutationFunction<ReferralInsertMutation, ReferralInsertMutationVariables>;

/**
 * __useReferralInsertMutation__
 *
 * To run a mutation, you first call `useReferralInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferralInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referralInsertMutation, { data, loading, error }] = useReferralInsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferralInsertMutation(baseOptions?: Apollo.MutationHookOptions<ReferralInsertMutation, ReferralInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReferralInsertMutation, ReferralInsertMutationVariables>(ReferralInsertDocument, options);
      }
export type ReferralInsertMutationHookResult = ReturnType<typeof useReferralInsertMutation>;
export type ReferralInsertMutationResult = Apollo.MutationResult<ReferralInsertMutation>;
export type ReferralInsertMutationOptions = Apollo.BaseMutationOptions<ReferralInsertMutation, ReferralInsertMutationVariables>;
export const OngoingcaresDocument = gql`
    query ongoingcares($assigned_to: String) {
  ongoingcares(assigned_to: $assigned_to) {
    id
    status
    ongoing_care
    further_session
    ongoing_care_reason
    further_session_reason
    service_requested
    user_id {
      id
      first_name
      last_name
    }
    assigned_to {
      id
      name
    }
    action_id {
      name
      id
    }
    assigned_date
    student_id {
      id
      first_name
      last_name
      birth_date
      contact_email
      contact_phone_number
      gender
      insurance_company {
        id
        name
      }
      unique_profile_id
    }
  }
}
    `;

/**
 * __useOngoingcaresQuery__
 *
 * To run a query within a React component, call `useOngoingcaresQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingcaresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingcaresQuery({
 *   variables: {
 *      assigned_to: // value for 'assigned_to'
 *   },
 * });
 */
export function useOngoingcaresQuery(baseOptions?: Apollo.QueryHookOptions<OngoingcaresQuery, OngoingcaresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OngoingcaresQuery, OngoingcaresQueryVariables>(OngoingcaresDocument, options);
      }
export function useOngoingcaresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingcaresQuery, OngoingcaresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OngoingcaresQuery, OngoingcaresQueryVariables>(OngoingcaresDocument, options);
        }
export type OngoingcaresQueryHookResult = ReturnType<typeof useOngoingcaresQuery>;
export type OngoingcaresLazyQueryHookResult = ReturnType<typeof useOngoingcaresLazyQuery>;
export type OngoingcaresQueryResult = Apollo.QueryResult<OngoingcaresQuery, OngoingcaresQueryVariables>;
export const UpdateOngoingCareDocument = gql`
    mutation updateOngoingCare($input: JSON!) {
  updateOngoingCare(input: $input)
}
    `;
export type UpdateOngoingCareMutationFn = Apollo.MutationFunction<UpdateOngoingCareMutation, UpdateOngoingCareMutationVariables>;

/**
 * __useUpdateOngoingCareMutation__
 *
 * To run a mutation, you first call `useUpdateOngoingCareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOngoingCareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOngoingCareMutation, { data, loading, error }] = useUpdateOngoingCareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOngoingCareMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOngoingCareMutation, UpdateOngoingCareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOngoingCareMutation, UpdateOngoingCareMutationVariables>(UpdateOngoingCareDocument, options);
      }
export type UpdateOngoingCareMutationHookResult = ReturnType<typeof useUpdateOngoingCareMutation>;
export type UpdateOngoingCareMutationResult = Apollo.MutationResult<UpdateOngoingCareMutation>;
export type UpdateOngoingCareMutationOptions = Apollo.BaseMutationOptions<UpdateOngoingCareMutation, UpdateOngoingCareMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation CreateAssignment($input: UserAssignmentInput!) {
  createAssignment(input: $input) {
    id
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const MyAssignmentsDocument = gql`
    query MyAssignments {
  myAssignments {
    id
    user {
      id
    }
    student {
      name
    }
    caseloadType {
      id
      name
    }
  }
}
    `;

/**
 * __useMyAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyAssignmentsQuery, MyAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAssignmentsQuery, MyAssignmentsQueryVariables>(MyAssignmentsDocument, options);
      }
export function useMyAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAssignmentsQuery, MyAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAssignmentsQuery, MyAssignmentsQueryVariables>(MyAssignmentsDocument, options);
        }
export type MyAssignmentsQueryHookResult = ReturnType<typeof useMyAssignmentsQuery>;
export type MyAssignmentsLazyQueryHookResult = ReturnType<typeof useMyAssignmentsLazyQuery>;
export type MyAssignmentsQueryResult = Apollo.QueryResult<MyAssignmentsQuery, MyAssignmentsQueryVariables>;
export const CreateReportshistoryDocument = gql`
    mutation CreateReportshistory($input: JSON!) {
  createReportshistory(input: $input)
}
    `;
export type CreateReportshistoryMutationFn = Apollo.MutationFunction<CreateReportshistoryMutation, CreateReportshistoryMutationVariables>;

/**
 * __useCreateReportshistoryMutation__
 *
 * To run a mutation, you first call `useCreateReportshistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportshistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportshistoryMutation, { data, loading, error }] = useCreateReportshistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportshistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportshistoryMutation, CreateReportshistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportshistoryMutation, CreateReportshistoryMutationVariables>(CreateReportshistoryDocument, options);
      }
export type CreateReportshistoryMutationHookResult = ReturnType<typeof useCreateReportshistoryMutation>;
export type CreateReportshistoryMutationResult = Apollo.MutationResult<CreateReportshistoryMutation>;
export type CreateReportshistoryMutationOptions = Apollo.BaseMutationOptions<CreateReportshistoryMutation, CreateReportshistoryMutationVariables>;
export const CreateDirectIndirectReportDocument = gql`
    mutation CreateDirectIndirectReport($input: JSON!) {
  createDirectIndirectReport(input: $input)
}
    `;
export type CreateDirectIndirectReportMutationFn = Apollo.MutationFunction<CreateDirectIndirectReportMutation, CreateDirectIndirectReportMutationVariables>;

/**
 * __useCreateDirectIndirectReportMutation__
 *
 * To run a mutation, you first call `useCreateDirectIndirectReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectIndirectReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectIndirectReportMutation, { data, loading, error }] = useCreateDirectIndirectReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectIndirectReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectIndirectReportMutation, CreateDirectIndirectReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectIndirectReportMutation, CreateDirectIndirectReportMutationVariables>(CreateDirectIndirectReportDocument, options);
      }
export type CreateDirectIndirectReportMutationHookResult = ReturnType<typeof useCreateDirectIndirectReportMutation>;
export type CreateDirectIndirectReportMutationResult = Apollo.MutationResult<CreateDirectIndirectReportMutation>;
export type CreateDirectIndirectReportMutationOptions = Apollo.BaseMutationOptions<CreateDirectIndirectReportMutation, CreateDirectIndirectReportMutationVariables>;
export const CreateProgressReportDocument = gql`
    mutation CreateProgressReport($input: JSON!) {
  createProgressReport(input: $input)
}
    `;
export type CreateProgressReportMutationFn = Apollo.MutationFunction<CreateProgressReportMutation, CreateProgressReportMutationVariables>;

/**
 * __useCreateProgressReportMutation__
 *
 * To run a mutation, you first call `useCreateProgressReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgressReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgressReportMutation, { data, loading, error }] = useCreateProgressReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgressReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgressReportMutation, CreateProgressReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgressReportMutation, CreateProgressReportMutationVariables>(CreateProgressReportDocument, options);
      }
export type CreateProgressReportMutationHookResult = ReturnType<typeof useCreateProgressReportMutation>;
export type CreateProgressReportMutationResult = Apollo.MutationResult<CreateProgressReportMutation>;
export type CreateProgressReportMutationOptions = Apollo.BaseMutationOptions<CreateProgressReportMutation, CreateProgressReportMutationVariables>;
export const CreateProgressSummaryDocument = gql`
    mutation CreateProgressSummary($input: JSON!) {
  createProgressSummary(input: $input)
}
    `;
export type CreateProgressSummaryMutationFn = Apollo.MutationFunction<CreateProgressSummaryMutation, CreateProgressSummaryMutationVariables>;

/**
 * __useCreateProgressSummaryMutation__
 *
 * To run a mutation, you first call `useCreateProgressSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgressSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgressSummaryMutation, { data, loading, error }] = useCreateProgressSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgressSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgressSummaryMutation, CreateProgressSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgressSummaryMutation, CreateProgressSummaryMutationVariables>(CreateProgressSummaryDocument, options);
      }
export type CreateProgressSummaryMutationHookResult = ReturnType<typeof useCreateProgressSummaryMutation>;
export type CreateProgressSummaryMutationResult = Apollo.MutationResult<CreateProgressSummaryMutation>;
export type CreateProgressSummaryMutationOptions = Apollo.BaseMutationOptions<CreateProgressSummaryMutation, CreateProgressSummaryMutationVariables>;
export const CreateProviderMinutesReportDocument = gql`
    mutation CreateProviderMinutesReport($input: JSON!) {
  createProviderMinutesReport(input: $input)
}
    `;
export type CreateProviderMinutesReportMutationFn = Apollo.MutationFunction<CreateProviderMinutesReportMutation, CreateProviderMinutesReportMutationVariables>;

/**
 * __useCreateProviderMinutesReportMutation__
 *
 * To run a mutation, you first call `useCreateProviderMinutesReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMinutesReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMinutesReportMutation, { data, loading, error }] = useCreateProviderMinutesReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProviderMinutesReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateProviderMinutesReportMutation, CreateProviderMinutesReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProviderMinutesReportMutation, CreateProviderMinutesReportMutationVariables>(CreateProviderMinutesReportDocument, options);
      }
export type CreateProviderMinutesReportMutationHookResult = ReturnType<typeof useCreateProviderMinutesReportMutation>;
export type CreateProviderMinutesReportMutationResult = Apollo.MutationResult<CreateProviderMinutesReportMutation>;
export type CreateProviderMinutesReportMutationOptions = Apollo.BaseMutationOptions<CreateProviderMinutesReportMutation, CreateProviderMinutesReportMutationVariables>;
export const DistrictSelectFilterDocument = gql`
    query DistrictSelectFilter($id: Int) {
  districtselect(id: $id) {
    id
    name
    status
  }
}
    `;

/**
 * __useDistrictSelectFilterQuery__
 *
 * To run a query within a React component, call `useDistrictSelectFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictSelectFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictSelectFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictSelectFilterQuery(baseOptions?: Apollo.QueryHookOptions<DistrictSelectFilterQuery, DistrictSelectFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictSelectFilterQuery, DistrictSelectFilterQueryVariables>(DistrictSelectFilterDocument, options);
      }
export function useDistrictSelectFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictSelectFilterQuery, DistrictSelectFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictSelectFilterQuery, DistrictSelectFilterQueryVariables>(DistrictSelectFilterDocument, options);
        }
export type DistrictSelectFilterQueryHookResult = ReturnType<typeof useDistrictSelectFilterQuery>;
export type DistrictSelectFilterLazyQueryHookResult = ReturnType<typeof useDistrictSelectFilterLazyQuery>;
export type DistrictSelectFilterQueryResult = Apollo.QueryResult<DistrictSelectFilterQuery, DistrictSelectFilterQueryVariables>;
export const ReportusersDocument = gql`
    query Reportusers($district_id: Int) {
  reportusers(district_id: $district_id) {
    id
    name
  }
}
    `;

/**
 * __useReportusersQuery__
 *
 * To run a query within a React component, call `useReportusersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportusersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportusersQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useReportusersQuery(baseOptions?: Apollo.QueryHookOptions<ReportusersQuery, ReportusersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportusersQuery, ReportusersQueryVariables>(ReportusersDocument, options);
      }
export function useReportusersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportusersQuery, ReportusersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportusersQuery, ReportusersQueryVariables>(ReportusersDocument, options);
        }
export type ReportusersQueryHookResult = ReturnType<typeof useReportusersQuery>;
export type ReportusersLazyQueryHookResult = ReturnType<typeof useReportusersLazyQuery>;
export type ReportusersQueryResult = Apollo.QueryResult<ReportusersQuery, ReportusersQueryVariables>;
export const ReportDocument = gql`
    query report($from_date: String, $to_date: String) {
  report(from_date: $from_date, to_date: $to_date)
}
    `;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      from_date: // value for 'from_date'
 *      to_date: // value for 'to_date'
 *   },
 * });
 */
export function useReportQuery(baseOptions?: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const GetUnallocatedUserSlotsDocument = gql`
    query getUnallocatedUserSlots($user_id: String) {
  getUnallocatedUserSlots(user_id: $user_id)
}
    `;

/**
 * __useGetUnallocatedUserSlotsQuery__
 *
 * To run a query within a React component, call `useGetUnallocatedUserSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnallocatedUserSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnallocatedUserSlotsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUnallocatedUserSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnallocatedUserSlotsQuery, GetUnallocatedUserSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnallocatedUserSlotsQuery, GetUnallocatedUserSlotsQueryVariables>(GetUnallocatedUserSlotsDocument, options);
      }
export function useGetUnallocatedUserSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnallocatedUserSlotsQuery, GetUnallocatedUserSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnallocatedUserSlotsQuery, GetUnallocatedUserSlotsQueryVariables>(GetUnallocatedUserSlotsDocument, options);
        }
export type GetUnallocatedUserSlotsQueryHookResult = ReturnType<typeof useGetUnallocatedUserSlotsQuery>;
export type GetUnallocatedUserSlotsLazyQueryHookResult = ReturnType<typeof useGetUnallocatedUserSlotsLazyQuery>;
export type GetUnallocatedUserSlotsQueryResult = Apollo.QueryResult<GetUnallocatedUserSlotsQuery, GetUnallocatedUserSlotsQueryVariables>;
export const GetAddSlotUsersDocument = gql`
    query getAddSlotUsers($district_id: Int) {
  getAddSlotUsers(district_id: $district_id) {
    id
    name
    signal_service_id {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAddSlotUsersQuery__
 *
 * To run a query within a React component, call `useGetAddSlotUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddSlotUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddSlotUsersQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useGetAddSlotUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAddSlotUsersQuery, GetAddSlotUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddSlotUsersQuery, GetAddSlotUsersQueryVariables>(GetAddSlotUsersDocument, options);
      }
export function useGetAddSlotUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddSlotUsersQuery, GetAddSlotUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddSlotUsersQuery, GetAddSlotUsersQueryVariables>(GetAddSlotUsersDocument, options);
        }
export type GetAddSlotUsersQueryHookResult = ReturnType<typeof useGetAddSlotUsersQuery>;
export type GetAddSlotUsersLazyQueryHookResult = ReturnType<typeof useGetAddSlotUsersLazyQuery>;
export type GetAddSlotUsersQueryResult = Apollo.QueryResult<GetAddSlotUsersQuery, GetAddSlotUsersQueryVariables>;
export const CreateAppointmentListDocument = gql`
    mutation CreateAppointmentList($input: AppointmentListInput!) {
  createAppointmentList(input: $input) {
    info
  }
}
    `;
export type CreateAppointmentListMutationFn = Apollo.MutationFunction<CreateAppointmentListMutation, CreateAppointmentListMutationVariables>;

/**
 * __useCreateAppointmentListMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentListMutation, { data, loading, error }] = useCreateAppointmentListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentListMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentListMutation, CreateAppointmentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentListMutation, CreateAppointmentListMutationVariables>(CreateAppointmentListDocument, options);
      }
export type CreateAppointmentListMutationHookResult = ReturnType<typeof useCreateAppointmentListMutation>;
export type CreateAppointmentListMutationResult = Apollo.MutationResult<CreateAppointmentListMutation>;
export type CreateAppointmentListMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentListMutation, CreateAppointmentListMutationVariables>;
export const AppointmentsListDocument = gql`
    query AppointmentsList($user_id: String!, $district_id: Int, $appointment_type_id: ID) {
  appointmentList(
    user_id: $user_id
    district_id: $district_id
    appointment_type_id: $appointment_type_id
  ) {
    id
    StartTime: start_date
    EndTime: end_date
    Subject: title
    EventStudents: students {
      id
      name
    }
    EventService: service_id {
      id
      name
    }
    user_id {
      first_name
      last_name
      id
      district {
        id
      }
    }
    appointment_type_id {
      id
      name
    }
    user_id {
      id
      first_name
      last_name
    }
    note
    RecurrenceRule: recurrance_r_rule
    RecurrenceID: parent_appointment_id
    RecurrenceException: recurrence_exception
    isreccuring
    created_by
    updated_by
    appointment_slot_id
    signal_service_id {
      id
      name
    }
  }
}
    `;

/**
 * __useAppointmentsListQuery__
 *
 * To run a query within a React component, call `useAppointmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsListQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      district_id: // value for 'district_id'
 *      appointment_type_id: // value for 'appointment_type_id'
 *   },
 * });
 */
export function useAppointmentsListQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsListQuery, AppointmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsListQuery, AppointmentsListQueryVariables>(AppointmentsListDocument, options);
      }
export function useAppointmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsListQuery, AppointmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsListQuery, AppointmentsListQueryVariables>(AppointmentsListDocument, options);
        }
export type AppointmentsListQueryHookResult = ReturnType<typeof useAppointmentsListQuery>;
export type AppointmentsListLazyQueryHookResult = ReturnType<typeof useAppointmentsListLazyQuery>;
export type AppointmentsListQueryResult = Apollo.QueryResult<AppointmentsListQuery, AppointmentsListQueryVariables>;
export const SessionStatusDocument = gql`
    query SessionStatus($appointment_id: String!, $scheduled_start: String!, $scheduled_end: String!) {
  sessionStatus(
    appointment_id: $appointment_id
    scheduled_start: $scheduled_start
    scheduled_end: $scheduled_end
  )
}
    `;

/**
 * __useSessionStatusQuery__
 *
 * To run a query within a React component, call `useSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionStatusQuery({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *      scheduled_start: // value for 'scheduled_start'
 *      scheduled_end: // value for 'scheduled_end'
 *   },
 * });
 */
export function useSessionStatusQuery(baseOptions: Apollo.QueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, options);
      }
export function useSessionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, options);
        }
export type SessionStatusQueryHookResult = ReturnType<typeof useSessionStatusQuery>;
export type SessionStatusLazyQueryHookResult = ReturnType<typeof useSessionStatusLazyQuery>;
export type SessionStatusQueryResult = Apollo.QueryResult<SessionStatusQuery, SessionStatusQueryVariables>;
export const UpdateAppointmentListDocument = gql`
    mutation updateAppointmentList($id: ID!, $caseloadInsert: Boolean, $input: AppointmentListInput!) {
  updateAppointmentList(id: $id, caseloadInsert: $caseloadInsert, input: $input) {
    info
  }
}
    `;
export type UpdateAppointmentListMutationFn = Apollo.MutationFunction<UpdateAppointmentListMutation, UpdateAppointmentListMutationVariables>;

/**
 * __useUpdateAppointmentListMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentListMutation, { data, loading, error }] = useUpdateAppointmentListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      caseloadInsert: // value for 'caseloadInsert'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentListMutation, UpdateAppointmentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentListMutation, UpdateAppointmentListMutationVariables>(UpdateAppointmentListDocument, options);
      }
export type UpdateAppointmentListMutationHookResult = ReturnType<typeof useUpdateAppointmentListMutation>;
export type UpdateAppointmentListMutationResult = Apollo.MutationResult<UpdateAppointmentListMutation>;
export type UpdateAppointmentListMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentListMutation, UpdateAppointmentListMutationVariables>;
export const DeleteAppointmentListDocument = gql`
    mutation DeleteAppointmentList($id: ID!) {
  deleteAppointmentList(id: $id)
}
    `;
export type DeleteAppointmentListMutationFn = Apollo.MutationFunction<DeleteAppointmentListMutation, DeleteAppointmentListMutationVariables>;

/**
 * __useDeleteAppointmentListMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentListMutation, { data, loading, error }] = useDeleteAppointmentListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentListMutation, DeleteAppointmentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentListMutation, DeleteAppointmentListMutationVariables>(DeleteAppointmentListDocument, options);
      }
export type DeleteAppointmentListMutationHookResult = ReturnType<typeof useDeleteAppointmentListMutation>;
export type DeleteAppointmentListMutationResult = Apollo.MutationResult<DeleteAppointmentListMutation>;
export type DeleteAppointmentListMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentListMutation, DeleteAppointmentListMutationVariables>;
export const CaseloadSelectDocument = gql`
    query CaseloadSelect($user_id: String!) {
  caseload(user_id: $user_id) {
    students {
      id
      name
    }
  }
}
    `;

/**
 * __useCaseloadSelectQuery__
 *
 * To run a query within a React component, call `useCaseloadSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseloadSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseloadSelectQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCaseloadSelectQuery(baseOptions: Apollo.QueryHookOptions<CaseloadSelectQuery, CaseloadSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseloadSelectQuery, CaseloadSelectQueryVariables>(CaseloadSelectDocument, options);
      }
export function useCaseloadSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseloadSelectQuery, CaseloadSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseloadSelectQuery, CaseloadSelectQueryVariables>(CaseloadSelectDocument, options);
        }
export type CaseloadSelectQueryHookResult = ReturnType<typeof useCaseloadSelectQuery>;
export type CaseloadSelectLazyQueryHookResult = ReturnType<typeof useCaseloadSelectLazyQuery>;
export type CaseloadSelectQueryResult = Apollo.QueryResult<CaseloadSelectQuery, CaseloadSelectQueryVariables>;
export const AppointmentStudentsDocument = gql`
    query appointmentStudents($user_id: String!) {
  appointmentStudents(user_id: $user_id) {
    id: student_id
    name: student_name
  }
}
    `;

/**
 * __useAppointmentStudentsQuery__
 *
 * To run a query within a React component, call `useAppointmentStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentStudentsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAppointmentStudentsQuery(baseOptions: Apollo.QueryHookOptions<AppointmentStudentsQuery, AppointmentStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentStudentsQuery, AppointmentStudentsQueryVariables>(AppointmentStudentsDocument, options);
      }
export function useAppointmentStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentStudentsQuery, AppointmentStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentStudentsQuery, AppointmentStudentsQueryVariables>(AppointmentStudentsDocument, options);
        }
export type AppointmentStudentsQueryHookResult = ReturnType<typeof useAppointmentStudentsQuery>;
export type AppointmentStudentsLazyQueryHookResult = ReturnType<typeof useAppointmentStudentsLazyQuery>;
export type AppointmentStudentsQueryResult = Apollo.QueryResult<AppointmentStudentsQuery, AppointmentStudentsQueryVariables>;
export const DeleteAppointmentListsDocument = gql`
    mutation DeleteAppointmentLists($parent_appointment_id: ID) {
  deleteAppointmentLists(parent_appointment_id: $parent_appointment_id)
}
    `;
export type DeleteAppointmentListsMutationFn = Apollo.MutationFunction<DeleteAppointmentListsMutation, DeleteAppointmentListsMutationVariables>;

/**
 * __useDeleteAppointmentListsMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentListsMutation, { data, loading, error }] = useDeleteAppointmentListsMutation({
 *   variables: {
 *      parent_appointment_id: // value for 'parent_appointment_id'
 *   },
 * });
 */
export function useDeleteAppointmentListsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentListsMutation, DeleteAppointmentListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentListsMutation, DeleteAppointmentListsMutationVariables>(DeleteAppointmentListsDocument, options);
      }
export type DeleteAppointmentListsMutationHookResult = ReturnType<typeof useDeleteAppointmentListsMutation>;
export type DeleteAppointmentListsMutationResult = Apollo.MutationResult<DeleteAppointmentListsMutation>;
export type DeleteAppointmentListsMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentListsMutation, DeleteAppointmentListsMutationVariables>;
export const ListsurveytherapistDocument = gql`
    query Listsurveytherapist($sid: Int) {
  surveytherapist(sid: $sid)
}
    `;

/**
 * __useListsurveytherapistQuery__
 *
 * To run a query within a React component, call `useListsurveytherapistQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsurveytherapistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsurveytherapistQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useListsurveytherapistQuery(baseOptions?: Apollo.QueryHookOptions<ListsurveytherapistQuery, ListsurveytherapistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListsurveytherapistQuery, ListsurveytherapistQueryVariables>(ListsurveytherapistDocument, options);
      }
export function useListsurveytherapistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsurveytherapistQuery, ListsurveytherapistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListsurveytherapistQuery, ListsurveytherapistQueryVariables>(ListsurveytherapistDocument, options);
        }
export type ListsurveytherapistQueryHookResult = ReturnType<typeof useListsurveytherapistQuery>;
export type ListsurveytherapistLazyQueryHookResult = ReturnType<typeof useListsurveytherapistLazyQuery>;
export type ListsurveytherapistQueryResult = Apollo.QueryResult<ListsurveytherapistQuery, ListsurveytherapistQueryVariables>;
export const ServicesDocument = gql`
    query Services($input: ServiceInputQuery) {
  services(input: $input) {
    id
    name
    code
    therapist_discipline
    is_direct
    is_indirect
    is_therapy
    is_evaluation
    is_support
    status
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const CaseloadStatusDocument = gql`
    query CaseloadStatus($user_id: String, $student_id: Int) {
  caseloadStatus(user_id: $user_id, student_id: $student_id)
}
    `;

/**
 * __useCaseloadStatusQuery__
 *
 * To run a query within a React component, call `useCaseloadStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseloadStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseloadStatusQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useCaseloadStatusQuery(baseOptions?: Apollo.QueryHookOptions<CaseloadStatusQuery, CaseloadStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseloadStatusQuery, CaseloadStatusQueryVariables>(CaseloadStatusDocument, options);
      }
export function useCaseloadStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseloadStatusQuery, CaseloadStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseloadStatusQuery, CaseloadStatusQueryVariables>(CaseloadStatusDocument, options);
        }
export type CaseloadStatusQueryHookResult = ReturnType<typeof useCaseloadStatusQuery>;
export type CaseloadStatusLazyQueryHookResult = ReturnType<typeof useCaseloadStatusLazyQuery>;
export type CaseloadStatusQueryResult = Apollo.QueryResult<CaseloadStatusQuery, CaseloadStatusQueryVariables>;
export const UserUnbookedSlotsDocument = gql`
    query UserUnbookedSlots($tid: String, $ttime: String, $service_id: Int) {
  userAvailableSp(tid: $tid, ttime: $ttime, service_id: $service_id)
}
    `;

/**
 * __useUserUnbookedSlotsQuery__
 *
 * To run a query within a React component, call `useUserUnbookedSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUnbookedSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUnbookedSlotsQuery({
 *   variables: {
 *      tid: // value for 'tid'
 *      ttime: // value for 'ttime'
 *      service_id: // value for 'service_id'
 *   },
 * });
 */
export function useUserUnbookedSlotsQuery(baseOptions?: Apollo.QueryHookOptions<UserUnbookedSlotsQuery, UserUnbookedSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserUnbookedSlotsQuery, UserUnbookedSlotsQueryVariables>(UserUnbookedSlotsDocument, options);
      }
export function useUserUnbookedSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserUnbookedSlotsQuery, UserUnbookedSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserUnbookedSlotsQuery, UserUnbookedSlotsQueryVariables>(UserUnbookedSlotsDocument, options);
        }
export type UserUnbookedSlotsQueryHookResult = ReturnType<typeof useUserUnbookedSlotsQuery>;
export type UserUnbookedSlotsLazyQueryHookResult = ReturnType<typeof useUserUnbookedSlotsLazyQuery>;
export type UserUnbookedSlotsQueryResult = Apollo.QueryResult<UserUnbookedSlotsQuery, UserUnbookedSlotsQueryVariables>;
export const UpdateAppointmentstatusDocument = gql`
    mutation updateAppointmentstatus($input: AppointmentstatusUpdate, $user_id: String, $student_id: Int, $appointment_slot_id: String) {
  updateAppointmentstatus(
    input: $input
    user_id: $user_id
    student_id: $student_id
    appointment_slot_id: $appointment_slot_id
  )
}
    `;
export type UpdateAppointmentstatusMutationFn = Apollo.MutationFunction<UpdateAppointmentstatusMutation, UpdateAppointmentstatusMutationVariables>;

/**
 * __useUpdateAppointmentstatusMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentstatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentstatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentstatusMutation, { data, loading, error }] = useUpdateAppointmentstatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *      appointment_slot_id: // value for 'appointment_slot_id'
 *   },
 * });
 */
export function useUpdateAppointmentstatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentstatusMutation, UpdateAppointmentstatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentstatusMutation, UpdateAppointmentstatusMutationVariables>(UpdateAppointmentstatusDocument, options);
      }
export type UpdateAppointmentstatusMutationHookResult = ReturnType<typeof useUpdateAppointmentstatusMutation>;
export type UpdateAppointmentstatusMutationResult = Apollo.MutationResult<UpdateAppointmentstatusMutation>;
export type UpdateAppointmentstatusMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentstatusMutation, UpdateAppointmentstatusMutationVariables>;
export const DeleteDocument = gql`
    mutation delete($input: AppointmentstatusUpdate, $user_id: String, $student_id: Int, $appointment_slot_id: String) {
  deleteAppointment(
    input: $input
    user_id: $user_id
    student_id: $student_id
    appointment_slot_id: $appointment_slot_id
  )
}
    `;
export type DeleteMutationFn = Apollo.MutationFunction<DeleteMutation, DeleteMutationVariables>;

/**
 * __useDeleteMutation__
 *
 * To run a mutation, you first call `useDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMutation, { data, loading, error }] = useDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      user_id: // value for 'user_id'
 *      student_id: // value for 'student_id'
 *      appointment_slot_id: // value for 'appointment_slot_id'
 *   },
 * });
 */
export function useDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMutation, DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMutation, DeleteMutationVariables>(DeleteDocument, options);
      }
export type DeleteMutationHookResult = ReturnType<typeof useDeleteMutation>;
export type DeleteMutationResult = Apollo.MutationResult<DeleteMutation>;
export type DeleteMutationOptions = Apollo.BaseMutationOptions<DeleteMutation, DeleteMutationVariables>;
export const UserServiceListDocument = gql`
    query UserServiceList($id: ID) {
  userServiceList(id: $id)
}
    `;

/**
 * __useUserServiceListQuery__
 *
 * To run a query within a React component, call `useUserServiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserServiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserServiceListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserServiceListQuery(baseOptions?: Apollo.QueryHookOptions<UserServiceListQuery, UserServiceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserServiceListQuery, UserServiceListQueryVariables>(UserServiceListDocument, options);
      }
export function useUserServiceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserServiceListQuery, UserServiceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserServiceListQuery, UserServiceListQueryVariables>(UserServiceListDocument, options);
        }
export type UserServiceListQueryHookResult = ReturnType<typeof useUserServiceListQuery>;
export type UserServiceListLazyQueryHookResult = ReturnType<typeof useUserServiceListLazyQuery>;
export type UserServiceListQueryResult = Apollo.QueryResult<UserServiceListQuery, UserServiceListQueryVariables>;
export const RescheduleusersDocument = gql`
    query Rescheduleusers {
  rescheduleusers {
    id
    name
  }
}
    `;

/**
 * __useRescheduleusersQuery__
 *
 * To run a query within a React component, call `useRescheduleusersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleusersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleusersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRescheduleusersQuery(baseOptions?: Apollo.QueryHookOptions<RescheduleusersQuery, RescheduleusersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RescheduleusersQuery, RescheduleusersQueryVariables>(RescheduleusersDocument, options);
      }
export function useRescheduleusersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RescheduleusersQuery, RescheduleusersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RescheduleusersQuery, RescheduleusersQueryVariables>(RescheduleusersDocument, options);
        }
export type RescheduleusersQueryHookResult = ReturnType<typeof useRescheduleusersQuery>;
export type RescheduleusersLazyQueryHookResult = ReturnType<typeof useRescheduleusersLazyQuery>;
export type RescheduleusersQueryResult = Apollo.QueryResult<RescheduleusersQuery, RescheduleusersQueryVariables>;
export const CreateWaitlistAppointmentDocument = gql`
    mutation CreateWaitlistAppointment($input: AppointmentListInput!) {
  createWaitlistAppointment(input: $input) {
    info
  }
}
    `;
export type CreateWaitlistAppointmentMutationFn = Apollo.MutationFunction<CreateWaitlistAppointmentMutation, CreateWaitlistAppointmentMutationVariables>;

/**
 * __useCreateWaitlistAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateWaitlistAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitlistAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitlistAppointmentMutation, { data, loading, error }] = useCreateWaitlistAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWaitlistAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitlistAppointmentMutation, CreateWaitlistAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitlistAppointmentMutation, CreateWaitlistAppointmentMutationVariables>(CreateWaitlistAppointmentDocument, options);
      }
export type CreateWaitlistAppointmentMutationHookResult = ReturnType<typeof useCreateWaitlistAppointmentMutation>;
export type CreateWaitlistAppointmentMutationResult = Apollo.MutationResult<CreateWaitlistAppointmentMutation>;
export type CreateWaitlistAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateWaitlistAppointmentMutation, CreateWaitlistAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($id: String!, $input: AppointmentServiceNoteUpdate, $sendMail: Boolean) {
  cancelAppointment(id: $id, input: $input, sendMail: $sendMail)
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const AppointmentsDataDocument = gql`
    query AppointmentsData($appointment_id: String) {
  appointmentsData(appointment_id: $appointment_id) {
    id
    user_id {
      street_address
      signalAgencies {
        name
        address
        city
        state
        zipcode
      }
    }
    signal_service_id {
      name
    }
    link
  }
}
    `;

/**
 * __useAppointmentsDataQuery__
 *
 * To run a query within a React component, call `useAppointmentsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsDataQuery({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useAppointmentsDataQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsDataQuery, AppointmentsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsDataQuery, AppointmentsDataQueryVariables>(AppointmentsDataDocument, options);
      }
export function useAppointmentsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsDataQuery, AppointmentsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsDataQuery, AppointmentsDataQueryVariables>(AppointmentsDataDocument, options);
        }
export type AppointmentsDataQueryHookResult = ReturnType<typeof useAppointmentsDataQuery>;
export type AppointmentsDataLazyQueryHookResult = ReturnType<typeof useAppointmentsDataLazyQuery>;
export type AppointmentsDataQueryResult = Apollo.QueryResult<AppointmentsDataQuery, AppointmentsDataQueryVariables>;
export const ResendAppointmentEmailDocument = gql`
    query ResendAppointmentEmail($appointment_id: String) {
  resendAppointmentEmail(appointment_id: $appointment_id)
}
    `;

/**
 * __useResendAppointmentEmailQuery__
 *
 * To run a query within a React component, call `useResendAppointmentEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useResendAppointmentEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResendAppointmentEmailQuery({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useResendAppointmentEmailQuery(baseOptions?: Apollo.QueryHookOptions<ResendAppointmentEmailQuery, ResendAppointmentEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResendAppointmentEmailQuery, ResendAppointmentEmailQueryVariables>(ResendAppointmentEmailDocument, options);
      }
export function useResendAppointmentEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResendAppointmentEmailQuery, ResendAppointmentEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResendAppointmentEmailQuery, ResendAppointmentEmailQueryVariables>(ResendAppointmentEmailDocument, options);
        }
export type ResendAppointmentEmailQueryHookResult = ReturnType<typeof useResendAppointmentEmailQuery>;
export type ResendAppointmentEmailLazyQueryHookResult = ReturnType<typeof useResendAppointmentEmailLazyQuery>;
export type ResendAppointmentEmailQueryResult = Apollo.QueryResult<ResendAppointmentEmailQuery, ResendAppointmentEmailQueryVariables>;
export const UpdateAppointmentServicesDocument = gql`
    mutation updateAppointmentServices($id: ID!, $input: AppointmentServiceNoteUpdate) {
  updateAppointmentServices(id: $id, input: $input)
}
    `;
export type UpdateAppointmentServicesMutationFn = Apollo.MutationFunction<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>;

/**
 * __useUpdateAppointmentServicesMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentServicesMutation, { data, loading, error }] = useUpdateAppointmentServicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentServicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>(UpdateAppointmentServicesDocument, options);
      }
export type UpdateAppointmentServicesMutationHookResult = ReturnType<typeof useUpdateAppointmentServicesMutation>;
export type UpdateAppointmentServicesMutationResult = Apollo.MutationResult<UpdateAppointmentServicesMutation>;
export type UpdateAppointmentServicesMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentServicesMutation, UpdateAppointmentServicesMutationVariables>;
export const ClientFilterDocument = gql`
    query clientFilter($student_id: Int) {
  clientFilter(student_id: $student_id) {
    id
    name
    county {
      id
    }
    session_service {
      id
      name
    }
  }
}
    `;

/**
 * __useClientFilterQuery__
 *
 * To run a query within a React component, call `useClientFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFilterQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useClientFilterQuery(baseOptions?: Apollo.QueryHookOptions<ClientFilterQuery, ClientFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFilterQuery, ClientFilterQueryVariables>(ClientFilterDocument, options);
      }
export function useClientFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFilterQuery, ClientFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFilterQuery, ClientFilterQueryVariables>(ClientFilterDocument, options);
        }
export type ClientFilterQueryHookResult = ReturnType<typeof useClientFilterQuery>;
export type ClientFilterLazyQueryHookResult = ReturnType<typeof useClientFilterLazyQuery>;
export type ClientFilterQueryResult = Apollo.QueryResult<ClientFilterQuery, ClientFilterQueryVariables>;
export const AddAppointmetnsListDocument = gql`
    query addAppointmetnsList($filter: UserFilterInputs, $pagination: PaginationInput) {
  addAppointmetnsList(filter: $filter, pagination: $pagination)
}
    `;

/**
 * __useAddAppointmetnsListQuery__
 *
 * To run a query within a React component, call `useAddAppointmetnsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmetnsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddAppointmetnsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAddAppointmetnsListQuery(baseOptions?: Apollo.QueryHookOptions<AddAppointmetnsListQuery, AddAppointmetnsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddAppointmetnsListQuery, AddAppointmetnsListQueryVariables>(AddAppointmetnsListDocument, options);
      }
export function useAddAppointmetnsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddAppointmetnsListQuery, AddAppointmetnsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddAppointmetnsListQuery, AddAppointmetnsListQueryVariables>(AddAppointmetnsListDocument, options);
        }
export type AddAppointmetnsListQueryHookResult = ReturnType<typeof useAddAppointmetnsListQuery>;
export type AddAppointmetnsListLazyQueryHookResult = ReturnType<typeof useAddAppointmetnsListLazyQuery>;
export type AddAppointmetnsListQueryResult = Apollo.QueryResult<AddAppointmetnsListQuery, AddAppointmetnsListQueryVariables>;
export const CreateSchoolDocument = gql`
    mutation CreateSchool($input: SchoolInput!) {
  createSchool(input: $input) {
    id
  }
}
    `;
export type CreateSchoolMutationFn = Apollo.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, options);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const DeleteSchoolDocument = gql`
    mutation DeleteSchool($id: Int!) {
  deleteSchool(id: $id)
}
    `;
export type DeleteSchoolMutationFn = Apollo.MutationFunction<DeleteSchoolMutation, DeleteSchoolMutationVariables>;

/**
 * __useDeleteSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolMutation, { data, loading, error }] = useDeleteSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(DeleteSchoolDocument, options);
      }
export type DeleteSchoolMutationHookResult = ReturnType<typeof useDeleteSchoolMutation>;
export type DeleteSchoolMutationResult = Apollo.MutationResult<DeleteSchoolMutation>;
export type DeleteSchoolMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>;
export const SchoolsDocument = gql`
    query Schools($district_id: Int) {
  schools(district_id: $district_id) {
    id
    city
    district {
      id
      name
      zip
      city
      street_address
      level_of_service
    }
    location_code
    name
    state {
      id
      name
      abbreviation
    }
    status
    street_address
    zip
  }
}
    `;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
      }
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
        }
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const SchoolDocument = gql`
    query School($id: Int!) {
  school(id: $id) {
    id
    city
    district {
      id
      name
      zip
      city
      street_address
      level_of_service
    }
    location_code
    name
    state {
      id
      name
      abbreviation
    }
    status
    street_address
    zip
  }
}
    `;

/**
 * __useSchoolQuery__
 *
 * To run a query within a React component, call `useSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolQuery(baseOptions: Apollo.QueryHookOptions<SchoolQuery, SchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolQuery, SchoolQueryVariables>(SchoolDocument, options);
      }
export function useSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolQuery, SchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolQuery, SchoolQueryVariables>(SchoolDocument, options);
        }
export type SchoolQueryHookResult = ReturnType<typeof useSchoolQuery>;
export type SchoolLazyQueryHookResult = ReturnType<typeof useSchoolLazyQuery>;
export type SchoolQueryResult = Apollo.QueryResult<SchoolQuery, SchoolQueryVariables>;
export const UpdateSchoolDocument = gql`
    mutation UpdateSchool($id: Int!, $input: SchoolInput!) {
  updateSchool(id: $id, input: $input)
}
    `;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const AllServiceRequestsDocument = gql`
    query AllServiceRequests {
  serviceRequests {
    id
    createdAt
    fileUrl
    type
    requester {
      id
      name
    }
    student {
      id
      name
    }
  }
}
    `;

/**
 * __useAllServiceRequestsQuery__
 *
 * To run a query within a React component, call `useAllServiceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServiceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServiceRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServiceRequestsQuery(baseOptions?: Apollo.QueryHookOptions<AllServiceRequestsQuery, AllServiceRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllServiceRequestsQuery, AllServiceRequestsQueryVariables>(AllServiceRequestsDocument, options);
      }
export function useAllServiceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllServiceRequestsQuery, AllServiceRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllServiceRequestsQuery, AllServiceRequestsQueryVariables>(AllServiceRequestsDocument, options);
        }
export type AllServiceRequestsQueryHookResult = ReturnType<typeof useAllServiceRequestsQuery>;
export type AllServiceRequestsLazyQueryHookResult = ReturnType<typeof useAllServiceRequestsLazyQuery>;
export type AllServiceRequestsQueryResult = Apollo.QueryResult<AllServiceRequestsQuery, AllServiceRequestsQueryVariables>;
export const CreateServiceRequestDocument = gql`
    mutation CreateServiceRequest($input: CreateServiceRequestInput!) {
  createServiceRequest(input: $input) {
    id
  }
}
    `;
export type CreateServiceRequestMutationFn = Apollo.MutationFunction<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>(CreateServiceRequestDocument, options);
      }
export type CreateServiceRequestMutationHookResult = ReturnType<typeof useCreateServiceRequestMutation>;
export type CreateServiceRequestMutationResult = Apollo.MutationResult<CreateServiceRequestMutation>;
export type CreateServiceRequestMutationOptions = Apollo.BaseMutationOptions<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>;
export const MyServiceRequestsDocument = gql`
    query MyServiceRequests {
  myServiceRequests {
    id
    createdAt
    type
    student {
      id
      name
    }
  }
}
    `;

/**
 * __useMyServiceRequestsQuery__
 *
 * To run a query within a React component, call `useMyServiceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyServiceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyServiceRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyServiceRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyServiceRequestsQuery, MyServiceRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyServiceRequestsQuery, MyServiceRequestsQueryVariables>(MyServiceRequestsDocument, options);
      }
export function useMyServiceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyServiceRequestsQuery, MyServiceRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyServiceRequestsQuery, MyServiceRequestsQueryVariables>(MyServiceRequestsDocument, options);
        }
export type MyServiceRequestsQueryHookResult = ReturnType<typeof useMyServiceRequestsQuery>;
export type MyServiceRequestsLazyQueryHookResult = ReturnType<typeof useMyServiceRequestsLazyQuery>;
export type MyServiceRequestsQueryResult = Apollo.QueryResult<MyServiceRequestsQuery, MyServiceRequestsQueryVariables>;
export const CreateServiceDocument = gql`
    mutation CreateService($input: ServiceInput!) {
  createService(input: $input) {
    name
    code
    therapist_discipline
    is_direct
    is_indirect
    is_therapy
    is_evaluation
    is_support
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const DeleteServiceDocument = gql`
    mutation DeleteService($id: Int!) {
  deleteService(id: $id)
}
    `;
export type DeleteServiceMutationFn = Apollo.MutationFunction<DeleteServiceMutation, DeleteServiceMutationVariables>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceMutation, DeleteServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument, options);
      }
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<DeleteServiceMutation, DeleteServiceMutationVariables>;
export const ListServicesDocument = gql`
    query ListServices {
  services {
    id
    name
    code
    therapist_discipline
    is_direct
    is_indirect
    is_therapy
    is_evaluation
    is_support
    status
  }
}
    `;

/**
 * __useListServicesQuery__
 *
 * To run a query within a React component, call `useListServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListServicesQuery(baseOptions?: Apollo.QueryHookOptions<ListServicesQuery, ListServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListServicesQuery, ListServicesQueryVariables>(ListServicesDocument, options);
      }
export function useListServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListServicesQuery, ListServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListServicesQuery, ListServicesQueryVariables>(ListServicesDocument, options);
        }
export type ListServicesQueryHookResult = ReturnType<typeof useListServicesQuery>;
export type ListServicesLazyQueryHookResult = ReturnType<typeof useListServicesLazyQuery>;
export type ListServicesQueryResult = Apollo.QueryResult<ListServicesQuery, ListServicesQueryVariables>;
export const ServiceDocument = gql`
    query Service($id: Int!) {
  service(id: $id) {
    id
    name
    code
    therapist_discipline
    is_direct
    is_indirect
    is_therapy
    is_evaluation
    is_support
    status
  }
}
    `;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const UpdateServiceDocument = gql`
    mutation UpdateService($id: Int!, $input: ServiceInput!) {
  updateService(id: $id, input: $input)
}
    `;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const CreateStateDocument = gql`
    mutation CreateState($input: StateInput!) {
  createState(input: $input) {
    id
  }
}
    `;
export type CreateStateMutationFn = Apollo.MutationFunction<CreateStateMutation, CreateStateMutationVariables>;

/**
 * __useCreateStateMutation__
 *
 * To run a mutation, you first call `useCreateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStateMutation, { data, loading, error }] = useCreateStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStateMutation(baseOptions?: Apollo.MutationHookOptions<CreateStateMutation, CreateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStateMutation, CreateStateMutationVariables>(CreateStateDocument, options);
      }
export type CreateStateMutationHookResult = ReturnType<typeof useCreateStateMutation>;
export type CreateStateMutationResult = Apollo.MutationResult<CreateStateMutation>;
export type CreateStateMutationOptions = Apollo.BaseMutationOptions<CreateStateMutation, CreateStateMutationVariables>;
export const DeleteStateDocument = gql`
    mutation DeleteState($id: Int!) {
  deleteState(id: $id)
}
    `;
export type DeleteStateMutationFn = Apollo.MutationFunction<DeleteStateMutation, DeleteStateMutationVariables>;

/**
 * __useDeleteStateMutation__
 *
 * To run a mutation, you first call `useDeleteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStateMutation, { data, loading, error }] = useDeleteStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStateMutation, DeleteStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStateMutation, DeleteStateMutationVariables>(DeleteStateDocument, options);
      }
export type DeleteStateMutationHookResult = ReturnType<typeof useDeleteStateMutation>;
export type DeleteStateMutationResult = Apollo.MutationResult<DeleteStateMutation>;
export type DeleteStateMutationOptions = Apollo.BaseMutationOptions<DeleteStateMutation, DeleteStateMutationVariables>;
export const ListStatesDocument = gql`
    query ListStates {
  states {
    id
    name
    abbreviation
    medicaid_name
    city
  }
}
    `;

/**
 * __useListStatesQuery__
 *
 * To run a query within a React component, call `useListStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListStatesQuery(baseOptions?: Apollo.QueryHookOptions<ListStatesQuery, ListStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStatesQuery, ListStatesQueryVariables>(ListStatesDocument, options);
      }
export function useListStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStatesQuery, ListStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStatesQuery, ListStatesQueryVariables>(ListStatesDocument, options);
        }
export type ListStatesQueryHookResult = ReturnType<typeof useListStatesQuery>;
export type ListStatesLazyQueryHookResult = ReturnType<typeof useListStatesLazyQuery>;
export type ListStatesQueryResult = Apollo.QueryResult<ListStatesQuery, ListStatesQueryVariables>;
export const StateDocument = gql`
    query State($id: Int!) {
  state(id: $id) {
    name
    abbreviation
    medicaid_name
    ein
    street_address
    city
    zip
    contact_info
  }
}
    `;

/**
 * __useStateQuery__
 *
 * To run a query within a React component, call `useStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStateQuery(baseOptions: Apollo.QueryHookOptions<StateQuery, StateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StateQuery, StateQueryVariables>(StateDocument, options);
      }
export function useStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StateQuery, StateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StateQuery, StateQueryVariables>(StateDocument, options);
        }
export type StateQueryHookResult = ReturnType<typeof useStateQuery>;
export type StateLazyQueryHookResult = ReturnType<typeof useStateLazyQuery>;
export type StateQueryResult = Apollo.QueryResult<StateQuery, StateQueryVariables>;
export const UpdateStateDocument = gql`
    mutation UpdateState($id: Int!, $input: StateInput!) {
  updateState(id: $id, input: $input)
}
    `;
export type UpdateStateMutationFn = Apollo.MutationFunction<UpdateStateMutation, UpdateStateMutationVariables>;

/**
 * __useUpdateStateMutation__
 *
 * To run a mutation, you first call `useUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateMutation, { data, loading, error }] = useUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateMutation, UpdateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateMutation, UpdateStateMutationVariables>(UpdateStateDocument, options);
      }
export type UpdateStateMutationHookResult = ReturnType<typeof useUpdateStateMutation>;
export type UpdateStateMutationResult = Apollo.MutationResult<UpdateStateMutation>;
export type UpdateStateMutationOptions = Apollo.BaseMutationOptions<UpdateStateMutation, UpdateStateMutationVariables>;
export const ActiveproviderDocument = gql`
    query Activeprovider($student_id: Int) {
  activeproviders(student_id: $student_id) {
    id
    student_id
    time_period
    minutes
    student_first_name
    student_last_name
    provider_first_name
    provider_last_name
  }
}
    `;

/**
 * __useActiveproviderQuery__
 *
 * To run a query within a React component, call `useActiveproviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveproviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveproviderQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useActiveproviderQuery(baseOptions?: Apollo.QueryHookOptions<ActiveproviderQuery, ActiveproviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveproviderQuery, ActiveproviderQueryVariables>(ActiveproviderDocument, options);
      }
export function useActiveproviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveproviderQuery, ActiveproviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveproviderQuery, ActiveproviderQueryVariables>(ActiveproviderDocument, options);
        }
export type ActiveproviderQueryHookResult = ReturnType<typeof useActiveproviderQuery>;
export type ActiveproviderLazyQueryHookResult = ReturnType<typeof useActiveproviderLazyQuery>;
export type ActiveproviderQueryResult = Apollo.QueryResult<ActiveproviderQuery, ActiveproviderQueryVariables>;
export const CreateStudentDocument = gql`
    mutation CreateStudent($input: StudentInput!) {
  createStudent(input: $input) {
    id
    name
    student_id
    first_name
    last_name
    district {
      id
      name
    }
    school {
      id
      name
    }
    icdCode {
      id
      code
      name
    }
    grade {
      id
      grade
    }
    primaryLanguage {
      id
      name
    }
    contact_phone_number
    contact_email
    birth_date
    gender
    iep_date
    re_evl_date
    status
  }
}
    `;
export type CreateStudentMutationFn = Apollo.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, options);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = Apollo.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const DeleteStudentDocument = gql`
    mutation DeleteStudent($id: Int!) {
  deleteStudent(id: $id)
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const StudentSessionCountDocument = gql`
    query StudentSessionCount {
  StudentSessionCount {
    id
    name
    student_id
    first_name
    last_name
    district {
      id
      name
    }
    school {
      id
      name
    }
    icdCode {
      id
      code
    }
    grade {
      id
      grade
    }
    primaryLanguage {
      id
      name
    }
    insurance
    contact_phone_number
    contact_email
    birth_date
    gender
    iep_date
    re_evl_date
    status
    parent1_phone_number
    parent2_phone_number
    parent1_email
    parent2_email
    count
    parent_name
    unique_profile_id
  }
}
    `;

/**
 * __useStudentSessionCountQuery__
 *
 * To run a query within a React component, call `useStudentSessionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSessionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSessionCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentSessionCountQuery(baseOptions?: Apollo.QueryHookOptions<StudentSessionCountQuery, StudentSessionCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSessionCountQuery, StudentSessionCountQueryVariables>(StudentSessionCountDocument, options);
      }
export function useStudentSessionCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSessionCountQuery, StudentSessionCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSessionCountQuery, StudentSessionCountQueryVariables>(StudentSessionCountDocument, options);
        }
export type StudentSessionCountQueryHookResult = ReturnType<typeof useStudentSessionCountQuery>;
export type StudentSessionCountLazyQueryHookResult = ReturnType<typeof useStudentSessionCountLazyQuery>;
export type StudentSessionCountQueryResult = Apollo.QueryResult<StudentSessionCountQuery, StudentSessionCountQueryVariables>;
export const ListStudentsDocument = gql`
    query ListStudents {
  students {
    id
    name
    student_id
    first_name
    last_name
    district {
      id
      name
    }
    school {
      id
      name
    }
    icdCode {
      id
      code
    }
    grade {
      id
      grade
    }
    primaryLanguage {
      id
      name
    }
    contact_phone_number
    contact_email
    birth_date
    gender
    iep_date
    re_evl_date
    status
    parent1_phone_number
    parent2_phone_number
    parent1_email
    parent2_email
  }
}
    `;

/**
 * __useListStudentsQuery__
 *
 * To run a query within a React component, call `useListStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListStudentsQuery(baseOptions?: Apollo.QueryHookOptions<ListStudentsQuery, ListStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStudentsQuery, ListStudentsQueryVariables>(ListStudentsDocument, options);
      }
export function useListStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStudentsQuery, ListStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStudentsQuery, ListStudentsQueryVariables>(ListStudentsDocument, options);
        }
export type ListStudentsQueryHookResult = ReturnType<typeof useListStudentsQuery>;
export type ListStudentsLazyQueryHookResult = ReturnType<typeof useListStudentsLazyQuery>;
export type ListStudentsQueryResult = Apollo.QueryResult<ListStudentsQuery, ListStudentsQueryVariables>;
export const BulkUploadCsvDocument = gql`
    mutation BulkUploadCsv($district_id: Int, $user_id: String, $fileUpload: Upload!) {
  bulkUploadCsv(
    district_id: $district_id
    user_id: $user_id
    fileUpload: $fileUpload
  )
}
    `;
export type BulkUploadCsvMutationFn = Apollo.MutationFunction<BulkUploadCsvMutation, BulkUploadCsvMutationVariables>;

/**
 * __useBulkUploadCsvMutation__
 *
 * To run a mutation, you first call `useBulkUploadCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadCsvMutation, { data, loading, error }] = useBulkUploadCsvMutation({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      user_id: // value for 'user_id'
 *      fileUpload: // value for 'fileUpload'
 *   },
 * });
 */
export function useBulkUploadCsvMutation(baseOptions?: Apollo.MutationHookOptions<BulkUploadCsvMutation, BulkUploadCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUploadCsvMutation, BulkUploadCsvMutationVariables>(BulkUploadCsvDocument, options);
      }
export type BulkUploadCsvMutationHookResult = ReturnType<typeof useBulkUploadCsvMutation>;
export type BulkUploadCsvMutationResult = Apollo.MutationResult<BulkUploadCsvMutation>;
export type BulkUploadCsvMutationOptions = Apollo.BaseMutationOptions<BulkUploadCsvMutation, BulkUploadCsvMutationVariables>;
export const StudentDocument = gql`
    query Student($id: Int!) {
  student(id: $id) {
    id
    name
    student_id
    first_name
    last_name
    district {
      id
      name
    }
    school {
      id
      name
    }
    icdCode {
      id
      code
    }
    grade {
      id
      grade
    }
    primaryLanguage {
      id
      name
    }
    contact_phone_number
    contact_email
    birth_date
    gender
    iep_date
    re_evl_date
    status
    parent1_phone_number
    parent2_phone_number
    parent1_email
    parent2_email
  }
}
    `;

/**
 * __useStudentQuery__
 *
 * To run a query within a React component, call `useStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentQuery(baseOptions: Apollo.QueryHookOptions<StudentQuery, StudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentQuery, StudentQueryVariables>(StudentDocument, options);
      }
export function useStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentQuery, StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentQuery, StudentQueryVariables>(StudentDocument, options);
        }
export type StudentQueryHookResult = ReturnType<typeof useStudentQuery>;
export type StudentLazyQueryHookResult = ReturnType<typeof useStudentLazyQuery>;
export type StudentQueryResult = Apollo.QueryResult<StudentQuery, StudentQueryVariables>;
export const UpdateStudentDocument = gql`
    mutation UpdateStudent($id: Int!, $input: StudentInput!) {
  updateStudent(id: $id, input: $input)
}
    `;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const ListActiveProviderDocument = gql`
    query ListActiveProvider($student_id: Int!) {
  activeprovider(student_id: $student_id) {
    id
    iep_minutes
    minutes_per
    frequency
    billing_code
    user_id {
      id
      first_name
      last_name
      name
      therapist_discipline
    }
    students {
      id
      name
      contact_email
      iep_date
      re_evl_date
      birth_date
      contact_phone_number
      grade {
        grade
      }
      school {
        name
      }
      district {
        name
      }
      icdCode {
        id
        code
      }
    }
    session_dates {
      started_at
      ended_at
    }
  }
}
    `;

/**
 * __useListActiveProviderQuery__
 *
 * To run a query within a React component, call `useListActiveProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActiveProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveProviderQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useListActiveProviderQuery(baseOptions: Apollo.QueryHookOptions<ListActiveProviderQuery, ListActiveProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActiveProviderQuery, ListActiveProviderQueryVariables>(ListActiveProviderDocument, options);
      }
export function useListActiveProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActiveProviderQuery, ListActiveProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActiveProviderQuery, ListActiveProviderQueryVariables>(ListActiveProviderDocument, options);
        }
export type ListActiveProviderQueryHookResult = ReturnType<typeof useListActiveProviderQuery>;
export type ListActiveProviderLazyQueryHookResult = ReturnType<typeof useListActiveProviderLazyQuery>;
export type ListActiveProviderQueryResult = Apollo.QueryResult<ListActiveProviderQuery, ListActiveProviderQueryVariables>;
export const CaseloadProvidersDocument = gql`
    query CaseloadProviders($student_id: Int!) {
  caseloadProviders(student_id: $student_id) {
    user_id
    fullname
    therapist_discipline
    minutes_per
    frequency
    student_id
    first_session_date
    last_session_date
  }
}
    `;

/**
 * __useCaseloadProvidersQuery__
 *
 * To run a query within a React component, call `useCaseloadProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseloadProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseloadProvidersQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useCaseloadProvidersQuery(baseOptions: Apollo.QueryHookOptions<CaseloadProvidersQuery, CaseloadProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseloadProvidersQuery, CaseloadProvidersQueryVariables>(CaseloadProvidersDocument, options);
      }
export function useCaseloadProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseloadProvidersQuery, CaseloadProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseloadProvidersQuery, CaseloadProvidersQueryVariables>(CaseloadProvidersDocument, options);
        }
export type CaseloadProvidersQueryHookResult = ReturnType<typeof useCaseloadProvidersQuery>;
export type CaseloadProvidersLazyQueryHookResult = ReturnType<typeof useCaseloadProvidersLazyQuery>;
export type CaseloadProvidersQueryResult = Apollo.QueryResult<CaseloadProvidersQuery, CaseloadProvidersQueryVariables>;
export const AppointmentProfileDocument = gql`
    query AppointmentProfile($id: Int!) {
  appointmentProfile(id: $id) {
    session_date
    session_time
    provider_name
    service
    date_requested
    status
    attendance
  }
}
    `;

/**
 * __useAppointmentProfileQuery__
 *
 * To run a query within a React component, call `useAppointmentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentProfileQuery(baseOptions: Apollo.QueryHookOptions<AppointmentProfileQuery, AppointmentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentProfileQuery, AppointmentProfileQueryVariables>(AppointmentProfileDocument, options);
      }
export function useAppointmentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentProfileQuery, AppointmentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentProfileQuery, AppointmentProfileQueryVariables>(AppointmentProfileDocument, options);
        }
export type AppointmentProfileQueryHookResult = ReturnType<typeof useAppointmentProfileQuery>;
export type AppointmentProfileLazyQueryHookResult = ReturnType<typeof useAppointmentProfileLazyQuery>;
export type AppointmentProfileQueryResult = Apollo.QueryResult<AppointmentProfileQuery, AppointmentProfileQueryVariables>;
export const AppointmentProfileCountDocument = gql`
    query AppointmentProfileCount($id: Int!) {
  appointmentProfileCount(id: $id) {
    sessioncount
    cancelledcount
  }
}
    `;

/**
 * __useAppointmentProfileCountQuery__
 *
 * To run a query within a React component, call `useAppointmentProfileCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentProfileCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentProfileCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentProfileCountQuery(baseOptions: Apollo.QueryHookOptions<AppointmentProfileCountQuery, AppointmentProfileCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentProfileCountQuery, AppointmentProfileCountQueryVariables>(AppointmentProfileCountDocument, options);
      }
export function useAppointmentProfileCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentProfileCountQuery, AppointmentProfileCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentProfileCountQuery, AppointmentProfileCountQueryVariables>(AppointmentProfileCountDocument, options);
        }
export type AppointmentProfileCountQueryHookResult = ReturnType<typeof useAppointmentProfileCountQuery>;
export type AppointmentProfileCountLazyQueryHookResult = ReturnType<typeof useAppointmentProfileCountLazyQuery>;
export type AppointmentProfileCountQueryResult = Apollo.QueryResult<AppointmentProfileCountQuery, AppointmentProfileCountQueryVariables>;
export const StudentsDocument = gql`
    query Students($id: Int!) {
  student(id: $id) {
    id
    name
    first_name
    last_name
    contact_email
    re_evl_date
    birth_date
  }
}
    `;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentsQuery(baseOptions: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
      }
export function useStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
        }
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = Apollo.QueryResult<StudentsQuery, StudentsQueryVariables>;
export const ContactAttemptsDocument = gql`
    query contactAttempts($student_id: Int!) {
  contact_attempts(student_id: $student_id) {
    therapistId {
      id
      name
    }
    students {
      id
      name
    }
    subject
    to
    from
    cc
    bcc
    sent_date
    client_fill
  }
}
    `;

/**
 * __useContactAttemptsQuery__
 *
 * To run a query within a React component, call `useContactAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactAttemptsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useContactAttemptsQuery(baseOptions: Apollo.QueryHookOptions<ContactAttemptsQuery, ContactAttemptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactAttemptsQuery, ContactAttemptsQueryVariables>(ContactAttemptsDocument, options);
      }
export function useContactAttemptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactAttemptsQuery, ContactAttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactAttemptsQuery, ContactAttemptsQueryVariables>(ContactAttemptsDocument, options);
        }
export type ContactAttemptsQueryHookResult = ReturnType<typeof useContactAttemptsQuery>;
export type ContactAttemptsLazyQueryHookResult = ReturnType<typeof useContactAttemptsLazyQuery>;
export type ContactAttemptsQueryResult = Apollo.QueryResult<ContactAttemptsQuery, ContactAttemptsQueryVariables>;
export const ListCaseloadsDocument = gql`
    query ListCaseloads($id: String) {
  caseloads(id: $id) {
    id
    iep_minutes
    minutes_per
    frequency
    updated_by
    billing_code
    user_id {
      id
      therapist_discipline
    }
    students {
      id
      name
      contact_email
      iep_date
      re_evl_date
      birth_date
      contact_phone_number
      grade {
        grade
      }
      school {
        name
      }
      district {
        name
      }
      icdCode {
        id
        code
      }
      primaryLanguage {
        name
      }
      parent1_phone_number
      parent2_phone_number
      parent1_email
      parent2_email
      gender
    }
    planned_sessions
    notes
    private_notes
  }
}
    `;

/**
 * __useListCaseloadsQuery__
 *
 * To run a query within a React component, call `useListCaseloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCaseloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCaseloadsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListCaseloadsQuery(baseOptions?: Apollo.QueryHookOptions<ListCaseloadsQuery, ListCaseloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCaseloadsQuery, ListCaseloadsQueryVariables>(ListCaseloadsDocument, options);
      }
export function useListCaseloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCaseloadsQuery, ListCaseloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCaseloadsQuery, ListCaseloadsQueryVariables>(ListCaseloadsDocument, options);
        }
export type ListCaseloadsQueryHookResult = ReturnType<typeof useListCaseloadsQuery>;
export type ListCaseloadsLazyQueryHookResult = ReturnType<typeof useListCaseloadsLazyQuery>;
export type ListCaseloadsQueryResult = Apollo.QueryResult<ListCaseloadsQuery, ListCaseloadsQueryVariables>;
export const ListStudentsProfileDocument = gql`
    query ListStudentsProfile($id: Int!) {
  studentsProfile(id: $id) {
    id
    name
    student_id
    first_name
    last_name
    contact_phone_number
    contact_email
    birth_date
    gender
    insurance_company {
      id
      name
    }
    student_specialities {
      speciality_id {
        id
        name
      }
    }
    session_service {
      id
      name
    }
    address
    accommodations
    city
    zip
    parent1_phone_number
    parent2_phone_number
    phonecheck
    parentphonecheck1
    parentphonecheck2
    parent1_email
    parent2_email
    parent_name
    parent2_name
    unique_profile_id
    primaryLanguage {
      id
      name
    }
    pronouns {
      id
      name
    }
    county {
      id
      name
    }
  }
}
    `;

/**
 * __useListStudentsProfileQuery__
 *
 * To run a query within a React component, call `useListStudentsProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStudentsProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStudentsProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListStudentsProfileQuery(baseOptions: Apollo.QueryHookOptions<ListStudentsProfileQuery, ListStudentsProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStudentsProfileQuery, ListStudentsProfileQueryVariables>(ListStudentsProfileDocument, options);
      }
export function useListStudentsProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStudentsProfileQuery, ListStudentsProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStudentsProfileQuery, ListStudentsProfileQueryVariables>(ListStudentsProfileDocument, options);
        }
export type ListStudentsProfileQueryHookResult = ReturnType<typeof useListStudentsProfileQuery>;
export type ListStudentsProfileLazyQueryHookResult = ReturnType<typeof useListStudentsProfileLazyQuery>;
export type ListStudentsProfileQueryResult = Apollo.QueryResult<ListStudentsProfileQuery, ListStudentsProfileQueryVariables>;
export const UpdateStudentProfileDocument = gql`
    mutation updateStudentProfile($id: Int!, $input: StudentProfileInput!) {
  updateStudentProfile(id: $id, input: $input)
}
    `;
export type UpdateStudentProfileMutationFn = Apollo.MutationFunction<UpdateStudentProfileMutation, UpdateStudentProfileMutationVariables>;

/**
 * __useUpdateStudentProfileMutation__
 *
 * To run a mutation, you first call `useUpdateStudentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentProfileMutation, { data, loading, error }] = useUpdateStudentProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentProfileMutation, UpdateStudentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentProfileMutation, UpdateStudentProfileMutationVariables>(UpdateStudentProfileDocument, options);
      }
export type UpdateStudentProfileMutationHookResult = ReturnType<typeof useUpdateStudentProfileMutation>;
export type UpdateStudentProfileMutationResult = Apollo.MutationResult<UpdateStudentProfileMutation>;
export type UpdateStudentProfileMutationOptions = Apollo.BaseMutationOptions<UpdateStudentProfileMutation, UpdateStudentProfileMutationVariables>;
export const UpdateCaseloadDocument = gql`
    mutation updateCaseload($id: ID!, $input: CaseloadUpdate!) {
  updateCaseload(id: $id, input: $input)
}
    `;
export type UpdateCaseloadMutationFn = Apollo.MutationFunction<UpdateCaseloadMutation, UpdateCaseloadMutationVariables>;

/**
 * __useUpdateCaseloadMutation__
 *
 * To run a mutation, you first call `useUpdateCaseloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseloadMutation, { data, loading, error }] = useUpdateCaseloadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaseloadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaseloadMutation, UpdateCaseloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaseloadMutation, UpdateCaseloadMutationVariables>(UpdateCaseloadDocument, options);
      }
export type UpdateCaseloadMutationHookResult = ReturnType<typeof useUpdateCaseloadMutation>;
export type UpdateCaseloadMutationResult = Apollo.MutationResult<UpdateCaseloadMutation>;
export type UpdateCaseloadMutationOptions = Apollo.BaseMutationOptions<UpdateCaseloadMutation, UpdateCaseloadMutationVariables>;
export const DistAdminDocumentDocument = gql`
    query DistAdminDocument($district_id: Int, $FromDate: String, $ToDate: String, $user_id: String, $StuId: Int) {
  distAdminDocument(
    district_id: $district_id
    FromDate: $FromDate
    ToDate: $ToDate
    user_id: $user_id
    StuId: $StuId
  ) {
    id
    scheduled_start
    scheduled_end
    sdate
    edate
    stime
    etime
    sign
    appointment_id {
      id
      service_id {
        name
      }
    }
    user_id {
      id
      name
    }
    session_Goals {
      goal_id {
        id
        name
        goal_number
      }
      student_id {
        id
        name
      }
      activity
      parent_goal_id
      assist
      attempts
      percentage
      total
    }
    notes
    plan
    session_students {
      student_id {
        id
        name
      }
      session_id {
        outcome_id {
          id
          name
        }
      }
      notes
      plan
      present
      assessment
      further_sessions
      further_sessions_text
      high_level_care
      high_level_care_text
    }
  }
}
    `;

/**
 * __useDistAdminDocumentQuery__
 *
 * To run a query within a React component, call `useDistAdminDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistAdminDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistAdminDocumentQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      user_id: // value for 'user_id'
 *      StuId: // value for 'StuId'
 *   },
 * });
 */
export function useDistAdminDocumentQuery(baseOptions?: Apollo.QueryHookOptions<DistAdminDocumentQuery, DistAdminDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistAdminDocumentQuery, DistAdminDocumentQueryVariables>(DistAdminDocumentDocument, options);
      }
export function useDistAdminDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistAdminDocumentQuery, DistAdminDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistAdminDocumentQuery, DistAdminDocumentQueryVariables>(DistAdminDocumentDocument, options);
        }
export type DistAdminDocumentQueryHookResult = ReturnType<typeof useDistAdminDocumentQuery>;
export type DistAdminDocumentLazyQueryHookResult = ReturnType<typeof useDistAdminDocumentLazyQuery>;
export type DistAdminDocumentQueryResult = Apollo.QueryResult<DistAdminDocumentQuery, DistAdminDocumentQueryVariables>;
export const DeleteIncompleteSessionsDocument = gql`
    mutation DeleteIncompleteSessions($sessionId: String!) {
  deleteIncompleteSessions(sessionId: $sessionId)
}
    `;
export type DeleteIncompleteSessionsMutationFn = Apollo.MutationFunction<DeleteIncompleteSessionsMutation, DeleteIncompleteSessionsMutationVariables>;

/**
 * __useDeleteIncompleteSessionsMutation__
 *
 * To run a mutation, you first call `useDeleteIncompleteSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncompleteSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncompleteSessionsMutation, { data, loading, error }] = useDeleteIncompleteSessionsMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDeleteIncompleteSessionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncompleteSessionsMutation, DeleteIncompleteSessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncompleteSessionsMutation, DeleteIncompleteSessionsMutationVariables>(DeleteIncompleteSessionsDocument, options);
      }
export type DeleteIncompleteSessionsMutationHookResult = ReturnType<typeof useDeleteIncompleteSessionsMutation>;
export type DeleteIncompleteSessionsMutationResult = Apollo.MutationResult<DeleteIncompleteSessionsMutation>;
export type DeleteIncompleteSessionsMutationOptions = Apollo.BaseMutationOptions<DeleteIncompleteSessionsMutation, DeleteIncompleteSessionsMutationVariables>;
export const StudentDocumentationDocument = gql`
    query StudentDocumentation($student_id: Int, $therapist_discipline: String, $FromDate: String, $ToDate: String) {
  studentDocumentation(
    student_id: $student_id
    therapist_discipline: $therapist_discipline
    FromDate: $FromDate
    ToDate: $ToDate
  ) {
    session_id {
      id
      scheduled_start
      scheduled_end
      sign
      user_id {
        name
      }
      outcome_id {
        id
        name
      }
      appointment_id {
        id
        service_id {
          name
        }
      }
    }
    session_Goals {
      id
      goal_id {
        id
        name
        goal_number
      }
      activity
      parent_goal_id
      assist
      attempts
      percentage
      total
    }
    student_id {
      id
      name
    }
    notes
    plan
    present
    assessment
    further_sessions
    further_sessions_text
    high_level_care
    high_level_care_text
    sdate
    edate
    stime
    etime
  }
}
    `;

/**
 * __useStudentDocumentationQuery__
 *
 * To run a query within a React component, call `useStudentDocumentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentationQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      therapist_discipline: // value for 'therapist_discipline'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useStudentDocumentationQuery(baseOptions?: Apollo.QueryHookOptions<StudentDocumentationQuery, StudentDocumentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentDocumentationQuery, StudentDocumentationQueryVariables>(StudentDocumentationDocument, options);
      }
export function useStudentDocumentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentationQuery, StudentDocumentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentDocumentationQuery, StudentDocumentationQueryVariables>(StudentDocumentationDocument, options);
        }
export type StudentDocumentationQueryHookResult = ReturnType<typeof useStudentDocumentationQuery>;
export type StudentDocumentationLazyQueryHookResult = ReturnType<typeof useStudentDocumentationLazyQuery>;
export type StudentDocumentationQueryResult = Apollo.QueryResult<StudentDocumentationQuery, StudentDocumentationQueryVariables>;
export const OutComeDocument = gql`
    query OutCome {
  outCome {
    id
    name
  }
}
    `;

/**
 * __useOutComeQuery__
 *
 * To run a query within a React component, call `useOutComeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutComeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutComeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutComeQuery(baseOptions?: Apollo.QueryHookOptions<OutComeQuery, OutComeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutComeQuery, OutComeQueryVariables>(OutComeDocument, options);
      }
export function useOutComeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutComeQuery, OutComeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutComeQuery, OutComeQueryVariables>(OutComeDocument, options);
        }
export type OutComeQueryHookResult = ReturnType<typeof useOutComeQuery>;
export type OutComeLazyQueryHookResult = ReturnType<typeof useOutComeLazyQuery>;
export type OutComeQueryResult = Apollo.QueryResult<OutComeQuery, OutComeQueryVariables>;
export const UserStudentsForDocDocument = gql`
    query UserStudentsForDoc($user_id: String!) {
  userStudentsForDoc(user_id: $user_id) {
    id
    student_name
  }
}
    `;

/**
 * __useUserStudentsForDocQuery__
 *
 * To run a query within a React component, call `useUserStudentsForDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudentsForDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudentsForDocQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserStudentsForDocQuery(baseOptions: Apollo.QueryHookOptions<UserStudentsForDocQuery, UserStudentsForDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserStudentsForDocQuery, UserStudentsForDocQueryVariables>(UserStudentsForDocDocument, options);
      }
export function useUserStudentsForDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStudentsForDocQuery, UserStudentsForDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserStudentsForDocQuery, UserStudentsForDocQueryVariables>(UserStudentsForDocDocument, options);
        }
export type UserStudentsForDocQueryHookResult = ReturnType<typeof useUserStudentsForDocQuery>;
export type UserStudentsForDocLazyQueryHookResult = ReturnType<typeof useUserStudentsForDocLazyQuery>;
export type UserStudentsForDocQueryResult = Apollo.QueryResult<UserStudentsForDocQuery, UserStudentsForDocQueryVariables>;
export const TherapistListForDocDocument = gql`
    query TherapistListForDoc($district_id: Int!) {
  therapistListForDoc(district_id: $district_id) {
    id
    name
  }
}
    `;

/**
 * __useTherapistListForDocQuery__
 *
 * To run a query within a React component, call `useTherapistListForDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useTherapistListForDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTherapistListForDocQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useTherapistListForDocQuery(baseOptions: Apollo.QueryHookOptions<TherapistListForDocQuery, TherapistListForDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TherapistListForDocQuery, TherapistListForDocQueryVariables>(TherapistListForDocDocument, options);
      }
export function useTherapistListForDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TherapistListForDocQuery, TherapistListForDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TherapistListForDocQuery, TherapistListForDocQueryVariables>(TherapistListForDocDocument, options);
        }
export type TherapistListForDocQueryHookResult = ReturnType<typeof useTherapistListForDocQuery>;
export type TherapistListForDocLazyQueryHookResult = ReturnType<typeof useTherapistListForDocLazyQuery>;
export type TherapistListForDocQueryResult = Apollo.QueryResult<TherapistListForDocQuery, TherapistListForDocQueryVariables>;
export const UserDocumentDocument = gql`
    query UserDocument($user_id: String, $FromDate: String, $ToDate: String, $StuId: Int) {
  userDocument(
    user_id: $user_id
    FromDate: $FromDate
    ToDate: $ToDate
    StuId: $StuId
  ) {
    id
    scheduled_start
    scheduled_end
    sdate
    edate
    stime
    etime
    sign
    appointment_id {
      id
      service_id {
        name
      }
    }
    user_id {
      id
      name
    }
    session_Goals {
      goal_id {
        id
        name
        goal_number
      }
      student_id {
        id
        name
      }
      activity
      parent_goal_id
      assist
      attempts
      percentage
      total
    }
    notes
    plan
    session_students {
      student_id {
        id
        name
      }
      session_id {
        outcome_id {
          id
          name
        }
      }
      notes
      plan
      present
      assessment
      further_sessions
      further_sessions_text
      high_level_care
      high_level_care_text
    }
  }
}
    `;

/**
 * __useUserDocumentQuery__
 *
 * To run a query within a React component, call `useUserDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDocumentQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      StuId: // value for 'StuId'
 *   },
 * });
 */
export function useUserDocumentQuery(baseOptions?: Apollo.QueryHookOptions<UserDocumentQuery, UserDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDocumentQuery, UserDocumentQueryVariables>(UserDocumentDocument, options);
      }
export function useUserDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDocumentQuery, UserDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDocumentQuery, UserDocumentQueryVariables>(UserDocumentDocument, options);
        }
export type UserDocumentQueryHookResult = ReturnType<typeof useUserDocumentQuery>;
export type UserDocumentLazyQueryHookResult = ReturnType<typeof useUserDocumentLazyQuery>;
export type UserDocumentQueryResult = Apollo.QueryResult<UserDocumentQuery, UserDocumentQueryVariables>;
export const CreateGoalsDocument = gql`
    mutation CreateGoals($input: StudentGoalsInput!, $BenchmarkGoalsInput: [BenchmarkGoalsInput!], $Tracker: [Tracker!]) {
  createGoals(
    input: $input
    BenchmarkGoalsInput: $BenchmarkGoalsInput
    Tracker: $Tracker
  ) {
    id
  }
}
    `;
export type CreateGoalsMutationFn = Apollo.MutationFunction<CreateGoalsMutation, CreateGoalsMutationVariables>;

/**
 * __useCreateGoalsMutation__
 *
 * To run a mutation, you first call `useCreateGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalsMutation, { data, loading, error }] = useCreateGoalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      BenchmarkGoalsInput: // value for 'BenchmarkGoalsInput'
 *      Tracker: // value for 'Tracker'
 *   },
 * });
 */
export function useCreateGoalsMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoalsMutation, CreateGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGoalsMutation, CreateGoalsMutationVariables>(CreateGoalsDocument, options);
      }
export type CreateGoalsMutationHookResult = ReturnType<typeof useCreateGoalsMutation>;
export type CreateGoalsMutationResult = Apollo.MutationResult<CreateGoalsMutation>;
export type CreateGoalsMutationOptions = Apollo.BaseMutationOptions<CreateGoalsMutation, CreateGoalsMutationVariables>;
export const GoalsDocument = gql`
    query Goals($student_id: Int) {
  goals(student_id: $student_id) {
    id
    name
    goal_number
    user_id
    goal_type_id {
      id
      goal_type
    }
    target
    baseline
    parent_goal_id {
      id
      goal_number
      name
      target
      trackers {
        id
        icon
        name
        tracker_type_id {
          id
        }
        tracker_icon_id
      }
    }
    trackers {
      id
      icon
      name
      tracker_type_id {
        id
      }
      tracker_icon_id
    }
  }
}
    `;

/**
 * __useGoalsQuery__
 *
 * To run a query within a React component, call `useGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGoalsQuery(baseOptions?: Apollo.QueryHookOptions<GoalsQuery, GoalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalsQuery, GoalsQueryVariables>(GoalsDocument, options);
      }
export function useGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalsQuery, GoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalsQuery, GoalsQueryVariables>(GoalsDocument, options);
        }
export type GoalsQueryHookResult = ReturnType<typeof useGoalsQuery>;
export type GoalsLazyQueryHookResult = ReturnType<typeof useGoalsLazyQuery>;
export type GoalsQueryResult = Apollo.QueryResult<GoalsQuery, GoalsQueryVariables>;
export const ListstudentIepsDocument = gql`
    query ListstudentIeps($student_id: Int!) {
  studentIeps(student_id: $student_id) {
    id
    student_id {
      id
    }
    date_reeval
    date_end
    date_initiated
    date_annual_review
  }
}
    `;

/**
 * __useListstudentIepsQuery__
 *
 * To run a query within a React component, call `useListstudentIepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListstudentIepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListstudentIepsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useListstudentIepsQuery(baseOptions: Apollo.QueryHookOptions<ListstudentIepsQuery, ListstudentIepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListstudentIepsQuery, ListstudentIepsQueryVariables>(ListstudentIepsDocument, options);
      }
export function useListstudentIepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListstudentIepsQuery, ListstudentIepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListstudentIepsQuery, ListstudentIepsQueryVariables>(ListstudentIepsDocument, options);
        }
export type ListstudentIepsQueryHookResult = ReturnType<typeof useListstudentIepsQuery>;
export type ListstudentIepsLazyQueryHookResult = ReturnType<typeof useListstudentIepsLazyQuery>;
export type ListstudentIepsQueryResult = Apollo.QueryResult<ListstudentIepsQuery, ListstudentIepsQueryVariables>;
export const GoalNumberCheckDocument = gql`
    query goalNumberCheck($goal_number: Float!, $student_id: Int) {
  goalNumberCheck(goal_number: $goal_number, student_id: $student_id)
}
    `;

/**
 * __useGoalNumberCheckQuery__
 *
 * To run a query within a React component, call `useGoalNumberCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalNumberCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalNumberCheckQuery({
 *   variables: {
 *      goal_number: // value for 'goal_number'
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGoalNumberCheckQuery(baseOptions: Apollo.QueryHookOptions<GoalNumberCheckQuery, GoalNumberCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalNumberCheckQuery, GoalNumberCheckQueryVariables>(GoalNumberCheckDocument, options);
      }
export function useGoalNumberCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalNumberCheckQuery, GoalNumberCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalNumberCheckQuery, GoalNumberCheckQueryVariables>(GoalNumberCheckDocument, options);
        }
export type GoalNumberCheckQueryHookResult = ReturnType<typeof useGoalNumberCheckQuery>;
export type GoalNumberCheckLazyQueryHookResult = ReturnType<typeof useGoalNumberCheckLazyQuery>;
export type GoalNumberCheckQueryResult = Apollo.QueryResult<GoalNumberCheckQuery, GoalNumberCheckQueryVariables>;
export const GoalProgressDocument = gql`
    query goalProgress($student_id: Int) {
  goalProgress(student_id: $student_id) {
    student_id
    goal_id
    overall
    last_3_progress
    last_1_progress
  }
}
    `;

/**
 * __useGoalProgressQuery__
 *
 * To run a query within a React component, call `useGoalProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalProgressQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useGoalProgressQuery(baseOptions?: Apollo.QueryHookOptions<GoalProgressQuery, GoalProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoalProgressQuery, GoalProgressQueryVariables>(GoalProgressDocument, options);
      }
export function useGoalProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoalProgressQuery, GoalProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoalProgressQuery, GoalProgressQueryVariables>(GoalProgressDocument, options);
        }
export type GoalProgressQueryHookResult = ReturnType<typeof useGoalProgressQuery>;
export type GoalProgressLazyQueryHookResult = ReturnType<typeof useGoalProgressLazyQuery>;
export type GoalProgressQueryResult = Apollo.QueryResult<GoalProgressQuery, GoalProgressQueryVariables>;
export const UpdateGoalsDocument = gql`
    mutation UpdateGoals($id: [ID!]!, $input: [StudentGoalsInput!]!, $BenchmarkGoalsInput: [BenchmarkGoalsInput!], $Tracker: [Tracker!]) {
  updateGoals(
    id: $id
    input: $input
    BenchmarkGoalsInput: $BenchmarkGoalsInput
    Tracker: $Tracker
  )
}
    `;
export type UpdateGoalsMutationFn = Apollo.MutationFunction<UpdateGoalsMutation, UpdateGoalsMutationVariables>;

/**
 * __useUpdateGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalsMutation, { data, loading, error }] = useUpdateGoalsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      BenchmarkGoalsInput: // value for 'BenchmarkGoalsInput'
 *      Tracker: // value for 'Tracker'
 *   },
 * });
 */
export function useUpdateGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoalsMutation, UpdateGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoalsMutation, UpdateGoalsMutationVariables>(UpdateGoalsDocument, options);
      }
export type UpdateGoalsMutationHookResult = ReturnType<typeof useUpdateGoalsMutation>;
export type UpdateGoalsMutationResult = Apollo.MutationResult<UpdateGoalsMutation>;
export type UpdateGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateGoalsMutation, UpdateGoalsMutationVariables>;
export const UpdateGoalsTrackerDocument = gql`
    mutation UpdateGoalsTracker($id: ID!, $input: GoalsTrackerInput!) {
  updateGoalsTracker(id: $id, input: $input)
}
    `;
export type UpdateGoalsTrackerMutationFn = Apollo.MutationFunction<UpdateGoalsTrackerMutation, UpdateGoalsTrackerMutationVariables>;

/**
 * __useUpdateGoalsTrackerMutation__
 *
 * To run a mutation, you first call `useUpdateGoalsTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalsTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalsTrackerMutation, { data, loading, error }] = useUpdateGoalsTrackerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoalsTrackerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoalsTrackerMutation, UpdateGoalsTrackerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoalsTrackerMutation, UpdateGoalsTrackerMutationVariables>(UpdateGoalsTrackerDocument, options);
      }
export type UpdateGoalsTrackerMutationHookResult = ReturnType<typeof useUpdateGoalsTrackerMutation>;
export type UpdateGoalsTrackerMutationResult = Apollo.MutationResult<UpdateGoalsTrackerMutation>;
export type UpdateGoalsTrackerMutationOptions = Apollo.BaseMutationOptions<UpdateGoalsTrackerMutation, UpdateGoalsTrackerMutationVariables>;
export const TrackerUpdateDocument = gql`
    mutation TrackerUpdate($id: [ID!]!, $Tracker: [Tracker!]!) {
  trackersUpdate(id: $id, Tracker: $Tracker)
}
    `;
export type TrackerUpdateMutationFn = Apollo.MutationFunction<TrackerUpdateMutation, TrackerUpdateMutationVariables>;

/**
 * __useTrackerUpdateMutation__
 *
 * To run a mutation, you first call `useTrackerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackerUpdateMutation, { data, loading, error }] = useTrackerUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      Tracker: // value for 'Tracker'
 *   },
 * });
 */
export function useTrackerUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TrackerUpdateMutation, TrackerUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackerUpdateMutation, TrackerUpdateMutationVariables>(TrackerUpdateDocument, options);
      }
export type TrackerUpdateMutationHookResult = ReturnType<typeof useTrackerUpdateMutation>;
export type TrackerUpdateMutationResult = Apollo.MutationResult<TrackerUpdateMutation>;
export type TrackerUpdateMutationOptions = Apollo.BaseMutationOptions<TrackerUpdateMutation, TrackerUpdateMutationVariables>;
export const UpdateIepsDocument = gql`
    mutation UpdateIeps($id: ID!, $input: StudentIepsInput!) {
  updateIeps(id: $id, input: $input)
}
    `;
export type UpdateIepsMutationFn = Apollo.MutationFunction<UpdateIepsMutation, UpdateIepsMutationVariables>;

/**
 * __useUpdateIepsMutation__
 *
 * To run a mutation, you first call `useUpdateIepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIepsMutation, { data, loading, error }] = useUpdateIepsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIepsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIepsMutation, UpdateIepsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIepsMutation, UpdateIepsMutationVariables>(UpdateIepsDocument, options);
      }
export type UpdateIepsMutationHookResult = ReturnType<typeof useUpdateIepsMutation>;
export type UpdateIepsMutationResult = Apollo.MutationResult<UpdateIepsMutation>;
export type UpdateIepsMutationOptions = Apollo.BaseMutationOptions<UpdateIepsMutation, UpdateIepsMutationVariables>;
export const DeleteGoalsTrackerDocument = gql`
    mutation DeleteGoalsTracker($id: [ID!]!) {
  deleteGoalsTracker(id: $id)
}
    `;
export type DeleteGoalsTrackerMutationFn = Apollo.MutationFunction<DeleteGoalsTrackerMutation, DeleteGoalsTrackerMutationVariables>;

/**
 * __useDeleteGoalsTrackerMutation__
 *
 * To run a mutation, you first call `useDeleteGoalsTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoalsTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoalsTrackerMutation, { data, loading, error }] = useDeleteGoalsTrackerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGoalsTrackerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoalsTrackerMutation, DeleteGoalsTrackerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGoalsTrackerMutation, DeleteGoalsTrackerMutationVariables>(DeleteGoalsTrackerDocument, options);
      }
export type DeleteGoalsTrackerMutationHookResult = ReturnType<typeof useDeleteGoalsTrackerMutation>;
export type DeleteGoalsTrackerMutationResult = Apollo.MutationResult<DeleteGoalsTrackerMutation>;
export type DeleteGoalsTrackerMutationOptions = Apollo.BaseMutationOptions<DeleteGoalsTrackerMutation, DeleteGoalsTrackerMutationVariables>;
export const StudentSessionsCountDocument = gql`
    query StudentSessionsCount($student_id: Int) {
  StudentSessionsCount(student_id: $student_id) {
    count
  }
}
    `;

/**
 * __useStudentSessionsCountQuery__
 *
 * To run a query within a React component, call `useStudentSessionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSessionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSessionsCountQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useStudentSessionsCountQuery(baseOptions?: Apollo.QueryHookOptions<StudentSessionsCountQuery, StudentSessionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSessionsCountQuery, StudentSessionsCountQueryVariables>(StudentSessionsCountDocument, options);
      }
export function useStudentSessionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSessionsCountQuery, StudentSessionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSessionsCountQuery, StudentSessionsCountQueryVariables>(StudentSessionsCountDocument, options);
        }
export type StudentSessionsCountQueryHookResult = ReturnType<typeof useStudentSessionsCountQuery>;
export type StudentSessionsCountLazyQueryHookResult = ReturnType<typeof useStudentSessionsCountLazyQuery>;
export type StudentSessionsCountQueryResult = Apollo.QueryResult<StudentSessionsCountQuery, StudentSessionsCountQueryVariables>;
export const CreateFileUploadDocument = gql`
    mutation createFileUpload($input: SignalFileUploadInput!) {
  createFileUpload(input: $input) {
    categories {
      categories
    }
  }
}
    `;
export type CreateFileUploadMutationFn = Apollo.MutationFunction<CreateFileUploadMutation, CreateFileUploadMutationVariables>;

/**
 * __useCreateFileUploadMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadMutation, { data, loading, error }] = useCreateFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileUploadMutation, CreateFileUploadMutationVariables>(CreateFileUploadDocument, options);
      }
export type CreateFileUploadMutationHookResult = ReturnType<typeof useCreateFileUploadMutation>;
export type CreateFileUploadMutationResult = Apollo.MutationResult<CreateFileUploadMutation>;
export type CreateFileUploadMutationOptions = Apollo.BaseMutationOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>;
export const MyCategoriesDocument = gql`
    query MyCategories {
  myCategories {
    id
    categories
  }
}
    `;

/**
 * __useMyCategoriesQuery__
 *
 * To run a query within a React component, call `useMyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MyCategoriesQuery, MyCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCategoriesQuery, MyCategoriesQueryVariables>(MyCategoriesDocument, options);
      }
export function useMyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCategoriesQuery, MyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCategoriesQuery, MyCategoriesQueryVariables>(MyCategoriesDocument, options);
        }
export type MyCategoriesQueryHookResult = ReturnType<typeof useMyCategoriesQuery>;
export type MyCategoriesLazyQueryHookResult = ReturnType<typeof useMyCategoriesLazyQuery>;
export type MyCategoriesQueryResult = Apollo.QueryResult<MyCategoriesQuery, MyCategoriesQueryVariables>;
export const FileuploadDocument = gql`
    query fileupload($student_id: Int) {
  fileUploadList(student_id: $student_id) {
    id
    user_id {
      id
      first_name
      last_name
    }
    file_name
    fileUrl
    created_by
    updated_by
    created_on
    updated_on
    categories {
      categories
    }
  }
}
    `;

/**
 * __useFileuploadQuery__
 *
 * To run a query within a React component, call `useFileuploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileuploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileuploadQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useFileuploadQuery(baseOptions?: Apollo.QueryHookOptions<FileuploadQuery, FileuploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileuploadQuery, FileuploadQueryVariables>(FileuploadDocument, options);
      }
export function useFileuploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileuploadQuery, FileuploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileuploadQuery, FileuploadQueryVariables>(FileuploadDocument, options);
        }
export type FileuploadQueryHookResult = ReturnType<typeof useFileuploadQuery>;
export type FileuploadLazyQueryHookResult = ReturnType<typeof useFileuploadLazyQuery>;
export type FileuploadQueryResult = Apollo.QueryResult<FileuploadQuery, FileuploadQueryVariables>;
export const DeleteFileUploadDocument = gql`
    mutation deleteFileUpload($id: String!) {
  deleteFileUpload(id: $id)
}
    `;
export type DeleteFileUploadMutationFn = Apollo.MutationFunction<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>;

/**
 * __useDeleteFileUploadMutation__
 *
 * To run a mutation, you first call `useDeleteFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileUploadMutation, { data, loading, error }] = useDeleteFileUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>(DeleteFileUploadDocument, options);
      }
export type DeleteFileUploadMutationHookResult = ReturnType<typeof useDeleteFileUploadMutation>;
export type DeleteFileUploadMutationResult = Apollo.MutationResult<DeleteFileUploadMutation>;
export type DeleteFileUploadMutationOptions = Apollo.BaseMutationOptions<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>;
export const MandatoryResendDetailsDocument = gql`
    query MandatoryResendDetails($student_id: Int) {
  mandatoryResendDetails(student_id: $student_id) {
    id
    user_id {
      id
      name
      street_address
      license_number
      cert_date
      signalAgencies {
        id
        name
      }
    }
    student_id {
      id
      name
      primaryLanguage {
        id
        name
      }
    }
    status_id {
      id
      name
    }
    comment
    licenses_and_certifications
    degreesheld_and_schoolattended
    clinician_phone
    specializedtraining_and_areasofclinicalcompetence
    supervisorname_and_credentials
    relationship_to_client_id {
      id
      name
      spanish_name
    }
    user_sign
    student_sign
    user_date
    student_date
    school_attended
    session_id {
      id
    }
  }
}
    `;

/**
 * __useMandatoryResendDetailsQuery__
 *
 * To run a query within a React component, call `useMandatoryResendDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatoryResendDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatoryResendDetailsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useMandatoryResendDetailsQuery(baseOptions?: Apollo.QueryHookOptions<MandatoryResendDetailsQuery, MandatoryResendDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatoryResendDetailsQuery, MandatoryResendDetailsQueryVariables>(MandatoryResendDetailsDocument, options);
      }
export function useMandatoryResendDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatoryResendDetailsQuery, MandatoryResendDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatoryResendDetailsQuery, MandatoryResendDetailsQueryVariables>(MandatoryResendDetailsDocument, options);
        }
export type MandatoryResendDetailsQueryHookResult = ReturnType<typeof useMandatoryResendDetailsQuery>;
export type MandatoryResendDetailsLazyQueryHookResult = ReturnType<typeof useMandatoryResendDetailsLazyQuery>;
export type MandatoryResendDetailsQueryResult = Apollo.QueryResult<MandatoryResendDetailsQuery, MandatoryResendDetailsQueryVariables>;
export const ReSendMandatoryEmailDocument = gql`
    mutation ReSendMandatoryEmail($id: String!) {
  reSendMandatoryEmail(id: $id)
}
    `;
export type ReSendMandatoryEmailMutationFn = Apollo.MutationFunction<ReSendMandatoryEmailMutation, ReSendMandatoryEmailMutationVariables>;

/**
 * __useReSendMandatoryEmailMutation__
 *
 * To run a mutation, you first call `useReSendMandatoryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSendMandatoryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSendMandatoryEmailMutation, { data, loading, error }] = useReSendMandatoryEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReSendMandatoryEmailMutation(baseOptions?: Apollo.MutationHookOptions<ReSendMandatoryEmailMutation, ReSendMandatoryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReSendMandatoryEmailMutation, ReSendMandatoryEmailMutationVariables>(ReSendMandatoryEmailDocument, options);
      }
export type ReSendMandatoryEmailMutationHookResult = ReturnType<typeof useReSendMandatoryEmailMutation>;
export type ReSendMandatoryEmailMutationResult = Apollo.MutationResult<ReSendMandatoryEmailMutation>;
export type ReSendMandatoryEmailMutationOptions = Apollo.BaseMutationOptions<ReSendMandatoryEmailMutation, ReSendMandatoryEmailMutationVariables>;
export const MandatoryPdfListCallDocument = gql`
    query mandatoryPdfListCall($id: String) {
  mandatoryListCall(id: $id) {
    id
    user_id {
      id
      name
      street_address
      license_number
      cert_date
      signalAgencies {
        id
        name
      }
    }
    student_id {
      id
      name
      primaryLanguage {
        id
        name
      }
    }
    status_id {
      id
      name
    }
    comment
    licenses_and_certifications
    degreesheld_and_schoolattended
    clinician_phone
    specializedtraining_and_areasofclinicalcompetence
    supervisorname_and_credentials
    relationship_to_client_id {
      id
      name
      spanish_name
    }
    user_sign
    student_sign
    user_date
    student_date
    school_attended
    session_id {
      id
    }
  }
}
    `;

/**
 * __useMandatoryPdfListCallQuery__
 *
 * To run a query within a React component, call `useMandatoryPdfListCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatoryPdfListCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatoryPdfListCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMandatoryPdfListCallQuery(baseOptions?: Apollo.QueryHookOptions<MandatoryPdfListCallQuery, MandatoryPdfListCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatoryPdfListCallQuery, MandatoryPdfListCallQueryVariables>(MandatoryPdfListCallDocument, options);
      }
export function useMandatoryPdfListCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatoryPdfListCallQuery, MandatoryPdfListCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatoryPdfListCallQuery, MandatoryPdfListCallQueryVariables>(MandatoryPdfListCallDocument, options);
        }
export type MandatoryPdfListCallQueryHookResult = ReturnType<typeof useMandatoryPdfListCallQuery>;
export type MandatoryPdfListCallLazyQueryHookResult = ReturnType<typeof useMandatoryPdfListCallLazyQuery>;
export type MandatoryPdfListCallQueryResult = Apollo.QueryResult<MandatoryPdfListCallQuery, MandatoryPdfListCallQueryVariables>;
export const NoteCategoryDocument = gql`
    query NoteCategory {
  noteCategory {
    id
    description
    name
    user_visible
    category_type_id {
      id
      name
    }
  }
}
    `;

/**
 * __useNoteCategoryQuery__
 *
 * To run a query within a React component, call `useNoteCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteCategoryQuery(baseOptions?: Apollo.QueryHookOptions<NoteCategoryQuery, NoteCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteCategoryQuery, NoteCategoryQueryVariables>(NoteCategoryDocument, options);
      }
export function useNoteCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteCategoryQuery, NoteCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteCategoryQuery, NoteCategoryQueryVariables>(NoteCategoryDocument, options);
        }
export type NoteCategoryQueryHookResult = ReturnType<typeof useNoteCategoryQuery>;
export type NoteCategoryLazyQueryHookResult = ReturnType<typeof useNoteCategoryLazyQuery>;
export type NoteCategoryQueryResult = Apollo.QueryResult<NoteCategoryQuery, NoteCategoryQueryVariables>;
export const NotesDocument = gql`
    query Notes($student_id: Int!) {
  notes(student_id: $student_id) {
    id
    note
    reference_id
    created_at
    mt_date
    createdby {
      id
      first_name
      last_name
      name
    }
    category_id {
      id
      name
    }
    student_id {
      id
      name
    }
  }
}
    `;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useNotesQuery(baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const CreateNotesDocument = gql`
    mutation CreateNotes($input: NotesInput!) {
  createNotes(input: $input) {
    id
  }
}
    `;
export type CreateNotesMutationFn = Apollo.MutationFunction<CreateNotesMutation, CreateNotesMutationVariables>;

/**
 * __useCreateNotesMutation__
 *
 * To run a mutation, you first call `useCreateNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotesMutation, { data, loading, error }] = useCreateNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotesMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotesMutation, CreateNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotesMutation, CreateNotesMutationVariables>(CreateNotesDocument, options);
      }
export type CreateNotesMutationHookResult = ReturnType<typeof useCreateNotesMutation>;
export type CreateNotesMutationResult = Apollo.MutationResult<CreateNotesMutation>;
export type CreateNotesMutationOptions = Apollo.BaseMutationOptions<CreateNotesMutation, CreateNotesMutationVariables>;
export const SignalTherapistUpdateViewDocument = gql`
    query SignalTherapistUpdateView($id: ID!) {
  signalTherapistUpdateView(id: $id) {
    id
    name
    email
    picture
    description
    userAvailabilityInput
  }
}
    `;

/**
 * __useSignalTherapistUpdateViewQuery__
 *
 * To run a query within a React component, call `useSignalTherapistUpdateViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalTherapistUpdateViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalTherapistUpdateViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignalTherapistUpdateViewQuery(baseOptions: Apollo.QueryHookOptions<SignalTherapistUpdateViewQuery, SignalTherapistUpdateViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalTherapistUpdateViewQuery, SignalTherapistUpdateViewQueryVariables>(SignalTherapistUpdateViewDocument, options);
      }
export function useSignalTherapistUpdateViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalTherapistUpdateViewQuery, SignalTherapistUpdateViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalTherapistUpdateViewQuery, SignalTherapistUpdateViewQueryVariables>(SignalTherapistUpdateViewDocument, options);
        }
export type SignalTherapistUpdateViewQueryHookResult = ReturnType<typeof useSignalTherapistUpdateViewQuery>;
export type SignalTherapistUpdateViewLazyQueryHookResult = ReturnType<typeof useSignalTherapistUpdateViewLazyQuery>;
export type SignalTherapistUpdateViewQueryResult = Apollo.QueryResult<SignalTherapistUpdateViewQuery, SignalTherapistUpdateViewQueryVariables>;
export const UpdateSignalTherapistDocument = gql`
    mutation UpdateSignalTherapist($id: ID!, $input: SignalTherapistInput!) {
  updateSignalTherapist(id: $id, input: $input)
}
    `;
export type UpdateSignalTherapistMutationFn = Apollo.MutationFunction<UpdateSignalTherapistMutation, UpdateSignalTherapistMutationVariables>;

/**
 * __useUpdateSignalTherapistMutation__
 *
 * To run a mutation, you first call `useUpdateSignalTherapistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignalTherapistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignalTherapistMutation, { data, loading, error }] = useUpdateSignalTherapistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSignalTherapistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignalTherapistMutation, UpdateSignalTherapistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignalTherapistMutation, UpdateSignalTherapistMutationVariables>(UpdateSignalTherapistDocument, options);
      }
export type UpdateSignalTherapistMutationHookResult = ReturnType<typeof useUpdateSignalTherapistMutation>;
export type UpdateSignalTherapistMutationResult = Apollo.MutationResult<UpdateSignalTherapistMutation>;
export type UpdateSignalTherapistMutationOptions = Apollo.BaseMutationOptions<UpdateSignalTherapistMutation, UpdateSignalTherapistMutationVariables>;
export const CreateUserSlotsDocument = gql`
    mutation CreateUserSlots($input: [therapistAvailabilityInput!]) {
  createUserSlots(input: $input)
}
    `;
export type CreateUserSlotsMutationFn = Apollo.MutationFunction<CreateUserSlotsMutation, CreateUserSlotsMutationVariables>;

/**
 * __useCreateUserSlotsMutation__
 *
 * To run a mutation, you first call `useCreateUserSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSlotsMutation, { data, loading, error }] = useCreateUserSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserSlotsMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSlotsMutation, CreateUserSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSlotsMutation, CreateUserSlotsMutationVariables>(CreateUserSlotsDocument, options);
      }
export type CreateUserSlotsMutationHookResult = ReturnType<typeof useCreateUserSlotsMutation>;
export type CreateUserSlotsMutationResult = Apollo.MutationResult<CreateUserSlotsMutation>;
export type CreateUserSlotsMutationOptions = Apollo.BaseMutationOptions<CreateUserSlotsMutation, CreateUserSlotsMutationVariables>;
export const DeleteUserSlotsDocument = gql`
    mutation deleteUserSlots($id: [Int!]!) {
  deleteUserSlots(id: $id)
}
    `;
export type DeleteUserSlotsMutationFn = Apollo.MutationFunction<DeleteUserSlotsMutation, DeleteUserSlotsMutationVariables>;

/**
 * __useDeleteUserSlotsMutation__
 *
 * To run a mutation, you first call `useDeleteUserSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSlotsMutation, { data, loading, error }] = useDeleteUserSlotsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSlotsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSlotsMutation, DeleteUserSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSlotsMutation, DeleteUserSlotsMutationVariables>(DeleteUserSlotsDocument, options);
      }
export type DeleteUserSlotsMutationHookResult = ReturnType<typeof useDeleteUserSlotsMutation>;
export type DeleteUserSlotsMutationResult = Apollo.MutationResult<DeleteUserSlotsMutation>;
export type DeleteUserSlotsMutationOptions = Apollo.BaseMutationOptions<DeleteUserSlotsMutation, DeleteUserSlotsMutationVariables>;
export const ListTrainingDocument = gql`
    query ListTraining {
  reportshistory {
    IMatter_Provider_Manual
    YRMHR_Provider_Manual
  }
}
    `;

/**
 * __useListTrainingQuery__
 *
 * To run a query within a React component, call `useListTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrainingQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTrainingQuery(baseOptions?: Apollo.QueryHookOptions<ListTrainingQuery, ListTrainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTrainingQuery, ListTrainingQueryVariables>(ListTrainingDocument, options);
      }
export function useListTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTrainingQuery, ListTrainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTrainingQuery, ListTrainingQueryVariables>(ListTrainingDocument, options);
        }
export type ListTrainingQueryHookResult = ReturnType<typeof useListTrainingQuery>;
export type ListTrainingLazyQueryHookResult = ReturnType<typeof useListTrainingLazyQuery>;
export type ListTrainingQueryResult = Apollo.QueryResult<ListTrainingQuery, ListTrainingQueryVariables>;
export const GetNewProfileSlotsDocument = gql`
    query getNewProfileSlots($user_id: String, $timeperiod: DateTime) {
  getNewProfileSlots(user_id: $user_id, timeperiod: $timeperiod)
}
    `;

/**
 * __useGetNewProfileSlotsQuery__
 *
 * To run a query within a React component, call `useGetNewProfileSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewProfileSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewProfileSlotsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      timeperiod: // value for 'timeperiod'
 *   },
 * });
 */
export function useGetNewProfileSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewProfileSlotsQuery, GetNewProfileSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewProfileSlotsQuery, GetNewProfileSlotsQueryVariables>(GetNewProfileSlotsDocument, options);
      }
export function useGetNewProfileSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewProfileSlotsQuery, GetNewProfileSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewProfileSlotsQuery, GetNewProfileSlotsQueryVariables>(GetNewProfileSlotsDocument, options);
        }
export type GetNewProfileSlotsQueryHookResult = ReturnType<typeof useGetNewProfileSlotsQuery>;
export type GetNewProfileSlotsLazyQueryHookResult = ReturnType<typeof useGetNewProfileSlotsLazyQuery>;
export type GetNewProfileSlotsQueryResult = Apollo.QueryResult<GetNewProfileSlotsQuery, GetNewProfileSlotsQueryVariables>;
export const GetUpdateSlotsListDocument = gql`
    query getUpdateSlotsList($user_id: String, $slot_date: DateTime) {
  getUpdateSlotsList(user_id: $user_id, slot_date: $slot_date)
}
    `;

/**
 * __useGetUpdateSlotsListQuery__
 *
 * To run a query within a React component, call `useGetUpdateSlotsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateSlotsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateSlotsListQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      slot_date: // value for 'slot_date'
 *   },
 * });
 */
export function useGetUpdateSlotsListQuery(baseOptions?: Apollo.QueryHookOptions<GetUpdateSlotsListQuery, GetUpdateSlotsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateSlotsListQuery, GetUpdateSlotsListQueryVariables>(GetUpdateSlotsListDocument, options);
      }
export function useGetUpdateSlotsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateSlotsListQuery, GetUpdateSlotsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateSlotsListQuery, GetUpdateSlotsListQueryVariables>(GetUpdateSlotsListDocument, options);
        }
export type GetUpdateSlotsListQueryHookResult = ReturnType<typeof useGetUpdateSlotsListQuery>;
export type GetUpdateSlotsListLazyQueryHookResult = ReturnType<typeof useGetUpdateSlotsListLazyQuery>;
export type GetUpdateSlotsListQueryResult = Apollo.QueryResult<GetUpdateSlotsListQuery, GetUpdateSlotsListQueryVariables>;
export const GetUserAvailableProfileSlotsDocument = gql`
    query getUserAvailableProfileSlots($slot_date: DateTime, $user_id: String) {
  getUserAvailableProfileSlots(slot_date: $slot_date, user_id: $user_id)
}
    `;

/**
 * __useGetUserAvailableProfileSlotsQuery__
 *
 * To run a query within a React component, call `useGetUserAvailableProfileSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAvailableProfileSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAvailableProfileSlotsQuery({
 *   variables: {
 *      slot_date: // value for 'slot_date'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserAvailableProfileSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAvailableProfileSlotsQuery, GetUserAvailableProfileSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAvailableProfileSlotsQuery, GetUserAvailableProfileSlotsQueryVariables>(GetUserAvailableProfileSlotsDocument, options);
      }
export function useGetUserAvailableProfileSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAvailableProfileSlotsQuery, GetUserAvailableProfileSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAvailableProfileSlotsQuery, GetUserAvailableProfileSlotsQueryVariables>(GetUserAvailableProfileSlotsDocument, options);
        }
export type GetUserAvailableProfileSlotsQueryHookResult = ReturnType<typeof useGetUserAvailableProfileSlotsQuery>;
export type GetUserAvailableProfileSlotsLazyQueryHookResult = ReturnType<typeof useGetUserAvailableProfileSlotsLazyQuery>;
export type GetUserAvailableProfileSlotsQueryResult = Apollo.QueryResult<GetUserAvailableProfileSlotsQuery, GetUserAvailableProfileSlotsQueryVariables>;
export const SignalServicesDocument = gql`
    query signalServices {
  signalServices {
    id
    name
  }
}
    `;

/**
 * __useSignalServicesQuery__
 *
 * To run a query within a React component, call `useSignalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignalServicesQuery(baseOptions?: Apollo.QueryHookOptions<SignalServicesQuery, SignalServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalServicesQuery, SignalServicesQueryVariables>(SignalServicesDocument, options);
      }
export function useSignalServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalServicesQuery, SignalServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalServicesQuery, SignalServicesQueryVariables>(SignalServicesDocument, options);
        }
export type SignalServicesQueryHookResult = ReturnType<typeof useSignalServicesQuery>;
export type SignalServicesLazyQueryHookResult = ReturnType<typeof useSignalServicesLazyQuery>;
export type SignalServicesQueryResult = Apollo.QueryResult<SignalServicesQuery, SignalServicesQueryVariables>;
export const GetUserDetailsInProfileDocument = gql`
    query getUserDetailsInProfile($user_id: String) {
  getUserDetailsInProfile(user_id: $user_id)
}
    `;

/**
 * __useGetUserDetailsInProfileQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsInProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsInProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsInProfileQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserDetailsInProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDetailsInProfileQuery, GetUserDetailsInProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsInProfileQuery, GetUserDetailsInProfileQueryVariables>(GetUserDetailsInProfileDocument, options);
      }
export function useGetUserDetailsInProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsInProfileQuery, GetUserDetailsInProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsInProfileQuery, GetUserDetailsInProfileQueryVariables>(GetUserDetailsInProfileDocument, options);
        }
export type GetUserDetailsInProfileQueryHookResult = ReturnType<typeof useGetUserDetailsInProfileQuery>;
export type GetUserDetailsInProfileLazyQueryHookResult = ReturnType<typeof useGetUserDetailsInProfileLazyQuery>;
export type GetUserDetailsInProfileQueryResult = Apollo.QueryResult<GetUserDetailsInProfileQuery, GetUserDetailsInProfileQueryVariables>;
export const GetMasterSlotsDocument = gql`
    query getMasterSlots($slot_date: DateTime, $user_id: String) {
  getMasterSlots(slot_date: $slot_date, user_id: $user_id)
}
    `;

/**
 * __useGetMasterSlotsQuery__
 *
 * To run a query within a React component, call `useGetMasterSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterSlotsQuery({
 *   variables: {
 *      slot_date: // value for 'slot_date'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetMasterSlotsQuery(baseOptions?: Apollo.QueryHookOptions<GetMasterSlotsQuery, GetMasterSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterSlotsQuery, GetMasterSlotsQueryVariables>(GetMasterSlotsDocument, options);
      }
export function useGetMasterSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterSlotsQuery, GetMasterSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterSlotsQuery, GetMasterSlotsQueryVariables>(GetMasterSlotsDocument, options);
        }
export type GetMasterSlotsQueryHookResult = ReturnType<typeof useGetMasterSlotsQuery>;
export type GetMasterSlotsLazyQueryHookResult = ReturnType<typeof useGetMasterSlotsLazyQuery>;
export type GetMasterSlotsQueryResult = Apollo.QueryResult<GetMasterSlotsQuery, GetMasterSlotsQueryVariables>;
export const CreateNewProfileSlotsDocument = gql`
    mutation createNewProfileSlots($input: [ProfileSlotsInput!]) {
  createNewProfileSlots(input: $input)
}
    `;
export type CreateNewProfileSlotsMutationFn = Apollo.MutationFunction<CreateNewProfileSlotsMutation, CreateNewProfileSlotsMutationVariables>;

/**
 * __useCreateNewProfileSlotsMutation__
 *
 * To run a mutation, you first call `useCreateNewProfileSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewProfileSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewProfileSlotsMutation, { data, loading, error }] = useCreateNewProfileSlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewProfileSlotsMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewProfileSlotsMutation, CreateNewProfileSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewProfileSlotsMutation, CreateNewProfileSlotsMutationVariables>(CreateNewProfileSlotsDocument, options);
      }
export type CreateNewProfileSlotsMutationHookResult = ReturnType<typeof useCreateNewProfileSlotsMutation>;
export type CreateNewProfileSlotsMutationResult = Apollo.MutationResult<CreateNewProfileSlotsMutation>;
export type CreateNewProfileSlotsMutationOptions = Apollo.BaseMutationOptions<CreateNewProfileSlotsMutation, CreateNewProfileSlotsMutationVariables>;
export const DeleteNewProfileSlotsDocument = gql`
    mutation deleteNewProfileSlots($id: [Int!]!) {
  deleteNewProfileSlots(id: $id)
}
    `;
export type DeleteNewProfileSlotsMutationFn = Apollo.MutationFunction<DeleteNewProfileSlotsMutation, DeleteNewProfileSlotsMutationVariables>;

/**
 * __useDeleteNewProfileSlotsMutation__
 *
 * To run a mutation, you first call `useDeleteNewProfileSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewProfileSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewProfileSlotsMutation, { data, loading, error }] = useDeleteNewProfileSlotsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewProfileSlotsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewProfileSlotsMutation, DeleteNewProfileSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNewProfileSlotsMutation, DeleteNewProfileSlotsMutationVariables>(DeleteNewProfileSlotsDocument, options);
      }
export type DeleteNewProfileSlotsMutationHookResult = ReturnType<typeof useDeleteNewProfileSlotsMutation>;
export type DeleteNewProfileSlotsMutationResult = Apollo.MutationResult<DeleteNewProfileSlotsMutation>;
export type DeleteNewProfileSlotsMutationOptions = Apollo.BaseMutationOptions<DeleteNewProfileSlotsMutation, DeleteNewProfileSlotsMutationVariables>;
export const UpdateRecurringSlotsDocument = gql`
    mutation updateRecurringSlots($user_id: String, $recurrence: Boolean, $description: String, $picture: String) {
  updateRecurringSlots(
    user_id: $user_id
    recurrence: $recurrence
    description: $description
    picture: $picture
  )
}
    `;
export type UpdateRecurringSlotsMutationFn = Apollo.MutationFunction<UpdateRecurringSlotsMutation, UpdateRecurringSlotsMutationVariables>;

/**
 * __useUpdateRecurringSlotsMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringSlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringSlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringSlotsMutation, { data, loading, error }] = useUpdateRecurringSlotsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      recurrence: // value for 'recurrence'
 *      description: // value for 'description'
 *      picture: // value for 'picture'
 *   },
 * });
 */
export function useUpdateRecurringSlotsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecurringSlotsMutation, UpdateRecurringSlotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecurringSlotsMutation, UpdateRecurringSlotsMutationVariables>(UpdateRecurringSlotsDocument, options);
      }
export type UpdateRecurringSlotsMutationHookResult = ReturnType<typeof useUpdateRecurringSlotsMutation>;
export type UpdateRecurringSlotsMutationResult = Apollo.MutationResult<UpdateRecurringSlotsMutation>;
export type UpdateRecurringSlotsMutationOptions = Apollo.BaseMutationOptions<UpdateRecurringSlotsMutation, UpdateRecurringSlotsMutationVariables>;
export const ListUseragencyDocument = gql`
    query ListUseragency($user_id: String) {
  useragency(user_id: $user_id) {
    agency
    agency_address
    agency_id
  }
}
    `;

/**
 * __useListUseragencyQuery__
 *
 * To run a query within a React component, call `useListUseragencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUseragencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUseragencyQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUseragencyQuery(baseOptions?: Apollo.QueryHookOptions<ListUseragencyQuery, ListUseragencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUseragencyQuery, ListUseragencyQueryVariables>(ListUseragencyDocument, options);
      }
export function useListUseragencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUseragencyQuery, ListUseragencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUseragencyQuery, ListUseragencyQueryVariables>(ListUseragencyDocument, options);
        }
export type ListUseragencyQueryHookResult = ReturnType<typeof useListUseragencyQuery>;
export type ListUseragencyLazyQueryHookResult = ReturnType<typeof useListUseragencyLazyQuery>;
export type ListUseragencyQueryResult = Apollo.QueryResult<ListUseragencyQuery, ListUseragencyQueryVariables>;
export const AgencyCheckDocument = gql`
    query AgencyCheck($agency_id: Int) {
  agencycheck(agency_id: $agency_id)
}
    `;

/**
 * __useAgencyCheckQuery__
 *
 * To run a query within a React component, call `useAgencyCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyCheckQuery({
 *   variables: {
 *      agency_id: // value for 'agency_id'
 *   },
 * });
 */
export function useAgencyCheckQuery(baseOptions?: Apollo.QueryHookOptions<AgencyCheckQuery, AgencyCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyCheckQuery, AgencyCheckQueryVariables>(AgencyCheckDocument, options);
      }
export function useAgencyCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyCheckQuery, AgencyCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyCheckQuery, AgencyCheckQueryVariables>(AgencyCheckDocument, options);
        }
export type AgencyCheckQueryHookResult = ReturnType<typeof useAgencyCheckQuery>;
export type AgencyCheckLazyQueryHookResult = ReturnType<typeof useAgencyCheckLazyQuery>;
export type AgencyCheckQueryResult = Apollo.QueryResult<AgencyCheckQuery, AgencyCheckQueryVariables>;
export const ListUserAgegroupDocument = gql`
    query ListUserAgegroup($user_id: String) {
  useragegroup(user_id: $user_id) {
    name
    age_group_id
    min_age
    max_age
  }
}
    `;

/**
 * __useListUserAgegroupQuery__
 *
 * To run a query within a React component, call `useListUserAgegroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserAgegroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserAgegroupQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserAgegroupQuery(baseOptions?: Apollo.QueryHookOptions<ListUserAgegroupQuery, ListUserAgegroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserAgegroupQuery, ListUserAgegroupQueryVariables>(ListUserAgegroupDocument, options);
      }
export function useListUserAgegroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserAgegroupQuery, ListUserAgegroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserAgegroupQuery, ListUserAgegroupQueryVariables>(ListUserAgegroupDocument, options);
        }
export type ListUserAgegroupQueryHookResult = ReturnType<typeof useListUserAgegroupQuery>;
export type ListUserAgegroupLazyQueryHookResult = ReturnType<typeof useListUserAgegroupLazyQuery>;
export type ListUserAgegroupQueryResult = Apollo.QueryResult<ListUserAgegroupQuery, ListUserAgegroupQueryVariables>;
export const ListUserLanguageDocument = gql`
    query ListUserLanguage($user_id: String) {
  userlanguage(user_id: $user_id) {
    name
    language_id
  }
}
    `;

/**
 * __useListUserLanguageQuery__
 *
 * To run a query within a React component, call `useListUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserLanguageQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserLanguageQuery(baseOptions?: Apollo.QueryHookOptions<ListUserLanguageQuery, ListUserLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserLanguageQuery, ListUserLanguageQueryVariables>(ListUserLanguageDocument, options);
      }
export function useListUserLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserLanguageQuery, ListUserLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserLanguageQuery, ListUserLanguageQueryVariables>(ListUserLanguageDocument, options);
        }
export type ListUserLanguageQueryHookResult = ReturnType<typeof useListUserLanguageQuery>;
export type ListUserLanguageLazyQueryHookResult = ReturnType<typeof useListUserLanguageLazyQuery>;
export type ListUserLanguageQueryResult = Apollo.QueryResult<ListUserLanguageQuery, ListUserLanguageQueryVariables>;
export const ListUserCountyDocument = gql`
    query ListUserCounty($user_id: String) {
  usercounty(user_id: $user_id) {
    name
    county_id
  }
}
    `;

/**
 * __useListUserCountyQuery__
 *
 * To run a query within a React component, call `useListUserCountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserCountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserCountyQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserCountyQuery(baseOptions?: Apollo.QueryHookOptions<ListUserCountyQuery, ListUserCountyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserCountyQuery, ListUserCountyQueryVariables>(ListUserCountyDocument, options);
      }
export function useListUserCountyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserCountyQuery, ListUserCountyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserCountyQuery, ListUserCountyQueryVariables>(ListUserCountyDocument, options);
        }
export type ListUserCountyQueryHookResult = ReturnType<typeof useListUserCountyQuery>;
export type ListUserCountyLazyQueryHookResult = ReturnType<typeof useListUserCountyLazyQuery>;
export type ListUserCountyQueryResult = Apollo.QueryResult<ListUserCountyQuery, ListUserCountyQueryVariables>;
export const ListUserDisciplineDocument = gql`
    query ListUserDiscipline($user_id: String) {
  userdiscipline(user_id: $user_id) {
    name
    discipline_id
  }
}
    `;

/**
 * __useListUserDisciplineQuery__
 *
 * To run a query within a React component, call `useListUserDisciplineQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDisciplineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDisciplineQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserDisciplineQuery(baseOptions?: Apollo.QueryHookOptions<ListUserDisciplineQuery, ListUserDisciplineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserDisciplineQuery, ListUserDisciplineQueryVariables>(ListUserDisciplineDocument, options);
      }
export function useListUserDisciplineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserDisciplineQuery, ListUserDisciplineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserDisciplineQuery, ListUserDisciplineQueryVariables>(ListUserDisciplineDocument, options);
        }
export type ListUserDisciplineQueryHookResult = ReturnType<typeof useListUserDisciplineQuery>;
export type ListUserDisciplineLazyQueryHookResult = ReturnType<typeof useListUserDisciplineLazyQuery>;
export type ListUserDisciplineQueryResult = Apollo.QueryResult<ListUserDisciplineQuery, ListUserDisciplineQueryVariables>;
export const ListUserInsuranceDocument = gql`
    query ListUserInsurance($user_id: String) {
  userinsurance(user_id: $user_id) {
    name
    insurance_id
  }
}
    `;

/**
 * __useListUserInsuranceQuery__
 *
 * To run a query within a React component, call `useListUserInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserInsuranceQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserInsuranceQuery(baseOptions?: Apollo.QueryHookOptions<ListUserInsuranceQuery, ListUserInsuranceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserInsuranceQuery, ListUserInsuranceQueryVariables>(ListUserInsuranceDocument, options);
      }
export function useListUserInsuranceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserInsuranceQuery, ListUserInsuranceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserInsuranceQuery, ListUserInsuranceQueryVariables>(ListUserInsuranceDocument, options);
        }
export type ListUserInsuranceQueryHookResult = ReturnType<typeof useListUserInsuranceQuery>;
export type ListUserInsuranceLazyQueryHookResult = ReturnType<typeof useListUserInsuranceLazyQuery>;
export type ListUserInsuranceQueryResult = Apollo.QueryResult<ListUserInsuranceQuery, ListUserInsuranceQueryVariables>;
export const ListUserSpecialityDocument = gql`
    query ListUserSpeciality($user_id: String) {
  userspeciality(user_id: $user_id) {
    name
    speciality_id
  }
}
    `;

/**
 * __useListUserSpecialityQuery__
 *
 * To run a query within a React component, call `useListUserSpecialityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserSpecialityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserSpecialityQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListUserSpecialityQuery(baseOptions?: Apollo.QueryHookOptions<ListUserSpecialityQuery, ListUserSpecialityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserSpecialityQuery, ListUserSpecialityQueryVariables>(ListUserSpecialityDocument, options);
      }
export function useListUserSpecialityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserSpecialityQuery, ListUserSpecialityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserSpecialityQuery, ListUserSpecialityQueryVariables>(ListUserSpecialityDocument, options);
        }
export type ListUserSpecialityQueryHookResult = ReturnType<typeof useListUserSpecialityQuery>;
export type ListUserSpecialityLazyQueryHookResult = ReturnType<typeof useListUserSpecialityLazyQuery>;
export type ListUserSpecialityQueryResult = Apollo.QueryResult<ListUserSpecialityQuery, ListUserSpecialityQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($district_id: Int) {
  therapists(district_id: $district_id) {
    id
    first_name
    last_name
    middle_initial
    maiden_name
    roles
    email
    mobile_phone
    zip
    city
    status
    home_phone
    street_address
    license_number
    cert_date
    cert_expire_date
    state_license {
      id
      name
      abbreviation
    }
    therapist_discipline
    add_student_permission {
      add_student_permission
    }
    district {
      id
      name
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const CreateUserDistrictDocument = gql`
    mutation CreateUserDistrict($input: [UserDistrictInput!]) {
  createUserDistrict(input: $input) {
    hourly_rate
  }
}
    `;
export type CreateUserDistrictMutationFn = Apollo.MutationFunction<CreateUserDistrictMutation, CreateUserDistrictMutationVariables>;

/**
 * __useCreateUserDistrictMutation__
 *
 * To run a mutation, you first call `useCreateUserDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDistrictMutation, { data, loading, error }] = useCreateUserDistrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserDistrictMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserDistrictMutation, CreateUserDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserDistrictMutation, CreateUserDistrictMutationVariables>(CreateUserDistrictDocument, options);
      }
export type CreateUserDistrictMutationHookResult = ReturnType<typeof useCreateUserDistrictMutation>;
export type CreateUserDistrictMutationResult = Apollo.MutationResult<CreateUserDistrictMutation>;
export type CreateUserDistrictMutationOptions = Apollo.BaseMutationOptions<CreateUserDistrictMutation, CreateUserDistrictMutationVariables>;
export const UpdateAddStudentPermissionDocument = gql`
    mutation updateAddStudentPermission($user_id: String!, $input: updatePrimaryUserDistrictsInput!) {
  updateAddStudentPermission(user_id: $user_id, input: $input)
}
    `;
export type UpdateAddStudentPermissionMutationFn = Apollo.MutationFunction<UpdateAddStudentPermissionMutation, UpdateAddStudentPermissionMutationVariables>;

/**
 * __useUpdateAddStudentPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateAddStudentPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddStudentPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddStudentPermissionMutation, { data, loading, error }] = useUpdateAddStudentPermissionMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddStudentPermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddStudentPermissionMutation, UpdateAddStudentPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddStudentPermissionMutation, UpdateAddStudentPermissionMutationVariables>(UpdateAddStudentPermissionDocument, options);
      }
export type UpdateAddStudentPermissionMutationHookResult = ReturnType<typeof useUpdateAddStudentPermissionMutation>;
export type UpdateAddStudentPermissionMutationResult = Apollo.MutationResult<UpdateAddStudentPermissionMutation>;
export type UpdateAddStudentPermissionMutationOptions = Apollo.BaseMutationOptions<UpdateAddStudentPermissionMutation, UpdateAddStudentPermissionMutationVariables>;
export const DeleteUserListDistrictDocument = gql`
    mutation DeleteUserListDistrict($user_id: String!, $input: [deleteUserListDistrictsInput!]) {
  deleteUserListDistricts(user_id: $user_id, input: $input)
}
    `;
export type DeleteUserListDistrictMutationFn = Apollo.MutationFunction<DeleteUserListDistrictMutation, DeleteUserListDistrictMutationVariables>;

/**
 * __useDeleteUserListDistrictMutation__
 *
 * To run a mutation, you first call `useDeleteUserListDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserListDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserListDistrictMutation, { data, loading, error }] = useDeleteUserListDistrictMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserListDistrictMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserListDistrictMutation, DeleteUserListDistrictMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserListDistrictMutation, DeleteUserListDistrictMutationVariables>(DeleteUserListDistrictDocument, options);
      }
export type DeleteUserListDistrictMutationHookResult = ReturnType<typeof useDeleteUserListDistrictMutation>;
export type DeleteUserListDistrictMutationResult = Apollo.MutationResult<DeleteUserListDistrictMutation>;
export type DeleteUserListDistrictMutationOptions = Apollo.BaseMutationOptions<DeleteUserListDistrictMutation, DeleteUserListDistrictMutationVariables>;
export const DisplayAddStudentPermissionDocument = gql`
    query DisplayAddStudentPermission($user_id: String!, $id: String!) {
  displayAddStudentPermission(user_id: $user_id, id: $id) {
    user_id
    add_student_permission
    alt_id
    hourly_rate
    status
  }
}
    `;

/**
 * __useDisplayAddStudentPermissionQuery__
 *
 * To run a query within a React component, call `useDisplayAddStudentPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAddStudentPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAddStudentPermissionQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisplayAddStudentPermissionQuery(baseOptions: Apollo.QueryHookOptions<DisplayAddStudentPermissionQuery, DisplayAddStudentPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayAddStudentPermissionQuery, DisplayAddStudentPermissionQueryVariables>(DisplayAddStudentPermissionDocument, options);
      }
export function useDisplayAddStudentPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayAddStudentPermissionQuery, DisplayAddStudentPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayAddStudentPermissionQuery, DisplayAddStudentPermissionQueryVariables>(DisplayAddStudentPermissionDocument, options);
        }
export type DisplayAddStudentPermissionQueryHookResult = ReturnType<typeof useDisplayAddStudentPermissionQuery>;
export type DisplayAddStudentPermissionLazyQueryHookResult = ReturnType<typeof useDisplayAddStudentPermissionLazyQuery>;
export type DisplayAddStudentPermissionQueryResult = Apollo.QueryResult<DisplayAddStudentPermissionQuery, DisplayAddStudentPermissionQueryVariables>;
export const UpdateAddStudentPermissionForUserDistrictsDocument = gql`
    mutation UpdateAddStudentPermissionForUserDistricts($user_id: String!, $input: [updateAddStudentPermissionForUserDistrictsInput]) {
  updateAddStudentPermissionForUserDistricts(user_id: $user_id, input: $input)
}
    `;
export type UpdateAddStudentPermissionForUserDistrictsMutationFn = Apollo.MutationFunction<UpdateAddStudentPermissionForUserDistrictsMutation, UpdateAddStudentPermissionForUserDistrictsMutationVariables>;

/**
 * __useUpdateAddStudentPermissionForUserDistrictsMutation__
 *
 * To run a mutation, you first call `useUpdateAddStudentPermissionForUserDistrictsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddStudentPermissionForUserDistrictsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddStudentPermissionForUserDistrictsMutation, { data, loading, error }] = useUpdateAddStudentPermissionForUserDistrictsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddStudentPermissionForUserDistrictsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddStudentPermissionForUserDistrictsMutation, UpdateAddStudentPermissionForUserDistrictsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddStudentPermissionForUserDistrictsMutation, UpdateAddStudentPermissionForUserDistrictsMutationVariables>(UpdateAddStudentPermissionForUserDistrictsDocument, options);
      }
export type UpdateAddStudentPermissionForUserDistrictsMutationHookResult = ReturnType<typeof useUpdateAddStudentPermissionForUserDistrictsMutation>;
export type UpdateAddStudentPermissionForUserDistrictsMutationResult = Apollo.MutationResult<UpdateAddStudentPermissionForUserDistrictsMutation>;
export type UpdateAddStudentPermissionForUserDistrictsMutationOptions = Apollo.BaseMutationOptions<UpdateAddStudentPermissionForUserDistrictsMutation, UpdateAddStudentPermissionForUserDistrictsMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UsersDocument = gql`
    query Users($input: UsersQueryInput) {
  users(input: $input) {
    id
    district {
      id
      name
    }
    email
    first_name
    last_name
    middle_initial
    maiden_name
    roles
    student_id {
      id
      name
    }
    city
    zip
    status
    telemedicine_enabled
    telemedicine_trained_at
    mobile_phone
    home_phone
    therapist_discipline
    state_license {
      id
      name
      abbreviation
    }
    street_address
    license_number
    cert_date
    cert_expire_date
    gender
    created_by
    updated_by
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UserInput!) {
  updateUser(id: $id, input: $input)
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DisableUserDocument = gql`
    mutation DisableUser($id: ID!, $updated_by: String) {
  disableUser(id: $id, updated_by: $updated_by)
}
    `;
export type DisableUserMutationFn = Apollo.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updated_by: // value for 'updated_by'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, options);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const EnableUserDocument = gql`
    mutation EnableUser($id: ID!, $updated_by: String) {
  enableUser(id: $id, updated_by: $updated_by)
}
    `;
export type EnableUserMutationFn = Apollo.MutationFunction<EnableUserMutation, EnableUserMutationVariables>;

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updated_by: // value for 'updated_by'
 *   },
 * });
 */
export function useEnableUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, options);
      }
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<EnableUserMutation, EnableUserMutationVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    district {
      id
      name
    }
    newUserAgeGroups {
      id
      user_id
      min_age
      max_age
    }
    email
    first_name
    last_name
    middle_initial
    maiden_name
    roles
    city
    zip
    status
    time_zone
    npi
    zoom_id
    student_id {
      id
      name
    }
    hourly_rate
    telemedicine_enabled
    telemedicine_trained_at
    mobile_phone
    home_phone
    state_license {
      id
      name
      abbreviation
    }
    street_address
    license_number
    cert_date
    cert_expire_date
    signalDiscipline {
      id
      name
    }
    signalAgeGroup {
      id
      name
    }
    signalCounties {
      id
      name
    }
    signalInsurance {
      id
      name
    }
    signalSpecilities {
      id
      name
    }
    signalLanguage {
      id
      name
    }
    signalAgencies {
      id
      name
    }
    ethinic_group_id {
      id
      name
    }
    signal_service_id {
      id
      name
    }
    signalUserExperience {
      id
      experience
    }
    gender
    password
    degree_held
    school_attended
    clinician_phone
    specialized_training
    supervisor_name_and_credentials
    waitlist_assignee
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const WaitlistDocument = gql`
    query waitlist($user_id: String) {
  waitlist(user_id: $user_id) {
    id
    preference_notes
    availability_notes
    student_id {
      id
      first_name
      last_name
      birth_date
      contact_email
      status
      contact_phone_number
      insurance_company {
        id
        name
      }
      gender
      psc_score
      sdoh_score
      craft_score
      student_specialities {
        speciality_id {
          id
          name
        }
      }
      session_service {
        id
        name
      }
    }
    created_at
    updated_at
    assigned_user_id {
      id
      name
    }
    outreach_attempts
  }
}
    `;

/**
 * __useWaitlistQuery__
 *
 * To run a query within a React component, call `useWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useWaitlistQuery(baseOptions?: Apollo.QueryHookOptions<WaitlistQuery, WaitlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaitlistQuery, WaitlistQueryVariables>(WaitlistDocument, options);
      }
export function useWaitlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaitlistQuery, WaitlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaitlistQuery, WaitlistQueryVariables>(WaitlistDocument, options);
        }
export type WaitlistQueryHookResult = ReturnType<typeof useWaitlistQuery>;
export type WaitlistLazyQueryHookResult = ReturnType<typeof useWaitlistLazyQuery>;
export type WaitlistQueryResult = Apollo.QueryResult<WaitlistQuery, WaitlistQueryVariables>;
export const AdminUsersListDocument = gql`
    query adminUsersList {
  adminUsersList {
    id
    name
  }
}
    `;

/**
 * __useAdminUsersListQuery__
 *
 * To run a query within a React component, call `useAdminUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersListQuery(baseOptions?: Apollo.QueryHookOptions<AdminUsersListQuery, AdminUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersListQuery, AdminUsersListQueryVariables>(AdminUsersListDocument, options);
      }
export function useAdminUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersListQuery, AdminUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersListQuery, AdminUsersListQueryVariables>(AdminUsersListDocument, options);
        }
export type AdminUsersListQueryHookResult = ReturnType<typeof useAdminUsersListQuery>;
export type AdminUsersListLazyQueryHookResult = ReturnType<typeof useAdminUsersListLazyQuery>;
export type AdminUsersListQueryResult = Apollo.QueryResult<AdminUsersListQuery, AdminUsersListQueryVariables>;
export const ListViewLogDocument = gql`
    query listViewLog($waitlist_id: String) {
  listViewLog(waitlist_id: $waitlist_id) {
    id
    waitlist_id {
      id
      student_id {
        id
      }
      assigned_user_id {
        id
        name
      }
    }
    user_id {
      id
      name
    }
    action_type_id {
      id
      name
    }
    details
    date
  }
}
    `;

/**
 * __useListViewLogQuery__
 *
 * To run a query within a React component, call `useListViewLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useListViewLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListViewLogQuery({
 *   variables: {
 *      waitlist_id: // value for 'waitlist_id'
 *   },
 * });
 */
export function useListViewLogQuery(baseOptions?: Apollo.QueryHookOptions<ListViewLogQuery, ListViewLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListViewLogQuery, ListViewLogQueryVariables>(ListViewLogDocument, options);
      }
export function useListViewLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListViewLogQuery, ListViewLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListViewLogQuery, ListViewLogQueryVariables>(ListViewLogDocument, options);
        }
export type ListViewLogQueryHookResult = ReturnType<typeof useListViewLogQuery>;
export type ListViewLogLazyQueryHookResult = ReturnType<typeof useListViewLogLazyQuery>;
export type ListViewLogQueryResult = Apollo.QueryResult<ListViewLogQuery, ListViewLogQueryVariables>;
export const SignalOutreachTypeDocument = gql`
    query signalOutreachType {
  signalOutreachType {
    id
    name
  }
}
    `;

/**
 * __useSignalOutreachTypeQuery__
 *
 * To run a query within a React component, call `useSignalOutreachTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalOutreachTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalOutreachTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignalOutreachTypeQuery(baseOptions?: Apollo.QueryHookOptions<SignalOutreachTypeQuery, SignalOutreachTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalOutreachTypeQuery, SignalOutreachTypeQueryVariables>(SignalOutreachTypeDocument, options);
      }
export function useSignalOutreachTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalOutreachTypeQuery, SignalOutreachTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalOutreachTypeQuery, SignalOutreachTypeQueryVariables>(SignalOutreachTypeDocument, options);
        }
export type SignalOutreachTypeQueryHookResult = ReturnType<typeof useSignalOutreachTypeQuery>;
export type SignalOutreachTypeLazyQueryHookResult = ReturnType<typeof useSignalOutreachTypeLazyQuery>;
export type SignalOutreachTypeQueryResult = Apollo.QueryResult<SignalOutreachTypeQuery, SignalOutreachTypeQueryVariables>;
export const RemoveFromWaitlistDocument = gql`
    mutation RemoveFromWaitlist($id: Int!) {
  deleteStudent(id: $id)
}
    `;
export type RemoveFromWaitlistMutationFn = Apollo.MutationFunction<RemoveFromWaitlistMutation, RemoveFromWaitlistMutationVariables>;

/**
 * __useRemoveFromWaitlistMutation__
 *
 * To run a mutation, you first call `useRemoveFromWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromWaitlistMutation, { data, loading, error }] = useRemoveFromWaitlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFromWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromWaitlistMutation, RemoveFromWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromWaitlistMutation, RemoveFromWaitlistMutationVariables>(RemoveFromWaitlistDocument, options);
      }
export type RemoveFromWaitlistMutationHookResult = ReturnType<typeof useRemoveFromWaitlistMutation>;
export type RemoveFromWaitlistMutationResult = Apollo.MutationResult<RemoveFromWaitlistMutation>;
export type RemoveFromWaitlistMutationOptions = Apollo.BaseMutationOptions<RemoveFromWaitlistMutation, RemoveFromWaitlistMutationVariables>;
export const AddClientFromWaitlistDocument = gql`
    mutation AddClientFromWaitlist($student_id: Int!, $user_id: String) {
  addClients(student_id: $student_id, user_id: $user_id)
}
    `;
export type AddClientFromWaitlistMutationFn = Apollo.MutationFunction<AddClientFromWaitlistMutation, AddClientFromWaitlistMutationVariables>;

/**
 * __useAddClientFromWaitlistMutation__
 *
 * To run a mutation, you first call `useAddClientFromWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientFromWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientFromWaitlistMutation, { data, loading, error }] = useAddClientFromWaitlistMutation({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAddClientFromWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<AddClientFromWaitlistMutation, AddClientFromWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientFromWaitlistMutation, AddClientFromWaitlistMutationVariables>(AddClientFromWaitlistDocument, options);
      }
export type AddClientFromWaitlistMutationHookResult = ReturnType<typeof useAddClientFromWaitlistMutation>;
export type AddClientFromWaitlistMutationResult = Apollo.MutationResult<AddClientFromWaitlistMutation>;
export type AddClientFromWaitlistMutationOptions = Apollo.BaseMutationOptions<AddClientFromWaitlistMutation, AddClientFromWaitlistMutationVariables>;
export const RemoveClientFromWaitlistDocument = gql`
    mutation RemoveClientFromWaitlist($student_id: Int!, $user_id: String) {
  removeClients(student_id: $student_id, user_id: $user_id)
}
    `;
export type RemoveClientFromWaitlistMutationFn = Apollo.MutationFunction<RemoveClientFromWaitlistMutation, RemoveClientFromWaitlistMutationVariables>;

/**
 * __useRemoveClientFromWaitlistMutation__
 *
 * To run a mutation, you first call `useRemoveClientFromWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientFromWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientFromWaitlistMutation, { data, loading, error }] = useRemoveClientFromWaitlistMutation({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRemoveClientFromWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientFromWaitlistMutation, RemoveClientFromWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientFromWaitlistMutation, RemoveClientFromWaitlistMutationVariables>(RemoveClientFromWaitlistDocument, options);
      }
export type RemoveClientFromWaitlistMutationHookResult = ReturnType<typeof useRemoveClientFromWaitlistMutation>;
export type RemoveClientFromWaitlistMutationResult = Apollo.MutationResult<RemoveClientFromWaitlistMutation>;
export type RemoveClientFromWaitlistMutationOptions = Apollo.BaseMutationOptions<RemoveClientFromWaitlistMutation, RemoveClientFromWaitlistMutationVariables>;
export const CreateWaitlistAssignDocument = gql`
    mutation createWaitlistAssign($input: WaitlistAssignInput) {
  createWaitlistAssign(input: $input)
}
    `;
export type CreateWaitlistAssignMutationFn = Apollo.MutationFunction<CreateWaitlistAssignMutation, CreateWaitlistAssignMutationVariables>;

/**
 * __useCreateWaitlistAssignMutation__
 *
 * To run a mutation, you first call `useCreateWaitlistAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitlistAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitlistAssignMutation, { data, loading, error }] = useCreateWaitlistAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWaitlistAssignMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitlistAssignMutation, CreateWaitlistAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitlistAssignMutation, CreateWaitlistAssignMutationVariables>(CreateWaitlistAssignDocument, options);
      }
export type CreateWaitlistAssignMutationHookResult = ReturnType<typeof useCreateWaitlistAssignMutation>;
export type CreateWaitlistAssignMutationResult = Apollo.MutationResult<CreateWaitlistAssignMutation>;
export type CreateWaitlistAssignMutationOptions = Apollo.BaseMutationOptions<CreateWaitlistAssignMutation, CreateWaitlistAssignMutationVariables>;
export const CreateWaitlistOutreachDocument = gql`
    mutation createWaitlistOutreach($input: WaitlistOutreach) {
  createWaitlistOutreach(input: $input)
}
    `;
export type CreateWaitlistOutreachMutationFn = Apollo.MutationFunction<CreateWaitlistOutreachMutation, CreateWaitlistOutreachMutationVariables>;

/**
 * __useCreateWaitlistOutreachMutation__
 *
 * To run a mutation, you first call `useCreateWaitlistOutreachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitlistOutreachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitlistOutreachMutation, { data, loading, error }] = useCreateWaitlistOutreachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWaitlistOutreachMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitlistOutreachMutation, CreateWaitlistOutreachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitlistOutreachMutation, CreateWaitlistOutreachMutationVariables>(CreateWaitlistOutreachDocument, options);
      }
export type CreateWaitlistOutreachMutationHookResult = ReturnType<typeof useCreateWaitlistOutreachMutation>;
export type CreateWaitlistOutreachMutationResult = Apollo.MutationResult<CreateWaitlistOutreachMutation>;
export type CreateWaitlistOutreachMutationOptions = Apollo.BaseMutationOptions<CreateWaitlistOutreachMutation, CreateWaitlistOutreachMutationVariables>;
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($input: ActivateAccountInput!) {
  activateAccount(input: $input)
}
    `;
export type ActivateAccountMutationFn = Apollo.MutationFunction<ActivateAccountMutation, ActivateAccountMutationVariables>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(ActivateAccountDocument, options);
      }
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<ActivateAccountMutation, ActivateAccountMutationVariables>;
export const UserInviteDocument = gql`
    query UserInvite($token: String!) {
  userInvite(token: $token) {
    id
    email
    name
    district {
      id
      name
    }
    roles
  }
}
    `;

/**
 * __useUserInviteQuery__
 *
 * To run a query within a React component, call `useUserInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInviteQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUserInviteQuery(baseOptions: Apollo.QueryHookOptions<UserInviteQuery, UserInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInviteQuery, UserInviteQueryVariables>(UserInviteDocument, options);
      }
export function useUserInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInviteQuery, UserInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInviteQuery, UserInviteQueryVariables>(UserInviteDocument, options);
        }
export type UserInviteQueryHookResult = ReturnType<typeof useUserInviteQuery>;
export type UserInviteLazyQueryHookResult = ReturnType<typeof useUserInviteLazyQuery>;
export type UserInviteQueryResult = Apollo.QueryResult<UserInviteQuery, UserInviteQueryVariables>;
export const SignalSlotsDocument = gql`
    query SignalSlots {
  signalSlots {
    id
    time_slot
  }
}
    `;

/**
 * __useSignalSlotsQuery__
 *
 * To run a query within a React component, call `useSignalSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignalSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignalSlotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignalSlotsQuery(baseOptions?: Apollo.QueryHookOptions<SignalSlotsQuery, SignalSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignalSlotsQuery, SignalSlotsQueryVariables>(SignalSlotsDocument, options);
      }
export function useSignalSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignalSlotsQuery, SignalSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignalSlotsQuery, SignalSlotsQueryVariables>(SignalSlotsDocument, options);
        }
export type SignalSlotsQueryHookResult = ReturnType<typeof useSignalSlotsQuery>;
export type SignalSlotsLazyQueryHookResult = ReturnType<typeof useSignalSlotsLazyQuery>;
export type SignalSlotsQueryResult = Apollo.QueryResult<SignalSlotsQuery, SignalSlotsQueryVariables>;
export const NextDaysDocument = gql`
    query NextDays {
  nextDays
}
    `;

/**
 * __useNextDaysQuery__
 *
 * To run a query within a React component, call `useNextDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextDaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextDaysQuery(baseOptions?: Apollo.QueryHookOptions<NextDaysQuery, NextDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextDaysQuery, NextDaysQueryVariables>(NextDaysDocument, options);
      }
export function useNextDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextDaysQuery, NextDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextDaysQuery, NextDaysQueryVariables>(NextDaysDocument, options);
        }
export type NextDaysQueryHookResult = ReturnType<typeof useNextDaysQuery>;
export type NextDaysLazyQueryHookResult = ReturnType<typeof useNextDaysLazyQuery>;
export type NextDaysQueryResult = Apollo.QueryResult<NextDaysQuery, NextDaysQueryVariables>;
export const ResourcesDocument = gql`
    query Resources($search: String, $discipline: ID, $cursor: String) {
  GCMS_resources(
    where: {_search: $search, disciplines_every: {id: $discipline}}
    orderBy: createdAt_DESC
    after: $cursor
    first: 9
  ) {
    id
    title
    createdAt
    coverImage {
      id
      url
      handle
      height
      width
    }
    disciplines {
      id
      name
    }
    content {
      ... on CmsArticle {
        id
      }
      ... on CmsPdf {
        id
      }
      ... on CmsCOGame {
        id
      }
      ... on CmsDragBoard {
        id
      }
      ... on CmsOrderingGame {
        id
      }
      ... on CmsVideo {
        id
        file {
          id
          url
        }
      }
      ... on CmsDragBoard {
        id
      }
      ... on CmsCOGame {
        id
      }
    }
  }
}
    `;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      discipline: // value for 'discipline'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;
export const ResourceDocument = gql`
    query Resource($id: ID!) {
  GCMS_resource(where: {id: $id}) {
    id
    title
    createdAt
    updatedAt
    coverImage {
      id
      url
      handle
      height
      width
    }
    disciplines {
      id
      name
    }
    related {
      id
      title
      coverImage {
        id
        url
        handle
        height
        width
      }
    }
    content {
      ... on CmsArticle {
        id
        body
      }
      ... on CmsPdf {
        id
        file {
          id
          url
        }
      }
      ... on CmsVideo {
        id
        file {
          id
          url
        }
      }
      ... on CmsOrderingGame {
        id
        questions {
          id
          question
          answers(orderBy: order_ASC) {
            id
            order
            image {
              id
              handle
              width
              height
            }
          }
        }
      }
      ... on CmsDragBoard {
        id
        images {
          id
          handle
          width
          height
        }
      }
      ... on CmsCOGame {
        id
        questions {
          id
          question
          answers {
            id
            isCorrect
            image {
              id
              handle
              width
              height
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useResourceQuery__
 *
 * To run a query within a React component, call `useResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceQuery(baseOptions: Apollo.QueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
      }
export function useResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
        }
export type ResourceQueryHookResult = ReturnType<typeof useResourceQuery>;
export type ResourceLazyQueryHookResult = ReturnType<typeof useResourceLazyQuery>;
export type ResourceQueryResult = Apollo.QueryResult<ResourceQuery, ResourceQueryVariables>;