import React from 'react'
import {
  AppBar as MAppBar,
  AppBarProps,
  Container,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core'
import useStyles from './Styles'

interface NavBarProps extends Omit<AppBarProps, 'title'> {
  title: React.ReactNode
  goBackBtn?: boolean
}

export const ChatNavBar: React.FC<NavBarProps> = ({
  title,
  goBackBtn,
  children,
  ...props
}: NavBarProps) => {
  const classes = useStyles()

  return (
    <MAppBar color="transparent" position="static" {...props} className={classes.root}>
      <Container className={classes.container}>
        <Toolbar>
          <Grid container alignItems="center" alignContent="center">
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="secondary"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs={6} classes={{ root: classes.links }}>
              {children}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </MAppBar>
  )
}

export default ChatNavBar
