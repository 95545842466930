import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core'
import ParticipantList from '../ParticipantList/ParticipantList'
import MainParticipant from '../MainParticipant/MainParticipant'

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`

  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr 300 px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`
      }
    },
    chatWindowOpen: { gridTemplateColumns: `1fr 300 px 320 px` }
  }
})

export default function Room() {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container)}>
      <MainParticipant />
      <ParticipantList />
    </div>
  )
}
