import { makeStyles } from '@material-ui/core'
// import Ymhlogo from '../../../../assets/illustrations/YMH-logo.png'
import inputonlinegiftools from '../../../../assets/illustrations/input-onlinegiftools.png'
import miramentalhealth from '../../../../assets/illustrations/Mira_Logo.png'
// import miramentalhealth from '../../../../assets/illustrations/wisconsin-logo.png'
import spanishLogoGif from '../../../../assets/illustrations/spanishLogoGiff.gif'

export default makeStyles({
  root: {
    zIndex: 90,
    boxShadow: 'none',
    backgroundColor: '#FFFFFF'
  },
  rootHeader: {
    zIndex: 90,
    backgroundColor: '#FFF', // #1a3560',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%)'
  },
  space: {
    flexGrow: 1
  },
  title: {
    minWidth: '220px'
  },
  logoCls: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '212px auto',
    backgroundPosition: '100%',
    height: '45px',
    width: '215px',
    backgroundImage: `url(${miramentalhealth})`,
    borderRadius: '12px',
    resizeMode: 'stretch'
  },
  spanishlogoCls: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '180px 40px',
    backgroundPosition: '0px 2px',
    height: '45px',
    backgroundImage: `url(${spanishLogoGif})`
  },
  titleCls: {
    width: '180px'
  },
  navStyle: {
    paddingRight: '3px',
    paddingLeft: '3px',
    fontSize: '18px',
    paddingBottom: '0'
  },
  buttonStyles: {
    color: '#0D1F48',
    fontFamily: 'Roboto',
    fontSize: '19px',
    paddingLeft: '6px',
    paddingRight: '6px',
    '&:hover': {
      color: '#F1583A',
      textDecoration: 'none'
    }
  },
  selectedStyles: {
    color: '#F1583A',
    borderBottom: '2px solid #F1583A',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontWeight: 'bold'
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto'
  }
})
