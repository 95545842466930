import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Filter,
  FilterSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  // Grid,
  Avatar
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import moment from 'moment'
import getZentryUrl from 'helpers/getZentryUrl'
import { getmd5 } from 'helpers/md5helper'
import FormControl from '@material-ui/core/FormControl'
import Dialog from 'components/ui/panel/dialog/Dialog'
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent, ChangeEventArgs } from '@syncfusion/ej2-react-buttons'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useBulkUploadCsvMutation,
  useStudentSessionCountLazyQuery
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'
import { RouterLink } from '../../../../components/ui/panel/link/RouterLink'

interface ListStudentViewParams extends UseParamsType {
  id: string
}

export const StudentListView: React.FC = () => {
  const uploadObj = useRef<UploaderComponent>(null)
  const preventDefault = (event) => event.preventDefault()
  const [bulkUploadCsv] = useBulkUploadCsvMutation()
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: districtId } = useParams<ListStudentViewParams>()
  const classes = useStyles()
  const { data: currentUserData } = useCurrentUser()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterData, setFilterData] = useState<any>()
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  // const [showDialog, setDialog] = useState<boolean>(false)
  const [studentListQuery, { data, loading, refetch }] = useStudentSessionCountLazyQuery({
    fetchPolicy: 'network-only'
  })
  const studentsFetch = useMemo(
    () => ({ district_id }) =>
      studentListQuery({
        // variables: { district_id }
      }),
    [studentListQuery]
  )

  if (data && filterData === undefined) {
    const filteredDataTest =
      data &&
      data?.StudentSessionCount?.filter((obj) => {
        return obj.status === 'ACTIVE'
      })
    setFilterData(filteredDataTest)
    // console.log(filteredDataTest)
    // console.log(filterData)
  }
  // console.log('student---data--', data)
  // const [studentsData, setData] = useState<any>()

  const [studentStatus, setStudentStatus] = useState('active')
  const [stufilter, setStuFilter] = useState('name')
  const [studentId, setStudentId] = useState('student')
  const [uploadStatus, setUploadStatus] = useState(true)
  const [fileData, setFileData] = useState<any>()
  // const [deleteStudent] = useDeleteStudentMutation()
  const handleClick = () => {
    history.push(`${url}/create`)
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  useEffect(() => {
    let inputdata

    if (districtId) {
      inputdata = {
        district_id: Number(districtId)
      }
      studentsFetch(inputdata)
    } else {
      inputdata = {
        district_id: Number(currentUserData?.currentUser.district?.id)
      }
      studentsFetch(inputdata)
    }
  }, [currentUserData, districtId, studentsFetch])
  const handleChange = (e) => {
    const keyWord = e.target.value
    setStudentStatus(keyWord)
  }
  const handleInsert = (e) => {
    const keyWord = e.target.value
    setStuFilter(keyWord)
  }
  const handleInsertChange = (e) => {
    const keyWord = e.target.value
    setStudentId(keyWord)
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.clientlistq?.columnName
          ? sfStoreState?.clientlistq?.columnName
          : 'first_name',
        direction: sfStoreState?.clientlistq?.columnDirection
          ? sfStoreState?.clientlistq?.columnDirection
          : 'Ascending'
      }
      // { field: 'last_name', direction: 'Ascending' },
      // { field: 'Age', direction: 'Ascending' },
      // { field: 'Gender', direction: 'Ascending' },
      // { field: 'parent_name', direction: 'Ascending' },
      // { field: 'Contact email', direction: 'Ascending' },
      // { field: 'parent_Email', direction: 'Ascending' },
      // { field: 'contact phone number', direction: 'Ascending' },
      // { field: 'parent phone number', direction: 'Ascending' },
      // { field: 'unique_profile_id', direction: 'Ascending' },
      // { field: 'insurance_company', direction: 'Ascending' }
    ]
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: [
      'first_name',
      'last_name',
      'Age',
      'Gender',
      'Parent_name',
      'Contact email',
      'Parent email',
      'Contact phone number',
      'Parent phone number',
      'unique_profile_id',
      'insurance_company'
    ],
    key: sfStoreState?.clientlistq?.searchText,
    ignoreCase: true
  }
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.clientlistq?.pageSize ? sfStoreState?.clientlistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.clientlistq?.currentPage ? sfStoreState?.clientlistq?.currentPage : 1
  }
  const handleClose = () => {
    // console.log('abc')
  }
  const submitDialog = () => {
    // console.log('abc')
  }
  const title = 'Client File Upload'
  const addStudent = (e) => {
    // console.log(this.addstudenttherapist)
    setDialogOpen(true)
  }
  const upload = (file) => {
    setFileData(file)

    if (file.filesData[0].status === 'Ready to upload') setUploadStatus(false)
    else setUploadStatus(true)
    // console.log(file)
  }
  const bulkUpload = () => {
    const temObj = {
      district_id: 1,
      user_id: '24c2d0c5-3dcc-4476-8ef0-a7fac524999d',
      fileUpload: fileData?.filesData[0].rawFile
    }

    if (fileData.filesData[0].status === 'Ready to upload')
      bulkUploadCsv({ variables: temObj }).then((response) => {
        console.log(response)
        // dialogClose(_refreshAppointments)
      })
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            clientlistq: { ...sfStoreState.clientlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              clientlistq: { ...sfStoreState.clientlistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          clientlistq: {
            ...sfStoreState.clientlistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          clientlistq: {
            ...sfStoreState.clientlistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }
  const UploadContent = () => {
    return (
      <form>
        <div className="InputCenter">
          <FormControl variant="filled" fullWidth>
            <UploaderComponent
              ref={uploadObj}
              id="fileUpload"
              type="file"
              maxFileSize={1000}
              allowedExtensions=".csv"
              multiple={false}
              change={(file) => upload(file)}
              showFileList
              autoUpload={false}
              // uploading={(file) => upload(file)}
              selected={(file) => upload(file)}
            />
          </FormControl>
        </div>
        <div>
          {fileData && (
            <span>
              <span>{fileData.filesData[0].name}</span>
              <span>{fileData.filesData[0].type}</span>
              <span>0.8 KB</span>
            </span>
          )}
        </div>
        <div style={{ width: '100%', marginLeft: '70%' }}>
          <Button
            color="primary"
            onClick={() => bulkUpload()}
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 custom-btn addEvent "
            variant="contained"
            size="medium"
            style={{ backgroundColor: uploadStatus ? '#b3b3b3' : '#2C9BD6' }}
            disabled={uploadStatus}
            // style={{ backgroundColor: '#2C9BD6' }}
          >
            Upload
          </Button>
        </div>
      </form>
    )
  }

  return (
    <>
      <NavBar title="Clients" goBackBtn />
      <PageContainer>
        {/* <Grid> */}
        {/* <DialogComponent
            isModal
            header={title}
            content={() => UploadContent()}
            showCloseIcon
            // open={()=>dialogOpen()}
            close={() => setDialogOpen(false)}
            height="auto"
            width="400px"
            visible={dialogOpen}
          /> */}
        {/* <Box display="flex" justifyContent="center" alignItems="center"> */}
        {/* <Dialog
              handleClose={handleClose}
              handleSubmit={submitDialog}
              disabled={false}
              submit="Upload"
              open={dialogOpen}
              title={title}
              backDrop
              custSubmitBtn
              isoverflowY
              isCustTitle
            >
            </Dialog>
          */}
        {/* </Box> */}
        {/* </Grid> */}
        <Box padding="10px" display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h3"
              style={{
                color: theme.palette.info.main,
                fontFamily: 'Roboto'
              }}
            >
              Clients
            </Typography>
          </Box>
        </Box>
        <div className="control-pane">
          <div className="control-section">
            {data && (
              <GridComponent
                key={Math.floor(Math.random() * 100)}
                allowTextWrap
                dataSource={filterData || []}
                width="auto"
                toolbar={toolbarOptions}
                allowPaging
                pageSettings={pageOptions}
                allowSorting
                sortSettings={sortSettings}
                filterSettings={filterOption}
                allowFiltering
                searchSettings={searchOptions}
                className={classes.headerCls}
                ref={(g) => gridref(g)}
                created={createdevent}
                actionComplete={(value) => handleActionBegin(value)}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    template={() => (
                      <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                    )}
                    width="45"
                  />
                  <ColumnDirective
                    field="first_name"
                    template={(names) => (
                      <>
                        <div>
                          <Link
                            style={{ color: 'black', cursor: 'pointer' }}
                            onClick={() =>
                              history.push(`/studentsinfo/${btoa(`${names?.id}_${names?.id}`)}`)
                            }
                          >
                            {/* <Link color="textPrimary" component={RouterLink} to={`/studentsinfo`}> */}

                            <div
                              className={classes.nameCls}
                              style={{ color: 'black', fontFamily: 'Roboto' }}
                            >
                              {names.first_name} {names.last_name}
                            </div>
                          </Link>
                          <div
                            className={classes.nameCls}
                            style={{ color: 'black', fontFamily: 'Roboto' }}
                          >
                            {names?.gender}
                          </div>
                        </div>
                      </>
                    )}
                    headerText="NAME"
                    width="120"
                    filter={{ type: 'CheckBox' }}
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="birth_date"
                    template={(age) => (
                      <>
                        <div className={classes.nameCls}>
                          Age
                          {moment(age?.birth_date, 'YYYYMMDD').fromNow().split('years ago')}
                        </div>
                      </>
                    )}
                    headerText="AGE"
                    width="90"
                    allowFiltering={false}
                  />

                  <ColumnDirective
                    field="unique_profile_id"
                    headerText="CLIENT ID"
                    width="100"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="contact_email"
                    headerText="CONTACT EMAIL"
                    width="140"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="contact_phone_number"
                    headerText="CONTACT NUMBER"
                    width="110"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="parent_name"
                    headerText="PARENT NAME"
                    width="110"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="parent1_email"
                    template={(mail) => (
                      <>
                        <div>{mail?.parent1_email}</div>
                        <div>{mail?.parent1_phone_number}</div>
                      </>
                    )}
                    headerText="PARENT EMAIL"
                    width="140"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="insurance"
                    // template={(insurance) => (
                    //   <>
                    //     <div>{insurance?.insurance_company?.name}</div>
                    //   </>
                    // )}
                    headerText="INSURANCE"
                    width="120"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    field="count"
                    headerText="#SESSIONS DONE"
                    width="110"
                    allowFiltering={false}
                  />
                </ColumnsDirective>
                <Inject services={[Filter, Search, Toolbar, Sort, Page]} />
                <div className="totalStudents" style={{ borderRight: '2px solid #7f7f7f' }}>
                  <span className="totalText">Total Clients</span>
                  <span style={{ fontSize: '24px' }}>{filterData?.length}</span>
                </div>
                {/* <Button
                  color="primary"
                  onClick={addStudent}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="mar-rt-15 custom-btn addEvent "
                  variant="contained"
                  size="medium"
                  // Style={{}}
                >
                  Bulk Upload
                </Button> */}

                {/* <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="mar-rt-15 custom-btn addEvent"
                  onClick={handleClick}
                >
                  Add Student
                </Button> */}
              </GridComponent>
            )}
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
