import React, { useState } from 'react'
import { Radio, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { User } from 'graphql/hooks.generated'
import useStyles from './Styles'
import Dialog from '../../panel/dialog/Dialog'

interface DMDialogProps {
  handleSubmit: (email: string) => Promise<unknown>
  open: boolean
  users: User[]
  setOpen: (pld: boolean) => unknown
}

export const DMDialog: React.FC<DMDialogProps> = ({
  handleSubmit,
  open,
  users,
  setOpen
}: DMDialogProps) => {
  const [selectedUser, setSelectedUser] = useState<string | null | undefined>(null)
  const classes = useStyles()
  const closeDialog = () => {
    setSelectedUser(null)
    setOpen(false)
  }
  const submitDialog = () => {
    const payloadUser: User | undefined = users.find(({ id }) => id === selectedUser)

    if (payloadUser) handleSubmit(payloadUser.email)

    closeDialog()
  }
  const chooseUser = (e) => setSelectedUser(e.target?.value)

  return (
    <Dialog
      handleClose={closeDialog}
      handleSubmit={submitDialog}
      disabled={!selectedUser}
      cancel="Cancel"
      submit="Start conversation"
      open={open}
      title="Choose user to start DM conversation"
    >
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} />
            <Radio onChange={chooseUser} value={user.id} checked={selectedUser === user.id} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default DMDialog
