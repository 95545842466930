import { Container, makeStyles } from '@material-ui/core'
import React from 'react'

interface PageContainerProps {
  children: NonNullable<React.ReactNode>
}
const useStyles = makeStyles({
  root: {
    width: '93%',
    backgroundColor: '#fff',
    margin: '0 auto',
    marginTop: '30px',
    marginBottom: '30px',
    paddingTop: '0px',
    paddingLeft: '0',
    paddingRight: '0',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },
  '& .MuiContainer-root': {
    paddingLeft: '0 !Important',
    paddingRight: '0 !important',
    paddingTop: '0 !important'
  }
})

export const PageContainer = (props: PageContainerProps) => {
  const { children } = props
  const styles = useStyles()

  return <Container classes={{ root: styles.root }}>{children}</Container>
}
