import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Grid, Box, Typography } from '@material-ui/core'
import Footer from '../../panel/footer/Footer'
import { PageContainer } from '../../panel/page-container/PageContainer'
import AppBar from '../../panel/app-bar/AppBar'
import Button from '../../panel/button/Button'
import useStyles from './Styles'

export const Register = () => {
  const classes = useStyles()
  const { url } = useRouteMatch()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <PageContainer>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item md={5} sm={12}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid container alignItems="center" justify="center" sm={12} spacing={5}>
                <Box pt={12}>
                  <img
                    alt="Access Anywhere"
                    style={{ width: 'auto', height: '46vh' }}
                    src="/TT_Illustrations_Access-Anywhere@2x.png"
                  />
                </Box>
              </Grid>
              <Grid item sm={8} style={{ textAlign: 'center' }}>
                <Box mt={4}>
                  <Typography style={{ fontSize: 24 }} variant="h1">
                    Get the support you need, right here, right now.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8} style={{ textAlign: 'center' }}>
                <Box mb={12} mt={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => history.push(`${url}/about-you`)}
                  >
                    Register now !
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  )
}
