import createTheme from './create-theme'
import { palette, customPalette } from './palette'

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '24px',
      fontWeight: 600
    },
    h2: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '22px',
      fontWeight: 600
    },
    h3: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '20px',
      fontWeight: 600
    },
    h4: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '18px',
      fontWeight: 600
    },
    h5: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      fontWeight: 600
    },
    h6: {
      fontSize: '14px',
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '14px'
    },
    subtitle2: {
      fontSize: '12px'
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '14px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '#root': {
          fontFamily: 'Inter'
        }
      }
    },
    MuiIcon: {
      root: {
        border: '1px'
      }
    },
    MuiSvgIcon: {
      root: {
        border: '1px'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '30px'
      },
      sizeSmall: {
        padding: '8px 25px',
        fontSize: 12,
        lineHeight: '16px'
      },
      sizeLarge: {
        padding: '12px 37px !important'
      }
    },
    MuiFilledInput: {
      input: {
        borderRadius: '2px !important',
        '&:placeholder': {
          fontSize: '3px !important'
        }
      },
      underline: {
        '&:before, &:after': {
          display: 'none'
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px !important'
      },
      outlined: {
        border: '1px solid #D7D7D7'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '12px',
        padding: '16px 32px'
      }
    }
  },
  palette,
  custom: { palette: customPalette }
})
