import React from 'react'
import { useChatCredentialsQuery } from 'graphql/hooks.generated'

export const withAuth = (Component) => (props) => {
  const { data: credentialsData, loading: credentialsLoading } = useChatCredentialsQuery()

  return (
    <Component
      chatCredentials={credentialsData?.chatCredentials}
      credentialsLoading={credentialsLoading}
      {...props}
    />
  )
}

export default withAuth
