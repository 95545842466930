import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import React, { Suspense } from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './components/providers/auth'
import { theme } from './config/constants/theme'
import { GraphQLProvider } from './components/providers/graphql'
import { RootView } from './views/RootView'
import './i18n'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider>
          <CssBaseline />
          <AuthProvider>
            <GraphQLProvider>
              <Suspense fallback="loading">
                <RootView />
              </Suspense>
            </GraphQLProvider>
          </AuthProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
