/* eslint-disable class-methods-use-this */
import * as React from 'react'
import { Internationalization } from '@syncfusion/ej2-base'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Inject,
  Page,
  Sort,
  Search
} from '@syncfusion/ej2-react-grids'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import './detail.css'
import '../../../../styles.css'
import { DialogActions, Link } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CheckIcon from '@material-ui/icons/Check'
import moment from 'moment'
import { CheckBoxComponent, ChangeEventArgs, ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { useLocation } from 'react-router-dom'
import AddStudentToCaseLoadForm from '../../../../components/ui/common/addstudent/addStudentModal'
import ConfirmDialog from '../../../../components/ui/panel/confirmDialog/ConfirmDialog'
import { theme } from '../../../../config/constants/theme'
import { RouterLink } from '../../../../components/ui/panel/link/RouterLink'
import Button from '../../../../components/ui/panel/button/Button'
import { SampleBase } from './sample-base'

const instance = new Internationalization()

class DetailTemplate extends SampleBase {
  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.format = (value) => {
      return instance.formatDate(value, { skeleton: 'yMd', type: 'date' })
    }
    this.template = this.gridTemplate
    this.toolbarOptions = ['Search']
    this.sortingOptions = {
      columns: [
        {
          field:
            this.props?.props?.match?.params?.userId !== this.props?.storedata?.caseloadid
              ? 'students.name'
              : this.props?.storedata?.caseloadlistq?.columnName
              ? this.props?.storedata?.caseloadlistq?.columnName
              : 'students.name',
          direction:
            this.props?.props?.match?.params?.userId !== this.props?.storedata?.caseloadid
              ? 'Ascending'
              : this.props?.storedata?.caseloadlistq?.columnDirection
              ? this.props?.storedata?.caseloadlistq?.columnDirection
              : 'Ascending'
        }
      ]
    }
    this.searchOptions = {
      fields: ['students.name'],
      key:
        this.props?.props?.match?.params?.userId !== this.props?.storedata?.caseloadid
          ? ''
          : this.props?.storedata?.caseloadlistq?.searchText,
      ignoreCase: true
    }
    this.state = {
      open: false,
      confirmBox: false,
      deleteId: '',
      name: '',
      url: '',
      openDialog: false,
      user_id: '',
      student_id: Number(''),
      statusToDisplay: 1,
      changeStatusList: false,
      allDataSource: [],
      totalCount: 0
    }
    this.hasParams = Object.keys(this.props.props.match.params).length
    this.studentData = this.props.caseloadData.caseload
    this.userdata = this.props.userdata
    // console.log('nnn', this.props.userdata)
    this.addstudenttherapist = false
    this.therapistenabledistricts = []
    this.currentuserdata = this.props.currentUserData
    this.isTherapist = this.props.isTherapist
    this.primaryDist = ''

    if (this.userdata && this.userdata.userListDistrictsForCaseload?.length)
      // eslint-disable-next-line array-callback-return
      this.userdata.userListDistrictsForCaseload.map((userObj) => {
        // if (this.currentuserdata) {
        // if(userObj.district_id === Number(this.currentuserdata?.data?.currentUser?.district?.id)) {
        //     this.addstudenttherapist = userObj.add_student_permission;
        // }
        // }
        if (userObj.primary) this.primaryDist = userObj.district_id
        if (userObj.add_student_permission && this.isTherapist) {
          this.therapistenabledistricts.push(userObj.district_id)
          this.addstudenttherapist = true
        }
        if (!this.isTherapist) this.therapistenabledistricts.push(userObj.district_id)
      })

    this.pageOptions = {
      pageSizes: [15, 25, 50, 100],
      pageSize:
        this.props?.props?.match?.params?.userId !== this.props?.storedata?.caseloadid
          ? 15
          : this.props?.storedata?.caseloadlistq?.pageSize
          ? this.props?.storedata?.caseloadlistq?.pageSize
          : 15,
      pageCount: 5,
      currentPage:
        this.props?.props?.match?.params?.userId !== this.props?.storedata?.caseloadid
          ? 1
          : this.props?.storedata?.caseloadlistq?.currentPage
          ? this.props?.storedata?.caseloadlistq?.currentPage
          : 1
    }
  }

  createdevent = () => {
    document
      .getElementById(`${this.grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = document.getElementById(`${this.grid?.element?.id}_searchbar`)?.value
        this.props.dispatchStore({
          ...this.props.storedata,
          caseloadlistq: { ...this.props?.storedata?.caseloadlistq, searchText: val },
          caseloadid: this.props.props.match.params.userId
        })
      })
    document
      .getElementById(`${this.grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = document.getElementById(`${this.grid?.element?.id}_searchbar`)?.value
          this.props.dispatchStore({
            ...this.props.storedata,
            caseloadlistq: { ...this.props?.storedata?.caseloadlistq, searchText: val },
            caseloadid: this.props.props.match.params.userId
          })
        }
      })
  }

  gridref(e) {
    this.grid = e
  }

  handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      this.props.dispatchStore({
        ...this.props.storedata,
        caseloadlistq: {
          ...this.props?.storedata?.caseloadlistq,
          columnName: args?.columnName,
          columnDirection: args?.direction
        },
        caseloadid: this.props.props.match.params.userId
      })
    if (args && args?.requestType === 'paging')
      this.props.dispatchStore({
        ...this.props.storedata,
        caseloadlistq: {
          ...this.props?.storedata?.caseloadlistq,
          currentPage: this.grid?.pageSettings?.currentPage,
          pageSize: this.grid?.pageSettings?.pageSize
        },
        caseloadid: this.props.props.match.params.userId
      })
  }

  componentDidMount() {
    if (this.props?.props?.location?.search !== '') {
      const URL = this.props?.props?.location?.search.split('=')
      this.setState({ url: decodeURI(URL[1]) })
    }

    const filteredData = this.props.caseloadData.caseload
    const totalActive = filteredData.filter((v) => v.status === 1)
    this.setState({
      allDataSource: filteredData.filter((v) => v.status === 1),
      totalCount: totalActive.length
    })
  }

  avatarTemplate(props) {
    return (
      <div className="w-40 h-40 d-flex justify-l">
        <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40">
          {props.students.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
        </Avatar>
      </div>
    )
  }

  studentDataTemp(props) {
    return (
      <div>
        {/* <Link
          className="Studentname"
          color="textPrimary"
          component={RouterLink}
          to={`/studentsinfo/${props.id}/${props.students.id}`}
        > */}{' '}
        <div style={{ paddingTop: '10px' }}>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/studentsinfo/${btoa(`${props.students.id}_${props.students.id}`)}`}
          >
            <span style={{ color: '#0D1F48', textDecorationColor: '#0D1F48' }}>
              <b>{props.students.name}</b>
            </span>
          </Link>
        </div>
        <span>{moment(props?.students?.birth_date, 'YYYYMMDD').fromNow().split('ago')}</span>
        {/* </Link> */}
      </div>
    )
  }

  studentAge(props) {
    return (
      <div>
        {/* <span style={{ paddingTop: '10px' }}>{props.students.district.name}</span> */}
        {moment(props.students.birth_date).format('MM-DD-YYYY')}
      </div>
    )
  }
  // studentSchool(props) {
  //   return (
  //     <div>
  //       <span style={{ paddingTop: '10px' }}>{props.students.school.name}</span>
  //     </div>
  //   )
  // }

  studentInsurance(props) {
    return (
      <div>
        <span style={{ paddingTop: '10px' }}>{props.students?.insurance_company?.name}</span>
      </div>
    )
  }

  studentClientId(props) {
    return (
      <div>
        <span style={{ paddingTop: '10px' }}>{props.students?.unique_profile_id}</span>
      </div>
    )
  }

  studentTreatmentneeded(props) {
    return (
      <div>
        {props.students?.student_specialities?.map((obj, j) => {
          return (
            <span style={{ paddingTop: '10px' }} key={obj}>
              {obj?.speciality_id?.name}
              {props.students?.student_specialities.length - 1 > j ? <span> , &nbsp; </span> : ''}
            </span>
          )
        })}
      </div>
    )
  }

  removeStudent(props) {
    return (
      <div>
        {props.status === 1 ? (
          <Button
            onClick={() => this.handleOpenModal(props)}
            // disabled={props.sessions_count < 6}
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 "
            variant="contained"
            size="medium"
            style={{
              height: '36px',
              // marginTop: '13px',
              fontSize: '10px',
              width: '107px'
            }}
          >
            <span style={{ width: '99%' }}>
              {/* {props.status === 1 ? 'Remove Client' : 'Add Client'} */}
              Close Client
            </span>
          </Button>
        ) : (
          <Button
            onClick={() => this.handleOpenModal(props)}
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="mar-rt-15 "
            variant="contained"
            size="medium"
            style={{
              height: '36px',
              // marginTop: '13px',
              fontSize: '10px',
              width: '107px'
            }}
          >
            <span style={{ width: '99%' }}>
              {/* {props.status === 1 ? 'Remove Client' : 'Add Client'} */}
              Add Client
            </span>
          </Button>
        )}
      </div>
    )
  }

  status(props) {
    if (props.status === 1) return <Chip label="Approved" />
    if (props.status === 2) return <Chip label="Yet to approve" />
    if (props.status === 0) return <Chip label="Rejected" />

    return (
      <div>
        <span style={{ paddingTop: '10px' }}>
          {props.status === 1 && props.status === 0 && props.status === 2}
        </span>
      </div>
    )
  }

  // status(props) {
  //   // console.log(props)
  //   return (
  //     <div
  //       className="icons"
  //       style={{ display: 'flex', fontSize: '20px', justifyContent: 'center' }}
  //     >
  //       {/* <span class="e-icons e-edit"></span> */}
  //       <button
  //         type="button"
  //         style={{
  //           marginRight: '9px',
  //           backgroundColor: 'green',
  //           color: '#FFF'
  //         }}
  //       >
  //         <span>
  //           <CheckIcon style={{ margin: '-5px' }} />
  //           Approve
  //         </span>
  //       </button>
  //       <button
  //         type="button"
  //         style={{
  //           marginRight: '54px',
  //           backgroundColor: '#F1583A',
  //           color: '#FFF',
  //           borderColor: '#F1583A'
  //         }}
  //       >
  //         <span>
  //           <ScheduleIcon style={{ margin: '-5px' }} />
  //           &nbsp; Reschedule
  //         </span>
  //       </button>
  //     </div>
  //   )
  // }

  studentIEPdate(props) {
    return (
      <div>
        <span style={{ paddingTop: '10px' }}>{props.sessions_count}</span>
        {/* <span className="e-icons e-date" style={{ paddingLeft: '15px' }}></span> */}
      </div>
    )
  }
  // revalTemplate(props) {
  //   // console.log(props)
  //   return (
  //     <div>
  //       <span style={{ paddingTop: '10px' }}>{props.students.re_evl_date}</span>
  //       {/* <span className="e-icons e-date" style={{ paddingLeft: '15px' }}></span> */}
  //     </div>
  //   )
  // }
  // frequencyTemp(props) {
  //   // console.log(props)
  //   return (
  //     <div>
  //       <span>{props.frequency}</span>
  //       {/* <span className="e-icons e-date" style={{ paddingLeft: '20px' }}></span> */}
  //     </div>
  //   )
  // }

  handleOpenModal = (props) => {
    this.setState({
      openDialog: !this.state.openDialog,
      user_id: props?.user_id?.id,
      student_id: props?.students?.id,
      statusToDisplay: props?.status
    })
  }

  handleCloseModal = () => {
    this.setState({ openDialog: !this.state.openDialog })
  }

  handleRemoveStudnet = () => {
    // console.log('data')
    // console.log(this.state.user_id, '/////', this.state.student_id)
    this.props.removeClientFromCaseload({
      user_id: this.state.user_id,
      student_id: this.state.student_id
    })
    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  handaleAddClient = () => {
    // console.log('add')
    this.props.addClientTOCaseload({
      user_id: this.state.user_id,
      student_id: this.state.student_id
    })
    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  changeStatusOfList(e) {
    // console.log(e)
    const filteredData = this.props.caseloadData.caseload

    if (e.checked)
      this.setState({
        allDataSource: filteredData.filter((v) => v.status === 0),
        changeStatusList: true
      })
    else
      this.setState({
        allDataSource: filteredData.filter((v) => v.status === 1),
        changeStatusList: false
      })
  }

  actionTemplate = (props) => {
    return (
      <div
        className="icons"
        style={{ display: 'flex', fontSize: '20px', justifyContent: 'center' }}
      >
        {/* <span class="e-icons e-edit"></span> */}
        <span
          className="e-icons e-delete e-hover delete-icon"
          style={{ paddingLeft: '15px', color: theme.palette.error.dark }}
          onClick={() => this.openConfirm(props.id, props.students.name)}
          role="button"
          onKeyPress={() => this.openConfirm(props.id, props.students.name)}
          tabIndex="0"
          aria-label="Edit"
        />
      </div>
    )
  }

  openConfirm = (id, name) => {
    this.setState({ confirmBox: true })
    this.setState({ deleteId: id })
    this.setState({ name })
  }

  handleOnDelete = () => {
    this.setState({ confirmBox: false })
    this.props.deleteCaseload(this.state.deleteId)
  }

  addStudent = (e) => {
    // console.log(this.addstudenttherapist)
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.setState({ confirmBox: false })
  }

  handleSubmit = () => {
    this.setState({ open: false })
    this.props.handleRefresh()
  }

  render() {
    return (
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            key={Math.floor(Math.random() * 100)}
            allowTextWrap
            dataSource={this.state.allDataSource}
            width="auto"
            toolbar={this.toolbarOptions}
            allowPaging
            pageSettings={this.pageOptions}
            allowSorting
            sortSettings={this.sortingOptions}
            responsive
            searchSettings={this.searchOptions}
            ref={(g) => this.gridref(g)}
            created={this.createdevent}
            actionComplete={(value) => this.handleActionBegin(value)}
          >
            <ColumnsDirective>
              <ColumnDirective template={this.avatarTemplate} width="40" testAlign="Center" />
              <ColumnDirective
                field="students.name"
                template={this.studentDataTemp}
                headerText="CLIENT NAME"
                width="80"
                testAlign="Left"
              />
              <ColumnDirective
                field="students.unique_profile_id"
                template={this.studentClientId}
                headerText="CLIENT ID"
                width="60"
                testAlign="Left"
              />
              <ColumnDirective
                field="students.birth_date"
                template={this.studentAge}
                headerText="DOB"
                width="50"
                testAlign="Left"
              />
              <ColumnDirective
                // field=" "
                template={this.studentInsurance}
                headerText="HEALTH INSURANCE"
                width="80"
                testAlign="Left"
              />
              <ColumnDirective
                // field=" "
                template={this.studentTreatmentneeded}
                headerText="TREATMENT NEED"
                width="100"
                testAlign="Left"
              />
              <ColumnDirective
                // field=" "
                template={this.studentIEPdate}
                headerText="SESSIONS COMPLETED"
                width="70"
                testAlign="Left"
              />
              <ColumnDirective
                // field=" "
                template={(v) => this.removeStudent(v)}
                headerText="ACTIONS"
                width="80"
                testAlign="Left"
              />
              {/* {(this.hasParams || this.addstudenttherapist) && (
                <ColumnDirective template={this.actionTemplate} width="30" />
              )} */}
            </ColumnsDirective>
            <Inject services={[Toolbar, Page, Sort, Search]} />
            <div style={{ display: 'flex', position: 'absolute', zIndex: 3 }}>
              <div className="totalStudents" style={{ borderRight: '2px solid #7f7f7f' }}>
                <span className="totalText">Total Active Clients</span>
                <span style={{ fontSize: '24px', fontWeight: '500' }}>{this.state.totalCount}</span>
              </div>
              <div
                style={{
                  marginLeft: '12rem',
                  fontSize: 'large',
                  marginTop: '23px',
                  color: '#F1583A',
                  fontWeight: 700
                }}
              >
                {' '}
                {this.state.url ? this.state.url : ''}
              </div>
            </div>
            {/* {this.hasParams || this.addstudenttherapist ? (
              <Button
                color="primary"
                onClick={(e) => this.addStudent(e)}
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="mar-rt-15 custom-btn addEvent "
                variant="contained"
                size="medium"
                Style={{}}
              >
                Add Student
              </Button>
            ) : (
              <></>
            )} */}
            <div
              style={{
                zIndex: 9,
                display: 'flex',
                position: 'absolute',
                right: '281px',
                top: '25px',
                fontSize: '16px',
                fontWeight: 500,
                color: '#606060',
                '& .e-checkbox-wrapper .e-frame.e-check': {
                  backgroundColor: 'rgb(241, 88, 58)'
                }
              }}
            >
              <span style={{ paddingRight: '10px' }}>
                <CheckBoxComponent
                  // style={{ backgroundColor: 'rgb(241, 88, 58)' }}
                  checked={this.state.changeStatusList}
                  change={(e) => this.changeStatusOfList(e)}
                />
              </span>
              <span style={{ paddingTop: '2px' }}>Show Closed Clients</span>
            </div>
          </GridComponent>
          {this.state.open && (
            <>
              <AddStudentToCaseLoadForm
                primaryDist={this.primaryDist.toString()}
                open={this.state.open}
                currentUserdata={this.currentuserdata}
                handleClose={this.handleClose}
                handleSubmit={this.handleSubmit}
                enabledistricts={this.therapistenabledistricts}
              />
            </>
          )}
          {this.state.confirmBox && (
            <>
              <ConfirmDialog
                open={this.state.confirmBox}
                handleClose={this.handleClose}
                handleOnDelete={this.handleOnDelete}
                name={this.state.name}
              />
            </>
          )}
          {this.state.openDialog && (
            <Dialog
              open={this.state.openDialog}
              maxWidth="md"
              onClose={() => this.handleOpenModal()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {this.state.statusToDisplay
                    ? 'Are you sure you want to close client from caseload?'
                    : 'Are you sure you want to add client to caseload?'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleCloseModal()} color="primary">
                  No
                </Button>
                {this.state.statusToDisplay ? (
                  <Button onClick={() => this.handleRemoveStudnet()} color="primary">
                    Yes
                  </Button>
                ) : (
                  <Button onClick={() => this.handaleAddClient()} color="primary">
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    )
  }
}

export default DetailTemplate
