import React, { useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize
} from '@material-ui/core'
import { useActivityfeedLazyQuery, useCurrentUserQuery } from 'graphql/hooks.generated'
import moment from 'moment'
import { ReactComponent as AttachIcon } from '../../../assets/icons/attach.svg'
import useStyles from '../Styles'

export default function AdminActivity() {
  const classes = useStyles()
  const [sdate, setSdate] = useState(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
  const [edate, setEdate] = useState(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
  const [filterval, setfilterval] = useState('Today')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    activityquery,
    { data: acticitydata, loading: activityloading }
  ] = useActivityfeedLazyQuery({ fetchPolicy: 'network-only' })
  const fetchactivity = useMemo(
    () => ({ roles, users, from_date, to_date }) =>
      activityquery({ variables: { roles, users, from_date, to_date } }),
    [activityquery]
  )
  useEffect(() => {
    if (currentUserdata)
      fetchactivity({
        roles: currentUserdata?.data?.currentUser?.roles?.[0] || '',
        users: currentUserdata?.data?.currentUser?.id,
        from_date: sdate,
        to_date: edate
      })
  }, [currentUserdata, fetchactivity, sdate, edate])
  useEffect(() => {
    let interval

    if (currentUserdata)
      interval = setInterval(() => {
        fetchactivity({
          roles: currentUserdata?.data?.currentUser?.roles?.[0] || '',
          users: currentUserdata?.data?.currentUser?.id,
          from_date: sdate,
          to_date: edate
        })
      }, 60000)

    return () => clearInterval(interval)
  })
  const changePeriod = (e) => {
    setfilterval(e.target.value)

    if (e.target.value === 'Today')
      setSdate(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
    if (e.target.value === 'L7days')
      setSdate(moment.tz(new Date(), 'US/Mountain').subtract(7, 'days').format('YYYY-MM-DD'))
    if (e.target.value === 'L30days')
      setSdate(moment.tz(new Date(), 'US/Mountain').subtract(30, 'days').format('YYYY-MM-DD'))
  }
  const stylecls = `.actFeed a {
    text-decoration: none
  }`

  return (
    <>
      <style>{stylecls}</style>
      <TableContainer component={Paper} className={classes.papercalender3}>
        <Table className={classes.listTable} aria-label="simple table">
          <Paper className={classes.paperNewStyle}>
            <Grid container alignItems="center">
              <Grid item xs={7} className={classes.act}>
                <span>Activity Feed</span>
              </Grid>
              <Grid item xs={5} className={classes.selectCls}>
                <FormControl size="medium" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    defaultValue={filterval}
                    value={filterval}
                    className={classes.tdd}
                    onChange={(e) => changePeriod(e)}
                  >
                    <MenuItem value="Today">Today</MenuItem>
                    <MenuItem value="L7days">Last 7 days</MenuItem>
                    <MenuItem value="L30days">Last 30 days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <TableBody>
            <div
              className={`${classes.comingSoonText} ${
                acticitydata && !acticitydata?.Activityfeed?.length ? classes.feedsec : ''
              }`}
            >
              {acticitydata &&
                acticitydata?.Activityfeed &&
                acticitydata?.Activityfeed?.length > 0 &&
                acticitydata.Activityfeed?.map((item) => (
                  <div
                    className="actFeed"
                    style={{ paddingTop: '10px' }}
                    dangerouslySetInnerHTML={{ __html: `${item?.notes} ${item?.hours}` }}
                  />
                ))}
              {activityloading && <ProgressIndicator position="relative" />}
              {acticitydata && !acticitydata?.Activityfeed?.length && (
                <div style={{ display: 'flex', alignItems: 'center' }}>No data found</div>
              )}
            </div>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
