import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  Icon,
  AppBar as MAppBar,
  AppBarProps,
  Toolbar,
  List,
  ListItem,
  Link,
  Button,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core'
import { HelpOutline as HelpIcon, CheckBoxOutlined as OnboardingIcon } from '@material-ui/icons'
import { tourRunStatusVar } from 'graphql/vars'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import ChangePasswordForm from 'views/useractions/changepassword/ChangePasswordForm'
import { Role } from 'graphql/hooks.generated'
import LoggedUser from 'components/ui/auth/logged-user/LoggedUser'
import IconButton from '../icon-button/IconButton'
import SettingsMenu, { SettingsItem } from '../settings-menu/SettingsMenu'
import useStyles from './Styles'
import { RouterLink } from '../link/RouterLink'
// import { ReactComponent as Logo } from '../../../../assets/icons/tt-logo.svg'

type IProps = AppBarProps

const { REACT_APP_TEXAS_HOME_URL, REACT_APP_TEXAS_HOME_ES_URL } = process.env
const AppBar: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const isPublicpath = history.location.pathname.includes('/public/')
  const { t, i18n } = useTranslation()
  useMemo(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('loc') === 'en' || params.get('loc') === 'es')
      i18n.changeLanguage(params.get('loc') || 'en')
    else i18n.changeLanguage('en')
  }, [i18n])
  const { data: currentUserData } = useCurrentUser()
  const navlinks = useMemo(() => {
    const userRoles = currentUserData?.currentUser.roles

    if (userRoles?.includes(Role.TeleteachersAdmin))
      return [
        // { title: 'Dashboard', path: '/' },
        { title: 'Districts', path: '/districts' },
        { title: 'Users', path: '/users' },
        // { title: 'Service Requests', path: '/serviceRequests' },
        { title: 'Documentation', path: '/documentation' },
        { title: 'Reports', path: '/reports' },
        { title: 'Services', path: '/services' },
        { title: 'Agency', path: '/agency' },
        { title: 'Ongoingcare', path: '/ongoing care' },
        { title: 'Waitlist', path: '/waitlist' }
      ]
    if (userRoles?.includes(Role.SchoolAdmin))
      return [
        // { title: 'Dashboard', path: '/' },
        // { title: 'Profile', path: '/TherapistInviteView' },
        { title: 'Dashboard', path: '/' },
        { title: 'Users', path: '/users' },
        { title: 'Documentation', path: `/documentation?type=da` },
        { title: 'Reports', path: '/reports' },
        // { title: 'Messages', path: '/messages' },
        { title: 'Clients', path: '/students' },
        { title: 'Provider', path: '/therapists' },
        { title: 'Agency', path: '/agency' },
        { title: 'Ongoingcare', path: '/ongoing care' },
        { title: 'Waitlist', path: '/waitlist' }
        // { title: 'Blackout Days', path: '/blackoutdays' }
      ]
    if (userRoles?.includes(Role.SchoolTherapist) || userRoles?.includes(Role.Therapist))
      return [
        // { title: 'Dashboard', path: '/' },
        // { title: 'Profile', path: '/TherapistInviteView' },
        { title: 'Dashboard', path: '/dashboard' },
        { title: 'Calendar', path: '/calendar' },
        { title: 'Caseload', path: '/caseload' },
        { title: 'Documentation', path: `/documentation?type=tp` }
        // { title: 'Reports', path: '/reports' },
        // { title: 'Resources', path: '/resources' }
        // { title: 'Messages', path: '/messages' }
      ]

    return []
  }, [currentUserData])
  const morelinks = useMemo(() => {
    const userRoles = currentUserData?.currentUser.roles

    if (userRoles?.includes(Role.TeleteachersAdmin))
      return [
        // { title: 'Services', path: '/services' },
        // { title: 'ICD 10 Codes', path: '/icdCodes' },
        // { title: 'Resources', path: '/resources' },
        // { title: 'Assign Therapist', path: '/providerAssignments/create' }
      ]
    if (userRoles?.includes(Role.SchoolAdmin))
      return [
        // { title: 'Therapist', path: '/therapists' },
        // { title: 'Blackout Days', path: '/blackoutdays' }
        // { title: 'Request Services', path: '/serviceRequests/my' }
      ]
    // if (userRoles?.includes(Role.SchoolTherapist) || userRoles?.includes(Role.Therapist))
    //   return [{ title: 'Assignments', path: '/providerAssignments/my' }]

    return []
  }, [currentUserData])
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  useEffect(() => {
    // console.log(currentUserData)
    // browser tab name will change based on locale
    document.title = `${
      i18n.language === 'en' ? 'Mira for Mental Health' : 'Mira for Mental Health'
    }`
  }, [i18n])
  const handleClose = () => {
    setAnchor(null)
  }
  const settings = [
    {
      icon: <HelpIcon fontSize="small" />,
      name: 'FAQ',
      handleClick: () => {
        window.open('https://teleteachershelp.zendesk.com/hc/en-us', '_blank')
      }
    },
    {
      icon: <OnboardingIcon fontSize="small" />,
      name: 'Onboarding',
      handleClick: () => {
        tourRunStatusVar(true)
        handleClose()
      }
    }
  ]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box display="flex" alignItems="center" style={{ height: '60px' }}>
      <MAppBar {...props} position="fixed" className={classes.rootHeader}>
        <Toolbar style={{ minHeight: '60px' }}>
          <Typography className={classes.titleCls}>
            {/* <RouterLink color="secondary" href="/" >
              <div className={classes.logoCls} />
            </RouterLink> */}
            <a
              href={`${
                isPublicpath
                  ? i18n.language === 'es'
                    ? REACT_APP_TEXAS_HOME_ES_URL
                    : REACT_APP_TEXAS_HOME_URL
                  : window.location.origin
              }`}
            >
              <div className={i18n.language === 'es' ? classes.spanishlogoCls : classes.logoCls} />
            </a>
          </Typography>
          <List component="nav" className={classes.flexContainer} aria-labelledby="main navigation">
            {currentUserData &&
              navlinks.map(({ title, path }) => {
                let selectedClass = classes.buttonStyles
                // console.log('path--', path)
                // console.log('history', history.location)
                const pathToCompare = history.location.pathname + history.location.search

                if (pathToCompare === path)
                  selectedClass = `${selectedClass} ${classes.selectedStyles}`

                return (
                  <ListItem key={title} button className={classes.navStyle}>
                    <Link
                      component="button"
                      style={{ textDecoration: 'none' }}
                      onClick={() => history.push(`${path}`)}
                      className={selectedClass}
                    >
                      {title}
                    </Link>
                  </ListItem>
                )
              })}
          </List>
          {currentUserData &&
            !currentUserData?.currentUser.roles?.includes(Role.TeleteachersAdmin) &&
            !currentUserData?.currentUser.roles?.includes(Role.SchoolAdmin) &&
            !currentUserData?.currentUser.roles?.includes(Role.SchoolTherapist) &&
            !currentUserData?.currentUser.roles?.includes(Role.Therapist) &&
            !currentUserData?.currentUser.roles?.includes(Role.Parent) && (
              <Button
                style={{
                  marginLeft: '10px',
                  paddingLeft: '13px',
                  marginTop: '10px',
                  border: '1px solid #ccc'
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                More..
              </Button>
            )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            style={{ width: '100%', maxWidth: '195px' }}
          >
            {currentUserData &&
              morelinks.map(({ title, path }) => {
                let selectedClass = classes.buttonStyles

                if (history.location.pathname === path)
                  selectedClass = `${selectedClass} ${classes.selectedStyles}`

                return (
                  <Link
                    component="button"
                    color="secondary"
                    onClick={() => history.push(`${path}`)}
                    className={selectedClass}
                  >
                    <MenuItem style={{ textDecoration: 'none' }} onClick={handleCloseMenu}>
                      {title}
                    </MenuItem>
                  </Link>
                )
              })}
          </Menu>
          <Typography variant="h3" className={classes.space}>
            &nbsp;
          </Typography>

          <Box display="flex" height="48px">
            {/* <Box mr={2}>
              <IconButton bordered id="help-btn" onClick={handleClick}>
                <Icon fontSize="small">?</Icon>
              </IconButton>
              <SettingsMenu
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleClose}
              >
                {settings.map((setting) => (
                  <SettingsItem onClick={setting.handleClick} key={setting.name}>
                    <Box mr={1}>{setting.icon}</Box>
                    {setting.name}
                  </SettingsItem>
                ))}
              </SettingsMenu>
            </Box> */}

            {currentUserData && (
              <>
                <LoggedUser />
                <ChangePasswordForm
                  email={currentUserData?.currentUser.email}
                  id={currentUserData?.currentUser.id}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </MAppBar>
      {/* <Toolbar style={{ minHeight: '90px' }} /> */}
      {currentUserData && (
        <>
          <ChangePasswordForm
            email={currentUserData?.currentUser.email}
            id={currentUserData?.currentUser.id}
          />
        </>
      )}
    </Box>
  )
}

export default AppBar
