import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { engLoc, spaLoc } from 'config/constants/translations'
import { VideoContext } from 'views/public/video/video'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext()
  const loc = useContext(VideoContext)

  return (
    <Button
      onClick={() => room?.disconnect()}
      // eslint-disable-next-line react/destructuring-assignment
      className={props.className}
      data-cy-disconnect
    >
      {loc === 'es' ? spaLoc.video.end : engLoc.video.end}
    </Button>
  )
}
