import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory } from 'react-router-dom'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { Role } from 'graphql/hooks.generated'
import { Box, Typography, useTheme } from '@material-ui/core'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import Link from '../../../../components/ui/panel/link/Link'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}
const tabPanelArr = [
  {
    linkText: 'Billing Report',
    description: 'This report shows provider timesheet',
    handleClicktext: 'Billing-Report'
  }
  // {
  //   linkText: 'Client Progress Report',
  //   description: 'This report shows progress and notes for all clients on your caseload',
  //   handleClicktext: 'progress-report'
  // },
  // {
  //   linkText: 'Client Progress Summary',
  //   description:
  //     'This report shows session goal progress for a single client or for all clientss on your caseload',
  //   handleClicktext: 'client-progress-summary'
  // },
  // {
  //   linkText: 'Direct/Indirect Time Report',
  //   description: 'This report shows direct and indirect time for a provider ',
  //   handleClicktext: 'Direct-Indirect'
  // },
  // {
  //   linkText: 'Provider Service Minutes',
  //   description:
  //     'Shows how many minutes of therapy each client has received over a given time period.',
  //   handleClicktext: 'Provider-Service-Minutes'
  // }
]

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  indicator: {
    backgroundColor: '#f1583a',
    top: '45px'
  },
  plr10: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  cursor: {
    cursor: 'pointer'
  },
  comingSoonText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '350px',
    fontSize: '20px'
  }
}))

export default function ReportsTabsListView() {
  const history = useHistory()
  const { hasAnyRole } = useCurrentUser()
  const theme = useTheme()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, newValue: number) => {
    setValue(newValue)
  }
  const handleClick = (url) => {
    history.push(`/reports/${url}`)
  }
  interface LinkTabProps {
    label?: string
    href?: string
  }

  function LinkTab(props: LinkTabProps) {
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault()
        }}
        {...props}
      />
    )
  }

  return (
    <>
      <NavBar title="Reports" goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography
                variant="h3"
                style={{ color: theme.palette.info.main, fontFamily: 'Roboto' }}
              >
                Reports
              </Typography>
            </Box>
          </Box>
          <div className={classes.root}>
            {tabPanelArr.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={idx}>
                {(hasAnyRole(Role.TeleteachersAdmin) || hasAnyRole(Role.SchoolAdmin)) && idx === 0 && (
                  <>
                    <Link onClick={() => handleClick(item.handleClicktext)}>
                      <span
                        className={classes.cursor}
                        style={{ fontSize: '15px', fontFamily: 'Roboto' }}
                      >
                        {item.linkText}
                      </span>
                    </Link>
                    <div style={{ fontSize: '15px', fontFamily: 'Roboto' }}>{item.description}</div>
                    <br />
                  </>
                )}
                {idx !== 0 && (
                  <>
                    {idx !== 3 && (
                      <>
                        {idx !== 4 && (
                          <>
                            <Link
                              className={classes.cursor}
                              onClick={() => handleClick(item.handleClicktext)}
                            >
                              <span className={classes.cursor}>{item.linkText}</span>
                            </Link>
                            <div>{item.description}</div>
                          </>
                        )}
                        {(hasAnyRole(Role.TeleteachersAdmin) ||
                          hasAnyRole(Role.SchoolAdmin) ||
                          hasAnyRole(Role.SchoolTherapist) ||
                          hasAnyRole(Role.Therapist)) &&
                          idx === 4 && (
                            <>
                              <Link onClick={() => handleClick(item.handleClicktext)}>
                                <span className={classes.cursor}>{item.linkText}</span>
                              </Link>
                              <div>{item.description}</div>
                            </>
                          )}
                      </>
                    )}
                    {(hasAnyRole(Role.TeleteachersAdmin) || hasAnyRole(Role.SchoolAdmin)) &&
                      idx === 3 && (
                        <>
                          <Link onClick={() => handleClick(item.handleClicktext)}>
                            <span className={classes.cursor}>{item.linkText}</span>
                          </Link>
                          <div>{item.description}</div>
                        </>
                      )}
                    <br />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </PageContainer>
    </>
  )
}
