import React, { useState, useEffect, useMemo } from 'react'
import { TextField, Select, DatePicker, makeValidate } from 'mui-rff'
import { Form, FormRenderProps } from 'react-final-form'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import { UseParamsType } from 'types'
import { useParams } from 'react-router-dom'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import {
  // Grid,
  InputLabel,
  Container,
  FormControl,
  Button,
  Table,
  TableContainer,
  DialogActions,
  DialogTitle,
  MenuItem
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {
  useCreateNotesMutation,
  useNoteCategoryQuery,
  useNotesQuery
} from 'graphql/hooks.generated'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  DetailRow,
  GridModel,
  SortSettingsModel,
  Filter,
  FilterSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import Avatar from '@material-ui/core/Avatar'
import * as yup from 'yup'
import { useCurrentUser } from '../../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { useStyles } from '../../../../../config/constants/theme/studentprofile'

interface StudentNotesModal {
  formnote: string
  formcategory: string
}
interface ListViewParams extends UseParamsType {
  stu_id: string
}

interface Parentparams {
  studentDetails: any
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3.5)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // fontFamily: [
      //   '-apple-system',
      //   'BlinkMacSystemFont',
      //   '"Segoe UI"',
      //   'Roboto',
      //   '"Helvetica Neue"',
      //   'Arial',
      //   'sans-serif',
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"'
      // ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase)
const noteValidator = () =>
  makeValidate(
    yup.object<StudentNotesModal>({
      formnote: yup.mixed<string>().label('Note').required(),
      formcategory: yup.mixed<string>().label('Category').required()
    })
  )

export const StudentProfileNotes = (props: Parentparams) => {
  const classes = useStyles()
  const { studentDetails } = props
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const { stu_id } = useParams<ListViewParams>()
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.noteslistq?.columnName
          ? sfStoreState?.noteslistq?.columnName
          : 'mt_date',
        direction: sfStoreState?.noteslistq?.columnDirection
          ? sfStoreState?.noteslistq?.columnDirection
          : 'Descending'
      }
    ]
  }
  const searchOptions = {
    fields: ['mt_date', 'createdby.name', 'category_id.name', 'note'],
    key: sfStoreState?.noteslistq?.searchText,
    ignoreCase: true
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.noteslistq?.pageSize ? sfStoreState?.noteslistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.noteslistq?.currentPage ? sfStoreState?.noteslistq?.currentPage : 1
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const [openDialog, setOpenDialog] = useState(false)
  const initialData = {}
  const validator = useMemo(() => noteValidator(), [])
  const { data: currentdata, loading: currentLoading } = useCurrentUser()
  const { data: notesdata, loading: notesloading, refetch } = useNotesQuery({
    variables: {
      student_id: Number(stud_id)
    },
    fetchPolicy: 'network-only'
  })
  const { data: categorydata, loading: categoryloading } = useNoteCategoryQuery({
    fetchPolicy: 'network-only'
  })
  const [createNotesQuery, { loading: createnoteloading }] = useCreateNotesMutation()
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const formSubmit = async (values) => {
    await createNotesQuery({
      variables: {
        input: {
          note: values.formnote,
          student_id: Number(stud_id),
          reference_id: null,
          category_id: Number(values.formcategory),
          createdby: currentdata?.currentUser?.id,
          mt_date: moment.tz(moment(), 'US/Mountain').format()
        }
      }
    })
    handleCloseDialog()
    refetch()
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            noteslistq: { ...sfStoreState.noteslistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              noteslistq: { ...sfStoreState.noteslistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          noteslistq: {
            ...sfStoreState.noteslistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          noteslistq: {
            ...sfStoreState.noteslistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }
  const renderForm = (renderProps: FormRenderProps<StudentNotesModal>) => {
    const { handleSubmit, values } = renderProps

    return (
      <Dialog
        open={openDialog}
        fullWidth
        disableBackdropClick
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.noteModaltitle} ${classes.brandonFF} ${classes.aligncenter}`}
        >
          Add Note
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <form>
              {/* <Grid container> */}
              <div>
                <div className={classes.pb10}>
                  <div className={`${classes.alignLeft}`}>
                    <InputLabel
                      htmlFor="label-note"
                      className={`${classes.studentlabels} ${classes.iBlock}`}
                    >
                      Note
                    </InputLabel>
                    *
                  </div>
                  <TextField
                    focused={false}
                    id="label-note"
                    fullWidth
                    multiline
                    variant="outlined"
                    name="formnote"
                    required
                    className={`${classes.inputPadding} ${classes.brandonFF}`}
                  />
                </div>
                <div className={classes.pb10}>
                  <div className={`${classes.alignLeft}`}>
                    <InputLabel
                      htmlFor="label-category"
                      className={`${classes.studentlabels} ${classes.iBlock}`}
                    >
                      Category
                    </InputLabel>
                    *
                  </div>
                  <Select
                    name="formcategory"
                    labelId="demo-customized-select-label"
                    id="label-category"
                    required
                    input={<BootstrapInput />}
                    className={`${classes.brandonFF} ${classes.dropDown} ${classes.mt0}`}
                    variant="filled"
                  >
                    {categorydata &&
                      categorydata?.noteCategory?.map((catObj) => (
                        <MenuItem className={classes.brandonFF} value={catObj?.id}>
                          {catObj.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              {/* </Grid> */}
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.brandonFF} onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            className={`${classes.save} ${classes.brandonFF}`}
            onClick={handleSubmit}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      {(notesloading || categoryloading || createnoteloading || currentLoading) && (
        <ProgressIndicator fullHeight />
      )}
      {/* <Grid container> */}
      <div>
        <div className={classes.namecard}>
          <div>
            <Avatar style={{ float: 'left' }} className={classes.avatar}>
              {studentDetails &&
                studentDetails?.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
            </Avatar>
            <div style={{ float: 'left', padding: '10px' }}>
              {studentDetails && (
                <>
                  <span style={{ fontWeight: 'bold', color: '#F1583A', fontSize: '22px' }}>
                    {studentDetails?.name}
                  </span>
                  <div>
                    {moment().diff(moment(studentDetails?.birth_date), 'years', false)} years old
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <TableContainer className={classes.updatetable}>
          <Table className={classes.table} aria-label="simple table">
            <GridComponent
              key={Math.floor(Math.random() * 100)}
              dataSource={notesdata?.notes || []}
              allowTextWrap
              allowPaging
              pageSettings={pageOptions}
              className={classes.brandonFF}
              searchSettings={searchOptions}
              toolbar={toolbarOptions}
              allowSorting
              sortSettings={sortSettings}
              ref={(g) => gridref(g)}
              created={createdevent}
              actionComplete={(value) => handleActionBegin(value)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="mt_date"
                  headerText="DATE"
                  width="100"
                  textAlign="Left"
                  template={(dateobj) => (
                    <div>
                      {moment.tz(dateobj.mt_date, 'US/Mountain').format('MM/DD/YYYY hh:mm A')}
                    </div>
                  )}
                />
                <ColumnDirective
                  field="createdby.name"
                  headerText="CREATED BY"
                  template={(createdbyobj) => <div>{createdbyobj.createdby?.name}</div>}
                  width="100"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="category_id.name"
                  headerText="CATEGORY"
                  template={(category) => <div>{category.category_id?.name}</div>}
                  width="100"
                  textAlign="Left"
                />
                <ColumnDirective field="note" headerText="NOTE" width="150" textAlign="Left" />
              </ColumnsDirective>
              <Inject services={[Toolbar, Filter, Search, Sort, Page, DetailRow]} />
              <div className={`${classes.headsection} ${classes.ptb10}`}>
                <Button
                  className={`${classes.brandonFF} ${classes.save} ${classes.alignRightt} ${classes.ptb10}`}
                  onClick={handleOpenDialog}
                >
                  Add Note
                </Button>
              </div>
            </GridComponent>
          </Table>
        </TableContainer>
      </div>
      {/* </Grid> */}
      <Form<StudentNotesModal>
        initialValues={initialData}
        key={Math.floor(Math.random() * 100)}
        validate={validator}
        render={renderForm}
        onSubmit={formSubmit}
      />
    </>
  )
}
