import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles(({ palette }: Theme) => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px dashed ${palette.divider}`
  },
  container: {
    padding: '0px 40px',
    width: '100%'
  },
  links: {
    textAlign: 'end'
  }
}))
