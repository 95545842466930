import { AuthenticationTokens } from '../graphql/hooks.generated'

export interface AuthenticationContext {
  isAuthenticated: boolean
  tokens: AuthenticationTokens | undefined
  logout(): void
  setFallbackTokens(
    tokens: AuthenticationTokens | Pick<AuthenticationTokens, 'id' | 'access'>,
    redirectUrl: string
  ): void
}

export enum DashboardType {
  ADMIN = 'District Administrator',
  TELETEACHERS_ADMIN = 'Teleteachers Administrator',
  THERAPIST = 'Therapist',
  PARENT = 'Parent'
}

export type RegisterOptions = {
  name: string
  username: string
  password: string
}

export interface UseParamsType {
  [key: string]: string | undefined
}
