import React from 'react'
import { useListChatUsersQuery } from 'graphql/hooks.generated'

export const withUserList = (Component) => (props) => {
  const { data: usersData, loading: usersLoading } = useListChatUsersQuery()
  const usersAvailable = () => {
    return !usersLoading && !!usersData?.listChatUsers?.length
  }
  const getUsers = () => {
    return usersAvailable() ? usersData?.listChatUsers || [] : []
  }

  return <Component getUsers={getUsers} usersAvailable={usersAvailable} {...props} />
}

export default withUserList
