/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react'
import moment from 'moment'
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule'
import Button from '../../../../components/ui/panel/button/Button'
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import {
  useAppointmentsListLazyQuery,
  useStudentSelectLazyQuery,
  useServiceSelectLazyQuery,
  useCreateAppointmentListMutation,
  useAppointmentsListQuery,
  useUpdateAppointmentListMutation,
  useDeleteAppointmentListMutation,
  useAppointmentStudentsLazyQuery,
  useDeleteAppointmentListsMutation,
  useServicesLazyQuery,
  useSessionStatusLazyQuery,
  useCaseloadStatusLazyQuery,
  useUpdateAppointmentstatusMutation,
  useDeleteMutation,
  useUserServiceListLazyQuery,
  useUpdateAppointmentServicesMutation
} from '../../../../graphql/hooks.generated'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import CancelAppointmentDialog from '../component/cancelAppointment'
import ResendAppointmentDialog from '../component/resendEmail'

const InputField = ({ title, value }) => {
  return (
    <React.Fragment>
      <div className="field-name-div p-x">
        <p className="field-name">{title} </p>
        <p style={{ padding: '0 20px' }}>: </p>
        <div className="e-text-fields-custom" style={{ width: '100%' }}>
          <p style={{ margin: '0px' }}>{value}</p>
          {/* <input id="Summary"  readOnly = {true} value={cellData?.Subject} className="e-field e-input " type="text" placeholder=" Title here" data-name="Summary" style={{ width: '100%' }}/> */}
        </div>
      </div>
    </React.Fragment>
  )
}

const ScheduleViewDialog = ({
  cellData,
  blockout,
  onChange,
  repeat,
  appointmentListData,
  currentUserId,
  currentUserDisID,
  admin,
  modalHeader,
  cancelHeader,
  emailHeader,
  openEdit
}) => {
  const [deleteAppointmentList] = useDeleteAppointmentListMutation()
  const [updateAppointment] = useUpdateAppointmentListMutation()
  const [updateStatus] = useUpdateAppointmentstatusMutation()
  const [updateService] = useUpdateAppointmentServicesMutation()
  const [rejectAppointment] = useDeleteMutation()
  const [appointmentsLoading, setAppointmentsLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const history = useHistory()
  const appointment_id = cellData.id
  const scheduled_start = moment(cellData.startTime).format()
  const scheduled_end = moment(cellData.endTime).format()
  const user_id = currentUserId
  const student_id = cellData?.EventStudents[0]?.id
  const [sessionStausQuery, { data: sessionStatus, loading }] = useSessionStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: { appointment_id, scheduled_start, scheduled_end }
  })
  const [
    caseloadStatusQuery,
    { data: activeStatus, loading: activeStatusLoading, refetch }
  ] = useCaseloadStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: { user_id, student_id }
  })
  const [appointmentStatus, setAppointmentStatus] = useState(true)
  const [quickLaunchStatus, setQuickLaunchStatus] = useState(false)
  const [toolTipMsg, setToolTipMsg] = useState('')
  const [timeCount, setTimeCount] = useState(0)
  const [approvLoading, setApprovLoading] = useState(false)
  const [cancelAppointment, setCancelAppointment] = useState(false)
  const [resendEmail, setResendEmail] = useState(false)
  const [updateEvent, setUpdateEvent] = useState(false)
  const [therapistServices, setTherapistServices] = useState([])
  const [updateServiceId, setUpdateServiceId] = useState(cellData?.signal_service_id?.id)
  const { data: currentUserData } = useCurrentUser()

  const [therapistId, setTherapistId] = useState(admin ? admin : currentUserData?.currentUser?.id)
  const [
    executeTherapistQuery,
    { data: userServiceList, loading: userServiceLoading }
  ] = useUserServiceListLazyQuery({
    variables: { id: therapistId },
    fetchPolicy: 'network-only'
  })
  const TherapistService = useMemo(() => () => executeTherapistQuery(), [executeTherapistQuery])

  const sessionStausFetch = useMemo(() => () => sessionStausQuery(), [sessionStausQuery])
  const caseloadStatusFetch = useMemo(() => () => caseloadStatusQuery(), [caseloadStatusQuery])

  function checkTime(item, type) {
    var currentTime = moment()
    var timeStore = moment(item.created)
    return currentTime.diff(timeStore, type)
  }
  // console.log(data?.sessionStatus)
  //2021-07-14T00:00:00+05:30
  const _directAppointMent = '1'
  const _inDirectAppointMent = '2'
  const _blockOutDaya = '3'
  const _view = 'view'
  const _edit = 'edit'
  const _editOccurence = 'edit_occurence'
  const _editFollowing = 'edit_following'
  const _quickLaunch = 'quick'
  const _refreshAppointments = 'refetch'
  const _detailsLoading = 'Please wait fetching Appointment details'

  const _createLoadingMsg = 'Please wait while event Create/Update is in progress'
  const _deleteLoadingMsg = 'Please wait while event Delete is in progress'
  const _launchsession = 'Click to Launch Session'
  const _dontLaunchSession = 'CAN NOT LAUNCH SESSION BEFORE THE SESSION START TIME'
  const therapistserviceFields = { text: 'label', value: 'value' }

  let studentsString = ''
  let subjectString = ''
  let serviceString = ''

  let students = cellData?.EventStudents?.map((item, i) => {
    // lstudentString = ''
    if (i === 0) {
      studentsString += item.name
    } else {
      studentsString += ', ' + item.name
    }

    // return studentString
  })
  let serviceIds = cellData?.EventService?.map((item, i) => {
    // lstudentString = ''
    if (i === 0) {
      serviceString += item.name
    } else {
      serviceString += ', ' + item.name
    }

    // return studentString
  })
  const onchangeHandel = (value, key) => {
    setUpdateEvent(true)
    setUpdateServiceId(Number(value.itemData.value))
  }
  const UpdateSeviceEvent = () => {
    setDeleteLoading(true)
    updateService({
      variables: {
        id: appointment_id,
        input: {
          signal_service_id: updateServiceId
        }
      }
    }).then(async () => {
      // refetch()
      await onChange(_refreshAppointments)
      setDeleteLoading(false)
    })
  }
  const CancelUpadte = () => {
    setUpdateEvent(false)
    setUpdateServiceId(cellData?.signal_service_id?.id)
  }

  const deleteAppointment = () => {
    setDeleteLoading(true)
    deleteAppointmentList({ variables: { id: cellData.id } }).then(async () => {
      // refetch()
      await onChange(_refreshAppointments)
      setDeleteLoading(false)
    })
  }
  const deleteOccurenceAppointment = (action) => {
    let startDate = new Date(cellData.startTime)
    let endDate = new Date(cellData.endTime)
    setDeleteLoading(true)
    let dataUpdate = cellData
    let filterId
    const filterREcureence = appointmentListData?.map((recu) => {
      if (recu.id === dataUpdate.id) {
        filterId = recu
      }
    })
    let peString
    let ieString
    ieString = moment.utc(cellData.startTime || cellData.StartTime).format()

    ieString = ieString.replace(/[:-]/g, '')
    let students = filterId?.EventStudents?.map((item, i) => {
      return item.id
    })
    let recurenceString = filterId.RecurrenceRule

    if (repeat === _editFollowing) {
      let utilString = ieString.slice(0, 9)
      utilString = utilString + '000000Z'
      if (recurenceString?.includes('UNTIL')) {
        let reccurenceRuleEdit = filterId.RecurrenceRule.split(';')
        recurenceString = ''

        reccurenceRuleEdit.map((itm) => {
          if (itm?.includes('UNTIL')) {
            recurenceString += 'UNTIL=' + utilString + ';'
          } else {
            recurenceString += itm + ';'
          }
        })
      } else {
        recurenceString += 'UNTIL=' + utilString + ';'
      }
    }
    let recurence =
      repeat === _editOccurence
        ? filterId.RecurrenceException + ',' + ieString
        : filterId.RecurrenceException
    let startTime = new Date()
    let temObj = {
      // ...fil
      appointmentTypeId: filterId.appointment_type_id.id,
      userId: currentUserId,
      createdBy: currentUserId,
      updatedBy: currentUserId,
      districtId: Number(currentUserDisID),
      serviceId: filterId.EventService.id,
      title: filterId.Subject,
      recurranceRRule: recurenceString,
      students: students,
      isreccuring: true,
      recurrenceException: recurence,
      endDate: moment(filterId.endTime || filterId.EndTime).format(),
      startDate: moment(filterId.startTime || filterId.StartTime).format()
    }

    // setAppointmentsLoading(true)
    updateAppointment({ variables: { id: filterId.id, input: { ...temObj } } }).then(async () => {
      await onChange(_refreshAppointments)
      setDeleteLoading(false)
    })
  }
  const acceptAppointment = () => {
    setApprovLoading(true)
    updateStatus({
      variables: {
        user_id,
        student_id,
        appointment_slot_id: cellData.appointment_slot_id,
        input: { status: 1 }
      }
    }).then(async () => {
      await refetch()
      setApprovLoading(false)
    })
  }
  const rejectAppointmentHandle = () => {
    rejectAppointment({
      variables: {
        user_id,
        student_id,
        appointment_slot_id: cellData.appointment_slot_id,
        input: { status: 0 }
      }
    }).then(async () => {
      await onChange(_refreshAppointments)
      setDeleteLoading(false)
    })
  }
  React.useEffect(() => {
    if (currentUserData?.currentUser?.id) {
      TherapistService()
    }
  }, [currentUserData])
  useEffect(() => {
    sessionStausFetch()
    caseloadStatusFetch()
  }, [])
  React.useEffect(() => {
    setUpdateServiceId(cellData?.signal_service_id?.id)
    setTherapistServices(userServiceList?.userServiceList?.[0]?.signal_service_id)
  }, [userServiceList])
  // const checkStatus = () => {
  //   setAppointmentStatus(false)
  //   setQuickLaunchStatus(true)
  //   setToolTipMsg(_launchsession)
  //   // }
  // }
  // const launchSession = () => {
  //   if (quickLaunchStatus) {
  //     history.push(
  //       `/calendar/${cellData.id}/${moment(cellData.startTime).format()}/${moment(
  //         cellData.endTime
  //       ).format()}/sessions`
  //     )
  //   }
  // }
  const checkStatus = () => {
    // console.log(data?.sessionStatus)
    if (sessionStatus?.sessionStatus) {
      const scheduleDate = moment(scheduled_end).add(24, 'h')
      const item = { created: moment(scheduleDate).subtract(24, 'h').toDate() }
      const res = checkTime(item, 'h')
      if (res < 24) {
        setAppointmentStatus(false)
        setQuickLaunchStatus(true)
        setToolTipMsg(_launchsession)
      } else {
        setAppointmentStatus(true)
      }
      // console.log(res) // 5
    } else if (sessionStatus?.sessionStatus === false) {
      setAppointmentStatus(false)
      setQuickLaunchStatus(true)
      setToolTipMsg(_launchsession)
    }
  }
  const launchSession = () => {
    if (quickLaunchStatus) {
      history.push(
        `/calendar/${cellData.id}/${moment(cellData.startTime).format()}/${moment(
          cellData.endTime
        ).format()}/sessions`
      )
    }
  }
  const launchSessionStatus = () => {
    let CurrentDate = new Date()
    let GivenDate = new Date(scheduled_start)
    const formatCurrentDate = moment.tz(CurrentDate, 'US/Mountain').format('YYYY-MM-DD')
    const formatStartDate = moment.tz(scheduled_start, 'US/Mountain').format('YYYY-MM-DD')
    if (admin) {
      setQuickLaunchStatus(false)
      setToolTipMsg(_dontLaunchSession)
    } else if (formatCurrentDate === formatStartDate) {
      setQuickLaunchStatus(true)
      setToolTipMsg(_launchsession)
    } else if (CurrentDate > GivenDate) {
      checkStatus()
    } else if (CurrentDate < GivenDate) {
      setQuickLaunchStatus(false)
      setToolTipMsg(_dontLaunchSession)
    }
  }
  const cancelAppointmentChange = (action) => {
    if (action === 'back') {
      setCancelAppointment(false)
      modalHeader(false, 'cancel')
    } else if (action === 'canceled' || action === 'emailSent') onChange(_refreshAppointments)
    else if (action === 'emailBack') {
      setResendEmail(false)
      modalHeader(false, 'email')
    }
  }
  const ResendOnclick = () => {
    setResendEmail(true)
    modalHeader(true, 'email')
  }

  useEffect(async () => {
    launchSessionStatus()
  }, [sessionStatus?.sessionStatus])

  // useEffect(async () => {
  //   var CurrentDate = new Date()
  //   let GivenDate = new Date(scheduled_start)
  //   if (GivenDate > CurrentDate) {
  //     setAppointmentStatus(false)
  //     setQuickLaunchStatus(false)
  //     setToolTipMsg(_dontLaunchSession)

  //   } else {
  //     setAppointmentStatus(false)
  //     setQuickLaunchStatus(true)
  //     setToolTipMsg(_launchsession)
  //   }
  // }, [sessionStatus])
  // Example Date object representing 5 hours ago

  return (
    // <pre>{props}</pre>
    <React.Fragment>
      {deleteLoading && (
        <div className="scheduleLoader" style={{ display: 'flex', padding: '30px' }}>
          <CircularProgress />
          <p style={{ textAlign: 'center', marginLeft: '40px' }}>{_createLoadingMsg}</p>
        </div>
      )}
      {!deleteLoading && !cancelAppointment && !resendEmail && !cancelHeader && !emailHeader && (
        <div style={{ paddingBottom: blockout ? '18px' : '0px' }}>
          {!blockout && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                style={{
                  display: 'flex-end',
                  justifyContent: 'flex-end',
                  paddingBottom: activeStatus?.caseloadStatus ? '15px ' : '0px'
                }}
              >
                {(cellData?.appointment_type_id == _directAppointMent ||
                  cellData?.appointment_type_id?.id == _directAppointMent) &&
                  !updateEvent && (
                    <TooltipComponent
                      className="tooltip-box"
                      content={toolTipMsg}
                      target="#tooltip"
                    >
                      <Button
                        color="primary"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="mar-rt-15 "
                        variant="outlined"
                        size="medium"
                        style={{ height: '36px', marginBottom: '10px' }}
                        onClick={() => openEdit()}
                        disabled={sessionStatus?.sessionStatus || loading}
                      >
                        {'Reschedule'}
                      </Button>
                      <Button
                        color="primary"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="mar-rt-15"
                        variant="outlined"
                        size="medium"
                        style={{
                          height: '36px',
                          marginBottom: '10px'
                        }}
                        onClick={() => {
                          setCancelAppointment(true), modalHeader(true, 'cancel')
                        }}
                        disabled={sessionStatus?.sessionStatus || loading}
                      >
                        {'Cancel'}
                      </Button>
                    </TooltipComponent>
                  )}

                {/* <Button color="primary" aria-controls="simple-menu" aria-haspopup="true"   className="mar-rt-15 m-t-5 m-b-20" variant="contained" size="medium"  style={{height: '36px'}} onClick={() =>  onChange('edit')}>
                        { 'Move'}
                    </Button> */}
              </div>
              {/* )} */}
            </div>
          )}
          <InputField title={'Title'} value={cellData?.Subject} />
          {sessionStatus?.sessionStatus === false && (
            <div className="field-name-div p-x">
              <p className="field-name">Service</p>
              <p style={{ padding: '0 20px' }}>: </p>
              <div style={{ width: '100%' }}>
                <DropDownListComponent
                  id="EventStudents"
                  openOnClick
                  placeholder="Select Service"
                  data-name="EventStudents"
                  className="e-field"
                  style={{ width: '100%' }}
                  fields={therapistserviceFields}
                  dataSource={therapistServices}
                  value={updateServiceId?.toString()}
                  select={(value) => onchangeHandel(value, 'signal_service_id')}
                  // change={(value) => onchangeHandel(value, 'signal_service_id')}
                  popupHeight="250"
                ></DropDownListComponent>
              </div>
            </div>
          )}
          {(sessionStatus?.sessionStatus || loading) && (
            <InputField title={'Service'} value={cellData?.signal_service_id?.name} />
          )}
          <InputField
            title={'From'}
            value={moment(cellData?.StartTime || cellData?.startTime).format('MM/D/YYYY hh:mm A')}
          />
          <InputField
            title={'To'}
            value={moment(cellData?.EndTime || cellData?.endTime).format('MM/D/YYYY hh:mm A')}
          />
          {!blockout && (
            <>
              {/* <InputField title={'Service'} value={serviceString} /> */}
              {(cellData?.appointment_type_id == _directAppointMent ||
                cellData?.appointment_type_id?.id == _directAppointMent) && (
                <InputField title={'Client'} value={studentsString} />
              )}
              {(cellData?.appointment_type_id == _inDirectAppointMent ||
                cellData?.appointment_type_id?.id == _inDirectAppointMent) && (
                <InputField title={'Note'} value={cellData?.note} />
              )}
            </>
          )}
          {!updateEvent && (
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 "
              variant="contained"
              size="medium"
              style={{
                height: '36px',
                marginTop: '8px',
                background: '#2c9bd6',
                position: 'absolute'
              }}
              onClick={() =>
                history.push(
                  `/studentsinfo/${btoa(
                    `${cellData?.EventStudents[0].id}_${cellData?.EventStudents[0].id}`
                  )}`
                )
              }
            >
              Client Info
            </Button>
          )}
          {!blockout && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                style={{
                  display: 'flex-end',
                  justifyContent: 'flex-end',
                  paddingBottom: activeStatus?.caseloadStatus ? '15px ' : '0px'
                }}
              >
                {(cellData?.appointment_type_id == _directAppointMent ||
                  cellData?.appointment_type_id?.id == _directAppointMent) && (
                  <TooltipComponent className="tooltip-box" content={toolTipMsg} target="#tooltip">
                    {/* <Button
                      color="primary"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="mar-rt-15 m-t-5 m-b-20"
                      variant="contained"
                      size="medium"
                      style={{ height: '36px' }}
                      onClick={() => onChange('edit')}
                      disabled={sessionStatus?.sessionStatus || loading}
                    >
                      {'Reschedule'}
                    </Button> */}
                    <Button
                      color="primary"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="mar-rt-15 m-t-5 m-b-20"
                      variant="outlined"
                      size="medium"
                      // style={{
                      //   height: '36px',
                      //   backgroundColor: quickLaunchStatus && !admin ? '#F1583A' : '#777777',
                      //   color: '#fff'
                      // }}
                      onClick={() => (updateEvent ? CancelUpadte() : ResendOnclick())}
                      // disabled={!quickLaunchStatus}
                    >
                      {updateEvent ? 'Cancel' : 'Resend'}
                    </Button>
                    <Button
                      color="primary"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="mar-rt-15 m-t-5 m-b-20"
                      variant="contained"
                      size="medium"
                      // style={{
                      //   height: '36px',
                      //   backgroundColor: quickLaunchStatus && !admin ? '#F1583A' : '#777777',
                      //   color: '#fff'
                      // }}
                      onClick={() => (updateEvent ? UpdateSeviceEvent() : launchSession())}
                      disabled={updateEvent ? false : !quickLaunchStatus}
                    >
                      {updateEvent ? 'Save' : 'Launch Session'}
                    </Button>
                  </TooltipComponent>
                )}

                {/* <Button color="primary" aria-controls="simple-menu" aria-haspopup="true"   className="mar-rt-15 m-t-5 m-b-20" variant="contained" size="medium"  style={{height: '36px'}} onClick={() =>  onChange('edit')}>
                        { 'Move'}
                    </Button> */}
              </div>
              {/* )} */}
            </div>
          )}
          {/* <InputField title={'Title'} value = {cellData?.Subject} />           */}
        </div>
      )}
      {(cancelAppointment || cancelHeader) && (
        <CancelAppointmentDialog cellData={cellData} onChange={cancelAppointmentChange} />
      )}
      {(resendEmail || emailHeader) && (
        <ResendAppointmentDialog cellData={cellData} onChange={cancelAppointmentChange} />
      )}
    </React.Fragment>
  )
}
export default ScheduleViewDialog
