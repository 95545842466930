/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, TextField } from '@material-ui/core'
import { useConfirmForgetPasswordMutation } from 'graphql/hooks.generated'
import { theme } from 'config/constants/theme'
// import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Dialog from 'components/ui/panel/dialog/Dialog'
import IconButton from 'components/ui/panel/icon-button/IconButton'
import HttpsIcon from '@material-ui/icons/Https'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

interface ForgotPasswordFormProps {
  email: string
  open: boolean
  handleClose: () => unknown
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    }
  })
)
const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props: ForgotPasswordFormProps) => {
  const { email, handleClose, open } = props
  const initailValues = { emailPaswd: '', newPasswd: '', confiPaswd: '' }
  const [showEmailPaswd, toggleEmailPaswd] = useState<boolean>(false)
  const [shownewPaswd, toggleNewPaswd] = useState<boolean>(false)
  const [showconfiPaswd, toggleConfiPaswd] = useState<boolean>(false)
  const [formValues, setFormValues] = useState(initailValues)
  const [formErrors, setFormErrors] = useState({ newPasswd: '' })
  const [showSpinner, setSpinner] = useState<boolean>(false)
  const [forgotpasswdErrMsg, setforgotpasswdErrMsg] = useState<string>('')
  const [forgotpasswdSusMesg, setforgotpasswdSusMesg] = useState<string>('')
  const [confirmForgetPasswordMutation] = useConfirmForgetPasswordMutation()
  const classes = useStyles()
  const confirmForgotPasswordFun = async (id, { Username, Password, ConfirmationCode }) => {
    setSpinner(true)
    try {
      await confirmForgetPasswordMutation({
        variables: { id, input: { Username, Password, ConfirmationCode } }
      })
      setSpinner(false)
      setforgotpasswdSusMesg('Password updated successfully.')
      setTimeout(() => {
        handleClose()
      }, 3000)
    } catch (e) {
      // console.log('Error--------', e)
      setSpinner(false)
      const errmsg = e.graphQLErrors[0].message
      // console.log(errmsg)
      setforgotpasswdErrMsg(errmsg)
      // handleClose()
    }
  }
  const submitOtpForm = () => {
    // console.log('final OTP form values', formValues)
    const id = email
    const inputData = {
      Username: email,
      Password: formValues.newPasswd,
      ConfirmationCode: formValues.emailPaswd
    }
    confirmForgotPasswordFun(id, inputData)
  }
  useEffect(() => {
    // console.log('formvalues', formValues)
  })
  const validateForm = (values) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{7,15}$/
    const errors = { newPasswd: '' }

    if (values.newPasswd !== values.confiPaswd) errors.newPasswd = 'Password not match'
    if (values.newPasswd.length >= 8 && !regex.test(values.newPasswd))
      errors.newPasswd = `Password should contain 8 - 15 characters with at least one uppercase, lowercase, numeric
        and special character,white space not allowed!`

    return errors
  }
  const handleFormChanges = (e) => {
    // console.log(e)
    const { name, value } = e.target
    formValues[name] = value
    setFormValues(formValues)
    setFormErrors(validateForm(formValues))
    setforgotpasswdErrMsg('')
  }
  const submitDialog = () => {
    // console.log('submit data', formValues)
    const errors = validateForm(formValues)
    let hasErrors = false
    Object.values(errors).forEach((value) => {
      if (value) hasErrors = true
    })

    if (hasErrors) setFormErrors(errors)
    if (!hasErrors) submitOtpForm()
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {showSpinner ? <ProgressIndicator fullHeight /> : ''}
      {open && (
        <Dialog
          handleClose={handleClose}
          handleSubmit={submitDialog}
          disabled={
            !(
              formValues.emailPaswd &&
              formValues.newPasswd &&
              formValues.newPasswd.length >= 8 &&
              formValues.confiPaswd &&
              formValues.confiPaswd.length >= 8
            )
          }
          submit="Submit"
          cancel="Cancel"
          open={open}
          // title="Forgot Password?"
          backDrop
          custSubmitBtn
          custCancelBtn
        >
          <form style={{ height: '334px' }}>
            <Grid container spacing={3} style={{ width: '385px' }}>
              <Grid style={{ padding: '13px', textAlign: 'center' }} item sm={12}>
                <Typography variant="h1">Forgot Password?</Typography>
                <p style={{ fontSize: '12px', marginTop: '7px' }}>
                  Password should have at least one uppercase, lowercase, numeric and special
                  character !
                </p>
              </Grid>
              <Grid style={{ marginTop: '-19px' }} item sm={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    label="One Time Password"
                    id="current-password"
                    name="emailPaswd"
                    fullWidth
                    variant="filled"
                    type={showEmailPaswd ? 'text' : 'password'}
                    value={formValues.emailPaswd}
                    onChange={handleFormChanges}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.rightIcon} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleEmailPaswd(!showEmailPaswd)}
                            edge="end"
                          >
                            {showEmailPaswd ? (
                              <Visibility style={{ color: 'slategray' }} />
                            ) : (
                              <VisibilityOff style={{ color: 'slategray' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    label="New Password"
                    id="new-password"
                    fullWidth
                    variant="filled"
                    name="newPasswd"
                    type={shownewPaswd ? 'text' : 'password'}
                    value={formValues.newPasswd}
                    onChange={handleFormChanges}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.rightIcon} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleNewPaswd(!shownewPaswd)}
                            edge="end"
                          >
                            {shownewPaswd ? (
                              <Visibility style={{ color: 'slategray' }} />
                            ) : (
                              <VisibilityOff style={{ color: 'slategray' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    label="Confirm Password"
                    id="confim-password"
                    fullWidth
                    variant="filled"
                    name="confiPaswd"
                    type={showconfiPaswd ? 'text' : 'password'}
                    value={formValues.confiPaswd}
                    onChange={handleFormChanges}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.rightIcon} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleConfiPaswd(!showconfiPaswd)}
                            edge="end"
                          >
                            {showconfiPaswd ? (
                              <Visibility style={{ color: 'slategray' }} />
                            ) : (
                              <VisibilityOff style={{ color: 'slategray' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid>
                {formErrors.newPasswd && (
                  <div>
                    <div style={{ color: theme.palette.error.dark }}>{formErrors.newPasswd}</div>
                  </div>
                )}
                {forgotpasswdErrMsg && (
                  <div style={{ color: theme.palette.error.dark }}>{forgotpasswdErrMsg}</div>
                )}
                {forgotpasswdSusMesg && (
                  <div style={{ color: theme.palette.success.dark }}>
                    <strong>{forgotpasswdSusMesg}</strong>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </Dialog>
      )}
    </Box>
  )
}

export default ForgotPasswordForm
