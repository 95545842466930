import React from 'react'
import { useParams } from 'react-router-dom'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { DisclosureForm } from '../../private/disclosureform/disclosureform'
import { useMandatoryInviteQuery } from '../../../graphql/hooks.generated'

interface ActivateAccountParams {
  token: string
  loc: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    invalidTokenCss: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '350px',
      fontSize: '20px'
    }
  })
)

export const StudentDisclosureForm = () => {
  const params = useParams<ActivateAccountParams>()
  let getLoc

  if (params?.loc)
    if (params.loc === '1') getLoc = 'en'
    else getLoc = 'es'

  const { data, loading, error } = useMandatoryInviteQuery({
    variables: { token: params.token }
  })
  const handleMandateFormClose = () => {
    console.log('click')
  }
  const classes = useStyles()

  return (
    <>
      {loading && <ProgressIndicator fullHeight />}
      {data && !error && (
        <DisclosureForm
          mandateformdata={data?.mandatoryInvite}
          handleMandateFormClose={handleMandateFormClose}
          mandatoryInvite
          loc={getLoc}
        />
      )}
      {error && (
        <div className={classes.invalidTokenCss}>
          This email link is no longer valid. Please check your email for a new link or contact
          &nbsp;
          <address>
            <a href="mailto:support@imattercolorado.org">support@imattercolorado.org</a>
          </address>{' '}
          &nbsp; if you have any questions.
        </div>
      )}
    </>
  )
}
