import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    minHeight: 'calc(100vh - 60px - 65px)',
    width: '100%',
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '& .MuiInputLabel-formControl': {
      transform: 'none'
    },
    '& .MuiFormLabel-root': {
      transform: 'none',
      color: '#6b6e7c'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiSelect-root': {
      border: '1px solid #E5E5E5',
      borderColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .Mui-error': {
      '& .MuiSelect-root': {
        borderColor: '#f66a53'
      }
    },
    '& textarea': {
      fontFamily: [
        'Poppins',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    }
  },
  pt20: {
    paddingTop: '16px'
  },
  inputLabel: {
    marginBottom: '10px',
    color: '#6b6e7c'
  },
  inputField: {
    '& .MuiOutlinedInput-input': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
      border: `1px solid #E5E5E5`,
      borderRadius: '3px',
      opacity: '1'
    }
  },
  ListRoot: {
    width: '100%',
    '& .MuiListItem-root': {
      background: '#F8F8F8 0% 0% no-repeat padding-box',
      marginBottom: '12px'
    },
    '& .MuiTypography-colorTextPrimary': {
      color: '#f1583a',
      fontSize: '18px'
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#6b6e7c',
      fontSize: '16px'
    },
    '& .MuiListItemSecondaryAction-root': {
      top: '25px'
    }
  },
  ListItemBottom: {
    '& .MuiListItem-root': {
      marginBottom: '5px'
    }
  },
  inline: {
    display: 'inline',
    fontWeight: 800
  },
  inlineSecondary: {
    fontWeight: 'bold',
    color: '#6b6e7c'
  },
  avatarCustom: {
    width: '80px',
    height: '80px',
    marginRight: '18px',
    marginTop: '4px'
  },
  slots: {
    marginTop: '10px',
    '& .MuiButton-outlined': {
      fontSize: '12px',
      padding: '1px 10px',
      color: '#6b6e7c',
      fontWeight: 'normal'
    }
    // marginLeft: '-100px'
  },
  customChip: {
    backgroundColor: 'white',
    border: '1px solid gray',
    height: '25px',
    margin: '5px',
    marginTop: '0px',
    '& .MuiChip-label': {
      padding: '5px 11px',
      fontSize: '12px'
    },
    '&:hover, &:focus': {
      backgroundColor: '#00de74',
      color: '#fff'
    }
  },
  slotBtnFirst: {
    width: '83px',
    color: '#4b99d1',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  selected: {
    backgroundColor: 'rgb(144, 238, 144)',
    color: '#000'
  },
  notAvailable: {
    backgroundColor: '#c8c8c8',
    border: '1px solid #707070',
    cursor: 'none',
    '&:hover, &:focus': {
      backgroundColor: '#c8c8c8'
    }
  },
  div3: {
    flex: '0 0 100%',
    '& .MuiListItem-root': {
      flexDirection: 'column'
    }
  },
  div1: {
    flex: '0 0 86%'
  },
  MuiTypographyRoot: {
    margin: '2px'
  },
  listItem: {
    flexWrap: 'wrap'
  },
  fieldInfo: {
    marginBottom: '12px',
    fontSize: '16px'
  },
  subHeader: {
    color: '#f1583a',
    fontSize: '16px'
  },
  consent: {
    marginBottom: '100px',
    marginTop: '50px',
    fontSize: '16px'
  },
  provider: {
    marginTop: '70px'
  },
  fieldInfoValue: {
    color: '#6b6e7c'
  },
  customBtnCls: {
    padding: '8px 21px',
    height: '40px'
  },
  selectCustom: {
    width: '100%'
  },
  containerWidth: {
    width: '97%'
  },
  dropDown: {
    marginTop: '10px'
  },
  formFeild: {
    marginTop: '25px'
  },
  inputPadding: {
    '& input': {
      padding: '11.5px'
    }
  },
  phoneHelperTxt: {
    '& .MuiFormHelperText-root': {
      color: ' #f56568'
    }
  },
  '.Mui-error': {
    borderColor: 'red'
  },
  radio: {
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#f1593a'
    }
  },
  registrationStep: {
    background: '#201057',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#201057',
      color: '#fff'
    }
  },
  registrationNextStep: {
    background: '#ff1c00',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff1c00',
      color: '#fff'
    }
  },
  registrationTherapistStep: {
    background: '#00de74',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00de74',
      color: '#fff'
    }
  },
  registrationWaitlistStep: {
    background: '#844191',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#844191',
      color: '#fff'
    }
  },
  registrationSubmitStep: {
    background: '#ff51a7',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff51a7',
      color: '#fff'
    }
  },
  registrationConfirmStep: {
    background: '#46a7dd',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#46a7dd',
      color: '#fff'
    }
  },
  registrationTherapistColor: {
    color: '#00de74',
    margin: '5px'
  },
  registrationSubmitColor: {
    color: '#ff51a7'
  },
  registrationNext: {
    color: '#ff1c00'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  labelTxt: {
    '@media (max-width: 550px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: '60px'
    }
  },
  texterror: {
    color: 'red'
  },
  ml20: {
    marginLeft: '20px'
  },
  mr25: {
    marginRight: '25px'
  },
  fright: {
    float: 'right'
  },
  pt15: {
    paddingTop: '15px'
  },
  waitlistroot: {
    fontSize: '15px',
    margin: 'auto',
    paddingTop: '20px',
    paddingRight: '25px'
  },
  textaream: {
    width: '100%',
    minHeight: '75px',
    fontSize: '15px'
  },
  therapistSelected: {
    backgroundColor: '#f1583a !important',
    color: '#FFFFF'
  },
  therapistCustomChip: {
    height: '25px',
    margin: '5px',
    marginTop: '0px',
    '& .MuiChip-label': {
      padding: '5px 11px',
      fontSize: '12px'
    },
    color: '#000',
    fontWeight: 600,
    '&:hover, &:focus': {
      backgroundColor: '#f1583a',
      color: '#fff'
    }
  },
  inpersonBg: {
    background: '#ADD8E6'
  },
  teleBg: {
    background: '#90EE90'
  },
  bothBg: {
    background: '#FFFF66'
  },
  slotsNotAvailable: {
    backgroundColor: '#dae7f3',
    border: '1px solid #707070',
    color: '#767676',
    '&:hover, &:focus': {
      backgroundColor: '#f1583a',
      color: '#fff'
    }
  }

  // registrationStep: {
  //   background: '#201057',
  //   color: '#fff'
  //   // '&:hover': {
  //   //   border: '1px solid #201057',
  //   //   backgroundColor: 'rgb(32 16 87 / 10%)'
  //   // },
  //   // color: '#201057',
  //   // border: '1px solid #201057'
  // },
  // registrationNextStep: {
  //   // '&:hover': {
  //   //   border: '1px solid #ff1c00',
  //   //   backgroundColor: '#ff1c001a'
  //   // },
  //   // color: '#ff1c00',
  //   // border: '1px solid #ff1c00'
  // },
  // registrationTherapistStep: {
  //   '&:hover': {
  //     border: '1px solid #00de74',
  //     backgroundColor: '#00de7412'
  //   },
  //   color: '#00de74',
  //   border: '1px solid #00de74'
  // },
  // registrationSubmitStep: {
  //   '&:hover': {
  //     border: '1px solid #ff51a7',
  //     backgroundColor: '#ff51a70f'
  //   },
  //   color: '#ff51a7',
  //   border: '1px solid #ff51a7'
  // },
  // registrationConfirmStep: {
  //   '&:hover': {
  //     border: '1px solid #46a7dd',
  //     backgroundColor: 'rgb(70 167 221 / 24%)'
  //   },
  //   color: '#46a7dd'
  //   // border: '1px solid rgb(70 167 221)'
  // }
})
