import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Filter,
  FilterSettingsModel,
  Grid,
  DetailRow
} from '@syncfusion/ej2-react-grids'
import { SplitButtonComponent } from '@syncfusion/ej2-react-splitbuttons'
import { useHistory, useRouteMatch, useParams, Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  // Grid,
  Avatar,
  DialogActions,
  InputLabel,
  MenuItem,
  MenuList,
  Select
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import moment from 'moment'
import FormControl from '@material-ui/core/FormControl'
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent, ChangeEventArgs } from '@syncfusion/ej2-react-buttons'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useOngoingcaresLazyQuery,
  useUpdateOngoingCareMutation,
  useStudentSessionCountLazyQuery,
  useAdminOngoingUsersListQuery
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'
import ActionDailog from '../dialogs/actionsDialog'

interface ListOngoingCareViewParams extends UseParamsType {
  id: string
}
// export interface ExapandRowProps {
//   name: any
//   value: any
//   type: any
//   data?: any
//   onChange?: any
//   error?: any
// }
const useStylesSync = makeStyles(() =>
  createStyles({
    syncSplitBtn: {
      '& .e-split-btn-wrapper .e-split-btn': {
        width: '65px',
        backgroundColor: 'rgb(241, 88, 58)',
        color: '#FFF',
        height: '35px',
        borderTopLeftRadius: '25px',
        borderBottomLeftRadius: '25px'
      },
      '& .e-split-btn-wrapper .e-dropdown-btn': {
        backgroundColor: 'rgb(241, 88, 58)',
        padding: '0px 10px',
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        borderLeft: '1px solid #FFF'
      },
      '& .e-split-btn-wrapper .e-dropdown-btn .e-btn-icon': {
        color: '#FFF'
      }
    }
  })
)

export const OngoingCareListView: React.FC = () => {
  // const uploadObj = useRef<UploaderComponent>(null)
  // const preventDefault = (event) => event.preventDefault()
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: districtId } = useParams<ListOngoingCareViewParams>()
  const classes = useStyles()
  const therapistFields = { text: 'name', value: 'id' }
  // const { data, loading, refetch } = useOngoingcaresQuery({
  //   fetchPolicy: 'network-only'
  // })
  const { data: adminList } = useAdminOngoingUsersListQuery({
    fetchPolicy: 'network-only'
  })
  const classessync = useStylesSync()
  const options = [{ text: 'Assign' }, { text: 'In Progress' }, { text: 'Completed' }]
  const [updateOngoingCare, { loading: updateOngoingLoading }] = useUpdateOngoingCareMutation()
  const [listOngoinCareQuery, { data: dataList, loading }] = useOngoingcaresLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [fileData, setFileData] = useState<any>()
  const [ongoingId, setOngoingId] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const [dataLoading, setDataLoading] = useState(false)
  const [adminData, setAdminData] = useState<any>([])
  const [adminValue, setAdminValue] = useState<any>('all')
  const [data, setData] = useState<any>([])
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const fetch = useMemo(
    () => (assigned_to) =>
      listOngoinCareQuery({
        variables: { assigned_to }
      }),
    [listOngoinCareQuery]
  )
  useEffect(() => {
    fetch(adminValue === 'all' ? '' : adminValue === 'null' ? null : adminValue)
  }, [fetch, adminValue])
  useEffect(() => {
    if (data && filterData === undefined) {
      const filteredDataTest =
        data &&
        data?.ongoingcares?.filter((obj) => {
          // return obj.id !== null
          let tmpObj = {}

          if (obj.id !== null)
            tmpObj = {
              ...obj,
              name: `${obj?.student_id?.first_name} ${obj?.student_id?.last_name}`,
              contact_email: obj?.student_id?.contact_email
            }

          return tmpObj
        })
      setFilterData(filteredDataTest)
    }
  }, [data, filterData])
  useEffect(() => {
    if (dataList?.ongoingcares) setData(dataList)
  }, [dataList])
  useEffect(() => {
    if (adminList?.adminUsersList) setAdminData(adminList?.adminUsersList)
  }, [adminList])
  // }, [data, filterData])
  // console.log('student---data--', data)
  // const [studentsData, setData] = useState<any>()
  const isLoading = () =>
    loading || updateOngoingLoading || dataLoading ? <ProgressIndicator fullHeight /> : ''
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.ongngcarelistq?.columnName
          ? sfStoreState?.ongngcarelistq?.columnName
          : 'service_requested',
        direction: sfStoreState?.ongngcarelistq?.columnDirection
          ? sfStoreState?.ongngcarelistq?.columnDirection
          : 'Descending'
      }
      // { field: 'student_id.first_name', direction: 'Ascending' },
      // { field: 'student_id.contact_email', direction: 'Ascending' },
      // { field: 'user_id.first_name', direction: 'Ascending' },
      // { field: 'student_id.unique_profile_id', direction: 'Ascending' },
      // { field: 'student_id.insurance_company.name', direction: 'Ascending' },
      // { field: 'further_session', direction: 'Ascending' },
      // { field: 'ongoing_care', direction: 'Ascending' },
      // { field: 'status', direction: 'Ascending' }
    ]
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: [
      'user_id.first_name',
      'user_id.last_name',
      'student_id.birth_date',
      'student_id.gender',
      'Parent_name',
      'student_id.contact_email',
      'student_id.contact_phone_number',
      'student_id.first_name',
      'service_requested',
      'student_id.unique_profile_id',
      'student_id.insurance_company.name',
      'further_session',
      'ongoing_care',
      'status'
    ],
    key: sfStoreState?.ongngcarelistq?.searchText,
    ignoreCase: true
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.ongngcarelistq?.pageSize ? sfStoreState?.ongngcarelistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.ongngcarelistq?.currentPage
      ? sfStoreState?.ongngcarelistq?.currentPage
      : 1
  }
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogAction, setDialogAction] = useState('')
  const [dialogData, setDialogData] = useState({})
  const handleOpenDialog = (action, value) => {
    setOpenDialog(true)
    setDialogAction(action.item.text)
    setOngoingId(value?.id)
    setDialogData(value)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleUpdate = async () => {
    // await updateOngoingCare({ variables: { id: ongoingId } })
    setOpenDialog(false)
    setDataLoading(true)
    // await refetch()
    setDataLoading(false)
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            ongngcarelistq: { ...sfStoreState.ongngcarelistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              ongngcarelistq: { ...sfStoreState.ongngcarelistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          ongngcarelistq: {
            ...sfStoreState.ongngcarelistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          ongngcarelistq: {
            ...sfStoreState.ongngcarelistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }
  const handleCancel = (value) => {
    setOpenDialog(false)

    if (value === 'refech')
      fetch(adminValue === 'all' ? '' : adminValue === 'null' ? null : adminValue)
  }
  const expandRow = (furtherSesion, ongoingReason, student_id) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Typography style={{ width: '350px' }}>Contact Details</Typography>
          <Typography>:</Typography>
          <Typography style={{ paddingLeft: '10px' }}>
            {student_id?.contact_email || '-'}, {student_id?.contact_phone_number || '-'}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ width: '350px' }}>Further Session Notes</Typography>
          <Typography>:</Typography>
          <Typography style={{ paddingLeft: '10px' }}>{furtherSesion || '-'}</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ width: '350px' }}>
            Request Client Resources/Referral Notes
          </Typography>
          <Typography>:</Typography>
          <Typography style={{ paddingLeft: '10px' }}>{ongoingReason || '-'}</Typography>
        </div>
      </>
    )
  }

  return (
    <>
      <NavBar title="Clients" goBackBtn />
      <PageContainer>
        <Box padding="10px" display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h3"
              style={{
                color: theme.palette.info.main,
                fontFamily: 'Roboto'
              }}
            >
              Clients
            </Typography>
          </Box>
        </Box>
        <div className="control-pane">
          <div className="control-section">
            {/* {data && ( */}
            <GridComponent
              key={Math.floor(Math.random() * 100)}
              allowTextWrap
              width="auto"
              toolbar={toolbarOptions}
              allowPaging
              pageSettings={pageOptions}
              allowSorting
              sortSettings={sortSettings}
              dataSource={data?.ongoingcares || []}
              // filterSettings={filterOption}
              // allowFiltering
              searchSettings={searchOptions}
              className={classes.headerCls}
              ref={(g) => gridref(g)}
              created={createdevent}
              actionComplete={(value) => handleActionBegin(value)}
              detailTemplate={(value) =>
                expandRow(value.further_session_reason, value.ongoing_care_reason, value.student_id)
              }
            >
              <ColumnsDirective>
                {/* <ColumnDirective
                  template={() => (
                    <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                  )}
                  width="50"
                /> */}
                <ColumnDirective
                  field="student_id.first_name"
                  template={(names) => {
                    // console.log('ssssssssss', names)
                    return (
                      <>
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={`/studentsinfo/${btoa(
                            `${names?.student_id?.id}_${names?.student_id?.id}`
                          )}`}
                        >
                          <div className={classes.nameCls} style={{ color: 'black' }}>
                            {names?.student_id?.first_name} {names?.student_id?.last_name} (
                            {names?.student_id?.unique_profile_id})
                          </div>
                        </Link>
                        <div className={classes.nameCls} style={{ color: 'black' }}>
                          {names?.student_id?.gender}, Age{' '}
                          {moment(names?.student_id?.birth_date, 'YYYYMMDD')
                            .fromNow()
                            .split('years ago')}
                        </div>
                      </>
                    )
                  }}
                  headerText="CLIENT NAME"
                  width="110"
                  filter={{ type: 'CheckBox' }}
                  allowFiltering={false}
                />
                {/* <ColumnDirective
                  field="student_id.unique_profile_id"
                  template={(client) => (
                    <>
                      <div>{client?.student_id?.unique_profile_id}</div>
                    </>
                  )}
                  headerText="CLIENT ID"
                  width="80"
                  allowFiltering={false}
                /> */}
                {/* <ColumnDirective
                    field="contact_email"
                    headerText="CONTACT EMAIL"
                    width="140"
                    allowFiltering={false}
                  /> */}
                {/* <ColumnDirective
                  field="student_id.contact_email"
                  template={(mail) => (
                    <>
                      <div>{mail?.student_id?.contact_email}</div>
                      <div>{mail?.student_id?.contact_phone_number}</div>
                      {/* <div>{mail?.parent1_phone_number}</div> */}
                {/* </> */}
                {/* )}
                  headerText="CONTACT DETAILS"
                  width="130"
                  allowFiltering={false}
                /> */}
                <ColumnDirective
                  field="user_id.first_name"
                  headerText="PROVIDER NAME"
                  template={(mail) => (
                    <>
                      <div>
                        {mail?.user_id?.first_name} {mail?.user_id?.last_name}
                      </div>
                    </>
                  )}
                  width="100"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="service_requested"
                  template={(care) => (
                    <>
                      <div>
                        {moment.tz(care?.service_requested, 'America/Denver').format('MM-DD-YYYY')}
                      </div>
                    </>
                  )}
                  headerText="DATE CLIENT RECORD WAS LAST UPDATED"
                  width="80"
                  allowFiltering={false}
                />
                {/* <ColumnDirective
                    field="parent1_email"
                    template={(mail) => ( */}
                <>
                  {/* <div>{mail?.parent1_email}</div>
                        <div>{mail?.parent1_phone_number}</div> */}
                  {/* <div>{mail?.parent1_phone_number}</div> */}
                </>
                {/* )}
                    headerText="PARENT EMAIL"
                    width="140"
                    allowFiltering={false}
                  /> */}
                <ColumnDirective
                  field="student_id.insurance_company.name"
                  template={(ins) => (
                    <>
                      <div>{ins?.student_id?.insurance_company?.name}</div>
                    </>
                  )}
                  headerText="HEALTH INSURANCE"
                  width="100"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="further_session"
                  template={(care) => (
                    <>
                      <div>{care?.further_session ? 'Yes' : ''}</div>
                    </>
                  )}
                  headerText="FURTHER SESSIONS"
                  width="80"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="ongoing_care"
                  template={(care) => (
                    <>
                      <div>{care?.ongoing_care ? 'Yes' : ''}</div>
                    </>
                  )}
                  headerText="REQUEST CLIENT RESOURCES /REFERRAL"
                  width="80"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="assigned_to.name"
                  template={(ins) => (
                    <>
                      <div>{ins?.assigned_to?.name}</div>
                    </>
                  )}
                  headerText="ASSIGNED TO"
                  width="90"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="status"
                  template={(care) => (
                    <>
                      <div>
                        {care?.assigned_date
                          ? moment.tz(care?.assigned_date, 'America/Denver').format('MM-DD-YYYY')
                          : '-'}
                      </div>
                    </>
                  )}
                  headerText="ASSIGNED DATE"
                  width="90"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field="status"
                  template={(care) => (
                    <>
                      <div>
                        {care?.action_id?.id === 1
                          ? 'Incomplete'
                          : care?.action_id?.id === 2
                          ? 'In progress'
                          : 'Unassigned'}
                      </div>
                    </>
                  )}
                  headerText="STATUS"
                  width="90"
                  allowFiltering={false}
                />
                <ColumnDirective
                  field=""
                  headerText="ACTIONS"
                  template={(care) => (
                    <>
                      <div
                        key={Math.floor(Math.random() * 100)}
                        className={classessync.syncSplitBtn}
                        style={{ fontSize: '12px' }}
                      >
                        <SplitButtonComponent
                          items={options}
                          select={(value) => handleOpenDialog(value, care)}
                          // select={(val) => handleSplitBtnChange(val, need)}
                        >
                          Actions
                        </SplitButtonComponent>
                        {/* <Button
                            color="primary"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className="mar-rt-15 "
                            variant="contained"
                            size="small"
                            style={{
                              height: '36px',
                              // marginTop: '13px',
                              fontSize: '10px',
                              width: '66px'
                            }}
                            onClick={(value) => handleOpenDialog(care)}
                          >
                            Complete
                          </Button> */}
                      </div>
                    </>
                  )}
                  width="110"
                  allowFiltering={false}
                />
              </ColumnsDirective>
              <Inject services={[Search, Toolbar, Sort, Page, DetailRow]} />
              <div style={{ display: 'flex' }}>
                <div className="totalStudents" style={{ borderRight: '2px solid #7f7f7f' }}>
                  <span className="totalText">Total Clients</span>
                  <span style={{ fontSize: '24px' }}>{data?.ongoingcares?.length}</span>
                </div>
                {adminList && (
                  <div className={classes.selectBoxStyles} style={{ right: '40%' }}>
                    <InputLabel
                      style={{ width: '115px', fontWeight: 'bold', color: '#606060' }}
                      id="demo-simple-select-label"
                    >
                      Assigned To:
                    </InputLabel>
                    <Select
                      style={{ width: '160px' }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={adminValue}
                      // label="assignto"
                      variant="outlined"
                      onChange={(e) => setAdminValue(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="null">Unassigned</MenuItem>
                      {adminList?.adminUsersList?.map((user) => (
                        <MenuItem key={user.id} value={user?.id?.toString()}>
                          {user?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                {/* <div style={{ width: '200px', marginLeft: '200px' }}>
                  <DropDownListComponent
                    // id={name}
                    //   openOnClick
                    placeholder="Select Assignee"
                    data-name="EventTherapist"
                    className="e-field"
                    fields={therapistFields}
                    dataSource={[...adminData, { name: 'Unassigned', id: 'Unassigned' }] || []}
                    value={adminValue}
                    change={(value) => setAdminValue(value?.value || null)}
                  />
                </div> */}
              </div>
              <Dialog
                open={openDialog}
                maxWidth="md"
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  {/* <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to Complete?
            </DialogContentText> */}
                  <ActionDailog
                    action={dialogAction}
                    data={dialogData}
                    cancel={(value) => handleCancel(value)}
                  />
                </DialogContent>
                {/* <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Yes
            </Button>
          </DialogActions> */}
              </Dialog>
            </GridComponent>
            {/* )} */}
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
