import React from 'react'
import {
  useListInstantMessagingLazyQuery,
  useStartInstantMessagingMutation,
  ChatCredentials,
  User
} from 'graphql/hooks.generated'

interface CredentialsProps {
  chatCredentials: ChatCredentials | null
  getUsers: () => User[]
  [key: string]: unknown
}

export const withDM = (Component) => (props: CredentialsProps) => {
  const { chatCredentials, getUsers } = props
  const [
    executeDMListQuery,
    { data: DMListData, loading: DMListLoading }
  ] = useListInstantMessagingLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeStartInstantMessagingMutation,
    { loading: startDMLoading }
  ] = useStartInstantMessagingMutation()
  React.useEffect(() => {
    if (chatCredentials && !DMListData && !DMListLoading)
      executeDMListQuery({
        variables: { chatAuthToken: chatCredentials.authToken }
      })
  }, [chatCredentials, DMListData, DMListLoading, executeDMListQuery])
  const startDirectMessaging = async (email: string) => {
    if (chatCredentials?.authToken && !startDMLoading)
      await executeStartInstantMessagingMutation({
        variables: { chatAuthToken: chatCredentials.authToken, email }
      })
  }
  const getDMUsers = () => {
    return getUsers().filter(({ id }) => {
      return !DMListData?.listInstantMessaging?.some(
        ({ user: { id: existingId } }) => id === existingId
      )
    })
  }

  return (
    <Component getDMUsers={getDMUsers} startDirectMessaging={startDirectMessaging} {...props} />
  )
}

export default withDM
