import React, { useState } from 'react'
import {
  Grid,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { User } from 'graphql/hooks.generated'
import useStyles from './Styles'
import Dialog from '../../panel/dialog/Dialog'

interface ChatDialogProps {
  handleSubmit: (name: string, emails: string[]) => Promise<unknown>
  open: boolean
  users: User[]
  setOpen: (pld: boolean) => unknown
}

export const ChatDialog: React.FC<ChatDialogProps> = ({
  handleSubmit,
  users,
  open,
  setOpen
}: ChatDialogProps) => {
  const [chatName, setChatName] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const classes = useStyles()
  const closeDialog = () => {
    setSelectedUsers([])
    setChatName('')
    setOpen(false)
  }
  const submitDialog = () => {
    const payloadUsers: User[] | undefined = users.filter(({ id }) => selectedUsers.includes(id))

    if (payloadUsers.length && chatName.length)
      handleSubmit(
        chatName,
        payloadUsers.map(({ email }) => email)
      )

    closeDialog()
  }
  const chooseUser = (e) => {
    if (e.target.checked) setSelectedUsers([...selectedUsers, e.target.value])
    else setSelectedUsers(selectedUsers.filter((id) => id !== e.target.value))
  }

  return (
    <Dialog
      handleClose={closeDialog}
      handleSubmit={submitDialog}
      disabled={!selectedUsers.length || !chatName.length || chatName.includes(' ')}
      cancel="Cancel"
      submit="Create"
      open={open}
      title="Choose users to create chat with"
    >
      <Grid item sm={12}>
        <TextField
          variant="filled"
          value={chatName}
          onChange={(e) => setChatName(e.target.value)}
          fullWidth
          name="chatName"
          label="Chat name (no spaces)"
        />
      </Grid>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} />
            <Checkbox
              onChange={chooseUser}
              value={user.id}
              checked={selectedUsers.includes(user.id)}
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default ChatDialog
