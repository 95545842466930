import React, { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Footer from '../../panel/footer/Footer'
import { PageContainer } from '../../panel/page-container/PageContainer'
import AppBar from '../../panel/app-bar/AppBar'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import ConfirmImg from '../../../../assets/illustrations/confirm.jpg'

export const Confirm = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { url } = useRouteMatch()
  const history = useHistory()
  const studentName = sessionStorage.getItem('s_name')
  const params = new URLSearchParams(window.location.search)
  let waitlistflow = false

  if (params.get('wl') === 'true') waitlistflow = true

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className={classes.root}
      style={{
        background: '#d6f1ff',
        marginTop: '-30px',
        marginBottom: '-30px',
        boxShadow: 'none'
      }}
    >
      <PageContainer>
        <Grid
          container
          direction="row"
          style={{
            boxShadow: 'none'
          }}
        >
          <Grid item md={12} sm={12} style={{ padding: '20px', color: '#315089' }}>
            <Grid>
              {!waitlistflow && (
                <Grid item md={12} sm={12}>
                  <h2 style={{ fontSize: '24px' }}>
                    {t('sessionconfirmation.hi')} {studentName}, {t('sessionconfirmation.note1')}
                  </h2>
                  <Box mt={2}>
                    <Typography style={{ marginTop: '20px' }}>
                      {t('sessionconfirmation.noteconf')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }}>
                      {t('sessionconfirmation.noteconf2')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }}>
                      {t('sessionconfirmation.noteconf3')}
                      <span> {t('sessionconfirmation.mail1')}.</span>{' '}
                      <span>{t('sessionconfirmation.note2')}</span>
                      <span>
                        {' '}
                        <a
                          style={{ textDecoration: 'none', color: '#0052cc' }}
                          href="mailto: lifelinecontactus@mhaofnyc.org."
                        >
                          {t('sessionconfirmation.mail1')}.
                        </a>
                      </span>{' '}
                      <span>{t('sessionconfirmation.note2a')}</span>
                      <span>
                        {' '}
                        <a
                          style={{ textDecoration: 'none', color: '#0052cc' }}
                          href="mailto: lifelinecontactus@mhaofnyc.org."
                        >
                          {t('sessionconfirmation.mail2')}
                        </a>
                      </span>{' '}
                      <span>{t('sessionconfirmation.note2b')}</span>
                    </Typography>
                    <Typography style={{ marginTop: '20px' }}>
                      <b>{t('sessionconfirmation.ccsT1')}</b>
                      {', '}
                      {t('sessionconfirmation.ccsT2')}{' '}
                      <a
                        href="https://teleteachers.com/behavioural-health-platform.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT3')}
                      </a>{' '}
                      {t('sessionconfirmation.ccsT4')}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {waitlistflow && (
                <Grid item md={12} sm={12}>
                  <h2 style={{ fontSize: '24px' }}>
                    {t('sessionconfirmation.waitlist1')} {studentName},
                  </h2>
                  <Box mt={2}>
                    <Typography style={{ marginTop: '20px' }}>
                      {t('sessionconfirmation.waitlist2')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }}>
                      {t('sessionconfirmation.waitlist3')} {t('sessionconfirmation.ccsT1')}
                      {', '}
                      {t('sessionconfirmation.ccsT2')}{' '}
                      <a
                        href="https://teleteachers.com/behavioural-health-platform.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT3')}
                      </a>{' '}
                      {t('sessionconfirmation.ccsT4')}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  )
}
