import React, { useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextareaAutosize
} from '@material-ui/core'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useActivityfeedLazyQuery, useCurrentUserQuery } from 'graphql/hooks.generated'
import moment from 'moment'
import { ReactComponent as AttachIcon } from '../../../assets/icons/attach.svg'
import useStyles from './Styles'

const top100Films = [
  { title: 'Amanda Dunkin', year: 1994 },
  { title: 'Penny Press', year: 1972 },
  { title: 'Logan Wilson', year: 1974 },
  { title: 'Isaac clark', year: 2008 },
  { title: 'fred Williams', year: 1957 }
]

export default function Activity() {
  const classes = useStyles()
  const [sdate, setSdate] = useState(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
  const [edate, setEdate] = useState(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
  const [filterval, setfilterval] = useState('Today')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [activityData, setActivityData] = useState<any>(null)
  const [
    activityquery,
    { data: acticitydata, loading: activityloading }
  ] = useActivityfeedLazyQuery({
    variables: {
      roles: 'school_therapist',
      users: currentUserdata?.data?.currentUser?.id,
      from_date: sdate,
      to_date: edate
    },
    fetchPolicy: 'network-only'
  })
  const fetchactivity = useMemo(() => () => activityquery(), [activityquery])
  useEffect(() => {
    if (acticitydata?.Activityfeed) setActivityData(acticitydata)
  }, [acticitydata])
  useEffect(() => {
    if (currentUserdata) fetchactivity()
  }, [currentUserdata, fetchactivity, sdate])
  useEffect(() => {
    if (currentUserdata?.data)
      setInterval(() => {
        fetchactivity()
      }, 60000)
  }, [currentUserdata, fetchactivity])
  const changePeriod = (e) => {
    setfilterval(e.target.value)

    if (e.target.value === 'Today')
      setSdate(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
    if (e.target.value === 'L7days')
      setSdate(moment.tz(new Date(), 'US/Mountain').subtract(7, 'days').format('YYYY-MM-DD'))
    if (e.target.value === 'L30days')
      setSdate(moment.tz(new Date(), 'US/Mountain').subtract(30, 'days').format('YYYY-MM-DD'))
  }
  const stylecls = `.actFeed a {
    text-decoration: none
  }`

  return (
    <>
      <style>{stylecls}</style>
      <Paper className={classes.paperNewStyle}>
        <Grid container alignItems="center">
          <Grid item xs={7} className={classes.act}>
            <span>Activity Feed</span>
          </Grid>
          <Grid item xs={5} className={classes.selectCls}>
            <FormControl size="medium" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                defaultValue={filterval}
                value={filterval}
                className={classes.tdd}
                onChange={(e) => changePeriod(e)}
              >
                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="L7days">Last 7 days</MenuItem>
                <MenuItem value="L30days">Last 30 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <TableContainer component={Paper} className={classes.papercalender}>
        <Table className={classes.listTable} aria-label="simple table">
          <TableBody>
            <div
              className={`${
                acticitydata && !activityData?.Activityfeed?.length ? classes.feedsec : ''
              }`}
              // style={{ height: '527px' }}
            >
              {acticitydata &&
                acticitydata?.Activityfeed &&
                acticitydata?.Activityfeed?.length > 0 &&
                acticitydata.Activityfeed?.map((item) => (
                  <div
                    className="actFeed"
                    style={{ paddingTop: '10px' }}
                    dangerouslySetInnerHTML={{ __html: `${item?.notes} ${item?.hours}` }}
                  />
                ))}
              {activityloading && <ProgressIndicator position="relative" />}
              {acticitydata && !acticitydata?.Activityfeed?.length && (
                <div style={{ display: 'flex', alignItems: 'center' }}>No data found</div>
              )}
            </div>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
