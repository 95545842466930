import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Grid,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  DetailRow,
  GridModel,
  SortSettingsModel,
  Filter,
  FilterSettingsModel
} from '@syncfusion/ej2-react-grids'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SplitButtonComponent } from '@syncfusion/ej2-react-splitbuttons'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  Avatar,
  DialogActions,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Select,
  InputLabel
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useHistory, useRouteMatch, useParams, Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import FormControl from '@material-ui/core/FormControl'
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent, ChangeEventArgs, ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useAddClientFromWaitlistMutation,
  useCurrentUserQuery,
  useRemoveClientFromWaitlistMutation,
  useRemoveFromWaitlistMutation,
  useStudentSessionCountLazyQuery,
  useWaitlistLazyQuery,
  useAdminUsersListQuery
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'
import ScheduleUpdateAddDialog from '../../schedule/update'
import { NewScheduleUpdate } from '../../schedule/newUpdate'
import { WaitlistLog } from '../waitlistlog/waitlistlog'
import { Outreach } from '../outreach/Outreach'
import { AssignForm } from '../assign/Assign'

interface WaitlistViewParams extends UseParamsType {
  id: string
}

const options = [
  { text: 'View Log' },
  { text: 'Assign' },
  { text: 'Outreach' },
  { text: 'Schedule Appt' },
  { text: 'Deactivate' }
]
const inactiveActions = [{ text: 'View Log' }, { text: 'Add Client' }]
const useStylesSync = makeStyles(() =>
  createStyles({
    syncSplitBtn: {
      '& .e-split-btn-wrapper .e-split-btn': {
        width: '100px',
        backgroundColor: 'rgb(241, 88, 58)',
        color: '#FFF',
        height: '40px',
        borderTopLeftRadius: '25px',
        borderBottomLeftRadius: '25px'
      },
      '& .e-split-btn-wrapper .e-dropdown-btn': {
        backgroundColor: 'rgb(241, 88, 58)',
        padding: '0px 15px',
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        borderLeft: '1px solid #FFF'
      },
      '& .e-split-btn-wrapper .e-dropdown-btn .e-btn-icon': {
        color: '#FFF'
      }
    }
  })
)

export const WaitlistListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: districtId } = useParams<WaitlistViewParams>()
  const classes = useStyles()
  const classessync = useStylesSync()
  const [removeStudent, { loading: removeLoading }] = useRemoveClientFromWaitlistMutation()
  const [addClient, { loading: addLoading }] = useAddClientFromWaitlistMutation()
  // const [status, setStatus] = useState<any>()
  const [fileData, setFileData] = useState<any>()
  const [filterData, setFilterData] = useState<any>()
  const [openDialog, setOpenDialog] = useState(false)
  const [removeStdId, setRemoveStdId] = useState()
  const [changeStatusList, setChangeStatusList] = useState(false)
  const [userid, setuserid] = useState(null)
  const [openOutreach, setOpenOutreach] = useState(false)
  const [openAssign, setOpenAssign] = useState(false)
  const [waitlogopen, setwaitlogopen] = useState(false)
  const [waitlistobj, setwaitlistobj] = useState<any[]>([])
  const [totalStatusCount, setTotalStatusCount] = useState<number | undefined>(0)
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const [waitlistquery, { data, loading }] = useWaitlistLazyQuery({
    fetchPolicy: 'network-only'
  })
  const { data: adminList, loading: adminlistloading } = useAdminUsersListQuery({
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    waitlistquery({
      variables: {
        user_id: userid
      }
    })
  }, [userid, waitlistquery])
  useEffect(() => {
    const countOfActive = data?.waitlist?.filter((v) => {
      return v?.student_id?.status === 'ACTIVE'
    })
    setTotalStatusCount(countOfActive?.length)
  }, [data])
  useEffect(() => {
    // if (data && filterData === undefined) {
    const filteredDataTest =
      data?.waitlist &&
      data?.waitlist?.map((obj: any) => {
        let tmpObj = {}
        const speciatlities =
          obj?.student_id?.student_specialities &&
          obj?.student_id?.student_specialities
            .map((itm) => {
              return itm.speciality_id.name
            })
            .join(', ')

        if (obj.id !== null)
          tmpObj = {
            ...obj,
            name: `${obj?.student_id?.first_name} ${obj?.student_id?.last_name}`,
            contact_email: obj?.student_id?.contact_email,
            status: obj?.student_id?.status,
            speciatlities
          }

        return tmpObj
      })
    setFilterData(filteredDataTest)
    // }
  }, [data])
  const [status, setStatus] = useState<any>()
  const changeStatusOfList = (e) => {
    setChangeStatusList(e.checked)
    setStatus(e.checked)
  }
  const [studentsData, setData] = useState<any>()
  const isLoading = () =>
    loading || removeLoading || addLoading || adminlistloading ? (
      <ProgressIndicator fullHeight />
    ) : (
      ''
    )
  const [hideDialog, setHideDialog] = useState(false)
  const [cellData, setCellData] = useState<any>()
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.waitlistq?.columnName ? sfStoreState?.waitlistq?.columnName : 'status',
        direction: sfStoreState?.waitlistq?.columnDirection
          ? sfStoreState?.waitlistq?.columnDirection
          : 'Descending'
      }
      // { field: 'last_name', direction: 'Ascending' },
      // { field: 'Age', direction: 'Ascending' },
      // { field: 'Gender', direction: 'Ascending' },
      // { field: 'Contact email', direction: 'Ascending' },
      // { field: 'contact phone number', direction: 'Ascending' },
      // { field: 'Date', direction: 'Ascending' },
      // { field: 'Psc/craft', direction: 'Ascending' },
      // { field: 'Treament need', direction: 'Ascending' }
    ]
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: [
      'name',
      'student_id.birth_date',
      'contact_email',
      'student_id.psc_score',
      'created_at',
      'updated_at',
      'speciatlities',
      'student_id.insurance_company.name',
      'status'
    ],
    key: sfStoreState?.waitlistq?.searchText,
    ignoreCase: true
  }
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel',
    columns: [
      {
        field: 'status',
        matchCase: false,
        operator: 'startswith',
        predicate: 'and',
        value: changeStatusList ? 'inactive' : 'active'
      }
    ]
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.waitlistq?.pageSize ? sfStoreState?.waitlistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.waitlistq?.currentPage ? sfStoreState?.waitlistq?.currentPage : 1
  }
  const collapseHandler = () => {
    if (grid) grid.detailRowModule.collapseAll()
  }
  const expandHandler = () => {
    if (grid) grid.detailRowModule.expandAll()
  }
  const childGridOptions: GridModel = {
    columns: [
      {
        field: 'availability_notes',
        headerText: 'Availability notes',
        textAlign: 'Center',
        width: 100
      },
      { field: 'preference_notes', headerText: 'Preference notes', textAlign: 'Center', width: 100 }
    ]
  }
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const CellEventClick = (waitlistData) => {
    const cellDataObject = {
      Subject: `${waitlistData?.student_id?.first_name} ${waitlistData?.student_id?.last_name}`,
      appointment_type_id: { id: '1' },
      action: 'waitList',
      change: !waitlistData?.action,
      note: '',
      EventStudents: [
        {
          name: `${waitlistData?.student_id?.first_name} ${waitlistData?.student_id?.last_name}`,
          id: waitlistData?.student_id?.id
        }
      ],
      waitlist: true
    }
    setCellData(cellDataObject)
    // if (!props.match.params.userId && !args.event.isreccuring) {
    setHideDialog(true)
    // setRecurConfirm(true)
    // } else if (!props.match.params.userId && args.event.isreccuring) {
    //   setRecurConfirm(true)
    // } else {
    //   setHideDialog(false)
    // }
  }
  const waitlistOutreach = (dataobj) => {
    setOpenOutreach(true)
    setwaitlistobj([dataobj])
  }
  const handleclose = () => {
    setOpenOutreach(false)
    setwaitlistobj([])
  }
  const waitlistAssign = (dataobj) => {
    setOpenAssign(true)
    setwaitlistobj([dataobj])
  }
  const handleAssignclose = () => {
    setOpenAssign(false)
  }
  const eventDialogClose = async (action) => {
    await waitlistquery({
      variables: {
        user_id: userid
      }
    })
    setHideDialog(false)
    setCellData(null)
  }
  const handleOpenDialog = (value) => {
    setOpenDialog(true)
    setRemoveStdId(value?.student_id?.id)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleRemoveFromWaitlist = async () => {
    await removeStudent({
      variables: {
        student_id: Number(removeStdId),
        user_id: currentUserdata?.data?.currentUser?.id
      }
    })
    setOpenDialog(false)
    await waitlistquery({
      variables: {
        user_id: userid
      }
    })
  }
  const handleAddedWaitlist = async () => {
    await addClient({
      variables: {
        student_id: Number(removeStdId),
        user_id: currentUserdata?.data?.currentUser?.id
      }
    })
    setOpenDialog(false)
    await waitlistquery({
      variables: {
        user_id: userid
      }
    })
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            waitlistq: { ...sfStoreState.waitlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              waitlistq: { ...sfStoreState.waitlistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          waitlistq: {
            ...sfStoreState.waitlistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          waitlistq: {
            ...sfStoreState.waitlistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }
  const eventDialogHeader = () => {
    const headerText = 'Waitlist Assingment'

    return (
      <div>
        <span style={{ color: 'white' }}>{headerText}</span>
      </div>
    )
  }
  const eventDialogContent = () => {
    return (
      <div>
        {/* <span style={{color: '#000'}} >content</span> */}
        <ScheduleUpdateAddDialog
          cellData={cellData}
          onChange={(value) => eventDialogClose(value)}
          admin
          currentTime={moment(new Date()).format('HH:mm:ss')}
          scheduleAction="waitList"
          appointmentListData={null}
          repeat={false}
        />
      </div>
    )
  }
  const handleSplitBtnChange = (event, need) => {
    if (event?.item?.text === 'View Log') {
      setwaitlogopen(true)
      setwaitlistobj([need])
    }
    if (event?.item?.text === 'Assign') waitlistAssign(need)
    if (event?.item?.text === 'Outreach') waitlistOutreach(need)
    if (event?.item?.text === 'Schedule Appt') CellEventClick(need)
    if (event?.item?.text === 'Deactivate') handleOpenDialog(need)
  }
  const handleInactiveSplitBtnChange = (event, need) => {
    if (event?.item?.text === 'View Log') {
      setwaitlogopen(true)
      setwaitlistobj([need])
    }
    if (event?.item?.text === 'Add Client') handleOpenDialog(need)
  }
  const waitlogclose = () => {
    setwaitlogopen(false)
    setwaitlistobj([])
  }
  const gridTemplate = (props) => {
    const deatilData = props

    return (
      <table className="detailtable" style={{ width: '100%' }}>
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '35%' }} />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <div>
                <span style={{ fontWeight: 500, padding: '10px 30px' }}>Availability notes: </span>{' '}
                {/* <p>{`${deatilData?.availability_notes}`}</p> */}
              </div>
            </td>
            <td>
              {/* <span style={{ fontWeight: 500, padding: '10px 30px' }}>Preference notes: </span>{' '} */}
              <p style={{ whiteSpace: 'normal' }}>{deatilData?.availability_notes}</p>
            </td>
            <td>
              <div>
                <span style={{ fontWeight: 500, padding: '10px 30px' }}>Preference notes: </span>{' '}
                {/* <p>{`${deatilData?.availability_notes}`}</p> */}
              </div>
            </td>
            <td>
              {/* <span style={{ fontWeight: 500, padding: '10px 30px' }}>Preference notes: </span>{' '} */}
              <p style={{ whiteSpace: 'normal' }}>{deatilData?.preference_notes}</p>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
  const assigntochange = (e) => {
    // console.log(e)
    if (e.target.value === 'null') setuserid(null)
    else setuserid(e.target.value)
  }

  return (
    <>
      {!hideDialog && (
        <>
          <NavBar title="Students" goBackBtn />
          <PageContainer>
            <>
              <Box padding="10px" display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h3"
                    style={{
                      color: theme.palette.info.main,
                      fontFamily: 'Roboto'
                    }}
                  >
                    Clients
                  </Typography>
                </Box>
              </Box>
              <div className="control-pane">
                <div className={classes.controlSection}>
                  {data && !hideDialog && (
                    <GridComponent
                      key={Math.floor(Math.random() * 100)}
                      allowTextWrap
                      width="auto"
                      toolbar={toolbarOptions}
                      allowPaging
                      pageSettings={pageOptions}
                      dataSource={filterData || []}
                      filterSettings={filterOption}
                      childGrid={childGridOptions}
                      searchSettings={searchOptions}
                      allowFiltering
                      allowSorting
                      sortSettings={sortSettings}
                      className={classes.headerCls}
                      detailTemplate={(value) => gridTemplate(value)}
                      ref={(g) => gridref(g)}
                      created={createdevent}
                      actionComplete={(value) => handleActionBegin(value)}
                    >
                      <ColumnsDirective>
                        {/* <ColumnDirective
                          // template={() => (
                          //   <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                          // )}
                          // width="40"
                          width="20"
                        /> */}
                        <ColumnDirective
                          field="name"
                          template={(names) => {
                            return (
                              <>
                                <Link
                                  color="textPrimary"
                                  component={RouterLink}
                                  to={`/studentsinfo/${btoa(
                                    `${names?.student_id?.id}_${names?.student_id?.id}`
                                  )}`}
                                >
                                  <div className={classes.nameCls} style={{ color: 'black' }}>
                                    {names?.student_id?.first_name} {names?.student_id?.last_name}
                                  </div>
                                </Link>
                                <div className={classes.nameCls} style={{ color: 'black' }}>
                                  {names?.student_id?.gender}
                                </div>
                              </>
                            )
                          }}
                          headerText="CLIENT NAME"
                          width="100"
                          allowFiltering={false}
                          // filter={{ type: 'CheckBox' }}
                        />
                        <ColumnDirective
                          field="student_id.birth_date"
                          template={(age) => (
                            <>
                              <div className={classes.nameCls} style={{ color: 'black' }}>
                                Age{' '}
                                {moment(age?.student_id?.birth_date, 'YYYY-MM-DD')
                                  .fromNow()
                                  .split('years ago')}
                              </div>
                            </>
                          )}
                          headerText="AGE"
                          width="80"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="contact_email"
                          template={(mail) => (
                            <>
                              <div>{mail?.student_id?.contact_email}</div>
                              <div>{mail?.student_id?.contact_phone_number}</div>
                              {/* <div>{mail?.parent1_phone_number}</div> */}
                            </>
                          )}
                          headerText="CONTACT DETAILS"
                          width="130"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="student_id.psc_score"
                          template={(scores) => (
                            <>
                              <span>
                                {scores?.student_id?.psc_score}/{scores?.student_id?.craft_score}
                              </span>
                            </>
                          )}
                          headerText="PSC/CRAFT"
                          width="98"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="speciatlities"
                          template={(need) => (
                            <>
                              <div>
                                {need.speciatlities}
                                {/* {need?.student_id?.student_specialities?.map((spe) => {
                            return spe.speciality_id?.name
                          })} */}
                              </div>
                            </>
                          )}
                          headerText="TREATMENT NEED"
                          width="110"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="student_id.insurance_company.name"
                          template={(insurance) => (
                            <>
                              <div>{insurance?.student_id?.insurance_company?.name}</div>
                            </>
                          )}
                          headerText="INSURANCE"
                          width="97"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="status"
                          template={(mail) => (
                            <>
                              <div>{mail?.student_id?.status}</div>
                            </>
                          )}
                          headerText="STATUS"
                          width="77"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="assigned_user_id.name"
                          template={(assignedname) => (
                            <>
                              <div>{assignedname?.assigned_user_id?.name}</div>
                            </>
                          )}
                          headerText="ASSIGNED TO"
                          width="90"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="outreach_attempts"
                          template={(oatempts) => (
                            <>
                              <div>{oatempts?.outreach_attempts}</div>
                            </>
                          )}
                          headerText="OUTREACH ATTEMPTS"
                          width="95"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="created_at"
                          template={(dates) => (
                            <>
                              <div>{moment(dates?.created_at).format('MM-DD-YYYY')}</div>
                            </>
                          )}
                          headerText="CREATED DATE"
                          width="90"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field="updated_at"
                          template={(dates) => (
                            <>
                              <div>{moment(dates?.updated_at).format('MM-DD-YYYY')}</div>
                            </>
                          )}
                          headerText="UPDATED DATE"
                          width="90"
                          allowFiltering={false}
                        />
                        <ColumnDirective
                          field=""
                          headerText="ACTIONS"
                          width="150"
                          allowFiltering={false}
                          template={(need) => (
                            <>
                              {need.student_id.status === 'ACTIVE' ? (
                                <>
                                  <div
                                    key={Math.floor(Math.random() * 100)}
                                    className={classessync.syncSplitBtn}
                                  >
                                    <SplitButtonComponent
                                      items={options}
                                      select={(val) => handleSplitBtnChange(val, need)}
                                    >
                                      -- Actions --
                                    </SplitButtonComponent>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    key={Math.floor(Math.random() * 100)}
                                    className={classessync.syncSplitBtn}
                                  >
                                    <SplitButtonComponent
                                      items={inactiveActions}
                                      select={(val) => handleInactiveSplitBtnChange(val, need)}
                                    >
                                      -- Actions --
                                    </SplitButtonComponent>
                                  </div>
                                </>
                                // <Button
                                //   color="primary"
                                //   aria-controls="simple-menu"
                                //   aria-haspopup="true"
                                //   className="mar-rt-15 "
                                //   variant="contained"
                                //   size="small"
                                //   style={{
                                //     height: '36px',
                                //     // marginTop: '13px',
                                //     fontSize: '10px',
                                //     width: '100px'
                                //   }}
                                //   onClick={(value) => handleOpenDialog(need)}
                                // >
                                //   Add Client
                                // </Button>
                              )}
                            </>
                          )}
                        />
                      </ColumnsDirective>
                      <Inject services={[Filter, Search, Toolbar, Sort, Page, DetailRow]} />
                      <div className="totalStudents" style={{ borderRight: '2px solid #7f7f7f' }}>
                        <span className="totalText">Total Active Clients</span>
                        <span style={{ fontSize: '24px' }}>{totalStatusCount}</span>
                      </div>
                      <div className={classes.checkBoxStyles}>
                        <span style={{ paddingRight: '10px' }}>
                          <CheckBoxComponent
                            // style={{ backgroundColor: 'rgb(241, 88, 58)' }}
                            checked={changeStatusList}
                            change={(e) => changeStatusOfList(e)}
                          />
                        </span>
                        <span style={{ paddingTop: '2px' }}>Show Inactive</span>
                      </div>
                      {adminList && (
                        <div className={classes.selectBoxStyles}>
                          <InputLabel
                            style={{ width: '115px', fontWeight: 'bold', color: '#606060' }}
                            id="demo-simple-select-label"
                          >
                            Assigned To:
                          </InputLabel>
                          <Select
                            style={{ width: '160px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={userid || 'null'}
                            // label="assignto"
                            variant="outlined"
                            onChange={(e) => assigntochange(e)}
                          >
                            <MenuItem value="null">Unassigned</MenuItem>
                            {adminList?.adminUsersList?.map((user) => (
                              <MenuItem key={user.id} value={user?.id?.toString()}>
                                {user?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                    </GridComponent>
                  )}

                  {/* {hideDialog && (
              <DialogComponent
                isModal
                header={() => eventDialogHeader()}
                content={() => eventDialogContent()}
                showCloseIcon
                // open={()=>dialogOpen()}
                close={() => eventDialogClose('close')}
                height="auto"
                width="525px"
                visible={hideDialog}
              />
            )} */}
                </div>
              </div>
            </>
            {openDialog && (
              <Dialog
                open={openDialog}
                maxWidth="md"
                onClose={handleOpenDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {status
                      ? 'Are you sure you want to add client to the waitlist?'
                      : 'Are you sure you want to deactivate client?'}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    No
                  </Button>
                  {status ? (
                    <Button onClick={handleAddedWaitlist} color="primary">
                      Yes
                    </Button>
                  ) : (
                    <Button onClick={handleRemoveFromWaitlist} color="primary">
                      Yes
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )}
            {isLoading()}
          </PageContainer>
        </>
      )}
      {hideDialog && (
        <NewScheduleUpdate
          initialValues
          onChange={(value) => eventDialogClose(value)}
          userDetails={null}
          userId={null}
          admin
          cellData={cellData}
          providerName={null}
        />
      )}
      {waitlogopen && (
        <WaitlistLog open={waitlogopen} handleclose={waitlogclose} waitlistobj={waitlistobj[0]} />
      )}
      {openOutreach && (
        <Outreach open={openOutreach} handleClose={handleclose} waitlistobj={waitlistobj[0]} />
      )}
      {openAssign && waitlistobj && waitlistobj?.length > 0 && (
        <AssignForm
          open={openAssign}
          handleClose={handleAssignclose}
          waitlistobj={waitlistobj[0]}
        />
      )}
    </>
  )
}
